import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SingleMenu from "./SingleMenu";

import { dateFormatter } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { status } = this.props;

    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === "active",
        "data-table__status_neg": status === "inactive",
      }
    );

    return <div className={className}>{status}</div>;
  };

  onRedirectToSubscribedWorkspace = (id, e) => {
    e.stopPropagation();
    e.preventDefault();
    const { workspacesCount } = this.props;

    if (!workspacesCount) {
      return false;
    }
    this.props.history.push(`/workspace/plan/${id}`);
  };

  getRowRender = () => {
    const {
      createdAt,
      buildStatus,
      deviceType,
      buildingVisoAgentVersion,
      osVersion,
      id,
      deviceCount,
      updatedAt,
      colorClassName,
      visoAgentVersion,
      currentSortKey,
      architecture
    } = this.props;

    const deviceTypeClass = classNames({
      "data-table__td_dark": currentSortKey === "deviceType",
    });

    const deviceCountClass = classNames({
      "data-table__td_dark": currentSortKey === "deviceCount",
    });

    const architectureClass = classNames(`text-capitalize ${colorClassName}`, {
      "data-table__td_dark": currentSortKey === "architecture",
    });

    const osVersionClass = classNames({
      "data-table__td_dark": currentSortKey === "osVersion",
    });

    const visoAgentVersionClass = classNames({
      "data-table__td_dark": currentSortKey === "visoAgentVersion",
    });

    const buildStatusClass = classNames("text-capitalize", {
      "data-table__td_dark": currentSortKey === "buildStatus",
      "success": buildStatus === 'succeeded',
      "text-color": buildStatus === 'building',
      "failure": buildStatus === 'failed',
    });

    const createdAtClass = classNames({
      "data-table__td_dark": currentSortKey === "createdAt",
    });

    const updatedAtClass = classNames({
      "data-table__td_dark": currentSortKey === "updatedAt",
    });

    return {
      name: () => <div className={deviceTypeClass}>{deviceType}</div>,
      visoAgentVersion: () => (
        <span className={visoAgentVersionClass}>{visoAgentVersion}</span>
      ),
      osVersion: () => (
        <span className={osVersionClass}>{osVersion || 'N/A'}</span>
      ),
      buildStatus: () => (
        <span className={buildStatusClass}>{buildStatus} {(buildStatus === 'building' || buildStatus === 'failed') && buildingVisoAgentVersion}</span>
      ),
      deviceCount: () => (
        <span className={deviceCountClass}>{deviceCount}</span>
      ),
      architecture: () => (
        <span
          className={architectureClass}
          onClick={(e) => this.onRedirectToSubscribedWorkspace(id, e)}
        >
          {architecture}
        </span>
      ),
      createdAt: () => (
        <span className={createdAtClass}>{dateFormatter(createdAt, true)}</span>
      ),
      updatedAt: () => (
        <span className={updatedAtClass}>{dateFormatter(updatedAt, true)}</span>
      ),
    };
  };

  render() {
    const { colorClassName, visoAgentVersion, id, buildStatus, buildingVisoAgentVersion } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <SingleMenu
          id={id}
          buildStatus={buildStatus}
          buildingVisoAgentVersion={buildingVisoAgentVersion}
          updateToLatestVersion={this.props.updateToLatestVersion}
          visoAgentVersion={visoAgentVersion} />
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
