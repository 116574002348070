import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { ExternalLink } from "react-feather";
import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import { dateFormatter } from "utils/common";
import { convertDefaultStatusToStatus } from "utils/common";

class Overview extends Component {
  getStatus = (status) => {
    const statusClass = classNames(
      "data-table__status text-capitalize text-captilize",
      {
        "data-table__status_pos": status === "approved",
        "data-table__status_warning": status === "pending",
        "data-table__status_neg": status === "rejected",
      }
    );

    return (
      <div className={statusClass}>
        {status && convertDefaultStatusToStatus(status)}
      </div>
    );
  };
  renderContent = () => {
    const { partner, color } = this.props;

    const fullName =
      partner.reviewedBy &&
      `${partner.reviewedBy.firstName} ${partner.reviewedBy.lastName}`;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Submitted at</div>
          <div className="overview__value">
            {partner.submittedAt && dateFormatter(partner.submittedAt, true)}
          </div>
          <div className="overview__label">Reviewed at</div>
          <div className="overview__value">
            {partner.reviewedAt && dateFormatter(partner.reviewedAt, true)}
          </div>
        </div>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Stripe Account</div>
          <div className="overview__value">
            <a
              href={`https://dashboard.stripe.com/connect/accounts/${partner.stripeAccountId}`}
              target="_blank"
              className="middle"
            >
              {partner.businessName}
              &nbsp;&nbsp;
              <ExternalLink size={16} />
            </a>
          </div>
          <div className="overview__label">Reviewed by</div>
          <div className="overview__value">
            <a href="javascript:void(0)">{fullName}</a>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title="Partner Application"
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Overview.propTypes = {
  onEdit: PropTypes.func,
  color: PropTypes.string,
  workspace: PropTypes.object,
};

export default Overview;
