import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import {
  defaultNotifier
} from 'functions/notificationHandler'
import appMessages from 'utils/message'
import Card from 'app/components/common/Detail/Card';
import CardTags from 'app/components/common/Detail/CardTags';

class Information extends Component {
  componentWillReceiveProps(nextprops) {
    if (nextprops.errors) {
      defaultNotifier(nextprops.errors, this.props)
    }
  }

  renderContent = () => {

    const { model } = this.props

    return (
      <div className="grid-col">
        <div className="overview__label">
          <FormattedMessage {...appMessages.name} />
        </div>
        <div className="overview__value">{ model && model.name}</div>
        <div className="overview__label">
          <FormattedMessage {...appMessages.description} />
        </div>
        <div className="overview__value overview__value_description">{ model && model.description }</div>
        <div className="overview__label">
          <FormattedMessage {...appMessages.tags} />
        </div>
        {model && <CardTags
          color={this.props.color}
          tags={model.tags}
        />
        }
      </div>
    )
  }

  render() {
    return (
      <Card
        color={this.props.color}
        title={
          <FormattedMessage {...appMessages.information} />
        }
        isLoading={this.props.isLoading}
        className="overview"
        onEdit={this.props.onEdit}
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  model: PropTypes.object
}

export default Information
