import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'

import {SaveIcon} from '../../../images/common'
import Modal from './Modal'

class ProcessPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showWarningModal: false
    }
    this.popupRef = React.createRef()
  }

  componentDidMount() {
    document.body.style.overflowY = 'hidden';
    document.addEventListener('keydown', this.onEscClick, false);
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'scroll';
    document.removeEventListener('keydown', this.onEscClick, false);
  }

  onEscClick = (event) => {
    if (!this.props.ignoreCloseOnEsc && event.keyCode === 27) {
      // on Esc button click it should close popup
      this.props.onCancel && this.props.onCancel();
    }
  }

  handleScrollbarsMount = (scrollbars) => {
    const viewElem = scrollbars && scrollbars.view

    this.popupRef = viewElem
    // hack to update ref and pass down
    this.setState({})
  }

  onModalClose = () => {
    this.setState({showWarningModal: false})
  }

  renderCancelModal = () => {
    const {onCancel} = this.props

    const buttons = [
      <button className="modal__confirm" key="confirm" onClick={onCancel}>
        Confirm
      </button>,
      <button className="modal__cancel" key="cancel" onClick={this.onModalClose}>
        Cancel
      </button>
    ]

    return (
      <Modal
        buttons={buttons}
        title='Discard Changes'
        body={<p>Confirm to proceed without saving the changes.</p>}
        onClose={this.onModalClose}
      />
    )
  }

  onCancel = () => {
    const {onCancel, isChanged} = this.props

    if (this.props.showUnsavedWarning && isChanged) {
      return this.setState({showWarningModal: true})
    }

    onCancel()
  }

  render() {
    const {showWarningModal} = this.state
    const { onBack, onCancel, onSave, onBackRight, discardLabel } = this.props

    return (
      <div id="popup-drs" className="popup popup_visible">
        {showWarningModal && this.renderCancelModal()}
        <Scrollbars
          style={{ width: '100%', height: '100vh' }}
          renderView={(props) => {
            return <div className="popup-scroll-wrap" {...props}></div>
          }}
          universal
          autoHide
          renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
          ref={this.handleScrollbarsMount}
        >
          <nav className="head-nav">
            {
              onBackRight &&
                <span
                  onClick={onBackRight}
                  className="round-button round-button_tr round-button_back cursor">
                  <span className="round-button__label">
                    Back</span>
                </span>
            }
            { onBack &&
              <span
                onClick={onBack}
                className="round-button round-button_tl round-button_back cursor"
              >
                <span className="round-button__label">Back</span>
              </span>
            }
            {
              onSave &&
              <span
                onClick={onSave}
                className="round-button round-button_tr cursor">
                <SaveIcon/>
                <span className="round-button__label">Save</span>
              </span>
            }
            { onCancel &&
              <span
                onClick={this.onCancel}
                className="round-button round-button_tr round-button_close cursor"
              >
                <span className="round-button__label">
                  {discardLabel || 'Cancel'}
                </span>
              </span>
            }
          </nav>
          <div className="popup__cont">
            <div className="popup__inner">
              {this.popupRef.current !== null && this.props.children(this.popupRef)}
            </div>
          </div>
        </Scrollbars>
      </div>
    )
  }
}

ProcessPage.propTypes = {
  children: PropTypes.any.isRequired,
  onBack: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.any
}

export default ProcessPage
