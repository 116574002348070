import React from 'react';
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types';

import {routeMapping} from 'routes';
import { SOLUTION_ACCENT } from 'app/constants/common';
import ProcessPage from 'app/components/common/ProcessPage';
import EstimateItem from './EstimateItem';

class FullBillEstimate extends React.Component {
  onClose = () => {
    return this.props.history.push(routeMapping.WORKSPACES.path);
  }

  render() {
    const { color } = this.props;

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {scrollRef => (
          <>
            <div className="headline">
              <h1 className="headline__title">Bill Estimate</h1>
              <h3 className="headline__subtitle">{this.props.workspace.organizationName}</h3>
            </div>

            <div className="actions-nav">
              <a href="/" className={`text-link ${color} actions-nav__link`}>
                Contact Us
              </a>
              <a href="/" className={`text-link ${color} actions-nav__link`}>
                FAQ
              </a>
            </div>

            <div className="data-table">
              <table className="data-table__table">
                <thead className="data-table__head">
                  <tr>
                    <th className="data-table__th data-table__th_1" />
                    <th className="data-table__th data-table__td_dark fw500">Subscription</th>
                    <th className="data-table__th">Plan</th>
                    <th className="data-table__th">Billing Period</th>
                    <th className="data-table__th">Provider</th>
                    <th className="data-table__th ta-right">Amount</th>
                    <th className="data-table__th data-table__th_1" />
                  </tr>
                </thead>

                <tbody className="data-table__body">
                  <EstimateItem name="Viso Suite" plan="Standard Plan" period="Monthly" provider="viso.ai" amount="$500"/>
                  <EstimateItem name="Module Name" plan="Item Plan" period="Monthly" provider="Company viso" amount="$9500"/>
                  <EstimateItem name="Module Name" plan="Standard Plan" period="Monthly" provider="viso" amount="$9000"/>
                  <EstimateItem name="Flow Name" plan="Standard Plan" period="Monthly" provider="viso" amount="$90"/>
                  <EstimateItem name="Flow Name" plan="Standard Plan" period="Monthly" provider="viso" amount="$40"/>
                  <tr>
                    <td colSpan="7" className="data-table__td">
                      <div className="data-table__divider" />
                    </td>
                  </tr>
                </tbody>

                <tfoot className="data-table__foot">
                  <tr>
                    <th className="data-table__td" />
                    <td rowSpan="2" colSpan="3" className="data-table__td">
                      Based on your current configuration, you are being
                      automatically charged $696.50.
                      <br />
                      To make changes to your product subscriptions, go to{' '}
                      <Link to={routeMapping.WORKSPACES.path}
                        className={`text-link ${color}`}
                      >
                        Manage Subscriptions
                      </Link>
                      .
                    </td>
                    <td className="data-table__td">Tax</td>
                    <td className="data-table__td ta-right">USD 50.00</td>
                    <td className="data-table__td data-table__td_1" />
                  </tr>
                  <tr>
                    <th className="data-table__td" />
                    <td className="data-table__td data-table__td_dark fw500">
                      Total due May 9, 2020
                    </td>
                    <td className="data-table__td data-table__td_dark fw500 ta-right">
                      USD 397.00
                    </td>
                    <td className="data-table__td data-table__td_1" />
                  </tr>
                </tfoot>
              </table>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

FullBillEstimate.propTypes = {
  color: PropTypes.string
};

FullBillEstimate.defaultProps = {
  color: SOLUTION_ACCENT
};

export default FullBillEstimate;
