import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { truncate } from "utils/common";
import Modal from "app/components/common/Modal";
import messages from "utils/message";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import {ModelIcon} from 'images/common';

import EditInformation from '../Edit/Information';
import DetailScroll from "app/components/common/Scroll";
import { defaultNotifier } from 'functions/notificationHandler'

import Information from './components/Information';
import Overview from './components/Overview';
import Label from './components/Label';
import Detail from './components/ModelDetail';
import AssociatedNode from './components/AssociatedNode/index';
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import SingleMenu from '../details/components/SingleMenu';
import {deleteModel, unassignNodesFromModel} from 'app/api/model';
import { routeMapping } from "app/constants/routes";

import { LIBRARY_ACCENT } from "app/constants/common";
import {
   getModel,
  } from "app/actions/models";
import PreLoader from "app/components/common/PreLoader/PreLoaderDetail";

class ModelDetails extends React.Component {
  state = {
    showDropdown: false,
    loggedInUser: this.props.loggedInUser,
    editPopupForm: null,
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.props.getModel({ id });
  }

  componentDidUpdate(nextProps) {
    if(nextProps.match.url !== this.props.match.url) {
      this.props.getModel({ id: this.props.match.params.id });
    }
  }

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  fetchModel = () => {
    const { id } = this.props.match.params;

    this.props.getModel({ id });
  };

  
  onDelete = async (id) => {
    this.setState({ showModalDelete: false });
    await deleteModel({ id });
    return this.props.history.push(routeMapping.MODELS.path);
    
  };

  renderModal = () => {
    const { body, onConfirm, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={this.onCloseModal}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={this.onCloseModal} />
    );
  };

  onHideDeleteWarning = async () => {
    this.setState({ showModalDelete: false });
  };

  onShowDeleteWarning = async (id, e) => {
    e.stopPropagation();

    this.setState({ showModalDelete: true, modelId: id });
  };

  onOpenInformationModal = () => {
    this.setState({ openEditInformationModal: true });
  };

  onCloseInformationModal = () => {
    this.setState({ openEditInformationModal: false });
  };

  onRenderDeleteModal = () => {
    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={e => this.onDelete(this.props.model.id, e)}
      >
        {this.state.isLoading ? (
          <FormattedMessage {...messages.loading} />
        ) : (
          <FormattedMessage {...messages.confirm} />
        )}
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onHideDeleteWarning}
      >
        <FormattedMessage {...messages.cancel} />
      </button>
    ];

    return (
      <Modal
        buttons={buttons}
        title={<FormattedMessage {...messages.removeYourModel} />}
        body={
          <p>
            <FormattedMessage {...messages.removeYourModelDesc}
              values={{name: <span className='text-color'>{this.props.model.name}</span>}} />
          </p>
        }
        onClose={this.onHideDeleteWarning}
      />
    );
  };

  onUnassign = async (id, node) => {
    const {data} = await unassignNodesFromModel({id, node});

    if (data.errors) {
      return defaultNotifier(data.errors)
    }

    defaultNotifier('Node successfully unassigned.')
    this.fetchModel();

  }
  
  render() {
    const {
      openEditInformationModal,
    } = this.state;
    const { color, models, isLoading } = this.props;

    const { id } = this.props.match.params;


    if (isLoading) {
      return <PreLoader isProcessPage />;
    }

    if (openEditInformationModal) {
      return (
        <EditInformation
          editMode
          getModel={() => this.fetchModel()}
          model={this.props.model}
          color={this.props.color}
          onClose={this.onCloseInformationModal}
        />
      );
    }

    const { showModalDelete } = this.state;

    return (
      <>
        {showModalDelete && this.onRenderDeleteModal()}
          <DetailScroll>
            <div className="headline">
            <div className={`headline__icon ${color} admin_workspace__image`}>
                <ModelIcon />
              </div>
              <h1 className="headline__title">
                {this.props.model && this.props.model.name}
              </h1>
            </div>

            <Breadcrumb customClass={color}>
              <BreadcrumbItem route="Library" />
              <BreadcrumbItem route="Models" />
              <BreadcrumbItem
                route={truncate(
                  this.props.model && this.props.model.name
                )}
              />
            </Breadcrumb>

            <div className="actions-nav">
              
              <VerticalButtonMenu customClass={color}>
                <SingleMenu
                  onDelete={this.onShowDeleteWarning}
                  model={this.props.model}
                />
              </VerticalButtonMenu>
              <span
            className={`actions-nav__link cursor label-color`}
                  onClick={this.onOpenInformationModal}
                >
                  Edit
                </span>
            </div>
              <>
                <div className="grid-row mobile-page">
                  <div className="grid-col grid-col_sm-12">
                    <Information
                      isLoading={this.state.isLoading || this.props.loading}
                      model={this.props.model}
                      color={this.props.color}
                      onEdit={() => this.onOpenInformationModal()}
                      onClose={this.onCloseInformationModal}
                    />
                    <AssociatedNode
                      color={color}
                      onUnassign={this.onUnassign}
                      detailRole={this.props.detailRole}
                      isLoading={this.state.isLoading || this.props.loading}
                      model={this.props.model}
                      getModel={this.fetchModel}
                    />
                  </div>
                  <div className="grid-col grid-col_sm-12">
                    <Overview
                      isLoading={this.state.isLoading || this.props.loading}
                      model={this.props.model}
                      color={this.props.color}
                      workspace={this.props.workspace}
                    />
                    <Detail
                      isLoading={this.state.isLoading || this.props.loading}
                      onOpenVersionPage={this.openVersionPopup}
                      model={this.props.model}
                      color={this.props.color}
                      workspace={this.props.workspace}
                    />
                    <Label
                      color={this.props.color}
                      model={this.props.model}/>
                  </div>
                </div>
              </>
          </DetailScroll>
      </>
    );
  }
}

ModelDetails.propTypes = {
  color: PropTypes.string,
  category: PropTypes.object,
  loggedInUser: PropTypes.object,
};

ModelDetails.defaultProps = {
  color: LIBRARY_ACCENT,
};

function mapStateToProps(state) {
  return {
    model: state.model.details,
    isLoading: state.model.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  getModel
});

export default compose(withRouter, withConnect)(ModelDetails);
