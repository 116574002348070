import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";
import {Link} from 'react-router-dom'

import Menu from "./SingleMenu";
import { dateFormatter } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { status } = this.props;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": status === 'active',
        "data-table__status_neg": status === 'inactive',
        "data-table__status_neutral": status === 'deleted',
      }
    );
    return <div className={className}><span className="text-capitalize">{status}</span></div>
  };

  getAnnotationStatus = () => {
    const { annotationEnabled } = this.props;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": annotationEnabled,
        "data-table__status_neg": !annotationEnabled,
      }
    );
    return <div className={className}><span className="text-capitalize">{annotationEnabled ? 'Active' : 'Inactive'}</span></div>
  };

  getTrainingStatus = () => {
    const { trainingEnabled } = this.props;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": trainingEnabled,
        "data-table__status_neg": !trainingEnabled,
      }
    );
    return <div className={className}><span className="text-capitalize">{trainingEnabled ? 'Active' : 'Inactive'}</span></div>
  };

  onRedirectToPartner = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    if(id) {
      return this.props.history.push(`/partner/${id}`)
    }
  }

  getPartner = () => {
    const { partner={} } = this.props;

    if(partner && partner.id && partner.status === 'approved') {
      return <span className="cursor accent0" onClick={(e) => this.onRedirectToPartner(e, partner.id)}>Partner</span>
    }
    return <span onClick={(e) => this.onRedirectToPartner(e)}>{(partner && partner.status) || 'Inactive'}</span>
  };

  getRowRender = () => {
    const {
      createdAt,
      name,
      id,
      colorClassName,
      createdBy,
      currentSortKey
    } = this.props;

    const nameClass = classNames({
      "data-table__td_dark": currentSortKey === "name",
    });

    const statusClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "status",
    });

    const idClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "id",
    });
    
    const partnerClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "partner.id",
    });

    const createdClass = classNames('type__value', {
      [colorClassName]: colorClassName,
      "data-table__td_dark": currentSortKey === "createdBy.name",
    });

    const createdAtClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "createdAt",
    });

    const annotationClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "annotationEnabled",
    });

    const trainingClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "trainingEnabled",
    });
    return {
      name: () => <div className={nameClass}>{name}</div>,
      status: () => <span className={statusClass}>{this.getStatus()}</span>,
      id: () => <span className={idClass}>{id}</span>,
      'partner.id': () => <span className={partnerClass}>{this.getPartner()}</span>,
      'createdBy.name': () => (
        createdBy && <span className={createdClass}>
          {createdBy.name}
        </span>
      ),
      annotationEnabled: () => <span className={annotationClass}>{this.getAnnotationStatus()}</span>,
      trainingEnabled: () => <span className={trainingClass}>{this.getTrainingStatus()}</span>,
      createdAt: () => <span className={createdAtClass}>{dateFormatter(createdAt, true)}</span>,
     };
  };

  render() {
    const { colorClassName, detailRole = {}, id, isActive, archived, deleteDate, status, name, annotationEnabled, trainingEnabled } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
            <Menu
              id={id}
              name={name}
              isActive={isActive}
              deleteDate={deleteDate}
              archived={archived}
              status={status}
              trainingEnabled={trainingEnabled}
              annotationEnabled={annotationEnabled}
              onActivateAnnotation={this.props.onActivateAnnotation}
              onDeactivateAnnotation={this.props.onDeactivateAnnotation}
              onActivateTraining={this.props.onActivateTraining}
              onDeactivateTraining={this.props.onDeactivateTraining}
              onManageAgent={this.props.onManageAgent}
              onCancelSchedule={this.props.onCancelSchedule}
              onDeactivate={this.props.onDeactivate}
              onActivate={this.props.onActivate}
              onUpdate={this.props.onUpdate}
              detailRole={detailRole.role}
              onDelete={this.props.onDelete}
              onScheduleDelete={this.props.onScheduleDelete}
            />
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
