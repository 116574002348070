import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List } from "react-feather";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import NotFound from "app/components/NotFound";
import { FormattedMessage } from "react-intl";
import Modal from "app/components/common/Modal";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import Pagination from "app/components/Pagination";

import SearchField from "app/components/common/SingleSearch";
import NoPlans from "../../common/NoItem";
import messages from "utils/message";

import Card from "./Card";
import ListTable from "./Table";
import { isSmallerDevice } from "utils/common";
import { setPreInstallSolution } from "app/api/listings";

import { routeMapping } from "app/constants/routes";

import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  SOLUTION_ACCENT,
} from "app/constants/common";
import {
  fetchListing,
  reviewAction,
  acceptAction,
  rejectAction,
} from "app/actions/listing";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";

import ListSelectedItems from "../../common/ListSelectedItems";
import { defaultNotifier } from "functions/notificationHandler";

class ListingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      selectedIds: [],
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "name",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: DEFAULT_PAGE_SIZE,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.listings,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: DEFAULT_PAGE_SIZE,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.listings);
    let result = finalResult;
    this.setCurrentPage(1);

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.setState({ filterResult: this.props.listings })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.getListing();
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  getListing = async () => {
    await this.props.fetchListing();
  };

  onApprove = async (id) => {
    await this.props.acceptAction(id);
    this.getListing();
  };

  onApproveListing = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The listing will be approved.`;
    const title = `Confirm approve listing`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onApprove(id),
    });
  };

  onPreInstallListing = async (id, preInstallable) => {
    const { data } = await setPreInstallSolution({
      id,
      preInstallable: !preInstallable,
    });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }
    defaultNotifier("Updated successfully");
    this.getListing();
  };

  onPreInstall = (id, preInstallable, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = preInstallable
      ? `The listing will be disable for pre installation.`
      : `The listing will be available for pre installation.`;

    const title = `Confirm`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onPreInstallListing(id, preInstallable),
    });
  };

  onReview = async (id) => {
    await this.props.reviewAction(id);
    this.getListing();
  };

  onReviewListing = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The listing status will be changed to review.`;
    const title = `Confirm starting the reivew`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onReview(id),
    });
  };

  onRejectListing = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The listing will be rejected.`;
    const title = `Confirm reject listing`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onReject(id),
    });
  };

  onReject = async (id) => {
    await this.props.rejectAction(id);
    this.getListing();
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  loadMore = () => {
    if (this.state.isAllSelected) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE });
  };

  isMore = () => {
    const { listings } = this.props;

    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    const data = hasFilter ? this.state.filterResult : listings;

    if (this.state.activeView === CARD_VIEW) {
      return false;
    }

    if (!data) {
      return false;
    }

    return data.length > this.state.offset;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`/listing/${id}`);
  };

  redirectToCreatePage = () => {
    return this.props.history.push(routeMapping.CREATE_CATEGORY.path);
  };

  renderModal = () => {
    const { body, onConfirm, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={title}
        body={body}
        onClose={this.onCloseModal}
      />
    );
  };

  // pagination
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  render() {
    const { color } = this.props;

    const {
      showModal,
      activeView,
      selectedIds,
      resetFilter,
      currentPage,
    } = this.state;

    const viewHeadlineClass = classNames(
      `actions-nav__icon actions-nav__icon_rows ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === TABLE_VIEW }
    );

    const viewListClass = classNames(
      `actions-nav__icon actions-nav__icon_cards ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === CARD_VIEW }
    );

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    // if (this.props.isLoading) {
    //   return <LoadingIndicator color={this.props.color} />;
    // }

    return (
      <>
        {showModal && this.renderModal()}
        <ListScroll>
          {(scrollParent) => (
            <>
              <div className="headline">
                <h1 className="headline__title">Listings</h1>
              </div>
              <Breadcrumb breadcrumbClass="breadcrumbs_static">
                <BreadcrumbItem />
                <BreadcrumbItem route="Marketplace Listings are provided by partner workspaces." />
              </Breadcrumb>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
              </div>
              {!hasFilter &&
              !this.props.listings.length &&
              !this.props.isLoading ? (
                <NotFound
                  color={color}
                  strokeWidth="1"
                  logo={<List size={42} />}
                  title="No listing available"
                />
              ) : (
                <>
                  <div className="filters-bar">
                    <ListSelectedItems
                      selectedNumber={selectedIds.length}
                      search={this.state.searchName}
                      onReset={this.onReset}
                      filterData={this.state.filter}
                      containerClassName={color}
                    />
                    <Filter
                      customClass={color}
                      resetFilter={resetFilter}
                      onChange={this.onFilter}
                      columns={filterColumns}
                      data={this.props.listings}
                      defaultFilter={[
                        {
                          field: "status",
                          value: "All",
                          isDefault: true,
                          columns: ["draft", "submitted", "listed"],
                        },
                      ]}
                    />
                    <SearchField
                      onSearch={this.onSearch}
                      customClass={color}
                      resetSearch={this.state.resetFilter}
                      label="Search"
                    />
                  </div>
                  {!this.props.isLoading && (
                  <div>
                    <InfiniteScroll
                      initialLoad={false}
                      useWindow={false}
                      getScrollParent={() => scrollParent}
                    >
                      {activeView === TABLE_VIEW && (
                        <>
                          <ListTable
                            data={
                              hasFilter
                                ? this.state.filterResult.filter(
                                    (item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }

                                      if (
                                        currentPage !== 1 &&
                                        index <
                                          this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }

                                      return false;
                                    }
                                  )
                                : this.props.listings.filter(
                                    (item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }

                                      if (
                                        currentPage !== 1 &&
                                        index <
                                          this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }

                                      return false;
                                    }
                                  )
                            }
                            onRowClick={this.onRowClick}
                            selectAll={this.selectAll}
                            onSort={this.onSort}
                            color={color}
                            isLoading={this.props.isLoading}
                            sort={this.state.sort}
                            isSelectedAll={this.getIsSelectedAll()}
                            selectedIds={selectedIds}
                            onSelect={this.onSelect}
                            onPreInstall={this.onPreInstall}
                            onReject={this.onRejectListing}
                            onApprove={this.onApproveListing}
                            onReview={this.onReviewListing}
                          />
                        </>
                      )}
                      {activeView === CARD_VIEW && (
                        <div className="data-cards">
                          <Card
                            color={color}
                            data={
                              hasFilter
                                ? this.state.filterResult.filter(
                                    (item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }

                                      if (
                                        currentPage !== 1 &&
                                        index <
                                          this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }

                                      return false;
                                    }
                                  )
                                : this.props.listings.filter(
                                    (item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }

                                      if (
                                        currentPage !== 1 &&
                                        index <
                                          this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }

                                      return false;
                                    }
                                  )
                            }
                            onDuplicate={this.onDuplicate}
                            onRowClick={this.onRowClick}
                            selectedItems={selectedIds}
                            onReject={this.onRejectListing}
                            onPreInstall={this.onPreInstall}
                            onApprove={this.onApproveListing}
                            onReview={this.onReviewListing}
                            onSelect={this.onSelect}
                          />
                        </div>
                      )}
                        {((hasFilter && !!this.state.filterResult.length) ||
                            (!hasFilter && !!this.props.listings.length)) && (
                            <Pagination
                              onNext={this.onNext}
                              currentPage={this.state.currentPage}
                              setCurrentPage={this.setCurrentPage}
                              onChangeLimit={this.onChangeLimit}
                              limit={this.state.offset}
                              totalPage={
                                hasFilter
                                  ? this.state.filterResult.length
                                  : this.props.listings.length
                              }
                            />
                          )}
                    </InfiniteScroll>
                    {activeView === CARD_VIEW &&
                      this.props.listings &&
                      !this.props.listings.length && <NoPlans />}
                  </div>
                  )}
                </>
              )}
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

ListingList.propTypes = {
  color: PropTypes.string,
  listings: PropTypes.array,
  activeView: PropTypes.string,
};

ListingList.defaultProps = {
  color: SOLUTION_ACCENT,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    listings: state.listing.list,
    isLoading: state.listing.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  fetchListing,
  reviewAction,
  acceptAction,
  rejectAction,
});

export default compose(withRouter, withConnect)(ListingList);
