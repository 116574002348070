import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {PlusCircle} from 'react-feather'


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'

const columnList = [{
  label: 'Name',
  isSortable: true,
  isLocked: true,
  sortKey: 'name'
}, {
  label: 'ID',
  sortable: false,
  isSortable: true,
  sortKey: 'id'
},
{
  label: 'Status',
  sortable: false,
  isSortable: true,
  sortKey: 'status'
},
{
  label: 'Description',
  sortable: false,
  isSortable: true,
  sortKey: 'description'
},
{
  label: 'Slug',
  sortable: false,
  isSortable: true,
  sortKey: 'slug'
},
{
  label: 'Parent',
  sortable: false,
  isSortable: true,
  sortKey: 'parent.name'
},
{
  label: <FormattedMessage {...appMessages.created} />,
  sortable: false,
  isSortable: true,
  sortKey: 'createdAt'
}]

class CategoryTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        notFoundIcon={<PlusCircle size={42}/>}
        rows={data || []}
        colorClassName={color}
        CheckboxImage={PlusCircle}
        isSelectable
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        isLoading={this.props.isLoading}
        onDeactivate={this.props.onDeactivate}
        onActivate={this.props.onActivate}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
        onDelete={this.props.onDelete}
        onUpdateStatus={this.props.onUpdateStatus}
      />
    )
  }
}

CategoryTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default CategoryTable
