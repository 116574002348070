import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'
import {FilePlus} from 'images/common'

const columnList = [{
  label: <FormattedMessage {...appMessages.planName} />,
  isSortable: true,
  isLocked: true,
  sortKey: 'prettyName'
},
{
  label: <FormattedMessage {...appMessages.product} />,
  sortable: false,
  isSortable: true,
  sortKey: 'product'
},
{
  label: 'Price',
  sortable: false,
  isSortable: false,
  sortKey: 'priceId'
},
{
  label: <FormattedMessage {...appMessages.category} />,
  sortable: false,
  isSortable: true,
  sortKey: 'category'
},
{
  label: <FormattedMessage {...appMessages.mode} />,
  sortable: false,
  isSortable: true,
  sortKey: 'billingPeriod'
},
{
  label: <FormattedMessage {...appMessages.quantity} />,
  sortable: false,
  isSortable: true,
  sortKey: 'quantity'
},
{
  label: <FormattedMessage {...appMessages.trial} />,
  sortable: false,
  isSortable: true,
  sortKey: 'trial'
},
{
  label: <FormattedMessage {...appMessages.created} />,
  sortable: false,
  isSortable: true,
  isOptional: true,
  sortKey: 'createdAt'
},
{
  label: 'Price List',
  sortable: false,
  isSortable: true,
  isOptional: true,
  sortKey: 'phases'
}
]
class ModuleTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds,
      onSetAsDevPlan
    } = this.props

    const result = data && data.map(item => ({...item, id: item.id, originalId: item.id}))
    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        notFoundIcon={<FilePlus size={42}/>}
        rows={result || []}
        colorClassName={color}
        CheckboxImage={FilePlus}
        isSelectable
        hideSingleMenu
        onSetAsDevPlan={onSetAsDevPlan}
        showQuota={this.props.showQuota}
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        isLoading={this.props.isLoading}
        onDeactivatePlan={this.props.onDeactivatePlan}
        onActivatePlan={this.props.onActivatePlan}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={null}
        onDelete={this.props.onDelete}
        onUpdateStatus={this.props.onUpdateStatus}
      />
    )
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ModuleTable
