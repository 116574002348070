import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SortableRow from "app/components/common/Table/SortableRow";
import SingleMenu from "./SingleMenu";
import { dateFormatter, getInterval, snakeCaseToTitleCase } from "utils/common";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { isActive } = this.props;

    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": isActive,
        "data-table__status_neg": !isActive,
      }
    );

    return <div className={className}>{isActive ? "Active" : "Inactive"}</div>;
  };

  getPrice = (type, prices, fixedPrices) => {
    if(type === 'TRIAL') {
      return `${fixedPrices[0].currency} ${fixedPrices[0].value}`
    }

    return `${prices[0].currency} ${prices.map(price => price.value).toString()}`
  }

  getRowRender = () => {
    const {
      plan,
      phase,
      colorClassName,
      type,
      prices,
      duration,
      fixedPrices,
      created,
      currentSortKey
    } = this.props;
    const phaseClass = classNames({
      "data-table__td_dark": currentSortKey === "phase",
    });

    const typeClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "interval",
    });

    const durationClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "duration",
    });

    const priceClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "price",
    });

    const createdClass = classNames('', {
      "data-table__td_dark": currentSortKey === "created",
    });

    const billingPeriodClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "billingPeriodClass",
    });

    return {
      phase: () => <div className={phaseClass}>{phase}</div>,
      type: () => (
        <div className={typeClass}>
          {type}
        </div>
      ),
      duration: () => <div className={durationClass}>{duration.number >= 0 ? duration.number : ''} {duration.unit}</div>,
      billingPeriod: () => <div className={billingPeriodClass}>{snakeCaseToTitleCase(plan.billingPeriod)}</div>,
      prices: () => <div className={priceClass}>{this.getPrice(type, prices, fixedPrices)}</div>,
      created: () => <span className={createdClass}>{dateFormatter(created * 1000, true)}</span>,
    };
  };

  render() {
    const { colorClassName, id, recurring } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <SingleMenu
            interval={recurring && recurring.interval}
            deletePrice={this.props.deletePrice}
            id={id}
            setDefaultPrice={this.props.setDefaultPrice}
          />
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
