import {sortBy} from 'lodash'

import { workspaces } from "../constants/workspaces";
import { billing } from "../constants/billing";

import {
  getWorkspaces,
  getWorkspace,
  scheduleDeleteWorkspace,
  archiveWorkspace,
  reactivateWorkspace,
  activateWorkspace,
  deleteWorkspace,
  deactivateWorkspace,
  getVisoAgents,
  activateAutoTraining
} from "../api/workspaces";
import { getWorkspaceSubscriptions, invoices } from "../api/billing";

import { defaultNotifier } from "functions/notificationHandler";

export function listWorkspaces(payload) {
  return async (dispatch) => {
    dispatch({ type: workspaces.GET_WORKSPACES_PENDING });

    try {
      const { data } = await getWorkspaces(payload);

      if (data.errors) {
        defaultNotifier(data.errors);
      }

      const result = sortBy(data.data.workspaces, ['name']);
      
      dispatch({
        type: workspaces.GET_WORKSPACES_FULFILLED,
        payload: result,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the workspaces");

      dispatch({
        type: workspaces.GET_WORKSPACES_REJECTED,
        error: {
          message: "Error while fetching workspaces.",
        },
      });
    }
  };
}

export function listVisoAgents(payload) {
  return async (dispatch) => {
    dispatch({ type: workspaces.GET_VISO_AGENTS_PENDING });

    try {
      const { data } = await getVisoAgents(payload);

      if (data.errors) {
        defaultNotifier(data.errors);
      }

      dispatch({
        type: workspaces.GET_VISO_AGENTS_FULFILLED,
        payload: data.data.visoAgents,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the agents");

      dispatch({
        type: workspaces.GET_VISO_AGENTS_REJECTED,
        error: {
          message: "Error while fetching agents.",
        },
      });
    }
  };
}


export function listWorkspaceSubscriptions(payload) {
  return async (dispatch) => {
    dispatch({ type: billing.GET_WORKSPACE_SUBSCRIPTIONS_REQUEST });

    try {
      const { data } = await getWorkspaceSubscriptions(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      dispatch({
        type: billing.GET_WORKSPACE_SUBSCRIPTIONS_SUCCESS,
        payload: data.data.workspaceInvoices.data,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the workspace subscriptions");

      dispatch({
        type: billing.GET_WORKSPACE_DETAILS_REJECTED,
        error: {
          message: "Error while fetching workspace subscriptions.",
        },
      });
    }
  };
}

export function getWorkspaceDetails(payload) {
  return async (dispatch) => {
    dispatch({ type: workspaces.GET_WORKSPACE_DETAILS_PENDING });

    try {
      const { data } = await getWorkspace(payload);

      if (data.errors) {
        defaultNotifier(data.errors);
      }

      // const response = await invoices({customerId: data.data.workspace.customerId});

      dispatch({
        type: workspaces.GET_WORKSPACE_DETAILS_FULFILLED,
        payload: data.data.workspace,
      });
      // dispatch({
      //   type: workspaces.GET_WORKSPACE_INVOICE_FULFILLED,
      //   payload: response.data.data.invoices,
      // });
    } catch (err) {
      defaultNotifier("Failed to fetch the workspaces");
    }
  };
}


export function getWorkspaceInvoice(payload) {
  return async (dispatch) => {
    dispatch({ type: workspaces.GET_WORKSPACE_INVOICE_PENDING });

    try {
      const { data } = await invoices(payload);

      if (data.errors) {
        defaultNotifier(data.errors);
      }

      dispatch({
        type: workspaces.GET_WORKSPACE_INVOICE_FULFILLED,
        payload: data.data.invoices,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the workspace invoice");
    }
  };
}

export function scheduleDeleteWorkspaceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await scheduleDeleteWorkspace(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      if (onCallback) {
        onCallback();
        defaultNotifier("Workspace will be deleted soon.");
      }
    } catch (err) {
      defaultNotifier("Failed to delete the workspaces");
    }
  };
}

export function cancelScheduleDeleteWorkspaceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await reactivateWorkspace(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      if (onCallback) {
        onCallback();
        defaultNotifier("Workspace recovered.");
      }
    } catch (err) {
      defaultNotifier("Failed to recover the workspaces");
    }
  };
}

export function deleteWorkspaceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      dispatch({ type: workspaces.DELETE_WORKSPACES_PENDING });
      const { data } = await deleteWorkspace(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      if (onCallback) {
        dispatch({ type: workspaces.DELETE_WORKSPACES_FULFILLED });
        onCallback();
        defaultNotifier("Workspace deleted successfully.");
      }
    } catch (err) {
      defaultNotifier("Failed to delete the workspaces");
      dispatch({ type: workspaces.DELETE_WORKSPACES_REJECTED });
    }
  };
}

export function archiveWorkspaceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await archiveWorkspace(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      if (onCallback) {
        onCallback();
        defaultNotifier("Workspace archived successfully.");
      }
    } catch (err) {
      defaultNotifier("Failed to archive the workspaces");
    }
  };
}

export function activateWorkspaceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await activateWorkspace(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Workspace activated successfully");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Failed to activate the workspaces");
    }
  };
}

export function deactivateWorkspaceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await deactivateWorkspace(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Workspace deactivated successfully");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Failed to deactivate the workspaces");
    }
  };
}

export function activateAutoTrainingAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await activateAutoTraining(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Activated successfully");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Failed to activate");
    }
  };
}