import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import ProcessPage from "app/components/common/ProcessPage";
import Tags from "app/components/common/Tags";
import CardTags from "app/components/common/Detail/CardTags";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import ImageUploader from './ImageUploader';

const MarketingInformation = ({
  color,
  solution,
  onClose,
  onUpdate,
  data,
  onConfirm,
  getSolution,
  removeImage,
}) => {
  const [images, setImages] = useState(solution.images);
  const [removedImages, setRemoveImages] = useState([]);
  const [value, setValue] = useState(solution.description);
  const [tags, setTags] = useState(solution.keywords);

  const onCallback = () => {
    getSolution();
    onClose();
  };

  const onRemoveImages = (payload) => {
    const updateImages = images.filter((item) => item !== payload);

    setImages(updateImages);
    setRemoveImages(payload);
  };

  const onSubmit = () => {
    let payload = {
      id: solution.id,
      description: value,
      keywords: tags,
    };

    if (data.url) {
      payload = {
        ...payload,
        newImageS3Keys: data.url,
      };
    }

    if (removedImages.length) {
      payload = {
        ...payload,
        deletedImages: removedImages,
      };
    }
    onConfirm(payload, onCallback);
  };

  const onChangeEditor = (payload) => {
    setValue(payload);
  };
  return (
    <ProcessPage onCancel={onClose}>
      {() => (
        <div className="popup__inner">
          <div className="headline">
            <h1 className="headline__title">Marketing Information</h1>
            <h3 className="headline__subtitle">
              {solution.name} • {solution.id}
            </h3>
          </div>

          <form action="#" className="popup__form popup__form_fw">
            <label className="popup__label">Screenshot Gallery</label>
            <div className="screenshots">
              <div className="grid-row screenshots_row">
                {/* <ImageUploader
                  onUpdate={onUpdate}
                /> */}
                {images &&
                  images.map((image) => {
                    return (
                      <div className="" key={image}>
                        <div
                          className="screenshots__item"
                          style={{ backgroundImage: `url(${image})` }}
                        >
                          {/* <span className="screenshots__remove cursor" onClick={() => onRemoveImages(image)}>Click to remove</span> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <label className="popup__label">Solution Description</label>
            <div className="text-editor">
              <ReactQuill
                theme="snow"
                disabled
                readOnly
                value={value}
                onChange={() => null}
              />
            </div>
            <div><FormattedMessage {...messages.keywords}/></div>
            <CardTags color={color} tags={tags} disabled/>
          </form>

          {/* <div className="foot-nav">
            <div onClick={onSubmit}
              className="round-button round-button_br round-button_primary round-button_forw cursor">
              <span className="round-button__label">
                <FormattedMessage {...messages.confirm}/>
              </span>
            </div>
          </div> */}
        </div>
      )}
    </ProcessPage>
  );
};

MarketingInformation.propTypes = {
  user: PropTypes.object,
};

export default MarketingInformation;
