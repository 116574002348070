import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {Users} from 'react-feather'


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'

const columnList = [{
  label: <FormattedMessage {...appMessages.userName} />,
  isSortable: true,
  isLocked: true,
  sortKey: 'firstName'
}, {
  label: <FormattedMessage {...appMessages.userEmail} />,
  sortKey: 'email'
},
{
  label: <FormattedMessage {...appMessages.visoRole} />,
  sortable: false,
  sortKey: 'role'
},
{
  label: <FormattedMessage {...appMessages.status} />,
  sortable: false,
  isSortable: true,
  sortKey: 'status'
},
{
  label: <FormattedMessage {...appMessages.modified} />,
  sortable: false,
  isSortable: true,
  sortKey: 'updatedAt'
},
{
  label: <FormattedMessage {...appMessages.lastSignIn} />,
  sortable: false,
  isSortable: true,
  sortKey: 'lastLoginAt'
}]

class UserList extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        notFoundIcon={<Users size={42}/>}
        rows={data || []}
        colorClassName={color}
        CheckboxImage={Users}
        isSelectable
        isUserSetting
        isLoading={this.props.isLoading}
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        onDeactivatePlan={this.props.onDeactivatePlan}
        onActivatePlan={this.props.onActivatePlan}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
        onDelete={this.props.onDelete}
        onUpdateStatus={this.props.onUpdateStatus}
      />
    )
  }
}

UserList.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default UserList
