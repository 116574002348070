import React from "react";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'

import { logout } from "../actions/login";
import { routeMapping } from "app/constants/routes";

class Logout extends React.Component {
  componentDidMount() {
    this.props.logout(this.props.history)
    this.props.history.push(routeMapping.LOGIN.path)
  }

  render() {
    return (
      <>
        Logging out...
      </>
    );
  }
}

Logout.propTypes = {};

function mapStateToProps(state) {
  return {
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {logout});

export default withRouter(withConnect(Logout));
