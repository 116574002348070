import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'
import { FilePlus } from "images/common";


const columnList = [{
  label: 'Plan Name',
  isSortable: true,
  isLocked: true,
  sortKey: 'planName'
}, {
  label: <FormattedMessage {...appMessages.status} />,
  sortKey: 'status',
  isSortable: true,
}, {
  label: <FormattedMessage {...appMessages.category} />,
  sortKey: 'category',
  isSortable: false,
}, {
  label: <FormattedMessage {...appMessages.quantity} />,
  sortKey: 'quantity',
  isSortable: false,
}, {
  label: <FormattedMessage {...appMessages.type} />,
  sortKey: 'phaseType',
  isSortable: false,
}, {
  label: 'Start Date',
  sortKey: 'billingStartDate',
  isSortable: false,
}, {
  label: 'Charged Through',
  sortKey: 'chargeThrough',
  isSortable: false,
}, {
  label: 'Cancel Date',
  sortKey: 'cancelDate',
  isSortable: false,
}, {
  label: 'Price',
  sortKey: 'amount',
  isSortable: false,
}
]

class ModuleTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds,
      onChangePlan
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        rows={data || []}
        notFoundIcon={<FilePlus/>}
        colorClassName={color}
        CheckboxImage={FilePlus}
        isSelectable
        onDelete={this.props.onDelete}
        workspace={this.props.workspace}
        withColumnSelect
        plans={this.props.plans}
        onChangePlan={onChangePlan}
        selectedItems={selectedIds}
        onSelect={onSelect}
        hideSingleMenu={this.props.hideSingleMenu}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSortChange}
        rowClick={this.props.onRowClick}
      />
    )
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ModuleTable
