const filterColumns = [{
  id: 1,
  label: 'Status',
  field: 'status',
  type: 'radio'
},
{
  id: 2,
  label: 'User Name',
  field: 'firstName',
  type: 'search',
},
{
  id: 4,
  label: 'Email',
  field: 'email',
  type: 'search'
},
{
  id: 6,
  label: 'Modified at',
  field: 'updatedAt',
  type: 'date'
},
{
  id: 7,
  label: 'Last sign-in',
  field: 'lastLoginAt',
  type: 'date'
}]

export default filterColumns
