import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames'

export const SingleMenu = ({hideParentClass, setDefaultPrice, id, closeMenu, deletePrice, interval, onToogleMenu }) => {

  const parentClass = classNames({
    'actions__dropdown actions__dropdown_visible': !hideParentClass
  });


  const monthlyClass = classNames('actions__option', {
    'actions__option_disabled': interval === 'year'
  });

  const yearlyClass = classNames('actions__option', {
    'actions__option_disabled': interval === 'month'
  });

  const setDefaultMonthly = (e, id, intervalType) => {
    e.stopPropagation()
    onToogleMenu(e, id)
    if(interval === 'month') {
      closeMenu()
      setDefaultPrice(id, intervalType)
    }
  }

  const setDefaultYearly = (e, id, intervalType) => {
    e.stopPropagation()
    onToogleMenu(e, id)

    if(interval === 'year') {
      closeMenu()
      setDefaultPrice(id, intervalType)
    }
  }

  const onDeleteMenu = (e, id) => {
    e.stopPropagation()
    closeMenu()
    deletePrice(e, id)
  }

  return (
    <div className={parentClass}>
      <span
        onClick={(e) => setDefaultMonthly(e, id, 'month')}

        className={monthlyClass}
      >
        Set as Monthly Default
      </span>
      <span
        onClick={(e) => setDefaultYearly(e, id, 'year')}
        className={yearlyClass}
      >
        Set as Yearly Default
      </span>
      <span
        className="actions__option"
        onClick={(e) => onDeleteMenu(e, id)}
      >
        Delete Price
      </span>
    </div>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
