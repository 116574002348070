import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import {SearchFieldIcon, XCircleIcon} from 'images/common'

const DEFAULT_SEARCH_LENGTH = 10

class SearchField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      showInputField: true,
    }
    this.textInput = React.createRef()
  }

  addTagsOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      this.onBlur(e)
    }
  }

  onChange = (e) => {
    this.setState({name: e.target.value})
  }

  onBlur = (e) => {
    if (e.target.value) {
      this.props.onSearch(e.target.value)
      this.setState({showInputField: false, name: e.target.value})
    }
  }

  onReset = () => {
    this.props.onSearch('')
    this.setState({showInputField: true, name: ''}, () => {
      this.textInput.current.value = ''
    })
  }

  componentWillReceiveProps(nextProps) {
    const  {resetSearch} = this.props

    if ((resetSearch !== nextProps.resetSearch) && nextProps.resetSearch) {
      this.onReset()
    }
  }

  onShowInputField = () => {
    this.setState({showInputField: true})
  }

  render() {
    const {name, showInputField} = this.state
    const { customClass, disabled, label } = this.props

    const searchClass = classNames('filters-bar__search filter__search', {
      [`filters-bar__search_${customClass}`]: customClass
    })

    if (showInputField) {
      return (
        <div className={classNames('filters-bar__item', customClass)}>
          <span style={{position: 'relative'}}>
            <input
              type="text"
              id="search-box"
              autoFocus={this.state.name}
              ref={this.textInput}
              disabled={disabled}
              value={name || ''}
              onBlur={this.onBlur}
              onKeyDown={this.addTagsOnEnter}
              placeholder={this.props.label}
              className={searchClass}
              onChange={this.onChange}
              size={name.length > DEFAULT_SEARCH_LENGTH ?
                name.replace(/ /g,'').length :
                DEFAULT_SEARCH_LENGTH}
            />
            <SearchFieldIcon/>
          </span>
        </div>
      )
    }

    return (
      <>
      <div className={classNames('filters-bar__item', customClass)}>
        <button type="button" onClick={this.onShowInputField} className="filters-bar__button">{label}: {name}
          <div className="filters-bar__remove" onClick={this.onReset}>
            <XCircleIcon />
          </div>
        </button>
      </div>
      </>
    )
  }
}

SearchField.propTypes = {
  resetSearch: PropTypes.any,
  disabled: PropTypes.bool,
  onSearch: PropTypes.func,
  customClass: PropTypes.string
}

export default SearchField
