import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import { FilePlus } from "images/common";
import NotFound from "app/components/NotFound";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import Pagination from "app/components/Pagination";
import Modal from "app/components/common/Modal";
import ProcessPage from "app/components/common/ProcessPage";
import ActionsView from "app/components/ActionsView";
import { EnumType } from "json-to-graphql-query";
import SearchField from "app/components/common/SingleSearch";

import Card from "./Card";
import List from "./Table";
import { isSmallerDevice } from "utils/common";

import messages from "utils/message";
import { routeMapping } from "app/constants/routes";
import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ACCENT,
} from "app/constants/common";
import {
  listPlans,
} from "app/actions/plans";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";
import { defaultNotifier } from "functions/notificationHandler";

import ListSelectedItems from "app/components/common/ListSelectedItems";

class PlanList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      selectedIds: [],
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "name",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: DEFAULT_PAGE_SIZE,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.plans,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: DEFAULT_PAGE_SIZE,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.plans);
    let result = finalResult;
    this.setCurrentPage(1);

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.setState({ filterResult: this.props.plans })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const isArchived = query.get("isArchived");

    if (isArchived) {
      this.setState({ showOnlyArchived: true });
    }

    this.getPlans();

    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  getPlans = () => {
    this.props.listPlans();
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  loadMore = () => {
    const { modules } = this.props;

    if (modules.loading) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE }, () =>
      this.getPlans()
    );
  };

  isMore = () => {
    return false;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.PLAN.path}/${id}`);
  };

  redirectToCreatePage = () => {
    return this.props.history.push(routeMapping.CREATE_PLAN.path);
  };

  // pagination
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  renderModal = () => {
    const { body, onConfirm, onCancel, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={onCancel}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={onCancel} />
    );
  };

  onAcceptCondition = e => {
    this.setState({ isAccepted: e.target.checked || !this.state.isAccepted });
  };
  
  onSavePlan = () => {
    this.props.onSelectPlan(this.state.selectedIds)
    this.props.onClose()
  }
  render() {
    const { color, plans } = this.props;

    const { showModal, activeView, selectedIds, resetFilter, currentPage } = this.state;

    const viewHeadlineClass = classNames(
      `actions-nav__icon actions-nav__icon_rows ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === TABLE_VIEW }
    );

    const viewListClass = classNames(
      `actions-nav__icon actions-nav__icon_cards ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === CARD_VIEW }
    );

    const selectedBasePlan = plans.filter(plan => plan.category === 'BASE' && selectedIds.includes(plan.id))

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    // if(this.props.isLoading) {
    //   return <LoadingIndicator
    //   color={this.props.colorClassName}
    // />
    // }

    return (
      <>
      <ProcessPage onCancel={this.props.onClose}>
        {() => (
            <>
          {this.state.showModal && this.renderModal()}
          <div className="popup__cont">
            <div className="login">
              <div className="headline">
                <h1 className="headline__title">
                Set the Workspace Plans
                </h1>
                <h3 className="headline__subtitle">
                Select one base and one or multiple add-on plans
                </h3>
              </div>

              {!hasFilter &&
              !this.props.plans.length &&
              !this.props.isLoading ? (
                <NotFound
                  color={color}
                  strokeWidth="1"
                  logo={<FilePlus size={42} />}
                  title="Create your own plans"
                  description="Add dynamic plans for your workspaces"
                  buttonText="Create first plan"
                  onAction={this.redirectToCreatePage}
                />
              ) : (
                <>
                  <div className="popup__filters-bar filters-bar">
                    <ListSelectedItems
                      selectedNumber={selectedIds.length}
                      search={this.state.searchName}
                      onReset={this.onReset}
                      filterData={this.state.filter}
                      containerClassName={color}
                    />
                    {!this.props.isLoading && (
                      <Filter
                        customClass={color}
                        resetFilter={resetFilter}
                        onChange={this.onFilter}
                        columns={filterColumns}
                        data={this.props.plans}
                        defaultFilter={[
                          {
                            field: "status",
                            value: "active",
                            isDefault: true,
                            readyOnly: true,
                            columns: ["active"],
                          },
                        ]}
                      />
                    )}
                    <SearchField
                      onSearch={this.onSearch}
                      customClass={color}
                      resetSearch={this.state.resetFilter}
                      label="Search"
                    />
                  </div>
                  <div>
                    <InfiniteScroll
                      initialLoad={false}
                      useWindow={false}
                      getScrollParent={() => this.scrollParent}
                    >
                      {activeView === TABLE_VIEW && (
                        <>
                          <List
                            data={
                              hasFilter
                                ?  this.state.filterResult
                                .filter(
                                  (item, index) => {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
      
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
      
                                    return false;
                                  }
                                )
                                : this.props.plans
                                .filter(
                                  (item, index) =>  {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
  
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
  
                                    return false;
                                  }
                                )
                            }
                            selectAll={() => null}
                            onSort={this.onSort}
                            color={color}
                            isLoading={this.props.isLoading}
                            onDeactivatePlan={this.onOpenDeactivatePlan}
                            onActivatePlan={this.onOpenActivatePlan}
                            onDuplicate={this.onDuplicate}
                            sort={this.state.sort}
                            isSelectedAll={this.getIsSelectedAll()}
                            selectedIds={selectedIds}
                            onSelect={this.onSelect}
                            onSetAsDevPlan={this.onSetAsDevPlan}
                            onUpdateStatus={this.onUpdateStatus}
                            onDelete={this.showDeleteModal}
                            showQuota={this.showQuota}
                          />
                        </>
                      )}
                      {activeView === CARD_VIEW && (
                        <div className="data-cards">
                          <Card
                            color={color}
                            data={
                              hasFilter
                                ?  this.state.filterResult
                                .filter(
                                  (item, index) => {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
      
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
      
                                    return false;
                                  }
                                )
                                : this.props.plans
                                .filter(
                                  (item, index) =>  {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
  
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
  
                                    return false;
                                  }
                                )
                            }
                            showQuota={this.showQuota}
                            onSetAsDevPlan={this.onSetAsDevPlan}
                            onDuplicate={this.onDuplicate}
                            selectedItems={selectedIds}
                            onDeactivatePlan={this.onOpenDeactivatePlan}
                            onActivatePlan={this.onOpenActivatePlan}
                            onUpdateStatus={this.onUpdateStatus}
                            onDelete={this.showDeleteModal}
                            onSelect={this.onSelect}
                          />
                        </div>
                      )}
                          {((hasFilter && !!this.state.filterResult.length) ||
                            (!hasFilter && !!this.props.plans
                              .filter(item => item.status === 'active')
                              .length)) && (
                            <Pagination
                              onNext={this.onNext}
                              currentPage={this.state.currentPage}
                              setCurrentPage={this.setCurrentPage}
                              onChangeLimit={this.onChangeLimit}
                              limit={this.state.offset}
                              totalPage={
                                hasFilter
                                  ? this.state.filterResult.length
                                  : this.props.plans.filter(item => item.status === 'active').length
                              }
                            />
                          )}
                    </InfiniteScroll>
                  </div>
                </>
              )}
              </div>
              </div>
              <div className="foot-nav">
                <button
                  className={classNames(
                    `round-button round-button_br round-button_forw round-button_${color}`,
                    {'round-button_disabled': selectedBasePlan.length !== 1 ,
                    }
                  )}
                  disabled={selectedBasePlan.length !== 1}
                  onClick={this.onSavePlan}
                >
                  <span className="round-button__label">
                    <FormattedMessage {...messages.save} />
                  </span>
                </button>
              </div>
            </>
          )}
        </ProcessPage>
        </>
    )
  }
}

PlanList.propTypes = {
  color: PropTypes.string,
  plans: PropTypes.array,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

PlanList.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    plans: state.plans.list,
    isLoading: state.plans.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  listPlans
});

export default compose(withRouter, withConnect)(PlanList);
