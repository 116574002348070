import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, Form } from 'redux-form'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import Input from 'app/components/common/Form/Input'
import InputPassword from 'app/components/common/Form/InputPassword'
import validation from './validation'

class LoginForm extends Component {
  state = {
    password: ''
  }

  redirectToForgetPassword = () => {
    this.props.history.replace('/forgot-password')
  }

  onUpdate = (e) => {
    this.setState({password: e.target.value})
  }

  render() {
    const {password} = this.state
    const {isLoading} = this.props

    return (
      <Form onSubmit={this.props.handleSubmit} className="login__form">
        <Field
          name="email"
          component={Input}
          label="Email"
        />

        <Field
          name="password"
          component={InputPassword}
          label="Password"
          onChange={this.onUpdate}
        />
        <div className="login__actions register__actions">
          <button
            type="submit"
            className={classNames('login__submit button', { 'login_disabled': !this.props.valid || isLoading || !password })}
            disabled={!this.props.valid || isLoading || !password}
          >{isLoading ? 'Logging...' : 'Log in'}</button>
        </div>
      </Form>
    )
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'LOGIN_FORM',
  destroyOnUnmount: true,
  validate: validation,
  
})(withRouter(LoginForm))
