export const workspaces = {
GET_WORKSPACES_PENDING: 'GET_WORKSPACES_PENDING',
GET_WORKSPACES_FULFILLED: 'GET_WORKSPACES_FULFILLED',
GET_WORKSPACES_REJECTED: 'GET_WORKSPACES_REJECTED',

GET_WORKSPACE_INVOICE_PENDING: 'GET_WORKSPACE_INVOICE_PENDING',
GET_WORKSPACE_INVOICE_FULFILLED: 'GET_WORKSPACE_INVOICE_FULFILLED',
GET_WORKSPACE_INVOICE_REJECTED: 'GET_WORKSPACE_INVOICE_REJECTED',

DELETE_WORKSPACES_PENDING: 'DELETE_WORKSPACES_PENDING',
DELETE_WORKSPACES_FULFILLED: 'DELETE_WORKSPACES_FULFILLED',
DELETE_WORKSPACES_REJECTED: 'DELETE_WORKSPACES_REJECTED',

GET_WORKSPACE_DETAILS_PENDING: 'GET_WORKSPACE_DETAILS_PENDING',
GET_WORKSPACE_DETAILS_FULFILLED: 'GET_WORKSPACE_DETAILS_FULFILLED',
GET_WORKSPACE_DETAILS_REJECTED: 'GET_WORKSPACE_DETAILS_REJECTED',

GET_VISO_AGENTS_PENDING: 'GET_VISO_AGENTS_PENDING',
GET_VISO_AGENTS_FULFILLED: 'GET_VISO_AGENTS_FULFILLED',
GET_VISO_AGENTS_REJECTED: 'GET_VISO_AGENTS_REJECTED',
}