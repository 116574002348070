import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import Card from "app/components/common/Detail/Card";

import { getMRR } from "utils/common";

class Metrics extends Component {
  renderContent = () => {
    const { workspace } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__label">Billable Endpoints</div>
          <div className="overview__value">
            {workspace.subscriptionQuantity} Endpoints
          </div>
        </div>
        <div className="grid-col">
          <div className="overview__label">MRR</div>
          <div className="overview__value">
            <NumberFormat
              value={getMRR(
                workspace.subscriptionQuantity,
                workspace.subscriptionData
              )}
              displayType={"text"}
              thousandSeparator
              renderText={(value) => (
                <span>
                  {workspace.subscriptionData.currency &&
                    workspace.subscriptionData.currency.toUpperCase()}{" "}
                  {value}
                </span>
              )}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title="Metrics"
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Metrics.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Metrics;
