import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import Modal from "app/components/common/Modal";
import messages from "utils/message";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { Briefcase } from "react-feather";
import DetailScroll from "app/components/common/Scroll";
import Contact from "./components/Contact";
import Information from "./components/Information";
import Application from "./components/Application";
import PartnerListings from "./components/PartnerListings";
import Overview from "./components/Overview";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import OfferListings from "./components/OfferListings";
import {
  fetchPartner,
  activatePartneAction,
  deactivatePartnerAction,
  approvePartnerAction,
  rejectPartnerAction,
} from "app/actions/partner";
import { routeMapping } from "app/constants/routes";
import PreLoader from "app/components/common/PreLoader/PreLoaderDetail";

const Menu = ({
  onActivate,
  onDeactivate,
  onApprove,
  onReject,
  closeMenu,
  rejectClass,
  deactivateCass,
  approveClass,
  activateClass,
}) => {
  return (
    <>
      <span className={approveClass} onClick={() => onApprove(closeMenu)}>
        Approve Partner
      </span>
      <span className={activateClass} onClick={() => onActivate(closeMenu)}>
        Activate Partner
      </span>
      <span onClick={() => onReject(closeMenu)} className={rejectClass}>
        Reject Partner
      </span>
      <span onClick={() => onDeactivate(closeMenu)} className={deactivateCass}>
        Deactivate Partner
      </span>
    </>
  );
};

class PartnerDetails extends React.Component {
  state = {
    showDropdown: false,
    loggedInUser: this.props.loggedInUser,
    editPopupForm: null,
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.props.fetchPartner({ id });
  }

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onEdit = (formName) => {
    this.setState({ editPopupForm: formName });
  };

  redirectToCategories = () => {
    this.props.history.push(routeMapping.CATEGORY.path);
  };

  onRejectPartner = async () => {
    const { id } = this.props.match.params;

    await this.props.rejectPartnerAction({ id }, () =>
      this.props.fetchPartner({ id })
    );
  };

  onDeactivatePartner = async () => {
    const { id } = this.props.match.params;

    await this.props.deactivatePartnerAction({ id }, () =>
      this.props.fetchPartner({ id })
    );
  };

  onActivatePartner = async () => {
    const id = this.props.partner.id;

    await this.props.activatePartneAction(
      {
        id,
      },
      () => this.props.fetchPartner({ id })
    );
  };

  onApprovePartner = async () => {
    const { id } = this.props.match.params;

    await this.props.approvePartnerAction(
      {
        id,
      },
      () => this.props.fetchPartner({ id })
    );
  };

  onEditClose = () => {
    this.setState({ editPopupForm: null });
  };

  showWorkspaceSubscribePage = () => {
    this.setState({ showSubscribeModal: true });
  };

  hideWorkspaceSubscribePage = () => {
    this.setState({ showSubscribeModal: false });
  };

  onActivate = (closeMenu) => {
    const body = `The partner will be activated.`;
    const title = `Confirm activate partner`;

    const {
      partner: { status },
    } = this.props;

    if (
      status === "rejected" ||
      status === "approved" ||
      status === "pending"
    ) {
      return false;
    }

    closeMenu();

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onActivatePartner(),
    });
  };

  onDeactivate = (closeMenu) => {
    const body = `The partner will be deactivated.`;
    const title = `Confirm deactivate partner`;

    const {
      partner: { status },
    } = this.props;

    if (
      status === "inactive" ||
      status === "rejected" ||
      status === "pending"
    ) {
      return false;
    }

    closeMenu();

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onDeactivatePartner(),
    });
  };

  onApprove = (closeMenu) => {
    const {
      partner: { status },
    } = this.props;

    if (
      status === "approved" ||
      status === "rejected" ||
      status === "inactive"
    ) {
      return false;
    }
    closeMenu();
    const body = `The partner will be approved.`;
    const title = `Confirm approve partner`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onApprovePartner(),
    });
  };

  onReject = (closeMenu) => {
    const {
      partner: { status },
    } = this.props;

    if (
      status === "rejected" ||
      status === "inactive" ||
      status === "approved"
    ) {
      return false;
    }

    closeMenu();

    const body = `The partner will be rejected.`;
    const title = `Confirm reject partner`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onRejectPartner(),
    });
  };

  renderModal = () => {
    const { body, onConfirm, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={title}
        body={body}
        onClose={this.onCloseModal}
      />
    );
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  render() {
    const { color, partner } = this.props;
    const { showModal } = this.state;

    if (!partner) {
      return <DetailScroll />;
    }

    const rejectClass = classNames("actions__option", {
      actions__option_disabled:
        partner.status === "rejected" ||
        partner.status === "approved" ||
        partner.status === "inactive",
      cursor: partner.status !== "rejected",
    });

    const activateClass = classNames("actions__option", {
      actions__option_disabled:
        partner.status === "rejected" ||
        partner.status === "approved" ||
        partner.status === "pending",
      cursor: partner.status !== "approved",
    });

    const deactivateCass = classNames("actions__option", {
      actions__option_disabled:
        partner.status === "inactive" ||
        partner.status === "rejected" ||
        partner.status === "pending",
      cursor: partner.status !== "inactive",
    });

    const approveClass = classNames("actions__option", {
      actions__option_disabled:
        partner.status === "approved" ||
        partner.status === "rejected" ||
        partner.status === "inactive",
      cursor: partner.status !== "approved",
    });

    if (this.props.isLoading) {
      return <PreLoader color={this.props.color} />;
    }

    return (
      <DetailScroll>
        {showModal && this.renderModal()}
        <div className={"messages-bar snackbar-wrapper"}></div>
        <div className="headline">
          <div className={`headline__icon ${color} admin_workspace__image`}>
            <Briefcase />
          </div>
          <h1 className="headline__title">
            {partner.workspace && partner.workspace.name}
          </h1>
        </div>

        <Breadcrumb breadcrumbClass={`breadcrumbs breadcrumbs_${color}`}>
          <BreadcrumbItem route="Partners" />
          <BreadcrumbItem route={partner.workspace && partner.workspace.name} />
        </Breadcrumb>

        <div className="actions-nav">
          <div className="actions">
            <VerticalButtonMenu customClass={color}>
              <Menu
                workspaceCount={partner.workspaceCount}
                onReject={this.onReject}
                onApprove={this.onApprove}
                onActivate={this.onActivate}
                onDeactivate={this.onDeactivate}
                rejectClass={rejectClass}
                activateClass={activateClass}
                deactivateCass={deactivateCass}
                approveClass={approveClass}
              />
            </VerticalButtonMenu>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col grid-col_sm-12">
            <Contact color={color} partner={partner} />
            <Application color={color} partner={partner} />
            <OfferListings color={color} partner={partner} />
          </div>
          <div className="grid-col grid-col_sm-12">
            <Overview color={color} partner={partner} />
            <Information color={color} partner={partner} />
            <PartnerListings color={color} partner={partner} />
          </div>
        </div>
      </DetailScroll>
    );
  }
}

PartnerDetails.propTypes = {
  color: PropTypes.string,
  partner: PropTypes.object,
  loggedInUser: PropTypes.object,
};

PartnerDetails.defaultProps = {
  color: "accent1",
};

function mapStateToProps(state) {
  return {
    partner: state.partner.detail,
    isLoading: state.partner.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  fetchPartner,
  rejectPartnerAction,
  activatePartneAction,
  approvePartnerAction,
  deactivatePartnerAction,
});

export default compose(withRouter, withConnect)(PartnerDetails);
