import React, { Component } from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import ProductList from './productList';

import Card from "app/components/common/Detail/Card";
import { TickIcon } from "images/common";
import { DollarSign } from "react-feather";

import { getInterval } from "utils/common";

export const getCurrencyIcon = {
  usd: <DollarSign />,
};

class Products extends Component {
  state = {
    showEditModal: false,
  };

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  openEditModal = () => {
    this.setState({ showEditModal: true });
  };

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  };

  renderContent = () => {
    const { color, listing } = this.props;

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {[1,2,3,4]
              .filter((item, index) => index < 4)
              .map((price) => (
                <tr className="data-table__row" key={price.id}>
                  <td className="data-table__td data-table__td_1">
                    <div
                      className={`checkbox ${
                        true ? `checkbox_${color}` : "checkbox_label"
                      }`}
                    >
                      {/* {true && (
                        <span
                          className={`data-cards__selected data-cards__selected_item ${color}`}
                        >
                          <TickIcon />
                        </span>
                      )} */}
                      <div className="checkbox__image">
                        {getCurrencyIcon['usd'] ||
                          price.currency.toUpperCase()}
                      </div>
                    </div>
                  </td>

                  <td className="data-table__td">
                    PlanName
                  </td>

                  <td className="data-table__td">
                    Base
                  </td>
                  <td className="data-table__td">
                    Price list
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { showEditModal } = this.state;
    const { color, listing } = this.props;

    if (showEditModal) {
      return <ProductList
      getCurrencyIcon={getCurrencyIcon['usd']}
      data={this.props.listing}
      onClose={this.closeModal}
    />
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        title="Products"
        customText={"View"}
        className="overview"
        action={this.openEditModal}
        content={this.renderContent()}
      />
    );
  }
}

Products.propTypes = {
  user: PropTypes.object,
};

export default Products;
