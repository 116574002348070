import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import {
  Briefcase,
} from "react-feather";

import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'

const columnList = [
  {
    label: 'Viso User',
    isSortable: true,
    isLocked: true,
    sortKey: 'email'
  },
  {
  label: 'User Name',
  isSortable: true,
  isLocked: true,
  sortKey: 'firstName'
},
{
  label: 'Status',
  sortable: false,
  isSortable: true,
  sortKey: 'status'
},
{
  label: <FormattedMessage {...appMessages.workspaces} />,
  sortable: false,
  isSortable: true,
  sortKey: 'workspaces'
},
{
  label: <FormattedMessage {...appMessages.ownWorkspaces} />,
  sortable: false,
  isSortable: true,
  sortKey: 'ownedWorkspaces'
},
{
  label: 'Billing Contact',
  sortable: false,
  isSortable: true,
  sortKey: 'isBillingContact'
},
{
  label: <FormattedMessage {...appMessages.created} />,
  sortable: false,
  isSortable: true,
  sortKey: 'createdAt'
},
{
  label: <FormattedMessage {...appMessages.tags} />,
  sortable: false,
  isSortable: true,
  sortKey: 'tags',
  isOptional: true
},
{
  label: <FormattedMessage {...appMessages.lastSignIn} />,
  sortable: false,
  isSortable: true,
  sortKey: 'lastLogin',
  isOptional: true

}
]

class ListingTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        notFoundIcon={<Briefcase size={42}/>}
        rows={data || []}
        colorClassName={color}
        CheckboxImage={Briefcase}
        isSelectable
        isUserSetting
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        isLoading={this.props.isLoading}
        onDeactivateDev={this.props.onDeactivateDev}
        onActivateDev={this.props.onActivateDev}
        onActivate={this.props.onActivate}
        onDeactivate={this.props.onDeactivate}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
        onUpdateStatus={this.props.onUpdateStatus}
      />
    )
  }
}

ListingTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ListingTable
