import { workspaces } from "../constants/workspaces";
import { billing } from "../constants/billing";

const INITIAL_STATE = {
  list: {
    count: 0,
    data: [],
  },
  isLoading: false,
  agentsLoading: false,
  detail: {},
  agents: [],
  workspaceSubscriptions: [],
  invoices: {
    data: [],
    hasMore: false
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case workspaces.GET_WORKSPACES_PENDING:
      return {
        ...state,
        isLoading: true,
        list: [],
      };
    case workspaces.DELETE_WORKSPACES_PENDING:
      return {
        ...state,
        isLoading: true,
        list: [],
      };
    case workspaces.DELETE_WORKSPACES_FULFILLED:
    case workspaces.DELETE_WORKSPACES_REJECTED:
      return {
        ...state,
        isLoading: false,
      };
    case workspaces.GET_VISO_AGENTS_PENDING:
      return {
        ...state,
        agentsLoading: true,
        agents: [],
      };

    case workspaces.GET_VISO_AGENTS_FULFILLED:
      return {
        ...state,
        agentsLoading: false,
        agents: action.payload,
      };

    case workspaces.GET_VISO_AGENTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        agents: [],
      };

    case workspaces.GET_WORKSPACES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
      };
    case workspaces.GET_WORKSPACES_REJECTED:
      return {
        ...state,
        isLoading: true,
        list: [],
      };
    case workspaces.GET_WORKSPACE_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        detail: {},
      };
    case workspaces.GET_WORKSPACE_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
      };
    case workspaces.GET_WORKSPACE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        detail: {},
      };

    case billing.GET_WORKSPACE_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        workspaceSubscriptions: [],
      };
    case billing.GET_WORKSPACE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        workspaceSubscriptions: action.payload,
      };
    case billing.GET_WORKSPACE_SUBSCRIPTIONS_REJECTED:
      return {
        ...state,
        isLoading: false,
        workspaceSubscriptions: [],
      };
      case workspaces.GET_WORKSPACE_INVOICE_PENDING:
        return {
          ...state,
          isLoading: false,
          invoices: state.invoices,
        };
      case workspaces.GET_WORKSPACE_INVOICE_FULFILLED:
        return {
          ...state,
          isLoading: false,
          invoices: action.payload,
        };
    default:
      return state;
  }
}
