import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";

import { FilePlus } from "images/common";
import Card from "app/components/common/Detail/Card";
import WorkspaceSubscription from "app/components/workspaces/subscription";
import { DEFAULT_ACCENT } from "app/constants/common";
import { dateFormatter, getPriceFormat, snakeCaseToTitleCase } from "utils/common";
import { routeMapping } from "app/constants/routes";

class Subscriptions extends Component {
  state = {
    openModal: false,
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  redirectToSubscription = (id) => {
    this.props.history.push(`${routeMapping.PLAN.path}/${id}`);
  };

  onEditRole = (data) => {
    const { tags } = this.state;
    const { user } = this.props;

    const payload = {
      ...data,
      tags,
      id: user.id,
    };

    this.props.onEdit(payload, this.closeEditModal);
  };

  getStatus = (status) => {
    // const date = moment(subscription.trial_end * 1000).format("D MMM");
    // const isTrialExist = moment(subscription.trial_end * 1000).isAfter(new Date(), "day");

    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": true,
      }
    );

    return <div className={className}>{status}</div>;
  };

  nonTrailStatus = (status, pause_collection) => {
    return (
      <span>
        {status}
        <span>{pause_collection && "(Paused)"}</span>
      </span>
    );
  };

  getDueDate = (subscription) => {
    if (subscription.status === "active") {
      return `due ${dateFormatter(subscription.current_period_end * 1000)}`;
    }

    return ` due ${dateFormatter(subscription.billing_cycle_anchor * 1000)}`;
  };

  renderContent = () => {
    if (!this.props.workspace) {
      return null;
    }
    const {
      workspace: { subscriptions = {} },
      color,
      plans,
    } = this.props;

    const currentSubscription =
      subscriptions && subscriptions;

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {currentSubscription && currentSubscription.map((item) => {
              const namePlan = plans.find(
                (plan) => plan.name === item.planName
              );

              const prices =
                item &&
                item.prices &&
                item.prices
                  .map((item) => item.recurringPrice)
                  .reduce((sum, price) => sum + price);

              return (
                <tr className="data-table__row">
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <FilePlus />
                      </div>
                    </div>
                  </td>
                  <td
                    className={`data-table__td ${color} subscription_name_col`}
                  >
                    {(namePlan && namePlan.prettyName) ||
                      (item && item.planName)}
                  </td>
                  <td className="data-table__td">
                    {item && item.productCategory && snakeCaseToTitleCase(item.productCategory)}
                  </td>
                  <td className="data-table__td">
                    {prices && getPriceFormat(prices)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { workspace, getWorkspaces } = this.props;

    if (this.props.isOpen) {
      return (
        <WorkspaceSubscription
          onClose={this.props.onClose}
          plans={this.props.plans}
          workspace={workspace}
          getWorkspaces={getWorkspaces}
          subscription={workspace.subscriptions ? workspace.subscriptions : []}
        />
      );
    }

    return (
      <Card
        noGrid
        customText={"View"}
        action={this.props.onOpen}
        color={this.props.color}
        title="Assigned Plans"
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

Subscriptions.propTypes = {
  color: PropTypes.string,
};

Subscriptions.defaultProps = {
  color: DEFAULT_ACCENT,
};

export default withRouter(Subscriptions);
