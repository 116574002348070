import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {Range, Handle} from 'rc-slider';
import { DragIcon } from 'images/common';

import messages from '../messages'
import { FILTER_TYPE } from '../constants'
import classNames from 'classnames'

class Search extends React.Component {
  state = {
    filterValue: '',
    value1: 100,
    value2: 5000
  }

  onApply = () => {
    const { filterValue } = this.state
    const { name, onFilterApply } = this.props

    onFilterApply(name, filterValue, FILTER_TYPE.SEARCH)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEnter, false);
  }

  onEnter = (event) => {
    if (event.key === 'Enter') {
      // on Esc button click it should close popup
      this.onApply()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onChange = (name, value) => {
    this.setState({value1: value[0], value2: value[1]})
    this.setState({ filterValue: value })
  }

  onChangeInput1 = (e) => {
    this.setState({value1: e.target.value})
  }

  onChangeInput2 = (e) => {
    this.setState({value2: e.target.value})
  }

  render() {
    const { title, customClass, color, disabled } = this.props
    const customInputClass =
    `range-slider text-field text-field_nolabel text-field_${customClass}`

    const inputClass = classNames({
      'text-field__range': true,
      'text-field__input': true,
      'text-field__input_filled': true,
    })

    const handleSliderClass = classNames('slider-icon', {
      [color]: color,
      disabled: disabled
    })

    return (
      <div className="text-field__range_container">
        <div className="filters-bar__heading">{title}</div>
        <div className="filters-bar__range">
          <input
            type={'text'}
            maxLength={4}
            value={this.state.value1}
            className={inputClass}
            onChange={this.onChangeInput1}
          />
          <div>-</div>
          <input
            type={'text'}
            maxLength={4}
            value={this.state.value2}
            className={inputClass}
            onChange={this.onChangeInput2}
          />
        </div>
        <div className={customInputClass}>
          <Range
            min={100}
            max={500}
            handle={(handleProps) => {
              return (
                <Handle { ...handleProps }>
                  <div className={handleSliderClass}><DragIcon/></div>
                </Handle>
              )
            }}
            onChange={(val) => this.onChange(val)}
            value={[this.state.value1, this.state.value2]}
            defaultValue={[this.state.value1, this.state.value2]}
            tipFormatter={value => `$ ${value}`}
          />
        </div>
        <hr className="mt_100"/>
        <button type="button" className={`filters-bar__apply ${customClass}`} onClick={this.onApply}>
          <FormattedMessage {...messages.apply} />
        </button>
      </div>
    )
  }
}

Search.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  onFilterApply: PropTypes.func,
  customClass: PropTypes.string
}

export default Search
