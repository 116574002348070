import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import EditInformation from "../Edit/Information";

class Information extends Component {
  state = {
    openEditInformationModal: false,
  };

  onEdit = () => {
    this.setState({ openEditInformationModal: true });
  };

  onCloseModal = () => {
    this.setState({ openEditInformationModal: false });
  };

  onRedirectToCategory = (id) => {
    this.props.history.push(`/category/${id}`);
  };

  renderContent = () => {
    const { category, color } = this.props;

    return (
      <>
        <div className="grid-col grid-col_6">
          <div className="overview__title" />
          <div className="overview__label">Category Name</div>
          <div className="overview__value">{category.name}</div>
          <div className="overview__label">Parent</div>
          <div className="tags tags_accent3">
            {category.parents &&
              category.parents.map((tag) => (
                <div
                  onClick={(e) => this.onRedirectToCategory(tag.id, e)}
                  className="tags__item category-tags"
                  key={tag}
                >
                  {tag.name}
                </div>
              ))}
          </div>
          <div className="overview__label">Category Description</div>
          <div className="overview__value">{category.description}</div>
        </div>
        <div className="grid-col grid-col_5">
          <div className="overview__title" />
          <div className="overview__label">Cateogry Slug</div>
          <div className="overview__value">{category.slug}</div>
        </div>
      </>
    );
  };

  render() {
    const { openEditInformationModal } = this.state;

    if (openEditInformationModal) {
      return (
        <EditInformation
          category={this.props.category}
          color={this.props.color}
          onClose={this.onCloseModal}
        />
      );
    }

    return (
      <Card
        color={this.props.color}
        fullWidth
        title={<FormattedMessage {...messages.information} />}
        className="overview"
        onEdit={this.onEdit}
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default withRouter(Information);
