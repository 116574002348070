import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FormattedMessage } from "react-intl";
import {isArrayEqual} from 'utils/common';
import ProcessPage from "app/components/common/ProcessPage";
import CustomInput from "app/components/common/Input/CustomInput";
import CustomTextarea from "app/components/common/Input/CustomTextarea";
import CustomMultiSelect from 'app/components/common/Input/CustomMultiSelect'

import appMessages from "utils/message";

import EditForm from "app/components/common/Edit/EditForm";
import { updateCategoryAction, listCategory } from "app/actions/category";

class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      slug: "",
      id: "",
      isChanged: false,
      page: "first",
      tags: props.category.tags || [],
      description: props.category.description || "",
    };
  }

  componentDidMount() {
    const { category } = this.props;
    
    this.setState({
      name: category.name,
      slug: category.slug,
      description: category.description,
      parentId: category.parents && category.parents.map(item => item.id),
    });
  }

  onBack = () => {
    this.props.onClose();
  };

  onChangeField = (name, value) => {
    this.setState({ [name]: value });
  };

  onUpdate = (id) => {
    this.setState({parentId: id})
  }

  renderContent = () => {
    const { color, categories=[], category } = this.props;
    const { name, description, slug } = this.state;

    let options = categories.filter((item) => !item.parents.length);
    options = options.map((parent) => ({
      ...parent,
      label: parent.name,
      value: parent.id,
      children: categories.filter((item) =>
        item.parents
          .find((i) => i.id === parent.id)
      )
      .map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
    }));

    const allOptions = categories.map(item => ({label: item.name, value: item.id}));

    return (
      <>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={name}
            onChange={(e) => this.onChangeField("name", e.target.value)}
            label={<FormattedMessage {...appMessages.categoryName} />}
            isInvalid={!name}
            postIconClass="success"
            hintText={name ? "" : "Name is required"}
          />
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={slug}
            disabled
            readOnly
            label={<FormattedMessage {...appMessages.categorySlug} />}
            isInvalid={!slug}
            postIconClass="success"
          />
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomTextarea
            value={description}
            onChange={(e) => this.onChangeField("description", e.target.value)}
            label={<FormattedMessage {...appMessages.categoryDescription} />}
            type="textarea"
          />
        </div>
        <div className={`select-field select-field_${color}`}>
          <CustomMultiSelect
            editable
            allOptions={allOptions}
            value={this.state.parentId}
            onClear={() => this.setState({parentId: null})}
            label="Parent Category"
            onChange={this.onUpdate}
            options={options}
            parentClass="select-field accent2"
          />
        </div>
      </>
    );
  };

  onEdit = async () => {
    const {category} = this.props
    const { name, description, parentId } = this.state;

    let payload = {
      id: category.id,
      name,
      description,
    };

    if(parentId) {
      payload = {
        id: category.id,
        name,
        parentIds: parentId,
        description,
      }
    }
    try {
      await this.props.updateCategoryAction(payload);
      this.props.onClose();
    } catch (err) {}
    this.props.onClose();
  };

  render() {
    const { category, color } = this.props;

    const { name, description, parentId } = this.state;
    const hasChange =
      category.name !== name ||
      category.description !== description ||
      (!isArrayEqual(category.parents.map(item => item.id), parentId))

    
    const submitClass = classNames(`button button_${color}`, {
      button_disabled: !hasChange,
    });

    return (
      <ProcessPage
        onCancel={this.props.onClose}
        isChanged={hasChange}
        showUnsavedWarning
      >
        {() => (
          <EditForm
            color={this.props.color}
            submitLabel={<FormattedMessage {...appMessages.continue} />}
            submitClassName={submitClass}
            onSubmit={this.onEdit}
            content={this.renderContent()}
            disabled={!(name && description)}
            title="Category Information"
            subTitle={<>{category.name}</>}
          />
        )}
      </ProcessPage>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.category.list,
    isLoading: state.category.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  updateCategoryAction,
  listCategory,
});

Information.propTypes = {
  color: PropTypes.string,
  plan: PropTypes.object,
  onClose: PropTypes.func,
  history: PropTypes.object,
  updateapplicationError: PropTypes.func,
  updateApplicationRequest: PropTypes.func,
};

export default withConnect(withRouter(Information));
