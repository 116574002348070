import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { Scrollbars } from 'react-custom-scrollbars'

import {SearchIcon} from 'images/common'
import { FormattedMessage } from 'react-intl'

import messages from 'utils/message'

const SelectRow = ({...props}) => {
  const {row, disabled, hide} = props
  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!row.disabled) {
      props.onItemClick(props.row.value)
    }
  }

  return (
    hide
      ? <></>
      : <div
        className={classNames('select-field__option', {
          'select-field__option_disable': disabled
        })}
        onClick={disabled
          ? (e) => {
            e.stopPropagation();
            e.preventDefault();
          }
          : onClick}
      >
        {row.label}
      </div>
  )
}

export default class CustomSelect extends React.PureComponent {
  constructor(props) {
    super(props)

    this._isMounted = false
    this.state = {
      isFocused: false,
      options: props.options || [],
      value: props.value || ''
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.options || [],
      value: nextProps.value || ''
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onFocus = () => {
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({
          isFocused: true
        })
      }
    }, 200)
  }

  onBlur = () => {
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({
          isFocused: false
        })
      }
    }, 200)
  }

  selectOption = (selectedValue, selectedLabel) => {
    if (this.state.value !== selectedValue) {
      this.setState({
        value: selectedValue,
        label: selectedLabel
      }, () => {
        this.props.onChange(selectedValue)
      })
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
      label: e.target.value
    })
    this.props.onChange(e.target.value)
  }

  isSearched = (item, searchTerm) => {
    return item.label && item.label
      .toLowerCase()
      .includes(searchTerm && searchTerm.toLowerCase())
  }

  isFiltered = (item, searchTerm) => {
    return item.value && item.value
      .toLowerCase()
      .includes(searchTerm && searchTerm.toLowerCase()) ||
      item.label && item.label
        .toLowerCase()
        .includes(searchTerm && searchTerm.toLowerCase())
  }

  render() {
    const { options, value } = this.state
    const { label, disabled, editable, searchIcon, color } = this.props
    const inputClass = classNames({
      'select-field__input': true,
      'no_bg': searchIcon,
      'select-field__input_filled': !!value,
      'select-field__input_focused': (this.state.isFocused && !this.props.readOnly)
    });

    const parentCustomClass = classNames('select-field', {
      [`select-field_${color}`]: color,
    })


    const option = options.find(row => row.value === value)
    const optionName = _.get(option, 'label', '')

    const hasNoResult = options
      .filter(image => this.isFiltered(image, value))

    return (
      <div className={parentCustomClass}>
        <input
          disabled={disabled}
          type="text"
          readOnly={!editable}
          onChange={this.onChange}
          value={editable && this.state.label ? this.state.label : optionName}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          className={inputClass}
          style={{ opacity: disabled ? 0.5 : 1 }}
        />
        <label className="select-field__label">{label}</label>
        {searchIcon && <div className="text-field__icon"><SearchIcon/></div>}
        <div className="select-field__dropdown">
          <Scrollbars
            autoHeight
            className="custom-scrollbars"
            autoHeightMax={214}
            universal
          >
            {!hasNoResult.length && !value &&
              <div
                className="select-field__option">
                <FormattedMessage {...messages.noDataAvailable}/>
              </div>
            }
            {editable ?
              options
                .filter(image => this.isSearched(image, value))
                .map(row =>
                  <SelectRow
                    key={row.value}
                    row={row}
                    onItemClick={() => this.selectOption(row.value, row.label)}
                    disabled={row.disabled || false}
                    hide={row.hide || false}
                  >
                    {row.label}
                  </SelectRow>
                )
              :
              options
                .map(row =>
                  <SelectRow
                    key={row.value}
                    row={row}
                    onItemClick={() => this.selectOption(row.value, row.label)}
                    disabled={row.disabled || false}
                    hide={row.hide || false}
                  >
                    {row.label}
                  </SelectRow>
                )
            }
          </Scrollbars>
        </div>
        <div className="text-field__underscore"></div>
      </div>
    )
  }
}
