import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'

export const CARD_VIEW = 'card'
export const TABLE_VIEW = 'list'

class ActionsView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showActions: false
    }
  }

  hideActions = () => {
    this.setState({ showActions: false })
  }

  showActions = () => {
    this.setState({ showActions: true })
  }

  render() {
    return (
      <div className={classNames('actions-nav', this.props.containerClassName)}>
        { this.props.children &&
          <div className='actions'>
            <OutsideClickHandler onOutsideClick={this.hideActions}>
              <button
                type='button'
                className={classNames(
                  'actions__button', this.props.colorClassName,
                  { 'actions__button_active': this.state.showActions }
                )}
                onClick={this.showActions}
              />
              <div
                className={classNames('actions__dropdown', {
                  actions__dropdown_visible: this.state.showActions
                })}
              >
                { this.props.children }
              </div>
            </OutsideClickHandler>
          </div>
        }
        <span
          className={classNames('actions-nav__icon', 'actions-nav__icon_rows cursor', this.props.colorClassName,
            { 'actions-nav__icon_active': this.props.activeView === TABLE_VIEW }
          )}
          onClick={() => this.props.updateView(TABLE_VIEW)}>
        </span>
        <span
          className={classNames('actions-nav__icon', 'actions-nav__icon_cards cursor', this.props.colorClassName,
            { 'actions-nav__icon_active': this.props.activeView === CARD_VIEW }
          )}
          onClick={() => this.props.updateView(CARD_VIEW)}>
        </span>
        { this.props.actionLabel &&
          <span
            onClick={this.props.onActionClick}
            className={classNames('actions-nav__link text-link cursor', this.props.colorClassName)}
          >
            { this.props.actionLabel }
          </span>
        }
      </div>
    )
  }
}

ActionsView.propTypes = {
  onActionClick: PropTypes.func,
  colorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  activeView: PropTypes.string,
  actionLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  updateView: PropTypes.func.isRequired,
  children: PropTypes.node
}

export default ActionsView
