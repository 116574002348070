import {model} from '../constants/model';

const INITIAL_STATE = {
  list: [],
  isLoading: false,
  detailsop: {},
  file: {
    url: '',
    uploading: false
  }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case model.GET_MODELS_PENDING:
      return {
        ...state,
        isLoading: true,
        list: []
      };
    case model.GET_MODELS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        list: action.payload
      };
    case model.GET_MODELS_REJECTED:
      return {
        ...state,
        isLoading: true,
        list: []
      };
      case model.GET_MODEL_DETAILS_PENDING:
        return {
          ...state,
          isLoading: true,
          details: {}
        };
      case model.GET_MODEL_DETAILS_FULFILLED:
        return {
          ...state,
          isLoading: false,
          details: action.payload
        };
      case model.GET_MODEL_DETAILS_REJECTED:
        return {
          ...state,
          isLoading: false,
          details: {}
        };
        case model.REMOVE_FILE:
          return {
            ...state,
            file: {
              uploading: false,
              url: ''
            }
          };
        case model.UPLOAD_FILE_REQUEST:
          return {
            ...state,
            file: {
              uploading: true,
              url: ''
            }
          };
          case model.ADD_FILE:
            return {
              ...state,
              file: {
                uploading: false,
                url: action.payload
              }
            };
    default:
      return state;
  }
}