import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'
import {HistoryIcon} from 'images/common'

const columnList = [{
  label: <FormattedMessage {...appMessages.date} />,
  isSortable: true,
  isLocked: true,
  sortKey: 'name'
}, {
  label: <FormattedMessage {...appMessages.invoiceNumber} />,
  sortKey: 'invoice'
}, {
  label: <FormattedMessage {...appMessages.billingPeriod} />,
  sortable: false,
  isSortable: true,
  sortKey: 'billingPeriod'
},
{
  label: <FormattedMessage {...appMessages.amount} />,
  sortable: false,
  isSortable: true,
  sortKey: 'amount'
},
{
  label: <FormattedMessage {...appMessages.actions} />,
  sortable: false,
  isSortable: true,
  sortKey: 'actions'
}]

class BillingTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        rows={data}
        colorClassName={color}
        CheckboxImage={HistoryIcon}
        isSelectable
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
      />
    )
  }
}

BillingTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default BillingTable
