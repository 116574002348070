import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {Codesandbox} from 'react-feather'

import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'

const columnList = [{
  label: 'Device Type',
  isSortable: true,
  isLocked: true,
  sortKey: 'deviceType'
}, {
  label: 'Architecture',
  sortKey: 'architecture'
},
{
  label: 'VA Version',
  sortable: false,
  isSortable: true,
  sortKey: 'visoAgentVersion'
},
{
  label: 'OS Version',
  sortable: false,
  isSortable: true,
  sortKey: 'osVersion'
},
{
  label: 'Device Count',
  sortable: false,
  isSortable: true,
  sortKey: 'deviceCount'
},
{
  label: 'Build Status',
  sortable: false,
  isSortable: true,
  sortKey: 'buildStatus'
},
{
  label: 'Last Build time',
  sortable: false,
  isSortable: true,
  sortKey: 'updatedAt'
},
{
  label: <FormattedMessage {...appMessages.created} />,
  sortable: false,
  isSortable: true,
  sortKey: 'createdAt',
  isOptional: true
}]

class ModuleTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        notFoundIcon={<Codesandbox size={42}/>}
        rows={data || []}
        colorClassName={color}
        CheckboxImage={Codesandbox}
        isSelectable
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        updateToLatestVersion={this.props.updateToLatestVersion}
        isLoading={this.props.isLoading}
        onDeactivatePlan={this.props.onDeactivatePlan}
        onActivatePlan={this.props.onActivatePlan}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
        onDelete={this.props.onDelete}
        onUpdateStatus={this.props.onUpdateStatus}
      />
    )
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ModuleTable
