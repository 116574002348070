import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import Menu from "./SingleMenu";
import SmallCard from "../../common/SmallCard";
import { WorkspaceIcon } from "images/common";

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getStatus = (status) => {
    const className = classNames("data-cards__status text-capitalize", {
      success: status === "active",
      failure: status === "inactive",
      neutral: status === "deleted",
    });

    return <i className={className}>•</i>;
  };

  onRedirectToPartner = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    if(id) {
      return this.props.history.push(`/partner/${id}`)
    }
  }

  getSubheading = (status, createdBy) => {
    return (
      <>
        {this.getStatus(status)} {status} •{' '}
        <span className={`text-link`}>
          {createdBy.name}
        </span>
      </>
    );
  };

  render() {
    const {
      color,
      data,
      onSelect,
      detailRole = {},
      selectedItems,
    } = this.props;

    return (
      <div className="grid-row">
        {data.map((workspace) => (
          <SmallCard
            key={workspace.id}
            heading={workspace.name}
            id={workspace.id}
            isLoading={this.props.isLoading}
            onCardClick={this.props.onRowClick}
            colorClassName={color}
            subheading={this.getSubheading(workspace.status, workspace.createdBy)}
            selectedItems={selectedItems}
            onSelect={onSelect}
            CheckboxImage={WorkspaceIcon}
          >
            <Menu
              detailRole={detailRole.role}
              id={workspace.id}
              annotationEnabled={workspace.annotationEnabled}
              trainingEnabled={workspace.trainingEnabled}
              onManageAgent={this.props.onManageAgent}
              deleteDate={workspace.deleteDate}
              archived={workspace.archived}
              status={workspace.status}
              onCancelSchedule={this.props.onCancelSchedule}
              isActive={workspace.isActive}
              onUpdate={this.props.onUpdate}
              onDelete={this.props.onDelete}
              onScheduleDelete={this.props.onScheduleDelete}
              onArchive={this.props.onArchive}
              onDeactivate={this.props.onDeactivate}
              onActivate={this.props.onActivate}
              onActivateAnnotation={this.props.onActivateAnnotation}
              onDeactivateAnnotation={this.props.onDeactivateAnnotation}
              onActivateTraining={this.props.onActivateTraining}
              onDeactivateTraining={this.props.onDeactivateTraining}
            />
          </SmallCard>
        ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
