import { listing } from "../constants/listing";

const INITIAL_STATE = {
  list: [],
  isLoading: false,
  dashboards: [],
  detail: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case listing.GET_LISTING_PENDING:
      return {
        ...state,
        isLoading: true,
        list: [],
      };
    case listing.GET_LISTING_FULFILLED:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
      };
    case listing.GET_LISTING_REJECTED:
      return {
        ...state,
        isLoading: true,
        list: [],
      };
    case listing.GET_LISTING_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        detail: {},
      };
    case listing.GET_LISTING_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
      };
    case listing.GET_LISTING_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        detail: {},
      };
      case listing.GET_DASHBOARD_PENDING:
        return {
          ...state,
          isLoading: true,
          dashboards: [],
        };
      case listing.GET_DASHBOARD_FULFILLED:
        return {
          ...state,
          isLoading: false,
          dashboards: action.payload,
        };
      case listing.GET_DASHBOARD_REJECTED:
        return {
          ...state,
          isLoading: false,
          dashboards: [],
        };
    default:
      return state;
  }
}
