import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {ModelIcon} from 'images/common';


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'

const columnList = [{
  label: 'Model Name',
  sortKey: 'name',
  isLocked: true,
  isSortable: true,
}, {
  label: <FormattedMessage {...appMessages.description} />,
  sortKey: 'description',
  isSortable: true,
}, {
  label: 'Framework',
  isSortable: true,
  sortKey: 'framework'
}, {
  label: <FormattedMessage {...appMessages.type} />,
  isSortable: true,
  sortKey: 'modelType'
}, {
  label: 'Dataset',
  isSortable: true,
  sortKey: 'dataset'
},
{
  label: 'Tags',
  isSortable: true,
  isOptional: true,
  sortKey: 'tags'
},
{
  label: 'Categories',
  isSortable: true,
  isOptional: true,
  sortKey: 'categories'
},
{
  label: 'Device Types',
  isSortable: true,
  isOptional: true,
  sortKey: 'deviceTypes'
}
]

class CategoryTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        notFoundIcon={<ModelIcon size={42}/>}
        rows={data || []}
        colorClassName={color}
        CheckboxImage={ModelIcon}
        isSelectable
        withColumnSelect
        onDelete={this.props.onDelete}
        selectedItems={selectedIds}
        onSelect={onSelect}
        isLoading={this.props.isLoading}
        onDeactivate={this.props.onDeactivate}
        onActivate={this.props.onActivate}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
        onDelete={this.props.onDelete}
        onUpdateStatus={this.props.onUpdateStatus}
      />
    )
  }
}

CategoryTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default CategoryTable
