import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CardToggle from 'app/components/common/Detail/CardToggle';

class Information extends Component {
  state = {
    isOpen: false
  }

  renderContent = () => {
    const {model} = this.props;

    return (
      <div className="grid-col">
        <div className='description-label mt'>
        {model && model.labels && model.labels
            .filter(label => label)
            .map(label => ` ${label}`)
            .toString()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <CardToggle
        color={this.props.color}
        title={'Model Labels'}
        subTitle='Labels that the model is able to handle.'
        customText='View all'
        isLoading={this.props.isLoading}
        className="overview"
        onEdit={this.props.onEdit}
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  flow: PropTypes.object
}

export default Information
