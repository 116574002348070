import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ChevronDown } from 'react-feather';

import Button from 'app/components/Button';

class Card extends Component {
  state = {
    isOpen: false
  }

  render() {
    const {noGrid, color} = this.props

    return (
      <div className={classnames('content-box', this.props.className, {
        'card_loading': this.props.isLoading
      })}>
        {
          this.props.customText &&
            <Button
              className={classnames('overview__edit overview-card__toggle', `overview__edit_${this.props.color}`,
                {
                  [this.props.color]: this.props.color,
                  [`overview-card__toggle_${color}`]: this.state.isOpen
                })}
              onClick={() => this.setState({isOpen: !this.state.isOpen})}
            >
              {this.props.customText}
              <ChevronDown size={40}/>
            </Button>
        }
        <div className="overview__title">
          { this.props.title }
        </div>
        {this.props.subTitle &&
        <div className="overview__subtitle">{this.props.subTitle}</div>
        }
        {noGrid && this.props.content}
        {!noGrid && <div className={classnames('grid-row', {
          'card-toggle-open': this.state.isOpen,
          'card-toggle-close': !this.state.isOpen
        })}>
          { this.props.content }
        </div>
        }
      </div>

    )
  }
}

Card.propTypes = {
  color: PropTypes.string,
  subTitle: PropTypes.any,
  customText: PropTypes.any,
  action: PropTypes.any,
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  color: 'accent0',
  className: 'overview'
};

export default Card
