import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SmallCard from "../../common/SmallCard";
import {FilePlus} from 'images/common'
import { getInitials } from 'utils/common';

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getStatus = (status) => {
    const className = classNames("data-cards__status c-text text-capitalize success", {
      success: status === 'active',
      failure: status === 'inactive'
    });

    return <i className={className}>•</i>;
  };

  getSubheading = (status) => {
    return <>
      {this.getStatus(status)} Active • Administrator
    </>
  }
  

  render() {
    const {
      color,
      data,
      onSelect,
      selectedItems,
    } = this.props;

    return (
      <div className="grid-row">
        {data.map((item) => (
          <SmallCard
          key={item.id}
          heading={`${item.firstName || ''} ${item.lastName || ''}`}
            id={item.id}
            initials={getInitials(item.firstName, item.lastName)}
            onCardClick={this.props.onRowClick}
            colorClassName={color}
            subheading={this.getSubheading(
              item.status,
              item.productId
            )}
            selectedItems={selectedItems}
            onSelect={onSelect}
            CheckboxImage={FilePlus}
          >
          </SmallCard>
        ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
