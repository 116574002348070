import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

import Menu from "./SingleMenu";
import { dateFormatter, getInvoice, getPaymentFormat } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { status } = this.props;

    const className = classNames("data-table__status type__value", {
      "data-table__status_pos": status === "active",
      "data-table__status_neg": status === "inactive",
      "data-table__status_neutral": status === "deleted",
    });
    return (
      <div className={className}>
        <span className="text-capitalize">{status}</span>
      </div>
    );
  };

  onRedirectToPartner = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    if (id) {
      return this.props.history.push(`/partner/${id}`);
    }
  };

  getPartner = () => {
    const { partner = {} } = this.props;

    if (partner && partner.id && partner.status === "approved") {
      return (
        <span
          className="cursor accent0"
          onClick={(e) => this.onRedirectToPartner(e, partner.id)}
        >
          Partner
        </span>
      );
    }
    return (
      <span onClick={(e) => this.onRedirectToPartner(e)}>
        {(partner && partner.status) || "Inactive"}
      </span>
    );
  };

  getPaymentMethod = (id) => {
    if (id === 1) {
      return "Bank";
    }

    if (id === 2) {
      return "Cash";
    }

    if (id === 3) {
      return "Credit Card";
    }

    if (id === 4) {
      return "Online Payment";
    }
  };

  onRedirectToWs = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.history.push(`/workspace/${id}`);
  };

  onRedirectToInvoice = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.history.push(`/invoices/${id}`);
  };

  getRowRender = () => {
    const {
      createdAt,
      name,
      amount,
      currency,
      workspace,
      invoiceNumber,
      paymentMethod,
      paymentNumber,
      paymentDate,
      colorClassName,
      createdBy,
      invoiceId,
      currentSortKey,
    } = this.props;

    const nameClass = classNames({
      "data-table__td_dark": currentSortKey === "paymentNumber",
    });

    const amountClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "amount",
    });

    const paymentMethodClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "paymentMethod",
    });

    const workspaceClass = classNames("type__value", {
      [colorClassName]: colorClassName,
      "data-table__td_dark": currentSortKey === "workspace",
    });

    const invoiceNumberClass = classNames("type__value", {
      [colorClassName]: colorClassName,
      "data-table__td_dark": currentSortKey === "invoiceNumber",
    });

    const createdAtClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "createdAt",
    });

    const paymentDateClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "paymentDate",
    });

    return {
      paymentNumber: () => <div className={nameClass}>{getPaymentFormat(paymentNumber)}</div>,
      amount: () => (
        <span className={amountClass}>
          <NumberFormat
            value={amount.toFixed(2)}
            displayType={"text"}
            thousandSeparator
            renderText={(value) => (
              <span>
                {currency} {value}
              </span>
            )}
          />
        </span>
      ),
      workspace: () => (
        <span
          className={workspaceClass}
          onClick={(e) => this.onRedirectToWs(workspace.id, e)}
        >
          {workspace && workspace.name}
        </span>
      ),
      paymentMethod: () => (
        <span className={paymentMethodClass}>
          {this.getPaymentMethod(paymentMethod || 1)}
        </span>
      ),
      invoiceNumber: () => (
        <span 
        onClick={(e) => this.onRedirectToInvoice(invoiceId, e)}
        className={invoiceNumberClass}>{getInvoice(invoiceNumber)}</span>
      ),
      paymentDate: () => (
        <span className={paymentDateClass}>
          {paymentDate && dateFormatter(paymentDate, true)}
        </span>
      ),
      createdAt: () => (
        <span className={createdAtClass}>{dateFormatter(createdAt, true)}</span>
      ),
    };
  };

  render() {
    const { colorClassName } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        ></SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
