export const CARD_VIEW = 'card'
export const TABLE_VIEW = 'list'
export const DEFAULT_PAGE_SIZE = 10

export const BASE_URL = `${process.env.API_URL}/svc-admin/graphql`;

export const DEFAULT_ACCENT = 'accent0'
export const PRIMARY_ACCENT = 'primary'
export const LIBRARY_ACCENT = 'accent3'
export const SOLUTION_ACCENT = 'accent4'
export const PARTNER_ACCENT = 'accent5'
export const DASHBOARD_ACCENT_COLOR = 'accent2'

export const INTERVAL = [
  {
    label: 'Monthly',
    value: 'month',
  },
  {
    label: 'Yearly',
    value: 'year',
  }
];
  
export const CURRENCY = [
  {
    label: "USD - US Dollar",
    value: "usd",
  }
];

export const NET_OPTIONS = [
  {
    label: "Net 14 days",
    value: "14",
  },
  {
    label: "Net 21 days",
    value: "21",
  },
  {
    label: "Net 30 days",
    value: "30",
  },
  {
    label: "Net 40 days",
    value: "40",
  },
  {
    label: "Net 50 days",
    value: "50",
  },
  {
    label: "Net 60 days",
    value: "60",
  },
  {
    label: "Net 90 days",
    value: "90",
  },
];
