import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import {XCircleIcon, PlusIcon} from 'images/common'

const DEFAULT_TAG_LENGTH = 7

class Tags extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tag: '',
      placeholder: 'Add a tag',
      showPlusIcon: true,
      tags: props.tags || []
    }
    this.textInput = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tags !== nextProps.tags) {
      this.setState({tags: nextProps.tags || []})
    }
  }

  onRemove = (selectedTag) => {
    const tags = this.state.tags.filter(tag => tag !== selectedTag)

    this.setState({tags})
    this.props.onChange(tags)
  }

  onUpdateTags = (selectedTags) => {
    this.setState({ tags: selectedTags, tag: '' })
    this.props.onChange(selectedTags)
  }

  onUpdate = (e) => {
    this.setState({tag: e.target.value})
  }

  addTagsOnEnter = (e) => {
    const {tags, tag} = this.state


    if (e.key === 'Enter' && tag && !tags.includes(tag)) {
      e.stopPropagation()
      e.preventDefault()
      const selectedTags = [...this.state.tags, tag]
      this.textInput.current.value = ''

      this.onUpdateTags(selectedTags)
    }
  }

  addTag = () => {
    const {tags, tag} = this.state

    if (tag
      && !tags.includes(tag)) {
      const selectedTags = [...this.state.tags, tag]

      this.onUpdateTags(selectedTags)
    }
  }

  onFocus = () => {
    this.setState({showPlusIcon: false, placeholder: ''})
  }

  onBlur = () => {
    const {name} = this.props
    const {tag, tags} = this.state

    if (tag
      && !tags.includes(tag)) {
      const selectedTags = [...this.state.tags, tag]
      this.textInput.current.value = ''

      this.onUpdateTags(selectedTags)
    }
    this.setState({showPlusIcon: true, placeholder: (name && `Add a ${name}`) || 'Add a tag'})
  }

  render() {
    const {tagClass, name} = this.props

    const {tags, showPlusIcon, tag, placeholder} = this.state
    const customClass = `tags_${tagClass}`
    const tagClassName = classNames('tags', {
      [customClass]: tagClass
    })

    const inputClassName = classNames('tags__input', {
      'tags__input_focused': !showPlusIcon
    })
    return (
    <>
    <label className="login__label">{name || 'Tags'}</label>
    <div className={tagClassName}>
      {!!tags.length && tags.map(item =>
        <div className="tags__item" key={item}>
          {item}
          <button type="button" className="tags__remove" onClick={() => this.onRemove(item)}>
            <XCircleIcon/>
          </button>
        </div>
      )}
      <div className="tags__add">
        <input type="text"
          placeholder={placeholder}
          className={inputClassName}
          onChange={this.onUpdate}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.addTagsOnEnter}
          ref={this.textInput}
          size={tag.length + 1 > DEFAULT_TAG_LENGTH ?
            tag.length :
            DEFAULT_TAG_LENGTH}
        />
        {this.state.showPlusIcon &&
        <span onClick={this.addTag}>
          <PlusIcon/>
        </span>
        }
      </div>
    </div>
    </>
    )
  }
}

Tags.propTypes = {
  tags: PropTypes.array,
  onChange: PropTypes.func,
  tagClass: PropTypes.string
}

export default Tags
