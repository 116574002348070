import React, { Component } from 'react'
import classNames from 'classnames'

import CustomInput from 'app/components/common/Input/CustomInput'

import ProcessPage from 'app/components/common/ProcessPage'
import LoadingIndicator from 'app/components/common/LoadingIndicator'

class RemovedWorkspace extends Component {
  state = {
    name: ''
  }

  onSubmit = () => {
    this.props.onDelete()
  }

  onChange = (e) => {
    this.setState({name: e.target.value})
  }

  render() {
    const {workspace, isLoading} = this.props

    if(isLoading){
    return <LoadingIndicator/>
    }

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {() => (
          <div className="popup__cont">
            <div className="login">
              <div className="headline">
                <h1 className="headline__title">
                Delete Workspace
                </h1>
                <h3 className="headline__subtitle">
                  {workspace && (workspace.name || workspace.subDomain)}
                </h3>

                <div className="workspace-delete workspace-delete_header pull-left">
                  <div className="title mx-b_10">Confirm remove workspace</div>
                  <p className="description">
                  The workspace will be deleted. All personal data will be permanently deleted.
                  </p>
                </div>
                <div className="workspace-delete pull-left workspace-delete__input">
                  <div className="description"></div>
                  <CustomInput
                    value={this.state.name}
                    onChange={this.onChange}
                    parentClass='text-field text-field_accent0'
                    label="Type “DELETE”"
                  />
                </div>
                <div className="">
                  <button
                    onClick={this.onSubmit}
                    className={classNames(`button button_${this.props.color}`, {
                      'round-button_disabled': this.state.name !== 'DELETE'
                    })}
                    disabled={this.state.name !== 'DELETE' || isLoading}
                  >
                Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </ProcessPage>
    )
  }
}

RemovedWorkspace.propTypes = {

}

export default RemovedWorkspace
