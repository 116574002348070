const filterColumns = [
{
  id: 1,
  label: 'Status',
  field: 'status',
  type: 'radio',
},
{
  id: 3,
  label: 'Partner ID',
  field: 'id',
  type: 'checkbox',
},
{
  id: 7,
  label: 'Created',
  field: 'createdAt',
  type: 'date',
}]

export default filterColumns