import {billing} from '../constants/billing';

const INITIAL_STATE = {
  isLoading: false,
  detail: null,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case billing.GET_BILLING_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        detail: null
      }
    case billing.GET_BILLING_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: null
      }
    case billing.GET_BILLING_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        detail: null
      }
    default:
      return state;
  }
}