import React from 'react';
import {FormattedMessage} from 'react-intl';


const filterColumns = [
  {
    id: 2,
    label: 'Status',
    field: 'status',
    type: 'radio'
  }, {
    id: 5,
    label: 'Created',
    field: 'created',
    type: 'date',
    unixTime: true
  }
]

export default filterColumns
