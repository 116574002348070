import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class TdWrap extends Component {
  isVisible = () => {
    const col = this.props.columns.find(cl => cl.sortKey === this.props.sortKey)

    return col.isVisible
  }

  render() {
    if (!this.isVisible()) {
      return null
    }

    return (
      <td className={classNames('data-table__td', this.props.className)}>
        { this.props.children }
      </td>
    )
  }
}

TdWrap.propTypes = {
  columns: PropTypes.array,
  sortKey: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

export default TdWrap
