import React, { Component } from "react";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import classNames from "classnames";
import moment from "moment";
import getSymbolFromCurrency from 'currency-symbol-map'
import NumberFormat from 'react-number-format';

import { FilePlus, HistoryIcon, DownloadIcon } from "images/common";
import Card from "app/components/common/Detail/Card";
import ViewHistory from '../../list/invoices'
import { dateFormatter } from "utils/common";

class Invoice extends Component {
  state = {
    openModal: false,
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  
  openEditModal = () => {
    this.setState({ showEditModal: true })
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false })
  }
  
  closeModal = () => {
    this.setState({ openModal: false });
  };

  renderContent = () => {
    const { showEditModal } = this.state
    const { color, invoice = [] } = this.props

    if (showEditModal) {
      return (
        <ViewHistory
          onClose={this.closeEditModal}
          color={color}
          workspace={this.props.workspace}
          invoices={invoice.items}
          allInvoices={invoice.items}
          getInvoicesRequest={this.props.getInvoiceDetails}
          workspaceName={
            this.props.workspace && this.props.workspace.name
          }/>
      )
    }

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {
            invoice.items
              .filter((item, index) => index < 3)
              .map((item, index) => (
                <tr className={`data-table__row card_${color}`} key={invoice.number} onClick={this.openEditModal}>
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <HistoryIcon/>
                      </div>
                    </div>
                  </td>
                  <td className={`data-table__td ${color}`}>{item.prettyPlanName || item.description}</td>
                  <td className="data-table__td ">{dateFormatter(invoice.startDate)}</td>
                  <NumberFormat
                    value={item.amount.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator renderText={value =>
                      <td className="data-table__td">
                        {item.currency} {value}</td>
                    }/>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    return (
      <Card
        noGrid
        isAccentAction
        customText={"View"}
        action={this.openEditModal}
        color={'accent0'}
        title={'Invoice Items'}
        className="flow-modules"
        content={this.renderContent()}
      />
    )
  }
}

export default withRouter(Invoice);
