import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { defaultNotifier } from "functions/notificationHandler";

export const SingleMenu = ({
  closeMenu,
  id,
  status,
  email,
  onToogleMenu,
  hideParentClass,
  devPlanActivated,
  onApprove,
  onReject,
  onDeactivate,
  onDeactivateDev,
  onActivateUser,
  onActivateDev,
  onActivate,
}) => {
  const deactiveClass = classNames("actions__option", {
    actions__option_disabled: status === "inactive",
    cursor: status === "active",
  });

  const activateDevClass = classNames("actions__option", {
    actions__option_disabled: devPlanActivated,
    cursor: !devPlanActivated,
  });

  const activateClass = classNames("actions__option", {
    actions__option_disabled: status === "active",
    cursor: status === "inactive",
  });

  const deactiveDevPlan = classNames("actions__option", {
    actions__option_disabled: !devPlanActivated,
    cursor: devPlanActivated,
  });

  const deactiveUser = (id, e) => {
    e.stopPropagation();

    if (status === "inactive") {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onDeactivate(id, e);
  };

  const devactivateDevPlan = (id, e, devPlanActivated) => {
    e.stopPropagation();
    if (!devPlanActivated) {
      return false;
    }
    onToogleMenu(e);

    closeMenu();
    onDeactivateDev(id, e);
  };

  const activateDevPlan = (payload, e, devPlanActivated) => {
    e.stopPropagation();

    if (devPlanActivated) {
      return false;
    }
    onToogleMenu(e);

    closeMenu();
    onActivateDev(payload, e);
  };

  const activateUser = (payload, e, status) => {
    e.stopPropagation();

    if (status === "active") {
      return false;
    }

    onToogleMenu(e, id);

    closeMenu();
    onActivate(payload, e);
  };

  const parentClass = classNames({
    "actions__dropdown actions__dropdown_visible": !hideParentClass,
  });

  return (
    <>
      <div className={parentClass}>
        <span
          onClick={(e) => devactivateDevPlan({ id }, e, devPlanActivated)}
          className={deactiveDevPlan}
        >
          Deactivate Developer Plan
        </span>
        <span
          onClick={(e) => activateDevPlan({ id }, e, devPlanActivated)}
          className={activateDevClass}
        >
          Activate Developer Plan
        </span>
        <span
          onClick={(e) => deactiveUser({ id }, e, status)}
          className={deactiveClass}
        >
          Deactivate User
        </span>
        <span
          onClick={(e) => activateUser({ id }, e, status)}
          className={activateClass}
        >
          Activate User
        </span>
        <CopyToClipboard
          text={email}
          onCopy={() => {
            closeMenu();
            defaultNotifier("Copied");
          }}
        >
          <span className="actions__option">Copy email address</span>
        </CopyToClipboard>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  listing: PropTypes.any,
};

export default SingleMenu;
