import {sortBy} from 'lodash'
import { plans } from "../constants/plans";

import {
  getPlans,
  getPlan,
  createPlan,
  updatePlan,
  deletePlan,
  subscriptionWorkspaces,
  deactivatePlan,
  activatePlan,
  createPrice,
  deletePrice,
  setAsDevPlan,
  setDefaultPrice
} from "../api/plans";
import { defaultNotifier } from "functions/notificationHandler";

export function listPlans(payload) {
  return async (dispatch) => {
    dispatch({ type: plans.GET_PLANS_PENDING });

    try {
      const { data } = await getPlans(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      const result = sortBy(data.data.plans, ['name']);

      dispatch({
        type: plans.GET_PLANS_FULFILLED,
        payload: result,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the plans");

      dispatch({
        type: plans.GET_PLANS_REJECTED,
        error: {
          message: "Error while fetching plans.",
        },
      });
    }
  };
}

export function listWorkspaceSubscriptions(payload) {
  return async (dispatch) => {
    dispatch({ type: plans.GET_SUBSCRIPTIONS_WORKSPACE_REQUEST });

    try {
      const { data } = await subscriptionWorkspaces(payload);

      if (data.errors) {
        dispatch({
          type: plans.GET_SUBSCRIPTIONS_WORKSPACE_REJECTED,
          error: {
            message: "Error while fetching subscription workspaces.",
          },
        });
        return defaultNotifier(data.errors);
      }
      dispatch({
        type: plans.GET_SUBSCRIPTIONS_WORKSPACE_SUCCESS,
        payload: data.data.subscriptionWorkspaces,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the subscription workspaces");

      dispatch({
        type: plans.GET_SUBSCRIPTIONS_WORKSPACE_REJECTED,
        error: {
          message: "Error while fetching subscription workspaces.",
        },
      });
    }
  };
}

export function getPlanDetails(payload) {
  return async (dispatch) => {
    dispatch({ type: plans.GET_PLAN_DETAILS_PENDING });

    try {
      const { data } = await getPlan(payload);
      if (data.errors) {
        dispatch({
          type: plans.GET_PLAN_DETAILS_REJECTED,
          error: {
            message: "Error while fetching the plan.",
          },
        });

        return defaultNotifier(data.errors);
      }

      dispatch({
        type: plans.GET_PLAN_DETAILS_FULFILLED,
        payload: data.data.plan,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the plan");

      dispatch({
        type: plans.GET_PLAN_DETAILS_REJECTED,
        error: {
          message: "Error while fetching the plan.",
        },
      });
    }
  };
}

export function createPlanAction(payload) {
  return async (dispatch) => {
    try {
      const { data } = await createPlan(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("New plan has been added.");

      return data.data.createPlan.id;
    } catch (err) {
      defaultNotifier("Fail to create new plan");
    }
  };
}

export function updatePlanAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await updatePlan(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Plan updated successfully.");
      if (onCallback) {
        onCallback();
      }
      dispatch({
        type: plans.GET_PLAN_DETAILS_FULFILLED,
        payload: data.data.updatePlan,
      });

      return data.data.updatePlan;
    } catch (err) {
      defaultNotifier("Fail to update plan");
    }
  };
}

export function deactivatePlanAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await deactivatePlan(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      if (!data.data.deactivatePlan) {
        return defaultNotifier('Failed to deactivate the plan.');
      }

      defaultNotifier("Plan deactivated successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to deactivated plan");
    }
  };
}


export function activatePlanAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await activatePlan(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      if (!data.data.activatePlan) {
        return defaultNotifier('Failed to deactivate the plan.');
      }

      defaultNotifier("Plan activated successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to activate plan");
    }
  };
}

export function deletePlanAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await deletePlan(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Plan deleted successfully.");

      if (onCallback) {
        onCallback();
      }

      dispatch({
        type: plans.GET_PLAN_DETAILS_FULFILLED,
        payload: data.data.deletePlan,
      });
    } catch (err) {
      defaultNotifier("Fail to delete plan");
    }
  };
}

export function createPriceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await createPrice(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("New price has been added.");
      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to create new price");
    }
  };
}

export function setDefaultPriceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await setDefaultPrice(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Price set as default.");
      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to set as default");
    }
  };
}

export function deletePriceAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await deletePrice(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Price deleted");
      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to delete");
    }
  };
}

export function setPlanAsDev(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await setAsDevPlan(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Plan set as developer plan");
      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to set as dev plan");
    }
  };
}