import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";

import { FilePlus } from "images/common";
import Card from "app/components/common/Detail/Card";
import WorkspaceSubscription from "app/components/workspaces/subscription";
import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import { dateFormatter } from "utils/common";

class Subscriptions extends Component {
  state = {
    openModal: false,
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  onEditRole = (data) => {
    const { tags } = this.state;
    const { user } = this.props;

    const payload = {
      ...data,
      tags,
      id: user.id,
    };

    this.props.onEdit(payload, this.closeEditModal);
  };

  getStatus = (subscription) => {
    const date = moment(subscription.trial_end * 1000).format("D MMM");
    const isTrialExist = moment(subscription.trial_end * 1000).isAfter(new Date(), "day");

    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": !isTrialExist,
        "data-table__status_neg": isTrialExist,
      }
    );

    return (
      <div className={className}>
        {isTrialExist ? `Trial ends ${date}` : this.nonTrailStatus(subscription.status, subscription.pause_collection)}
      </div>
    );
  };

  nonTrailStatus = (status, pause_collection) => {
    return (
      <span>{ status }
      <span>{pause_collection && '(Paused)'}</span></span>
    )
  }

  getDueDate = (subscription) => {
    if(subscription.status === 'active') {
      return `due ${dateFormatter(subscription.current_period_end * 1000)}`
    }

    return ` due ${dateFormatter(subscription.billing_cycle_anchor * 1000)}`
  }

  renderContent = () => {
    if (!this.props.workspace) {
      return null;
    }
    const {
      workspace: {
        subscriptions = [],
      },
      color,
    } = this.props;

    return (
      <div className="flow-modules__data-table data-table">
      {[].map(listing =>
        <table className="data-table__table">
          <tbody className="data-table__body">
            <tr className="data-table__row">
              <td className="data-table__td data-table__td_1">
                <div className={`checkbox checkbox_${color}`}>
                  <div className="checkbox__image">
                    <FilePlus />
                  </div>
                </div>
              </td>
              <td className={`data-table__td ${color}`}>
                {listing.name}
              </td>
              <td className="data-table__td">
                {this.getStatus(listing)}
              </td>
              <td className="data-table__td">
                {this.getDueDate(listing)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      </div>
    );
  };

  render() {
    const { workspace } = this.props;

    if (this.state.openModal) {
      return (
        <WorkspaceSubscription
          onClose={this.closeModal}
          workspace={workspace}
          subscription={
            workspace.subscriptions ? workspace.subscriptions : []
          }
        />
      );
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        title="Partner Listings"
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

Subscriptions.propTypes = {
  color: PropTypes.string,
};

Subscriptions.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

export default Subscriptions;
