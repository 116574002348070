import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SortableRow from "app/components/common/Table/SortableRow";
import SingleMenu from "./SingleMenu";
import { dateFormatter, getInterval } from "utils/common";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { isActive } = this.props;

    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": isActive,
        "data-table__status_neg": !isActive,
      }
    );

    return <div className={className}>{isActive ? "Active" : "Inactive"}</div>;
  };

  getRowRender = () => {
    const {
      id,
      colorClassName,
      recurring,
      workspaceCount,
      created,
      currentSortKey
    } = this.props;
    const idClass = classNames({
      "data-table__td_dark": currentSortKey === "id",
    });

    const intervalClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "interval",
    });

    const createdClass = classNames('', {
      "data-table__td_dark": currentSortKey === "created",
    });

    const subscriptionsClas = classNames('text-link cursor', {
      [colorClassName]: colorClassName,
      "data-table__td_dark": currentSortKey === "interval",
    });

    return {
      id: () => <div className={idClass}>{id}</div>,
      interval: () => (
        <div className={intervalClass}>
          {recurring && recurring.interval && getInterval[recurring.interval]}
        </div>
      ),
      subscriptions: () => (
        <span className={subscriptionsClas}>
          {workspaceCount || 0} workspaces
        </span>
      ),
      created: () => <span className={createdClass}>{dateFormatter(created * 1000, true)}</span>,
    };
  };

  render() {
    const { colorClassName, id, recurring } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <SingleMenu
            interval={recurring && recurring.interval}
            deletePrice={this.props.deletePrice}
            id={id}
            setDefaultPrice={this.props.setDefaultPrice}
          />
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
