import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import SmallCard from "app/components/common/SmallCard";
import SingleMenu from "./SingleMenu";
import { getInterval } from "utils/common";

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getSubheading = (recurring) => {
    return <>{recurring.interval && getInterval[recurring.interval]}</>;
  };

  render() {
    const { color, data, onSelect, selectedItems, plan } = this.props;

    return (
      <div className="grid-row">
        {data &&
          data.map((item) => (
            <>
              <SmallCard
                key={item.id}
                heading={item.id}
                id={item.id}
                monthlyPriceId={plan && plan.monthlyPriceId}
                yearlyPriceId={plan && plan.yearlyPriceId}
                onCardClick={this.props.onRowClick}
                colorClassName={color}
                subheading={this.getSubheading(item.recurring)}
                selectedItems={selectedItems}
                onSelect={onSelect}
                CheckboxImage={this.props.getCurrencyIcon[item.currency] || item.currency.toUpperCase()}
              >
                <SingleMenu
                  id={item.id}
                  interval={item.recurring && item.recurring.interval}
                  deletePrice={this.props.deletePrice}
                  setDefaultPrice={this.props.setDefaultPrice}
                />
              </SmallCard>
            </>
          ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
