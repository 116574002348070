import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'
import { routeMapping } from 'routes'

const MAX_TAGS_COUNT = 2

class TableTags extends Component {
  constructor(props) {
    super(props)

    this.tagsRef = React.createRef()
  }

  getExtraTagsLength = () => {
    return this.getExtraTags().length
  }

  getExtraTags = () => {
    return this.props.tags.slice(MAX_TAGS_COUNT)
  }

  onRedirect = (id) => {
    this.props.history.push(`${routeMapping.WORKSPACE.path}/${id}`)
  }

  renderExtraTags = () => {
    const {type, id} = this.props;

    const extraTagsLength = this.getExtraTagsLength()
    const extraTags = this.getExtraTags()

    return (
      <>
        <div className="tags__count" ref={this.tagsRef}>+{extraTagsLength}</div>
        <UncontrolledPopover trigger="hover" target={this.tagsRef} className="tags-popover">
          <PopoverBody>
            { type === 'link' && extraTags.map((tag) => (
              <div className="tag-popover" key={tag}>{ tag }</div>
            ))}
            { type !== 'link' && extraTags.map((extraTag) => (
              <div className="tag-popover" key={extraTag}>{ extraTag }</div>
            ))}
          </PopoverBody>
        </UncontrolledPopover>
      </>
    )
  }

  render() {
    const {icon, type, data} = this.props

    const extraTagsLength = this.getExtraTagsLength()

    return (
      <div className={classNames('tags', { [`tags_${this.props.colorClassName}`]: this.props.colorClassName })}>
        { this.props.tags.slice(0, MAX_TAGS_COUNT).map((tag, index) => {
          if (icon) {
            return <div key={tag} className="tags__item">{icon} { tag }</div>
          }

          if(type === 'link') {
            return <div key={tag} className="tags__item tags__item_id" onClick={() => this.onRedirect(data[index].id)}>{ tag }</div>
          }
          return <div key={tag} className="tags__item">{ tag }</div>
        }
        )}
        { extraTagsLength > 0 && this.renderExtraTags() }
      </div>
    )
  }
}

TableTags.propTypes = {
  tags: PropTypes.array,
  colorClassName: PropTypes.string
}

TableTags.deaultProps = {
  tags: []
}

export default withRouter(TableTags)
