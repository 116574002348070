import React from "react";
import classNames from "classnames";
import { LIBRARY_ACCENT } from "utils/constants";

import ProcessPage from "app/components/common/ProcessPage";
import { defaultNotifier } from "functions/notificationHandler";
import CustomInput from "app/components/common/Input/CustomInput";
import { assignNodesToModel } from "app/api/model";
import { CloseIcon } from "images/common";

class ModuleAssign extends React.Component {
  state = {
    models: [{
      module: '',
      node: ''
    }],
    module: [],
    node: [],
  };

  componentDidMount() {}

  onSelectNodes = (id) => {
    return this.setState({ selectedNodesIds: id });
  };

  onResetNode = () => {
    this.setState({ selectedNodesIds: [] });
  };

  onSelect = (id, e) => {
    e && e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  onSubmit = async () => {
    const { model } = this.props;
    const payload = this.state.models
    .filter(item => item.module && item.node)

    await assignNodesToModel({ id: model.id, nodes: payload });
    this.props.closeEditModal();
    defaultNotifier("Associated nodes added successfully.");

    this.props.getModel();
  };

  onReset = () => {
    this.setState({ selectedIds: "" });
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  onAdd = () => {
    this.state.models.push(this.state.models.length);

    this.setState({ models: this.state.models });
  };

  onRemove = (ind) => {
    let models = this.state.models.filter((item, index) => index !== ind);

    this.setState({ models });
  };

  onChange = (name, value, index) => {
    if (name === "module") {
      // this.state.module[index] = value.target.value;
      this.state.models[index] = {
        ...this.state.models[index],
        module: value.target.value,
      };
      return this.setState({ models: this.state.models });
    }
    // this.state.node[index] = value.target.value;
    this.state.models[index] = {
      ...this.state.models[index],
      node: value.target.value,
    };
    return this.setState({ models: this.state.models });
  };

  render() {
    const { model, color } = this.props;
    const submitClass = classNames("button", {
      [`button_${color}`]: color,
    });

    return (
      <ProcessPage onCancel={this.props.closeEditModal}>
        {(scrollRef) => (
          <>
            <div className="headline">
              <h1 className="headline__title">Assign to model nodes</h1>
              <h3 className="headline__subtitle">
                Select the module and ndoe for which the current AI model{" "}
                {model.name} will be available.
              </h3>
            </div>
            <div className="data-cards login__form">
              {this.state.models.map((model, index) => {
                return (
                  <div className="relative" key={index}>
                    <div className="grid-row mx-b-12">
                      <div className="grid-col">
                        <div className={`text-field text-field_${color}`}>
                          <CustomInput
                            color="accent3"
                            value={model.module}
                            onChange={(value) =>
                              this.onChange("module", value, index)
                            }
                            label={"Module"}
                          />
                        </div>
                      </div>
                      <div className="grid-col">
                        <div className={`text-field text-field_${color}`}>
                          <CustomInput
                            value={model.node}
                            color="accent3"
                            onChange={(value) =>
                              this.onChange("node", value, index)
                            }
                            label={"Node"}
                          />
                        </div>
                      </div>
                    </div>
                    {index !== 0 && (
                      <div
                        onClick={() => this.onRemove(index)}
                        className="btn-close-node"
                      >
                        <CloseIcon />
                      </div>
                    )}
                  </div>
                );
              })}
              <div className="data-cards__add cursor" onClick={this.onAdd}>
                + Assign another node
              </div>
            </div>
            <div className="login__actions">
              <button onClick={this.onSubmit} className={submitClass}>
                Save
              </button>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

ModuleAssign.propTypes = {};

export default ModuleAssign;
