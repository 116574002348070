import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import { PlusCircle } from "react-feather";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import NotFound from "app/components/NotFound";
import { FormattedMessage } from "react-intl";
import Modal from "app/components/common/Modal";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { isSmallerDevice } from "utils/common";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import Pagination from "app/components/Pagination";

import SearchField from "app/components/common/SingleSearch";
import NoPlans from "../../common/NoItem";
import messages from "utils/message";

import Card from "./Card";
import List from "./Table";
import { deleteModel } from "app/api/model";
import { routeMapping } from "app/constants/routes";

import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  LIBRARY_ACCENT,
} from "app/constants/common";
import { listModel } from "app/actions/models";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";

import ListSelectedItems from "../../common/ListSelectedItems";
import PreLoader from "app/components/common/PreLoader/PreLoaderDetail";

class ModelList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      selectedIds: [],
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "name",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: DEFAULT_PAGE_SIZE,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.models,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: DEFAULT_PAGE_SIZE,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.models);
    let result = finalResult;
    this.setCurrentPage(1);

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.setState({ filterResult: this.props.models })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.getModels();
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  getModels = () => {
    this.props.listModel();
  };

  onActivate = async (id) => {
    await this.props.activateCategoryAction(
      {
        id,
      },
      this.getModels
    );
  };

  onActivateCategory = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The category will be activated.`;
    const title = `Confirm activate category`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onActivate(id),
    });
  };

  onDeactivate = async (id, e) => {
    await this.props.deactivateCategoryAction(
      {
        id,
      },
      this.getModels
    );
  };

  onDelete = async (id) => {
    this.setState({ showModalDelete: false });
    await deleteModel({ id });
    this.getModels();
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  loadMore = () => {
    if (this.state.isAllSelected) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE });
  };

  isMore = () => {
    const { models } = this.props;

    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    const data = hasFilter ? this.state.filterResult : models;

    if (this.state.activeView === CARD_VIEW) {
      return false;
    }

    if (!data) {
      return false;
    }

    return data.length > this.state.offset;
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`/model/${id}`);
  };

  redirectToCreatePage = () => {
    return this.props.history.push(routeMapping.CREATE_MODEL.path);
  };

  onHideDeleteWarning = async () => {
    this.setState({ showModalDelete: false, applicationId: null });
  };

  onShowDeleteWarning = async (id, e) => {
    e.stopPropagation();

    this.setState({ showModalDelete: true, modelId: id });
  };

  onRenderDeleteModal = () => {
    const { models } = this.props;
    const { modelId } = this.state;

    const selectedModel = models.find((item) => item.id === modelId);

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={(e) => this.onDelete(this.state.modelId, e)}
      >
        {this.state.isLoading ? (
          <FormattedMessage {...messages.loading} />
        ) : (
          <FormattedMessage {...messages.confirm} />
        )}
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onHideDeleteWarning}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={<FormattedMessage {...messages.removeYourModel} />}
        body={
          <p>
            <FormattedMessage
              {...messages.removeYourModelDesc}
              values={{
                name: (
                  <span className="text-color">
                    {selectedModel && selectedModel.name}
                  </span>
                ),
              }}
            />
          </p>
        }
        onClose={this.onHideDeleteWarning}
      />
    );
  };

  // pagination
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  render() {
    const { color } = this.props;

    const { currentPage, activeView, selectedIds, resetFilter } = this.state;

    const viewHeadlineClass = classNames(
      `actions-nav__icon actions-nav__icon_rows ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === TABLE_VIEW }
    );

    const viewListClass = classNames(
      `actions-nav__icon actions-nav__icon_cards ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === CARD_VIEW }
    );

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    if (this.props.isLoading) {
      return <PreLoader color={this.props.color} />;
    }

    return (
      <>
        <ListScroll>
          {(scrollParent) => (
            <>
              {this.state.showModalDelete && this.onRenderDeleteModal()}
              <div className="headline">
                <h1 className="headline__title">Default AI models</h1>
              </div>
              <Breadcrumb breadcrumbClass="breadcrumbs_static">
                <BreadcrumbItem />
                <BreadcrumbItem route="AI models that are default in Viso Suite.." />
              </Breadcrumb>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
                <span
                  className={`actions-nav__link text-link ${color} cursor`}
                  onClick={this.redirectToCreatePage}
                >
                  Create
                </span>
              </div>
              {!hasFilter &&
              !this.props.models.length &&
              !this.props.isLoading ? (
                <NotFound
                  color={color}
                  strokeWidth="1"
                  logo={<PlusCircle size={42} />}
                  title="Create your own model"
                  buttonText="Create first model"
                  onAction={this.redirectToCreatePage}
                />
              ) : (
                <>
                  <div className="filters-bar">
                    <ListSelectedItems
                      selectedNumber={selectedIds.length}
                      search={this.state.searchName}
                      onReset={this.onReset}
                      filterData={this.state.filter}
                      containerClassName={color}
                    />
                    <Filter
                      defaultFilter={[
                        {
                          field: "modelType",
                          isDefault: true,
                          value: "All",
                          columns: [
                            "face_recognition",
                            "image_classification",
                            "image_segmentation",
                            "key_point_detection",
                            "object_detection",
                            "object_tracking",
                            "pose_estimation",
                          ],
                        },
                      ]}
                      customClass={color}
                      resetFilter={resetFilter}
                      onChange={this.onFilter}
                      columns={filterColumns}
                      data={this.props.models}
                    />
                    <SearchField
                      onSearch={this.onSearch}
                      customClass={color}
                      resetSearch={this.state.resetFilter}
                      label="Search"
                    />
                  </div>
                  <div>
                    <InfiniteScroll
                      initialLoad={false}
                      useWindow={false}
                      getScrollParent={() => scrollParent}
                    >
                      {activeView === TABLE_VIEW && (
                        <>
                          <List
                            data={
                              hasFilter
                                ? this.state.filterResult.filter(
                                    (item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }
        
                                      if (
                                        currentPage !== 1 &&
                                        index < this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }
        
                                      return false;
                                    }
                                  )
                                : this.props.models.filter(
                                    (item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }
    
                                      if (
                                        currentPage !== 1 &&
                                        index < this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }
    
                                      return false;
                                    }
                                  )
                            }
                            onRowClick={this.onRowClick}
                            selectAll={this.selectAll}
                            onSort={this.onSort}
                            color={color}
                            onDelete={this.onShowDeleteWarning}
                            isLoading={this.props.isLoading}
                            onDeactivate={this.onDeactivateCategory}
                            onActivate={this.onActivateCategory}
                            sort={this.state.sort}
                            isSelectedAll={this.getIsSelectedAll()}
                            selectedIds={selectedIds}
                            onSelect={this.onSelect}
                            onUpdateStatus={this.onUpdateStatus}
                          />
                        </>
                      )}
                      {activeView === CARD_VIEW && (
                        <div className="data-cards">
                          <Card
                            color={color}
                            data={
                              hasFilter
                                ? this.state.filterResult.filter(
                                    (item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }
        
                                      if (
                                        currentPage !== 1 &&
                                        index < this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }
        
                                      return false;
                                    }
                                  )
                                : this.props.models.filter(
                                    (item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }
    
                                      if (
                                        currentPage !== 1 &&
                                        index < this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }
    
                                      return false;
                                    }
                                  )
                            }
                            onDelete={this.onShowDeleteWarning}
                            onDuplicate={this.onDuplicate}
                            onRowClick={this.onRowClick}
                            selectedItems={selectedIds}
                            onDeactivate={this.onDeactivateCategory}
                            onActivate={this.onActivateCategory}
                            onUpdateStatus={this.onUpdateStatus}
                            onSelect={this.onSelect}
                          />
                        </div>
                      )}
                        {((hasFilter && !!this.state.filterResult.length) ||
                            (!hasFilter && !!this.props.models.length)) && (
                            <Pagination
                              onNext={this.onNext}
                              currentPage={this.state.currentPage}
                              setCurrentPage={this.setCurrentPage}
                              onChangeLimit={this.onChangeLimit}
                              limit={this.state.offset}
                              totalPage={
                                hasFilter
                                  ? this.state.filterResult.length
                                  : this.props.models.length
                              }
                            />
                          )}
                    </InfiniteScroll>
                    {activeView === CARD_VIEW &&
                      this.props.models &&
                      !this.props.models.length && <NoPlans />}
                  </div>
                </>
              )}
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

ModelList.propTypes = {
  color: PropTypes.string,
  models: PropTypes.array,
  activeView: PropTypes.string,
};

ModelList.defaultProps = {
  color: LIBRARY_ACCENT,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    models: state.model.list,
    isLoading: state.model.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  listModel,
});

export default compose(withRouter, withConnect)(ModelList);
