import { partner } from "../constants/partner";

const INITIAL_STATE = {
  list: [],
  isLoading: false,
  detail: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case partner.GET_PARTNER_PENDING:
      return {
        ...state,
        isLoading: true,
        list: [],
      };
    case partner.GET_PARTNER_FULFILLED:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
      };
    case partner.GET_PARTNER_REJECTED:
      return {
        ...state,
        isLoading: true,
        list: [],
      };
    case partner.GET_PARTNER_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        detail: {},
      };
    case partner.GET_PARTNER_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
      };
    case partner.GET_PARTNER_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        detail: {},
      };
    default:
      return state;
  }
}
