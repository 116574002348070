import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import Modal from "app/components/common/Modal";
import messages from "utils/message";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { convertDefaultStatusToStatus } from "utils/common";

import DetailScroll from "app/components/common/Scroll";
import Information from "./components/Information";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import Metadata from "./components/Metadata";
import Package from "./components/Package";
import Document from "./components/DocumentAndSupport";
import Payment from "./components/Payment";
import Preview from "./SolutionPreview";
import { SOLUTION_ACCENT } from "app/constants/common";
import {
  getListDetails,
  reviewAction,
  acceptAction,
  rejectAction,
  fetchDashboards,
} from "app/actions/listing";
import { routeMapping } from "app/constants/routes";
import Dashboard from "./components/Dashboard";
import DemoVideo from "./components/Video";
import {setPreInstallSolution} from 'app/api/listings';
import { defaultNotifier } from "functions/notificationHandler";
import PreLoader from "app/components/common/PreLoader/PreLoaderDetail";

const Menu = ({
  onReview,
  onApprove,
  onPreInstall,
  onReject,
  listing,
  closeMenu,
  preInstallClass,
  rejectClass,
  approveClass,
  reviewClass,
}) => {
  return (
    <>
      <span className={reviewClass} onClick={() => onReview(closeMenu)}>
        Start Review
      </span>
      <span className={approveClass} onClick={() => onApprove(closeMenu)}>
        Approve Listing
      </span>
      <span onClick={() => onReject(closeMenu)} className={rejectClass}>
        Reject Listing
      </span>
      <span onClick={() => onPreInstall(closeMenu)} className={preInstallClass}>
        {listing.preInstallable ? 'Disable Pre-installation' : 'Enable Pre-installation'}
      </span>
    </>
  );
};

class ListingDetails extends React.Component {
  state = {
    showDropdown: false,
    loggedInUser: this.props.loggedInUser,
    editPopupForm: null,
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.props.getListDetails({ id });
    // if(!this.props.categories.length) {
    //   this.props.listCategory();
    // }
  }

  getSnapshotBeforeUpdate(nextProps) {
    const { listing } = this.props;

    if (
      listing &&
      listing.type !== (nextProps.listing && nextProps.listing.type) &&
      listing.type === "application"
    ) {
      this.props.fetchDashboards({ workspaceId: listing.workspaceId });
    }
  }

  getListing = () => {
    const { id } = this.props.match.params;

    this.props.getListDetails({ id });
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onEdit = (formName) => {
    this.setState({ editPopupForm: formName });
  };

  redirectToListings = () => {
    this.props.history.push(routeMapping.LISTING.path);
  };

  onReject = async () => {
    const { id } = this.props.match.params;

    await this.props.rejectAction({ id });
    this.redirectToListings();
  };

  onReviewListing = async () => {
    const id = this.props.listing.id;

    await this.props.reviewAction({
      id,
    });
    this.props.getListDetails({ id });
  };

  onPreInstallListing = async () => {
    const {listing} = this.props;
    const id = this.props.listing.id;

      const {data} = await setPreInstallSolution({
        id,
        preInstallable: !listing.preInstallable
      });

      if(data.errors) {
        return defaultNotifier(data.errors)
      }
      defaultNotifier('Updated successfully')

      this.props.getListDetails({ id });
  };

  onApproveListing = async () => {
    const { id } = this.props.match.params;

    await this.props.acceptAction({
      id,
    });
    this.props.getListDetails({ id });
  };

  onEditClose = () => {
    this.setState({ editPopupForm: null });
  };

  showWorkspaceSubscribePage = () => {
    this.setState({ showSubscribeModal: true });
  };

  hideWorkspaceSubscribePage = () => {
    this.setState({ showSubscribeModal: false });
  };

  onReview = (closeMenu) => {
    const body = `The listing status will be changed to review.`;
    const title = `Confirm starting the reivew`;

    const { listing } = this.props;

    if (
      listing.status === "listed" ||
      listing.status === "rejected" ||
      listing.status === "draft" ||
      listing.status === "in_review"
    ) {
      return false;
    }

    closeMenu();

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onReviewListing(),
    });
  };

  onApprove = (closeMenu) => {
    const { listing } = this.props;

    if (
      listing.status === "listed" ||
      listing.status === "rejected" ||
      listing.status === "draft" ||
      listing.status === "submitted"
    ) {
      return false;
    }

    closeMenu();
    const body = `The listing will be approved.`;
    const title = `Confirm approve listing`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onApproveListing(),
    });
  };

  onPreInstall = (closeMenu) => {
    const { listing } = this.props;

    if (
      listing.status === "listed" &&
      listing.type === "application"
    ) {
      closeMenu();
      const body = listing.preInstallable ?
       `The listing will be disable for pre installation.`
       :
       `The listing will be available for pre installation.`;
      const title = `Confirm`;
  
      this.setState({
        showModal: true,
        body,
        title,
        onConfirm: () => this.onPreInstallListing(),
      });
    }
  };

  onRejectListing = (closeMenu) => {
    const { listing } = this.props;

    if (
      listing.status === "listed" ||
      listing.status === "rejected" ||
      listing.status === "draft" ||
      listing.status === "in_progress"
    ) {
      return false;
    }

    closeMenu();

    const body = `The listing will be rejected.`;
    const title = `Confirm reject listing`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onReject(),
    });
  };

  renderModal = () => {
    const { body, onConfirm, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={title}
        body={body}
        onClose={this.onCloseModal}
      />
    );
  };

  onShowPreview = () => {
    const { id } = this.props.match.params;

    this.props.history.push(`/listing/${id}/preview`);
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  render() {
    const { color, listing } = this.props;
    const { showModal } = this.state;

    if (!listing) {
      return <DetailScroll />;
    }

    const rejectClass = classNames("actions__option", {
      actions__option_disabled:
        listing.status === "rejected" ||
        listing.status === "draft" ||
        listing.status === "listed",
      cursor: listing.status !== "rejected",
    });

    const preInstallClass = classNames("actions__option", {
      actions__option_disabled: listing.status !== "listed" || listing.type !== 'application',
      cursor: listing.status === "application" && listing.status === "listed",
    });

    const reviewClass = classNames("actions__option", {
      actions__option_disabled:
        listing.status === "listed" ||
        listing.status === "rejected" ||
        listing.status === "draft" ||
        listing.status === "in_progress",
      cursor: listing.status === "submitted",
    });

    const approveClass = classNames("actions__option", {
      actions__option_disabled:
        listing.status === "submitted" ||
        listing.status === "draft" ||
        listing.status === "rejected" ||
        listing.status === "listed",
      cursor: listing.status === "active",
    });

    if (this.state.showPreview) {
      return <Preview solution={listing} />;
    }

    if (this.props.isLoading) {
      return <PreLoader color={this.props.color} />;
    }

    return (
      <DetailScroll>
        {showModal && this.renderModal()}
        <div className={"messages-bar snackbar-wrapper"}></div>
        <div className="headline">
          <div className={`headline__icon ${color} admin_workspace__image`}>
            <img
              src={(listing && listing.logo) || "http://placehold.it/88x88"}
              width="53"
              height="53"
              alt=""
            />
          </div>
          <h1 className="headline__title">{listing.name}</h1>
        </div>

        <Breadcrumb breadcrumbClass={`breadcrumbs breadcrumbs_${color}`}>
          <BreadcrumbItem route="Listings" />
          <BreadcrumbItem route={listing.name} />
        </Breadcrumb>

        <div className="actions-nav">
          <div className="actions">
            <VerticalButtonMenu customClass={color}>
              <Menu
                workspaceCount={listing.workspaceCount}
                onReview={this.onReview}
                listing={listing}
                onPreInstall={this.onPreInstall}
                preInstallClass={preInstallClass}
                onApprove={this.onApprove}
                onReject={this.onRejectListing}
                rejectClass={rejectClass}
                reviewClass={reviewClass}
                approveClass={approveClass}
              />
            </VerticalButtonMenu>
          </div>
          <span
            className={`actions-nav__link text-link ${color} cursor`}
            onClick={this.onShowPreview}
          >
            Preview
          </span>
        </div>
        <div className="grid-row">
          <div className="grid-col grid-col_sm-12">
            <Information color={color} listing={listing} />
            <Package color={color} listing={listing} />
            {listing && listing.type === "application" && (
              <>
                <Dashboard
                  dashboards={this.props.dashboards}
                  color={color}
                  solution={listing}
                  getSolution={this.getListing}
                />
                {listing && listing.videos && (
                  <DemoVideo
                    color={color}
                    solution={listing}
                    getSolution={this.getListing}
                  />
                )}
              </>
            )}
          </div>
          <div className="grid-col grid-col_sm-12">
            <Metadata color={color} listing={listing} />
            <Document
              color={color}
              removeImage={this.props.removeImage}
              listing={listing}
              data={this.props.data}
              getSolution={this.getSolution}
              onEdit={this.props.updateSolutionBasicInfoRequest}
              onConfirm={this.props.updateSolutionInfoRequest}
              onUpdate={this.props.uploadSolutionFilesRequest}
            />
            <Payment color={color} listing={listing} />
          </div>
        </div>
      </DetailScroll>
    );
  }
}

ListingDetails.propTypes = {
  color: PropTypes.string,
  listing: PropTypes.object,
  loggedInUser: PropTypes.object,
};

ListingDetails.defaultProps = {
  color: SOLUTION_ACCENT,
};

function mapStateToProps(state) {
  return {
    listing: state.listing.detail,
    dashboards: state.listing.dashboards,
    // categories: state.listing.list,
    isLoading: state.listing.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  getListDetails,
  reviewAction,
  acceptAction,
  rejectAction,
  fetchDashboards,
});

export default compose(withRouter, withConnect)(ListingDetails);
