import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from 'classnames'

import Menu from "./SingleMenu";
import SortableRow from "app/components/common/Table/SortableRow";
import NumberFormat from "react-number-format";
import { dateFormatter, getMRR, getInterval, getPriceFormat, snakeCaseToTitleCase } from "utils/common";

class Workspaces extends PureComponent {
  getStatus = (status) => {
    const { pause_collection } = this.props

    const className = classNames('data-table__status',
      {
        'data-table__status_pos': status === 'ACTIVE',
        'data-table__status_neg': status === 'BLOCKED',
        'data-table__status': status === 'PENDING',
      })

    return (
      <div className={className}>{ snakeCaseToTitleCase(status) }
      <span>{pause_collection && '(Paused)'}</span></div>
    )
  }

  getDueDate = (subscription) => {
    if(subscription.status === 'active') {
      return `${dateFormatter(subscription.current_period_end * 1000)}`
    }

    return `${dateFormatter(subscription.billing_cycle_anchor * 1000)}`
  }
  
  getProvider = (plan, metadata) => {

    if(metadata.type === 'marketplace') {
      return plan.product && plan.product.metadata.provider
    }

    return 'viso.ai'
  }

  getRowRender = () => {
    const {
      type,
      plan,
      plans,
      chargedThroughDate,
      planName,
      quantity,
      state,
      prices,
      phaseType,
      cancelledDate,
      billingStartDate,
      productCategory,
      currentSortKey
    } = this.props;

    const nameClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "planName",
    });

    const typeClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "type",
    });

    const categoryClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "category",
    });

    const quantityClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "quantity",
    });

    const statusClass = classNames('', {
      "data-table__td_dark": currentSortKey === "status",
    });


    const phaseClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "phaseType",
    });

    const startAtClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "billingStartDate",
    });

    const chargeThrouhClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "chargeThrough",
    });

    const cancelledDateClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "cancelledDate",
    });

    const intervalClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "interval",
    });

    
    const amountClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "amount",
    });

    
    const nextDueClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "nextDue",
    });

    const priceAmount = prices.map(item => item.recurringPrice).reduce((sum ,price) => sum + price);

    const namePlan = plans.find(plan => plan.name === planName);
  
    return {
      planName: () => <div className={nameClass}>{(namePlan && namePlan.prettyName) || planName}</div>,
      phaseType: () => <span className="type__value text-capitalize">{phaseType}</span>,
      category: () => <span className="type__value">{productCategory && snakeCaseToTitleCase(productCategory)}</span>,
      quantity: () => <span className="type__value">{productCategory === 'BASE' ? 'Workspace' : `${namePlan && namePlan.quantity} Seat`}</span>,
      status: () => <span className={statusClass}>{this.getStatus(state)}</span>,
      period: () => <span className="text-capitalize">{snakeCaseToTitleCase(type)}</span>,
      billingStartDate: () => dateFormatter(billingStartDate),
      chargeThrough: () => dateFormatter(chargedThroughDate),
      cancelledDate: () => cancelledDate ? dateFormatter(cancelledDate) : 'N/A',
      interval: () => getInterval[plan.interval],
      endpoints: () => quantity,
      amount: () => <span>{getPriceFormat(priceAmount)}</span>,
      nextInvoice: () => <NumberFormat
      value={getMRR(quantity ,plan )}
      displayType={'text'}
      thousandSeparator renderText={value =>
        <span>{plan.currency && plan.currency.toUpperCase()}{' '}{value}</span>
      }/>,
      nextDue: () => this.getDueDate(this.props),
      // provider: () => this.getProvider(plan, metadata)
    };
  };

  render() {
    const { colorClassName, detailRole = {}, subscriptionId, productCategory } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
            <Menu 
            id={subscriptionId}
            onChangePlan={this.props.onChangePlan}
            category={productCategory}
            onDelete={this.props.onDelete}
            detailRole={detailRole.role} />
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
