import {sortBy} from 'lodash'
import { category } from "../constants/category";

import {
  getCategory,
  createMarketplaceCategory,
  getMarketplaceCategories,
  activateMarketplaceCategory,
  deleteMarketplaceCategory,
  updateMarketplaceCategory,
  deactivateMarketplaceCategory,
} from "../api/marketplace";
import { defaultNotifier } from "functions/notificationHandler";

export function listCategory(payload) {
  return async (dispatch) => {
    dispatch({ type: category.GET_CATEGORIES_PENDING });

    try {
      const { data } = await getMarketplaceCategories(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      const result = sortBy(data.data.marketplaceCategories, ['name']);

      dispatch({
        type: category.GET_CATEGORIES_FULFILLED,
        payload: result,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the categories");

      dispatch({
        type: category.GET_CATEGORIES_REJECTED,
        error: {
          message: "Error while fetching categories.",
        },
      });
    }
  };
}

export function getCategoryDetails(payload) {
  return async (dispatch) => {
    dispatch({ type: category.GET_CATEGORY_DETAILS_PENDING });

    try {
      const { data } = await getCategory(payload);
      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      dispatch({
        type: category.GET_CATEGORY_DETAILS_FULFILLED,
        payload: data.data.marketplaceCategory,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the category");

      dispatch({
        type: category.GET_CATEGORY_DETAILS_REJECTED,
        error: {
          message: "Error while fetching the category.",
        },
      });
    }
  };
}

export function createCategoryAction(payload) {
  return async (dispatch) => {
    try {
      const { data } = await createMarketplaceCategory(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("New category has been added.");

      return data.data.createMarketplaceCategory.id;
    } catch (err) {
      defaultNotifier("Fail to create new category");
    }
  };
}

export function updateCategoryAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await updateMarketplaceCategory(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Category updated successfully.");
      if (onCallback) {
        onCallback();
      }
      dispatch({
        type: category.GET_CATEGORY_DETAILS_FULFILLED,
        payload: data.data.updateMarketplaceCategory,
      });

      return data.data.updateMarketplaceCategory;
    } catch (err) {
      defaultNotifier("Fail to update category");
    }
  };
}

export function deactivateCategoryAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await deactivateMarketplaceCategory(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Category deactivated successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to deactivated category");
    }
  };
}

export function activateCategoryAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await activateMarketplaceCategory(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Category activated successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to activate category");
    }
  };
}

export function deleteCategoryAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await deleteMarketplaceCategory(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Category deleted successfully.");

      if (onCallback) {
        onCallback();
      }

    } catch (err) {
      defaultNotifier("Fail to delete category");
    }
  };
}
