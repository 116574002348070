import React from 'react'
import { CloudOff } from 'react-feather'

const NoModule = ({message}) => (
  <tr className="data-table__row data-table__row_disabled">
    <td className="data-table__td">
      <CloudOff/>
    </td>
    <td className="data-table__td">
      {message}
    </td>
  </tr>
)

export default NoModule
