import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import classNames from 'classnames'

class ListScroll extends Component {
  constructor(props) {
    super(props)

    this.contentRef = React.createRef()
  }

  handleScrollbarsMount = (scrollbars, b) => {
    const viewElem = scrollbars && scrollbars.view

    this.contentRef = viewElem
    // hack to update ref and pass down
    this.setState({})
  }

  render() {
    const {hideRenderView, isStatusBarOpen, customClass} = this.props

    const mainClass = classNames('content', {
      'expanded': !isStatusBarOpen,
      [customClass]: customClass

    })

    return (
      <Scrollbars
        style={{ width: '100%', height: '100vh' }}
        renderView={(props) => {
          if (!hideRenderView) {
            return <main {...props} className={mainClass} />
          }

          return <div {...props}></div>
        }}
        universal
        autoHide
        renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
        ref={this.handleScrollbarsMount}
      >
        <div className='content-wrapper'>
          <div className='content-body'>
            {this.contentRef.current !== null && this.props.children(this.contentRef)}
          </div>
        </div>
      </Scrollbars>
    )
  }
}

function mapStateToProps(state) {
  return {
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(
  mapStateToProps
)

ListScroll.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  hideRenderView: PropTypes.bool
}

export default withConnect(ListScroll)
