import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ModuleRow from "./Row";
import Table from "app/components/common/Table";
import appMessages from "utils/message";
import { WorkspaceIcon } from "images/common";

import Pagination from 'app/components/Pagination'
const columnList = [
  {
    label: <FormattedMessage {...appMessages.payment} />,
    isSortable: true,
    isLocked: true,
    sortKey: "paymentNumber",
  },
  {
    label: <FormattedMessage {...appMessages.amount} />,
    isSortable: true,
    sortKey: "amount",
  },
  {
    label: <FormattedMessage {...appMessages.paymentMethod} />,
    sortable: false,
    isSortable: true,
    sortKey: "paymentMethod",
  },
  {
    label: <FormattedMessage {...appMessages.workspace} />,
    isSortable: true,
    sortKey: "workspace",
  },
  {
    label: <FormattedMessage {...appMessages.invoice} />,
    isSortable: true,
    sortKey: "invoiceNumber",
  },
  {
    label: <FormattedMessage {...appMessages.paymentDate} />,
    sortable: false,
    isSortable: true,
    sortKey: "paymentDate",
  },
  {
    label: <FormattedMessage {...appMessages.created} />,
    sortable: false,
    isSortable: true,
    sortKey: "createdAt",
  },
];

class ModuleTable extends React.Component {
  render() {
    const { color, data, onSelect, selectedIds } = this.props;

    return (
      <>
        <Table
          columns={columnList}
          RowComponent={ModuleRow}
          rows={data || []}
          notFoundIcon={<WorkspaceIcon size={42} />}
          colorClassName={color}
          isPaymentSetting
          CheckboxImage={WorkspaceIcon}
          isSelectable
          withColumnSelect
          onManageAgent={this.props.onManageAgent}
          isLoading={this.props.isLoading}
          selectedItems={selectedIds}
          onSelect={onSelect}
          onDeactivate={this.props.onDeactivate}
          onActivate={this.props.onActivate}
          onCancelSchedule={this.props.onCancelSchedule}
          currentSortKey={this.props.sort && this.props.sort.key}
          currentSortDir={this.props.sort && this.props.sort.direction}
          onUpdateSort={this.props.onSort}
          rowClick={this.props.onRowClick}
          onDelete={this.props.onDelete}
          onScheduleDelete={this.props.onScheduleDelete}
          onArchive={this.props.onArchive}
          onUpdate={this.props.onUpdate}
          onActivateAnnotation={this.props.onActivateAnnotation}
          onDeactivateAnnotation={this.props.onDeactivateAnnotation}
          onActivateTraining={this.props.onActivateTraining}
          onDeactivateTraining={this.props.onDeactivateTraining}
        />
      </>
    );
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired,
};

export default ModuleTable;
