import React from "react";
import PropTypes from "prop-types";

export const SingleMenu = ({ id, onDelete }) => {
  return (
    <>
      <span onClick={(e) => onDelete(id, e)} className="actions__option cursor">
        Delete
      </span>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
