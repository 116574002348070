export const plans = {
GET_PLANS_PENDING: 'GET_PLANS_PENDING',
GET_PLANS_FULFILLED: 'GET_PLANS_FULFILLED',
GET_PLANS_REJECTED: 'GET_PLANS_REJECTED',

GET_PLAN_DETAILS_PENDING: 'GET_PLAN_DETAILS_PENDING',
GET_PLAN_DETAILS_FULFILLED: 'GET_PLAN_DETAILS_FULFILLED',
GET_PLAN_DETAILS_REJECTED: 'GET_PLAN_DETAILS_REJECTED',

GET_SUBSCRIPTIONS_WORKSPACE_REQUEST: 'GET_SUBSCRIPTIONS_WORKSPACE_REQUEST',
GET_SUBSCRIPTIONS_WORKSPACE_SUCCESS: 'GET_SUBSCRIPTIONS_WORKSPACE_SUCCESS',
GET_SUBSCRIPTIONS_WORKSPACE_REJECTED: 'GET_SUBSCRIPTIONS_WORKSPACE_REJECTED',
}