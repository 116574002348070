import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import CardTags from "app/components/common/Detail/CardTags";
import { dateFormatter, getPaymentMethod, getPriceFormat } from "utils/common";

class Overview extends Component {
  renderContent = () => {
    const { payment } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.amountReceived} />
          </div>
          <div className="overview__value">{getPriceFormat(payment.amount)}</div>
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.paymentDate} />
          </div>
          <div className="overview__value">{payment.paymentDate && dateFormatter(payment.paymentDate, true)}</div>
        </div>
        <div className="grid-col">
          <div className="overview__label">
            <FormattedMessage {...messages.paymentType} />
          </div>
          <div className="overview__value">Purchase</div>
          <div className="overview__label">
            <FormattedMessage {...messages.paymentMethod} />
          </div>
          <div className="overview__value">{getPaymentMethod(payment.paymentMethod)}</div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title="Overview"
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Overview.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Overview;
