import React from 'react'

import DetailScroll from 'app/components/common/Scroll';
import classNames from 'classnames'

const LoadingIndicator = ({sidebarStatus, color = 'accent0', customClass, isProcessPage}) => {
  const mainClass = classNames('loading_icon', {
    'loader_expand': sidebarStatus,
    [color]: color,
    [customClass]: customClass,
    'full-page': isProcessPage
  })

  return (
    
    <DetailScroll isProcessPage>
      <div className={mainClass}>
        <div className="boxes">
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        </div>
    </DetailScroll>
  )
}

export default LoadingIndicator
