import React, { Component } from "react";
import PropTypes from "prop-types";

import { ModuleIcon, VersionIcon, TickIcon } from "images/common";
import Card from "app/components/common/Detail/Card";

// import VersionList from './edit/ManageVersions';
import { getDuration } from "utils/common";

class Package extends Component {
  state = {
    showEditModal: false,
  };

  openEditModal = () => {
    this.setState({ showEditModal: true });
  };

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  };

  renderContent = () => {
    const { color } = this.props;
    const { listing = {} } = this.props;

    if (!listing.package) {
      return null;
    }

    return (
      <>
        <div className="flow-modules__data-table data-table">
          <table className="data-table__table">
            <tbody className="data-table__body">
              <tr className="data-table__row">
                <td className="data-table__td data-table__td_1">
                  <div className={`checkbox checkbox_${color}`}>
                    <div className="checkbox__image">
                      <ModuleIcon />
                    </div>
                  </div>
                </td>
                <td className={`data-table__td ${color}`}>
                  {listing.package && listing.package.name}
                </td>
                <td className="data-table__td">
                  {listing.package && listing.package.id}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <hr />
        <div className="overview__title mx-t-20">Versions</div>
        <div className="flow-modules__data-table data-table">
          <table className="data-table__table">
            <tbody className="data-table__body">
              {listing.versions &&
                listing.versions.map((version) => (
                  <tr className="data-table__row">
                    <td className="data-table__td data-table__td_1">
                      <div className={`checkbox checkbox_${color}`}>
                        {listing.activeVersion === version.version && (
                          <span
                            className={`data-cards__selected data-cards__selected_item ${color}`}
                          >
                            <TickIcon />
                          </span>
                        )}
                        <div className="checkbox__image">
                          <VersionIcon />
                        </div>
                      </div>
                    </td>
                    <td className={`data-table__td ${color}`}>
                      {version.version}
                    </td>
                    <td className="data-table__td">
                      {version.createdAt && getDuration(version.createdAt)}
                    </td>
                    <td className="data-table__td"></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  render() {
    const { showEditModal } = this.state;
    const { color, listing } = this.props;

    if (showEditModal) {
      if (listing.package) {
        return <div>test</div>;
      }
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        title="Package"
        subTitle="Custom module from your library."
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

Package.propTypes = {
  user: PropTypes.object,
};

export default Package;
