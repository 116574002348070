import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {FileText} from 'react-feather'
import Card from "app/components/common/Detail/Card";
// import UpdateDocumentation from './edit/UpdateDocumentation'
import {FormattedMessage} from 'react-intl'
import MarketingInformation from './MarketingInformation'
import PrivacyPolicy from './PrivacyPolicy'
import UserGuide from './UserGuide'
import License from './License'
import {
  PlanLicenseIcon,
  UserGuideIcon,
  PrivacyPolicyIcon,
  MarketingPageIcon } from 'images/common';
import messages from 'utils/message'

class DocumentSupport extends Component {
  state = {
    showEditModal: false,
    openMarketingInfo: false,
    openUserGuideInfo: false,
    openPrivacyPolicyInfo: false,
    openLicenseInfo: false,
  }

  onAddTags = tags => {
    this.setState({ tags })
  }

  openEditModal = () => {
    this.setState({ showEditModal: true })
  }

  onCloseMarketingInfo = () => {
    this.setState({openMarketingInfo: false })
  }

  onOpenMarketingInfo = () => {
    this.setState({openMarketingInfo: true })
  }

  onCloseLicenseInfo = () => {
    this.setState({openLicenseInfo: false })
  }

  onOpenLicenseInfo = () => {
    this.setState({openLicenseInfo: true })
  }

  onCloseUserGuideInfo = () => {
    this.setState({openUserGuideInfo: false })
  }

  onOpenUserGuideInfo = () => {
    this.setState({openUserGuideInfo: true })
  }

  onClosePrivacyPolicyInfo = () => {
    this.setState({openPrivacyPolicyInfo: false })
  }

  onOpenPrivacyPolicyInfo = () => {
    this.setState({openPrivacyPolicyInfo: true })
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false })
  }

  getSolution = () => {
    const { solution } = this.props

    this.closeEditModal();
    this.props.getSolutionDetailsRequest({id: solution.id})
  }

  onEditInformation = data => {
    const { solution } = this.props

    const payload = {
      ...data,
      id: solution.id
    }

    this.props.onEdit(payload, this.getSolution)
  }


  renderContent = () => {
    const {color, solution} = this.props

    if (this.state.showEditModal) {
      // return <UpdateDocumentation
      //   color={color}
      //   initialValues={{
      //     userGuide: solution.userGuide,
      //     privacyPolicy: solution.privacyPolicy,
      //     email: solution.email,
      //     phone: solution.phone,
      //     website: solution.website
      //   }}
      //   onClose={this.onCloseMarketingInfo}
      //   solution={solution}
      //   onSubmit={this.onEditInformation}/>
      return <div>test</div>
    }

    return (
      <div className="flow-modules__data-table data-table ">
        <div className="document-card">
          <div className="" onClick={this.onOpenUserGuideInfo}>
            <table className="data-table__table">
              <tbody className="data-table__body">
                <tr className="data-table__row">
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <UserGuideIcon size="20"/>
                      </div>
                    </div>
                  </td>
                  <td className={'data-table__td text-color'}>User Guide</td>
                  <td className={'data-table__td'}></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="" onClick={this.onOpenLicenseInfo}>
            <table className="data-table__table">
              <tbody className="data-table__body">
                <tr className="data-table__row">
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <PlanLicenseIcon size="20" />
                      </div>
                    </div>
                  </td>
                  <td className={'data-table__td text-color'}>Terms of Service</td>
                  <td className={'data-table__td'}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="document-card">
          <div className="" onClick={this.onOpenPrivacyPolicyInfo}>
            <table className="data-table__table">
              <tbody className="data-table__body">
                <tr className="data-table__row">
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <PrivacyPolicyIcon size="20"/>
                      </div>
                    </div>
                  </td>
                  <td className={'data-table__td text-color'}>Privacy Policy</td>
                  <td className={'data-table__td'}></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="" onClick={this.onOpenMarketingInfo}>
            <table className="data-table__table">
              <tbody className="data-table__body">
                <tr className="data-table__row">
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <MarketingPageIcon size="20"/>
                      </div>
                    </div>
                  </td>
                  <td className={'data-table__td text-color'}>Marketplace Page</td>
                  <td className={'data-table__td'}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {color, listing} = this.props

    if (this.state.openUserGuideInfo) {
      return <UserGuide
        onUpdate={this.props.onUpdate}
        onClose={this.onCloseUserGuideInfo}
        removeImage={this.props.removeImage}
        color={color}
        getSolution={this.props.getSolution}
        onConfirm={this.props.onEdit}
        data={this.props.data}
        solution={listing}/>
    }

    if (this.state.openPrivacyPolicyInfo) {
      return <PrivacyPolicy
        onUpdate={this.props.onUpdate}
        onClose={this.onClosePrivacyPolicyInfo}
        removeImage={this.props.removeImage}
        color={color}
        getSolution={this.props.getSolution}
        onConfirm={this.props.onEdit}
        data={this.props.data}
        solution={listing}/>
    }

    if (this.state.openLicenseInfo) {
      return <License
        onUpdate={this.props.onUpdate}
        onClose={this.onCloseLicenseInfo}
        removeImage={this.props.removeImage}
        color={color}
        getSolution={this.props.getSolution}
        onConfirm={this.props.onEdit}
        data={this.props.data}
        solution={listing}/>
    }

    if (this.state.openMarketingInfo) {
      return <MarketingInformation
        onUpdate={this.props.onUpdate}
        onClose={this.onCloseMarketingInfo}
        removeImage={this.props.removeImage}
        color={color}
        getSolution={this.props.getSolution}
        onConfirm={this.props.onConfirm}
        data={this.props.data}
        solution={listing}/>
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        title={<FormattedMessage {...messages.documentationAndSupport}/>}
        subTitle={<FormattedMessage {...messages.manageResourcesAndContactInformation}/>}
        className="overview"
        content={this.renderContent()}
      />
    )
  }
}

DocumentSupport.propTypes = {
  user: PropTypes.object
}

export default DocumentSupport
