import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const SingleMenu = ({
  closeMenu,
  id,
  status,
  onToogleMenu,
  hideParentClass,
  onApprove,
  onReject,
  onDeactivate,
  onActivate
}) => {
  const rejectClass = classNames("actions__option", {
    actions__option_disabled: status === "rejected" || status === "approved" || status === "inactive",
    cursor: status !== "rejected",
  });

  const activateClass = classNames("actions__option", {
    actions__option_disabled: status === "rejected" || status === "approved" || status === "pending",
    cursor: status !== "approved",
  });

  const deactivateClass = classNames("actions__option", {
    actions__option_disabled: status === "inactive" || status === "rejected" || status === "pending",
    cursor: status !== "inactive",
  });

  const approveClass = classNames("actions__option", {
    actions__option_disabled: status === "approved" || status === "rejected" || status === "inactive",
    cursor: status !== "approved",
  });

  const onRejectPartner = (id, e) => {
    e.stopPropagation();

    if (status === "rejected" || status === "approved" || status === "inactive") {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onReject(id, e);
  };

  const onApprovePartner = (payload, e, status) => {
    e.stopPropagation();
    if (status === "approved" || status === "rejected" || status === "inactive") {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onApprove(payload, e);
  };

  const onActivatePartner = (payload, e, status) => {
    e.stopPropagation();

    if (status === "rejected" || status === "approved" || status === "pending") {
      return false;
    }

    onToogleMenu(e, id);

    closeMenu();
    onActivate(payload, e);
  };

  const onDeactivatePartner = (payload, e, status) => {
    e.stopPropagation();

    if (status === "inactive" || status === "rejected" || status === "pending") {
      return false;
    }

    onToogleMenu(e, id);

    closeMenu();
    onDeactivate(payload, e);
  };

  const parentClass = classNames({
    "actions__dropdown actions__dropdown_visible": !hideParentClass,
  });

  return (
    <>
      <div className={parentClass}>
        <span
          onClick={(e) => onApprovePartner({ id }, e, status)}
          className={approveClass}
        >
        Approve Partner
        </span>
        <span
          onClick={(e) => onActivatePartner({ id }, e, status)}
          className={activateClass}
        >
        Activate Partner

        </span>
        <span
          onClick={(e) => onRejectPartner({ id }, e, status)}
          className={rejectClass}
        >
        Reject Partner
        </span>
        <span
          onClick={(e) => onDeactivatePartner({ id }, e, status)}
          className={deactivateClass}
        >
        Deactivate Partner
        </span>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  listing: PropTypes.any,
};

export default SingleMenu;
