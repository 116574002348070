import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import TableTags from "app/components/common/TableTags";

import Menu from "./SingleMenu";
import { dateFormatter } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";
import { convertDefaultStatusToStatus, getDuration } from "utils/common";

class CategoryRow extends PureComponent {
  onRedirectToCategory = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.history.push(`/category/${id}`);
  };

  getRowRender = () => {
    const {
      name,
      description,
      framework,
      dataset,
      categories,
      deviceTypes,
      createdBy,
      updatedAt,
      version,
      tags,
      modelType,
      createdAt,
      colorClassName,
      currentSortKey,
    } = this.props;

    const nameClass = classNames({
      "data-table__td_dark": currentSortKey === "name",
    });

    const descClass = classNames({
      "data-table__td_dark": currentSortKey === "description",
    });

    const frameworkClass = classNames({
      "data-table__td_dark": currentSortKey === "framework",
    });

    const createdByClass = classNames({
      "data-table__td_dark": currentSortKey === "createdBy",
    });

    const updatedAtClass = classNames({
      "data-table__td_dark": currentSortKey === "updatedAt",
    });

    const typeClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "modelType",
    });

    const datasetClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "dataset",
    });

    const categoriesClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "categories",
    });

    const deviceTypesClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "deviceTypes",
    });

    const createdAtClass = classNames({
      "data-table__td_dark": currentSortKey === "createdAt",
    });

    const versionClass = classNames({
      "data-table__td_dark": currentSortKey === "version",
    });

    return {
      name: () => (
        <div className={nameClass} title={name}>
          {name}
        </div>
      ),
      description: () => (
        <div className={descClass} title={description}>
          {description}
        </div>
      ),
      framework: () => (
        <div className={frameworkClass} title={framework}>
          {convertDefaultStatusToStatus(framework)}
        </div>
      ),
      dataset: () => (
        <div className={datasetClass}>
          {convertDefaultStatusToStatus(dataset)}
        </div>
      ),
      categories: () => (
        <div className={categoriesClass}>
          {categories &&
            categories.map((cat, index) => {
              if (
                categories.length - 1 === 0 ||
                index === categories.length - 1
              ) {
                return convertDefaultStatusToStatus(cat);
              }

              return `${convertDefaultStatusToStatus(cat)}, `;
            })}
        </div>
      ),
      deviceTypes: () => (
        <div className={deviceTypesClass}>
          {deviceTypes &&
            deviceTypes.map((cat, index) => {
              if (
                deviceTypes.length - 1 === 0 ||
                index === deviceTypes.length - 1
              ) {
                return convertDefaultStatusToStatus(cat);
              }

              return `${convertDefaultStatusToStatus(cat)}, `;
            })}
        </div>
      ),
      modelType: () => (
        <span className={typeClass}>
          {convertDefaultStatusToStatus(modelType)}
        </span>
      ),
      "createdBy.name": () =>
        createdBy && (
          <>
            <Link className={`text-link ${colorClassName}`}>
              <div onClick={(e) => this.redirectToUser(e, createdBy.id)}>
                {createdBy.name}
              </div>
            </Link>
          </>
        ),
      createdAt: () => (
        <div className={createdAtClass}>{dateFormatter(createdAt, true)}</div>
      ),
      updatedAt: () => (
        <div className={updatedAtClass}>{getDuration(updatedAt, true)}</div>
      ),
      version: () => <div className={versionClass}>{version || "-"}</div>,
      tags: () =>
        tags && <TableTags tags={tags} colorClassName={colorClassName} />,
    };
  };

  render() {
    const { colorClassName, id, onDelete, status } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <Menu id={id} workspace={this.props.workspace} onDelete={onDelete} />
        </SortableRow>
      </>
    );
  }
}

CategoryRow.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(CategoryRow));
