import { ui } from '../constants/ui';

const getCurrentDevice = ({width}) => {
  if (width <= 575.98) return 'mobile';
  if (width > 575.98 && width < 991.97) return 'tablet';
  if (width > 991.98) return 'desktop'
  return 'desktop'
}

export function updateLeftPanelStatus(payload) {
  return async (dispatch) => {
    dispatch({ type: ui.TOGGLE_LEFT_PANEL, payload: payload });
  };
}

export function updateDeviceType(payload) {
  return {
    type: ui.UPDATE_DEVICE_TYPE,
    payload: getCurrentDevice(payload)
  }
}