import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl'
import { PlayCircle } from 'react-feather'

import Card from 'app/components/common/Detail/Card';

class DemoVideo extends Component {
  state = {
    showEditModal: false
  };

  openEditModal = () => {
    this.setState({ showEditModal: true });
  };

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  };

  renderContent = () => {
    const { color } = this.props;
    const { solution = {} } = this.props;

    if (!solution.videos) {
      return null;
    }

    return (
      <>
        <div className="flow-modules__data-table data-table">
          <table className="data-table__table">
            <tbody className="data-table__body">
              {solution.videos && solution.videos.map(item =>
                <tr className="data-table__row" key={item.id}>
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <PlayCircle />
                      </div>
                    </div>
                  </td>
                  <td className={`data-table__td ${color}`}>
                    {item.name}
                  </td>
                  <td className="data-table__td">
                    {item.type}
                  </td>
                  <td className="data-table__td"/>
                </tr>

              )}
            </tbody>
          </table>
        </div>
          </>
    );
  };

  render() {
    const { showEditModal } = this.state;
    const { color, solution } = this.props;

    // if (showEditModal) {
    //   return (
    //     <VersionList
    //       onClose={this.closeEditModal}
    //       solution={solution}
    //       getSolution={this.props.getSolution}
    //       color={color}
    //     />
    //   );
    // }

    return (
      <Card
        noGrid
        color={this.props.color}
        subTitle='Video files that will be imported to the workspace library.'
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

DemoVideo.propTypes = {
  user: PropTypes.object
};

export default DemoVideo;
