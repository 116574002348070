import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import OutsideClickHandler from "react-outside-click-handler";
import { TickIcon } from "images/common";

class SmallCard extends Component {
  state = {
    showSingleMenu: false,
    settingSelected: null,
  };

  isActive = (id) => {
    const { selectedItems = [] } = this.props;

    return selectedItems.includes(id);
  };

  isSelected = (id) => {
    const { selectedItems = [] } = this.props;

    return selectedItems.indexOf(id) > -1;
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false, settingSelected: null });
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      showSingleMenu: true,
      settingSelected: this.props.id,
    });
  };

  toggleSelect = (id, e) => {
    const {
      onSelect,
      selectedItems = [],
      inputType,
      allowOnlySingleSelect,
    } = this.props;
    if (allowOnlySingleSelect) {
      return onSelect(id, e);
    }

    if (inputType === "radio") {
      return onSelect(id);
    }

    if (this.isSelected(id)) {
      onSelect(
        selectedItems.filter((item) => item !== id),
        e
      );
    } else {
      onSelect(selectedItems.concat(id), e);
    }
  };

  render() {
    const { initials, monthlyPriceId, yearlyPriceId } = this.props;


    return (
      <div
        className="data-card"
        onClick={(e) => {
          if(this.props.onCardClick) {
            return this.props.onCardClick(this.props.id, e);
          }
          this.toggleSelect(this.props.id, e)
        }}
      >
        <div
          className={classNames("data-cards__item", {
            isActive: this.state.settingSelected === this.props.id,
            'data-card__loading': this.props.isLoading,
            [`data-cards__item_${this.props.colorClassName}`]: this.props
              .colorClassName,
            "data-cards__item_selected": this.isActive(this.props.id),
          })}
        >
          <div className="grid-row grid-row_aic">
            <div className="grid-col grid-col_auto">
              <div
                className={classNames("checkbox", {
                  [`checkbox_${this.props.colorClassName}`]: this.props
                    .colorClassName,
                })}
              >
                {monthlyPriceId && monthlyPriceId === this.props.id && (
                  <span
                    className={`data-cards__selected ${this.props.colorClassName}`}
                  >
                    <TickIcon />
                  </span>
                )}
                {yearlyPriceId && yearlyPriceId === this.props.id && (
                  <span
                    className={`data-cards__selected ${this.props.colorClassName}`}
                  >
                    <TickIcon />
                  </span>
                )}
                <input
                  type="checkbox"
                  className="checkbox__input"
                  checked={this.isActive(this.props.id)}
                  onClick={(e) => this.toggleSelect(this.props.id, e)}
                  readOnly
                />
                {initials && <div className="checkbox__image">{initials}</div>}
                {!initials && typeof this.props.CheckboxImage === "function" && (
                  <div className="checkbox__image">
                    <this.props.CheckboxImage />
                  </div>
                )}
                {!initials &&
                  typeof this.props.CheckboxImage !== "function" && (
                    <div className="checkbox__image">
                      {this.props.CheckboxImage}
                    </div>
                  )}
              </div>
            </div>
            <div className="grid-col">
              <div className="data-cards__heading h4">{this.props.heading}</div>
              <div className="data-cards__subheading h5">
                {this.props.subheading}
              </div>
            </div>
            {!this.props.hideSingleMenu && (
              <OutsideClickHandler onOutsideClick={this.onCloseSingleMenu}>
                <div className="data-cards__actions actions">
                  <button
                    type="button"
                    className={classNames("actions__button", {
                      [this.props.colorClassName]: this.props.colorClassName,
                    })}
                    onClick={this.onToogleMenu}
                  />
                  {this.state.showSingleMenu &&
                    React.Children.map(this.props.children, (child) => {
                      return React.cloneElement(child, {
                        closeMenu: this.onCloseSingleMenu,
                        onToogleMenu: this.onToogleMenu,
                      });
                    })}
                </div>
              </OutsideClickHandler>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SmallCard.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subheading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  colorClassName: PropTypes.string,
  selectedItems: PropTypes.array,
  id: PropTypes.string,
  onCardClick: PropTypes.func,
  onSelect: PropTypes.func,
  CheckboxImage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default SmallCard;
