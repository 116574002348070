import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import appMessages from "utils/message";
import CustomInput from "app/components/common/Input/CustomInput";
import ProcessPage from "app/components/common/ProcessPage";
import { defaultNotifier } from "functions/notificationHandler";
import classNames from "classnames";

class AddonPlan extends Component {
  state = {
    applicationLimit: null,
    applicationVersionLimit: null,
  };

  componentDidMount() {
    const { plan } = this.props;

    this.setState({
      unit: plan.unit,
      quantity: plan.quantity,
    });
  }

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onChangeInt = (name, value) => {
    this.setState({ [name]: parseInt(value, 10) });
  };

  onChangeSwitch = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    const payload = {
      id: this.props.plan.id,
      unit: this.state.unit,
      quantity: this.state.quantity,
    };

    await this.props.updatePlanAction(payload);

    this.props.onClose();
  };

  render() {
    const { quantity, unit } = this.state;
    const { color } = this.props;

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {() => (
          <>
            <div className="headline">
              <h1 className="headline__title">Add-on Plan Configuration</h1>
              <h3 className="headline__subtitle">
                Manage the Plans usage limit
              </h3>
            </div>
            <div className="login">
              <div className="plan_quota_wrapper">
                <div className="plan_quota_container">
                  <div className="plan_quoto_block">
                    <div className="plan_quota_title">
                      <FormattedMessage {...appMessages.itemLimits} />
                    </div>
                    <div className="grid-row">
                      <div className="grid-col">
                        <div className={`text-field text-field_${color}`}>
                          <CustomInput
                            value={quantity}
                            type="number"
                            parentColor={color}
                            label="Quantity"
                            onChange={(e) =>
                              this.onChangeInt("quantity", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="grid-col">
                        <div className={`text-field text-field_${color}`}>
                          <CustomInput
                            value={unit}
                            label="Unit"
                            parentColor={color}
                            onChange={(e) =>
                              this.onChange("unit", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="login__actions">
                <button
                  onClick={this.onSubmit}
                  className={classNames("button", `button_${this.props.color}`)}
                >
                  <FormattedMessage {...appMessages.confirm} />
                </button>
              </div>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

export default AddonPlan;
