import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { XCircleIcon } from "images/common";

export default class CustomMultiSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFocussed: false,
      options: props.options || [],
      value: props.value || [],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      options: nextProps.options || [],
      value: nextProps.value || [],
    });
  }

  toggleFocussed = () => {
    if (this.props.readOnly) {
      return;
    }

    setTimeout(() => {
      this.setState({
        isFocussed: !this.state.isFocussed,
      });
    }, 200);
  };

  selectOption(selectedOption) {
    if (!this.state.value.includes(selectedOption)) {
      this.setState(
        {
          value: this.state.value.concat([selectedOption]),
        },
        () => {
          this.props.onChange(this.state.value);
        }
      );
    }
  }

  removeValue(id) {
    if (this.props.readOnly) {
      return;
    }

    const index = this.state.value.indexOf(id);
    const values = this.state.value.concat([]);
    values.splice(index, 1);
    this.setState(
      {
        value: values,
      },
      () => {
        this.props.onChange(this.state.value);
      }
    );
  }

  render() {
    const { options, value } = this.state;
    const { label, allOptions } = this.props;
    const inputClass = classNames({
      "select-field__input": true,
      "select-field__input_filled": value && value.length,
      "select-field__input_focused": !!this.state.isFocussed,
    });
    const parentClass =
      this.props.parentClass || "select-field select-field_accent1";

    return (
      <div
        className={classNames(parentClass, {
          "select-field_readonly": this.props.readOnly,
        })}
      >
        {value.length ? (
          <div className="select-field__options tags tags_accent3">
            {(allOptions || options || [])
              .filter((opt) => value.includes(opt.value))
              .map((opt) => (
                <span className="tags__item" key={opt.value}>
                  {opt.label}
                  <button
                    type="button"
                    className="tags__remove"
                    onClick={() => {
                      this.removeValue(opt.value);
                    }}
                  >
                    <XCircleIcon />
                  </button>
                </span>
              ))}
          </div>
        ) : (
          <></>
        )}
        <input
          type="text"
          readOnly
          className={inputClass}
          onFocus={this.toggleFocussed}
          onBlur={this.toggleFocussed}
        />
        <label className="select-field__label">{label}</label>
        <div className="select-field__dropdown">
          <Scrollbars
            style={{ maxHeight: "312px", minHeight: "200px" }}
            universal
            renderThumbVertical={(props) => (
              <div {...props} className="thumb-vertical" />
            )}
          >
            {(options || [])
              .filter((opt) => !value.includes(opt.value))
              .map((opt) => {
                const itemClass = classNames(
                  "select-field__option select-field__option_multi",
                  {
                    "no-cursor": opt.children && opt.children.length,
                  }
                );

                return (
                  <div key={opt.value}>
                    <div
                      data-label={opt.label}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (!opt.children || !opt.children.length) {
                          this.selectOption(opt.value);
                        }
                      }}
                      className={itemClass}
                    >
                      {opt.label}
                    </div>
                    {opt.children &&
                      opt.children
                        .filter((opt) => !value.includes(opt.value))
                        .map((item) => (
                          <div
                            data-label={item.label}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.selectOption(item.value);
                            }}
                            className="select-field__option select-field__option_multi"
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.label}
                          </div>
                        ))}
                  </div>
                );
              })}
          </Scrollbars>
        </div>
        <div className="select-field__underscore" />
      </div>
    );
  }
}

CustomMultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  parentClass: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
};
