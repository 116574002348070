import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import { axiosInstance as unauthAxios } from 'config/config';
import { BASE_URL } from 'app/constants/common';

export function obtainToken(data) {
  const loginQuery = {
    mutation: {
      login: {
        __args: {
          email: data.email,
          password: data.password,
        },
        token: true,
        user: {
          firstName: true,
          lastName: true
        }
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(loginQuery, { pretty: true });

  const config = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  };
  return unauthAxios(config);
}
