import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import ProcessPage from '../common/ProcessPage'
import LoginForm from './Form'

import {loginUser} from '../../actions/login'
import { routeMapping } from "app/constants/routes";

class Login extends Component {

  componentDidMount() {
    if(this.props.user.isLoggedIn) {
      this.props.history.push(routeMapping.DASHBOARD.path)
    }
  }

  onLogin = async (data) => {
    await this.props.loginUser(data, this.props.history)
  }

  render() {
    return (
      <ProcessPage>
        {() => (
          <div className="popup__inner">
            <div className="headline">
              <h1 className="headline__title center login_title">Viso Suite</h1>
              <h3 className="headline__subtitle">Login to access the Admin Panel</h3>
            </div>
            <div className="login">
              <LoginForm onSubmit={this.onLogin}
                isLoading={this.props.isLoading}/>
            </div>
          </div>
        )}
      </ProcessPage>
    )
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const withConnect = connect(mapStateToProps,{loginUser})

export default withRouter(withConnect(Login))
