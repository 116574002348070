import {sortBy} from 'lodash'
import { model } from "../constants/model";
import axios from 'axios'

import {
  getModels,
  uploadModelFile,
  getModelDetails,
} from "../api/model";
import { defaultNotifier } from "functions/notificationHandler";

export function listModel(payload) {
  return async (dispatch) => {
    dispatch({ type: model.GET_MODELS_PENDING });

    try {
      const { data } = await getModels(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      const result = sortBy(data.data.models, ['name']);

      dispatch({
        type: model.GET_MODELS_FULFILLED,
        payload: result,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the models");

      dispatch({
        type: model.GET_MODELS_REJECTED,
        error: {
          message: "Error while fetching models.",
        },
      });
    }
  };
}

export function getModel(payload) {
  return async (dispatch) => {
    dispatch({ type: model.GET_MODEL_DETAILS_PENDING });

    try {
      const { data } = await getModelDetails(payload);
      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      dispatch({
        type: model.GET_MODEL_DETAILS_FULFILLED,
        payload: data.data.model,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the model");

      dispatch({
        type: model.GET_MODEL_DETAILS_REJECTED,
        error: {
          message: "Error while fetching the model.",
        },
      });
    }
  };
}

export function getFileData(data, config) {
  const formData = new FormData();

  data.response.fields && Object.keys(data.response.fields).forEach(item => {
    formData.append(item, data.response.fields[item]);
  })
  formData.append('file', data.file)

  return axios.post(data.response.url, formData, config)
}

export function modelUpload(payload, config) {
  return async (dispatch) => {
    dispatch({ type: model.UPLOAD_FILE_REQUEST });

    try {
      const { data } = await uploadModelFile(payload);
      if (data.errors) {
        return defaultNotifier(data.errors);
      }

        await getFileData({response: data.data.uploadFile, file: payload.file}, config);

        dispatch({
          type: model.ADD_FILE,
          payload: data.data.uploadFile.fields.key
        });
    } catch (err) {
      defaultNotifier("Failed to uploading the model");

      dispatch({
        type: model.REMOVE_FILE,
        error: {
          message: "Error while uploading the model.",
        },
      });
    }
  };
}

export function onClearFile() {
  return async (dispatch) => {
    dispatch({ type: model.REMOVE_FILE });
  };
}


