import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "app/components/Button";
import { LockIcon, EditPencilIcon, ImportIcon } from "images/common";
import Modal from "../../common/Modal";

class Card extends Component {
  state = {
    lockedWarning: false,
  };

  onLockSelect = () => {
    this.setState({ lockedWarning: true });
  };

  renderModal = () => {
    const buttons = [
      <button
        className="modal__cancel"
        key="cancel"
        onClick={() => this.setState({ lockedWarning: false })}
      >
        Close
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={this.props.title}
        body={"Locked because it is in use"}
        onClose={() => this.setState({ lockedWarning: false })}
      />
    );
  };

  render() {
    const { lockedWarning } = this.state;
    const { noGrid, secondaryContent, customTag, catalog } = this.props;

    return (
      <div className={classnames('content-box relative', this.props.className, {
        card_loading: this.props.isLoading,
      })}
      >
        {lockedWarning && this.renderModal()}
        {customTag && (
          <div className="content-tag">
            <div className="tags">{this.props.customTag}</div>
          </div>
        )}
          {this.props.customText && (
          <button
            className={classnames(
              "overview__edit",
              `overview__edit_${this.props.color}`
            )}
            onClick={this.props.action}
          >
            {this.props.customText === 'Update' ?
            <>
                      <EditPencilIcon />
                      {this.props.customText} 
            </>
            :
            <>{this.props.customText}</>
            }
          </button>
        )}
        {this.props.secondaryCustomText && (
          <button
            className={classnames(
              "overview__edit",
              "overview__edit_secondary",
              `overview__edit_${this.props.color}`
            )}
            onClick={this.props.secondaryAction}
          >
            {/* <ImportIcon/> */}
            {this.props.secondaryCustomText}
          </button>
        )}
        {this.props.onEdit && !this.props.isLocked && (
          <Button
          className={classnames('overview__edit', `overview__edit_${this.props.color}`)}
          onClick={this.props.onEdit}
        >
            <EditPencilIcon />
            Edit
            </Button>
        )}
    
        {!!this.props.isLocked && (
          <button
            onClick={this.onLockSelect}
            className={classnames("overview__edit")}
          >
            <LockIcon />
          </button>
        )}
        <div className="overview__title">{this.props.title}</div>
        {this.props.subTitle && (
          <div className="overview__subtitle">{this.props.subTitle}</div>
        )}
        {noGrid && this.props.content}
        {!noGrid && <div className={classnames('grid-row', {
          'full-width': this.props.fullWidth
        })}>{this.props.content}</div>}
        {secondaryContent && (
          <div className="grid-row">{this.props.secondaryContent}</div>
        )}
      </div>
    );
  }
}

Card.propTypes = {
  color: PropTypes.string,
  subTitle: PropTypes.string,
  customText: PropTypes.string,
  action: PropTypes.func,
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLocked: PropTypes.bool,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  color: "accent0",
  className: "overview",
};

export default Card;
