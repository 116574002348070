export const MODEL_TYPE = [
  {
    label: 'Face Recognition',
    value: 'face_recognition'
  },
  {
    label: 'Image Classification',
    value: 'image_classification'
  },
  {
    label: 'Image Segmentation',
    value: 'image_segmentation'
  },
  {
    label: 'Key Point Detection',
    value: 'key_point_detection'
  },
  {
    label: 'Object Detection',
    value: 'object_detection'
  },
  {
    label: 'Object Tracking',
    value: 'object_tracking'
  },
  {
    label: 'Pose Estimation',
    value: 'pose_estimation'
  },
];

export const MODEL_CATEGORY = [
  {
    label: 'Behaviour Analysis',
    value: 'behaviour_analysis'
  },
  {
    label: 'Facial Recognition',
    value: 'facial_recognition'
  },
  {
    label: 'License Plate Recognition',
    value: 'license_plate_recognition'
  },
  {
    label: 'Motion Analysis',
    value: 'motion_analysis'
  },
  {
    label: 'Traffic Analysis',
    value: 'traffic_analysis'
  }
];

export const FRAMEWORK = [
  {
    label: 'OpenVINO',
    value: 'openvino'
  },
  {
    label: 'TensorFlow',
    value: 'tensorflow'
  },
  {
    label: 'Tensorflow-GPU',
    value: 'tensorflow_gpu'
  },
  {
    label: 'Tensorflow-Lite',
    value: 'tensorflow_lite'
  },
  {
    label: 'PyTorch',
    value: 'torch'
  },
  {
    label: 'PyTorch GPU',
    value: 'torch_gpu'
  }
];

export const DEVICE_TYPE = [
  {
    label: 'CUDA',
    value: 'CUDA'
  },
  {
    label: 'CPU',
    value: 'CPU'
  },
  {
    label: 'FPGA',
    value: 'FPGA'
  },
  {
    label: 'eTPU',
    value: 'eTPU'
  },
  {
    label: 'GNA',
    value: 'GNA'
  },
  {
    label: 'HDDL',
    value: 'HDDL'
  },
  {
    label: 'iGPU',
    value: 'iGPU'
  },
  {
    label: 'MYRIAD',
    value: 'MYRIAD'
  },
  {
    label: 'nGPU',
    value: 'nGPU'
  },
];

export const MODEL_DATASET = [
  {
    label: 'COCO',
    value: 'coco',
  },
  {
    label: 'Darknet',
    value: 'darknet',
  },
  {
    label: 'Kitti',
    value: 'kitti',
  },
  {
    label: 'Imagenet',
    value: 'Imagenet',
  },
  {
    label: 'CC100',
    value: 'CC100',
  },
  {
    label: 'Cifar10',
    value: 'Cifar10',
  },
  {
    label: 'MNIST',
    value: 'MNIST',
  },
  {
    label: 'CrowdHuman',
    value: 'CrowdHuman',
  },
  {
    label: 'Cifar100',
    value: 'Cifar100',
  },
  {
    label: 'Fashion-MNIST',
    value: 'Fashion-MNIST',
  },
  {
    label: 'OpenImages',
    value: 'OpenImages',
  }
];

export const MODEL_ARCHITECTURE = [
  {
    label: 'CenterNet',
    value: 'CenterNet',
  },
  {
    label: 'DETR',
    value: 'DETR',
  },
  {
    label: 'EfficientDet',
    value: 'EfficientDet',
  },
  {
    label: 'Fast-R-CNN',
    value: 'Fast-R-CNN',
  },
  {
    label: 'Faster-R-CNN',
    value: 'Faster-R-CNN',
  },
  {
    label: 'R-CNN',
    value: 'R-CNN',
  },
  {
    label: 'RetinaNet',
    value: 'RetinaNet',
  },
  {
    label: 'SSD',
    value: 'SSD',
  },
  {
    label: 'YOLOv3',
    value: 'YOLOv3',
  }, {
    label: 'YOLOv3-tiny',
    value: 'YOLOv3-tiny',
  }, {
    label: 'YOLOv4-tiny',
    value: 'YOLOv4-tiny',
  }, {
    label: 'YOLOv5',
    value: 'YOLOv5',
  }, {
    label: 'YOLOv7',
    value: 'YOLOv7',
  }, {
    label: 'YOLOv8',
    value: 'YOLOv8',
  }, {
    label: 'YOLOv9',
    value: 'YOLOv9',
  }, {
    label: 'YOLOX',
    value: 'YOLOX',
  }, {
    label: 'YOLOR',
    value: 'YOLOR',
  }, {
    label: 'Other',
    value: 'Other',
  }
];

export const LICENSE = [
  {
    label: 'apache-2.0',
    value: 'apache-2.0',
  },
  {
    label: 'mit',
    value: 'mit',
  },
  {
    label: 'gpl-3.0',
    value: 'gpl-3.0',
  },
  {
    label: 'gpl-2.0',
    value: 'gpl-2.0',
  },
  {
    label: 'agpl-3.0',
    value: 'agpl-3.0',
  },
  {
    label: 'bsd-3-clause',
    value: 'bsd-3-clause',
  },
  {
    label: 'bsd-2-clause',
    value: 'bsd-2-clause',
  },
  {
    label: 'bsd-0-clause',
    value: 'bsd-0-clause',
  },
  {
    label: 'bsd-4-clause',
    value: 'bsd-4-clause',
  }, {
    label: 'lgpl-3.0',
    value: 'lgpl-3.0',
  }, {
    label: 'lgpl-2.0',
    value: 'lgpl-2.0',
  }, {
    label: 'lgpl-2.1',
    value: 'lgpl-2.1',
  }, {
    label: 'cc0',
    value: 'cc0',
  }, {
    label: 'cc-by-nc',
    value: 'cc-by-nc',
  }, {
    label: 'cc-by',
    value: 'cc-by',
  }, {
    label: 'cc-by-sa',
    value: 'cc-by-sa',
  }, {
    label: 'mpl',
    value: 'mpl',
  }, {
    label: 'openrail',
    value: 'openrail',
  }
];
