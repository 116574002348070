import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Codesandbox } from "react-feather";
import { getInitials, dateFormatter } from "utils/common";
import Card from "app/components/common/Detail/Card";
import { FormattedMessage } from "react-intl";
import { WorkspaceIcon } from "images/common";

import messages from "utils/message";

class AssociatedContact extends Component {
  state = {
    showListPage: false,
  };

  onRedirectToUser = (id) => {
    // this.props.history.push(`${routeMapping.SETTINGS_USER.path}/${id}`)
  };

  getStatus = (status) => {
    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === 'active',
        "data-table__status_neg": status === 'inactive',
        "data-table__status_neutral": status === 'deleted',
      }
    );

    return <div className={className}>{status}</div>
  };

  renderContent = () => {
    const { color, payment: {workspace = {}} } = this.props;

    const contactName = (workspace && workspace.billingContact && workspace.billingContact.name) || 
    workspace && workspace.createdBy && workspace.createdBy.name;
    const nameList = contactName && contactName.split(' ');

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
              <tr className="data-table__row">
                <td className="data-table__td data-table__td_1">
                  <div className={`checkbox checkbox_${color}`}>
                    <div className="checkbox__image">
                    {nameList && getInitials(nameList[0], nameList[1])}
                    </div>
                  </div>
                </td>
                <td className="data-table__td">
                  {contactName}
                </td>
                <td className="data-table__td">
                  {contactName && (workspace.billingContact.email || workspace.createdBy.email)}
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <Card
        noGrid
        color={this.props.color}
        title="Associated Billing Contact"
        subTitle="The current billing contact of the workspace."
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

AssociatedContact.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default withRouter(AssociatedContact);
