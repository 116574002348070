import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'
import { FilePlus } from "images/common";


const columnList = [{
  label: 'Workspace',
  isSortable: false,
  isLocked: true,
  sortKey: 'name'
}, {
  label: <FormattedMessage {...appMessages.status} />,
  sortKey: 'status',
  isSortable: false,
}, {
  label: <FormattedMessage {...appMessages.workspaceID} />,
  sortKey: 'id',
  isSortable: false,
},
{
  label: <FormattedMessage {...appMessages.subscribedAt} />,
  sortable: false,
  isSortable: false,
  sortKey: 'createdAt'
},
]

class ModuleTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        rows={data || []}
        notFoundIcon={<FilePlus/>}
        colorClassName={color}
        CheckboxImage={FilePlus}
        isSelectable
        workspace={this.props.workspace}
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSortChange}
        rowClick={this.props.onRowClick}
      />
    )
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ModuleTable
