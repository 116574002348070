import ListScroll from 'app/components/common/ListScroll';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { routeMapping } from 'routes';
import appMessages from 'utils/message';
import Pagination from 'app/components/Pagination';
import Table from 'app/components/common/Table';
import { WorkspaceIcon } from 'images/common';
import SortableRow from 'app/components/common/Table/SortableRow';

const result = [
]

const columnList = [{
  label: <FormattedMessage {...appMessages.applicationName} />,
  sortKey: 'name',
  isLocked: true,
  isSortable: true,
},
{
  label: 'Test',
  sortKey: 'name',
  isLocked: true,
  isSortable: true,
},
{
  label: 'Test',
  sortKey: 'name',
  isLocked: true,
  isSortable: true,
},
];

const getRowRender = () => {

  return {
    name: () => <div>Name</div>,
    type: () => <div>Type</div>,
  };
}

const Row = (props) => {

  return (
    <SortableRow
      {...props}
      columns={columnList}
      customRender={getRowRender()}
      appendToSetting={(
        <div
          className="actions actions_update actions_icon"
        >
          <span
            className='actions_icon_item  actions_icon_disabled'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span>
              <WorkspaceIcon />
            </span>
          </span>
        </div>
      )}
    >
    </SortableRow>
  )
}

class PreLoaderList extends React.Component {
  render() {
    const { color } = this.props;

    return (
      <>
          <ListScroll>
            {() => (
              <div>
                <div className="headline">
                  <h1 className="headline__title loading">
                    <span>Loading</span>
                  </h1>
                </div>
                <div className={`secondary-nav secondary-nav_${color} secondary-nav_loading`}>
                  <div
                    className="secondary-nav__link"
                  >
                    <span>Testing</span>
                  </div>
                  <div
                    className="secondary-nav__link"
                  >
                   <span>Testing</span>
                  </div>

                </div>
                <Table
                  columns={[]}
                  RowComponent={Row}
                  rows={result}
                  CheckboxImage={WorkspaceIcon}
                  isSelectable
                  withColumnSelect
                  colorClassName={color}
                  isLoading
                />
                <div className='loading'>
                  <Pagination
                    currentPage={1}
                    setCurrentPage={this.setCurrentPage}
                    onChangeLimit={this.onChangeLimit}
                    limit={10}
                    totalPage={10}
                  />
                </div>
              </div>
            )}
          </ListScroll>
      </>
    );
  }
}

export default PreLoaderList;
