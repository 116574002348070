import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';

const CHARACTER_SIZE = 200

class CustomTextarea extends React.PureComponent {
  constructor(props) {
    super(props)

    this.inputText = React.createRef()
  }

  state = {
    showError: false
  };

  onBlur = e => {
    if (!this.inputText.current.value) {
      this.setState({ showError: true });
    }

    if (this.inputText.current.value) {
      this.setState({ showError: false });
    }
  };

  onChange = (e) => {
    const value = this.inputText.current.value

    if (this.inputText.current.value) {
      this.setState({ showError: false });
    }

    if (value.length > CHARACTER_SIZE) {
      return null
    }
    this.props.onChange(e)
  }

  render() {
    const { showError } = this.state;
    const { parentClass } = this.props;

    const inputClass = classNames({
      'text-field__input': true,
      'text-field__input_filled': !!this.props.value
    });

    const parentCustomClass = classNames('text-field', {
      [`text-field_${parentClass}`]: this.props.parentClass,
      'text-field_invalid': this.props.isInvalid && showError
    });

    return (
      <>
        <div className={parentCustomClass}>
          <TextareaAutosize
            rows={1}
            disabled={this.props.readOnly}
            maxRows={10}
            onBlur={e => this.onBlur(e)}
            value={this.props.value}
            className={inputClass}
            onChange={this.onChange}
            ref={this.inputText}
          />
          <label className="text-field__label text-field__description">
            {this.props.label}
          </label>
          <div className="text-field__underscore" />
          {showError && this.props.hintText && (
            <div className="text-field__hint text-field__hint_r">
              {this.props.hintText}
            </div>
          )}
        </div>
        {!showError && !this.props.hideCharacterCounter &&  <div className="text-field__counter">{(this.props.value && this.props.value.length) || 0}/{CHARACTER_SIZE}</div>}
      </>
    );
  }
}

CustomTextarea.propTypes = {
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
export default CustomTextarea;
