import { auth } from '../constants/login';

import { obtainToken } from '../api/login';
import { defaultNotifier } from 'functions/notificationHandler'
import { routeMapping } from "app/constants/routes";


export function loginUser(payload, history) {
  return async (dispatch) => {
    dispatch({ type: auth.USER_LOGIN_PENDING });

    try {
      const {data} = await obtainToken(payload);

      if (data.errors) {
        return defaultNotifier(data.errors)
      }

      localStorage.setItem('accessToken', data.data.login.token)
  
      dispatch({
        type: auth.USER_LOGIN_FULFILLED,
        payload: data.data.login,
      });
      history.push(routeMapping.WORKSPACES.path)
      
    } catch (err) {
      defaultNotifier('Failed to login')

      dispatch({
        type: auth.USER_LOGIN_REJECTED,
        error: {
          message: 'Error while login.',
        },
      });
    }
  };
}

export function logout(history) {
  localStorage.clear()
  return async (dispatch) => {
    dispatch({ type: auth.USER_LOGOUT });
    history.push(routeMapping.LOGOUT.path)
  };
}
