import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

const Accordion = ({title, description, noLine, alwaysOpen, isOpen, isChanged}) => {
  const [isExpand, onToggle] = useState(alwaysOpen);

  const accordionWrapperClass = classNames('accordion-wrapper-body', {
    'accordion-no-height': alwaysOpen ? false : !isExpand,
  })

  const accordionWrapperHeaderClass = classNames('accordion-wrapper-headers', {
    'accordion-wrapper-no-height': !isExpand,
    'accordion-wrapper-headers-container': isExpand
  })

  const accordionContainerClass = classNames('accordion-wrapper', {
    'accordion-container-no-height': !isExpand,
    'accordion-container': isExpand
  })

  useEffect(() => {
    onToggle(isOpen)
  }, [isOpen, isChanged])

  return (
    <>
      <div className={accordionContainerClass}>
        <div className={accordionWrapperHeaderClass} onClick={(e)=> {
          onToggle(alwaysOpen ? true : !isExpand);
        }}>
          <div className="accordion-wrapper-header">{title}</div>
          <div>{isExpand ? <ChevronUp /> : <ChevronDown />}</div>
        </div>
        <div className={accordionWrapperClass}>{description}</div>
      </div>
        {!noLine && <div className="accordion-hr"></div>}
    </>
  );
};

export default Accordion;
