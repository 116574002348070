export const listing = {
GET_LISTING_PENDING: 'GET_LISTING_PENDING',
GET_LISTING_FULFILLED: 'GET_LISTING_FULFILLED',
GET_LISTING_REJECTED: 'GET_LISTING_REJECTED',

GET_DASHBOARD_PENDING: 'GET_DASHBOARD_PENDING',
GET_DASHBOARD_FULFILLED: 'GET_DASHBOARD_FULFILLED',
GET_DASHBOARD_REJECTED: 'GET_DASHBOARD_REJECTED',


GET_LISTING_DETAILS_PENDING: 'GET_LISTING_DETAILS_PENDING',
GET_LISTING_DETAILS_FULFILLED: 'GET_LISTING_DETAILS_FULFILLED',
GET_LISTING_DETAILS_REJECTED: 'GET_LISTING_DETAILS_REJECTED',
}