import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Codesandbox } from "react-feather";
import { getInitials, dateFormatter } from "utils/common";
import Card from "app/components/common/Detail/Card";
import AgentList from "app/components/workspaces/manageAgents";

class VisoAgents extends Component {
  state = {
    showListPage: false,
  };
  onRedirectToUser = (id) => {
    // this.props.history.push(`${routeMapping.SETTINGS_USER.path}/${id}`)
  };

  renderContent = () => {
    const { color } = this.props;
    const { agents } = this.props;

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {agents.map((agent) => (
              <tr className="data-table__row">
                <td className="data-table__td data-table__td_1">
                  <div className={`checkbox checkbox_${color}`}>
                    <div className="checkbox__image">
                      <Codesandbox />
                    </div>
                  </div>
                </td>
                <td className={`data-table__td ${color}`}>
                  {agent.deviceType}{" "}
                  <span className="label-color">
                    • {agent.visoAgentVersion}
                  </span>
                  {agent.osVersion && <span className="label-color">
                  {' '}• {agent.osVersion}
                  </span>}
                </td>
                <td className="data-table__td">
                  {agent.updatedAt && dateFormatter(agent.updatedAt, true)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { workspace } = this.props;

    if (this.state.showListPage) {
      return (
        <AgentList 
        workspaceName={workspace.name}
        onClose={() => this.setState({ showListPage: false })} />
      );
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        customText={"View"}
        action={() => this.setState({ showListPage: true })}
        title="Viso Agents"
        subTitle="Currently installed Viso Agents"
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

VisoAgents.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default withRouter(VisoAgents);
