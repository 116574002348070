import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'

import Card from 'app/components/common/Detail/Card'

import appMessges from 'utils/message'
import {NodeIcon} from 'images/common'
import VerticalButtonMenu from 'app/components/common/VerticalButtonMenu';
import Menu from './SingleMenu'
import AssignModule from '../../../Create/ModuleAssign'

class AssociatedNode extends Component {
  state = {
    showEditModal: false
  }

  openEditModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showEditModal: true })
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false })
  }

  onEditRole = data => {
    const { tags } = this.state
    const { user } = this.props

    const payload = {
      ...data,
      tags,
      id: user.id
    }

    this.props.onEdit(payload, this.closeEditModal)
  }

  renderContent = () => {
    const { model, color } = this.props

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {model && model.associatedNodes &&
            model.associatedNodes
              .map((item, index) => {
                return (
                  <tr className="data-table__row" onClick={this.openEditModal} key={index} >
                    <td className="data-table__td data-table__td_1">
                      <div className={`checkbox checkbox_${color}`}>
                        <div className="checkbox__image">
                          <NodeIcon/>
                        </div>
                      </div>
                    </td>
                    <td className='data-table__td data-table__td_dark'>{item.node}</td>
                    <td className='data-table__td'>{item.module}</td>
                    <td className="data-table__td data-table__overflow">
                      <span>
                        <VerticalButtonMenu customClass={color} allowAutoClose>
                          <Menu
                            model={model}
                            id={item.node}
                            onUnassign={this.props.onUnassign}
                          />
                        </VerticalButtonMenu>
                      </span>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    if (this.state.showEditModal) {
      return <AssignModule
        color={this.props.color}
        closeEditModal={this.closeEditModal}
        getModel={this.props.getModel}
        model={this.props.model}/>
    }

    return (
      <Card
        noGrid
        isLoading={this.props.isLoading}
        color={this.props.color}
        customText={<FormattedMessage {...appMessges.add}/>}
        action={this.openEditModal}
        title={<FormattedMessage {...appMessges.associatedNodes}/>}
        subTitle={<FormattedMessage {...appMessges.associatedNodesDesc}/>}
        className="flow-modules"
        content={this.renderContent()}
      />
    )
  }
}

AssociatedNode.propTypes = {
  user: PropTypes.object
}

export default AssociatedNode
