import React, { Component } from 'react';
import classNames from 'classnames';
import { ChevronDown, ChevronRight, ChevronLeft } from 'react-feather';
import OutsideClickHandler from 'react-outside-click-handler';

class ActionsView extends Component {
  state = {
    showPageLimit: false,
    totalPage: 30,
    limit: this.props.limit || 10,
    currentPage: 1,
  };

  hidePageLimit = () => {
    this.setState({ showPageLimit: false });
  };

  showPageLimit = () => {
    this.setState({ showPageLimit: true });
  };

  setCurrentPage = (currentPage) => {
    this.setState({currentPage: currentPage})
    this.props.setCurrentPage(currentPage)
  }

  onPrevious = () => {
    if (this.props.currentPage !== 1) {
      this.setState({currentPage: this.props.currentPage - 1})
      this.props.setCurrentPage(this.props.currentPage - 1)
    }

  }

  onNext = () => {
    const {limit, totalPage, currentPage} = this.props;

    if ((currentPage * limit) < totalPage) {
      this.setState({currentPage: currentPage + 1})
      this.props.setCurrentPage(currentPage + 1)
    }

  }

  setLimit = (payload) => {
    this.props.onChangeLimit(payload)
    this.setState({limit: payload})
  }

  render() {
    const {limit} = this.state;
    const {totalPage, currentPage, hidePage, invoicePage, paymentPage} = this.props;
    const totalLimit = limit * currentPage;

    return (
      <>
        <hr className="pagination-hr" />
        <div className="pagination">
          <OutsideClickHandler onOutsideClick={this.hidePageLimit}>
            <span className="pagination-item">Items per page</span>
            <span className="pagination-page-section">
              <span
                className="pagination-page-limit"
                onClick={this.showPageLimit}
              >
                <span className="current-page">{limit}</span>
                <span className="pagination-svg">
                  <ChevronDown size={16} />
                </span>
              </span>
              {this.state.showPageLimit && !invoicePage && !paymentPage && (
                <div
                  className="pagination-selector"
                  onClick={this.hidePageLimit}
                >
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(10)}>10</h4>
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(25)}>25</h4>
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(50)}>50</h4>
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(100)}>100</h4>
                </div>
              )}
                {this.state.showPageLimit && invoicePage && (
                <div
                  className="pagination-selector"
                  onClick={this.hidePageLimit}
                >
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(10)}>10</h4>
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(20)}>20</h4>
                </div>
              )}
              {this.state.showPageLimit && paymentPage && (
                <div
                  className="pagination-selector"
                  onClick={this.hidePageLimit}
                >
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(10)}>10</h4>
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(25)}>25</h4>
                </div>
              )}
            </span>
            {!hidePage &&
            <>
            <span className="pagination-info">{((currentPage - 1) * limit) + 1}-{totalPage > totalLimit ? totalLimit : totalPage} of {totalPage}</span>{' '}
            <span
              className={classNames('pagination-prev', {
                'pagination-disabled': currentPage === 1
              })}
              onClick={this.onPrevious}><ChevronLeft size={16} /></span>
            <span onClick={this.onNext}
              className={classNames('pagination-next', {
                'pagination-disabled': (totalLimit) >= totalPage
              })}
            ><ChevronRight size={16}/></span>
            </>
  }
          </OutsideClickHandler>
        </div>
      </>
    );
  }
}

export default ActionsView;
