import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { FilePlus } from "images/common";
import { FormattedMessage } from "react-intl";
import messages from "utils/message";

import DetailScroll from "app/components/common/Scroll";
import Information from "./components/Information";
import Overview from "./components/Overview";
import Metrics from "./components/Metrics";
import PlanPrice from "./components/PlanPrice";
import PlanPhase from "./components/PlanPhase";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import Quota from "./components/Quota";
import AddonPlan from "./components/AddonPlan";
import Modal from "app/components/common/Modal";

import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import {
  getPlanDetails,
  updatePlanAction,
  deactivatePlanAction,
  activatePlanAction,
  setPlanAsDev,
  listWorkspaceSubscriptions,
  deletePlanAction,
} from "app/actions/plans";
import SubscribedWorkspace from "../subscription";
import { routeMapping } from "app/constants/routes";

import { Settings } from "@carbon/react/icons";
import PreLoader from "app/components/common/PreLoader/PreLoaderDetail";

const Menu = ({
  onActivatePlan,
  category,
  onDeactivatePlan,
  onDelete,
  closeMenu,
  onSetDevPlan,
  onShowAddonPlan,
  showWorkspaceSubscribePage,
  setPlanClass,
  deleteClass,
  deactivateClass,
  activateClass,
  onShowQuota,
}) => {
  return (
    <>
      <span className={activateClass} onClick={() => onActivatePlan(closeMenu)}>
        <span className="action-icon">
          <div className="action_icon_item">
            <Settings />
          </div>
          <div className="action-icon-text">Activate Plan</div>
        </span>
      </span>
      <span
        className={deactivateClass}
        onClick={() => onDeactivatePlan(closeMenu)}
      >
        <span className="action-icon">
          <div className="action_icon_item">
            <Settings />
          </div>
          <div className="action-icon-text">Deactivate Plan</div>
        </span>
      </span>
      {/* <span onClick={() => onSetDevPlan(closeMenu)} className={setPlanClass}>
        Set as Developer Plan
      </span> */}
      {category === "BASE" && (
        <span
          onClick={(e) => onShowQuota(closeMenu)}
          className="actions__option cursor"
        >
          <span className="action-icon">
            <div className="action_icon_item">
              <Settings />
            </div>
            <div className="action-icon-text">Configure Plan</div>
          </span>
        </span>
      )}
      {category === "ADD_ON" && (
        <span
          onClick={(e) => onShowAddonPlan(closeMenu)}
          className="actions__option cursor"
        >
          <span className="action-icon">
            <div className="action_icon_item">
              <Settings />
            </div>
            <div className="action-icon-text">Configure Plan</div>
          </span>
        </span>
      )}
      <span
        onClick={(e) => showWorkspaceSubscribePage(closeMenu)}
        className="actions__option cursor"
      >
        <span className="action-icon">
          <div className="action_icon_item">
            <Settings />
          </div>
          <div className="action-icon-text">Subscribed Workspaces</div>
        </span>
      </span>
      <span onClick={() => onDelete(closeMenu)} className={deleteClass}>
        <span className="action-icon">
          <div className="action_icon_item">
            <Settings />
          </div>
          <div className="action-icon-text">Remove Plan</div>
        </span>
      </span>
    </>
  );
};

class PlanDetails extends React.Component {
  state = {
    plan: {},
    showDropdown: false,
    showDeletePlanModal: false,
    isAccepted: false,
    loggedInUser: this.props.loggedInUser,
    editPopupForm: null,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.props.getPlanDetails({ id });
  }

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onEdit = (formName) => {
    this.setState({ editPopupForm: formName });
  };

  redirectToPlans = () => {
    this.props.history.push(routeMapping.PLANS.path);
  };

  onDelete = async (closeMenu) => {
    const { plan } = this.props;

    if (
      plan.status === "draft" ||
      plan.status === "active" ||
      !!plan.workspaceCount
    ) {
      return false;
    }

    await this.props.deletePlanAction({ id: plan.id }, this.redirectToPlans);
  };

  onAcceptCondition = (e) => {
    this.setState({ isAccepted: e.target.checked || !this.state.isAccepted });
  };

  deletePlanModal = (closeMenu) => {
    const { color } = this.props;
    const { plan } = this.props;

    if (
      plan.status === "draft" ||
      plan.status === "active" ||
      !!plan.workspaceCount
    ) {
      return false;
    }

    const buttons = [
      <button
        className={classNames("modal__confirm", {
          button_disabled: !this.state.isAccepted,
          [`modal__confirm_${color}`]: color,
        })}
        key="confirm"
        disabled={!this.state.isAccepted}
        onClick={() => {
          if (this.state.isAccepted) {
            this.setState({ showDeletePlanModal: false });
            this.onDelete(closeMenu);
          }
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={() => this.setState({ showDeletePlanModal: false })}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        classNames="modal__frame"
        buttons={buttons}
        title={"Confirm immediate plan removal"}
        body={
          <>
            <div>
              The plan will be deleted immediately and cannot be recovered.
            </div>
            <div className="modal__checkbox">
              <div className={`checkbox checkbox_blocked checkbox_${color}`}>
                <input
                  type="checkbox"
                  name="new"
                  className={`checkbox__input modal__checkbox__input checkbox_${color}`}
                  onClick={this.onAcceptCondition}
                  checked={this.state.isAccepted}
                />
                <div
                  className="checkbox__check"
                  onClick={this.onAcceptCondition}
                />
                <label
                  className="checkbox__label modal__checkbox__label label-color-imp"
                  onClick={this.onAcceptCondition}
                >
                  I know what I’m doing
                </label>
              </div>
            </div>
          </>
        }
        onClose={this.onCloseModal}
      />
    );
  };

  onOpenActivatePlan = (closeMenu) => {
    const title = "Confirm activate plan";
    const body =
      "Activating the plan will add it to the assigned pricelist(s) and make it available to users.";

    const { plan } = this.props;

    if (plan.status === "active") {
      return false;
    }

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: () => this.onActivatePlan(closeMenu),
      onCancel: this.onCloseModal,
    });
  };

  onActivatePlan = async (closeMenu) => {
    const { id } = this.props.match.params;
    const { plan } = this.props;

    if (plan.status === "active") {
      return false;
    }
    closeMenu();

    await this.props.activatePlanAction(
      {
        id: plan.id,
      },
      () => this.props.getPlanDetails({ id })
    );
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  onOpenDeactivatePlan = (closeMenu) => {
    const title = "Confirm deactivate plan";
    const body =
      "Deactivating the plan will remove it from assigned pricelist(s) and make it unavailable to users.";

    const { plan } = this.props;

    if (plan.status === "inactive") {
      return false;
    }

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: () => this.onDeactivatePlan(closeMenu),
      onCancel: this.onCloseModal,
    });
  };

  onDeactivatePlan = async (closeMenu) => {
    const { id } = this.props.match.params;
    const { plan } = this.props;

    closeMenu();
    await this.props.deactivatePlanAction(
      {
        id: plan.id,
      },
      () => this.props.getPlanDetails({ id })
    );
  };

  onSetDevPlan = async (closeMenu) => {
    const { id } = this.props.match.params;
    const { plan } = this.props;

    if (plan.status !== "inactive" || plan.isDevPlan) {
      return false;
    }
    closeMenu();

    await this.props.setPlanAsDev(
      {
        id: plan.id,
      },
      () => this.props.getPlanDetails({ id })
    );
  };

  onEditClose = () => {
    this.setState({ editPopupForm: null });
  };

  showWorkspaceSubscribePage = (closeMenu) => {
    if (closeMenu && typeof closeMenu === "function") {
      closeMenu();
    }

    this.setState({ showSubscribeModal: true });
  };

  onShowQuota = async (close) => {
    close();
    this.setState({ showQuotaPage: true });
  };

  onShowAddonPlan = async (close) => {
    close();
    this.setState({ showAddonPlan: true });
  };

  closeQuota = () => {
    const { id } = this.props.match.params;
    this.setState({ showQuotaPage: false, showAddonPlan: false });

    this.props.getPlanDetails({ id });
  };

  hideWorkspaceSubscribePage = () => {
    this.setState({ showSubscribeModal: false });
  };

  renderModal = () => {
    const { body, onConfirm, onCancel, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={onCancel}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={onCancel} />
    );
  };

  onConfiguration = () => {
    const { plan } = this.props;

    if (plan.category === "ADD_ON") {
      return this.setState({ showAddonPlan: true });
    }

    if (plan.category === "BASE") {
      return this.setState({ showQuotaPage: true });
    }
  };

  render() {
    const { showSubscribeModal, showModal, showDeletePlanModal } = this.state;
    const { color, plan } = this.props;

    if (!plan) {
      return <DetailScroll />;
    }

    if (this.state.showQuotaPage) {
      return <Quota onClose={this.closeQuota} color={color} plan={plan} />;
    }

    if (this.state.showAddonPlan) {
      return (
        <AddonPlan
          updatePlanAction={this.props.updatePlanAction}
          onClose={this.closeQuota}
          color={color}
          plan={plan}
        />
      );
    }

    const deleteClass = classNames("actions__option", {
      actions__option_disabled:
        plan.status === "active" ||
        plan.status === "draft" ||
        plan.workspaceCount !== 0,
      // cursor: plan.status === "inactive",
    });

    const activateClass = classNames("actions__option", {
      actions__option_disabled: plan.status === "active",
      cursor: plan.status === "inactive",
    });

    const deactivateClass = classNames("actions__option", {
      actions__option_disabled: plan.status === "inactive",
      cursor: plan.status === "active",
    });

    const setPlanClass = classNames("actions__option", {
      actions__option_disabled: plan.status !== "inactive" || plan.isDevPlan,
      cursor: plan.status === "inactive",
    });

    if (showSubscribeModal) {
      return (
        <SubscribedWorkspace
          subscribedWorkspaces={this.props.subscribedWorkspaces}
          onClose={this.hideWorkspaceSubscribePage}
          plan={plan}
        />
      );
    }

    if (this.props.isLoading) {
      return <PreLoader color={this.props.color} />;
    }

    return (
      <DetailScroll>
        {showModal && this.renderModal()}
        {showDeletePlanModal && this.deletePlanModal()}
        <div className={"messages-bar snackbar-wrapper"}></div>
        <div className="headline">
          <div className={`headline__icon ${color} admin_workspace__image`}>
            <FilePlus />
          </div>
          <h1 className="headline__title">
            {plan.prettyName}
            {plan.status && (
              <>
                {"   "}
                <span className="workspace_owner_tag">
                  {plan.isDevPlan
                    ? "Developer Plan"
                    : plan.status.toUpperCase()}
                </span>
              </>
            )}
          </h1>
        </div>

        <Breadcrumb breadcrumbClass={`breadcrumbs breadcrumbs_${color}`}>
          <BreadcrumbItem route="Base Plans" />
          <BreadcrumbItem route={plan.prettyName} />
        </Breadcrumb>

        <div className="actions-nav">
          <div className="actions">
            <VerticalButtonMenu customClass={color}>
              <Menu
                showWorkspaceSubscribePage={this.showWorkspaceSubscribePage}
                onShowQuota={this.onShowQuota}
                onShowAddonPlan={this.onShowAddonPlan}
                workspaceCount={plan.workspaceCount}
                onActivatePlan={this.onOpenActivatePlan}
                onDeactivatePlan={this.onOpenDeactivatePlan}
                onDelete={() => this.setState({ showDeletePlanModal: true })}
                setPlanClass={setPlanClass}
                deleteClass={deleteClass}
                category={plan.category}
                onSetDevPlan={this.onSetDevPlan}
                activateClass={activateClass}
                deactivateClass={deactivateClass}
              />
            </VerticalButtonMenu>
          </div>
          <span
            className={`actions-nav__link actions-nav__target accent3 cursor`}
            onClick={this.showWorkspaceSubscribePage}
          >
            <span className="action-icon">
              <div className="action-icon-text">Subscribed Workspaces</div>
            </span>
          </span>
          <span
            className={`actions-nav__link actions-nav__target accent3 cursor`}
            onClick={() => this.onConfiguration()}
          >
            Configuration
          </span>
        </div>

        <div className="grid-row">
          <div className="grid-col grid-col_sm-12">
            <Information color={color} plan={plan} />
            {/* <PlanPrice color={color} plan={plan} /> */}
            <PlanPhase color={color} plan={plan} />
          </div>
          <div className="grid-col grid-col_sm-12">
            <Overview color={color} plan={plan} />
            <Metrics
              plan={plan}
              color={color}
              showWorkspaceSubscribePage={this.showWorkspaceSubscribePage}
            />
          </div>
        </div>
      </DetailScroll>
    );
  }
}

PlanDetails.propTypes = {
  color: PropTypes.string,
  plan: PropTypes.object,
  listRequest: PropTypes.func,
  workspaces: PropTypes.array,
  loggedInUser: PropTypes.object,
  updateWorkspaceRequest: PropTypes.func,
};

PlanDetails.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

function mapStateToProps(state) {
  return {
    plan: state.plans.detail,
    isLoading: state.plans.isLoading,
    subscribedWorkspaces: state.plans.workspaces,
  };
}

const withConnect = connect(mapStateToProps, {
  getPlanDetails,
  setPlanAsDev,
  activatePlanAction,
  deactivatePlanAction,
  updatePlanAction,
  deletePlanAction,
  listWorkspaceSubscriptions,
});

export default compose(withRouter, withConnect)(PlanDetails);
