import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {uniq} from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Scrollbars } from 'react-custom-scrollbars'

import {SearchIcon, XCircleIcon, CloseIcon} from 'images/common'
import messages from '../messages'

class Tags extends React.Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
  }

  state = {
    searchValue: '',
    filterValue: [],
    columns: []
  }

  componentDidMount() {
    const {columns} = this.props
    const tags = []

    columns.forEach(column => tags.push(...column))

    this.setState({columns: uniq(tags)})
    document.addEventListener('keydown', this.onEnter, false);
  }

  onClear = () => {
    const {columns} = this.props
    this.textInput.current.value = ''

    this.setState({searchValue: ''})
    const tags = []

    columns.forEach(column => tags.push(...column))

    this.setState({columns: uniq(tags)})
  }


  onEnter = (event) => {
    if (event.key === 'Enter' && !!this.state.filterValue.length) {
      // on Esc button click it should close popup
      this.onApply()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onApply = () => {
    const { filterValue } = this.state
    const { name, onFilterApply } = this.props

    onFilterApply(name, filterValue, 'search')
  }

  onAdd = (value) => {
    return this.setState({ filterValue: [...this.state.filterValue, value] })
  }

  onRemove = (e, value) => {
    e.preventDefault()
    e.stopPropagation()
    const removeFilterList = this.state.filterValue.filter(tag => tag !== value)

    return this.setState({filterValue: removeFilterList})
  }

  onSearch = (event) => {
    const columns = []
    this.props.columns.forEach(column => columns.push(...column))

    const searchValue = event.target.value
    this.setState({searchValue: searchValue})

    const filteredColumns = columns
      .filter(tag => tag.toLowerCase().includes(searchValue.toLowerCase()))

    this.setState({columns: filteredColumns})
  }

  render() {
    const { title, customClass } = this.props
    const { columns, filterValue} = this.state
    const customInputClass =
    `text-field text-field_nolabel text-field_${customClass}`

    const btnClass = classNames('filters-bar__apply', {
      [customClass]: customClass,
      'filters-bar__disabled': !this.state.filterValue.length
    })

    const iconClass = classNames('cursor', {
      'text-field__closeIcon': this.state.searchValue
    })

    return (
      <>
        <div className="filters-bar__heading">{title}</div>
        <div className={customInputClass}>
          <input
            ref={this.textInput}
            type="text" placeholder="Search" className="text-field__input" onChange={this.onSearch} />
          <div className="text-field__underscore"></div>
          <div className="text-field__icon">{!this.state.searchValue && <SearchIcon/>}</div>
        </div>
          {this.state.searchValue && <span className={iconClass} onClick={() => this.onClear()}><CloseIcon/></span>}
          <Scrollbars
            autoHeightMin={120}
            autoHeight autoHeightMax={columns.length > 0 ? 250 : 0}
            renderView={props => <div {...props} className="view"/>}>
            <div className={`tags tags_${customClass}`}>
              {columns
                .map(tag => {
                  const filterClass = classNames('tags__item', {
                    'tags__item_selectable': !filterValue.includes(tag)
                  })

                  return (
                    <div className={filterClass} onClick={() => this.onAdd(tag)} key={tag}>{tag}<button type="button" className="tags__unselect" onClick={(e) => this.onRemove(e, tag)}>
                      <XCircleIcon/>
                    </button></div>
                  )
                })}
            </div>
          </Scrollbars>
        <hr className="mt_100"/>
          <button type="button" className={btnClass} onClick={this.onApply}>
            <FormattedMessage {...messages.apply} />
          </button>
      </>
    )
  }
}

Tags.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  columns: PropTypes.array,
  customClass: PropTypes.string,
  onFilterApply: PropTypes.func
}

export default Tags
