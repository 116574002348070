import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import EditInformation from "../Edit/Information";
import CardTags from "app/components/common/Detail/CardTags";

class Information extends Component {
  state = {
    openEditInformationModal: false,
  };

  onEdit = () => {
    this.setState({ openEditInformationModal: true });
  };

  onCloseModal = () => {
    this.setState({ openEditInformationModal: false });
  };

  renderContent = () => {
    const { listing } = this.props;
    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Listing Name</div>
          <div className="overview__value">{listing.name}</div>
        </div>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Listing ID</div>
          <div className="overview__value">{listing.id}</div>
        </div>
      </>
    );
  };

  renderSecondaryContent = () => {
    const { listing } = this.props;

    return (
      <div className="grid-col">
        <div className="overview__label">Categories</div>
        <div className="overview__value">
          {listing.marketplaceCategories &&
          listing.marketplaceCategories.length ? (
            <CardTags
              color={this.props.color}
              tags={
                listing.marketplaceCategories &&
                listing.marketplaceCategories.map((category) => category.name)
              }
            />
          ) : (
            "-"
          )}
        </div>
      </div>
    );
  };

  render() {
    const { openEditInformationModal } = this.state;

    if (openEditInformationModal) {
      return (
        <EditInformation
          listing={this.props.listing}
          color={this.props.color}
          onClose={this.onCloseModal}
        />
      );
    }

    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.information} />}
        className="overview"
        secondaryContent={this.renderSecondaryContent()}
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Information;
