import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Field,
  reduxForm,
  FieldArray,
  Form,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";

import ProcessPage from "app/components/common/ProcessPage";
import SingleSelect from "app/components/common/Form/SingleSelect";
import Input from "app/components/common/Form/Input";

import EditForm from "app/components/common/Edit/EditForm";
import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import { CloseIcon } from "images/common";
import validate from "./validate";

import { INTERVAL, CURRENCY } from "app/constants/common";

const FORM_NAME = "PRICING";

const required = (value) => (value ? undefined : "Required");
const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

class PlanPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      id: "",
      tags: [],
      description: "",
    };
  }

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  onChangeField = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmitApplication = () => {
    this.props.onClose();
  };

  componentDidMount() {
    const {
      array: { push },
    } = this.props;

    push("tiers", {});
    push("tiers", {});
    push("tiers", {});
  }

  componentWillUnmount() {
    this.props.change('tiers', [])
  }

  renderContent = () => {
    const { color, editMode } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div className="plan_title__container">
          <h3 className="plan_title">Pricing Settings</h3>
          <div className="grid-row grid-row_nog grid-row_jcb">
            <div className="grid-col mw-200">
              <Field
                parentClass={`select-field select-field_${color}`}
                name="interval"
                options={INTERVAL}
                component={SingleSelect}
                label="Interval"
                validate={[required]}
              />
              <Field
                name="minEndpoints"
                readOnly={editMode}
                component={Input}
                parentClass={color}
                label="Minimum"
                postFix="Units"
                maxLength="3"
                type="text"
                normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                pattern="[0-9]*"
                inputMode="numeric"
                validate={[required, number]}
              />
            </div>
            <div className="grid-col mw-200">
              <Field
                disabled
                parentClass={`select-field select-field_${color}`}
                name="currency"
                options={CURRENCY}
                component={SingleSelect}
                label="Currency"
                validate={[required]}
              />
            </div>
          </div>
          <h3 className="plan_title">Trial Settings</h3>
          <div className="grid-row grid-row_nog grid-row_jcb">
            <div className="grid-col mw-200">
              <Field
                readOnly={editMode}
                parentClass={color}
                name="trial"
                component={Input}
                label="Trial period"
                postFix="Days"
                maxLength="3"
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                validate={[required, number]}
              />
            </div>
            <div className="grid-col mw-200">
              <Field
                name="trialFee"
                readOnly={editMode}
                component={Input}
                parentClass={color}
                label="Trial price"
                postFix="USD"
                type="number"
                min='0'
                validate={[number]}
              />
            </div>
          </div>
       
        </div>
        <FieldArray
          name="tiers"
          component={(plans) => {
            return (
              <div className="plan_title__container" key={plans}>
                <h3 className="plan_title">Pricing Tiers</h3>
                {plans.fields.map((plan, index) => (
                  <div className="grid-row plan_form grid-row_nog grid-row_jcb" key={index}>
                    <div className={`plan_number checkbox checkbox_${color}`}>
                      <div className="checkbox__image">{index + 1}</div>
                    </div>
                    <div className="grid-col mw-138">
                      <Field
                        type="number"
                        parse={value => Number(value)}
                        readOnly={plans.fields.length - 1 === index}
                        parentClass={color}
                        name={`${plan}.up_to`}
                        component={Input}
                        label={
                          plans.fields.length - 1 === index ? "∞" : "Last Unit"
                        }
                        maxLength="4"
                        disabled={editMode || plans.fields.length - 1 === index}
                      />
                    </div>
                    <div className="grid-col mw-200">
                      <Field
                        parentClass={color}
                        name={`${plan}.unit_amount`}
                        component={Input}
                        type="number"
                        label={'price'}
                        validate={[required]}
                        postFix={
                          this.props.currency &&
                          this.props.currency.toUpperCase()
                        }
                        maxLength="4"
                        disabled={editMode}
                      />
                    </div>
                    {index !== 0 && plans.fields.length - 1 !== index && (
                      <div
                        className="plan-close-tier cursor"
                        onClick={() => plans.fields.remove(index)}
                      >
                        <CloseIcon />
                      </div>
                    )}
                  </div>
                ))}
                <span
                  className="plan-add-tier cursor"
                  onClick={() => !editMode && plans.fields.push({})}
                >
                  {" "}
                  + Add another tier{" "}
                </span>
              </div>
            );
          }}
        />
        <div className="foot-nav">
          <button
            type="submit"
            disabled={editMode ? false : !this.props.valid}
            onClick={editMode && this.props.onEdit}
            className={classNames(
              "round-button round-button_br round-button_primary round-button_forw",
              {
                "round-button_disabled": !this.props.valid,
                [`round-button_${color}`]: color,
              }
            )}
          >
            <span className="round-button__label">Confirm</span>
          </button>
        </div>
      </Form>
    );
  };

  render() {
    const { color, hideProgress } = this.props;
    const { name } = this.state;

    return (
      <ProcessPage onCancel={this.props.onClose} onBack={this.props.onBack}>
        {() => (
          <>
            {!hideProgress && <div className={`progress-bar progress-bar_${color}`}>
              <div style={{ width: "100%" }}></div>
            </div>
  }
            <EditForm
              wrapperClass="pricing__form"
              color={this.props.color}
              content={this.renderContent()}
              disabled={!name}
              title="Plan Pricing"
              subTitle="Every tier is filled up at its pricing before the next tier is reached."
            />
          </>
        )}
      </ProcessPage>
    );
  }
}

PlanPricing.propTypes = {
  color: PropTypes.string,
};

PlanPricing.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

function mapStateToProps(state) {
  return {
    currency: 'usd'
  };
}

const withConnect = connect(mapStateToProps, null);

export default reduxForm({
  form: FORM_NAME,
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(withConnect(PlanPricing));
