import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import {
  Briefcase,
} from "react-feather";

import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'

const columnList = [{
  label: 'Partner',
  isSortable: true,
  isLocked: true,
  sortKey: 'workspace.name'
},
{
  label: 'Status',
  sortable: false,
  isSortable: true,
  sortKey: 'status'
},
{
  label: 'Partner ID',
  sortable: false,
  isSortable: true,
  sortKey: 'id'
},
{
  label: <FormattedMessage {...appMessages.modified} />,
  sortable: false,
  isSortable: true,
  sortKey: 'updatedAt'
},
{
  label: <FormattedMessage {...appMessages.created} />,
  sortable: false,
  isSortable: true,
  sortKey: 'createdAt'
}
]

class ListingTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        notFoundIcon={<Briefcase size={42}/>}
        rows={data || []}
        colorClassName={color}
        CheckboxImage={Briefcase}
        isSelectable
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        isLoading={this.props.isLoading}
        onReject={this.props.onReject}
        onApprove={this.props.onApprove}
        onActivate={this.props.onActivate}
        onDeactivate={this.props.onDeactivate}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
        onUpdateStatus={this.props.onUpdateStatus}
      />
    )
  }
}

ListingTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ListingTable
