import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames'

export const SingleMenu = ({ onDelete,
   id,
    onToogleMenu,
     status,
     showQuota,
      closeMenu,
       workspaceCount,
        hideParentClass,
         onActivatePlan,
          onDeactivatePlan,
          onSetAsDevPlan,
          isDevPlan,
          originalId
        }) => {
  const deleteClass = classNames("actions__option", {
    actions__option_disabled: status === "active" || status === "draft" || workspaceCount !== 0,
    cursor: status === "inactive",
  });

  const setPlanClass = classNames("actions__option", {
    actions__option_disabled: status !== 'inactive' || isDevPlan,
    cursor: status === "inactive",
  });

  const quotaClass = classNames("actions__option");

  const activateClass = classNames("actions__option", {
    actions__option_disabled: status === 'active',
    cursor: status === "inactive",
  });

  const deactivateClass = classNames("actions__option", {
    actions__option_disabled: status === 'inactive',
    cursor: status === "active",
  });

  const onDeleteMenu = (id, e) => {
    e.stopPropagation()
    onToogleMenu(e, id)

    if(status === "draft" || status === 'active' || !!workspaceCount) {
      return false
    }

    closeMenu()
    onDelete(id, e)
  }

  const onActivateMenu = (id, e) => {
    e.stopPropagation()
    onToogleMenu(e, id)

    if( status === 'active') {
      return false
    }
  
    closeMenu()
    onActivatePlan(id, e)
  }

  const setAsDevPlan = (id, e) => {
    e.stopPropagation()
    onToogleMenu(e, id)

    if( status !== 'inactive' || isDevPlan) {
      return false
    }
  
    closeMenu()
    onSetAsDevPlan(id, e)
  }

  
  const onDeactivateMenu = (id, e) => {
    e.stopPropagation()
    onToogleMenu(e, id)

    if(status === 'inactive') {
      return false
    }
  
    closeMenu()
    onDeactivatePlan(id, e)
  }

  const onShowQuota = (id, e) => {
    e.stopPropagation()
    onToogleMenu(e, id)
  
    closeMenu()
    showQuota(e, id)
  }

  const parentClass = classNames({
    'actions__dropdown actions__dropdown_visible': !hideParentClass
  });

  return (
    <>
      <div className={parentClass}>
      {/* <span className="actions__option" onClick={(e) => onDuplicate(id, e)}>
        Copy to edit
      </span> */}
      <span
        onClick={(e) => onActivateMenu(originalId, e)}
        className={activateClass}
      >
        Activate Plan
      </span>
      <span
        onClick={(e) => onDeactivateMenu(originalId, e)}
        className={deactivateClass}
      >
        Deactivate Plan
      </span>
      <span onClick={(e) => onShowQuota(originalId, e)} className={quotaClass}>
        Quotas and Limits
      </span>
      {/* <span onClick={(e) => setAsDevPlan(originalId, e)} className={setPlanClass}>
        Set as Developer Plan
      </span> */}
      <span onClick={(e) => onDeleteMenu(originalId, e)} className={deleteClass}>
        Remove Plan
      </span>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
