import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

import CustomInput from "app/components/common/Input/CustomInput";
import CustomTextarea from "app/components/common/Input/CustomTextarea";
import ProcessPage from "app/components/common/ProcessPage";
import EditForm from "app/components/common/Edit/EditForm";
import CustomSelect from "app/components/common/Input/CustomSelect";

import {addCredit} from 'app/api/workspaces';
import { defaultNotifier } from "functions/notificationHandler";

const REASON_LIST = [
  {
    label: "100 - Courtesy",
    value: "100 - Courtesy",
  },
  {
    label: "101 - Billing Error",
    value: "101 - Billing Error",
  },
  {
    label: "199 - OTHERS",
    value: "199 - OTHERS",
  },
];

class AddCredit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChanged: false,
      comment: "",
      amount: '',
      reason: "100 - Courtesy",
      currency: "USD",
    };
  }

  componentDidMount() {}

  onFieldChange = (e, name) => {
    this.setState({
      isChanged: true,
      [name]: e.target.value,
    });
  };

  onChangeReason = () => {
    this.setState({})
  }

  onSubmit = async () => {
    const {id} = this.props;
    const {amount, reason, comment, isLoading} = this.state;

    if(isLoading) {
      return false
    }

    if(amount && id) {
      this.setState({isLoading: true})
      const {data} = await addCredit({
        workspaceId: id,
        amount: parseFloat(amount),
        reason,
        comment
      });

      
      if(data.errors) {
        defaultNotifier(data.errors)
        return this.setState({isLoading: false})
      }

      this.setState({isLoading: false})

      defaultNotifier('Credit added successfully.')
      this.props.getWorkspaces();
      this.props.onClose();
    }
  };

  renderContent = () => {
    const { color } = this.props;

    return (
      <>
        <div className="grid-row">
          <div className="grid-col">
            <div className={`text-field text-field_${color}`}>
              <CustomInput
                value={this.state.amount}
                type="number"
                parentClass={color}
                onChange={(e) => this.onFieldChange(e, "amount")}
                label="Amount"
                isInvalid={!this.state.amount}
                hintText={this.state.amount ? "" : "Amount is required"}
              />
            </div>
          </div>
          <div className="grid-col">
            <div className={`text-field text-field_${color}`}>
            <CustomSelect
              readOnly
              disabled
              value={this.state.currency}
              label="Currency"
              onChange={this.onUpdatePayment}
              options={[{value: 'USD', label: 'USD'}]}
            />
            </div>
          </div>
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomSelect
            value={this.state.reason}
            label="Reason"
            onChange={this.onChangeReason}
            options={REASON_LIST}
          />
        </div>

        <div className={`text-field text-field_${color}`}>
          <CustomTextarea
            parentClass={color}
            value={this.state.comment}
            onChange={(e) => this.onFieldChange(e, "comment")}
            label="Comment"
            type="textarea"
          />
        </div>
      </>
    );
  };

  render() {
    const {isLoading} = this.state;

    return (
      <ProcessPage onCancel={() => this.props.onClose()}>
        {() => (
          <EditForm
            color={this.props.color}
            submitLabel={isLoading ? "Loading..." : "Add Credit"}
            submitClassName={
              !this.state.amount || this.state.isLoading ? "round-button_disabled" : null
            }
            onSubmit={this.onSubmit}
            title="Add New Credit"
            subTitle={
              "Add credit to the Workspace. This adjustment of account credit generates a new invoice."
            }
            content={this.renderContent()}
            disabled={!this.state.amount}
          />
        )}
      </ProcessPage>
    );
  }
}

AddCredit.propTypes = {
  profile: PropTypes.object,
  closePopup: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddCredit;
