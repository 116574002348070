export const routeMapping = {
    DASHBOARD: { path: '/dashboard' },
    SUBSCRIPTIONS: { path: '/subscriptions' },
    SUBSCRIPTION_DETAILS: { path: '/subscriptions/:id' },
    PAYMENTS: { path: '/payments' },
    PAYMENT_DETAILS: { path: '/payments/:id' },
    INVOICES: { path: '/invoices' },
    INVOICE_DETAILS: { path: '/invoices/:id' },
    WORKSPACES: {path: '/workspaces'},
    CATEGORY: {path: '/categories'},
    MODELS: {path: '/models'},
    MODEL: {path: '/model/:id'},
    LISTING: {path: '/listing'},
    PARTNER: {path: '/partners'},
    PLANS: {path: '/plans'},
    USERS: {path: '/staff'},
    VISO_USER: {path: '/users'},
    SETTINGS: {path: '/settings'},
    WORKSPACE_AGENTS: {path: '/workspace/:id/agents/:name'},
    PLAN: {path: '/plan'},
    CREATE_PLAN: {path: '/plan/new'},
    CREATE_CATEGORY: {path: '/category/new'},
    CREATE_MODEL: {path: '/model/new'},
    PLAN_DETAILS: {path: '/plan/:id'},
    SUBSCRIBED_WORKSPACE_DETAILS: {path: '/workspace/plan/:id'},
    CATEGORY_DETAILS: {path: '/category/:id'},
    LISTING_DETAILS: {path: '/listing/:id'},
    PARTNER_DETAILS: {path: '/partner/:id'},
    WORKSPACE: {path: '/workspace'},
    WORKSPACE_ESTIMATE: {path: '/workspace/estimate/:id'},
    WORKSPACE_SUBSCRIPTION: {path: '/workspace/subscription/:id'},
    WORKSPACE_BILLING: {path: '/workspace/billing/:id'},
    WORKSPACE_DETAILS: {path: '/workspace/:id'},
    LOGOUT: { path: '/logout' },
    LOGIN: { path: '/login' },
  }
  
  export const AUTH_BASE = '/auth/graphql'
  