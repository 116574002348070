import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from 'classnames'

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import { dateFormatter } from "utils/common";

class WorkspaceOverview extends Component {
  getStatus = (status) => {
    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === 'active',
        "data-table__status_neg": status === 'inactive',
        "data-table__status_neutral": status === 'deleted',
      }
    );

    return <div className={className}>{status}</div>
  };

  getAnnotationStatus = () => {
    const { annotationEnabled } = this.props.workspace;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": annotationEnabled,
        "data-table__status_neg": !annotationEnabled,
      }
    );
    return <div className={className}><span className="text-capitalize">{annotationEnabled ? 'Active' : 'Inactive'}</span></div>
  };

  getTrainingStatus = () => {
    const { trainingEnabled } = this.props.workspace;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": trainingEnabled,
        "data-table__status_neg": !trainingEnabled,
      }
    );
    return <div className={className}><span className="text-capitalize">{trainingEnabled ? 'Active' : 'Inactive'}</span></div>
  };


  renderContent = () => {
    const {workspace} = this.props
    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.state} />
          </div>
          <div className="overview__value">{workspace.subDomain}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.activationDate} />
          </div>
          <div className="overview__value">{dateFormatter(workspace.createdAt, true)}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.billingStartDate} />
          </div>
          <div className="overview__value">
          <div className="overview__value">{dateFormatter(workspace.createdAt, true)}</div>
          </div>
          <div className="overview__label">
            <FormattedMessage {...messages.billingPeriod} />
          </div>
          <div className="overview__value">
            Monthly
          </div>
        </div>
        <div className="grid-col">
        <div className="overview__label">
            <FormattedMessage {...messages.chargedThrough} />
          </div>
          <div className="overview__value">
            {this.getStatus(workspace.status)}
          </div>
          <div className="overview__label">
          <FormattedMessage {...messages.cancelDate} />
          </div>
          <div className="overview__value">{workspace.deleteDate && dateFormatter(workspace.deleteDate, true)}</div>
          <div className="overview__label">
          <FormattedMessage {...messages.billingEndDate} />
          </div>
          <div className="overview__value">
          <div className="overview__value">{workspace.deleteDate && dateFormatter(workspace.deleteDate, true)}</div>
          </div>
          <div className="overview__label">
          <FormattedMessage {...messages.billingCycleDay} />
          </div>
          <div className="overview__value">
            23
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.overview} />}
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

WorkspaceOverview.propTypes = {
  onEdit: PropTypes.func,
  color: PropTypes.string,
  workspace: PropTypes.object,
};

export default WorkspaceOverview;
