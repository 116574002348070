import axios from 'axios'

import { defaultNotifier } from 'functions/notificationHandler'

function createAxiosInstance(unauthorizedCallback) {
  return axios.create({
    headers: {
      'content-type': 'application/json'
    },
    validateStatus: status => {
      const unauthorizedStatus = 401
      if (status === unauthorizedStatus) {
        unauthorizedCallback()
      }
      return status >= 200 && status < 400
    }
  })
}

function logout() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('userData')
  window.location.href = '/logout'
}

function isTokenExpired(error) {
  if (error.data.errors) {
    let message = error.data.errors[0].message
    if (message.includes('jwt expired') ||
     message.includes('invalid token') ||
     message.includes('You are denied system access') ||
     message.includes('jwt malformed')) {
      setTimeout(() => {
        defaultNotifier('Your session has expired, please login again to continue.')
      }, 800)
      logout()
    }
    return error
  }
}

export const axiosInstance = createAxiosInstance(logout)

export default function request(params) {
  const token = localStorage.getItem('accessToken')

  return axiosInstance({
    headers: {
      'content-type': 'application/json',
      'authorization': token ? `Bearer ${token}` : undefined
    },
    ...params
  })
    .then(res => res)
    .catch(err => (
      err.response ?
        isTokenExpired(err.response) :
        {
          errors: [{
            message: 'Something went wrong.'
          }]
        }
    ))
}
