import React, { Component } from 'react';

import Breadcrumb, { BreadcrumbItem } from 'app/components/Breadcrumb';
import DetailScroll from 'app/components/common/Scroll';
import { DEFAULT_ACCENT } from 'utils/constants';
import VerticalButtonMenu from 'app/components/common/VerticalButtonMenu';

import Overview from './Overview';

class PreLoader extends Component {
  render() {
    return (
      <>
        <DetailScroll>
          <div className="headline">
            <div className="headline__icon" />
            <h1 className="headline__title heading__loading" />
          </div>

          <Breadcrumb className="breadcrumbs_loading">
            <BreadcrumbItem route="Deployment" />
            <BreadcrumbItem route="Profiles" />
            <BreadcrumbItem
              route=""
            />
          </Breadcrumb>
          <>
            <div className="actions-nav card_loading">
              <VerticalButtonMenu />
            </div>
            <div className="grid-row mobile-page">
              <div className="grid-col grid-col_sm-12">
                <Overview />
                <Overview />
              </div>

              <div className="grid-col grid-col_sm-12">
                <Overview />
                <Overview />
              </div>
            </div>
          </>
        </DetailScroll>
      </>
    );
  }
}

PreLoader.defaultProps = {
  color: DEFAULT_ACCENT,
};

export default PreLoader;
