import React from 'react'
export default (props) =>
  <svg
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
    className={props.iconClass || 'accent4'}
  >
    <path d="M0 0h24v24H0z" fill="none"/>
    <path
      d="M3 18h18v-2H3v2zM3 6v2h6V6H3zm0 7h12v-2H3v2z"
      fill="currentColor"/>
  </svg>
