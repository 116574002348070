import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import ProcessPage from "app/components/common/ProcessPage";
import CustomInput from "app/components/common/Input/CustomInput";
import CustomTextarea from "app/components/common/Input/CustomTextarea";
import CustomMultiSelect from "app/components/common/Input/CustomMultiSelect";

import EditForm from "app/components/common/Edit/EditForm";
import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import { routeMapping } from "app/constants/routes";
import { createCategoryAction, listCategory } from "app/actions/category";
import { defaultNotifier } from "functions/notificationHandler";

class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      id: "",
      tags: [],
      description: "",
    };
  }

  componentDidMount() {
    this.props.listCategory();
  }

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  onChangeField = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmitApplication = () => {
    this.props.onClose();
  };

  onClose = () => {
    this.props.history.push(routeMapping.CATEGORY.path);
  };

  onSubmit = async () => {
    const { name, slug, description, parentId } = this.state;

    let payload = {
      name,
      slug,
      description,
    };

    if (parentId) {
      payload = {
        name,
        slug,
        parentIds: parentId,
        description,
      };
    }

    try {
      await this.props.createCategoryAction(payload);
      this.props.history.push(routeMapping.CATEGORY.path);
    } catch (err) {
      defaultNotifier("Error adding the new category");
    }
  };

  onUpdate = (id) => {
    this.setState({ parentId: id });
  };

  renderContent = () => {
    const { color, categories } = this.props;
    const { name, description, slug } = this.state;

    let options = categories.filter((item) => !item.parents.length);
    options = options.map((parent) => ({
      ...parent,
      label: parent.name,
      value: parent.id,
      children: categories.filter((item) =>
        item.parents
          .find((i) => i.id === parent.id)
      )
      .map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
    }));

    const allOptions = categories.map(item => ({label: item.name, value: item.id}))

    return (
      <>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={name}
            onChange={(e) => this.onChangeField("name", e.target.value)}
            label="Category Name"
            isInvalid={!name}
            postIconClass="success"
            hintText={name ? "" : "Name is required"}
          />
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={slug}
            onChange={(e) => this.onChangeField("slug", e.target.value)}
            label="Category Slug"
            isInvalid={!name}
            postIconClass="success"
            hintText={name ? "" : "Name is required"}
          />
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomTextarea
            value={description}
            onChange={(e) => this.onChangeField("description", e.target.value)}
            label="Description"
            type="textarea"
          />
        </div>
        <div className={`select-field select-field_${color}`}>
          <CustomMultiSelect
            editable
            allOptions={allOptions}
            value={this.state.parentId}
            label="Parent Category"
            onChange={this.onUpdate}
            options={options}
            parentClass="select-field accent2"
          />
        </div>
      </>
    );
  };

  render() {
    const { color } = this.props;
    const { name } = this.state;

    const submitClass = classNames(`button button_${color}`, {
      button_disabled: !name,
    });

    return (
      <ProcessPage onCancel={this.onClose}>
        {() => (
          <>
            <EditForm
              color={this.props.color}
              submitLabel="Save"
              submitClassName={submitClass}
              onSubmit={this.onSubmit}
              content={this.renderContent()}
              disabled={!name}
              title="Category Information"
              subTitle="Create a category."
            />
          </>
        )}
      </ProcessPage>
    );
  }
}

Information.propTypes = {
  color: PropTypes.string,
};

Information.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

function mapStateToProps(state) {
  return {
    categories: state.category.list,
    isLoading: state.category.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  createCategoryAction,
  listCategory,
});

export default compose(withRouter, withConnect)(Information);
