import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const SingleMenu = ({ detailRole, hideParentClass }) => {
  const parentClass = classNames({
    'actions__dropdown actions__dropdown_visible': !hideParentClass
  });

  return (
    <>
      <div className={parentClass}>
      <a
        href="/"
        className="actions__option actions__option_disabled"
      >
        Deactivate Workspace
      </a>
      <a
        href="/"
        className="actions__option actions__option_disabled"
      >
        Activate Workspace
      </a>
      <a
        href="/"
        className="actions__option actions__option_disabled"
      >
        Archive Workspace
      </a>
      <a
        href="/"
        className="actions__option actions__option_disabled"
      >
        Remove Workspace
      </a>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
