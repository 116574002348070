import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import appMessages from 'utils/message'
import Card from 'app/components/common/Detail/Card';
import {convertDefaultStatusToStatus } from 'utils/common'

class AssociatedNode extends Component {
  renderContent = () => {
    const { model} = this.props

    return (
      <>
          <div className="grid-col">
            <dl>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.modelType} />
              </dt>
              <dd className="overview__value">{model && model.modelType && convertDefaultStatusToStatus(model.modelType)}</dd>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.framework}/>
              </dt>
              <dd className="overview__value">
                {model && model.framework && convertDefaultStatusToStatus(model.framework)}
              </dd>
            </dl>
          </div>
          <div className="grid-col">
            <dl>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.categories} />
              </dt>
              <dd className="overview__value text-capitalize allow-overflow">
                {model && model.categories && model.categories.map((cat, index) => {
                  if ((model.categories.length - 1) === 0 || index === model.categories.length - 1) {
                    return convertDefaultStatusToStatus(cat)
                  }

                  return `${convertDefaultStatusToStatus(cat)}, `
                })
                }
                {/* {model.categories && convertDefaultStatusToStatus(model.categories)} */}
              </dd>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.deviceTypes} />
              </dt>
              <dd className="overview__value">
                {model && model.deviceTypes && model.deviceTypes.map((cat, index) => {
                  if ((model.deviceTypes.length - 1) === 0 || index === model.deviceTypes.length - 1) {
                    return convertDefaultStatusToStatus(cat)
                  }

                  return `${convertDefaultStatusToStatus(cat)}, `
                })}
                {/* {model.deviceType && convertDefaultStatusToStatus(model.deviceType)} */}
              </dd>
            </dl>
          </div>
          </>
    )
  }

  render() {
    return (
      <Card
        isLoading={this.props.isLoading}
        color={this.props.color}
        title={
          <FormattedMessage {...appMessages.overview} />
        }
        className="overview"
        onEdit={this.props.onEdit}
        content={this.renderContent()}
      />
    );
  }
}

AssociatedNode.propTypes = {
  flow: PropTypes.object,
  color: PropTypes.string
}

export default AssociatedNode
