import React, { Component } from "react";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import { getInvoice } from "utils/common";

import Card from "app/components/common/Detail/Card";
import ViewHistory from '../../list/invoices'
import { dateFormatter } from "utils/common";
import { routeMapping } from "routes";
import { TickIcon } from "images/common";

class Invoice extends Component {
  state = {
    openModal: false,
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  onRedirectToInvoice = () => {
    const {payment} = this.props;

    this.props.history.push(`${routeMapping.INVOICES.path}/${payment.invoiceId}`)
  };

  openEditModal = () => {
    this.setState({ showEditModal: true })
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false })
  }
  
  closeModal = () => {
    this.setState({ openModal: false });
  };

  renderContent = () => {
    const { color, payment } = this.props

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
          <tr className="data-table__row" onClick={this.onRedirectToInvoice}>
                <td className="data-table__td data-table__td_1">
                  <div className={`checkbox checkbox_${color}`}>
                  <span
                            className={`data-cards__selected data-cards__selected_item success`}
                          >
                            <TickIcon />
                          </span>
                    <div className="checkbox__image">
                      IN
                    </div>
                  </div>
                </td>
                <td className="data-table__td">
                  {getInvoice(payment.invoiceNumber)}
                </td>
                {/* <td className="data-table__td">
                  {this.getStatus()}
                </td> */}
              </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    return (
      <Card
        noGrid
        isAccentAction
        color={'accent0'}
        title={'Related Invoice'}
        className="flow-modules"
        content={this.renderContent()}
      />
    )
  }
}

export default withRouter(Invoice);
