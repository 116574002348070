import React, { Component } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import ProcessPage from "app/components/common/ProcessPage";
import CustomInput from "app/components/common/Input/CustomInput";
import CustomTextarea from "app/components/common/Input/CustomTextarea";

import Tags from "app/components/common/Tags";
import appMessages from "utils/message";
import EditForm from "app/components/common/Edit/EditForm";
import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import { routeMapping } from "app/constants/routes";
import { createPlanAction, listPlans } from "app/actions/plans";

class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      id: "",
      tags: [],
      description: "",
    };
  }

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  onChangeField = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmitApplication = () => {
    this.props.onClose();
  };

  onClose = () => {
    this.props.history.push(routeMapping.PLANS.path);
  };


  onSubmit = async () => {
    const { name, tags, description } = this.state;

    const payload = {
      name,
      tags,
      description,
    };

    try {
      const id = await this.props.createPlanAction(payload);

      if(id) {
        this.props.history.push(`${routeMapping.PLAN.path}/${id}`);
      }
    } catch (err) {}
  };

  renderContent = () => {
    const { color } = this.props;
    const { name, description } = this.state;

    return (
      <>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={name}
            onChange={(e) => this.onChangeField("name", e.target.value)}
            label={<FormattedMessage {...appMessages.planName} />}
            isInvalid={!name}
            postIconClass="success"
            hintText={name ? "" : "Name is required"}
          />
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomTextarea
            value={description}
            onChange={(e) => this.onChangeField("description", e.target.value)}
            label={<FormattedMessage {...appMessages.planDescription} />}
            type="textarea"
          />
        </div>
        <Tags
          tags={this.state.tags}
          tagClass={color}
          onChange={this.onAddTags}
        />
      </>
    );
  };

  render() {
    const { color } = this.props;
    const { name } = this.state;

    const submitClass = classNames(`button button_${color}`, {
      button_disabled: !name,
    });

    return (
      <ProcessPage onCancel={this.onClose}>
        {() => (
          <>
            <EditForm
              color={this.props.color}
              submitLabel="Create Draft"
              submitClassName={submitClass}
              onSubmit={this.onSubmit}
              content={this.renderContent()}
              disabled={!name}
              title="Create a new plan"
              subTitle="Create a plan."
            />
          </>
        )}
      </ProcessPage>
    );
  }
}

Information.propTypes = {
  color: PropTypes.string,
};

Information.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

const withConnect = connect(null, {
  createPlanAction,
  listPlans,
});

export default compose(withRouter, withConnect)(Information);
