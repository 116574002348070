import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ActionMenu from 'app/components/common/ActionMenu';
import {SettingsIcon, ActivateIcon, DeactivateIcon, RemoveIcon} from 'images/common';

export const SingleMenu = ({
  onDelete,
  closeMenu,
  id,
  balance,
  status,
  onRecord,
  hideParentClass,

}) => {
  const activeClass = classNames("actions__option", {
    actions__option_disabled: status === "paid" && balance === 0,
    cursor: status !== 'active' && status !== 'deleted',
  });

  const invoiceClass = classNames("actions__option", {
    actions__option_disabled: status === 'inactive' || status === 'deleted',
    cursor: status !== 'inactive' && status !== 'deleted',
  });

  const manageClass = classNames("actions__option", {
    actions__option_disabled: status === 'inactive' || status === 'deleted',
    cursor: status !== 'inactive' && status !== 'deleted',
  });

  const parentClass = classNames({
    'actions__dropdown actions__dropdown_visible': !hideParentClass
  });

  const onRecordMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(status === 'paid' || balance === 0) {
      return true
    };

    closeMenu()
    onRecord(id)
  }

  return (
    <>
      <div className={parentClass}>
      <ActionMenu
        className='actions__option'
        text="Download PDF"
      >
      </ActionMenu>
      <ActionMenu
        onClick={onRecordMenu}
        className={activeClass}
        disabled={balance === 0 && status === 'paid'}
        text="Record Payment"
      >
      </ActionMenu>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
