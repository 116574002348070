import { invoices } from "../constants/invoices";
import { billing } from "../constants/billing";

const INITIAL_STATE = {
  list: {
    count: 0,
    data: [],
  },
  isLoading: false,
  detail: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case invoices.GET_INVOICES_PENDING:
      return {
        ...state,
        isLoading: true,
        list: {
          count: state.list.count || 0,
          data: state.list.data || []
        },
      };
      case invoices.GET_INVOICES_FULFILLED:
        return {
          ...state,
          isLoading: false,
          list: action.payload,
        };
        case invoices.GET_INVOICES_REJECTED:
          return {
            ...state,
            isLoading: false,
            list: {
              count: state.list.count || 0,
              data: state.list.data || []
            },
          };
    case invoices.GET_INVOICE_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        detail: null,
      };
    case invoices.GET_INVOICE_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
      };
    case invoices.GET_INVOICE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        detail: null,
      };
    default:
      return state;
  }
}
