export const model = {
GET_MODELS_PENDING: 'GET_MODELS_PENDING',
GET_MODELS_FULFILLED: 'GET_MODELS_FULFILLED',
GET_MODELS_REJECTED: 'GET_MODELS_REJECTED',

GET_MODEL_DETAILS_PENDING: 'GET_MODEL_DETAILS_PENDING',
GET_MODEL_DETAILS_FULFILLED: 'GET_MODEL_DETAILS_FULFILLED',
GET_MODEL_DETAILS_REJECTED: 'GET_MODEL_DETAILS_REJECTED',

ADD_FILE: 'ADD_FILE',
REMOVE_FILE: 'REMOVE_FILE',
UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
}