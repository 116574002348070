import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from 'classnames'

import SortableRow from "app/components/common/Table/SortableRow";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { status } = this.props

    const className = classNames('data-table__status type__value text-captilize',
      {
        'data-table__status_pos': status === 'active',
        'data-table__status_neg': status === 'Deactivated'
      })

    return (
      <div className={className}>{ status }</div>
    )
  }

  getRowRender = () => {
    const {
      colorClassName,
      actions,
      name
    } = this.props;

    return {
      name: () => <div className="data-table__td_dark">{name}</div>,
      actions: () => (
        <a href="/" className={`text-link ${colorClassName}`}>
          {actions}
        </a>
      ),
    };
  };

  render() {
    const { colorClassName} = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
