const filterColumns = [
  {
    id: 1,
    label: 'User email',
    field: 'email',
    type: 'search',
  },
  {
    id: 2,
    label: 'User name',
    field: 'username',
    type: 'search',
  },
{
  id: 1,
  label: 'Status',
  field: 'status',
  type: 'radio',
},
{
  id: 7,
  label: 'Created',
  field: 'createdAt',
  type: 'date',
},
{
  id: 8,
  label: 'Last sign in',
  field: 'lastLogin',
  type: 'date',
}
]

export default filterColumns