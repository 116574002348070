import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import SortableRow from 'app/components/common/Table/SortableRow'
import NumberFormat from 'react-number-format';
import {FormattedMessage} from 'react-intl'

import {dateFormatter} from 'utils/common'

class UserRow extends PureComponent {

  getStatus = (status) => {
    const className = classNames('data-table__status type__value text-captilize',
      {
        'data-table__status_pos': status === 'paid',
        'data-table__status_neg': status !== 'paid'
      })

    return (
      <div className={className}>{status}</div>
    )
  }

  getRowRender = () => {
    const {created, number, colorClassName, amount_due, status, invoice_pdf, currency} = this.props

    return {
      date: () => <div className="data-table__td_dark">{ dateFormatter(created * 1000, true)}</div>,
      invoiceNo: () => <div className="">{number}</div>,
      status: () => <span className="">{this.getStatus(status)}</span>,
      amount: () =><NumberFormat
        value={(amount_due && parseFloat(amount_due / 100).toFixed(2)) || '0.00'}
        displayType={'text'}
        thousandSeparator renderText={value =>
          <span>
            {currency && currency.toUpperCase()} {value}
          </span>
        }/>,
      actions: () => <a download href={invoice_pdf} target='_blank' rel="noopener noreferrer" className={`${colorClassName}`}>
        Download
      </a>,
    }
  }

  render() {
    const {colorClassName} = this.props

    return (
      <>
        <SortableRow
          { ...this.props }
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        />
        <td></td>
      </>
    )
  }
}

UserRow.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array
}

export default withRouter(UserRow)
