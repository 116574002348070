import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from 'utils/message';
import {
  WorkspaceImage,
  CloseIcon,
  UploadIcon,
  PlusUploadIcon
} from 'images/common';

const FileUploader = ({
  onUpdate,
  file,
  onRemove,
  color,
  uploading,
  title,
  accept
}) => {
  const [progress, setProgress] = useState(0);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: accept || '',
    disabled: uploading,
    multiple: false,
    onDrop: files => {
      if (!files.length) {
        return false;
      }

      onUpdate({ file: files[0] });
    }
  });

  const files = acceptedFiles.map(item => {
    const updateTimer = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 10);
      }

      clearInterval(updateTimer);
    }, 200);

    return uploading ? (
      <div className={`upload-field upload-field_${color} upload-field-loading`} key={item.path}>
        <div className="grid-row">
          <div className="grid-col grid-col_2">
            <div className="upload-field__icon">
              <WorkspaceImage />
            </div>
          </div>

          <div className="grid-col grid-col_8 break-all">
            <div className="upload-field__filename">{item.path}</div>
            <div className="upload-field__progress">
              <div style={{ width: `${progress}%` }} />
            </div>
          </div>

          <div className="grid-col grid-col_2 image-close-icon">
            <button
              type="button"
              className="upload-field__cancel"
              onClick={() => onRemove()}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    ) : (
      <span key={item.path} />
    );
  });

  return (
    <>
      <div className={`upload-field upload-field_${color}`}>
        <label className="upload-field__label">{title || 'Zip File'}</label>
        {!(file && file.name) &&
        <div {...getRootProps({ className: 'dropzone' })}>
          <div className={`upload-field__area ${color}`}>
            <UploadIcon />
            <div className="upload-field__caption">
              <FormattedMessage {...messages.dragFileHereOr} />{' '}
              <span className="color">
                <FormattedMessage {...messages.browse} />
              </span>
            </div>
            <div className="upload-field__plus">
              <PlusUploadIcon />
              <input {...getInputProps({ className: 'upload-field__input' })} />
            </div>
          </div>
        </div>
        }
      </div>
      <aside>
        <span>{files}</span>
        {!uploading && (file && file.name) && (
          <div className={`upload-field upload-field_${color}`}>
            <div className="grid-row">
              <div className="grid-col grid-col_2">
                <div className="upload-field__icon">
                  <WorkspaceImage />
                </div>
              </div>

              <div className="grid-col grid-col_8 break-all vertical-align">
                <div className="upload-field__filename">
                  {file && file.name}
                </div>
              </div>
              <div className="grid-col grid-col_2 break-all image-close-icon">
                <button
                  type="button"
                  className="upload-field__cancel"
                  onClick={() => onRemove()}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>
        )}
      </aside>
    </>
  );
};

FileUploader.propTypes = {
  workspace: PropTypes.object,
  removeLogo: PropTypes.func,
  uploading: PropTypes.bool,
  addLogo: PropTypes.func
};

export default compose()(FileUploader);
