import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import { FilePlus } from "images/common";
import NotFound from "app/components/NotFound";
import Pagination from "app/components/Pagination";
import Modal from "app/components/common/Modal";
import ProcessPage from "app/components/common/ProcessPage";

import SearchField from "app/components/common/SingleSearch";
import NoPlans from "../../common/NoItem";

import Card from "app/components/plans/list/Card";
import List from "app/components/plans/list/Table";
import { isSmallerDevice } from "utils/common";

import messages from "utils/message";
import { routeMapping } from "app/constants/routes";
import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DASHBOARD_ACCENT_COLOR,
} from "app/constants/common";
import {
  listPlans,
  deletePlanAction,
  updatePlanAction,
  deactivatePlanAction,
  activatePlanAction,
  setPlanAsDev,
} from "app/actions/plans";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "app/components/plans/list/filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";
import { defaultNotifier } from "functions/notificationHandler";

import ListSelectedItems from "../../common/ListSelectedItems";
import { createPlan } from "app/api/plans";
import { addSubscriptionPlan, changeSubscriptionPlan } from "app/api/workspaces";

class PlanList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      selectedIds: [],
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "prettyName",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.plans,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onDuplicate = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const plan = this.props.plans.find((plan) => plan.id === id);

    try {
      const { data } = await createPlan({
        name: plan.name,
        type: plan.type || "1 day",
        tags: plan.tags || [],
        description: plan.description || "",
        trial: plan.trial || 1,
        tiers: plan.tiers,
        currency: plan.currency || "usd",
        minEndpoints: plan.minEndpoints,
      });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }
      defaultNotifier("Successfully plan duplicated");
      this.props.history.push(
        `${routeMapping.PLANS.path}/${data.data.createSubscription.id}`
      );
    } catch (err) {
      defaultNotifier("Failed to duplicate a plan");
    }
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.plans);
    let result = finalResult;
    this.setCurrentPage(1);

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.prettyName
          .toLowerCase()
          .includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.setState({ filterResult: this.props.plans })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    if (typeof id === "string") {
      return this.setState({ selectedIds: [id] });
    }

    return this.setState({ selectedIds: id });
  };

  onSubscribeWs = async () => {
    const { workspace } = this.props;

    const { data } = await addSubscriptionPlan({
      workspaceId: workspace.id,
      planId: this.state.selectedIds[0],
    });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    defaultNotifier("New subscription added.");
    this.props.onCancel();
  };

  onChangeSubscribeWs = async () => {
    const { workspace, id } = this.props;

    const { data } = await changeSubscriptionPlan({
      workspaceId: workspace.id,
      subscriptionId: id,
      planId: this.state.selectedIds[0],
    });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    defaultNotifier("Subscription changed successfully.");
    this.props.onCancel();
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.getPlans();

    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  getPlans = () => {
    this.props.listPlans();
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  renderModal = () => {
    const { color } = this.props;
    const { plans, type, subscriptionName } = this.props;

    const plan = plans.find((plan) => plan.id === this.state.selectedIds[0]);
    const oldPlan = plans.find((plan) => plan.name === subscriptionName);

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          this.onCloseModal();
          this.props.onCancel();
          if(type === 'changePlan') {
            return this.onChangeSubscribeWs();
          }

          return this.onSubscribeWs();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={
          type !== "changePlan" ? "Add Subscription" : "Change Subscription"
        }
        body={
          type !== "changePlan"
            ? `You are subscribing the workspace to the new plan ${plan.prettyName}.`
            : `You are changing from the current plan ${oldPlan.prettyName || subscriptionName} to the new plan ${plan.prettyName}.`
        }
        onClose={this.onCloseModal}
      />
    );
  };

  showDeleteModal = (id, e) => {
    const { plans } = this.props;
    const plan = plans.find((plan) => plan.id === id);

    if (
      plan.status === "draft" ||
      plan.status === "active" ||
      !!plan.workspaceCount
    ) {
      return false;
    }
    this.setState({ showshowModal: true, selectedPlan: id });
  };

  onDelete = async (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    await this.props.deletePlanAction({ id });
    this.getPlans();
  };

  showAddonPlan = async (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ showAddPlanPage: true, selectedPlan: id });
  };

  closeQuota = () => {
    this.setState({ showQuotaPage: false, showAddPlanPage: false });
    this.getPlans();
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  onShowModal = () => {
    this.setState({ showModal: true });
  };

  isMore = () => {
    return false;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.PLAN.path}/${id}`);
  };

  redirectToCreatePage = () => {
    return this.props.history.push(routeMapping.CREATE_PLAN.path);
  };

  // pagination
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  onAcceptCondition = (e) => {
    this.setState({ isAccepted: e.target.checked || !this.state.isAccepted });
  };

  render() {
    const { color, category, isActiveOnly, product } = this.props;

    const {
      showModal,
      activeView,
      selectedIds,
      resetFilter,
      currentPage,
      showshowModal,
    } = this.state;

    const viewHeadlineClass = classNames(
      `actions-nav__icon actions-nav__icon_rows ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === TABLE_VIEW }
    );

    const viewListClass = classNames(
      `actions-nav__icon actions-nav__icon_cards ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === CARD_VIEW }
    );

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    return (
      <>
        {showModal && this.renderModal()}
        <ProcessPage onCancel={this.props.onCancel}>
          {(scrollRef) => (
            <>
              <div className="headline">
                <h1 className="headline__title">{this.props.title}</h1>
                <h3 className="headline__subtitle">{this.props.subTitle}</h3>
              </div>
              {!hasFilter &&
              !this.props.plans.length &&
              !this.props.isLoading ? (
                <NotFound
                  color={color}
                  strokeWidth="1"
                  logo={<FilePlus size={42} />}
                  title="Create your own plans"
                  description="Add dynamic plans for your workspaces"
                  buttonText="Create first plan"
                  onAction={this.redirectToCreatePage}
                />
              ) : (
                <>
                  <div className="filters-bar">
                    <ListSelectedItems
                      selectedNumber={selectedIds.length}
                      search={this.state.searchName}
                      onReset={this.onReset}
                      filterData={this.state.filter}
                      containerClassName={color}
                    />
                    {!this.props.isLoading && (
                      <Filter
                        customClass={color}
                        resetFilter={resetFilter}
                        onChange={this.onFilter}
                        columns={filterColumns}
                        data={this.props.plans}
                        defaultFilter={[
                          {
                            field: "status",
                            value: isActiveOnly ? "active" : "All",
                            isDefault: true,
                            columns: ["active", "inactive", "draft"],
                          },
                        ]}
                      />
                    )}
                    <SearchField
                      onSearch={this.onSearch}
                      customClass={color}
                      resetSearch={this.state.resetFilter}
                      label="Search"
                    />
                  </div>
                  <div>
                    <InfiniteScroll
                      initialLoad={false}
                      useWindow={false}
                      getScrollParent={() => this.scrollParent}
                    >
                      {activeView === TABLE_VIEW && (
                        <>
                          <List
                            data={
                              hasFilter
                                ? this.state.filterResult
                                    .filter(
                                      (item) => item.category === category
                                    )
                                    .filter((item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }

                                      if (
                                        currentPage !== 1 &&
                                        index <
                                          this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }

                                      return false;
                                    })
                                : this.props.plans
                                    .filter(
                                      (item) => item.category === category
                                    )
                                    .filter((item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }

                                      if (
                                        currentPage !== 1 &&
                                        index <
                                          this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }

                                      return false;
                                    })
                            }
                            onRowClick={this.onSelect}
                            selectAll={() => null}
                            hideSingleMenu
                            onSort={this.onSort}
                            color={color}
                            isLoading={this.props.isLoading}
                            onDeactivatePlan={this.onOpenDeactivatePlan}
                            onActivatePlan={this.onOpenActivatePlan}
                            onDuplicate={this.onDuplicate}
                            sort={this.state.sort}
                            allowOnlySingleSelect
                            isSelectedAll={this.getIsSelectedAll()}
                            selectedIds={selectedIds}
                            onSelect={this.onSelect}
                            onSetAsDevPlan={this.onSetAsDevPlan}
                            onUpdateStatus={this.onUpdateStatus}
                            onDelete={this.showDeleteModal}
                            showQuota={this.showQuota}
                            showAddonPlan={this.showAddonPlan}
                          />
                        </>
                      )}
                      {activeView === CARD_VIEW && (
                        <div className="data-cards">
                          <Card
                            color={color}
                            hideSingleMenu
                            data={
                              hasFilter
                                ? this.state.filterResult
                                    .filter(
                                      (item) => item.category === category
                                    )
                                    // .filter((item) =>
                                    //   product ? item.product === product : item
                                    // )
                                    .filter((item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }

                                      if (
                                        currentPage !== 1 &&
                                        index <
                                          this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }

                                      return false;
                                    })
                                : this.props.plans
                                    .filter(
                                      (item) => item.category === category
                                    )
                                    // .filter((item) =>
                                    //   product ? item.product === product : item
                                    // )
                                    .filter((item, index) => {
                                      if (
                                        currentPage === 1 &&
                                        index < this.state.offset
                                      ) {
                                        return item;
                                      }

                                      if (
                                        currentPage !== 1 &&
                                        index <
                                          this.state.offset * currentPage &&
                                        index >=
                                          this.state.offset * (currentPage - 1)
                                      ) {
                                        return item;
                                      }

                                      return false;
                                    })
                            }
                            showQuota={this.showQuota}
                            showAddonPlan={this.showAddonPlan}
                            onSetAsDevPlan={this.onSetAsDevPlan}
                            onDuplicate={this.onDuplicate}
                            onRowClick={this.onSelect}
                            selectedItems={selectedIds}
                            onDeactivatePlan={this.onOpenDeactivatePlan}
                            onActivatePlan={this.onOpenActivatePlan}
                            onUpdateStatus={this.onUpdateStatus}
                            onDelete={this.showDeleteModal}
                            onSelect={this.onSelect}
                          />
                        </div>
                      )}
                      {((hasFilter && !!this.state.filterResult.length) ||
                        (!hasFilter && !!this.props.plans.length)) && (
                        <Pagination
                          onNext={this.onNext}
                          currentPage={this.state.currentPage}
                          setCurrentPage={this.setCurrentPage}
                          onChangeLimit={this.onChangeLimit}
                          limit={this.state.offset}
                          totalPage={
                            hasFilter
                              ? this.state.filterResult.length
                              : this.props.plans.length
                          }
                        />
                      )}
                    </InfiniteScroll>
                    {activeView === CARD_VIEW &&
                      this.props.plans &&
                      !this.props.plans.length && <NoPlans />}
                  </div>
                  <div className="foot-nav">
                    <button
                      className={classNames(
                        `round-button round-button_br round-button_forw round-button_${color}`,
                        { "round-button_disabled": selectedIds.length !== 1 }
                      )}
                      disabled={selectedIds.length !== 1}
                      onClick={this.onShowModal}
                    >
                      <span className="round-button__label">
                        <FormattedMessage {...messages.save} />
                      </span>
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </ProcessPage>
      </>
    );
  }
}

PlanList.propTypes = {
  color: PropTypes.string,
  plans: PropTypes.array,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

PlanList.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    plans: state.plans.list,
    isLoading: state.plans.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  listPlans,
  setPlanAsDev,
  updatePlanAction,
  deletePlanAction,
  deactivatePlanAction,
  activatePlanAction,
});

export default compose(withRouter, withConnect)(PlanList);
