import { jsonToGraphQLQuery } from "json-to-graphql-query";

import axiosObj from "config/config";
import { BASE_URL } from "app/constants/common";

const DEFAULT_INVOICE_FIELDS = {
  invoiceId: true,
  invoiceNumber: true,
  amount: true,
  creditAdj: true,
  refundAdj: true,
  balance: true,
  currency: true,
  invoiceDate: true,
  targetDate: true,
  accountId: true,
  status: true,
  items: true,
  workspace: {
    id: true,
    name: true,
    createdBy: {
      id: true,
      name: true,
      email: true
    },
    status: true,
    billingContact: {
      id: true,
      name: true,
      email: true
    },
    paymentTerms: true
  }
};

const DEFAULT_INVOICES_FIELDS = {
  count: true,
  data: {
    invoiceId: true,
    invoiceNumber: true,
    amount: true,
    creditAdj: true,
    refundAdj: true,
    balance: true,
    currency: true,
    invoiceDate: true,
    targetDate: true,
    accountId: true,
    items: true,
    status: true,
    workspace: {
      id: true,
      name: true,
    }
  }
};

export function getInvoices(args) {
  const query = {
    query: {
      invoices: {
        __args: args,
        ...DEFAULT_INVOICES_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getInvoice(payload) {
  const query = {
    query: {
      invoice: {
        __args: payload,
        ...DEFAULT_INVOICE_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
