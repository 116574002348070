import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import ListScroll from "app/components/common/ListScroll";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import SingleMenu from "./SingleMenu";
import CustomInput from "app/components/common/Input/CustomInput";
import ProcessPage from "app/components/common/ProcessPage";
import EditForm from "app/components/common/Edit/EditForm";
import { SOLUTION_ACCENT } from "app/constants/common";
import { createPlanAction, listPlans } from "app/actions/plans";
import {
  getVersionInfo,
  updateVersionSettings,
  createVisoSetting,
} from "app/api/users";
import { billingCatalog } from "app/api/billing";
import { defaultNotifier } from "functions/notificationHandler";
import { SettingIcon } from "images/common";
import Card from "app/components/common/Detail/Card";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { dateFormatter, clipboard, toolbarOptions } from "utils/common";
import Editor from "./Editor";
import PreLoader from "../common/PreLoader/PreLoaderDetail";
import Switch from "app/components/common/Input/Switch";
import CustomSelect from "app/components/common/Input/CustomSelect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Announcement from "./Announcement";
import Staging from './Staging'

const getAccent = [
  {
    label: "Main accent",
    value: "accent0",
  },
  {
    label: "Alert color",
    value: "danger",
  },
  {
    label: "Success Color",
    value: "success",
  },
];

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: [],
      billingCatalog: {},
      color: "accent0",
      enabledAnnouncement: false,
      enableCloseBtn: true,
      limitedVisibility: true,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const { data } = await getVersionInfo();
      const result = await billingCatalog();
      this.setState({ isLoading: false });

      if (data.errors) {
        defaultNotifier(data.errors);
      }

      if (result.data.errors) {
        defaultNotifier(result.data.errors);
      }

      if (result.data.data) {
        this.setState({ billingCatalog: result.data.data.billingCatalog });
      }

      if (data.data) {
        this.setState({ settings: data.data.visoSettings });
      }
    } catch (err) {
      this.setState({ isLoading: false });

      return defaultNotifier(err);
    }
  }

  onChangeField = (name, value) => {
    const data = this.state.settings.map((setting) => {
      if (setting.name === name) {
        return { name: setting.name, value: value };
      }

      return setting;
    });

    this.setState({ [name]: value, settings: data });
  };

  onUpdateField = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    this.setState({ isLoading: true });

    this.state.settings.forEach(async (item) => {
      if (this.state[item.name]) {
        try {
          await updateVersionSettings({
            name: item.name,
            value: this.state[item.name],
          });
          this.setState({ isLoading: false, showEditModal: false });
        } catch (err) {
          this.setState({ isLoading: false });
          return defaultNotifier(err);
        }
      }
      this.setState({ showEditModal: false, isLoading: false });
    });
  };

  onCreate = async () => {
    this.setState({ isLoading: true });

    try {
      const { data } = await createVisoSetting({
        name: this.state.fieldName,
        value: this.state.fieldValue || "0.0.0",
      });
      this.setState({ isLoading: false });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }
      this.setState({
        settings: [...this.state.settings, data.data.createVisoSetting],
        showCreatePage: false,
      });
      return defaultNotifier("New setting added");
    } catch (err) {
      this.setState({ isLoading: false });
      return defaultNotifier(err);
    }
  };

  renderContent = () => {
    const { color } = this.props;

    return (
      <>
        {this.state.settings
          // .filter(setting => setting.name ==='Viso Builder Version' || setting.name === 'Viso Agent Version')
          .map((setting) => (
            <div
              className={`text-field text-field_${color}`}
              key={setting.name}
            >
              <CustomInput
                value={setting.value}
                onChange={(e) =>
                  this.onChangeField(setting.name, e.target.value)
                }
                label={setting.name}
                isInvalid={!setting.value}
                postIconClass="success"
                hintText={[setting.value] ? "" : `${setting.value} is required`}
              />
            </div>
          ))}
      </>
    );
  };

  getStatus = (status) => {
    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === "active",
        "data-table__status_neg": status === "inactive",
      }
    );

    return <div className={className}>{status}</div>;
  };

  onChangeSwitch = (name, value) => {
    this.setState({ [name]: value });
  };

  onChangeColor = (value) => {
    this.setState({ color: value });
  };

  onChangeEditor = (editorState) => this.setState({ editorState });

  renderCreateContent = () => {
    const { color } = this.props;

    return (
      <>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={this.state.fieldName}
            onChange={(e) => this.onUpdateField("fieldName", e.target.value)}
            label={"Label name"}
            isInvalid={!this.state.fieldName}
            hintText={this.state.fieldName ? "" : "Label is required"}
          />
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={this.state.fieldValue}
            onChange={(e) => this.onUpdateField("fieldValue", e.target.value)}
            label={"Value"}
          />
        </div>
      </>
    );
  };

  defaultContent = () => {
    const { settings } = this.state;

    return (
      <div className="grid-col">
        {settings.map((item) => {
          return (
            <div className="card-setting-item">
              <div className="overview__label">{item.name}</div>
              <div className="overview__value">{item.value}</div>
            </div>
          );
        })}
      </div>
    );
  };

  stagingSettingContent = () => {
    const { settings } = this.state;

    return (
      <div className="grid-col">
        <div className="card-setting-item">
          <div className="overview__label">Staging status</div>
          <div className="overview__value">N/A</div>
        </div>
      </div>
    );
  };

  billingContent = () => {
    const { billingCatalog } = this.state;

    if (!billingCatalog) {
      return <div />;
    }

    return (
      <div className="grid-col">
        <div className="card-setting-item">
          <div className="overview__label">Catalog Effective Date</div>
          <div className="overview__value">{billingCatalog.effectiveDate}</div>
        </div>
        <div className="card-setting-item">
          <div className="overview__label">Last updated</div>
          <div className="overview__value">
            {dateFormatter(billingCatalog.updatedAt, true)}
          </div>
        </div>
      </div>
    );
  };

  onCreateSetting = () => {
    this.setState({ showCreatePage: true });
  };

  onClose = () => {
    this.setState({ showCreatePage: false });
  };

  onDownload = async () => {
    const { billingCatalog } = this.state;

    const a = document.createElement("a");
    var blob = new Blob([billingCatalog.xml], { type: "text/plain" });

    a.setAttribute("href", window.URL.createObjectURL(blob));
    a.setAttribute("download", `catalog-${billingCatalog.effectiveDate}.xml`);

    a.dataset.downloadurl = ["text/plain", a.download, a.href].join(":");
    a.click();
  };

  render() {
    const { color } = this.props;
    const { showEditModal, updateCatalogModal, showAnnoucementPage } =
      this.state;

    const submitClass = classNames(`button button_${color}`, {
      // button_disabled: !name,
    });

    if (this.state.isLoading) {
      return <PreLoader color={this.props.color} />;
    }

    if (this.state.showCreatePage) {
      return (
        <ProcessPage onCancel={this.onClose}>
          {() => (
            <EditForm
              color={this.props.color}
              submitLabel="Save"
              submitClassName={
                !this.state.fieldName ? "round-button_disabled" : null
              }
              onSubmit={this.onCreate}
              title="Create viso settings"
              content={this.renderCreateContent()}
              disabled={!this.state.fieldName}
            />
          )}
        </ProcessPage>
      );
    }

    if (showEditModal) {
      return (
        <ProcessPage onCancel={() => this.setState({ showEditModal: false })}>
          {() => (
            <EditForm
              color={this.props.color}
              submitLabel="Save"
              title="Update Viso Agent"
              subTitle="Update the current Viso Agent version number"
              submitClassName={submitClass}
              onSubmit={this.onSubmit}
              content={this.renderContent()}
            />
          )}
        </ProcessPage>
      );
    }

    if (updateCatalogModal) {
      return (
        <Editor
          color={this.props.color}
          onClose={() => this.setState({ updateCatalogModal: false })}
          billingCatalog={this.state.billingCatalog}
        />
      );
    }

    if (this.state.updateStagingStatus) {
      return (
        <Staging
          onClose={() => this.setState({ updateStagingStatus: false })}
        />
      )
    }
    return (
      <ListScroll>
        {(scrollParent) => (
          <>
            <div className="headline">
              <div className="headline__icon admin_workspace__image accent4">
                <SettingIcon />
              </div>
              <h1 className="headline__title">Settings</h1>
            </div>
            <div className={`secondary-nav secondary-nav_${color}`}>
              <Link
                to="/settings/agent"
                className="secondary-nav__link secondary-nav__link_active"
              >
                Viso Agent
              </Link>
            </div>
            {/* <Breadcrumb customClass={color}>
              <BreadcrumbItem route="Settings"/>
              <BreadcrumbItem route="General Settings of the Viso Platform." />
            </Breadcrumb> */}
            <div className="actions-nav">
              <div className="actions">
                <VerticalButtonMenu customClass={color}>
                  {/* <SingleMenu
                    hideParentClass
                    onCreateSetting={this.onCreateSetting}
                  /> */}
                </VerticalButtonMenu>
              </div>
            </div>
            <div className="grid-row">
              <div className="grid-col">
                <Card
                  color={this.props.color}
                  title="Viso Agent"
                  fullWidth
                  className="overview"
                  customText={"Update"}
                  action={() => this.setState({ showEditModal: true })}
                  content={this.defaultContent()}
                />
                <Announcement />
              </div>
              <div className="grid-col">
                <Card
                  color={this.props.color}
                  title="Billing Catalog"
                  fullWidth
                  className="overview"
                  customText={"Update"}
                  secondaryCustomText={"Download"}
                  action={() => {
                    this.setState({ updateCatalogModal: true }, () => { });
                  }}
                  secondaryAction={this.onDownload}
                  content={this.billingContent()}
                />
                <Card
                  color={this.props.color}
                  title="Staging Settings"
                  fullWidth
                  className="overview"
                  customText={"Edit"}
                  action={() => {
                    this.setState({ updateStagingStatus: true }, () => { });
                  }}
                  content={this.stagingSettingContent()}
                />
              </div>
            </div>
          </>
        )}
      </ListScroll>
    );
  }
}

Settings.propTypes = {
  color: PropTypes.string,
};

Settings.defaultProps = {
  color: SOLUTION_ACCENT,
};

const withConnect = connect(null, {
  createPlanAction,
  listPlans,
});

export default compose(withRouter, withConnect)(Settings);
