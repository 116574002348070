import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from 'classnames'

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import { dateFormatter, getInvoice } from "utils/common";
import NumberFormat from "react-number-format";

class invoiceOverview extends Component {
  getStatus = (status) => {
    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === 'paid',
        "data-table__status_neg": status === 'overdue',
        "data-table__status_neutral": status === 'deleted',
      }
    );

    return <div className={className}>{status}</div>
  };

  getAnnotationStatus = () => {
    const { annotationEnabled } = this.props.invoice;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": annotationEnabled,
        "data-table__status_neg": !annotationEnabled,
      }
    );
    return <div className={className}><span className="text-capitalize">{annotationEnabled ? 'Active' : 'Inactive'}</span></div>
  };

  getTrainingStatus = () => {
    const { trainingEnabled } = this.props.invoice;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": trainingEnabled,
        "data-table__status_neg": !trainingEnabled,
      }
    );
    return <div className={className}><span className="text-capitalize">{trainingEnabled ? 'Active' : 'Inactive'}</span></div>
  };


  renderContent = () => {
    const {invoice} = this.props
    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.amount} />
          </div>
          <div className="overview__value">
          <NumberFormat
            value={invoice.amount.toFixed(2)}
            displayType={"text"}
            thousandSeparator
            renderText={(value) => <span>
              {invoice.currency} {value}
              </span>
            }
          />
          </div>
          <div className="overview__label">
            Created Date
          </div>
          <div className="overview__value">{dateFormatter(invoice.invoiceDate)}</div>
          <div className="overview__label">
            Invoice Credit
          </div>
          <div className="overview__value">
          <NumberFormat
            value={invoice.creditAdj.toFixed(2)}
            displayType={"text"}
            thousandSeparator
            renderText={(value) => <span>
              {invoice.currency} {value}
              </span>
            }
          />
          </div>
          <div className="overview__label">
            Invoice Status
          </div>
          <div className="overview__value">{this.getStatus(invoice.status)}</div>
        </div>
        <div className="grid-col">
        <div className="overview__label">
            <FormattedMessage {...messages.invoiceNumber} />
          </div>
          <div className="overview__value">
            {getInvoice(invoice.invoiceNumber)}
          </div>
          <div className="overview__label">
            Due Date
          </div>
          <div className="overview__value">
          <div className="overview__value">{dateFormatter(invoice.targetDate)}</div>
          </div>
          <div className="overview__label">
          Balance
          </div>
          <div className="overview__value">
          <NumberFormat
            value={invoice.balance.toFixed(2)}
            displayType={"text"}
            thousandSeparator
            renderText={(value) => <span>
              {invoice.currency} {value}
              </span>
            }
          />
          </div>
          <div className="overview__label">
            Paid at
          </div>
          <div className="overview__value"></div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.overview} />}
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

invoiceOverview.propTypes = {
  onEdit: PropTypes.func,
  color: PropTypes.string,
  invoice: PropTypes.object,
};

export default invoiceOverview;
