import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { orderBy } from "lodash";
import Pagination from "app/components/Pagination";
import 'react-dates/initialize';
import { defaultNotifier } from 'functions/notificationHandler'
import SearchField from "app/components/common/SingleSearch";
import Card from "app/components/invoices/list/Card";
import List from "app/components/invoices/list/Table";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import messages from "utils/message";
import Noinvoices from "../../common/NoItem";
import { routeMapping } from "app/constants/routes";
import { isSmallerDevice } from "utils/common";
import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ACCENT,
} from "app/constants/common";
import { listInvoice } from "app/actions/invoices";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "app/components/invoices/list/filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";
import ProcessPage from "app/components/common/ProcessPage";

import ListSelectedItems from "../../common/ListSelectedItems";

class WorkspaceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      dateInfo: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'dateInfo'
      },
      currentPage: 1,
      selectedIds: [],
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "invoiceNumber",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      filteredWorkspace: [],
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
      tags: [],
      note: '',
      reference: ''
    };
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      filteredWorkspace: orderBy(
        hasFilter ? this.state.filteredWorkspace.map(item => ({...item, id: item.invoiceId}))
        : 
        this.props.invoices.map(item => ({...item, id: item.invoiceId}))
        ,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    if(!this.props.isLoading) {
      this.setCurrentPage(1);
    }

    const finalResult = getOutput(
      filterData,
      this.props.invoices.data
    );
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filteredWorkspace: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
      },
      () => this.setState({ filteredWorkspace: this.props.invoices })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    if(typeof (id) === 'string') {
      return this.setState({ selectedIds: [id] });
    }
    return this.setState({ selectedIds: id });

  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.getInvoices();
  
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  getInvoices = () => {
    this.props.listInvoice({limit: 10, offset: 0});
  };

  onCloseModal = () => {
    this.setState({ showModal: false, note: '', reference: '', tags: [] });
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.INVOICES.path}/${id}`);
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  // pagination 
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
    
    this.props.listInvoice({limit: offset, offset: 0});
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
    if(currentPage !== this.state.currentPage) {
      this.props.listInvoice({limit: this.state.offset, offset: (currentPage - 1) * this.state.offset});
    }
  };

  render() {
    const { color, invoices = [] } = this.props;

    const {
      searchName,
      showModal,
      showOnlyArchived,
      activeView,
      selectedIds,
      resetFilter,
    } = this.state;
    let workspaceList = [];

    workspaceList = invoices;

    if (showOnlyArchived) {
      workspaceList =
        invoices && invoices.data.filter((workspace) => workspace.archived);
    }

    const viewHeadlineClass = classNames(
      "actions-nav__icon actions-nav__icon_cards cursor",
      {
        "actions-nav__icon_active": activeView === CARD_VIEW,
        [color]: color,
      }
    );

    const viewListClass = classNames(
      "actions-nav__icon actions-nav__icon_rows cursor",
      {
        "actions-nav__icon_active": activeView === TABLE_VIEW,
        [color]: color,
      }
    );

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    // if (
    //   !this.state.searchName &&
    //   !this.state.filter.single.length &&
    //   !this.state.filter.range.length &&
    //   !result.length
    // ) {
    //   return (
    //     <NotFound
    //       color={color}
    //       strokeWidth="1"
    //       logo={<WorkspaceIcon size={42} />}
    //       title="Start deploying your Apps"
    //       description="Using profiles, you’ll be able to deploy your applications to
    //    devices. Create profiles to manage deployments."
    //       buttonText="Create first profile"
    //       onAction={this.props.onCreate}
    //     />
    //   );
    // }
    return (
      <>
 <ProcessPage onCancel={this.props.onClose}>
        {(scrollParent) => (
            <>
          {this.state.showModal && this.renderModal()}
          <div className="popup__cont">
            <div className="login">
              <div className="headline">
                <h1 className="headline__title">
                <FormattedMessage {...messages.invoices} />
                </h1>
                <h3 className="headline__subtitle">
                </h3>
              </div>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
              </div>

              <div className="filters-bar">
                <ListSelectedItems
                  selectedNumber={selectedIds.length}
                  search={this.state.searchName}
                  onReset={this.onReset}
                  filterData={this.state.filter}
                  containerClassName={color}
                />
                <Filter
                  customClass={color}
                  resetFilter={resetFilter}
                  onChange={this.onFilter}
                  disabled={this.props.isLoading}
                  columns={filterColumns}
                  data={this.props.invoices && this.props.invoices.data}
                  defaultFilter={[{
                    field: "status",
                    value: "All",
                    isDefault: true,
                    columns: ["paid", "overdue", "open"],
                  }]}
                />
                <SearchField
                  onSearch={this.onSearch}
                  customClass={color}
                  resetSearch={this.state.resetFilter}
                  label="Search"
                />
              </div>
              <div>
                <InfiniteScroll
                  getScrollParent={() => scrollParent}
                >
                  {activeView === TABLE_VIEW && (
                    <>
                    <List
                      data={
                        hasFilter
                          ? this.state.filteredWorkspace
                          : this.props.invoices && this.props.invoices.data
                          .map(item => ({...item, id: item.invoiceId}))
                      }
                      onRowClick={this.onSelect}
                      selectAll={() => null}
                      onSort={this.onSort}
                      color={color}
                      allowOnlySingleSelect={true}
                      isLoading={this.props.isLoading}
                      sort={this.state.sort}
                      isSelectedAll={this.getIsSelectedAll()}
                      selectedIds={selectedIds}
                      onSelect={this.onSelect}
                      onRecord={this.onRecord}
                      // onUpdate={this.onUpdateWorkspace}
                    />
                      </>
                  )}
                  {activeView === CARD_VIEW && (
                    <div className="data-cards">
                      <Card
                        color={color}
                        data={
                          hasFilter
                            ? this.state.filteredWorkspace
                            : this.props.invoices.data
                          .map(item => ({...item, id: item.invoiceId}))
                        }
                        onRowClick={this.onSelect}
                        allowOnlySingleSelect={true}
                        selectedItems={selectedIds}
                        onSelect={this.onSelect}
                        isLoading={this.props.isLoading}
                        onRecord={this.onRecord}
                      />
                    </div>
                  )}
                    {((hasFilter && !!this.state.filteredWorkspace.length) ||
                      (!hasFilter && !!this.props.invoices.data.length)) && (
                      <Pagination
                        invoicePage
                        currentPage={this.state.currentPage}
                        setCurrentPage={this.setCurrentPage}
                        onChangeLimit={this.onChangeLimit}
                        limit={this.state.offset}
                        totalPage={hasFilter ? this.state.filteredWorkspace.length : 
                          this.props.invoices.count
                        }
                      />
                    )}
                </InfiniteScroll>
                {activeView === CARD_VIEW &&
                  !this.props.isLoading &&
                  !invoices.count
                  && <Noinvoices />}
              </div>
              <div className="foot-nav">
                <button
                  className={classNames(
                    `round-button round-button_br round-button_forw round-button_${color}`,
                    {'round-button_disabled': selectedIds.length !== 1 ,
                    }
                  )}
                  disabled={selectedIds.length !== 1}
                  onClick={() => this.props.onSelectInvoice(selectedIds)}
                >
                  <span className="round-button__label">
                    <FormattedMessage {...messages.save} />
                  </span>
                </button>
              </div>
              </div>
              </div>
            </>
          )}
        </ProcessPage>
      </>
    );
  }
}

WorkspaceList.propTypes = {
  color: PropTypes.string,
  invoices: PropTypes.array,
  activeView: PropTypes.string,
};

WorkspaceList.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    invoices: state.invoices.list,
    isLoading: state.invoices.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  listInvoice,
});

export default compose(withRouter, withConnect)(WorkspaceList);
