import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";

import List from "./Table";

import { routeMapping } from "app/constants/routes";

import {
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE
} from "app/constants/common";

import { getFilterData } from "app/components/common/Filter/filter";
import ProcessPage from "app/components/common/ProcessPage";

const data = [
  {
    id: "1",
    name: "Apr 8, 2020",
    invoice: "VS-90234",
    billingPeriod: "Monthly",
    amount: "$204.30",
    actions: "Download",
  }
];

class Billing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      selectedIds: [],
      showDropdown: false,
      sort: {
        key: "name",
        direction: "asc",
      },
      resetFilter: false,
      filter: null,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    return this.setState(
      {
        sort,
        offset: 0,
      },
      this.getWorkspaces
    );
  };

  onSearch = (names) => {
    const filterList = names.map((name) => {
      return {
        field: "name",
        value: name,
      };
    });

    return this.setState(
      { filter: filterList, resetSearch: false },
      this.getWorkspaces
    );
  };

  onFilter = (data) => {
    const { filter, searchName } = getFilterData(data);

    this.setState(
      {
        resetFilter: false,
        filter,
        offset: 0,
        searchName: searchName ? searchName.filterValue : null,
      },
      this.getWorkspaces
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props.modules;
    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  onReset = () => {
    this.setState({ resetSearch: true });
    this.setState({ selectedIds: [] });

    if (this.state.filter) {
      this.setState(
        { filter: null, searchName: "", resetFilter: true },
        this.getWorkspaces
      );
    }
    document.getElementById("search-box").value = "";

    if (this.state.searchName) {
      this.setState({ searchName: "", resetSearch: true }, this.getWorkspaces);
    }
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {}

  getWorkspaces = () => {
    return;
  };

  loadMore = () => {
    const { data } = this.props;

    if (data.loading) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE }, () =>
      this.getWorkspaces()
    );
  };

  isMore = () => {
    return false;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.WORKSPACE.path}/${id}`);
  };

  addModule = () => {
    this.props.history.push(routeMapping.IMPORT_MODULE.path);
  };
  
  render() {
    const { color } = this.props;

    const {
      selectedIds,
    } = this.state;

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {(scrollRef) => (
          <>
            <div className="headline">
              <h1 className="headline__title">Billing History</h1>
              <h3 className="headline__subtitle">{this.props.workspace.organizationName}</h3>
            </div>
            <InfiniteScroll
              className="data-table"
              loadMore={this.debouncedLoadMore}
              hasMore={false}
              initialLoad={false}
              loader={
                <div className="ml-3" key={0}>
                  Loading...
                </div>
              }
              useWindow={false}
              getScrollParent={() => this.scrollParent}
            >
              <List
                color={color}
                data={data}
                selectedIds={selectedIds}
                onSortChange={this.onSorting}
                sort={this.state.sort}
                onSelect={this.onSelect}
                isSelectedAll={this.selectAll}
              />
            </InfiniteScroll>
          </>
        )}
      </ProcessPage>
    );
  }
}

Billing.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

export default compose(withRouter)(Billing);
