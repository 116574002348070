const filterColumns = [
  {
    id: 1,
    label: 'Status',
    field: 'status',
    type: 'radio',
  },
  {
    id: 2,
    label: 'Worskpace',
    field: 'workspace',
    type: 'multiSelect',
  },
  {
    id: 5,
    label: 'Created',
    field: 'invoiceDate',
    type: 'date',
  }]
  
  export default filterColumns