import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { SubscriptionIcon } from "images/common";
import DetailScroll from "app/components/common/Scroll";
import Information from "./components/Information";
import Workspace from "./components/Workspace";
import {updateWorkspaceAnnotationSetting, updateWorkspaceTrainingSetting, cvEnvs} from 'app/api/workspaces'
import { defaultNotifier } from 'functions/notificationHandler'
import ActionMenu from 'app/components/common/ActionMenu';

import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";

import { DEFAULT_ACCENT } from "app/constants/common";
import {
  getWorkspaceDetails,
  listWorkspaceSubscriptions,
  listVisoAgents
} from "app/actions/workspaces";
import SingleMenu from "../list/SingleMenu";
import messages from "utils/message";
import { dateFormatter } from "utils/common";
import LoadingIndicator from "app/components/common/LoadingIndicator";

import Modal from "app/components/common/Modal";

import {
  listWorkspaces,
  deleteWorkspaceAction,
  scheduleDeleteWorkspaceAction,
  archiveWorkspaceAction,
  cancelScheduleDeleteWorkspaceAction,
  activateWorkspaceAction,
  deactivateWorkspaceAction,
  getWorkspaceInvoice
} from "app/actions/workspaces";
import { routeMapping } from "app/constants/routes";
import AssociatedContact from "./components/AssociatedContact";
import WorkspaceOverview from "./components/WorkspaceOverview";
import Products from "./components/Products";

class WorkspaceDetail extends React.Component {
  state = {
    workspace: {},
    showDeleteModal: false,
    showDropdown: false,
    loggedInUser: this.props.loggedInUser,
    editPopupForm: null,
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  getWorkspaces = () => {
    const { id } = this.props.match.params;

    this.props.getWorkspaceDetails({ id });
  };

  redirectToWorkspaces = () => {
    this.setState({ showDeleteModal: false });

    this.props.history.push(routeMapping.WORKSPACES.path);
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.props.getWorkspaceDetails({ id });
  }

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  renderModal = () => {
    const { body, onConfirm, onCancel, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={onCancel}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={onCancel} />
    );
  };

  render() {
    const { color, workspace, invoices } = this.props;
    const { showModal } = this.state;
    const { id } = this.props.match.params;

    if (!workspace) {
      return <DetailScroll/>
    }

    if(this.props.isLoading){
      return <LoadingIndicator
        color={this.props.colorClassName}
      />
    }

    return (
      <DetailScroll>
        {showModal && this.renderModal()}
        <div className={"messages-bar snackbar-wrapper"}></div>
        <div className="headline">
          <div className="headline__icon admin_workspace__image accent0">
            <SubscriptionIcon />
          </div>
          <h1 className="headline__title">{workspace.name}</h1>
        </div>

        <Breadcrumb breadcrumbClass={`breadcrumbs breadcrumbs_${color}`}>
          <BreadcrumbItem route="Subscriptions" />
          <BreadcrumbItem route={workspace.name} />
        </Breadcrumb>

        <div className="actions-nav">
          <div className="actions">
            <VerticalButtonMenu customClass={color}>
              <ActionMenu
                className={'actions__option'}
                text={'Export current view'}
                onClick={(e) => null}
              />
              <ActionMenu
                className={'actions__option'}
                text={'Refresh list'}
                onClick={(e) => null}
              />
            </VerticalButtonMenu>
          </div>
        </div>
          <div className="grid-row">
            <div className="grid-col grid-col_sm-12">
              {workspace && (
                <Information workspace={workspace} color={color} />
              )}
              {workspace && <Workspace color={color} workspace={workspace} />}
              {workspace && (
                <AssociatedContact workspace={workspace} color={color} />
              )}

            </div>
            <div className="grid-col grid-col_sm-12">
              {workspace && (
                <WorkspaceOverview color={color} workspace={workspace} />
              )}
              {workspace && (
                <Products color={color} workspace={workspace} />
              )}

            </div>
          </div>
      </DetailScroll>
    );
  }
}

WorkspaceDetail.propTypes = {
  color: PropTypes.string,
  workspace: PropTypes.object,
  listRequest: PropTypes.func,
  workspaces: PropTypes.array,
  loggedInUser: PropTypes.object,
  updateWorkspaceRequest: PropTypes.func,
};

WorkspaceDetail.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    workspace: state.workspaces.detail,
    workspaces: state.workspaces.list,
    invoices: state.workspaces.invoices,
    agents: state.workspaces.agents,
    isLoading: state.workspaces.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  getWorkspaceDetails,
  getWorkspaceInvoice,
  listWorkspaceSubscriptions,
  listWorkspaces,
  listVisoAgents,
  deactivateWorkspaceAction,
  deleteWorkspaceAction,
  cancelScheduleDeleteWorkspaceAction,
  scheduleDeleteWorkspaceAction,
  archiveWorkspaceAction,
  activateWorkspaceAction,
});

export default compose(withRouter, withConnect)(WorkspaceDetail);
