const filterColumns = [
{
  id: 1,
  label: 'Status',
  field: 'status',
  type: 'radio',
},
{
  id: 2,
  label: 'Plan Name',
  field: 'name',
  type: 'search',
},
{
  id: 3,
  label: 'Product ID',
  field: 'productId',
  type: 'search',
},
// {
//   id: 4,
//   label: 'Price ID',
//   field: 'priceId',
//   type: 'checkbox',
// },
// {
//   id: 5,
//   label: 'Provider',
//   version: 'provider',
//   type: 'checkbox',
// },
{
  id: 6,
  label: 'Subscriptions',
  field: 'workspaceCount',
  type: 'singleRange',
},
{
  id: 7,
  label: 'Created',
  field: 'createdAt',
  type: 'date',
}]

export default filterColumns