import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from 'classnames';

class Status extends Component {
    render() {
      const {type, status, customClass} = this.props
  
      const className = classNames('data-table__status type__value text-captilize label-color',
      {
        'data-table__status_pos': type === 'active',
        'data-table__status_neg': type === 'inactive',
        [customClass]: customClass
      })

      return (
        <div className={className}>{status}</div>
    );
  }
}

Status.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Status;
