import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { EnumType } from "json-to-graphql-query";
import { orderBy } from "lodash";
import NotFound from "app/components/NotFound";
import { FilePlus } from "images/common";
import {FormattedMessage} from 'react-intl'
import SearchField from "app/components/common/SingleSearch";
import messages from 'utils/message'
import List from "./Table";
import Card from "./Card";
import Modal from 'app/components/common/Modal';

import { routeMapping } from "app/constants/routes";

import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import ProcessPage from "app/components/common/ProcessPage";
import { listWorkspaceSubscriptions } from "app/actions/plans";
import ActionsView, { TABLE_VIEW, CARD_VIEW } from "app/components/ActionsView";
import AddNewPrice from "../details/Create/Pricing";
import {
  createPriceAction,
  getPlanDetails,
  deletePriceAction,
  setDefaultPriceAction,
} from "app/actions/plans";
import EditPricing from "../details/Edit/Pricing";
import { getOutput } from "app/components/common/Filter/clientFilter";
import ListSelectedItems from "../../common/ListSelectedItems";

const priceItem = {
  month: "monthly",
  year: "yearly",
};

class Subscription extends React.Component {
  state = {
    resetFilter: false,
    filter: {
      single: [],
      range: [],
    },
    sort: {
      key: "name",
      direction: "asc",
    },
    searchName: null,
    showPriceTier: false,
    offset: 0,
    activeView: TABLE_VIEW,
  };

  componentDidMount() {
    this.fetchPlan()
  }

  setDefaultPrice = (priceId, interval) => {
    const { plan } = this.props;

    const payload = {
      id: priceId,
      planId: plan.id,
      interval: new EnumType(interval),
    };

    this.props.setDefaultPriceAction(payload, this.fetchPlan);
  };

  deletePrice = (priceId) => {
    const { plan } = this.props;

    const payload = {
      id: this.state.selectedPriceId,
      planId: plan.id,
    };
    this.setState({showModalDelete: false});
    this.props.deletePriceAction(payload, this.fetchPlan);
  };

  fetchPlan = () => {
    const { id } = this.props.match.params;

    this.props.getPlanDetails({ productId: id });
    this.setState({ showNewPrice: false });
  };

  onSelect = (id, e) => {
    e.stopPropagation();
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  onRowClick = (id) => {
    // this.setState({
    //   showPriceTier: true,
    //   price: this.props.data.find((item) => item.id === id),
    // });
  };

  addModule = () => {
    this.props.history.push(routeMapping.IMPORT_MODULE.path);
  };

  onSearch = () => {
    return null;
  };

  onRedirectToPlan = (id) => {
    return this.props.history.push(`${routeMapping.PLANS.path}`);
  };

  onClose = () => {
    return this.props.history.push(routeMapping.WORKSPACES.path);
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  showAddPrice = () => {
    this.setState({ showNewPrice: true });
  };

  hideAddPrice = () => {
    this.setState({ showNewPrice: false });
  };

  onCloseModal = () => {
    this.setState({ showPriceTier: false });
  };

  onSubmit = (data) => {
    const { plan } = this.props;

    const tiers = data.tiers.map((item) => {
      return { ...item, unit_amount: parseInt(item.unit_amount * 100) };
    });

    const payload = {
      tiers: tiers,
      interval: new EnumType(data.interval),
      currency: new EnumType(data.currency),
      minEndpoints: +data.minEndpoints,
      trial: +data.trial,
      trialFee: +data.trialFee * 100,
      planId: plan.id,
    };

    this.props.createPriceAction(payload, this.fetchPlan);
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    const payload = this.props.data

    this.setState({
      sort,
      isSorted: true,
      offset: 0,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : payload,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: 0,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: 0,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onRenderDeleteModal = () => {
    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={e => this.deletePrice()}
      >
          Confirm
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onHideDeleteWarning}
      >
        Cancel
      </button>
    ];

    return (
      <Modal
        buttons={buttons}
        title={<><FormattedMessage {...messages.removePrice} />?</>}
        body={
          <p>
            <FormattedMessage {...messages.pleaseConfirmToProceed} />
          </p>
        }
        onClose={this.onHideDeleteWarning}
      />
    );
  };

  onHideDeleteWarning = async () => {
    this.setState({ showModalDelete: false, selectedPriceId: '' });
  };

  onShowDeleteWarning = async (e, id) => {
    e.stopPropagation();

    this.setState({ showModalDelete: true, selectedPriceId: id });
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const payload = this.props.data;
    const finalResult = getOutput(filterData, payload);
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: 0,
      },
      () => this.setState({ filterResult: this.props.data })
    );
  };

  render() {
    const { color, plan } = this.props;

    const { selectedIds, resetFilter, activeView } = this.state;

    if (this.state.showPriceTier) {
      return (
        <EditPricing
          editMode
          plan={this.props.plan}
          tiers={this.state.price.tiers}
          initialValues={{
            minEndpoints: this.state.price.metadata.minEndpoints,
            trial:
              this.state.price.recurring &&
              this.state.price.recurring.trial_period_days,
            interval: this.state.price.recurring.interval || "month",
            currency: 'usd',
            trialFee: this.state.price.metadata.trialFee/100
          }}
          color={this.props.color}
          onClose={this.onCloseModal}
        />
      );
    }

    if (!plan) {
      return false;
    }

    if (this.state.showNewPrice) {
      return (
        <AddNewPrice
          hideProgress
          initialValues={{
            interval: "month",
            currency: 'usd'
          }}
          onSubmit={this.onSubmit}
          onClose={this.hideAddPrice}
        />
      );
    }

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {(scrollRef) => (
          <>
            {this.state.showModalDelete && this.onRenderDeleteModal()}
            <div className="headline">
              <h1 className="headline__title">Plan Phases</h1>
              <h3 className="headline__subtitle">{plan.name}</h3>
            </div>

            {!hasFilter &&
            !(this.props.data && this.props.data.length) &&
            !this.props.isLoading ? (
              <NotFound
                color={color}
                strokeWidth="1"
                logo={<FilePlus size={42} />}
                title="Create your own price"
                description="Add dynamic prices for your plans"
                buttonText="Create first price"
                onAction={this.showAddPrice}
              />
            ) : (
              <>
                <ActionsView
                  activeView={this.state.activeView}
                  updateView={this.updateView}
                  colorClassName={color}
                  containerClassName="popup__actions-nav"
                  onActionClick={this.showAddPrice}
                ></ActionsView>
                <div className="popup__filters-bar filters-bar popup__filters-bar_custom_spacing2">
                  <ListSelectedItems
                    search={this.state.searchName}
                    onReset={this.onReset}
                    filterData={this.state.filter}
                    containerClassName={color}
                  />
                  <Filter
                    defaultFilter={[{
                      field: "type",
                      value: "All",
                      isDefault: true,
                      columns: ["trial"],
                    }]}
                    customClass={color}
                    resetFilter={resetFilter}
                    onChange={this.onFilter}
                    columns={filterColumns}
                    data={this.props.data}
                  />
                  <SearchField
                    onSearch={this.onSearch}
                    customClass={color}
                    resetSearch={this.state.resetFilter}
                    label="Search"
                  />
                </div>
                <InfiniteScroll
                  className="data-table"
                  loadMore={this.debouncedLoadMore}
                  hasMore={false}
                  initialLoad={false}
                  loader={
                    <div className="ml-3" key={0}>
                      Loading...
                    </div>
                  }
                  useWindow={false}
                  getScrollParent={() => this.scrollParent}
                >
                  {activeView === TABLE_VIEW && (
                    <List
                      color={color}
                      data={
                        hasFilter ? this.state.filterResult : this.props.data
                      }
                      selectedIds={selectedIds}
                      plan={plan}
                      getCurrencyIcon={this.props.getCurrencyIcon}
                      onSort={this.onSort}
                      setDefaultPrice={this.setDefaultPrice}
                      sort={this.state.sort}
                      deletePrice={this.onShowDeleteWarning}
                      onRowClick={this.onRowClick}
                      onSelect={this.onSelect}
                      isSelectedAll={this.selectAll}
                    />
                  )}
                  {activeView === CARD_VIEW && (
                    <div className="data-cards">
                      <Card
                        color={color}
                        setDefaultPrice={this.setDefaultPrice}
                        data={
                          hasFilter ? this.state.filterResult : this.props.data
                        }
                        onSelect={this.onSelect}
                        getCurrencyIcon={this.props.getCurrencyIcon}
                        plan={plan}
                        deletePrice={this.onShowDeleteWarning}
                        onRowClick={this.onRowClick}
                      />
                    </div>
                  )}
                </InfiniteScroll>
              </>
            )}
          </>
        )}
      </ProcessPage>
    );
  }
}

Subscription.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

Subscription.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

function mapStateToProps(state) {
  return {
    plan: state.plans.detail,
    isLoading: state.plans.isLoading,
    subscribedWorkspaces: state.plans.workspaces,
  };
}

const withConnect = connect(mapStateToProps, {
  listWorkspaceSubscriptions,
  createPriceAction,
  getPlanDetails,
  deletePriceAction,
  setDefaultPriceAction,
});

export default compose(withRouter, withConnect)(Subscription);
