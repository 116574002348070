import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardTags = ({ tags, color }) => (
  <div className={classnames('tags', `tags_${color}`)}>
    {
      tags && tags.map(tag => <div className="tags__item" key={tag}>{ tag }</div>)
    }
  </div>
);

CardTags.propTypes = {
  color: PropTypes.string,
  tags: PropTypes.array,
};

CardTags.defaultProps = {
  color: 'accent0'
};

export default CardTags;
