import {sortBy} from 'lodash'
import { listing } from "../constants/listing";

import {
  getListingDetails,
  getListings,
  approveSolution,
  rejectSolution,
  reviewSolution,
  getDashboards
} from "../api/listings";
import { defaultNotifier } from "functions/notificationHandler";

export function fetchListing(payload) {
  return async (dispatch) => {
    dispatch({ type: listing.GET_LISTING_PENDING });

    try {
      const { data } = await getListings(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      const result = sortBy(data.data.solutions, ['name']);
      
      dispatch({
        type: listing.GET_LISTING_FULFILLED,
        payload: result,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the listings");

      dispatch({
        type: listing.GET_LISTING_REJECTED,
        error: {
          message: "Error while fetching listings.",
        },
      });
    }
  };
}

export function fetchDashboards(payload) {
  return async (dispatch) => {
    dispatch({ type: listing.GET_DASHBOARD_PENDING });

    try {
      const { data } = await getDashboards(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      dispatch({
        type: listing.GET_DASHBOARD_FULFILLED,
        payload: data.data.dashboards,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the dashboards");

      dispatch({
        type: listing.GET_DASHBOARD_REJECTED,
        error: {
          message: "Error while fetching dashboards.",
        },
      });
    }
  };
}

export function getListDetails(payload) {
  return async (dispatch) => {
    dispatch({ type: listing.GET_LISTING_DETAILS_PENDING });

    try {
      const { data } = await getListingDetails(payload);
      if (data.errors) {
        defaultNotifier(data.errors);
      }

      if(data.data) {
        dispatch({
          type: listing.GET_LISTING_DETAILS_FULFILLED,
          payload: data.data.solution,
        });
      }
    } catch (err) {
      defaultNotifier("Failed to fetch the listing");

      dispatch({
        type: listing.GET_LISTING_DETAILS_REJECTED,
        error: {
          message: "Error while fetching the listing.",
        },
      });
    }
  };
}

export function reviewAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await reviewSolution(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Being reviewed.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to review the solution");
    }
  };
}

export function acceptAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await approveSolution(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Solution approved successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to approve the solution");
    }
  };
}

export function rejectAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await rejectSolution(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Solution rejected successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to reject the solution");
    }
  };
}
