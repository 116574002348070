import React from "react";
import PropTypes from "prop-types";
import CustomInput from "app/components/common/Input/CustomTextarea";
import Tags from "app/components/common/Tags";
import Button from "app/components/Button";
import { defaultNotifier } from "functions/notificationHandler";
import { FormattedMessage } from "react-intl";
import ProcessPage from "app/components/common/ProcessPage";
import classNames from "classnames";
import { DEFAULT_ACCENT } from "utils/constants";

import appMessages from "utils/message";
import CustomSelect from "app/components/common/Input/CustomSelect";
import { inviteWorkspaceSetup } from "app/api/users";
import Card from "app/components/common/Detail/Card";
import CardEmpty from "app/components/common/Detail/CardEmpty";
import Modal from "app/components/common/Modal";
import Plans from "./prices/list";
import { FilePlus } from "images/common";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {getPrice, snakeCaseToTitleCase} from 'utils/common';
import { NET_OPTIONS } from "app/constants/common";

const expirationOption = [
  {
    label: "Expires in 1 day",
    value: "1",
  },
  {
    label: "Expires in 7 days",
    value: "7",
  },
  {
    label: "Expires in 30 days",
    value: "30",
  },
  {
    label: "No expiration",
    value: "0",
  },
];
class InviteUser extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    inviteUserRequest: PropTypes.func.isRequired,
    history: PropTypes.object,
    inviteUsers: PropTypes.object,
    errors: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPlanList: false,
      selectedPlan: [],
      email: null,
      name: "",
      tags: [],
      offset: 0,
      sort: {
        key: "name",
        direction: "asc",
      },
      expiration: "1",
      modal: this.props.onShow,
      errorMessage: "",
      loggedInUser: this.props.loggedInUser,
      usersList: [],
      selectedGroups: [],
      selectedScopes: [],
      selectedRoleIds: [],
      creditCard: false,
      bank: false,
      paymentTerms: '30'
    };
  }

  getUserList = () => {
    this.props.fetchInvitedByUserRequest();
  };

  onSelect = (id) => {
    return this.setState({ selectedRoleIds: id });
  };

  componentDidMount() {}

  onRedirectToHome = () => {
    this.props.history.replace("/");
  };

  renderUsers = () => {
    const { color } = this.props;

    return (
      <div className={`text-field text-field_${color} mx-b_50`}>
        <CustomInput
          value={this.state.email}
          hideCharacterCounter
          parentClass={`text-field text-field_${color}`}
          label={<FormattedMessage {...appMessages.userEmail} />}
          onChange={(e) => this.onChangeField(e, "email")}
        />
        <CustomInput
          value={this.state.name}
          hideCharacterCounter
          parentClass={`text-field text-field_${color}`}
          label={<FormattedMessage {...appMessages.workspaceName} />}
          onChange={(e) => this.onChangeField(e, "name")}
        />
      </div>
    );
  };

  onChangeExpiration = (expiration) => {
    this.setState({ expiration });
  };

  onChangeField = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  removeUser = (id) => {
    let usersList = this.state.usersList.slice();

    usersList = usersList.filter((user) => user.id !== id);
    this.setState({
      usersList,
    });
  };

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  isValidEmail = (emailAddress) => {
    return !(
      emailAddress &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailAddress)
    );
  };

  isValidEmails = () => {
    if (!this.state.usersList.length) {
      return false;
    }

    return this.state.usersList.reduce((isValid, user) => {
      if (!this.isValidEmail(user) || !isValid) {
        return false;
      }

      return true;
    }, true);
  };

  onRedirectToHome = () => {
    this.props.history.replace("/");
  };

  onCancel = () => {
    this.props.closeModal();
  };

  onContinue = async () => {
      const payload = {
      email: this.state.email,
      tags: this.state.tags,
      expiration: parseInt(this.state.expiration, 10),
      name: this.state.name,
      creditCard: this.state.creditCard,
      bank: this.state.bank,
      planIds: this.state.selectedPlan,
      paymentTerms: parseInt(this.state.paymentTerms)
    };

    const { data } = await inviteWorkspaceSetup(payload);

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    this.props.getVisoUsers();
    defaultNotifier("Invitation sent successfully");
    this.props.onClose();
  };

  renderContent = () => {
    const { color, plans } = this.props;

    const planCollection = plans.filter((item) =>
      this.state.selectedPlan.includes(item.id)
    );

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {planCollection.map((plan) => (
              <tr className="data-table__row">
                <td className="data-table__td data-table__td_1">
                  <div className={`checkbox checkbox_${color}`}>
                    <div className="checkbox__image">
                      <FilePlus />
                    </div>
                  </div>
                </td>
                <td className={`data-table__td ${color}`}>{plan && plan.prettyName}</td>
                <td className={`data-table__td`}>{plan && snakeCaseToTitleCase(plan.category)}</td>
                <td className={`data-table__td`}>{getPrice(plan)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  renderModal = () => {
    const { plans } = this.props;

    const planCollection = plans
    .filter((item) => this.state.selectedPlan.includes(item.id))
    .map(item => ` ${item.prettyName}`);

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        disabled={this.state.isLoading}
        onClick={() => {
          this.onContinue();
        }}
      >
        <FormattedMessage {...appMessages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="confirm"
        disabled={this.state.isLoading}
        onClick={() => {
          this.setState({ showModal: false });
        }}
      >
        <FormattedMessage {...appMessages.cancel} />
      </button>,
    ];

    const creditCard = this.getPaymentMethod();

    return (
      <Modal
        classNames=""
        buttons={buttons}
        title={"Send Invitation"}
        subTitle="Confirm to send out the User Invitation to create a Viso Suite Workspace."
        body={
          <div className="modal__item">
            <div>
              <span className="text-color">Email:</span> {this.state.email}
            </div>
            <div>
              <span className="text-color">Workspace:</span> {this.state.name}
            </div>
            <div>
              <span className="text-color">Plans:</span> {planCollection.toString()}
            </div>
            <div>
              <span className="text-color">Payment:</span> {creditCard}
            </div>
          </div>
        }
        onClose={() => this.setState({ showModal: false })}
      />
    );
  };

  getPaymentMethod = () => {
    if (this.state.bank && this.state.creditCard) {
      return "Credit Card, Bank Transaction";
    }

    if (this.state.bank) {
      return "Bank Transaction";
    }

    if (this.state.creditCard) {
      return "Credit Card";
    }
  };

  onUpdatePayment = (term) => {
    this.setState({paymentTerms: term})
  }

  render() {
    const { color } = this.props;
    const { expiration, selectedPlan, creditCard, bank } = this.state;

    if (this.state.showPlanList) {
      return (
        <Plans
          selectedIds={this.state.selectedPlan}
          onSelectPlan={(id) =>
            this.setState({ selectedPlan: id, showPlanList: false })
          }
          onClose={() => this.setState({ showPlanList: false })}
        />
      );
    }

    return (
      <ProcessPage onCancel={this.props.onCancel}>
        {() => (
          <>
            {this.state.showModal && this.renderModal()}
            <div className="popup__cont">
              <div className="login">
                <div className="headline">
                  <h1 className="headline__title">Invite to Create Workspace</h1>
                  <h3 className="headline__subtitle">
                  Users will receive an email inviting them to create a workspace with the selected conditions.
                  </h3>
                </div>
                <div className="login__form">
                  {this.renderUsers()}
                  <div className={`text-field text-field_${color} `}>
                    <CustomSelect
                      color="accent4"
                      value={expiration}
                      onChange={this.onChangeExpiration}
                      label="Expiration"
                      options={expirationOption}
                    />
                  </div>
                  <div className={`text-field text-field_${color}`}>
                    <CustomSelect
                      value={this.state.paymentTerms}
                      label="Payment Terms"
                      onChange={this.onUpdatePayment}
                      options={NET_OPTIONS}
                    />
                  </div>
                  {!this.state.selectedPlan.length && (
                    <CardEmpty
                      noGrid
                      isLoading={this.props.isLoading}
                      color={this.props.color}
                      title='Workspace Plans'
                      message="Select active plans"
                      className="flow-modules custom-box"
                      onEdit={() => this.setState({ showPlanList: true })}
                    />
                  )}
                  {!!this.state.selectedPlan.length && (
                    <Card
                      noGrid
                      isLoading={this.props.isLoading}
                      color={this.props.color}
                      title={"Workspace Plans"}
                      className="empty-box custom-box"
                      onEdit={() => this.setState({ showPlanList: true })}
                      content={this.renderContent()}
                    />
                  )}
                  <div className="checkbox-wrapper">
                    <h5 className="label-color">Available Payment Methods</h5>
                    <div className="modal__checkbox pointerNone">
                      <input
                        type="checkbox"
                        name="replace"
                        checked={this.state.creditCard}
                        className="checkbox__input modal__checkbox__input"
                        // onClick={() =>
                        //   this.setState({ creditCard: !this.state.creditCard })
                        // }
                      />
                      <div className="checkbox__check" />
                      <label
                        className={classNames(
                          "checkbox__label checkbox modal__checkbox__label",
                          {
                            "label-color": !this.state.creditCard,
                            "text-color": this.state.creditCard,
                          }
                        )}
                        // onClick={() =>
                        //   this.setState({ creditCard: !this.state.creditCard })
                        // }
                      >
                        Credit Card
                      </label>
                    </div>
                    <div className="modal__checkbox">
                      <input
                        type="checkbox"
                        name="replace"
                        checked={this.state.bank}
                        className="checkbox__input modal__checkbox__input"
                        onClick={() =>
                          this.setState({ bank: !this.state.bank })
                        }
                      />
                      <div className="checkbox__check" />
                      <label
                        className={classNames(
                          "checkbox__label checkbox modal__checkbox__label",
                          {
                            "label-color": !this.state.bank,
                            "text-color": this.state.bank,
                          }
                        )}
                        onClick={() =>
                          this.setState({ bank: !this.state.bank })
                        }
                      >
                        Bank Transaction
                      </label>
                    </div>
                  </div>
                  <Tags
                    tags={this.state.tags}
                    onChange={this.onAddTags}
                    tagClass={color}
                  />

                  <div className="login__actions">
                    <Button
                      type="button"
                      disabled={!this.state.email 
                        || !this.state.name 
                        || !selectedPlan.length 
                        || (!bank && !creditCard)}
                      className={`button button_${color}`}
                      onClick={() => this.setState({ showModal: true })}
                    >
                      <FormattedMessage {...appMessages.continue} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

InviteUser.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    plans: state.plans.list,
  };
}

const withConnect = connect(mapStateToProps, null);

export default compose(withRouter, withConnect)(InviteUser);
