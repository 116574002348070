import { defineMessages } from 'react-intl'

export default defineMessages({
  save: {
    id: 'save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel'
  },
  back: {
    id: 'back',
    defaultMessage: 'Back'
  },
  type: {
    id: 'type',
    defaultMessage: 'Type'
  },
  version: {
    id: 'version',
    defaultMessage: 'Version'
  },
  settings: {
    id: 'settings',
    defaultMessage: 'Settings'
  },
  selected: {
    id: 'selected',
    defaultMessage: 'Selected'
  },
  search: {
    id: 'search',
    defaultMessage: 'Search'
  },
  createJoin: {
    id: 'createJoin',
    defaultMessage: 'Create or join'
  },
  logout: {
    id: 'logout',
    defaultMessage: 'Logout'
  },
  dashboard: {
    id: 'dashboard',
    defaultMessage: 'Dashboard'
  },
  created: {
    id: 'created',
    defaultMessage: 'Created'
  },
  library: {
    id: 'library',
    defaultMessage: 'Library'
  },
  overview: {
    id: 'overview',
    defaultMessage: 'Overview'
  },
  information: {
    id: 'information',
    defaultMessage: 'Information'
  },
  tags: {
    id: 'module.tags',
    defaultMessage: 'Tags'
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit'
  },
  lastUpdated: {
    id: 'lastUpdated',
    defaultMessage: 'Last updated'
  },
  description: {
    id: 'description',
    defaultMessage: 'Description'
  },
  continue: {
    id: 'continue',
    defaultMessage: 'Continue'
  },
  create: {
    id: 'create',
    defaultMessage: 'Create'
  },
  profiles: {
    id: 'profiles',
    defaultMessage: 'Profiles'
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile'
  },
  devices: {
    id: 'devices',
    defaultMessage: 'Devices'
  },
  applications: {
    id: 'applications',
    defaultMessage: 'Applications'
  },
  modules: {
    id: 'modules',
    defaultMessage: 'Modules'
  },
  notifications: {
    id: 'notifications',
    defaultMessage: 'Notifications'
  },
  marketplace: {
    id: 'marketplace',
    defaultMessage: 'Marketplace'
  },
  collapse: {
    id: 'collapse',
    defaultMessage: 'Collapse'
  },
  createdBy: {
    id: 'createBy',
    defaultMessage: 'Created by'
  },
  deployment: {
    id: 'deployment',
    defaultMessage: 'Deployment'
  },
  lastModified: {
    id: 'lastModified',
    defaultMessage: 'Last Modified'
  },
  modifiedBy: {
    id: 'modifiedBy',
    defaultMessage: 'Modified By'
  },
  user: {
    id: 'user',
    defaultMessage: 'User'
  },
  access: {
    id: 'access',
    defaultMessage: 'Access'
  },
  account: {
    id: 'Account',
    defaultMessage: 'Account'
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions'
  },
  roles: {
    id: 'roles',
    defaultMessage: 'Roles'
  },
  download: {
    id: 'download',
    defaultMessage: 'Download'
  },
  scopes: {
    id: 'scopes',
    defaultMessage: 'Scopes'
  },
  workspace: {
    id: 'workspace',
    defaultMessage: 'Workspace'
  },
  groups: {
    id: 'groups',
    defaultMessage: 'Groups'
  },
  licenses: {
    id: 'licenses',
    defaultMessage: 'Licenses'
  },
  personalProfile: {
    id: 'personalProfile',
    defaultMessage: 'Personal Profile'
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Confirm'
  },
  noGroupAssigned: {
    id: 'noGroupAssigned',
    defaultMessage: 'No Group assigned'
  },
  noScopeAssigned: {
    id: 'noScopeAssigned',
    defaultMessage: 'No Scope assigned'
  },
  workspaceLogo: {
    id: 'workspaceLogo',
    defaultMessage: 'Workspace Logo'
  },
  colorTheme: {
    id: 'colorTheme',
    defaultMessage: 'Color Theme'
  },
  secondaryColors: {
    id: 'secondaryColors',
    defaultMessage: 'Secondary Colors'
  },
  noData: {
    id: 'noData',
    defaultMessage: 'No Data.'
  },
  // WOrkspace
  manageWorkspaces: {
    id: 'manageWorkspaces',
    defaultMessage: 'Manage workspaces'
  },
  yourWorkspaces: {
    id: 'yourWorkspaces',
    defaultMessage: 'Your workspaces'
  },
  newWorkspace: {
    id: 'newWorkspace',
    defaultMessage: 'New workspaces'
  },
  manageWorkspace: {
    id: 'manageWorkspace',
    defaultMessage: 'Workspaces contain profiles and users. You can belong to multiple workspaces at the same time and switch between them whenever you like. Note that billing is per-workspace.'
  },
  remove: {
    id: 'remove',
    defaultMessage: 'Remove'
  },
  otherWorkspaces: {
    id: 'otherWorkspaces',
    defaultMessage: 'Other workspaces'
  },
  otherWorkspaceDesc: {
    id: 'otherWorkspaceDesc',
    defaultMessage: 'There are other workspaces you are eligible to join due to your email address.'
  },
  addWorkspace: {
    id: 'addWorkspace',
    defaultMessage: 'Add New Workspace'
  },
  selectWorkspace: {
    id: 'selectWorkspace',
    defaultMessage: 'Select your workspace.'
  },
  createOrJoinWorkspace: {
    id: 'createOrJoinWorkspace',
    defaultMessage: 'Create or join a workspace'
  },
  addedBy: {
    id: 'addedBy',
    defaultMessage: 'Added by'
  },
  deviceType: {
    id: 'deviceType',
    defaultMessage: 'Device Type'
  },
  osVersion: {
    id: 'osVersion',
    defaultMessage: 'OS Version'
  },
  ipAddress: {
    id: 'ipAddress',
    defaultMessage: 'IP Address'
  },
  addedAt: {
    id: 'addedAt',
    defaultMessage: 'Added at'
  },
  device: {
    id: 'device',
    defaultMessage: 'Device'
  },
  close: {
    id: 'close',
    defaultMessage: 'Close'
  },
  deviceDetails: {
    id: 'deviceDetails',
    defaultMessage: 'Device Details'
  },
  userName: {
    id: 'userName',
    defaultMessage: 'User Name'
  },
  userEmail: {
    id: 'userEmail',
    defaultMessage: 'User Email'
  },
  userRole: {
    id: 'userRole',
    defaultMessage: 'User Role'
  },
  status: {
    id: 'status',
    defaultMessage: 'Status'
  },
  visoRole: {
    id: 'visoRole',
    defaultMessage: 'Viso Role'
  },
  modified: {
    id: 'modified',
    defaultMessage: 'Modified'
  },
  lastSignIn: {
    id: 'lastSignIn',
    defaultMessage: 'Last sign-in'
  },
  date: {
    id: 'date',
    defaultMessage: 'Date'
  },
  invoiceNo: {
    id: 'invoiceNo',
    defaultMessage: 'Invoice number'
  },
  billingPeriod: {
    id: 'billingPeriod',
    defaultMessage: 'Billing Period'
  },
  amount: {
    id: 'amount',
    defaultMessage: 'Amount'
  },
  earningsStatement: {
    id: 'earningsStatement',
    defaultMessage: 'Earnings Statement'
  },
  earningPeriod: {
    id: 'earningPeriod',
    defaultMessage: 'Earning Period'
  },
  createListing: {
    id: 'createListing',
    defaultMessage: 'Create new listing'
  },
  solutionName: {
    id: 'solutionName',
    defaultMessage: 'Solution Name'
  },
  solutionId: {
    id: 'solutionId',
    defaultMessage: 'Solution ID'
  },
  moduleName: {
    id: 'moduleName',
    defaultMessage: 'Module Name'
  },
  category: {
    id: 'category',
    defaultMessage: 'Category'
  },
  categoryName: {
    id: 'categoryName',
    defaultMessage: 'Category Name'
  },
  categorySlug: {
    id: 'categorySlug',
    defaultMessage: 'Category Slug'
  },
  categoryDescription: {
    id: 'categoryDescription',
    defaultMessage: 'Category Description'
  },
  assigned: {
    id: 'assigned',
    defaultMessage: 'Assigned'
  },
  permissions: {
    id: 'permissions',
    defaultMessage: 'Permissions'
  },
  noAccess: {
    id: 'noAccess',
    defaultMessage: 'No Access'
  },
  limitedAccess: {
    id: 'limitedAccess',
    defaultMessage: 'Limited Access'
  },
  fullAccess: {
    id: 'fullAccess',
    defaultMessage: 'Full Access'
  },
  discardChanges: {
    id: 'discardChanges',
    defaultMessage: 'Discard Changes'
  },
  cancelMessage: {
    id: 'cancelMessage',
    defaultMessage: 'Confirm to proceed without saving the changes.'
  },
  license: {
    id: 'license',
    defaultMessage: 'License'
  },
  provider: {
    id: 'provider',
    defaultMessage: 'Provider'
  },
  repository: {
    id: 'repository',
    defaultMessage: 'Repository'
  },
  visibility: {
    id: 'visibility',
    defaultMessage: 'Visibility'
  },
  encryptionKey: {
    id: 'encryptionKey',
    defaultMessage: 'Encryption key'
  },
  createApplication: {
    id: 'createApplication',
    defaultMessage: 'Create Application'
  },
  editApplication: {
    id: 'editApplication',
    defaultMessage: 'Application Information'
  },
  cloneAnApplication: {
    id: 'cloneApplication',
    defaultMessage: 'Clone an Application'
  },
  cloneApplication: {
    id: 'cloneApplication',
    defaultMessage: 'Clone Application'
  },
  passphrase: {
    id: 'passphrase',
    defaultMessage: 'Passphrase'
  },
  password: {
    id: 'password',
    defaultMessage: 'Password'
  },
  username: {
    id: 'username',
    defaultMessage: 'Username'
  },
  sshKey: {
    id: 'sshKey',
    defaultMessage: 'SSH Key'
  },
  gitRepoUrl: {
    id: 'gitRepoUrl',
    defaultMessage: 'Git repository URL'
  },
  yourChanges: {
    id: 'yourChanges',
    defaultMessage: 'Your Changes'
  },
  commitChanges: {
    id: 'commitChanges',
    defaultMessage: 'Commit Changes'
  },
  saveChanges: {
    id: 'saveChanges',
    defaultMessage: 'Save Changes'
  },
  comment: {
    id: 'comment',
    defaultMessage: 'Comment'
  },
  commitedBy: {
    id: 'commitedBy',
    defaultMessage: 'Commited by'
  },
  versionTag: {
    id: 'versionTag',
    defaultMessage: 'Version Tag'
  },
  branch: {
    id: 'branch',
    defaultMessage: 'Branch'
  },
  refresh: {
    id: 'refresh',
    defaultMessage: 'Refresh'
  },
  commit: {
    id: 'commit',
    defaultMessage: 'Commit'
  },
  action: {
    id: 'action',
    defaultMessage: 'Action'
  },
  assignedApplication: {
    id: 'assignedApplication',
    defaultMessage: 'Assigned Application'
  },
  updated: {
    id: 'updated',
    defaultMessage: 'Updated'
  },
  size: {
    id: 'size',
    defaultMessage: 'Size'
  },
  encryption: {
    id: 'encryption',
    defaultMessage: 'Encryption'
  },
  encryptionLabel: {
    id: 'encryption',
    defaultMessage: 'Encryption'
  },
  addRemote: {
    id: 'addRemote',
    defaultMessage: 'Add remote'
  },
  remoteName: {
    id: 'remoteName',
    defaultMessage: 'Remote name'
  },
  url: {
    id: 'url',
    defaultMessage: 'URL'
  },
  addGitRemote: {
    id: 'addGitRemote',
    defaultMessage: 'Add Git remote'
  },
  currentKey: {
    id: 'currentKey',
    defaultMessage: 'Current key'
  },
  newKey: {
    id: 'newKey',
    defaultMessage: 'New key'
  },
  readMe: {
    id: 'readMe',
    defaultMessage: 'Readme'
  },
  name: {
    id: 'name',
    defaultMessage: 'Name'
  },
  commitDescription: {
    id: 'commitDescription',
    defaultMessage: 'Commit Description'
  },
  applicationDescription: {
    id: 'applicationDescription',
    defaultMessage: 'Application Description'
  },
  addAModule: {
    id: 'addAModule',
    defaultMessage: 'Add a module'
  },
  importModule: {
    id: 'importModule',
    defaultMessage: 'Import Module'
  },
  addModuleDescription: {
    id: 'addModuleDescription',
    defaultMessage: 'To begin, you module needs a name and a short description'
  },
  moduleDescription: {
    id: 'moduleDescription',
    defaultMessage: 'Module Description'
  },
  moduleId: {
    id: 'moduleId',
    defaultMessage: 'Module ID'
  },
  cloneModule: {
    id: 'cloneModule',
    defaultMessage: 'Clone Module'
  },
  importAModule: {
    id: 'importAModule',
    defaultMessage: 'Import a module'
  },
  applicationName: {
    id: 'applicationName',
    defaultMessage: 'Application Name'
  },
  usernameOptional: {
    id: 'usernameOptional',
    defaultMessage: 'Username (optional)'
  },
  passwordOptional: {
    id: 'passwordOptional',
    defaultMessage: 'Password (optional)'
  },
  dockerImage: {
    id: 'dockerImage',
    defaultMessage: 'Docker Image'
  },
  noApplicationAssigned: {
    id: 'noApplicationAssigned',
    defaultMessage: 'No application assigned'
  },
  moduleNodes: {
    id: 'moduleNodes',
    defaultMessage: 'Module Nodes'
  },
  myViso: {
    id: 'myViso',
    defaultMessage: 'My Viso'
  },
  manage: {
    id: 'manage',
    defaultMessage: 'Manage'
  },
  partner: {
    id: 'partner',
    defaultMessage: 'Partner'
  },
  addFilter: {
    id: 'addFilter',
    defaultMessage: 'Add a filter'
  },
  all: {
    id: 'all',
    defaultMessage: 'All'
  },
  workspacesName: {
    id: 'workspacesName',
    defaultMessage: 'Workspace'
  },
  workspaces: {
    id: 'workspaces',
    defaultMessage: 'Workspaces'
  },
  workspaceId: {
    id: 'workspaceId',
    defaultMessage: 'Workspace-ID'
  },
  owner: {
    id: 'owner',
    defaultMessage: 'Owner'
  },
  visoAgentVersion: {
    id: 'visoAgentVersion',
    defaultMessage: 'Viso Agent Version'
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Last Name'
  },
  organizationName: {
    id: 'organizationName',
    defaultMessage: 'Organization Name'
  },
  organizationAddress: {
    id: 'organizationAddress',
    defaultMessage: 'Organization Address'
  },
  workspaceID: {
    id: 'workspaceID',
    defaultMessage: 'Workspace ID'
  },
  creationDate: {
    id: 'creationDate',
    defaultMessage: 'Creation Date'
  },
  organization: {
    id: 'organization',
    defaultMessage: 'Organization'
  },
  subscriptionName: {
    id: 'subscriptionName',
    defaultMessage: 'Subscription Name'
  },
  costEndpoint: {
    id: 'costEndpoint',
    defaultMessage: 'Cost/Endpoint'
  },
  totalCost: {
    id: 'totalCost',
    defaultMessage: 'Total Cost'
  },
  mode: {
    id: 'mode',
    defaultMessage: 'Mode'
  },
  subscribed: {
    id: 'subscribed',
    defaultMessage: 'Subscribed'
  },
  endpoints: {
    id: 'endpoints',
    defaultMessage: 'Endpoints'
  },
  invoiceNumber: {
    id: 'invoiceNumber',
    defaultMessage: 'Invoice number'
  },
  plans: {
    id: 'plans',
    defaultMessage: 'Plans'
  },
  planName: {
    id: 'Plan Name',
    defaultMessage: 'Plan Name'
  },
  productId: {
    id: 'Product Id',
    defaultMessage: 'Product ID'
  },
  priceId: {
    id: 'Price Id',
    defaultMessage: 'Price ID'
  },
  startsAt: {
    id: 'Starts At',
    defaultMessage: 'Starts At'
  },
  planDescription: {
    id: 'planDescription',
    defaultMessage: 'Plan Description'
  },
  interval: {
    id: 'interval',
    defaultMessage: 'Interval'
  },
  trialPeriod: {
    id: 'trialPeriod',
    defaultMessage: 'Trial period'
  },
  currency: {
    id: 'currency',
    defaultMessage: 'Currency'
  },
  minimum: {
    id: 'minimum',
    defaultMessage: 'Minimum'
  },
  planID: {
    id: 'planID',
    defaultMessage: 'Plan ID'
  },
  activeSubscriptions: {
    id: 'activeSubscriptions',
    defaultMessage: 'Active Subscriptions'
  },
  trialPeriodDays: {
    id: 'trialPeriodDays',
    defaultMessage: 'Trial period days'
  },
  billingEndpoints: {
    id: 'billingEndpoints',
    defaultMessage: 'Billing Endpoints'
  },
  mrr: {
    id: 'mrr',
    defaultMessage: 'MRR'
  },
  priceSettings: {
    id: 'priceSettings',
    defaultMessage: 'Pricing Settings'
  },
  metrics: {
    id: 'metrics',
    defaultMessage: 'Metrics'
  },
  customer: {
    id: 'customer',
    defaultMessage: 'Customer'
  },
  subscriptionStatus: {
    id: 'subscriptionStatus',
    defaultMessage: 'Subscription Status'
  },
  billableEndpoints: {
    id: 'billableEndpoints',
    defaultMessage: 'Billable Endpoints'
  },
  perEndpoints: {
    id: 'perEndpoints',
    defaultMessage: 'Per Endpoint'
  },
  subscribedAt: {
    id: 'subscribedAt',
    defaultMessage: 'Subscribed at'
  },
  nextInvoice: {
    id: 'nextInvoice',
    defaultMessage: 'Next Invoice'
  },
  nextDueDate: {
    id: 'nextDueDate',
    defaultMessage: 'Next Due Date'
  },
  export: {
    id: 'export',
    defaultMessage: 'Export'
  },
  workspaceName: {
    id: 'workspaceName',
    defaultMessage: 'Workspace Name'
  },
  companyName: {
    id: 'companyName',
    defaultMessage: 'Company Name'
  },
  companySize: {
    id: 'companySize',
    defaultMessage: 'Company Size'
  },
  subscriptions: {
    id: 'subscriptions',
    defaultMessage: 'Subscriptions'
  },
  companyIndustry: {
    id: 'companyIndustry',
    defaultMessage: 'Company Industry'
  },
  apply: {
    id: 'apply',
    defaultMessage: 'Apply'
  },
  faq: {
    id: 'faq',
    defaultMessage: 'FAQ'
  },
  lastMonth: {
    id: 'lastMonth',
    defaultMessage: 'Last month'
  },
  currentBalance: {
    id: 'currentBalance',
    defaultMessage: 'Current balance'
  },
  paymentsAndReports: {
    id: 'paymentsAndReports',
    defaultMessage: 'Payments and reports'
  },
  vendorInformation: {
    id: 'vendorInformation',
    defaultMessage: 'Vendor Information'
  },
  byClickingSave: {
    id: 'byClickingSave',
    defaultMessage: 'By clicking Save you accept the'
  },
  customerAgreement: {
    id: 'customerAgreement',
    defaultMessage: 'Customer Agreement'
  },
  andAgreeToPayFees: {
    id: 'andAgreeToPayFees',
    defaultMessage: 'and agree to pay fees charged in accordance with our'
  },
  pricing: {
    id: 'pricing',
    defaultMessage: 'pricing'
  },
  complete: {
    id: 'complete',
    defaultMessage: 'Complete'
  },
  documentationAndSupport: {
    id: 'documentationAndSupport',
    defaultMessage: 'Documentation'
  },
  manageResourcesAndContactInformation: {
    id: 'manageResourcesAndContactInformation',
    defaultMessage: 'Information displayed to the user in the Viso Hub.'
  },
  informationDisplayedToTheUser: {
    id: 'informationDisplayedToTheUser',
    defaultMessage: 'Information displayed to the user in the'
  },
  screenshotGallery: {
    id: 'screenshotGallery',
    defaultMessage: 'Screenshot Gallery'
  },
  tagLine: {
    id: 'tagLine',
    defaultMessage: 'TagLine'
  },
  listingId: {
    id: 'listingId',
    defaultMessage: 'Listing ID'
  },
  startingAt: {
    id: 'startingAt',
    defaultMessage: 'Starting at'
  },
  chooseYour: {
    id: 'chooseYour',
    defaultMessage: 'Choose your'
  },
  edition: {
    id: 'edition',
    defaultMessage: 'Edition'
  },
  selectThePlan: {
    id: 'selectThePlan',
    defaultMessage: 'Select the plan for your'
  },
  payBasedOnUsage: {
    id: 'payBasedOnUsage',
    defaultMessage: 'Pay based on usage.'
  },
  reviewYourOrder: {
    id: 'reviewYourOrder',
    defaultMessage: 'Review your order'
  },
  tryCancelAtAnyTime: {
    id: 'tryCancelAtAnyTime',
    defaultMessage: 'for free for 30 days. cancel at any time'
  },
  try: {
    id: 'try',
    defaultMessage: 'Try'
  },
  paymentInfo: {
    id: 'paymentInfo',
    defaultMessage: 'Payment Info'
  },
  yourCreditCardChargedMonthly: {
    id: 'yourCreditCardChargedMonthly',
    defaultMessage: 'Your credit card will be charged monthly and your fees may vary based on how we bill.'
  },
  addNewRole: {
    id: 'addNewRole',
    defaultMessage: 'Create new role'
  },
  createRole: {
    id: 'createRole',
    defaultMessage: 'Create Role'
  },
  updateRole: {
    id: 'updateRole',
    defaultMessage: 'Update Role'
  },
  roleName: {
    id: 'roleName',
    defaultMessage: 'User Role'
  },
  userRoleName: {
    id: 'userRoleName',
    defaultMessage: 'Role name'
  },
  roleId: {
    id: 'roleId',
    defaultMessage: 'Role ID'
  },
  userRoles: {
    id: 'userRoles',
    defaultMessage: 'User Roles'
  },
  modalDeviceInfo: {
    id: 'modalDeviceInfo',
    defaultMessage: 'Basic Information about the Edge Device such as name, type and custom fields.'
  },
  modalSolutionsInfo: {
    id: 'modalSolutionsInfo',
    defaultMessage: 'Basic Information about the Edge Device such as name, type and custom fields.'
  },
  modalReportsInfo: {
    id: 'modalReportsInfo',
    defaultMessage: 'Basic Information about the Edge Device such as name, type and custom fields.'
  },
  modalUsersAndRolesInfo: {
    id: 'modalUsersAndRolesInfo',
    defaultMessage: 'Basic Information about the Edge Device such as name, type and custom fields.'
  },
  drs: {
    id: 'drsInfo',
    defaultMessage: 'DRS'
  },
  drsInfo: {
    id: 'drs',
    defaultMessage: 'Basic Information about the DRS.'
  },
  manageAccessDRS: {
    id: 'manageAccessDRS',
    defaultMessage: 'Manage access to DRS'
  },
  other: {
    id: 'drsInfo',
    defaultMessage: 'Other'
  },
  otherInfo: {
    id: 'drs',
    defaultMessage: 'Other Permissions'
  },
  manageAccessOther: {
    id: 'manageAccessDRS',
    defaultMessage: 'Manage Other'
  },
  manageAccessDevices: {
    id: 'manageAccessDevices',
    defaultMessage: 'Manage access to Devices'
  },
  manageAccessSolutions: {
    id: 'manageAccessSolutions',
    defaultMessage: 'Manage access to Solutions'
  },
  manageAccessReports: {
    id: 'manageAccessReports',
    defaultMessage: 'Manage access to Reports'
  },
  manageAccessUserAndRoles: {
    id: 'manageAccessUserAndRoles',
    defaultMessage: 'Manage access to User and Roles'
  },
  createNewUserRole: {
    id: 'createNewUserRole',
    defaultMessage: 'Role Information'
  },
  roleDescription: {
    id: 'roleDescription',
    defaultMessage: 'Role description'
  },
  releases: {
    id: 'releases',
    defaultMessage: 'Releases'
  },
  userGroups: {
    id: 'userGroups',
    defaultMessage: 'User Groups'
  },
  setUpAdvancedPermissions: {
    id: 'setUpAdvancedPermissions',
    defaultMessage: 'Set up advanced permissions'
  },
  setUpBasicPermissions: {
    id: 'setUpBasicPermissions',
    defaultMessage: 'Set up basic permissions'
  },
  updateBasicPermissions: {
    id: 'updateBasicPermissions',
    defaultMessage: 'Update the permissions'
  },
  manageAccessToDevices: {
    id: 'manageAccessToDevices',
    defaultMessage: 'Manage access to devices'
  },
  setupCustomGroup: {
    id: 'setupCustomGroup',
    defaultMessage: 'Set up a custom group.'
  },
  enableDisableIndividualPermissions: {
    id: 'enableDisableIndividualPermissions',
    defaultMessage: 'Enable and disable portal modules and individual permissions.'
  },
  settingsManageUsersAccess: {
    id: 'settingsManageUsersAccess',
    defaultMessage: 'Settings to manage users access to resources.'
  },
  finish: {
    id: 'finish',
    defaultMessage: 'Finish'
  },
  advancedPermissions: {
    id: 'advancedPermissions',
    defaultMessage: 'Advanced Permissions'
  },
  groupUsers: {
    id: 'groupUsers',
    defaultMessage: 'Group Users'
  },
  managePermissions: {
    id: 'managePermissions',
    defaultMessage: 'Manage Permissions'
  },
  userInformation: {
    id: 'userInformation',
    defaultMessage: 'Role Information'
  },
  lastEdited: {
    id: 'lastEdited',
    defaultMessage: 'Last edited'
  },
  userAssigned: {
    id: 'userAssigned',
    defaultMessage: 'Users'
  },
  userRolesTitle: {
    id: 'userRolesTitle',
    defaultMessage: 'User Roles'
  },
  usersOf: {
    id: 'usersOf',
    defaultMessage: 'Users of'
  },
  specialRolePermissions: {
    id: 'specialRolePermissions',
    defaultMessage: 'Special Role Permissions'
  },
  assignUserRole: {
    id: 'assignUserRole',
    defaultMessage: 'Assign User Role'
  },
  areYourSureToAssignTheRole: {
    id: 'areYourSureToAssignTheRole',
    defaultMessage: 'Are you sure to assign the role {name} to the {count} selected user(s)?'
  },
  assignUsersToRole: {
    id: 'assignUsersToRole',
    defaultMessage: 'Assign Users to Role'
  },
  manageUsersThatHaveTheCurrentRole: {
    id: 'manageUsersThatHaveTheCurrentRole',
    defaultMessage: 'Manage users that have the current role assigned'
  },
  theRoleWillBeDeleted: {
    id: 'theRoleWillBeDeleted',
    defaultMessage: 'The role will be deleted'
  },
  info: {
    id: 'info',
    defaultMessage: 'Info'
  },
  lockedBecauseItsUse: {
    id: 'lockedBecauseItsUse',
    defaultMessage: 'Locked because it is in use'
  },
  provideBasicInfo: {
    id: 'provideBasicInfo',
    defaultMessage: 'Provide some basic information'
  },
  nameAlreadyInUse: {
    id: 'nameAlreadyInUse',
    defaultMessage: 'Name is already in use'
  },
  confirmDeleteRole: {
    id: 'confirmDeleteRole',
    defaultMessage: 'Confirm delete role'
  },
  assignRoleToUser: {
    id: 'assignRoleToUser',
    defaultMessage: 'Assign role to user'
  },
  assignRoleToUsers: {
    id: 'assignRoleToUsers',
    defaultMessage: 'Assign Role to Users'
  },
  removeRole: {
    id: 'removeRole',
    defaultMessage: 'Remove Role'
  },
  personalSettings: {
    id: 'personalSettings',
    defaultMessage: 'Personal Settings'
  },
  userProfile: {
    id: 'userProfile',
    defaultMessage: 'User Profile'
  },
  workspaceSettings: {
    id: 'workspaceSettings',
    defaultMessage: 'Workspace settings'
  },
  addNewUser: {
    id: 'addNewUser',
    defaultMessage: 'Create new user'
  },
  updateUser: {
    id: 'updateUser',
    defaultMessage: 'Update User'
  },
  userId: {
    id: 'userId',
    defaultMessage: 'User ID'
  },
  defaultLanguage: {
    id: 'defaultLanguage',
    defaultMessage: 'Default Language'
  },
  userLanguage: {
    id: 'userLanguage',
    defaultMessage: 'User Language'
  },
  inviteNewUsersWorkspace: {
    id: 'inviteNewUsersWorkspace',
    defaultMessage: 'Invite users to Workspace'
  },
  addNewUsersBySendingInvitations: {
    id: 'addNewUsersBySendingInvitations',
    defaultMessage: 'Users will receive an email inviting them to the workspace'
  },
  userEmails: {
    id: 'emails',
    defaultMessage: 'User Emails'
  },
  role: {
    id: 'role',
    defaultMessage: 'Role'
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Last Name'
  },
  internalId: {
    id: 'internalId',
    defaultMessage: 'Internal ID'
  },
  changePassword: {
    id: 'changePassword',
    defaultMessage: 'Change password'
  },
  setPassword: {
    id: 'setPassword',
    defaultMessage: 'Set password'
  },
  userStatus: {
    id: 'userStatus',
    defaultMessage: 'User Status'
  },
  userGroup: {
    id: 'userGroup',
    defaultMessage: 'User Group'
  },
  addAnother: {
    id: 'addAnother',
    defaultMessage: 'Add another'
  },
  assignToUserGroup: {
    id: 'assignToUserGroup',
    defaultMessage: 'Assign to user groups'
  },
  assignRole: {
    id: 'assignRole',
    defaultMessage: 'Assign Role'
  },
  assignScope: {
    id: 'assignScope',
    defaultMessage: 'Assign Scope'
  },
  inviteUsers: {
    id: 'inviteUsers',
    defaultMessage: 'Invite users'
  },
  changeYourPassword: {
    id: 'changeYourPassword',
    defaultMessage: 'Change your password'
  },
  setYourPassword: {
    id: 'setYourPassword',
    defaultMessage: 'Set your password'
  },
  pleaseSetNewPassword: {
    id: 'pleaseSetNewPassword',
    defaultMessage: 'Please set a new password.'
  },
  currentPassword: {
    id: 'currentPassword',
    defaultMessage: 'Current password'
  },
  newPassword: {
    id: 'newPassword',
    defaultMessage: 'New password'
  },
  confirmPassword: {
    id: 'confirmPassword',
    defaultMessage: 'Confirm password'
  },
  invitedBy: {
    id: 'invitedBy',
    defaultMessage: 'Invited by'
  },
  showReportEntriesOf: {
    id: 'showReportEntriesOf',
    defaultMessage: 'Show Report Entries of'
  },
  userGroupId: {
    id: 'userGroupId',
    defaultMessage: 'User Group ID'
  },
  letsSetUpYourProfile: {
    id: 'letsSetUpYourProfile',
    defaultMessage: 'Let’s set up your profile.'
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Phone Number'
  },
  userGroupDescription: {
    id: 'userGroup',
    defaultMessage: 'Manage the groups to which the current user is assigned to.'
  },
  lastLogin: {
    id: 'lastLogin',
    defaultMessage: 'Last Login'
  },
  lastActivity: {
    id: 'lastActivity',
    defaultMessage: 'Last activity'
  },
  roleDescriptionPermission: {
    id: 'roleDescriptionPermission',
    defaultMessage: 'Role of the current user to manage permissions.'
  },
  userScope: {
    id: 'userScope',
    defaultMessage: 'User Scope'
  },
  userScopeDescription: {
    id: 'userScopeDescription',
    defaultMessage: 'Scope of the current user to manage access to resources.'
  },
  deleteUser: {
    id: 'deleteUser',
    defaultMessage: 'Delete selected user(s)'
  },
  getStarted: {
    id: 'getStarted',
    defaultMessage: 'Get Started'
  },
  userSettings: {
    id: 'userSettings',
    defaultMessage: 'User Settings'
  },
  noRoleAssigned: {
    id: 'noRoleAssigned',
    defaultMessage: 'No Role assigned'
  },
  joinedAt: {
    id: 'joinedAt',
    defaultMessage: 'Joined At'
  },
  sentOut: {
    id: 'sentOut',
    defaultMessage: 'Sent out'
  },
  expiresAt: {
    id: 'expiresAt',
    defaultMessage: 'Expires at'
  },
  resendInvitations: {
    id: 'resendInvitations',
    defaultMessage: 'Resend Invitations'
  },
  resendInvitation: {
    id: 'resendInvitation',
    defaultMessage: 'Resend Invitation'
  },
  cancelInvitations: {
    id: 'cancelInvitations',
    defaultMessage: 'Cancel Invitations'
  },
  cancelInvitation: {
    id: 'cancelInvitation',
    defaultMessage: 'Cancel Invitation'
  },
  userInvitations: {
    id: 'userInvitations',
    defaultMessage: 'User Invitations'
  },
  invitations: {
    id: 'invitations',
    defaultMessage: 'Invitations'
  },
  viewUser: {
    id: 'viewUser',
    defaultMessage: 'View User'
  },
  configureNewUsers: {
    id: 'configureNewUsers',
    defaultMessage: 'Configure new users'
  },
  addNewUsersToYour: {
    id: 'addNewUsersToYour',
    defaultMessage: 'Add new users to your'
  },
  assignAUserRoleToUsers: {
    id: 'assignAUserRoleToUsers',
    defaultMessage: 'Assign a User Role to the new users to manage their permissions.'
  },
  inviteNow: {
    id: 'inviteNow',
    defaultMessage: 'Invite Now'
  },
  assignToNewUsers: {
    id: 'assignToNewUsers',
    defaultMessage: 'Assign to new users'
  },
  confirmActivateUsers: {
    id: 'confirmActivateUsers',
    defaultMessage: 'Confirm Activate Users'
  },
  confirmActivateUser: {
    id: 'confirmActivateUser',
    defaultMessage: 'Confirm Activate User'
  },
  confirmDeactivateUser: {
    id: 'confirmDeactivateUser',
    defaultMessage: 'Confirm Deactivate User'
  },
  confirmDeactivateUsers: {
    id: 'confirmDeactivateUsers',
    defaultMessage: 'Confirm Deactivate Users'
  },
  confirmDeleteTemplate: {
    id: 'confirmDeleteTemplate',
    defaultMessage: 'Confirm Delete Template'
  },
  confirmDeleteTemplateDesc: {
    id: 'confirmDeleteTemplateDesc',
    defaultMessage: 'Are you sure you want to delete the template?'
  },
  deactivate: {
    id: 'deactivate',
    defaultMessage: 'Deactivate'
  },
  confirmArchiveUsers: {
    id: 'confirmArchiveUsers',
    defaultMessage: 'Confirm Archive Users'
  },
  confirmArchiveUser: {
    id: 'confirmArchiveUser',
    defaultMessage: 'Confirm Archive User'
  },
  inviteNewUsers: {
    id: 'inviteNewUsers',
    defaultMessage: 'Invite new users'
  },
  invitationList: {
    id: 'invitationList',
    defaultMessage: 'Invitation List'
  },
  activateUsers: {
    id: 'activateUsers',
    defaultMessage: 'Activate users'
  },
  activateUser: {
    id: 'activateUser',
    defaultMessage: 'Activate user'
  },
  deactivateUsers: {
    id: 'deactivateUsers',
    defaultMessage: 'Deactivate users'
  },
  deactivateUser: {
    id: 'deactivateUser',
    defaultMessage: 'Deactivate user'
  },
  myProfile: {
    id: 'myProfile',
    defaultMessage: 'My Profile'
  },
  manageYourInformationAndSettings: {
    id: 'manageYourInformationAndSettings',
    defaultMessage: 'Manage your information and personal settings'
  },
  myWorkspaces: {
    id: 'myWorkspaces',
    defaultMessage: 'My Workspaces'
  },
  manageYourWorkspacesOrCreate: {
    id: 'manageYourWorkspacesOrCreate',
    defaultMessage: 'Manage your workspaces or create a new one'
  },
  createAndManageTheUsers: {
    id: 'createAndManageTheUsers',
    defaultMessage: 'Create and manage the users and user profiles'
  },
  rolesAndAccess: {
    id: 'rolesAndAccess',
    defaultMessage: 'Roles & Access'
  },
  manageUserRoles: {
    id: 'manageUserRoles',
    defaultMessage: 'Manage user roles and the related permissions'
  },
  manageWorkspaceInformation: {
    id: 'manageWorkspaceInformation',
    defaultMessage: 'Manage workspace information and settings'
  },
  billingAndSubscriptions: {
    id: 'billingAndSubscriptions',
    defaultMessage: 'Billing & Subscriptions'
  },
  manageYourPaymentInformation: {
    id: 'manageYourPaymentInformation',
    defaultMessage: 'Manage your payment information'
  },
  manageYourMarketplaceListings: {
    id: 'manageYourMarketplaceListings',
    defaultMessage: 'Manage your Marketplace listings and earnings'
  },
  archiveWorkspace: {
    id: 'archiveWorkspace',
    defaultMessage: 'Archive Workspace'
  },
  deleteWorkspace: {
    id: 'deleteWorkspace',
    defaultMessage: 'Delete Workspace'
  },
  editWorkspace: {
    id: 'editWorkspace',
    defaultMessage: 'Manage Workspace'
  },
  english: {
    id: 'english',
    defaultMessage: 'English'
  },
  heading: {
    id: 'workspace',
    defaultMessage: 'Workspace'
  },
  organizationName: {
    id: 'organizationName',
    defaultMessage: 'Organization Name'
  },
  organizationAddress: {
    id: 'organizationAddress',
    defaultMessage: 'Organization Address'
  },
  defaultUserRole: {
    id: 'defaultUserRole',
    defaultMessage: 'Default User Role'
  },
  administrator: {
    id: 'administrator',
    defaultMessage: 'Administrator'
  },
  customer: {
    id: 'customer',
    defaultMessage: 'Customer'
  },
  workspaceDomain: {
    id: 'workspaceDomain',
    defaultMessage: 'Workspace Domain'
  },
  workspaceOwner: {
    id: 'workspaceOwner',
    defaultMessage: 'Workspace Owner'
  },
  appearance: {
    id: 'appearance',
    defaultMessage: 'Appearance'
  },
  baseColor: {
    id: 'baseColor',
    defaultMessage: 'Base Color'
  },
  page: {
    id: 'page',
    defaultMessage: 'Page'
  },
  sidebar: {
    id: 'sidebar',
    defaultMessage: 'Sidebar'
  },
  customizeWorkspace: {
    id: 'customizeWorkspace',
    defaultMessage: 'Customize Workspace'
  },
  ownerOfTheWorkspace: {
    id: 'ownerOfTheWorkspace',
    defaultMessage: 'Users with the role Owner can manage billing and subscriptions.'
  },
  workspaceAccessDenied: {
    id: 'workspaceAccessDenied',
    defaultMessage: 'Workspace Access Denied'
  },
  cantAccessWorkspace: {
    id: 'cantAccessWorkspace',
    defaultMessage: 'You can\'t access the current Workspace. Please contact a Workspace Administrator.'
  },
  loginAccount: {
    id: 'loginAccount',
    defaultMessage: 'Log in with your Viso Account.'
  },
  areYouFacingFinancialDifficulties: {
    id: 'areYouFacingFinancialDifficulties',
    defaultMessage: 'Are you facing financial difficulties?'
  },
  getInTouchNowToDiscuss: {
    id: 'getInTouchNowToDiscuss',
    defaultMessage: 'We’re here to help. Get in touch now to discuss your situation.'
  },
  weareSadToSee: {
    id: 'weareSadToSee',
    defaultMessage: 'We are sad to see you go'
  },
  onDeletingYourWorkspaceDesc: {
    id: 'onDeletingYourWorkspaceDesc',
    defaultMessage: 'On deleting your workspace, you lose access to Viso workspace services, and we permanently delete your data. You can cancel the deletion for 14 days.'
  },
  typeDelete: {
    id: 'typeDelete',
    defaultMessage: 'Type “DELETE”'
  },
  manageUsers: {
    id: 'manageUsers',
    defaultMessage: 'Manage Users'
  },
  manageAccess: {
    id: 'manageAccess',
    defaultMessage: 'Manage Access'
  },
  billingCenter: {
    id: 'billingCenter',
    defaultMessage: 'Billing Center'
  },
  accessWorkspace: {
    id: 'accessWorkspace',
    defaultMessage: 'Access Workspace'
  },
  manageMyProfile: {
    id: 'manageMyProfile',
    defaultMessage: 'Manage my Profile'
  },
  removedWorkspace: {
    id: 'removedWorkspace',
    defaultMessage: 'Removed Workspace'
  },
  recoverYourWorkspace: {
    id: 'recoverYourWorkspace',
    defaultMessage: 'Recover your workspace'
  },
  workspaceHasBeenDeleted: {
    id: 'workspaceHasBeenDeleted',
    defaultMessage: 'Your workspace has been deleted. We will permanently delete your personal data. To cancel the deletion, you can recover the workspace until'
  },
  recover: {
    id: 'recover',
    defaultMessage: 'Recover'
  },
  successfullyUpdated: {
    id: 'successfullyUpdated',
    defaultMessage: 'Successfully Updated'
  },
  unsuccessfullyUpdated: {
    id: 'unsuccessfully',
    defaultMessage: 'Unsuccessfully Updated'
  },
  successfullyDeleted: {
    id: 'successfullyDeleted',
    defaultMessage: 'Successfully Deleted'
  },
  unsuccessfullyDeleted: {
    id: 'unsuccessfullyDeleted',
    defaultMessage: 'Unsuccessfully Deleted'
  },
  en: {
    id: 'en',
    defaultMessage: 'en'
  },
  de: {
    id: 'de',
    defaultMessage: 'de'
  },
  fr: {
    id: 'fr',
    defaultMessage: 'fr'
  },
  it: {
    id: 'it',
    defaultMessage: 'it'
  },
  addFilter: {
    id: 'addFilter',
    defaultMessage: 'Add a filter'
  },
  activeSeats: {
    id: 'activeSeats',
    defaultMessage: 'Active seats'
  },
  availableSeats: {
    id: 'availableSeats',
    defaultMessage: 'Available seats'
  },
  activationEndDate: {
    id: 'activationEndDate',
    defaultMessage: 'Activation end'
  },
  getNewLicense: {
    id: 'getNewLicense',
    defaultMessage: 'Get a new License'
  },
  activeUntil: {
    id: 'activeUntil',
    defaultMessage: 'Active until'
  },
  noLicense: {
    id: 'noLicense',
    defaultMessage: 'No License'
  },
  licenseInformation: {
    id: 'licenseInformation',
    defaultMessage: 'License Information'
  },
  licenseKey: {
    id: 'licenseKey',
    defaultMessage: 'License Key'
  },
  licenseType: {
    id: 'licenseType',
    defaultMessage: 'License Type'
  },
  activationStart: {
    id: 'activationStart',
    defaultMessage: 'Activation Start Date'
  },
  planName: {
    id: 'planName',
    defaultMessage: 'Plan Name'
  },
  licenseId: {
    id: 'licenseId',
    defaultMessage: 'License ID'
  },
  owner: {
    id: 'owner',
    defaultMessage: 'Owner'
  },
  expiration: {
    id: 'expiration',
    defaultMessage: 'Expiration'
  },
  licenseOverview: {
    id: 'expiration',
    defaultMessage: 'Overview'
  },
  seatPurchased: {
    id: 'seatPurchased',
    defaultMessage: 'Seats purchased'
  },
  seatAssigned: {
    id: 'seatAssigned',
    defaultMessage: 'Seats assigned'
  },
  seatUnassigned: {
    id: 'seatUnassigned',
    defaultMessage: 'Seats unassigned'
  },
  report: {
    id: 'report',
    defaultMessage: 'Report'
  },
  entries: {
    id: 'entries',
    defaultMessage: 'Entries'
  },
  default: {
    id: 'default',
    defaultMessage: 'Default'
  },
  submittedDate: {
    id: 'submittedDate',
    defaultMessage: 'Submitted Date'
  },
  group: {
    id: 'group',
    defaultMessage: 'Group'
  },
  reportsByGroup: {
    id: 'reportsByGroup',
    defaultMessage: 'Reports By Group'
  },
  allDevices: {
    id: 'allDevices',
    defaultMessage: 'All Devices'
  },
  reportName: {
    id: 'reportName',
    defaultMessage: 'Report Name'
  },
  reportMedia: {
    id: 'reportMedia',
    defaultMessage: 'Report Media'
  },
  reportDetails: {
    id: 'reportDetails',
    defaultMessage: 'Report Details'
  },
  reportType: {
    id: 'reportType',
    defaultMessage: 'Report Type'
  },
  submittedBy: {
    id: 'submittedBy',
    defaultMessage: 'Submitted by'
  },
  submittedDt: {
    id: 'submittedDate',
    defaultMessage: 'Submitted date'
  },
  filesAttached: {
    id: 'filesAttached',
    defaultMessage: 'Files Attached'
  },
  manageReport: {
    id: 'manageReport',
    defaultMessage: 'Manage Report'
  },
  exportCSV: {
    id: 'exportCSV',
    defaultMessage: 'Export CSV'
  },
  setupReport: {
    id: 'setupReport',
    defaultMessage: 'Set up new Report'
  },
  preview: {
    id: 'preview',
    defaultMessage: 'Preview'
  },
  permission: {
    id: 'permission',
    defaultMessage: 'Permission'
  },
  listing: {
    id: 'listing',
    defaultMessage: 'Listing'
  },
  areYouSureToDeactivateThe1User: {
    id: 'areYouSureToDeactivateThe1User',
    defaultMessage: 'Are you sure to deactivate the 1 selected user?'
  },
  areYouSureToActivateThe1User: {
    id: 'areYouSureToActivateThe1User',
    defaultMessage: 'Are you sure to activate the 1 selected user?'
  },
  areYouSureToArchiveThe1User: {
    id: 'areYouSureToArchiveThe1User',
    defaultMessage: 'Are you sure to archive the 1 selected user?'
  },
  redirectingToLoginScreen: {
    id: 'redirectingToLoginScreen',
    defaultMessage: 'Redirecting to login screen'
  },
  invitationSentSuccessfully: {
    id: 'invitationSentSuccessfully',
    defaultMessage: 'Invitation sent successfully'
  },
  invitationCancelledSuccessfully: {
    id: 'invitationCancelledSuccessfully',
    defaultMessage: 'Invitation cancelled successfully'
  },
  invitationsCancelledSuccessfully: {
    id: 'invitationsCancelledSuccessfully',
    defaultMessage: 'Invitations cancelled successfully'
  },
  invitationResendSuccessfully: {
    id: 'invitationResendSuccessfully',
    defaultMessage: 'Invitation resend successfully'
  },
  invitationsResendSuccessfully: {
    id: 'invitationsResendSuccessfully',
    defaultMessage: 'Invitations resend successfully'
  },
  userStatusUpdated: {
    id: 'userStatusUpdated',
    defaultMessage: 'User status updated.'
  },
  userDeactivatedSuccessfully: {
    id: 'userDeactivatedSuccessfully',
    defaultMessage: 'User deactivated successfully'
  },
  usersDeactivatedSuccessfully: {
    id: 'usersDeactivatedSuccessfully',
    defaultMessage: 'Users deactivated successfully'
  },
  userActivatedSuccessfully: {
    id: 'userActivatedSuccessfully',
    defaultMessage: 'User activated successfully'
  },
  usersActivatedSuccessfully: {
    id: 'usersActivatedSuccessfully',
    defaultMessage: 'Users activated successfully'
  },
  userArchivedSuccessfully: {
    id: 'userArchivedSuccessfully',
    defaultMessage: 'User archived successfully.'
  },
  usersArchivedSuccessfully: {
    id: 'usersArchivedSuccessfully',
    defaultMessage: 'Users archived successfully.'
  },
  usersWereSkipped: {
    id: 'usersWereSkipped',
    defaultMessage: 'users were skipped'
  },
  devicesWereSkipped: {
    id: 'devicesWereSkipped',
    defaultMessage: 'devices were skipped'
  },
  roleChangesSaved: {
    id: 'roleChangesSaved',
    defaultMessage: 'Role changes saved'
  },
  roleAssignedSuccessfully: {
    id: 'roleAssignedSuccessfully',
    defaultMessage: 'Role assigned successfully.'
  },
  roleDeletedSuccessfully: {
    id: 'roleDeletedSuccessfully',
    defaultMessage: 'Role deleted successfully.'
  },
  profileChangesSaved: {
    id: 'profileChangesSaved',
    defaultMessage: 'Profile Changes Saved'
  },
  sDeviceUpdated: {
    id: 'sDeviceUpdated',
    defaultMessage: 'Device(s) Updated'
  },
  moduleChangesSaved: {
    id: 'moduleChangesSaved',
    defaultMessage: 'Module changes saved.'
  },
  applicationChangesSaved: {
    id: 'applicationChangesSaved',
    defaultMessage: 'Application changes saved.'
  },
  deviceRebootFailed: {
    id: 'deviceRebootFailed',
    defaultMessage: 'Device reboot failed!'
  },
  devicesRebootFailed: {
    id: 'devicesRebootFailed',
    defaultMessage: 'Device(s) reboot failed!'
  },
  deviceDeleteFailed: {
    id: 'deviceDeleteFailed',
    defaultMessage: 'Device delete failed!'
  },
  rebooting: {
    id: 'rebooting',
    defaultMessage: 'Rebooting....'
  },
  deviceRebootSuccessfully: {
    id: 'deviceRebootSuccessfully',
    defaultMessage: 'Device rebooted successfully'
  },
  deviceDeletedSuccessfully: {
    id: 'deviceDeletedSuccessfully',
    defaultMessage: 'Device deleted successfully!'
  },
  removing: {
    id: 'removing',
    defaultMessage: 'Removing....'
  },
  devicesDeleteSuccessfully: {
    id: 'devicesDeleteSuccessfully',
    defaultMessage: 'Device(s) deleted successfully'
  },
  devicesDeleteFailed: {
    id: 'devicesDeleteFailed',
    defaultMessage: 'Device(s) delete failed!'
  },
  successfullyCreated: {
    id: 'successfullyCreated',
    defaultMessage: 'Successfully created'
  },
  profileDeletedSuccessfully: {
    id: 'profileDeletedSuccessfully',
    defaultMessage: 'Profile deleted successfully'
  },
  profileArchivedSuccessfully: {
    id: 'profileArchivedSuccessfully',
    defaultMessage: 'Profile archived successfully'
  },
  profileUnarchivedSuccessfully: {
    id: 'profileUnarchivedSuccessfully',
    defaultMessage: 'Profile unarchived successfully'
  },
  profileActivatedSuccessfully: {
    id: 'profileActivatedSuccessfully',
    defaultMessage: 'Profile activated successfully'
  },
  successfullyProfileDuplicated: {
    id: 'successfullyProfileDuplicated',
    defaultMessage: 'Successfully profile duplicated'
  },
  successfullyCreatedRelease: {
    id: 'successfullyCreatedRelease',
    defaultMessage: 'Successfully created a release'
  },
  profilesWereSkipped: {
    id: 'profilesWereSkipped',
    defaultMessage: 'profiles were skipped'
  },
  toViewHistoryicalData: {
    id: 'toViewHistoryicalData',
    defaultMessage: 'To view historical data the existing dashboard should be updated or a new dashboard created using the old device names.'
  },
  employeeArea: {
    id: 'employeeArea',
    defaultMessage: 'Employee Area'
  },
  rectangleSetting: {
    id: 'rectangleSetting',
    defaultMessage: 'Rectangle Setting'
  },
  excludeROI: {
    id: 'excludeROI',
    defaultMessage: 'Exclude ROI'
  },
  angle: {
    id: 'angle',
    defaultMessage: 'Angle'
  },
  crossLine: {
    id: 'crossLine',
    defaultMessage: 'Cross Line'
  },
  regionType: {
    id: 'regionType',
    defaultMessage: 'Region Type'
  },
  areaCounts: {
    id: 'areaCounts',
    defaultMessage: 'Area counts'
  },
  sectionSetting: {
    id: 'sectionSetting',
    defaultMessage: 'Section Setting'
  },
  gridRows: {
    id: 'gridRows',
    defaultMessage: 'Grid Rows'
  },
  gridCols: {
    id: 'gridCols',
    defaultMessage: 'Grid Cols'
  },
  areaSetting: {
    id: 'areaSetting',
    defaultMessage: 'Area Setting'
  },
  areaNumber: {
    id: 'areaNumber',
    defaultMessage: 'Area Number'
  },
  color: {
    id: 'color',
    defaultMessage: 'Color'
  },
  areaName: {
    id: 'areaName',
    defaultMessage: 'Area Name'
  },
  members: {
    id: 'members',
    defaultMessage: 'Members'
  },
  support: {
    id: 'support',
    defaultMessage: 'Support'
  },
  switchWorkspace: {
    id: 'switchWorkspace',
    defaultMessage: 'Switch Workspace'
  },
  deviceStatus: {
    id: 'deviceStatus',
    defaultMessage: 'Device Status'
  },
  pageNotFound: {
    id: 'pageNotFound',
    defaultMessage: 'Page Not Found'
  },
  outdatedLink: {
    id: 'outdatedLink',
    defaultMessage: 'Looks like the link is outdated or broken.'
  },
  backToHome: {
    id: 'backToHome',
    defaultMessage: 'Back to Home'
  },
  skipTask: {
    id: 'skipTask',
    defaultMessage: 'Skip task'
  },
  setup: {
    id: 'setup',
    defaultMessage: 'Setup'
  },
  setupVisoSuite: {
    id: 'setupVisoSuite',
    defaultMessage: 'Setup Viso Suite'
  },
  completeTheseTask: {
    id: 'completeTheseTask',
    defaultMessage: 'Complete these tasks to take full advantage of Viso Suite in your project.'
  },
  completeNow: {
    id: 'completeNow',
    defaultMessage: 'Complete now'
  },
  inviteTeamMembers: {
    id: 'inviteTeamMembers',
    defaultMessage: 'Invite team members'
  },
  sendYourTeamMemberAnInvitation: {
    id: 'sendYourTeamMemberAnInvitation',
    defaultMessage: 'Send your team members an invitation to join the workspace and collaborate. Check the invitation list to see and manage pending invitations.'
  },
  enrollYourFirstDevice: {
    id: 'enrollYourFirstDevice',
    defaultMessage: 'Enroll your first device'
  },
  selectTheHardwarePlatform: {
    id: 'selectTheHardwarePlatform',
    defaultMessage: 'Select the hardware platform and device type you want to use. Create and download the image file to flash it to your device. Once online, it appears in your workspace. Connect it to a USB or IP camera to provide vision input.'
  },
  addNewModuleNInstall: {
    id: 'addNewModuleNInstall',
    defaultMessage: 'In your library, add a new module to your workspace. Install the modules required to create a demo application.'
  },
  createYourFirstApp: {
    id: 'createYourFirstApp',
    defaultMessage: 'Create your first application'
  },
  createDemoApplication: {
    id: 'createDemoApplication',
    defaultMessage: 'Create a demo application in the Viso Builder with the installed modules.'
  },
  deployAProfile: {
    id: 'deployAProfile',
    defaultMessage: 'Deploy a profile'
  },
  createANewProfileToDeploy: {
    id: 'createANewProfileToDeploy',
    defaultMessage: 'Create a new profile to deploy an application to devices. Assign the profile to devices, it will be automatically deployed as soon as the device is online.'
  },
  setUpADashboard: {
    id: 'setUpADashboard',
    defaultMessage: 'Set up a dashboard'
  },
  deployedApplicationPeriodically: {
    id: 'deployedApplicationPeriodically',
    defaultMessage: 'Deployed applications periodically sends data to your workspace. Set up the queries and configure charts to visualize timeseries data in your dashboard.'
  },
  completeTasks: {
    id: 'completeTasks',
    defaultMessage: 'Completed tasks'
  },
  primaryOwner: {
    id: 'primaryOwner',
    defaultMessage: 'Primary Owner'
  },
  getEtcher: {
    id: 'getEtcher',
    defaultMessage: 'Get Etcher'
  },
  downloadImage: {
    id: 'downloadImage',
    defaultMessage: 'Download image'
  },
  downloadImageFileAndSave: {
    id: 'downloadImageFileAndSave',
    defaultMessage: 'First, download the image file and save it to your computer. It can be used to enroll multiple devices.'
  },
  bootableDrive: {
    id: 'bootableDrive',
    defaultMessage: 'Bootable USB drive'
  },
  createABootableUSB: {
    id: 'createABootableUSB',
    defaultMessage: 'Second, create a bootable USB drive with the downloaded image file. Use a tool like Etcher.'
  },
  installToDevice: {
    id: 'installToDevice',
    defaultMessage: 'Install to device'
  },
  installTheOSToDevice: {
    id: 'installTheOSToDevice',
    defaultMessage: 'Finally, Install the OS to the device. Establish internet connection and find it in devices after 10 minutes.'
  },
  emailIsInvalid: {
    id: 'emailIsInvalid',
    defaultMessage: 'Email is Invalid!'
  },
  showArchivedProfiles: {
    id: 'showArchivedProfiles',
    defaultMessage: 'Show archived profiles'
  },
  showAllProfiles: {
    id: 'showAllProfiles',
    defaultMessage: 'Show all profiles'
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete'
  },
  videoFiles: {
    id: 'videoFiles',
    defaultMessage: 'Video Files'
  },
  uploadVideoFile: {
    id: 'uploadVideoFile',
    defaultMessage: 'Upload Video File'
  },
  videoName: {
    id: 'videoName',
    defaultMessage: 'Video Name'
  },
  videoFilemp4: {
    id: 'videoFilemp4',
    defaultMessage: 'Video file (mp4)'
  },
  fileDescription: {
    id: 'fileDescription',
    defaultMessage: 'File Description'
  },
  importVideoFile: {
    id: 'importVideoFile',
    defaultMessage: 'Import Video File'
  },
  videoFileInformation: {
    id: 'videoFileInformation',
    defaultMessage: 'Video File Information'
  },
  importVidoeDescription: {
    id: 'importVidoeDescription',
    defaultMessage: 'Import a Video File to the gallery to use it as visual input in applications'
  },
  userManagement: {
    id: 'userManagement',
    defaultMessage: 'User Management'
  },
  cCancel: {
    id: 'cCancel',
    defaultMessage: 'Cancel'
  },
  deleteSubscription: {
    id: 'deleteSubscription',
    defaultMessage: 'If you proceed, your subscription will be cancelled and related contents will be removed with immediate effect. This action is irreversible.'
  },
  important: {
    id: 'important',
    defaultMessage: 'Important'
  },
  typeCancel: {
    id: 'typeCancel',
    defaultMessage: 'Type “CANCEL”'
  },
  solutionDetails: {
    id: 'solutionDetails',
    defaultMessage: 'Solution Details'
  },
  enterYourSolutionDetails: {
    id: 'enterYourSolutionDetails',
    defaultMessage: 'Enter your solution details'
  },
  createNewSolutionSelector: {
    id: 'createNewSolutionSelector',
    defaultMessage: 'List a Solution to the Marketplace'
  },
  createNewSolutionSelectorDesc: {
    id: 'createNewSolutionSelectorDesc',
    defaultMessage: 'Create a new application starting from scratch or with a template.'
  },
  listModule: {
    id: 'listModule',
    defaultMessage: 'List Module'
  },
  listApplication: {
    id: 'listApplication',
    defaultMessage: 'List Application'
  },
  listModuleDesc: {
    id: 'listModuleDesc',
    defaultMessage: 'Publish a custom Module from your Library as a Marketplace Solution.'
  },
  listApplicationDesc: {
    id: 'listApplicationDesc',
    defaultMessage: 'Publish a custom Application from your Library as a Marketplace Solution.'
  },
  userGuide: {
    id: 'userGuide',
    defaultMessage: 'User Guide'
  },
  marketplacePage: {
    id: 'marketplacePage',
    defaultMessage: 'Marketplace Page'
  },
  openInBuilder: {
    id: 'openInBuilder',
    defaultMessage: 'Open in Builder'
  },
  listNewModule: {
    id: 'listNewModule',
    defaultMessage: 'List new Module'
  },
  listNewApplication: {
    id: 'listNewApplication',
    defaultMessage: 'List new Application'
  },
  menu: {
    id: 'menu',
    defaultMessage: 'Menu'
  },
  visoMarketplace: {
    id: 'visoMarketplace',
    defaultMessage: 'Viso Marketplace'
  },
  importApplicationDesc: {
    id: 'importApplicationDesc',
    defaultMessage: 'Import complete AI vision applications. Ready to use.'
  },
  types: {
    id: 'types',
    defaultMessage: 'Types'
  },
  categories: {
    id: 'categories',
    defaultMessage: 'Categories'
  },
  filters: {
    id: 'filter',
    defaultMessage: 'Filters'
  },
  searchApplicationDesc: {
    id: 'searchApplicationDesc',
    defaultMessage: 'Search for application or business need'
  },
  categoryDesc: {
    id: 'categoryDesc',
    defaultMessage:
      'Some short category description that provides information about the category.'
  },
  mostInstalled: {
    id: 'mostInstalled',
    defaultMessage: 'Most installed'
  },
  newest: {
    id: 'newest',
    defaultMessage: 'Newest'
  },
  recommendedForYou: {
    id: 'recommendedForYou',
    defaultMessage: 'Recommended for you'
  },
  popularModules: {
    id: 'popularModules',
    defaultMessage: 'Popular Modules'
  },
  seeAll: {
    id: 'seeAll',
    defaultMessage: 'See all'
  },
  recentlyAdded: {
    id: 'recentlyAdded',
    defaultMessage: 'Recently added'
  },
  sortBy: {
    id: 'sortBy',
    defaultMessage: 'Sort by'
  },
  filteredBy: {
    id: 'filteredBy',
    defaultMessage: 'filtered by'
  },
  resultFor: {
    id: 'resultForm',
    defaultMessage: 'result for'
  },
  seeUserLists: {
    id: 'seeUserLists',
    defaultMessage: 'See user lists'
  },
  seeUserPages: {
    id: 'seeUserPages',
    defaultMessage: 'See user pages'
  },
  updateUsers: {
    id: 'updateUsers',
    defaultMessage: 'Update users'
  },
  userActions: {
    id: 'userActions',
    defaultMessage: 'User actions'
  },
  addNewUsers: {
    id: 'addNewUsers',
    defaultMessage: 'Add new users'
  },
  archiveUsers: {
    id: 'archiveUsers',
    defaultMessage: 'Archive users'
  },
  archiveUser: {
    id: 'archiveUser',
    defaultMessage: 'Archive user'
  },
  deleteUsers: {
    id: 'deleteUsers',
    defaultMessage: 'Delete users'
  },
  seeDashboard: {
    id: 'seeDashboard',
    defaultMessage: 'See dashboard'
  },
  updateDashboard: {
    id: 'updateDashboard',
    defaultMessage: 'Update dashboard'
  },
  dashboardActions: {
    id: 'dashboardActions',
    defaultMessage: 'Dashboard actions'
  },
  addNewDashboard: {
    id: 'addNewDashboard',
    defaultMessage: 'Add new dashboard'
  },
  removeDashboard: {
    id: 'removeDashboard',
    defaultMessage: 'Remove dashboard'
  },
  seeDeviceLists: {
    id: 'seeDeviceLists',
    defaultMessage: 'See device lists'
  },
  seeDevices: {
    id: 'seeDevices',
    defaultMessage: 'See Devices'
  },
  seeDevicePages: {
    id: 'seeDevicePages',
    defaultMessage: 'See device pages'
  },
  updateDevices: {
    id: 'updateDevices',
    defaultMessage: 'Update devices'
  },
  deviceActions: {
    id: 'deviceActions',
    defaultMessage: 'Device actions'
  },
  addNewDevices: {
    id: 'addNewDevices',
    defaultMessage: 'Add new devices'
  },
  archiveDevices: {
    id: 'archiveDevices',
    defaultMessage: 'Archive devices'
  },
  deleteDevices: {
    id: 'deleteDevices',
    defaultMessage: 'Delete devices'
  },
  seeFlowLists: {
    id: 'seeFlowLists',
    defaultMessage: 'See flow lists'
  },
  seeFlowPages: {
    id: 'seeFLowPages',
    defaultMessage: 'See flow pages'
  },
  updateFlows: {
    id: 'updateFlow',
    defaultMessage: 'Update flows'
  },
  addNewFlows: {
    id: 'addNewFlows',
    defaultMessage: 'Add new flows'
  },
  archiveFlows: {
    id: 'archiveFlows',
    defaultMessage: 'Archive flows'
  },
  deleteFlows: {
    id: 'deleteFlows',
    defaultMessage: 'Delete flows'
  },
  flowActions: {
    id: 'flowActions',
    defaultMessage: 'Flow actions'
  },
  seeGroupLists: {
    id: 'seeGroupLists',
    defaultMessage: 'See group lists'
  },
  seeGroupPages: {
    id: 'seeGroupPages',
    defaultMessage: 'See group pages'
  },
  updateGroups: {
    id: 'updateGroups',
    defaultMessage: 'Update groups'
  },
  groupActions: {
    id: 'groupActions',
    defaultMessage: 'Group actions'
  },
  addNewGroups: {
    id: 'addNewGroups',
    defaultMessage: 'Add new groups'
  },
  archiveGroups: {
    id: 'archiveGroups',
    defaultMessage: 'Archive groups'
  },
  deleteGroups: {
    id: 'deleteGroups',
    defaultMessage: 'Delete groups'
  },
  seeModuleLists: {
    id: 'seeModuleLists',
    defaultMessage: 'See module lists'
  },
  seeModulePages: {
    id: 'seeModulePages',
    defaultMessage: 'See module pages'
  },
  updateModules: {
    id: 'updateModules',
    defaultMessage: 'Update modules'
  },
  moduleActions: {
    id: 'moduleActions',
    defaultMessage: 'Module actions'
  },
  deleteModules: {
    id: 'deleteModules',
    defaultMessage: 'Delete Modules'
  },
  addNewModules: {
    id: 'addNewModules',
    defaultMessage: 'Add new modules'
  },
  archiveModules: {
    id: 'archiveModules',
    defaultMessage: 'Archive modules'
  },
  seeProfileLists: {
    id: 'seeProfileLists',
    defaultMessage: 'See profile lists'
  },
  seeProfilePages: {
    id: 'seeProfilePages',
    defaultMessage: 'See profile pages'
  },
  updateProfiles: {
    id: 'updateProfiles',
    defaultMessage: 'Update profiles'
  },
  profileActions: {
    id: 'profileActions',
    defaultMessage: 'Profile actions'
  },
  addNewProfiles: {
    id: 'addNewProfiles',
    defaultMessage: 'Add new profiles'
  },
  archiveProfiles: {
    id: 'archiveProfiles',
    defaultMessage: 'Archive profiles'
  },
  deleteProfiles: {
    id: 'deleteProfiles',
    defaultMessage: 'Delete profiles'
  },
  seeRoleList: {
    id: 'seeRoleList',
    defaultMessage: 'See role list'
  },
  seeRolesPages: {
    id: 'seeRolesPages',
    defaultMessage: 'See role pages'
  },
  updateRoles: {
    id: 'updateRoles',
    defaultMessage: 'Update roles'
  },
  roleActions: {
    id: 'roleActions',
    defaultMessage: 'Role actions'
  },
  addNewRoles: {
    id: 'addNewRoles',
    defaultMessage: 'Add new roles'
  },
  archiveRole: {
    id: 'archiveRole',
    defaultMessage: 'Archive roles'
  },
  deleteRoles: {
    id: 'deleteRoles',
    defaultMessage: 'Delete roles'
  },
  seeScopeList: {
    id: 'seeScopeList',
    defaultMessage: 'See scope lists'
  },
  seeScopePages: {
    id: 'seeScopePages',
    defaultMessage: 'See scope pages'
  },
  updateScopes: {
    id: 'updateScopes',
    defaultMessage: 'Update scopes'
  },
  scopeActions: {
    id: 'scopeActions',
    defaultMessage: 'Scope actions'
  },
  addNewScopes: {
    id: 'addNewScopes',
    defaultMessage: 'Add new scopes'
  },
  archiveScopes: {
    id: 'archiveScopes',
    defaultMessage: 'Archive scopes'
  },
  deleteScopes: {
    id: 'deleteScopes',
    defaultMessage: 'Delete scopes'
  },
  specialRolePermission: {
    id: 'specialRolePermission',
    defaultMessage: 'Special Role Permissions'
  },
  loadPreset: {
    id: 'loadPreset',
    defaultMessage: 'Load preset'
  },
  view: {
    id: 'view',
    defaultMessage: 'View'
  },
  addAFilter: {
    id: 'addAFilter',
    defaultMessage: 'Add a filter'
  },
  noDataAvailable: {
    id: 'noDataAvailable',
    defaultMessage: 'No data available'
  },
  resources: {
    id: 'resources',
    defaultMessage: 'Resources'
  },
  usersAssigned: {
    id: 'usersAssigned',
    defaultMessage: 'users assigned'
  },
  apply: {
    id: 'apply',
    defaultMessage: 'Apply'
  },
  addNewColumns: {
    id: 'addNewColumns',
    defaultMessage: 'Add new Columns'
  },
  manageColumns: {
    id: 'manageColumns',
    defaultMessage: 'Manage Columns'
  },
  noResultsFound: {
    id: 'noResultsFound',
    defaultMessage: 'No results found'
  },
  new: {
    id: 'new',
    defaultMessage: 'NEW'
  },
  tryAdjustingSearch: {
    id: 'tryAdjustingSearch',
    defaultMessage:
      'Try adjusting your search or filter to find what you are looking for.'
  },
  removeInstalledApplications: {
    id: 'removeInstalledApplications',
    defaultMessage: 'You are about to remove installed applications from'
  },
  dragFileHereOr: {
    id: 'dragFileHereOr',
    defaultMessage: 'Drag file here or'
  },
  browse: {
    id: 'browse',
    defaultMessage: 'browse'
  },
  reset: {
    id: 'reset',
    defaultMessage: 'Reset'
  },
  smallSelected: {
    id: 'smallSelected',
    defaultMessage: 'selected'
  },
  skip: {
    id: 'skip',
    defaultMessage: 'Skip'
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading'
  },
  createVisoAccount: {
    id: 'createVisoAccount',
    defaultMessage: 'Create your personal Viso Account'
  },
  setUpAccount: {
    id: 'setUpAccount',
    defaultMessage: 'Set up your personal account with '
  },
  passwordValidation: {
    id: 'passwordValidation',
    defaultMessage:
      'Password must be 8 characters, at least one letter, symbol and a number'
  },
  agreeAccount: {
    id: 'agreeAccount',
    defaultMessage: 'By creating an account, you agree to our'
  },
  termsOfService: {
    id: 'termsOfService',
    defaultMessage: 'Terms of Service'
  },
  and: {
    id: 'and',
    defaultMessage: 'and'
  },
  privacyPolicy: {
    id: 'privacyPolicy',
    defaultMessage: 'Privacy Policy'
  },
  occasionallyEmails: {
    id: 'occasionallyEmails',
    defaultMessage: 'We’ll send you account-related emails'
  },
  createAccount: {
    id: 'createAccount',
    defaultMessage: 'Create Account'
  },
  creating: {
    id: 'creating',
    defaultMessage: 'Creating'
  },
  inviteLinkNotActive: {
    id: 'inviteLinkNotActive',
    defaultMessage: 'The invite link is no longer active'
  },
  invitationLinkExpire: {
    id: 'invitationLinkExpire',
    defaultMessage: 'The invitation link has expired.'
  },
  pleaseCheckLinkAvailable: {
    id: 'pleaseCheckLinkAvailable',
    defaultMessage:
      'Please check with the person who shared it with you to see if there’s a new link available.'
  },
  keepSeeingNotification: {
    id: 'keepSeeingNotification',
    defaultMessage: 'Keep seeing this notification?'
  },
  contactUs: {
    id: 'contactUs',
    defaultMessage: 'Contact us.'
  },
  notActivatedYourAccount: {
    id: 'notActivatedYourAccount',
    defaultMessage: 'Looks like you have not activated your account.'
  },
  didntReceiveEmail: {
    id: 'didntReceiveEmail',
    defaultMessage: 'Didnt receive the email? Click here to'
  },
  resendEmail: {
    id: 'resendEmail',
    defaultMessage: 'Resend Email'
  },
  didntReceiveEmailFromUs: {
    id: 'didntReceiveEmailFromUs',
    defaultMessage: 'Didn’t receive an email from us?'
  },
  emailConfirmationToEnsureData: {
    id: 'emailConfirmationToEnsureData',
    defaultMessage:
      'Email confirmation helps us to ensure your data will always be safe.'
  },
  sendPasswordReset: {
    id: 'sendPasswordReset',
    defaultMessage: 'Send password reset'
  },
  setNewPassword: {
    id: 'setNewPassword',
    defaultMessage: 'Set a new Password'
  },
  passwordTiedToAccount: {
    id: 'passwordTiedToAccount',
    defaultMessage: 'The password is tied to the account'
  },
  wantToUseAnotherAccount: {
    id: 'wantToUseAnotherAccount',
    defaultMessage: 'Want to use another account?'
  },
  signIn: {
    id: 'signIn',
    defaultMessage: 'Sign In'
  },
  setAccount: {
    id: 'setAccount',
    defaultMessage: 'Set Account'
  },
  forgotPassword: {
    id: 'forgotPassword',
    defaultMessage: 'Forgot password?'
  },
  dontHaveAccount: {
    id: 'dontHaveAccount',
    defaultMessage: 'Don\'t have an account?'
  },
  signUp: {
    id: 'signUp',
    defaultMessage: 'Sign up'
  },
  logIn: {
    id: 'logIn',
    defaultMessage: 'Log in'
  },
  loginWithViso: {
    id: 'loginWithViso',
    defaultMessage: 'Log in with your Viso Account.'
  },
  billingInformation: {
    id: 'billingInformation',
    defaultMessage: 'Billing Information'
  },
  bussinessName: {
    id: 'bussinessName',
    defaultMessage: 'Business name'
  },
  country: {
    id: 'country',
    defaultMessage: 'Country'
  },
  address1: {
    id: 'addres1',
    defaultMessage: 'Address 1'
  },
  address2: {
    id: 'address2',
    defaultMessage: 'Address 2'
  },
  city: {
    id: 'city',
    defaultMessage: 'City'
  },
  postCode: {
    id: 'postCode',
    defaultMessage: 'Postcode'
  },
  openGmail: {
    id: 'openGmail',
    defaultMessage: 'Open Gmail'
  },
  openOutlook: {
    id: 'openOutlook',
    defaultMessage: 'Open Outlook'
  },
  openYahoo: {
    id: 'openYahoo',
    defaultMessage: 'Open Yahoo!'
  },
  checkYourInbox: {
    id: 'checkYourInbox',
    defaultMessage: 'Great, please check your inbox'
  },
  sentEmailTo: {
    id: 'sentEmailTo',
    defaultMessage: 'We sent password reset to'
  },
  pleaseVerifyEmail: {
    id: 'pleaseVerifyEmail',
    defaultMessage: 'Please verify your email'
  },
  pleaseCheckEmail: {
    id: 'pleaseCheckEmail',
    defaultMessage: 'Please check your email'
  },
  alreadyHaveAnAccount: {
    id: 'alreadyHaveAnAccount',
    defaultMessage: 'Already have an account?'
  },
  addUser: {
    id: 'addUser',
    defaultMessage: 'Add a user'
  },
  sUsers: {
    id: 'sUsers',
    defaultMessage: 'users'
  },
  users: {
    id: 'users',
    defaultMessage: 'Users'
  },
  signUpViso: {
    id: 'signUpViso',
    defaultMessage: 'Sign up for Viso Suite'
  },
  createYourPersonalAccount: {
    id: 'createYourPersonalAccount',
    defaultMessage: 'Create your personal Account'
  },
  workspaceInformation: {
    id: 'workspaceInformation',
    defaultMessage: 'Workspace Information'
  },
  welcome: {
    id: 'welcome',
    defaultMessage: 'Welcome'
  },
  workspaceBelongsTo: {
    id: 'workspaceBelongsTo',
    defaultMessage: 'This workspace belongs to'
  },
  upcomingInvoice: {
    id: 'upcomingInvoice',
    defaultMessage: 'Upcoming Invoice'
  },
  unitPrice: {
    id: 'unitPrice',
    defaultMessage: 'Unit Price'
  },
  currentConfiguration: {
    id: 'currentConfiguration',
    defaultMessage:
      'Based on your current configuration, you are being automatically charged'
  },
  changeProductSubscriptions: {
    id: 'changeProductSubscriptions',
    defaultMessage: 'To make changes to your product subscriptions, go to'
  },
  manageSubscriptions: {
    id: 'manageSubscriptions',
    defaultMessage: 'Manage Subscriptions'
  },
  amountDue: {
    id: 'amountDue',
    defaultMessage: 'Amount due'
  },
  changePlan: {
    id: 'changePlan',
    defaultMessage: 'Change Plan'
  },
  changeSubscription: {
    id: 'changeSubscription',
    defaultMessage: 'Change Subscription'
  },
  change: {
    id: 'change',
    defaultMessage: 'Change'
  },
  by: {
    id: 'by',
    defaultMessage: 'by'
  },
  seats: {
    id: 'seats',
    defaultMessage: 'Seats'
  },
  cancelSubscription: {
    id: 'cancelSubscription',
    defaultMessage: 'Cancel Subscription'
  },
  priceEstimate: {
    id: 'priceEstimate',
    defaultMessage: 'Price estimate'
  },
  nextBillingDate: {
    id: 'nextBillingDate',
    defaultMessage: 'Next billing date'
  },
  billTo: {
    id: 'billTo',
    defaultMessage: 'Bill to'
  },
  summary: {
    id: 'summary',
    defaultMessage: 'Summary'
  },
  address: {
    id: 'address',
    defaultMessage: 'Address'
  },
  phoneNumber: {
    id: 'phoneNumber',
    defaultMessage: 'Phone Number'
  },

  billingMethod: {
    id: 'billingMethod',
    defaultMessage: 'Billing method'
  },
  subTotal: {
    id: 'subTotal',
    defaultMessage: 'SubTotal'
  },
  getStartedWith: {
    id: 'getStartedWith',
    defaultMessage: 'Get started with'
  },
  confirmYourOrder: {
    id: 'confirmYourOrder',
    defaultMessage: 'Confirm your Order'
  },
  getStartedImmediately: {
    id: 'getStartedImmediately',
    defaultMessage:
      'Get started immediately and access all services offered by the selected plan. After the trial period ends, you will be charged automatically based on the payment details and interval you provide us with. You can change your settings anytime in the Billing Center of your Workspace.'
  },
  payment: {
    id: 'payment',
    defaultMessage: 'Payment'
  },
  expires: {
    id: 'expires',
    defaultMessage: 'Expires'
  },
  monthly: {
    id: 'monthly',
    defaultMessage: 'Monthly'
  },
  byClickingConfirm: {
    id: 'byClickingConfirm',
    defaultMessage: 'By clicking "Confirm" you agree to our'
  },
  billingInformationDesc: {
    id: 'billingInformationDesc',
    defaultMessage: 'The business that this workspace belongs to.'
  },
  contactInInvoice: {
    id: 'contactInInvoice',
    defaultMessage: 'The contact who will be reflected in the invoice'
  },
  recentCommitsToApplication: {
    id: 'recentCommitsToApplication',
    defaultMessage: 'Recent Commits that have been made to the Application.'
  },
  billing: {
    id: 'billing',
    defaultMessage: 'Billing'
  },
  history: {
    id: 'history',
    defaultMessage: 'History'
  },
  billHistory: {
    id: 'billHistory',
    defaultMessage: 'Bill History'
  },
  paymentSettings: {
    id: 'paymentSettings',
    defaultMessage: 'Payment Settings'
  },
  paymentInformation: {
    id: 'paymentInformation',
    defaultMessage: 'Payment Information'
  },
  interval: {
    id: 'interval',
    defaultMessage: 'Interval'
  },
  nameOnCard: {
    id: 'nameOnCard',
    defaultMessage: 'Name on Card'
  },
  invoicesList: {
    id: 'invoicesList',
    defaultMessage: 'Invoices List'
  },
  billingStatementDisplay: {
    id: 'billingStatementDisplay',
    defaultMessage:
      'Your billing statement will display viso.ai AG, located in Schaffhausen, Switzerland. Your credit card issuer may charge foreign transaction or cross-border fees in addition to the total price.'
  },
  billingContact: {
    id: 'billingContact',
    defaultMessage: 'Billing Contact'
  },
  viewFull: {
    id: 'viewFull',
    defaultMessage: 'View Full'
  },
  tax: {
    id: 'tax',
    defaultMessage: 'Tax'
  },
  profileInformation: {
    id: 'profileInformation',
    defaultMessage: 'Profile Information'
  },
  profileName: {
    id: 'profileName',
    defaultMessage: 'Profile name'
  },
  profileDescription: {
    id: 'profileDescription',
    defaultMessage: 'Profile Description'
  },
  profileId: {
    id: 'profileId',
    defaultMessage: 'Profile ID'
  },
  assignedApplicationDescription: {
    id: 'assignedApplicationDescription',
    defaultMessage:
      'The profile contains a specific version of an Application from your Library.'
  },
  licenceName: {
    id: 'licenceName',
    defaultMessage: 'Licence name'
  },
  availableSeat: {
    id: 'availableSeat',
    defaultMessage: 'Available seat'
  },
  totalSeats: {
    id: 'totalSeats',
    defaultMessage: 'Total seats'
  },
  activationEnd: {
    id: 'activationEnd',
    defaultMessage: 'Activation end'
  },
  confirmationMessage: {
    id: 'confirmationMessage',
    defaultMessage: 'Are you sure you want update profile?'
  },
  updateProfile: {
    id: 'updateProfile',
    defaultMessage: 'Update Profile'
  },
  addProfile: {
    id: 'addProfile',
    defaultMessage: 'Add Information about the new Profile.'
  },
  assignAFlow: {
    id: 'assignAFlow',
    defaultMessage: 'Assign an Application'
  },
  selectFlow: {
    id: 'selectFlow',
    defaultMessage: 'Add an application to the profile.'
  },
  releaseDate: {
    id: 'releaseDate',
    defaultMessage: 'Release Date'
  },
  stages: {
    id: 'stages',
    defaultMessage: 'Stages'
  },
  deployedBy: {
    id: 'deployedBy',
    defaultMessage: 'Deployed by'
  },
  runtime: {
    id: 'runtime',
    defaultMessage: 'Runtime'
  },
  releasePipeline: {
    id: 'releasePipeline',
    defaultMessage: 'Release Pipeline'
  },
  releasePipelineDescription: {
    id: 'releasePipelineDescription',
    defaultMessage: 'See and monitor all your deployment releases.'
  },
  noFlowAssigned: {
    id: 'noFlowAssigned',
    defaultMessage: 'No Flow assigned'
  },
  createDeviceImage: {
    id: 'createDeviceImage',
    defaultMessage: 'Create a Device Image'
  },
  createDeviceImageDesc: {
    id: 'createDeviceImageDesc',
    defaultMessage:
      'Select the device type that you want to add to your device images.'
  },
  createImage: {
    id: 'createImage',
    defaultMessage: 'Create Image'
  },
  enrollDevices: {
    id: 'enrollDevices',
    defaultMessage: 'Enroll devices'
  },
  enrollDeviceDescription: {
    id: 'enrollDeviceDescription',
    defaultMessage:
      'Note: Download the image below to flash it with a device of the device type. When online, the devices will be automatically enrolled to your workspace and are ready to be set up.'
  },
  noProfiles: {
    id: 'noProfiles',
    defaultMessage: 'Currently no profile available.'
  },
  noProfilesAssigned: {
    id: 'noProfilesAssigned',
    defaultMessage: 'No Profile Assigned'
  },
  deviceName: {
    id: 'deviceName',
    defaultMessage: 'Device Name'
  },
  selectLicense: {
    id: 'selectLicense',
    defaultMessage: 'Select license'
  },
  selectLicenseDescription: {
    id: 'selectLicenseDescription',
    defaultMessage: 'Select license to use to assign profile'
  },
  pushProfile: {
    id: 'pushProfile',
    defaultMessage: 'Are you sure to assign the profile '
  },
  pushNow: {
    id: 'pushNow',
    defaultMessage: 'Push now'
  },
  selectProfile: {
    id: 'selectProfile',
    defaultMessage: 'Select profile'
  },
  selectDevices: {
    id: 'selectDevices',
    defaultMessage: 'Select devices'
  },
  unassignedDeviceList: {
    id: 'unassignedDeviceList',
    defaultMessage:
      ' Unassigned devices are listed below by default. <br /> Modify the Status column filter to find more devices.'
  },
  selectDevicesToAssignTo: {
    id: 'selectDevicesToAssignTo',
    defaultMessage: 'Select devices to assign to'
  },
  updateDevicesDesc: {
    id: 'updateDevicesDesc',
    defaultMessage:
      'Roll-out the selected profile to either all or specific devices.'
  },
  pushAll: {
    id: 'pushAll',
    defaultMessage: 'Push to all associated devices'
  },
  pushSelected: {
    id: 'pushSelected',
    defaultMessage: 'Push to specific associated devices'
  },
  pushUpdateDeviceDialog: {
    id: 'pushUpdateDeviceDialog',
    defaultMessage: 'Are you sure to push the profile '
  },
  pushUpdateDeviceDialog2: {
    id: 'pushUpdateDeviceDialog2',
    defaultMessage: 'to all associated devices? '
  },
  pushUpdateDeviceTitle: {
    id: 'pushUpdateDeviceTitle',
    defaultMessage: 'Push profile update to devices'
  },
  deployNewApplication: {
    id: 'deployNewApplication',
    defaultMessage: 'You are about to deploy a new application to'
  },
  sDevice: {
    id: 'sDevice',
    defaultMessage: 'Device(s)'
  },
  continueProcess: {
    id: 'continueProcess',
    defaultMessage: 'Do you want to continue?'
  },
  confirmAndProceed: {
    id: 'confirmAndProceed',
    defaultMessage: 'I confirm and want to proceed'
  },
  applicationOverride: {
    id: 'applicationOverride',
    defaultMessage:
      'The installed application will be removed. Do you want to continue?'
  },
  aboutToUpdate: {
    id: 'aboutToUpdate',
    defaultMessage: 'You are about to update'
  },
  toAnotherVersionApplication: {
    id: 'toAnotherVersionApplication',
    defaultMessage: 'to another version of the currently installed application.'
  },
  keepCompatibleSettings: {
    id: 'keepCompatibleSettings',
    defaultMessage: 'Keep compatible configuration settings'
  },
  resetConfigurationSettings: {
    id: 'resetConfigurationSettings',
    defaultMessage: 'Reset configuration settings to default'
  },
  deployApplication: {
    id: 'deployApplication',
    defaultMessage: 'Deploy new Application'
  },
  replaceApplication: {
    id: 'replaceApplication',
    defaultMessage: 'Replace existing Application'
  },
  updateApplication: {
    id: 'updateApplication',
    defaultMessage: 'Update Application'
  },
  deleteEndpoint: {
    id: 'deleteEndpoint',
    defaultMessage:
      'Deleting the endpoint will lead to data loss. Please confirm to proceed.'
  },
  removeDevice: {
    id: 'removeDevice',
    defaultMessage: 'Remove Device'
  },
  sRemoveDevice: {
    id: 'sRemoveDevice',
    defaultMessage: 'Remove Device(s)'
  },
  addDevices: {
    id: 'addDevices',
    defaultMessage: 'Add Devices to your Workspace'
  },
  downloadDeviceImage: {
    id: 'downloadDeviceImage',
    defaultMessage:
      'Download the Device Image and flash it to multiple devices to add them automatically.'
  },
  assignProfile: {
    id: 'assignProfile',
    defaultMessage: 'Assign profile'
  },
  unassignProfile: {
    id: 'unassignProfile',
    defaultMessage: 'Unassign profile'
  },
  deviceImageList: {
    id: 'deviceImageList',
    defaultMessage: 'Device Image List'
  },
  sDeleteDevices: {
    id: 'sDeleteDevices',
    defaultMessage: 'Delete Device(s)'
  },
  rebootDevice: {
    id: 'rebootDevice',
    defaultMessage: 'Reboot device'
  },
  selectProfileDesc: {
    id: 'selectProfileDesc',
    defaultMessage: 'Select profile to be assigned to devices.'
  },
  activateThis: {
    id: 'activateThis',
    defaultMessage: 'Are you sure to activate the'
  },
  confirmArchiveProfiles: {
    id: 'confirmArchiveProfiles',
    defaultMessage: 'Confirm Archive Profiles'
  },
  archiveThis: {
    id: 'archiveThis',
    defaultMessage: 'Are you sure to archive the'
  },
  archive: {
    id: 'archive',
    defaultMessage: 'Archive'
  },
  confirmActivateProfiles: {
    id: 'confirmActivateProfiles',
    defaultMessage: 'Confirm Activate Profiles'
  },
  activate: {
    id: 'activate',
    defaultMessage: 'Activate'
  },
  confirmRemoveProfiles: {
    id: 'confirmRemoveProfiles',
    defaultMessage: 'Confirm Remove Profiles'
  },
  removeThis: {
    id: 'removeThis',
    defaultMessage: 'Are you sure to remove the'
  },
  assignProfileToDevices: {
    id: 'assignProfileToDevices',
    defaultMessage: 'Assign profile to device(s)'
  },
  removeProfile: {
    id: 'removeProfile',
    defaultMessage: 'Remove Profile'
  },
  createProfile: {
    id: 'createProfile',
    defaultMessage: 'Create profile'
  },
  partnerPortal: {
    id: 'partnerPortal',
    defaultMessage: 'Partner Portal'
  },
  findAndShareSolution: {
    id: 'findAndShareSolution',
    defaultMessage: 'Find and share solutions to build great things.'
  },
  createNewSolution: {
    id: 'createNewSolution',
    defaultMessage: 'Create new solution'
  },
  unpublishSolution: {
    id: 'unpublishSolution',
    defaultMessage: 'Unpublish solution'
  },
  publishSolution: {
    id: 'publishSolution',
    defaultMessage: 'Publish solution'
  },
  cancelSubmission: {
    id: 'cancelSubmission',
    defaultMessage: 'Cancel submission'
  },
  archiveSolution: {
    id: 'archiveSolution',
    defaultMessage: 'Delete solution'
  },
  showArchivedSolutions: {
    id: 'showArchivedSolutions',
    defaultMessage: 'Show archived solutions'
  },
  createNewListing: {
    id: 'createNewListing',
    defaultMessage: 'Create new listing'
  },
  solutions: {
    id: 'solutions',
    defaultMessage: 'Solutions'
  },
  addNewModuleVersion: {
    id: 'addNewModuleVersion',
    defaultMessage: 'Add a new Module Version'
  },
  importCustomModule: {
    id: 'importCustomModule',
    defaultMessage: 'Import a Custom Module'
  },
  versionUpload: {
    id: 'versionUpload',
    defaultMessage: 'Version Upload'
  },
  zipFile: {
    id: 'zipFile',
    defaultMessage: 'Zip File'
  },
  uploadAModule: {
    id: 'uploadAModule',
    defaultMessage:
      'Upload a Zip-File with the new module version. Make sure the version in package.json is updated correctly'
  },
  createNewVersion: {
    id: 'createNewVersion',
    defaultMessage: 'Create new version'
  },
  addModuleDesc: {
    id: 'addModuleDesc',
    defaultMessage:
      'To begin, your module needs a name and a short description.'
  },
  unarchiveProfile: {
    id: 'unarchiveProfile',
    defaultMessage: 'Unarchive profile'
  },
  archiveProfile: {
    id: 'archiveProfile',
    defaultMessage: 'Archive profile'
  },
  selectApplicationVersion: {
    id: 'selectApplicationVersion',
    defaultMessage: 'Select the Application Version'
  },
  required: {
    id: 'required',
    defaultMessage: 'Required'
  },
  activateProfiles: {
    id: 'activateProfiles',
    defaultMessage: 'Activate profile(s)'
  },
  changedTo: {
    id: 'changedTo',
    defaultMessage: 'Changed to'
  },
  modification: {
    id: 'modification',
    defaultMessage: 'Modification'
  },
  exportVersionLogCSV: {
    id: 'exportVersionLogCSV',
    defaultMessage: 'Export Version Log as CSV'
  },
  deleteSelectedVersions: {
    id: 'deleteSelectedVersions',
    defaultMessage: 'Delete Selected Version(s)'
  },
  open: {
    id: 'open',
    defaultMessage: 'Open'
  },
  noReportVersions: {
    id: 'noReportVersions',
    defaultMessage: 'No Report Versions...'
  },
  noDescription: {
    id: 'noDescription',
    defaultMessage: 'No description available'
  },
  reportId: {
    id: 'reportId',
    defaultMessage: 'Report ID'
  },
  creationDate: {
    id: 'creationDate',
    defaultMessage: 'Creation Date'
  },
  activity: {
    id: 'activity',
    defaultMessage: 'Last Activity'
  },
  relatedDevice: {
    id: 'relatedDevice',
    defaultMessage: 'Related Device'
  },
  versions: {
    id: 'versions',
    defaultMessage: 'Versions'
  },
  details: {
    id: 'details',
    defaultMessage: 'Details'
  },
  architecture: {
    id: 'architecture',
    defaultMessage: 'Architecture'
  },
  confirmRemoveImages: {
    id: 'confirmRemoveImages',
    defaultMessage: 'Confirm Remove Image(s)'
  },
  selectedImages: {
    id: 'selectedImages?',
    defaultMessage: 'selected images(s)?'
  },
  deviceImages: {
    id: 'deviceImages',
    defaultMessage: 'Device Images'
  },
  manageAndDownloadYourDevice: {
    id: 'manageAndDownloadYourDevice',
    defaultMessage: 'Manage and download your device images.'
  },
  addDeviceType: {
    id: 'addDeviceType',
    defaultMessage: 'Add Device Type'
  },
  removeImages: {
    id: 'removeImages',
    defaultMessage: 'Remove Images'
  },
  createNew: {
    id: 'createNew',
    defaultMessage: 'Create new'
  },
  addNew: {
    id: 'addNew',
    defaultMessage: 'Add new'
  },
  createYourFirstDeviceImage: {
    id: 'createYourFirstDeviceImage',
    defaultMessage: 'Create your first Device Image'
  },
  selectTheDeviceTypeToCreate: {
    id: 'selectTheDeviceTypeToCreate',
    defaultMessage:
      'Select the Device Type to create the image, download the image file and and flash it to your devices.'
  },
  selectDeviceType: {
    id: 'selectDeviceType',
    defaultMessage: 'Select Device Type'
  },
  deviceInformation: {
    id: 'deviceInformation',
    defaultMessage: 'Device Information'
  },
  licencePlate: {
    id: 'licencePlate',
    defaultMessage: 'Licence Plate'
  },
  base: {
    id: 'base',
    defaultMessage: 'Base'
  },
  vehicleNumber: {
    id: 'vehicleNumber',
    defaultMessage: 'Vehicle Number'
  },
  deviceGroups: {
    id: 'deviceGroups',
    defaultMessage: 'Device Groups'
  },
  deviceGroupsSubtitle: {
    id: 'deviceGroupsSubtitle',
    defaultMessage:
      'Manage the groups to which the current device is assigned to.'
  },
  deviceId: {
    id: 'deviceId',
    defaultMessage: 'Device ID'
  },
  noProfileAssigned: {
    id: 'noProfileAssigned',
    defaultMessage: 'No Profile assigned'
  },
  configureDeployedNodes: {
    id: 'configureDeployedNodes',
    defaultMessage: 'Configure deployed Nodes'
  },
  configure: {
    id: 'configure',
    defaultMessage: 'Configure'
  },
  configuredBy: {
    id: 'configuredBy',
    defaultMessage: 'Configured by'
  },
  configStatus: {
    id: 'configStatus',
    defaultMessage: 'Config Status'
  },
  deployedAt: {
    id: 'deployedAt',
    defaultMessage: 'Deployed at'
  },
  configureAt: {
    id: 'configureAt',
    defaultMessage: 'Configured at'
  },
  deployedApplication: {
    id: 'deployedApplication',
    defaultMessage: 'Deployed Application'
  },
  deviceHealth: {
    id: 'deviceHealth',
    defaultMessage: 'Device Health'
  },
  seeMetrics: {
    id: 'seeMetrics',
    defaultMessage: 'See metrics and analytics about this device'
  },
  show: {
    id: 'show',
    defaultMessage: 'Show'
  },
  deviceDescription: {
    id: 'deviceDescription',
    defaultMessage: 'Device Description'
  },
  nameIsRequired: {
    id: 'nameIsRequired',
    defaultMessage: 'Name is required'
  },
  descriptionIsRequired: {
    id: 'descriptionIsRequired',
    defaultMessage: 'Description is required'
  },
  usernameIsRequired: {
    id: 'usernameIsRequired',
    defaultMessage: 'Username is required'
  },
  createWorkspace: {
    id: 'createWorkspace',
    defaultMessage: 'Create Workspace'
  },
  flows: {
    id: 'flows',
    defaultMessage: 'Flows'
  },
  reporting: {
    id: 'reporting',
    defaultMessage: 'Reporting'
  },
  reports: {
    id: 'reports',
    defaultMessage: 'Reports'
  },
  createYourFirstApplication: {
    id: 'createYourFirstApplication',
    defaultMessage: 'Create your first AI Vision Application'
  },
  createYourFirstVideo: {
    id: 'createYourFirstVideo',
    defaultMessage: 'Add your first Video'
  },
  useTheEditorToCreate: {
    id: 'useTheEditorToCreate',
    defaultMessage:
      'Use the Editor to create Applications with your Modules. Configure, update and maintain the Application.'
  },
  createFirstApplication: {
    id: 'createFirstApplication',
    defaultMessage: 'Create first Application'
  },
  createNewApplication: {
    id: 'createNewApplication',
    defaultMessage: 'Create a new Application'
  },
  importFromFile: {
    id: 'importFromFile',
    defaultMessage: 'Import from File'
  },
  manageTemplates: {
    id: 'manageTemplates',
    defaultMessage: 'Manage Templates'
  },
  results: {
    id: 'results',
    defaultMessage: 'results'
  },
  removeApplication: {
    id: 'removeApplication',
    defaultMessage: 'Remove Application'
  },
  assignTheProfileToDevices: {
    id: 'assignTheProfileToDevices',
    defaultMessage:
      'Assign the profile to Devices, it gets deployed as soon as the device is online.'
  },
  archived: {
    id: 'archived',
    defaultMessage: 'Archived'
  },
  active: {
    id: 'active',
    defaultMessage: 'Active'
  },
  pleaseConfirm: {
    id: 'pleaseConfirm',
    defaultMessage: 'Please Confirm'
  },
  selectedProfiles: {
    id: 'selectedProfiles',
    defaultMessage: 'selected profile(s)?'
  },
  createNewProfile: {
    id: 'createNewProfile',
    defaultMessage: 'Create a new profile'
  },
  startDeployingApps: {
    id: 'startDeployingApps',
    defaultMessage: 'Start deploying your Apps'
  },
  createProfilesToManageDeployments: {
    id: 'createProfilesToManageDeployments',
    defaultMessage:
      'Using profiles, you’ll be able to deploy your applications to devices. Create profiles to manage deployments.'
  },
  createFirstProfile: {
    id: 'createFirstProfile',
    defaultMessage: 'Create first profile'
  },
  syncDevices: {
    id: 'syncDevices',
    defaultMessage: 'Sync Devices'
  },
  devicesByGroup: {
    id: 'devicesByGroup',
    defaultMessage: 'Devices By Group'
  },
  deviceList: {
    id: 'deviceList',
    defaultMessage: 'Device List'
  },
  deviceGroup: {
    id: 'deviceGroup',
    defaultMessage: 'Device Group'
  },
  showFilter: {
    id: 'showFilter',
    defaultMessage: 'Show Filter'
  },
  groupsAssigned: {
    id: 'groupsAssigned',
    defaultMessage: 'Groups assigned'
  },
  assignedRelease: {
    id: 'assignedRelease',
    defaultMessage: 'Assigned Release'
  },
  updateDevice: {
    id: 'updateDevice',
    defaultMessage: 'Update Device'
  },
  archiveDevice: {
    id: 'archiveDevice',
    defaultMessage: 'Archive Device'
  },
  macAddress: {
    id: 'macAddress',
    defaultMessage: 'MAC Address'
  },
  addedOn: {
    id: 'addedOn',
    defaultMessage: 'Added'
  },
  software: {
    id: 'software',
    defaultMessage: 'Software'
  },
  softwareRelease: {
    id: 'softwareRelease',
    defaultMessage: 'SW Release'
  },
  /* List & Detail Page */
  all: {
    id: 'all',
    defaultMessage: 'All'
  },
  online: {
    id: 'online',
    defaultMessage: 'online'
  },
  offline: {
    id: 'offline',
    defaultMessage: 'Offline'
  },
  allGroups: {
    id: 'allGroups',
    defaultMessage: 'All Groups'
  },
  rowsPerPage: {
    id: 'rowsPerPage',
    defaultMessage: 'Rows Per Page'
  },
  deviceOverview: {
    id: 'deviceOverview',
    defaultMessage: 'Device Overview'
  },
  id: {
    id: 'id',
    defaultMessage: 'ID'
  },
  selectGroup: {
    id: 'selectGroup',
    defaultMessage: 'Select Group'
  },
  add: {
    id: 'add',
    defaultMessage: 'Add'
  },
  addComment: {
    id: 'addComment',
    defaultMessage: 'Add Comment'
  },
  confirmation: {
    id: 'confirmation',
    defaultMessage: 'Confirmation'
  },
  removeDeviceconfirmationMessage: {
    id: 'removeDeviceconfirmationMessage',
    defaultMessage: 'Are you sure to remove this device?'
  },
  ok: {
    id: 'ok',
    defaultMessage: 'OK'
  },
  DRSSystem: {
    id: 'DRSSystem',
    defaultMessage: 'DRS-System'
  },
  reportEntries: {
    id: 'reportEntries',
    defaultMessage: 'Report Entries'
  },
  importBlueprint: {
    id: 'importBlueprint',
    defaultMessage: 'Import a Blueprint'
  },
  addInformation: {
    id: 'addInformation',
    defaultMessage: 'Create a new application'
  },
  addModule: {
    id: 'addModule',
    defaultMessage: 'Add module'
  },
  noApplication: {
    id: 'noApplication',
    defaultMessage: 'Currently no application available.'
  },
  noModule: {
    id: 'noModule',
    defaultMessage: 'Currently no module available.'
  },
  moduleCategory: {
    id: 'category',
    defaultMessage: 'Category'
  },
  moduleType: {
    id: 'type',
    defaultMessage: 'Type'
  },
  moduleModified: {
    id: 'modified',
    defaultMessage: 'Modified'
  },
  moduleInformation: {
    id: 'moduleInformation',
    defaultMessage: 'Module Information'
  },
  applicationId: {
    id: 'applicationId',
    defaultMessage: 'Application ID'
  },
  moduleAddInformation: {
    id: 'moduleAddInformation',
    defaultMessage: 'Please add information about the module to be created.'
  },
  moduleProvideInformation: {
    id: 'moduleProvideInformation',
    defaultMessage: 'Please provide information to import the module.'
  },
  removeFromLibrary: {
    id: 'removeFromLibrary',
    defaultMessage: 'Remove from library'
  },
  used: {
    id: 'used',
    defaultMessage: 'Used'
  },
  notInstalled: {
    id: 'notInstalled',
    defaultMessage: 'Not installed'
  },
  requiredModules: {
    id: 'requiredModules',
    defaultMessage: 'Required Modules'
  },
  applicationDependents: {
    id: 'applicationDependents',
    defaultMessage: 'Application Dependents'
  },
  dependents: {
    id: 'dependents',
    defaultMessage: 'Dependents'
  },
  currentApplicationProfiles: {
    id: 'currentApplicationProfiles',
    defaultMessage: 'The current application contains the following profiles'
  },
  applicationCommitHistory: {
    id: 'applicationCommitHistory',
    defaultMessage: 'Application Commit History'
  },
  applicationVersions: {
    id: 'applicationVersions',
    defaultMessage: 'Application Versions'
  },
  applicationVersion: {
    id: 'applicationVersion',
    defaultMessage: 'Application Version'
  },
  applicationHasVersion: {
    id: 'applicationHasVersion',
    defaultMessage:
      'The application has versions that can be assigned to a profile to deploy.'
  },
  manageApplicationFlow: {
    id: 'manageApplicationFlow',
    defaultMessage: 'Manage the application flow in the Viso Builder'
  },
  manageEncryptionOfApplication: {
    id: 'manageEncryptionOfApplication',
    defaultMessage: 'Manage the encryption of the application.'
  },
  latestVersion: {
    id: 'latestVersion',
    defaultMessage: 'Latest Version'
  },
  createFromTemplate: {
    id: 'createFromTemplate',
    defaultMessage: 'Create from Template'
  },
  deleteTemplate: {
    id: 'deleteTemplate',
    defaultMessage: 'Delete Template'
  },
  templateName: {
    id: 'templateName',
    defaultMessage: 'Template Name'
  },
  createdDate: {
    id: 'createdDate',
    defaultMessage: 'Created Date'
  },
  createNewApplicationStarting: {
    id: 'createNewApplicationStarting',
    defaultMessage:
      'Create a new application starting from scratch or with a template.'
  },
  buildApplicationFromScratch: {
    id: 'buildApplicationFromScratch',
    defaultMessage:
      'Build your application from scratch, start fresh with an empty canvas.'
  },
  fromTemplate: {
    id: 'fromTemplate',
    defaultMessage: 'From Template'
  },
  createFromTemplateDesc: {
    id: 'createFromTemplateDesc',
    defaultMessage:
      'Create your own application starting with a pre-made template.'
  },
  importFile: {
    id: 'importFile',
    defaultMessage: 'Import File'
  },
  importApplicationExported: {
    id: 'importApplicationExported',
    defaultMessage:
      'Import an Application that was exported from a Viso Workspace.'
  },
  importApplicationFile: {
    id: 'importApplicationFile',
    defaultMessage: 'Import an Application File'
  },
  importApplicationExportedFromWorkspace: {
    id: 'importApplicationExportedFromWorkspace',
    defaultMessage:
      'Import an Application that was previously exported from a Viso Workspace.'
  },
  importApplication: {
    id: 'importApplication',
    defaultMessage: 'Import Application'
  },
  applicationFile: {
    id: 'applicationFile',
    defaultMessage: 'Application File'
  },
  createAnApplicationFromScratch: {
    id: 'createAnApplicationFromScratch',
    defaultMessage: 'Create an Application from Scratch'
  },
  createAnApplicationFromScratchDesc: {
    id: 'createAnApplicationFromScratchDesc',
    defaultMessage:
      'To begin, your application needs a name and a short description.'
  },
  applicationTemplates: {
    id: 'applicationTemplates',
    defaultMessage: 'Application Templates'
  },
  yourApplicationTemplates: {
    id: 'yourApplicationTemplates',
    defaultMessage: 'Your Application Templates'
  },
  savingWillCreateANewApplicationVersion: {
    id: 'savingWillCreateANewApplicationVersion',
    defaultMessage:
      'Saving will create a new Application Version that can be assigned to a Profile for Deployment. Discard if you want to proceed without saving.'
  },
  applicationInformation: {
    id: 'applicationInformation',
    defaultMessage: 'Application Information'
  },
  applicationConfiguration: {
    id: 'applicationConfiguration',
    defaultMessage: 'Configuration'
  },
  modulesContained: {
    id: 'modulesContained',
    defaultMessage: 'Modules contained'
  },
  modulesDescription: {
    id: 'modulesDescription',
    defaultMessage:
      'Select Device Groups that are elements of the current scope in order to manage access and visibility.'
  },
  removeLibrary: {
    id: 'removeLibrary',
    defaultMessage: 'Remove from library'
  },
  newApplicationDescription: {
    id: 'newApplicationDescription',
    defaultMessage: 'Provide Information about the new Application.'
  },
  applicationConfigurationDes: {
    id: 'applicationConfigurationDes',
    defaultMessage: 'Build and Configure the new Application in the Editor.'
  },
  newApplication: {
    id: 'newApplication',
    defaultMessage: 'Application Information'
  },
  next: {
    id: 'next',
    defaultMessage: 'Next'
  },
  currentKeyIsRequired: {
    id: 'currentKeyIsRequired',
    defaultMessage: 'Current key is required'
  },
  newKeyIsRequired: {
    id: 'newKeyIsRequired',
    defaultMessage: 'New key is required'
  },
  saveApplicationAsTemplate: {
    id: 'saveApplicationAsTemplate',
    defaultMessage: 'Save Application as Template'
  },
  createApplicationAndReuse: {
    id: 'createApplicationAndReuse',
    defaultMessage: 'Create an Application Template that you can re-use'
  },
  createTemplate: {
    id: 'createTemplate',
    defaultMessage: 'Create Template'
  },
  importing: {
    id: 'importing',
    defaultMessage: 'Importing'
  },
  exportToFile: {
    id: 'exportToFile',
    defaultMessage: 'Export to File'
  },
  saveAsTemplate: {
    id: 'saveAsTemplate',
    defaultMessage: 'Save as Template'
  },
  exportVersionLogAsCSV: {
    id: 'exportVersionLogAsCSV',
    defaultMessage: 'Export Version Log as CSV'
  },
  importPublicModule: {
    id: 'importPublicModule',
    defaultMessage: 'Import a Public Module'
  },
  findPublicModuleToImport: {
    id: 'findPublicModuleToImport',
    defaultMessage: 'Find a public module to import to your workspace library.'
  },
  install: {
    id: 'install',
    defaultMessage: 'Install'
  },
  addNewModule: {
    id: 'addNewModule',
    defaultMessage: 'Add a new Module'
  },
  addModuelToLibrary: {
    id: 'addModuelToLibrary',
    defaultMessage:
      'Add modules to your library to use them building applications.'
  },
  publicModule: {
    id: 'publicModule',
    defaultMessage: 'Public Module'
  },
  whatAreYouLooking: {
    id: 'whatAreYouLooking',
    defaultMessage: 'What are you looking for?'
  },
  phraseToSecureYourCredentials: {
    id: 'phraseToSecureYourCredentials',
    defaultMessage: 'A phrase to secure your credentials with'
  },
  linkedNode: {
    id: 'linkedNode',
    defaultMessage: 'Linked Node'
  },
  dockerImageURI: {
    id: 'dockerImageURI',
    defaultMessage: 'Docker Image URI'
  },
  currentModuleContainsDockerImages: {
    id: 'currentModuleContainsDockerImages',
    defaultMessage: 'The current module contains the following docker images.'
  },
  dependencies: {
    id: 'dependencies',
    defaultMessage: 'Dependencies'
  },
  moduleDependents: {
    id: 'moduleDependents',
    defaultMessage: 'Module Dependents'
  },
  currentApplicationProfile: {
    id: 'currentApplicationProfile',
    defaultMessage: 'The current application is part of the following profiles.'
  },
  nodesOfModule: {
    id: 'nodesOfModule',
    defaultMessage: 'Nodes of the Module'
  },
  nodeName: {
    id: 'nodeName',
    defaultMessage: 'Node Name'
  },
  nodesContainedModule: {
    id: 'nodesContainedModule',
    defaultMessage:
      'Nodes contained in the Module, building blocks in the Viso Builder'
  },
  nameOfContainer: {
    id: 'nameOfContainer',
    defaultMessage: 'Name of the container'
  },
  containerType: {
    id: 'containerType',
    defaultMessage: 'Container Type'
  },
  secretAccessKey: {
    id: 'secretAccessKey',
    defaultMessage: 'Secret Access Key'
  },
  accessKeyID: {
    id: 'accessKeyID',
    defaultMessage: 'Access Key ID'
  },
  copy: {
    id: 'copy',
    defaultMessage: 'Copy'
  },
  pushCommandsFor: {
    id: 'pushCommandsFor',
    defaultMessage: 'Push commands for'
  },
  moduleVersions: {
    id: 'moduleVersions',
    defaultMessage: 'Module Versions'
  },
  update: {
    id: 'update',
    defaultMessage: 'Update'
  },
  currentApplicationModules: {
    id: 'currentApplicationModules',
    defaultMessage: 'The current application contains the following modules'
  },
  cNew: {
    id: 'cNew',
    defaultMessage: 'NEW'
  },
  manageTheEncryptionApplication: {
    id: 'manageTheEncryptionApplication',
    defaultMessage: 'Manage the encryption of the application.'
  },
  currentVersion: {
    id: 'currentVersion',
    defaultMessage: 'Current Version'
  },
  moduleSettings: {
    id: 'moduleSettings',
    defaultMessage: 'Module Settings'
  },
  removeModule: {
    id: 'removeModule',
    defaultMessage: 'Remove Module'
  },
  whenYouRemoveTheModule: {
    id: 'whenYouRemoveTheModule',
    defaultMessage:
      'When you remove the module from your Library, you will not be able to use it in the Viso Builder or deploy Applications that have this Module as a Dependency.'
  },
  updateAvailable: {
    id: 'updateAvailable',
    defaultMessage: 'Update available'
  },
  yourApplicationNeedsAName: {
    id: 'yourApplicationNeedsAName',
    defaultMessage:
      'To begin, your application needs a name and a short description.'
  },
  removeModuleFromLibrary: {
    id: 'removeModuleFromLibrary',
    defaultMessage:
      'When you remove the module from your Library, you will not be able to use it in the Viso Builder or deploy Applications that have this Module as a Dependency.'
  },
  superchargeYourEditor: {
    id: 'superchargeYourEditor',
    defaultMessage: 'Supercharge your Editor'
  },
  moduleToBuildApplications: {
    id: 'moduleToBuildApplications',
    defaultMessage:
      'Modules can be used in the Editor to build Applications. Search available Modules or create own Modules.'
  },
  addNewVersion: {
    id: 'addNewVersion',
    defaultMessage: 'Add new version'
  },
  workspaceLogin: {
    id: 'workspaceLogin',
    defaultMessage: 'Workspace Login'
  },
  signInToYourWorkspace: {
    id: 'signInToYourWorkspace',
    defaultMessage: 'Sign in to your workspace'
  },
  email: {
    id: 'email',
    defaultMessage: 'Email'
  },
  login: {
    id: 'login',
    defaultMessage: 'Login'
  },
  refreshFrame: {
    id: 'refreshFrame',
    defaultMessage: 'Refresh Frame'
  },
  urlNotFound: {
    id: 'urlNotFound',
    defaultMessage: 'URL not found...'
  },
  localConfiguration: {
    id: 'localConfiguration',
    defaultMessage: 'Local Configuration'
  },
  keepRatio: {
    id: 'keepRatio',
    defaultMessage: 'Keep Ratio'
  },
  vendorContact: {
    id: 'vendorContact',
    defaultMessage: 'Vendor Contact'
  },
  primaryPersonContacted: {
    id: 'primaryPersonContacted',
    defaultMessage: 'The primary person to be contacted.'
  },
  salesHistory: {
    id: 'salesHistory',
    defaultMessage: 'Sales History'
  },
  estimatedRevenue: {
    id: 'estimatedRevenue',
    defaultMessage: 'Estimated Revenue'
  },
  excludeArea: {
    id: 'excludeArea',
    defaultMessage: 'Exclude Area'
  },
  fileName: {
    id: 'fileName',
    defaultMessage: 'Filename'
  },
  fileURL: {
    id: 'fileURL',
    defaultMessage: 'File URL'
  },
  healthCheck: {
    id: 'healthCheck',
    defaultMessage: 'Health Check'
  },
  lastScan: {
    id: 'lastScan',
    defaultMessage: 'Last Scan'
  },
  scanAgain: {
    id: 'scanAgain',
    defaultMessage: 'Scan again'
  },
  deviceHealthCheck: {
    id: 'deviceHealthCheck',
    defaultMessage: 'Device Health Check'
  },
  deviceAnalytics: {
    id: 'deviceAnalytics',
    defaultMessage: 'Device Analytics'
  },
  editVideoInformation: {
    id: 'editVideoInformation',
    defaultMessage: 'Edit Video Information'
  },
  endpointsMinimum: {
    id: 'endpointsMinimum',
    defaultMessage: 'Endpoints Minimum'
  },
  planMustBeSelected: {
    id: 'planMustBeSelected',
    defaultMessage: 'Plan must be selected before creating the workspace.'
  },
  errorWhileSubmittingBilling: {
    id: 'errorWhileSubmittingBilling',
    defaultMessage: 'Error while submitting the billing'
  },
  failedToCreateAnImage: {
    id: 'failedToCreateAnImage',
    defaultMessage: 'Failed to create an image.'
  },
  deviceImageUnderCreation: {
    id: 'deviceImageUnderCreation',
    defaultMessage: 'The Device Image is under creation and will be ready soon.'
  },
  failedToFetchUrl: {
    id: 'failedToFetchUrl',
    defaultMessage: 'Failed to fetch the downloadable url'
  },
  devicesAssigned: {
    id: 'devicesAssigned',
    defaultMessage: 'Devices assigned'
  },
  errorCreatingRelease: {
    id: 'errorCreatingRelease',
    defaultMessage: 'Error creating a release'
  },
  passed: {
    id: 'passed',
    defaultMessage: 'Passed'
  },
  failed: {
    id: 'failed',
    defaultMessage: 'Failed'
  },
  devicesSync: {
    id: 'devicesSync',
    defaultMessage: 'Devices synced failed!'
  },
  failedToDeleteProfile: {
    id: 'failedToDeleteProfile',
    defaultMessage: 'Failed to delete the profile'
  },
  failedToArchiveProfile: {
    id: 'failedToArchiveProfile',
    defaultMessage: 'Failed to archive the profile'
  },
  failedToUnarchiveProfile: {
    id: 'failedToUnarchiveProfile',
    defaultMessage: 'Failed to unarchive the profile'
  },
  failedToDuplicateProfile: {
    id: 'failedToDuplicateProfile',
    defaultMessage: 'Failed to duplicate a profile'
  },
  newProfileAdded: {
    id: 'newProfileAdded',
    defaultMessage: 'A new profile is added'
  },
  failedToActivateProfile: {
    id: 'failedToActivateProfile',
    defaultMessage: 'Failed to activate the profile'
  },
  failedToRemoveImages: {
    id: 'failedToRemoveImages',
    defaultMessage: 'Failed to remove the image(s)'
  },
  available: {
    id: 'available',
    defaultMessage: 'Available'
  },
  terminal: {
    id: 'terminal',
    defaultMessage: 'Terminal'
  },
  activated: {
    id: 'activated',
    defaultMessage: 'Activated'
  },
  supportedVisoOSDetected: {
    id: 'supportedVisoOSDetected',
    defaultMessage: 'Supported visoOS version detected.'
  },
  noUnderVoltage: {
    id: 'noUnderVoltage',
    defaultMessage: 'No under voltage events have been detected.'
  },
  overMemoryAvailable: {
    id: 'overMemoryAvailable',
    defaultMessage: 'Over 75% of total memory is currently available.'
  },
  noAbnormalTemperature: {
    id: 'noAbnormalTemperature',
    defaultMessage: 'No abnormal CPU temperature detected.'
  },
  allContainersRunning: {
    id: 'allContainersRunning',
    defaultMessage: 'All containers are running as expected'
  },
  supervisorRunning: {
    id: 'supervisorRunning',
    defaultMessage: 'Supervisor is running and healthy.'
  },
  noNetworkIssues: {
    id: 'noNetworkIssues',
    defaultMessage: 'No network issues detected.'
  },
  diskspaceAvailable: {
    id: 'diskspaceAvailable',
    defaultMessage: 'More than 30% diskspace is available.'
  },
  allServicesRunning: {
    id: 'allServicesRunning',
    defaultMessage: 'All services are running and healthy.'
  },
  deviceTimeSynchronized: {
    id: 'deviceTimeSynchronized',
    defaultMessage: 'Device time is synchronized with the NTP server.'
  },
  noCameraIssues: {
    id: 'noCameraIssues',
    defaultMessage: 'No camera issues have been detected.'
  },
  operatingSystem: {
    id: 'operatingSystem',
    defaultMessage: 'Operating System'
  },
  voltage: {
    id: 'voltage',
    defaultMessage: 'Voltage'
  },
  memory: {
    id: 'memory',
    defaultMessage: 'Memory'
  },
  temperature: {
    id: 'temperature',
    defaultMessage: 'Temperature'
  },
  containerEngine: {
    id: 'containerEngine',
    defaultMessage: 'Container Engine'
  },
  supervisor: {
    id: 'supervisor',
    defaultMessage: 'Supervisor'
  },
  networking: {
    id: 'networking',
    defaultMessage: 'Networking'
  },
  diskSpace: {
    id: 'diskSpace',
    defaultMessage: 'Disk Space'
  },
  restartingService: {
    id: 'restartingService',
    defaultMessage: 'Restarting Service'
  },
  timeSync: {
    id: 'timeSync',
    defaultMessage: 'Time Sync'
  },
  cameraHealth: {
    id: 'cameraHealth',
    defaultMessage: 'Camera Health'
  },
  containerStatus: {
    id: 'containerStatus',
    defaultMessage: 'Container Status'
  },
  scanCompletedSuccessfully: {
    id: 'scanCompletedSuccessfully',
    defaultMessage: 'Scan completed successfully'
  },
  failedToUnassignProfile: {
    id: 'failedToUnassignProfile',
    defaultMessage: 'Failed to unassign profile'
  },
  applicationRemovedSuccessfully: {
    id: 'applicationRemovedSuccessfully',
    defaultMessage: 'Application removed successfully'
  },
  debugging: {
    id: 'debugging',
    defaultMessage: 'Debugging'
  },
  toolsToDebug: {
    id: 'toolsToDebug',
    defaultMessage: 'Tools to debug this device.'
  },
  failedToFetchTheProfiles: {
    id: 'failedToFetchTheProfiles',
    defaultMessage: 'Failed to fetch the profiles'
  },
  upgradeModuleVersion: {
    id: 'upgradeModuleVersion',
    defaultMessage: 'Upgrade module version'
  },
  invalidFileType: {
    id: 'invalidFileType',
    defaultMessage: 'Invalid file type. Supports only mp4 videos.'
  },
  maximumFileSize: {
    id: 'maximumFileSize',
    defaultMessage: 'Maximum file size exceeded. Video size larger than 100 mb.'
  },
  mustContainOnly: {
    id: 'mustContainOnly',
    defaultMessage: 'Must contain only A-Z 0-9 _-'
  },
  unableToDeleteImage: {
    id: 'unableToDeleteImage',
    defaultMessage: 'Unable to delete the image'
  },
  linkNodeToContainer: {
    id: 'linkNodeToContainer',
    defaultMessage: 'Link a node to a container'
  },
  moduleInstalled: {
    id: 'moduleInstalled',
    defaultMessage: 'Module installed successfully.'
  },
  moduleUpdated: {
    id: 'moduleUpdated',
    defaultMessage: 'Module updated successfully.'
  },
  moduleInstallFailed: {
    id: 'moduleInstallFailed',
    defaultMessage: 'Module install failed.'
  },
  moduleUpdateFailed: {
    id: 'moduleUpdateFailed',
    defaultMessage: 'Module update failed.'
  },
  moduleRemoved: {
    id: 'moduleRemoved',
    defaultMessage: 'Module removed successfully.'
  },
  failedToRemoveModule: {
    id: 'failedToRemoveModule',
    defaultMessage: 'Failed to remove the module.'
  },
  setPricingSolution: {
    id: 'setPricingSolution',
    defaultMessage: 'Set the Pricing of your Solution'
  },
  makeSolutionFree: {
    id: 'makeSolutionFree',
    defaultMessage: 'Make your solution available for Free'
  },
  clickToCreatePrice: {
    id: 'clickToCreatePrice',
    defaultMessage: 'Click to create a Price'
  },
  monthlyPrice: {
    id: 'monthlyPrice',
    defaultMessage: 'Monthly Price'
  },
  setFeeMonthly: {
    id: 'setFeeMonthly',
    defaultMessage: 'Set the fee that applies on monthly payment.'
  },
  seeFeeYearly: {
    id: 'seeFeeYearly',
    defaultMessage: 'Set the fee that applies on yearly payment.'
  },
  yearlyPrice: {
    id: 'yearlyPrice',
    defaultMessage: 'Yearly Price'
  },
  viewUserGuide: {
    id: 'viewUserGuide',
    defaultMessage: 'View user guide'
  },
  signInInstall: {
    id: 'signInInstall',
    defaultMessage: 'Sign in to install'
  },
  reviews: {
    id: 'reviews',
    defaultMessage: 'Reviews'
  },
  moduleOverview: {
    id: 'moduleOverview',
    defaultMessage: 'Module Overview'
  },
  contact: {
    id: 'contact',
    defaultMessage: 'Contact'
  },
  emailSupport: {
    id: 'emailSupport',
    defaultMessage: 'Email support'
  },
  companyWebsite: {
    id: 'companyWebsite',
    defaultMessage: 'Company website'
  },
  supportWebsite: {
    id: 'supportWebsite',
    defaultMessage: 'Support website'
  },
  integrationRequires: {
    id: 'integrationRequires',
    defaultMessage: 'is provided by a third-party and is governed by separate'
  },
  moreModulesIn: {
    id: 'moreModulesIn',
    defaultMessage: 'More modules in'
  },
  confirmDelete: {
    id: 'confirmDelete',
    defaultMessage: 'Confirm Delete'
  },
  deleteSolution: {
    id: 'deleteSolution',
    defaultMessage: 'Are you sure you want to delete this solution.'
  },
  solutionInReview: {
    id: 'solutionInReview',
    defaultMessage: 'Solution is in review'
  },
  applicationInReview: {
    id: 'applicationInReview',
    defaultMessage: 'Your application is being reviewed. Please try back later.'
  },
  nameOfSolution: {
    id: 'nameOfSolution',
    defaultMessage: 'Name of Solution'
  },
  addCategory: {
    id: 'addCategory',
    defaultMessage: 'Add a category'
  },
  solutionLogo: {
    id: 'solutionLogo',
    defaultMessage: 'Solution Logo'
  },
  completeListing: {
    id: 'completeListing',
    defaultMessage: 'Complete Listing'
  },
  confirmRequirements: {
    id: 'confirmRequirements',
    defaultMessage: 'Confirm requirements'
  },
  submitSolutionDesc: {
    id: 'submitSolutionDesc',
    defaultMessage: 'Submit the solution to be publicly listed to the marketplace. Please make sure the submission is in line with the guidelines and fulfils the requirements. After your solution was listed, you can manage it in the your partner portal.'
  },
  byClicking: {
    id: 'byClicking',
    defaultMessage: 'By clicking'
  },
  youAgreeTo: {
    id: 'youAgreeTo',
    defaultMessage: 'you agree to'
  },
  submitForReview: {
    id: 'submitForReview',
    defaultMessage: 'Submit for Review'
  },
  saveAsDraft: {
    id: 'saveAsDraft',
    defaultMessage: 'Save as draft'
  },
  ourVendorPolicyAnd: {
    id: 'ourVendorPolicyAnd',
    defaultMessage: 'our Vendor Policy and'
  },
  solutionPricing: {
    id: 'solutionPricing',
    defaultMessage: 'Solution Pricing'
  },
  everyTierIsFilled: {
    id: 'everyTierIsFilled',
    defaultMessage: 'Every tier is filled up at its pricing before the next tier is reached.'
  },
  addAnotherTier: {
    id: 'addAnotherTier',
    defaultMessage: 'Add another tier'
  },
  pricingSettings: {
    id: 'pricingSettings',
    defaultMessage: 'Pricing Settings'
  },
  free: {
    id: 'free',
    defaultMessage: 'Free'
  },
  cancelSubmissionDesc: {
    id: 'cancelSubmissionDesc',
    defaultMessage: 'Are you sure you want to cancel the submission?'
  },
  addAVersion: {
    id: 'addAVersion',
    defaultMessage: 'Add a Version'
  },
  selectFromLibrary: {
    id: 'selectFromLibrary',
    defaultMessage: 'Select from Library'
  },
  uninstallingTheModule: {
    id: 'uninstallingTheModule',
    defaultMessage: 'Uninstalling the Module'
  },
  workEmail: {
    id: 'workEmail',
    defaultMessage: 'Work Email'
  },
  marketplaceInstalled: {
    id: 'marketplaceInstalled',
    defaultMessage: 'Marketplace installed successfully'
  },
  marketplaceInstallFailed: {
    id: 'marketplaceInstallFailed',
    defaultMessage: 'Marketplace installed failed'
  },
  marketplaceRemoved: {
    id: 'marketplaceRemoved',
    defaultMessage: 'Marketplace removed successfully'
  },
  passwordResetSuccessfully: {
    id: 'passwordResetSuccessfully',
    defaultMessage: 'Password reset successfully. Please login'
  },
  installingMarketplaceToWorkspace: {
    id: 'installingMarketplaceToWorkspace',
    defaultMessage: 'Installing marketplace to your workspace'
  },
  errorOpeningTheNodeEditor: {
    id: 'errorOpeningTheNodeEditor',
    defaultMessage: 'Viso builder is not available now. Please try again later.'
  },
  solutionUpdatedSuccessfully: {
    id: 'solutionUpdatedSuccessfully',
    defaultMessage: 'Solution updated successfully'
  },
  solutionLogoUploaded: {
    id: 'solutionLogoUploaded',
    defaultMessage: 'Solution logo uploaded successfully.'
  },
  solutionSubmittedSuccessfully: {
    id: 'solutionSubmittedSuccessfully',
    defaultMessage: 'Solution submitted successfully'
  },
  partnerApplicationSubmitted: {
    id: 'partnerApplicationSubmitted',
    defaultMessage: 'Partner application submitted.'
  },
  priceSetAsDefault: {
    id: 'priceSetAsDefault',
    defaultMessage: 'Price set as default.'
  },
  solutionDeletedSuccessfully: {
    id: 'solutionDeletedSuccessfully',
    defaultMessage: 'Solution deleted successfully'
  },
  submissionCancelledSuccessfully: {
    id: 'submissionCancelledSuccessfully',
    defaultMessage: 'Submission cancelled successfully'
  },
  failedToFetchTHeSSHKeys: {
    id: 'failedToFetchTHeSSHKeys',
    defaultMessage: 'Failed to fetch the SSH Keys'
  },
  successfullyCreatedAPublicContainer: {
    id: 'successfullyCreatedAPublicContainer',
    defaultMessage: 'Successfully created a public docker container.'
  },
  successfullyCreatedAPrivateDockerContainer: {
    id: 'successfullyCreatedAPrivateDockerContainer',
    defaultMessage: 'Successfully created a private docker container.'
  },
  applicationImported: {
    id: 'applicationImported',
    defaultMessage: 'Application imported successfully.'
  },
  commitPushedSuccessfully: {
    id: 'commitPushedSuccessfully',
    defaultMessage: 'Commit pushed successfully.'
  },
  videoImported: {
    id: 'videoImported',
    defaultMessage: 'Video imported successfully.'
  },
  updatedSuccessfully: {
    id: 'updatedSuccessfully',
    defaultMessage: 'Updated successfully'
  },
  newApplicationCreated: {
    id: 'newApplicationCreated',
    defaultMessage: 'Application created successfully.'
  },
  newVideoCreated: {
    id: 'newVideoCreated',
    defaultMessage: 'New video created successfully.'
  },
  videoUpdated: {
    id: 'videoUpdated',
    defaultMessage: 'Video updated successfully.'
  },
  videoDeleted: {
    id: 'videoDeleted',
    defaultMessage: 'Video deleted successfully.'
  },
  packageAdded: {
    id: 'packageAdded',
    defaultMessage: 'Package added successfully.'
  },
  videosAdded: {
    id: 'videosAdded',
    defaultMessage: 'Video added successfully.'
  },
  dashboardAdded: {
    id: 'dashboardAdded',
    defaultMessage: 'Dashboard added successfully.'
  },
  moduleDependencies: {
    id: 'moduleDependencies',
    defaultMessage: 'Module Dependencies'
  },
  showInstruction: {
    id: 'showInstruction',
    defaultMessage: 'Show instruction'
  },
  linkContainerToNode: {
    id: 'linkContainerToNode',
    defaultMessage: 'Link a container to a node'
  },
  pricesToWorkspaceSubscribe: {
    id: 'pricesToWorkspaceSubscribe',
    defaultMessage: 'Prices to which workspaces can subscribe to.'
  },
  demoVideos: {
    id: 'demoVideos',
    defaultMessage: 'Demo Videos'
  },
  videoFilesThatWillBeImported: {
    id: 'videoFilesThatWillBeImported',
    defaultMessage: 'Video files that will be imported to the workspace library.'
  },
  demoVideoFiles: {
    id: 'demoVideoFiles',
    defaultMessage: 'Demo Video Files'
  },
  setAsDefault: {
    id: 'setAsDefault',
    defaultMessage: 'Set as Default'
  },
  setAPrice: {
    id: 'setAPrice',
    defaultMessage: 'Set a price'
  },
  setFee: {
    id: 'setFee',
    defaultMessage: 'Set the fee that applies on payment.'
  },
  developer: {
    id: 'developer',
    defaultMessage: 'Developer'
  },
  perUnit: {
    id: 'perUnit',
    defaultMessage: 'Per Unit'
  },
  perEndpoint: {
    id: 'perEndpoint',
    defaultMessage: 'per endpoint'
  },
  deleteDevice: {
    id: 'deleteDevice',
    defaultMessage: 'Delete device'
  },
  supportInformation: {
    id: 'supportInformation',
    defaultMessage: 'Support Information'
  },
  startSession: {
    id: 'startSession',
    defaultMessage: 'Start Session'
  },
  workspaceCustomization: {
    id: 'workspaceCustomization',
    defaultMessage: 'Workspace Customization'
  },
  addFirstDevices: {
    id: 'addFirstDevices',
    defaultMessage: 'Add First Device'
  },
  inviteNewTeamMembers: {
    id: 'inviteNewTeamMembers',
    defaultMessage: 'Invite new team members'
  },
  inviteOthersToJoin: {
    id: 'inviteOthersToJoin',
    defaultMessage: 'Invite others to join the workspace and collaborate. People you invite will receive an email to join.'
  },
  invitePeople: {
    id: 'invitePeople',
    defaultMessage: 'Invite People'
  },
  devicesSyncedSuccessfully: {
    id: 'devicesSyncedSuccessfully',
    defaultMessage: 'Devices synced successfully!'
  },
  devicesSyncedFailed: {
    id: 'devicesSyncedFailed',
    defaultMessage: 'Devices synced failed!'
  },
  profileRemovedSuccessfully: {
    id: 'profileRemovedSuccessfully',
    defaultMessage: 'Profile removed successfully.'
  },
  failedCreatingARelease: {
    id: 'failedCreatingARelease',
    defaultMessage: 'Failed creating a release'
  },
  confirmRemoveProfile: {
    id: 'confirmRemoveProfile',
    defaultMessage: 'Confirm Remove Profile'
  },
  cWorkspace: {
    id: 'cWorkspace',
    defaultMessage: 'Workspace'
  },
  keepPlan: {
    id: 'keepPlan',
    defaultMessage: 'Keep plan'
  },
  sEndpoints: {
    id: 'sEndpoints',
    defaultMessage: 'endpoints'
  },
  for: {
    id: 'for',
    defaultMessage: 'for'
  },
  monthlyPerEndpoint: {
    id: 'monthlyPerEndpoint',
    defaultMessage: 'monthly per endpoint'
  },
  isTheMinimumAmount: {
    id: 'isTheMinimumAmount',
    defaultMessage: 'is the minimum amount'
  },
  viewRequiredModules: {
    id: 'viewRequiredModules',
    defaultMessage: 'View required Modules'
  },
  proceed: {
    id: 'proceed',
    defaultMessage: 'Proceed'
  },
  loggingOutToPurchase: {
    id: 'loggingOutToPurchase',
    defaultMessage: 'Logging out to setup the purchase. Please re-login'
  },
  intallingFromMarketplaceCompleted: {
    id: 'intallingFromMarketplaceCompleted',
    defaultMessage: 'Installing from Marketplace completed'
  },
  congratulationsListingInstalled: {
    id: 'congratulationsListingInstalled',
    defaultMessage: 'Congratulations! The item was successfully installed with your workspace and is now ready to use.'
  },
  viewInSubscriptions: {
    id: 'viewInSubscriptions',
    defaultMessage: 'View in Subscriptions'
  },
  currentApplicationRequiresTheFollowingModules: {
    id: 'currentApplicationRequiresTheFollowingModules',
    defaultMessage: 'The current application requires the following modules.'
  },
  contrast: {
    id: 'contrast',
    defaultMessage: 'Contrast'
  },
  selectedUser: {
    id: 'selectedUser',
    defaultMessage: 'selected user(s)?'
  },
  sureActivate: {
    id: 'sureActivate',
    defaultMessage: 'Are you sure to activate the'
  },
  sureDeactivate: {
    id: 'sureDeactivate',
    defaultMessage: 'Are you sure to deactivate the'
  },
  sureArchive: {
    id: 'sureArchive',
    defaultMessage: 'Are you sure to archive the'
  },
  sureAssignRole: {
    id: 'sureAssignRole',
    defaultMessage: 'Are you sure to assign the role'
  },
  toThe: {
    id: 'toThe',
    defaultMessage: 'to the'
  },
  cPrimaryOwner: {
    id: 'cPrimaryOwner',
    defaultMessage: 'PRIMARY OWNER'
  },
  confirmCancelInvitations: {
    id: 'confirmCancelInvitations',
    defaultMessage: 'Confirm Cancel Invitations'
  },
  sureCancelInvitation: {
    id: 'sureCancelInvitation',
    defaultMessage: 'Are you sure to cancel the invitation to the'
  },
  sureResendInvitation: {
    id: 'sureResendInvitation',
    defaultMessage: 'Are you sure to resend the invitation to the'
  },
  confirmResendInvitations: {
    id: 'confirmResendInvitations',
    defaultMessage: 'Confirm Resend Invitations'
  },
  workspaceInvitations: {
    id: 'workspaceInvitations',
    defaultMessage: 'Workspace Invitations'
  },
  rolePermissions: {
    id: 'rolePermissions',
    defaultMessage: 'Role Permissions'
  },
  permissionsSavedSuccessfully: {
    id: 'permissionsSavedSuccessfully',
    defaultMessage: 'Permissions saved successfully'
  },
  roleInformation: {
    id: 'roleInformation',
    defaultMessage: 'Role Information'
  },
  solutionPrices: {
    id: 'solutionPrices',
    defaultMessage: 'Solution Prices'
  },
  noDescriptionAdded: {
    id: 'noDescriptionAdded',
    defaultMessage: 'No description added'
  },
  applicationOverview: {
    id: 'applicationOverview',
    defaultMessage: 'Application Overview'
  },
  moreApplicationsIn: {
    id: 'moreApplicationsIn',
    defaultMessage: 'More applications in'
  },
  marketingInformation: {
    id: 'marketingInformation',
    defaultMessage: 'Marketing Information'
  },
  keywords: {
    id: 'keywords',
    defaultMessage: 'Keywords'
  },
  addKeyword: {
    id: 'addKeyword',
    defaultMessage: 'Add keyword'
  },
  filetype: {
    id: 'filetype',
    defaultMessage: 'Filetype'
  },
  repeatVideo: {
    id: 'repeatVideo',
    defaultMessage: 'Repeat Video'
  },
  deleteAll: {
    id: 'deleteAll',
    defaultMessage: 'Delete all'
  },
  remainingTasks: {
    id: 'remainingTasks',
    defaultMessage: 'Remaining tasks'
  },
  congratulationsSetupComplete: {
    id: 'congratulationsSetupComplete',
    defaultMessage: 'Congratulations, the Setup is now complete'
  },
  clickToRemove: {
    id: 'clickToRemove',
    defaultMessage: 'Click to remove'
  },
  timezone: {
    id: 'timezone',
    defaultMessage: 'Timezone'
  },
  preferences: {
    id: 'preferences',
    defaultMessage: 'Preferences'
  },
  workspaceChangesSaved: {
    id: 'workspaceChangesSaved',
    defaultMessage: 'Workspace changes saved.'
  },
  logoUploadedSuccessfully: {
    id: 'logoUploadedSuccessfully',
    defaultMessage: 'Logo uploaded successfully.'
  },
  logoRemovedSuccessfully: {
    id: 'logoRemovedSuccessfully',
    defaultMessage: 'Logo removed successfully.'
  },
  workspaeRecoveredSuccessfully: {
    id: 'workspaeRecoveredSuccessfully',
    defaultMessage: 'Workspace recovered successfully.'
  },
  workspaceSubscriptionCancelled: {
    id: 'workspaceSubscriptionCancelled',
    defaultMessage: 'Workspace subscription cancelled. Logging out..'
  },
  multipleUser: {
    id: 'multipleUser',
    defaultMessage: 'Multiple User'
  },
  createNewPassword: {
    id: 'createNewPassword',
    defaultMessage: 'Create a new password for your account'
  },
  getStartedImmediatelySubscription: {
    id: 'getStartedImmediatelySubscription',
    defaultMessage:
      'Get started immediately and access all features offered by this subscription. You’ll be automatically be charged the monthly cost. Manage the subscription directly in the Billing Center of your Workspace.'
  },
  fullName: {
    id: 'fullName',
    defaultMessage: 'Full Name'
  },
  createYourFirstVideoDesc: {
    id: 'createYourFirstVideoDesc',
    defaultMessage: 'Add your first Video here to be used as a video source in your Applications'
  },
  downloadingTheDeviceImage: {
    id: 'downloadingTheDeviceImage',
    defaultMessage: 'Downloading the device image...'
  },
  interfaceLanguage: {
    id: 'interfaceLanguage',
    defaultMessage: 'Interface Language'
  },
  vatNumber: {
    id: 'vatNumber',
    defaultMessage: 'VAT Number'
  },
  yourPartnerApplicationUnderReview: {
    id: 'yourPartnerApplicationUnderReview',
    defaultMessage: 'Your partner application is under review'
  },
  currentlyReviewing: {
    id: 'currentlyReviewing',
    defaultMessage: 'The Viso Team is currently reviewing your application. We will notify you.'
  },
  feelFreeToReachOut: {
    id: 'feelFreeToReachOut',
    defaultMessage: 'If you have any questions, feel free to reach out.'
  },
  serviceCurrentlyDeactivated: {
    id: 'serviceCurrentlyDeactivated',
    defaultMessage: 'This service is currently deactivated.'
  },
  adminDeactivatedService: {
    id: 'adminDeactivatedService',
    defaultMessage: 'Admin deactivated this service.'
  },
  signUpToBecomePartner: {
    id: 'signUpToBecomePartner',
    defaultMessage: 'Sign up to become a Viso Partner'
  },
  applicationRejected: {
    id: 'applicationRejected',
    defaultMessage: 'Your application has been rejected. Please re-send again'
  },
  shareAndSellModuleAndApplications: {
    id: 'shareAndSellModuleAndApplications',
    defaultMessage: 'Share and sell modules and applications from your library to the Marketplace.'
  },
  applyNow: {
    id: 'applyNow',
    defaultMessage: 'Apply now'
  },
  readThe: {
    id: 'readThe',
    defaultMessage: 'Read the'
  },
  andAgreeToThe: {
    id: 'andAgreeToThe',
    defaultMessage: 'and agree to the'
  },
  partnerRequirements: {
    id: 'partnerRequirements',
    defaultMessage: 'Partner Requirements'
  },
  partnerAgreement: {
    id: 'partnerAgreement',
    defaultMessage: 'Partner Agreement'
  },
  companyAddress: {
    id: 'companyAddress',
    defaultMessage: 'Company Address'
  },
  logo: {
    id: 'logo',
    defaultMessage: 'Logo'
  },
  dragFileHere: {
    id: 'dragFileHere',
    defaultMessage: 'Drag file here'
  },
  oneTimeFee: {
    id: 'oneTimeFee',
    defaultMessage: 'One Time Fee'
  },
  one_TimeFee: {
    id: 'one_TimeFee',
    defaultMessage: 'One-Time Fee'
  },
  setFeeChargedOnPurchase: {
    id: 'setFeeChargedOnPurchase',
    defaultMessage: 'Set the fee that will be charged on purchase.'
  },
  failedToAddPrice: {
    id: 'failedToAddPrice',
    defaultMessage: 'Failed to add the price.'
  },
  defineMonthlyAndYearlyPrice: {
    id: 'defineMonthlyAndYearlyPrice',
    defaultMessage: 'Define the monthly and yearly marketplace prices of the module.'
  },
  moduleLogo: {
    id: 'moduleLogo',
    defaultMessage: 'Module Logo'
  },
  applicationLogo: {
    id: 'applicationLogo',
    defaultMessage: 'Application Logo'
  },
  oneTime: {
    id: 'oneTime',
    defaultMessage: 'One Time'
  },
  createYourOwnPrice: {
    id: 'createYourOwnPrice',
    defaultMessage: 'Create your own price'
  },
  addDynamicPrices: {
    id: 'addDynamicPrices',
    defaultMessage: 'Add dynamic prices for your solution'
  },
  createFirstPrice: {
    id: 'createFirstPrice',
    defaultMessage: 'Create first price'
  },
  addNewPrice: {
    id: 'addNewPrice',
    defaultMessage: 'Add new price'
  },
  setPricingModule: {
    id: 'setPricingModule',
    defaultMessage: 'Set the Pricing of your Module'
  },
  setPricingApplication: {
    id: 'setPricingApplication',
    defaultMessage: 'Set the Pricing of your Application'
  },
  defineMarketplacePriceApplication: {
    id: 'defineMarketplacePriceApplication',
    defaultMessage: 'Define the marketplace price of the application you are about to list.'
  },
  solutionDescription: {
    id: 'solutionDescription',
    defaultMessage: 'Solution Description'
  },
  makeModuleFree: {
    id: 'makeModuleFree',
    defaultMessage: 'Make your module available for Free'
  },
  makeApplicationFree: {
    id: 'makeApplicationFree',
    defaultMessage: 'Make your application available for Free'
  },
  pricingTiers: {
    id: 'pricingTiers',
    defaultMessage: 'Pricing Tiers'
  },
  pleaseProvideInformationAboutOrg: {
    id: 'pleaseProvideInformationAboutOrg',
    defaultMessage: 'Please provide information about your organization.'
  },
  sPricing: {
    id: 'sPricing',
    defaultMessage: 'pricing'
  },
  partnerApplication: {
    id: 'partnerApplication',
    defaultMessage: 'Partner Application'
  },
  vendorInformationComplete: {
    id: 'vendorInformationComplete',
    defaultMessage: 'Vendor Information Complete'
  },
  allInformationRequiredIsNowComplete: {
    id: 'allInformationRequiredIsNowComplete',
    defaultMessage: 'All Information required is now complete. Please accept the terms'
  },
  toSubmitApplication: {
    id: 'toSubmitApplication',
    defaultMessage: 'to submit the application.'
  },
  iAcceptTheViso: {
    id: 'iAcceptTheViso',
    defaultMessage: 'I accept the viso.ai'
  },
  vendorAgreement: {
    id: 'vendorAgreement',
    defaultMessage: 'Vendor Agreement'
  },
  submit: {
    id: 'submit',
    defaultMessage: 'Submit'
  },
  deviceUpdated: {
    id: 'deviceUpdated',
    defaultMessage: 'Device Updated'
  },
  unassigned: {
    id: 'unassigned',
    defaultMessage: 'Unassigned'
  },
  currentModuleApplications: {
    id: 'currentModuleApplications',
    defaultMessage: 'The current module is part of the following applications.'
  },
  vendorPolicy: {
    id: 'vendorPolicy',
    defaultMessage: 'Vendor Policy'
  },
  freePriceTierAlreadyAdded: {
    id: 'freePriceTierAlreadyAdded',
    defaultMessage: 'Free price tier already added'
  },
  dashboardId: {
    id: 'dashboardId',
    defaultMessage: 'Dashboard Id'
  },
  removePrice: {
    id: 'removePrice',
    defaultMessage: 'Remove Price'
  },
  pleaseConfirmToProceed: {
    id: 'pleaseConfirmToProceed',
    defaultMessage: 'Please confirm to proceed'
  },
  lastUpdatedBy: {
    id: 'lastUpdatedBy',
    defaultMessage: 'Last updated by'
  },
  createdAt: {
    id: 'createAt',
    defaultMessage: 'Created at'
  },
  modifiedAt: {
    id: 'modifiedAt',
    defaultMessage: 'Modified at'
  },
  assign: {
    id: 'assign',
    defaultMessage: 'Assign'
  },
  application: {
    id: 'application',
    defaultMessage: 'Application'
  },
  addVersion: {
    id: 'addVersion',
    defaultMessage: 'Add Version'
  },
  updatedAt: {
    id: 'updatedAt',
    defaultMessage: 'Updated At'
  },
  updatedBy: {
    id: 'updatedBy',
    defaultMessage: 'Updated By'
  },
  total: {
    id: 'total',
    defaultMessage: 'Total'
  },
  deployed: {
    id: 'deployed',
    defaultMessage: 'Deployed'
  },
  error: {
    id: 'error',
    defaultMessage: 'Error'
  },
  profileDeployedAt: {
    id: 'profileDeployedAt',
    defaultMessage: 'Profile Deployed At'
  },
  lastSeen: {
    id: 'lastSeen',
    defaultMessage: 'Last seen'
  },
  configuredAt: {
    id: 'configuredAt',
    defaultMessage: 'Configured at'
  },
  archiveSolutionDesc: {
    id: 'archiveSolutionDesc',
    defaultMessage: 'Are you sure you want to archive the solution?'
  },
  unpublishSolutionDesc: {
    id: 'unpublishSolutionDesc',
    defaultMessage: 'Are you sure you want to unpublish the solution?'
  },
  deleteSolutionDesc: {
    id: 'deleteSolutionDesc',
    defaultMessage: 'Are you sure you want to delete the solution?'
  },
  setAsLatestVersion: {
    id: 'setAsLatestVersion',
    defaultMessage: 'Set as latest version'
  },
  newVersionAdded: {
    id: 'newVersionAdded',
    defaultMessage: 'Successfully set as active version'
  },
  onCancelSubscriptionDesc: {
    id: 'onCancelSubscriptionDesc',
    defaultMessage: 'If you proceed, your subscription will be cancelled and related contents will be removed with immediate effect. This action is irreversible.'
  },
  cancellingSubscription: {
    id: 'cancellingSubscription',
    defaultMessage: 'Cancelling the subscription'
  },
  copyFileURL: {
    id: 'copyFileURL',
    defaultMessage: 'Copy File URL'
  },
  editInformation: {
    id: 'editInformation',
    defaultMessage: 'Edit Information'
  },
  removeFile: {
    id: 'removeFile',
    defaultMessage: 'Remove File'
  },
  exitFullscreen: {
    id: 'exitFullscreen',
    defaultMessage: 'Exit Fullscreen'
  },
  fullScreen: {
    id: 'fullScreen',
    defaultMessage: 'Full Screen'
  },
  importType: {
    id: 'importType',
    defaultMessage: 'Import Type'
  },
  sourceURL: {
    id: 'sourceURL',
    defaultMessage: 'Source URL'
  },
  invalidURL: {
    id: 'invalidURL',
    defaultMessage: 'Invalid URL'
  },
  installing: {
    id: 'installing',
    defaultMessage: 'Installing'
  },
  installed: {
    id: 'installed',
    defaultMessage: 'Installed'
  },
  removeApplicationFromLibrary: {
    id: 'removeApplicationFromLibraryy',
    defaultMessage:
      'Are you sure you want to remove the application?'
  },
  installedAll: {
    id: 'installedAll',
    defaultMessage: 'Install all'
  },
  installModule: {
    id: 'installModule',
    defaultMessage: 'Install Module'
  },
  installApplication: {
    id: 'installApplication',
    defaultMessage: 'Install Application'
  },
  toYourWorkspace: {
    id: 'toYourWorkspace',
    defaultMessage: 'to your Workspace'
  },
  dashboardDesc: {
    id: 'dashboardDesc',
    defaultMessage: 'Attached dashboards that will be imported to the workspace.'
  },
  price: {
    id: 'price',
    defaultMessage: 'Price'
  },
  getStartedApplicationSubscription: {
    id: 'getStartedApplicationSubscription',
    defaultMessage: 'Get started immediately and use the Application as a template from your workspace library. Build your application using this application template.'
  },
  confirmYourSubscription: {
    id: 'confirmYourSubscription',
    defaultMessage: 'Confirm your Subscription'
  },
  removePrice: {
    id: 'removePrice',
    defaultMessage: 'Remove Price'
  },
  pleaseConfirmToProceed: {
    id: 'pleaseConfirmToProceed',
    defaultMessage: 'Please confirm to proceed'
  },
  setMonthlyDefault: {
    id: 'setMonthlyDefault',
    defaultMessage: 'Set as Monthly Default'
  },
  setYearlyDefault: {
    id: 'setYearlyDefault',
    defaultMessage: 'Set as Yearly Default'
  },
  deletePrice: {
    id: 'deletePrice',
    defaultMessage: 'Delete price'
  },
  successfullyVideoDelete: {
    id: 'successfullyVideoDelete',
    defaultMessage: 'Successfully video deleted.'
  },
  confirmToRemove: {
    id: 'confirmToRemove',
    defaultMessage: 'Confirm to remove'
  },
  forcefullyOpenEditor: {
    id: 'forcefullyOpenEditor',
    defaultMessage: 'Open node editor anyways'
  },
  solutionSuccessfullyArchived: {
    id: 'solutionSuccessfullyArchived',
    defaultMessage: 'Solution archived successfully'
  },
  unpublishSolutionSuccess: {
    id: 'unpublishSolutionSuccess',
    defaultMessage: 'Solution unpublished'
  },
  visoBuilderIsLocked: {
    id: 'visoBuilderIsLocked',
    defaultMessage: 'The Viso Builder is locked'
  },
  currentlyEditingApplications: {
    id: 'currentlyEditingApplications',
    defaultMessage: 'is currently editing applications. Do you want to take over? Unsaved changes will be lost.'
  },
  offeredListings: {
    id: 'offeredListings',
    defaultMessage: 'Offered Listings'
  },
  partnerContact: {
    id: 'partnerContact',
    defaultMessage: 'Partner Contact'
  },
  termsOfUse: {
    id: 'termsOfUse',
    defaultMessage: 'Terms of service'
  },
  planQuotasAndLimits: {
    id: 'planQuotasAndLimits',
    defaultMessage: 'Plan quotas and limits'
  },
  featuresIncludedInPlan: {
    id: 'featuresIncludedInPlan',
    defaultMessage: 'Features that are included in the plan.'
  },
  itemLimits: {
    id: 'itemLimits',
    defaultMessage: 'Item Limits'
  },
  resourceQuotas: {
    id: 'resourceQuotas',
    defaultMessage: 'Resource quotas'
  },
  premiumFeatures: {
    id: 'premiumFeatures',
    defaultMessage: 'Premium Features'
  },
    aiModels: {
    id: 'aiModels',
    defaultMessage: 'AI models'
  },
  imageSizeEg: {
    id: 'imageSizeEg',
    defaultMessage: 'Image size (e.g. 200x200)'
  },
  models: {
    id: 'models',
    defaultMessage: 'Models'
  },
  modelDetails: {
    id: 'modelDetails',
    defaultMessage: 'Model Details'
  },
  dataset: {
    id: 'dataset',
    defaultMessage: 'Dataset'
  },
  modelSize: {
    id: 'modelSize',
    defaultMessage: 'Model size'
  },
  imageSize: {
    id: 'imageSize',
    defaultMessage: 'Image size'
  },
  source: {
    id: 'source',
    defaultMessage: 'Source'
  },
  modelType: {
    id: 'modelType',
    defaultMessage: 'Model type'
  },
  framework: {
    id: 'framework',
    defaultMessage: 'Framework'
  },
  deviceTypes: {
    id: 'deviceTypes',
    defaultMessage: 'Device types'
  },
  associatedModules: {
    id: 'associatedModules',
    defaultMessage: 'Associated Modules'
  },
  modelImported: {
    id: 'modelImported',
    defaultMessage: 'Model created successfully.'
  },
  associatedNodes: {
    id: 'associatedNodes',
    defaultMessage: 'Associated Nodes'
  },
  associatedNodesDesc: {
    id: 'associatedNodesDesc',
    defaultMessage: 'Nodes of Modules with which the AI model can be used.'
  },
  removeYourModel: {
    id: 'removeYourModel',
    defaultMessage: 'Remove your model'
  },
  removeYourModelDesc: {
    id: 'removeYourModelDesc',
    defaultMessage: 'Are you sure you want to remove your AI model {name}? The model will be permanently deleted.'
  },
  noModel: {
    id: 'noModel',
    defaultMessage: 'Currently no model available.'
  },
  annotation: {
    id: 'annotation',
    defaultMessage: 'Annotation'
  },
  training: {
    id: 'training',
    defaultMessage: 'Training'
  },
  accountID: {
    id: 'accountID',
    defaultMessage: 'Account ID'
  },
  billCycleDay: {
    id: 'billCycleDay',
    defaultMessage: 'Bill Cycle Day'
  },
  paymentMethod: {
    id: 'paymentMethod',
    defaultMessage: 'Payment Method'
  },
  notes: {
    id: 'notes',
    defaultMessage: 'Notes'
  },
  accountCredit: {
    id: 'accountCredit',
    defaultMessage: 'Account Credit'
  },
  accountBalance: {
    id: 'accountBalance',
    defaultMessage: 'Account Balance'
  },
  timeZone: {
    id: 'timeZone',
    defaultMessage: 'TimeZone'
  },
  locale: {
    id: 'locale',
    defaultMessage: 'Locale'
  },
  company: {
    id: 'company',
    defaultMessage: 'Company'
  },
  reference: {
    id: 'reference',
    defaultMessage: 'Reference'
  },
  internalNote: {
    id: 'internalNote',
    defaultMessage: 'Internal Note'
  },
  state: {
    id: 'state',
    defaultMessage: 'State'
  },
  activationDate: {
    id: 'activationDate',
    defaultMessage: 'Activation Date'
  },
  billingStartDate: {
    id: 'billingStartDate',
    defaultMessage: 'Billing Start Date'
  },
  billingPeriod: {
    id: 'billingPeriod',
    defaultMessage: 'Billing Period'
  },
  billingCycleDay: {
    id: 'billingCycleDay',
    defaultMessage: 'Billing Cycle Day'
  },
  billingEndDate: {
    id: 'billingEndDate',
    defaultMessage: 'Billing End Date'
  },
  cancelDate: {
    id: 'cancelDate',
    defaultMessage: 'Cancel Date'
  },
  chargedThrough: {
    id: 'chargedThrough',
    defaultMessage: 'Charged Through'
  },
  invoiceDate: {
    id: 'invoiceDate',
    defaultMessage: 'Invoice Date'
  },
  invoiceCredit: {
    id: 'invoiceCredit',
    defaultMessage: 'Invoice Credit'
  },
  invoices: {
    id: 'invoices',
    defaultMessage: 'Invoices'
  },
  invoice: {
    id: 'invoice',
    defaultMessage: 'Invoice'
  },
  payments: {
    id: 'payments',
    defaultMessage: 'Payments'
  },
  subscription: {
    id: 'subscription',
    defaultMessage: 'Subscription'
  },
  activatedAt: {
    id: 'activatedAt',
    defaultMessage: 'ActivatedAt'
  },
  paymentDate: {
    id: 'paymentDate',
    defaultMessage: 'Payment Date'
  },
  dueDate: {
    id: 'dueDate',
    defaultMessage: 'Due Date'
  },
  amountReceived: {
    id: 'amountReceived',
    defaultMessage: 'Amount Recieved'
  },
  paymentType: {
    id: 'paymentType',
    defaultMessage: 'Payment Type'
  },
  product: {
    id: 'product',
    defaultMessage: 'Product'
  },
  trial: {
    id: 'trial',
    defaultMessage: 'Trial'
  },
  priceList: {
    id: 'priceList',
    defaultMessage: 'Price List'
  },
  ownWorkspaces: {
    id: 'ownWorkspaces',
    defaultMessage: 'Own Workspaces'
  },
  billingContact: {
    id: 'billingContact',
    defaultMessage: 'Billing Contact'
  },
  inviteCreateWs: {
    id: 'inviteCreateWs',
    defaultMessage: 'Invite to Create Workspace'
  },
  inviteCreateWsDesc: {
    id: 'inviteCreateWsDesc',
    defaultMessage: 'Users will receive an email inviting them to create a workspace with the selected conditions.'
  },
  workspacePlanConfiguration: {
    id: 'workspacePlanConfiguration',
    defaultMessage: 'Workspace Plan Configuration'
  },
  quantity: {
    id: 'quantity',
    defaultMessage: 'Quantity'
  },
})
