import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import CardTags from 'app/components/common/Detail/CardTags';

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import EditInformation from '../Edit/Information'

class Information extends Component {
  state = {
    openEditInformationModal: false
  }
  
  onEdit = () => {
    this.setState({openEditInformationModal: true})
  }

  onCloseModal = () => {
    this.setState({openEditInformationModal: false})
  }

  renderContent = () => {
    const {plan} = this.props

    return (
      <>
        <div className="grid-col">
        <div className="grid-row">
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.planName} />
          </div>
          <div className="overview__value">{plan.prettyName}</div>
          </div>
          </div>

          <div className="overview__label">
            <FormattedMessage {...messages.planDescription} />
          </div>
          <div className="overview__value">{plan.description}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.tags} />
          </div>
        <CardTags
          color={this.props.color}
          tags={plan.tags}
        />
        </div>
      </>
    );
  };

  render() {
    const {openEditInformationModal} = this.state

    if (openEditInformationModal) {
      return <EditInformation
        editMode
        plan={this.props.plan}
        color={this.props.color}
        onClose={this.onCloseModal}
      />
    }

    return (
      <Card
      fullWidth
      isLocked={this.props.plan.workspaceCount}
        color={this.props.color}
        title={<FormattedMessage {...messages.information} />}
        className="overview"
        onEdit={this.props.plan.workspaceCount === 0 && this.onEdit}
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Information;
