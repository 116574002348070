import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import { FilePlus } from "images/common";
import NotFound from "app/components/NotFound";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import Pagination from "app/components/Pagination";
import Modal from "app/components/common/Modal";

import SearchField from "app/components/common/SingleSearch";
import NoPlans from "../../common/NoItem";

import Card from "./Card";
import List from "./Table";
import { isSmallerDevice } from "utils/common";

import messages from "utils/message";
import { routeMapping } from "app/constants/routes";
import Quota from "../details/components/Quota";
import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DASHBOARD_ACCENT_COLOR,
} from "app/constants/common";
import {
  listPlans,
  deletePlanAction,
  updatePlanAction,
  deactivatePlanAction,
  activatePlanAction,
  setPlanAsDev,
} from "app/actions/plans";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";
import { defaultNotifier } from "functions/notificationHandler";

import ListSelectedItems from "../../common/ListSelectedItems";
import { createPlan } from "app/api/plans";
import AddonPlan from "../details/components/AddonPlan";

class PlanList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      selectedIds: [],
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "prettyName",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.plans,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onDuplicate = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const plan = this.props.plans.find((plan) => plan.id === id);

    try {
      const { data } = await createPlan({
        name: plan.name,
        type: plan.type || "1 day",
        tags: plan.tags || [],
        description: plan.description || "",
        trial: plan.trial || 1,
        tiers: plan.tiers,
        currency: plan.currency || "usd",
        minEndpoints: plan.minEndpoints,
      });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }
      defaultNotifier("Successfully plan duplicated");
      this.props.history.push(
        `${routeMapping.PLANS.path}/${data.data.createSubscription.id}`
      );
    } catch (err) {
      defaultNotifier("Failed to duplicate a plan");
    }
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.plans);
    let result = finalResult;
    this.setCurrentPage(1);

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.prettyName.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
      },
      () => this.setState({ filterResult: this.props.plans })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const isArchived = query.get("isArchived");

    if (isArchived) {
      this.setState({ showOnlyArchived: true });
    }

    this.getPlans();

    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  getPlans = () => {
    this.props.listPlans();
  };

  onOpenActivatePlan = (id, e) => {
    const title = "Confirm activate plan";
    const body =
      "Activating the plan will add it to the assigned pricelist(s) and make it available to users.";

    const { plans } = this.props;
    const plan = plans.find(plan => plan.id === id);

    if(plan.status === 'active') {
      return false
    }

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: () =>
        this.onActivatePlan(id, e),
      onCancel: this.onCloseModal,
    });
  };

  onActivatePlan = async (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    await this.props.activatePlanAction(
      {
        id,
      },
      this.getPlans
    );
  };

  onOpenDeactivatePlan = (id, e) => {
    const title = "Confirm deactivate plan";
    const body =
      "Deactivating the plan will remove it from assigned pricelist(s) and make it unavailable to users.";

    const { plans } = this.props;
    const plan = plans.find(plan => plan.id === id);

    if(plan.status === 'inactive') {
      return false
    }

    this.setState({
      title,
      showModal: true,
      selectedPlan: id,
      body,
      onConfirm: () =>
        this.onDeactivatePlan(id, e),
      onCancel: this.onCloseModal,
    });
  };

  onDeactivatePlan = async (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    await this.props.deactivatePlanAction(
      {
        id,
      },
      this.getPlans
    );
  };

  onSetAsDevPlan = async (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    await this.props.setPlanAsDev(
      {
        id,
      },
      this.getPlans
    );
  };

  deletePlanModal = () => {
    const { color } = this.props;
    const { plans } = this.props;
    const plan = plans.find(plan => plan.id === this.state.selectedPlan);

    if(plan.status === "draft" || plan.status === 'active' || !!plan.workspaceCount) {
      return false
    }

    const buttons = [
      <button
        className={classNames('modal__confirm', {
          button_disabled: !this.state.isAccepted,
          [`modal__confirm_${color}`]: color
        })}
        key="confirm"
        disabled={!this.state.isAccepted}
        onClick={(e) => {
          if (this.state.isAccepted) {
            this.setState({showDeletePlanModal: false});
            this.onDelete(plan.id, e)
          }
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={() => this.setState({ showDeletePlanModal: false })}
      >
        <FormattedMessage {...messages.cancel} />
      </button>
    ];

    return (
      <Modal
        classNames="modal__frame"
        buttons={buttons}
        title={'Confirm immediate plan removal'}
        body={
          <>
    <div>The plan will be deleted immediately and cannot be recovered.</div>
            <div className="modal__checkbox">
              <div className={`checkbox checkbox_blocked checkbox_${color}`}>
                <input
                  type="checkbox"
                  name="new"
                  className={`checkbox__input modal__checkbox__input checkbox_${color}`}
                  onClick={this.onAcceptCondition}
                  checked={this.state.isAccepted}
                />
                <div className="checkbox__check"
                  onClick={this.onAcceptCondition}
                />
                <label className="checkbox__label modal__checkbox__label label-color-imp" 
                  onClick={this.onAcceptCondition}>
                  I know what I’m doing
                </label>
              </div>
            </div>
          </>
        }
        onClose={this.onCloseModal}
      />
    );
  };

  showDeleteModal = (id, e) => {
    const { plans } = this.props;
    const plan = plans.find(plan => plan.id === id);
  
    if(plan.status === "draft" || plan.status === 'active' || !!plan.workspaceCount) {
      return false
    }
    this.setState({showDeletePlanModal: true, selectedPlan: id})
  }

  onDelete = async (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    await this.props.deletePlanAction({ id });
    this.getPlans();
  };

  showQuota = async (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ showQuotaPage: true, selectedPlan: id });
  };

  showAddonPlan = async (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ showAddPlanPage: true, selectedPlan: id });
  };


  closeQuota = () => {
    this.setState({ showQuotaPage: false, showAddPlanPage: false });
    this.getPlans();
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  isMore = () => {
    return false;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.PLAN.path}/${id}`);
  };

  redirectToCreatePage = () => {
    return this.props.history.push(routeMapping.CREATE_PLAN.path);
  };

  // pagination
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  renderModal = () => {
    const { body, onConfirm, onCancel, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={onCancel}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={onCancel} />
    );
  };

  onAcceptCondition = e => {
    this.setState({ isAccepted: e.target.checked || !this.state.isAccepted });
  };
  
  render() {
    const { color } = this.props;

    const { showModal, activeView, selectedIds, resetFilter, currentPage, showDeletePlanModal } = this.state;

    const viewHeadlineClass = classNames(
      `actions-nav__icon actions-nav__icon_rows ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === TABLE_VIEW }
    );

    const viewListClass = classNames(
      `actions-nav__icon actions-nav__icon_cards ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === CARD_VIEW }
    );

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    if (this.state.showQuotaPage) {
      return (
        <Quota
          onClose={this.closeQuota}
          color={color}
          plan={this.props.plans.find(
            (plan) => plan.id === this.state.selectedPlan
          )}
        />
      );
    }

    if (this.state.showAddPlanPage) {
      return (
        <AddonPlan
          updatePlanAction={this.props.updatePlanAction}
          onClose={this.closeQuota}
          color={color}
          plan={this.props.plans.find(
            (plan) => plan.id === this.state.selectedPlan
          )}
        />
      );
    }

    // if(this.props.isLoading) {
    //   return <LoadingIndicator
    //   color={this.props.colorClassName}
    // />
    // }

    return (
      <>
        {showModal && this.renderModal()}
        {showDeletePlanModal && this.deletePlanModal()}
        <ListScroll>
          {(scrollParent) => (
            <>
              <div className="headline">
                <h1 className="headline__title">Plans</h1>
              </div>
              <Breadcrumb breadcrumbClass="breadcrumbs_static">
                <BreadcrumbItem />
                <BreadcrumbItem route="Every workspace needs to have a base plan in place." />
              </Breadcrumb>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
                <span
                  className={`actions-nav__link text-link ${color} cursor`}
                  onClick={this.redirectToCreatePage}
                >
                  Create Plan
                </span>
              </div>
              {!hasFilter &&
              !this.props.plans.length &&
              !this.props.isLoading ? (
                <NotFound
                  color={color}
                  strokeWidth="1"
                  logo={<FilePlus size={42} />}
                  title="Create your own plans"
                  description="Add dynamic plans for your workspaces"
                  buttonText="Create first plan"
                  onAction={this.redirectToCreatePage}
                />
              ) : (
                <>
                  <div className="filters-bar">
                    <ListSelectedItems
                      selectedNumber={selectedIds.length}
                      search={this.state.searchName}
                      onReset={this.onReset}
                      filterData={this.state.filter}
                      containerClassName={color}
                    />
                      <Filter
                        customClass={color}
                        resetFilter={resetFilter}
                        onChange={this.onFilter}
                        columns={filterColumns}
                        data={this.props.plans}
                        defaultFilter={[
                          {
                            field: "status",
                            value: "All",
                            isDefault: true,
                            columns: ["active", "inactive", "draft"],
                          },
                        ]}
                      />
                    <SearchField
                      onSearch={this.onSearch}
                      customClass={color}
                      resetSearch={this.state.resetFilter}
                      label="Search"
                    />
                  </div>
                  {!this.props.isLoading && (
                  <div>
                    <InfiniteScroll
                      initialLoad={false}
                      useWindow={false}
                      getScrollParent={() => this.scrollParent}
                    >
                      {activeView === TABLE_VIEW && (
                        <>
                          <List
                            data={
                              hasFilter
                                ?  this.state.filterResult.filter(
                                  (item, index) => {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
      
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
      
                                    return false;
                                  }
                                )
                                : this.props.plans.filter(
                                  (item, index) =>  {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
  
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
  
                                    return false;
                                  }
                                )
                            }
                            onRowClick={this.onRowClick}
                            selectAll={this.selectAll}
                            onSort={this.onSort}
                            color={color}
                            isLoading={this.props.isLoading}
                            onDeactivatePlan={this.onOpenDeactivatePlan}
                            onActivatePlan={this.onOpenActivatePlan}
                            onDuplicate={this.onDuplicate}
                            sort={this.state.sort}
                            isSelectedAll={this.getIsSelectedAll()}
                            selectedIds={selectedIds}
                            onSelect={this.onSelect}
                            onSetAsDevPlan={this.onSetAsDevPlan}
                            onUpdateStatus={this.onUpdateStatus}
                            onDelete={this.showDeleteModal}
                            showQuota={this.showQuota}
                            showAddonPlan={this.showAddonPlan}
                          />
                        </>
                      )}
                      {activeView === CARD_VIEW && (
                        <div className="data-cards">
                          <Card
                            color={color}
                            data={
                              hasFilter
                                ?  this.state.filterResult.filter(
                                  (item, index) => {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
      
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
      
                                    return false;
                                  }
                                )
                                : this.props.plans.filter(
                                  (item, index) =>  {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
  
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
  
                                    return false;
                                  }
                                )
                            }
                            showQuota={this.showQuota}
                            showAddonPlan={this.showAddonPlan}
                            onSetAsDevPlan={this.onSetAsDevPlan}
                            onDuplicate={this.onDuplicate}
                            onRowClick={this.onRowClick}
                            selectedItems={selectedIds}
                            onDeactivatePlan={this.onOpenDeactivatePlan}
                            onActivatePlan={this.onOpenActivatePlan}
                            onUpdateStatus={this.onUpdateStatus}
                            onDelete={this.showDeleteModal}
                            onSelect={this.onSelect}
                          />
                        </div>
                      )}
                          {((hasFilter && !!this.state.filterResult.length) ||
                            (!hasFilter && !!this.props.plans.length)) && (
                            <Pagination
                              onNext={this.onNext}
                              currentPage={this.state.currentPage}
                              setCurrentPage={this.setCurrentPage}
                              onChangeLimit={this.onChangeLimit}
                              limit={this.state.offset}
                              totalPage={
                                hasFilter
                                  ? this.state.filterResult.length
                                  : this.props.plans.length
                              }
                            />
                          )}
                    </InfiniteScroll>
                    {activeView === CARD_VIEW &&
                      this.props.plans &&
                      !this.props.plans.length && <NoPlans />}
                  </div>
                  )}
                </>
              )}
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

PlanList.propTypes = {
  color: PropTypes.string,
  plans: PropTypes.array,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

PlanList.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    plans: state.plans.list,
    isLoading: state.plans.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  listPlans,
  setPlanAsDev,
  updatePlanAction,
  deletePlanAction,
  deactivatePlanAction,
  activatePlanAction,
});

export default compose(withRouter, withConnect)(PlanList);
