import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { orderBy } from "lodash";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import Pagination from "app/components/Pagination";
import { DateRangePicker } from 'react-date-range';
import 'react-dates/initialize';
import { defaultNotifier } from 'functions/notificationHandler'
import SearchField from "app/components/common/SingleSearch";
import Card from "./Card";
import List from "./Table";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import Modal from "app/components/common/Modal";
import messages from "utils/message";
import Noinvoices from "../../common/NoItem";
import { routeMapping } from "app/constants/routes";
import { isSmallerDevice } from "utils/common";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import CustomInput from "app/components/common/Input/CustomInput";
import Tags from "app/components/common/Tags";
import { SingleDatePicker } from 'react-dates';
import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ACCENT,
} from "app/constants/common";
import { getInvoice } from "utils/common";
import { listInvoice } from "app/actions/invoices";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";

import ListSelectedItems from "../../common/ListSelectedItems";
import { XCircleIcon } from "images/common";
import {recordPayment} from 'app/api/payment';
import 'react-dates/lib/css/_datepicker.css';
import AddRecord from "./AddRecord";

class WorkspaceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      dateInfo: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'dateInfo'
      },
      currentPage: 1,
      selectedIds: [],
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "invoiceNumber",
        direction: "desc",
      },
      filter: {
        single: [],
        range: [],
      },
      filteredWorkspace: [],
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
      tags: [],
      note: '',
      reference: ''
    };
    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  loadMore = () => {
    const { isLoading } = this.props;

    if (true) {
      return false;
    }

    this.setState(
      {
        limit: this.state.limit + DEFAULT_PAGE_SIZE,
      },
      this.props.listInvoice({limit: this.state.offset + DEFAULT_PAGE_SIZE, offset: this.state.offset})
    );
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      filteredWorkspace: orderBy(
        hasFilter 
        ? 
        this.state.filteredWorkspace.map(item => ({...item, id: item.invoiceId}))
        : 
        this.props.invoices.data
        .map(item => ({...item, id: item.invoiceId}))
        ,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    if(!this.props.isLoading) {
      this.setCurrentPage(1);
    }

    const payload = this.props.invoices.data.map((item) => ({
      ...item,
      id: item.invoiceId,
      status: item.status,
      quantity: item.quantity,
      provider: "viso",
      createdAt: new Date(item.created * 1000),
    }));

    const finalResult = getOutput(
      filterData,
      payload
    );

    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filteredWorkspace: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
      },
      () => this.setState({ filteredWorkspace: this.props.invoices })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.getInvoices();
    this.setState({offset: DEFAULT_PAGE_SIZE})
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  getInvoices = () => {
    this.props.listInvoice({limit: DEFAULT_PAGE_SIZE, offset: this.state.offset});
  };

  onCloseModal = () => {
    this.setState({ showModal: false, note: '', reference: '', tags: [] });
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.INVOICES.path}/${id}`);
  };

  addModule = () => {
    this.props.history.push(routeMapping.IMPORT_MODULE.path);
  };

  onRedirectToArchivePage = () => {
    this.props.history.push("/invoices?isArchived=true");
  };

  onCloseShowArchived = () => {
    this.setState({ showOnlyArchived: false });
    this.props.history.push(routeMapping.invoices.path);
  };

  onManageAgent = (ws, e) => {
    this.props.history.push(`/workspace/${ws.id}/agents/${ws.name}`);
  };

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  onRecordSubmit = async () => {
    const {date} = this.state;
    const paymentDate = moment(date).format('YYYY-MM-DD');

    const {data} = await recordPayment({
      invoiceId: this.state.invoiceId,
      amount: this.state.amount,
      paymentMethod: 1,
      reference: this.state.reference || '',
      note: this.state.note || '',
      tags: this.state.tags,
      paymentDate: paymentDate || ''
    });

    if(data.errors) {
      return defaultNotifier('Error while adding the record')
    }

    if(data.data.recordPayment) {
      defaultNotifier(data.data.recordPayment)
      return this.getInvoices()
    }
  }

  onRecord = async (id) => {
    const invoice = this.props.invoices.data.find(invoice => invoice.invoiceId === id);

    this.setState({
      selectedInvoice: invoice,
      showModal: true
    })
  }

  // pagination 
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
    
    this.props.listInvoice({limit: offset, offset: 0});
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
    if(currentPage !== this.state.currentPage) {
      this.props.listInvoice({limit: this.state.offset, offset: (currentPage - 1) * this.state.offset});
    }
  };

  
  onAddTags = tags => {
    this.setState({ tags});
  };

  onChangeFloat = (e, name) => {
    this.setState({[name]: parseInt(e.target.value, 10)})
  }

  onChange = (e, name) => {
    this.setState({[name]: e.target.value})
  }

  renderModal = () => {
    const {color} = this.props;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          this.onRecordSubmit()
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        classNames="modal__frame6"
        buttons={buttons}
        title={"Record a payment"}
        body={
          <>
            <br/>
            <div className={`text-field text-field_${color}`}>
                  <CustomInput
                    value={this.state.amount}
                    onChange={(e) => this.onChangeFloat(e, "amount")}
                    label='USD'
                  />
                  </div>
                  <div className={`text-field text-field_${color}`}>
                  <CustomInput
                    value={this.state.reference}
                    onChange={(e) => this.onChange(e, "reference")}
                    label='Reference'
                  />
                  </div>
                  <div className={`text-field text-field_${color}`}>
                  <CustomInput
                    value={this.state.note}
                    onChange={(e) => this.onChange(e, "note")}
                    label='Note'
                  />
                  </div>
                  <br/>
                  <SingleDatePicker
                  isOutsideRange={() => false}
                  displayFormat={() => "DD MMMM YYYY"}
                  numberOfMonths={1} autoFocus
                  date={this.state.date} // momentPropTypes.momentObj or null
                  onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  id="your_unique_id" // PropTypes.string.isRequired,
                />
                  <br/>
                  <div className={`text-field text-field_${color}`}>
                  <Tags
                      tags={this.state.tags}
                      tagClass={'accent0'}
                      onChange={this.onAddTags}
                    />
            </div>
          </>
        }
        onClose={() => {
          this.setState({ showModal: false });
        }}
      />
    );
  };

  onHideModal = () => {
    this.setState({showModal: false})
  }

  isMore = () => {
    const { color, invoices = [], isLoading } = this.props;

    return (
      invoices
      && invoices.data
      && invoices.data.length > 0
      && !isLoading
      && invoices.count > invoices.data.length
    );

  };

  render() {
    const { color, invoices = [] } = this.props;

    const {
      searchName,
      showOnlyArchived,
      activeView,
      selectedIds,
      resetFilter,
    } = this.state;
    const viewHeadlineClass = classNames(
      "actions-nav__icon actions-nav__icon_cards cursor",
      {
        "actions-nav__icon_active": activeView === CARD_VIEW,
        [color]: color,
      }
    );

    const viewListClass = classNames(
      "actions-nav__icon actions-nav__icon_rows cursor",
      {
        "actions-nav__icon_active": activeView === TABLE_VIEW,
        [color]: color,
      }
    );

    const hasFilter =
    this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    if(this.state.showModal) {
      return <AddRecord 
      getInvoices={this.getInvoices}
      invoice={this.state.selectedInvoice} 
      onCancel={this.onHideModal}/>
    }
    
    // if (
    //   !this.state.searchName &&
    //   !this.state.filter.single.length &&
    //   !this.state.filter.range.length &&
    //   !result.length
    // ) {
    //   return (
    //     <NotFound
    //       color={color}
    //       strokeWidth="1"
    //       logo={<WorkspaceIcon size={42} />}
    //       title="Start deploying your Apps"
    //       description="Using profiles, you’ll be able to deploy your applications to
    //    devices. Create profiles to manage deployments."
    //       buttonText="Create first profile"
    //       onAction={this.props.onCreate}
    //     />
    //   );
    // }
    return (
      <>
        <ListScroll>
          {(scrollParent) => (
            <>
              <div className="headline">
                <h1 className="headline__title">
                  <FormattedMessage {...messages.invoices} />
                </h1>
              </div>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
              </div>

              <div className="filters-bar">
                <ListSelectedItems
                  selectedNumber={selectedIds.length}
                  search={this.state.searchName}
                  onReset={this.onReset}
                  filterData={this.state.filter}
                  containerClassName={color}
                />
                <Filter
                  customClass={color}
                  resetFilter={resetFilter}
                  onChange={this.onFilter}
                  disabled={this.props.isLoading}
                  columns={filterColumns}
                  data={this.props.invoices && 
                    this.props.invoices.data.map(item => ({...item, id: item.invoiceId}))
                  }
                  defaultFilter={[{
                    field: "status",
                    value: "All",
                    isDefault: true,
                    columns: ["paid", "overdue", "open"],
                  }]}
                />
                {showOnlyArchived && (
                  <div
                    className={classNames(
                      "filters-bar__item",
                      this.props.color
                    )}
                  >
                    <button type="button" className="filters-bar__button">
                      {searchName} Show archived
                      <div
                        className="filters-bar__remove"
                        onClick={this.onCloseShowArchived}
                      >
                        <XCircleIcon />
                      </div>
                    </button>
                  </div>
                )}
                <SearchField
                  onSearch={this.onSearch}
                  customClass={color}
                  resetSearch={this.state.resetFilter}
                  label="Search"
                />
              </div>
              <div>
                <InfiniteScroll
                  // loadMore={this.debouncedLoadMore}
                  // hasMore={this.isMore()}
                  initialLoad={false}
                  useWindow={false}
                  getScrollParent={() => scrollParent}
                >
                  {activeView === TABLE_VIEW && (
                    <>
                    <List
                      data={
                        hasFilter
                          ? this.state.filteredWorkspace
                          : this.props.invoices && this.props.invoices.data
                          .map(item => ({...item, id: item.invoiceId}))
                      }
                      onRowClick={this.onRowClick}
                      selectAll={this.selectAll}
                      onSort={this.onSort}
                      color={color}
                      isLoading={this.props.isLoading}
                      sort={this.state.sort}
                      isSelectedAll={this.getIsSelectedAll()}
                      selectedIds={selectedIds}
                      onSelect={this.onSelect}
                      onRecord={this.onRecord}
                      // onUpdate={this.onUpdateWorkspace}
                    />
                      </>
                  )}
                  {activeView === CARD_VIEW && (
                    <div className="data-cards">
                      <Card
                        color={color}
                        data={
                          hasFilter
                            ? this.state.filteredWorkspace
                            : this.props.invoices.data
                          .map(item => ({...item, id: item.invoiceId}))
                        }
                        onRowClick={this.onRowClick}
                        selectedItems={selectedIds}
                        onSelect={this.onSelect}
                        isLoading={this.props.isLoading}
                        onRecord={this.onRecord}
                      />
                    </div>
                  )}
                    {((hasFilter && !!this.state.filteredWorkspace.length) ||
                      (!hasFilter && !!this.props.invoices.count)) && (
                      <Pagination
                        invoicePage
                        currentPage={this.state.currentPage}
                        setCurrentPage={this.setCurrentPage}
                        onChangeLimit={this.onChangeLimit}
                        limit={this.state.offset}
                        totalPage={(hasFilter && !this.state.isSorted) ? this.state.filteredWorkspace.length : this.props.invoices.count}
                      />
                    )}
                </InfiniteScroll>
                {activeView === CARD_VIEW &&
                  !this.props.isLoading &&
                  !invoices.data.length && <Noinvoices />}
              </div>
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

WorkspaceList.propTypes = {
  color: PropTypes.string,
  invoices: PropTypes.array,
  activeView: PropTypes.string,
};

WorkspaceList.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    invoices: state.invoices.list,
    isLoading: state.invoices.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  listInvoice,
});

export default compose(withRouter, withConnect)(WorkspaceList);
