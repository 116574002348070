import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {FormattedMessage} from 'react-intl'

import Button from 'app/components/Button';
import messages from 'utils/message'
import EditPencil from 'images/common/edit-pencil'
import {NoIcon} from 'images/common'

class Card extends Component {
  render() {
    const {message} = this.props

    return (
      <div className={classnames('content-box empty-box', this.props.className, {
        'card_loading': this.props.isLoading
      })}>
        {
          this.props.customText &&
            <Button
              className={classnames('overview__edit', `overview__edit_${this.props.color}`, {[this.props.color]: this.props.color})}
              onClick={this.props.action}
            >
              {this.props.customText}
            </Button>
        }
        { this.props.onEdit &&
          <Button
            className={classnames('overview__edit', `overview__edit_${this.props.color}`)}
            onClick={this.props.onEdit}
          >
            <EditPencil /><FormattedMessage {...messages.edit}/>
          </Button>
        }
        <div className="overview__title mx-b-12">
          { this.props.title }
        </div>
        {this.props.subTitle &&
        <div className="overview__subtitle">{this.props.subTitle}</div>
        }
        <div className="grid-row">
          <tr className="">
            <td className="data-table__td data-table__td_1">
              <div className="checkbox">
                <div className="checkbox__image checkbox__empty">
                  {<NoIcon/>}
                </div>
              </div>
            </td>
            <td className="data-table__td data-table__td label-color"><h4>{message}</h4></td>
          </tr>
        </div>
      </div>

    )
  }
}

Card.propTypes = {
  color: PropTypes.string,
  subTitle: PropTypes.any,
  customText: PropTypes.any,
  action: PropTypes.any,
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  color: 'accent0',
  className: 'overview'
};

export default Card
