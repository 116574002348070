import React from 'react'
import PropTypes from 'prop-types'

import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import {WorkspaceIcon} from 'images/common'
import {SubscriptionIcon} from 'images/common'
import { FormattedMessage } from 'react-intl'
import appMessages from 'utils/message';

const columnList = [{
  label: 'Category',
  isSortable: true,
  isLocked: true,
  sortKey: 'id'
}, 
{
  label: 'Name',
  sortKey: 'name',
  isSortable: true,
  isLocked: true,
},
{
  label: 'Phase Type',
  sortable: false,
  sortKey: 'phaseType'
},
{
  label: 'Start Date',
  sortable: false,
  isSortable: true,
  sortKey: 'startDate'
},
{
  label: <FormattedMessage {...appMessages.chargedThrough}/>,
  sortable: false,
  isSortable: true,
  sortKey: 'chargedThrough'
},
{
  label: <FormattedMessage {...appMessages.cancelDate}/>,
  sortable: false,
  isSortable: true,
  sortKey: 'cancelDate'
},
{
  label: 'Price',
  sortable: false,
  isSortable: true,
  sortKey: 'price'
}
]

class ModuleTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        rows={[]}
        notFoundIcon={<SubscriptionIcon/>}
        isPriceSetting
        getCurrencyIcon={'usd'}
        colorClassName={color}
        CheckboxImage={WorkspaceIcon}
        isSelectable
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        monthlyPriceId={this.props.plan && this.props.plan.monthlyPriceId}
        yearlyPriceId={this.props.plan && this.props.plan.yearlyPriceId}
        deletePrice={this.props.deletePrice}
        setDefaultPrice={this.props.setDefaultPrice}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
      />
    )
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ModuleTable
