import React from 'react'

import Tags from './types/Tags'
import SingleRange from './types/SingleRange'
import MultiRange from './types/MultiRange'
import Radio from './types/Radio'
import Search from './types/Search'
import Checkbox from './types/Checkbox'
import DatePicker from './types/DatePicker'
import MultiSelect from './types/MultiSelect'
import SingleSelect from './types/SingleSelect'

const FilterType = (props) => {

  const filterTypeContainer = {
    'search': <Search {...props} />,
    'singleRange': <SingleRange {...props}/>,
    'multiRange': <MultiRange {...props}/>,
    'radio': <Radio {...props} />,
    'multiSelect': <MultiSelect {...props} />,
    'date': <DatePicker {...props} />,
    'singleSelect': <SingleSelect {...props} />,
    'checkbox': <Checkbox {...props} />,
    'tags': <Tags {...props} />
  }

  return filterTypeContainer[props.type]
}

export default FilterType
