import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { orderBy } from "lodash";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import Pagination from "app/components/Pagination";

import { defaultNotifier } from 'functions/notificationHandler'
import SearchField from "app/components/common/SingleSearch";
import Card from "./Card";
import List from "./Table";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import Modal from "app/components/common/Modal";
import DeleteWorkspace from "../DeleteWorkspace";
import messages from "utils/message";
import NoWorkspaces from "../../common/NoItem";
import { routeMapping } from "app/constants/routes";
import { isSmallerDevice } from "utils/common";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import PreLoaderList from "app/components/common/PreLoader/PreLoaderList";
import AddCharge from "../details/components/AddCharge";
import AddCredit from "../details/components/AddCredit";

import {
  listPlans
} from "app/actions/plans";

import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ACCENT,
} from "app/constants/common";
import {
  listWorkspaces,
  deleteWorkspaceAction,
  scheduleDeleteWorkspaceAction,
  cancelScheduleDeleteWorkspaceAction,
  archiveWorkspaceAction,
  activateWorkspaceAction,
  deactivateWorkspaceAction,
} from "app/actions/workspaces";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";

import { updateWorkspaceAnnotationSetting, updateWorkspaceTrainingSetting, cvEnvs, updateWorkspaceDatasetSetting, activateAutoTraining, activateIncidents, activateDashboards } from 'app/api/workspaces'
import ListSelectedItems from "../../common/ListSelectedItems";
import { XCircleIcon } from "images/common";
import { dateFormatter } from "utils/common";

class WorkspaceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      selectedIds: [],
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "name",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      filteredWorkspace: [],
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: DEFAULT_PAGE_SIZE,
      filteredWorkspace: orderBy(
        hasFilter ? this.state.filteredWorkspace : this.props.workspaces,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: DEFAULT_PAGE_SIZE,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    this.setCurrentPage(1);

    const finalResult = getOutput(
      filterData,
      this.props.workspaces.map((item) => ({
        ...item,
        partnerData: {
          id: (item.partner && item.partner.id) || "1",
          name:
            (item.partner && item.partner.status) === "approved"
              ? "Partner"
              : (item.partner && item.partner.status) || "Inactive",
        },
      }))
    );
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filteredWorkspace: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.setState({ filteredWorkspace: this.props.workspaces })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const isArchived = query.get("isArchived");

    if (isArchived) {
      this.setState({ showOnlyArchived: true });
    }

    this.getWorkspaces();
    this.props.listPlans();

    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  getWorkspaces = () => {
    this.setState({ showDeleteModal: false });

    this.props.listWorkspaces();
  };

  onUpdateWorkspace = (payload, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = payload.isActive
      ? "Confirm activate workspace"
      : "Confirm deactivate workspace";
    const body = payload.isActive
      ? "Activating the workspace will allow the workspace users to sign in and re-activate the paused subscriptions."
      : "The users of the workspace will not be able to sign in and access their data. All subscriptions are paused.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: () =>
        this.props.activateWorkspaceAction(payload, this.getWorkspaces),
      onCancel: this.onCloseModal,
    });
  };

  onDeactiveWorkspace = async (payload, e) => {
    e.preventDefault();
    e.stopPropagation();

    const title = "Confirm deactivate workspace";
    const body =
      "The users of the workspace will not be able to sign in and access their data. All subscriptions are paused.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: () =>
        this.props.deactivateWorkspaceAction(payload, this.getWorkspaces),
      onCancel: this.onCloseModal,
    });
  };

  onActivateWorkspace = (payload, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Confirm activate workspace";
    const body =
      "Activating the workspace will allow the workspace users to sign in and re-activate the paused subscriptions.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: () =>
        this.props.activateWorkspaceAction(payload, this.getWorkspaces),
      onCancel: this.onCloseModal,
    });
  };

  onActivateAnnotation = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Activate Annotation";
    const body =
      "Activating the annotation will allow the workspace users to create environments and re-use paused environments.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await updateWorkspaceAnnotationSetting({
          id,
          enabled: true
        });
        this.setState({ fetching: false })

        defaultNotifier('Annotation enabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateAnnotation = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const { color } = this.props;
    this.setState({ fetching: true })
    const { data } = await cvEnvs({ workspaceId: id });
    this.setState({ fetching: false })

    const cvList = data.data.cvEnvs;

    const activeEnv = cvList && cvList
      .filter(cv => cv.template === 'CVAT')
      .filter(cv => cv.status !== 'paused')

    const title = "Deactivate Annotation";
    const body = <span>Workspace users will no longer be able to create environments. There are <span className={color}>{activeEnv ? activeEnv.length : 0} environment(s)</span> that will be set to paused.</span>

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await updateWorkspaceAnnotationSetting({
          id,
          enabled: false
        });
        this.setState({ fetching: false })
        defaultNotifier('Annotation disabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onActivateAutoTraining = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Activate Experiment";
    const body =
      "Activating will allow the workspace users to create experiment and re-use paused experiment.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await activateAutoTraining({
          id,
          enabled: true
        });
        this.setState({ fetching: false })

        defaultNotifier('Training enabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateAutoTraining = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Deactivate Experiment";
    const body =
      "Deactivating will not allow the workspace users to create experiment and re-use paused experiment.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await activateAutoTraining({
          id,
          enabled: false
        });
        this.setState({ fetching: false })

        defaultNotifier('Training disabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };


  onActivateIncident = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Activate Incident";
    const body =
      "Activating will allow the workspace users to view incident.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await activateIncidents({
          id,
          enabled: true
        });
        this.setState({ fetching: false })

        defaultNotifier('Incident enabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateIncident = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Deactivate Incident";
    const body =
      "Deactivating will not allow the workspace users to view incident.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await activateIncidents({
          id,
          enabled: false
        });
        this.setState({ fetching: false })

        defaultNotifier('Incident disabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  // Dashboard
  onActivateDashboard = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Activate Dashboard";
    const body =
      "Activating will allow the workspace users to view dashboard.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await activateDashboards({
          id,
          enabled: true
        });
        this.setState({ fetching: false })

        defaultNotifier('Dashboard enabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateDashboard = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Deactivate Dashboard";
    const body =
      "Deactivating will not allow the workspace users to view dashboard.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await activateDashboards({
          id,
          enabled: false
        });
        this.setState({ fetching: false })

        defaultNotifier('Dashboard disabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onActivateTraining = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Activate Training";
    const body =
      "Activating the training will allow the workspace users to create environments and re-use paused environments.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await activateAutoTraining({
          id,
          enabled: true
        });
        this.setState({ fetching: false })

        defaultNotifier('Training enabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onActivateDataset = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Activate Dataset";
    const body =
      "Activating the dataset will allow the workspace users to create dataset.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await updateWorkspaceDatasetSetting({
          id,
          enabled: true
        });
        this.setState({ fetching: false })

        defaultNotifier('Dataset enabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateDataset = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Deactivate Dataset";
    const body =
      "Deactivating the dataset will revoke all the access related to dataset.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await updateWorkspaceDatasetSetting({
          id,
          enabled: false
        });
        this.setState({ fetching: false })

        defaultNotifier('Dataset disabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateTraining = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const { color } = this.props;
    this.setState({ fetching: true })
    const { data } = await cvEnvs({ workspaceId: id });
    this.setState({ fetching: false })

    const cvList = data.data.cvEnvs;

    const activeEnv = cvList && cvList
      .filter(cv => cv.template === 'JupyterLab')
      .filter(cv => cv.status !== 'paused')
    const title = "Deactivate Training";
    const body = <span>Workspace users will no longer be able to create environments. There are <span className={color}>{activeEnv ? activeEnv.length : 0} environment(s)</span> that will be set to paused.</span>

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        this.setState({ fetching: true })
        await updateWorkspaceTrainingSetting({
          id,
          enabled: false
        });
        this.setState({ fetching: false })
        defaultNotifier('Training disabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onAddCredit = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      showCreditModal: true,
      selectedWs: id
    });
  };

  onAddCharge = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      showChargeModal: true,
      selectedWs: id
    });
  };


  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  onScheduleDeleteWorkspace = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const date = moment().add(14, "days").format("D MMM YYYY HH:mm");

    const body = `The workspace will be deleted. All personal data will be permanently deleted. To cancel the deletion, the workspace can be recovered until ${date}.`;
    const title = `Confirm remove workspace`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () =>
        this.props.scheduleDeleteWorkspaceAction(
          { workspaceId: id },
          this.getWorkspaces
        ),
      onCancel: this.onCloseModal,
    });
  };

  onDeleteWorkspace = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The workspace will be deleted. All personal data will be permanently deleted.`;
    const title = `Confirm remove workspace`;

    this.setState({
      showDeleteModal: true,
      body,
      title,
      currentWorkspaceId: id,
    });
  };

  onCancelSchedule = (id, e, deleteDate) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `Your workspace has been deleted. We will permanently delete your personal data.
    To cancel the deletion, you can recover the workspace until ${dateFormatter(
      deleteDate,
      true
    )}`;
    const title = `Confirm recover workspace`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () =>
        this.props.cancelScheduleDeleteWorkspaceAction(
          { workspaceId: id },
          this.getWorkspaces
        ),
      onCancel: this.onCloseModal,
    });
  };

  loadMore = () => {
    if (this.state.isAllSelected) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE });
  };

  isMore = () => {
    const { workspaces } = this.props;

    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    const data = hasFilter ? this.state.filteredWorkspace : workspaces;

    if (this.state.activeView === CARD_VIEW) {
      return false
    }

    if (!data) {
      return false;
    }

    return data.length > this.state.offset;
  };

  renderModal = () => {
    const { body, onConfirm, onCancel, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        disabled={this.state.fetching}
        onClick={() => {
          if (!this.state.fetching) {
            onConfirm();
            this.onCloseModal();
          }
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={onCancel}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={onCancel} />
    );
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.WORKSPACE.path}/${id}`);
  };

  addModule = () => {
    this.props.history.push(routeMapping.IMPORT_MODULE.path);
  };

  onRedirectToArchivePage = () => {
    this.props.history.push("/workspaces?isArchived=true");
  };

  onCloseShowArchived = () => {
    this.setState({ showOnlyArchived: false });
    this.props.history.push(routeMapping.WORKSPACES.path);
  };

  onManageAgent = (ws, e) => {
    this.props.history.push(`/workspace/${ws.id}/agents/${ws.name}`);
  };

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }


  // pagination 
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  render() {
    const { color, workspaces = [] } = this.props;

    const {
      searchName,
      showModal,
      showOnlyArchived,
      activeView,
      selectedIds,
      resetFilter,
      currentPage
    } = this.state;
    let workspaceList = [];

    workspaceList = workspaces;

    // if (this.state.fetching) {
    //   return (
    //     <LoadingIndicator
    //       color={this.props.colorClassName}
    //     />
    //   );
    // }

    if (showOnlyArchived) {
      workspaceList =
        workspaces && workspaces.filter((workspace) => workspace.archived);
    }

    const viewHeadlineClass = classNames(
      "actions-nav__icon actions-nav__icon_cards cursor",
      {
        "actions-nav__icon_active": activeView === CARD_VIEW,
        [color]: color,
      }
    );

    const viewListClass = classNames(
      "actions-nav__icon actions-nav__icon_rows cursor",
      {
        "actions-nav__icon_active": activeView === TABLE_VIEW,
        [color]: color,
      }
    );

    if (this.state.showDeleteModal) {
      return (
        <DeleteWorkspace
          isLoading={this.props.isLoading}
          workspace={this.props.workspaces.find(
            (workspace) => workspace.id === this.state.currentWorkspaceId
          )}
          onDelete={() =>
            this.props.deleteWorkspaceAction(
              { id: this.state.currentWorkspaceId },
              this.getWorkspaces
            )
          }
          onClose={() => this.setState({ showDeleteModal: false })}
        />
      );
    }

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    // if (
    //   !this.state.searchName &&
    //   !this.state.filter.single.length &&
    //   !this.state.filter.range.length &&
    //   !result.length
    // ) {
    //   return (
    //     <NotFound
    //       color={color}
    //       strokeWidth="1"
    //       logo={<WorkspaceIcon size={42} />}
    //       title="Start deploying your Apps"
    //       description="Using profiles, you’ll be able to deploy your applications to
    //    devices. Create profiles to manage deployments."
    //       buttonText="Create first profile"
    //       onAction={this.props.onCreate}
    //     />
    //   );
    // }

    if (this.props.isLoading) {
      return <PreLoaderList />
    }


    if (this.state.showCreditModal) {
      return <AddCredit
        id={this.state.selectedWs}
        getWorkspaces={this.getWorkspaces}
        onClose={() => this.setState({ showCreditModal: false })} />
    }

    if (this.state.showChargeModal) {
      return <AddCharge
        id={this.state.selectedWs}
        getWorkspaces={this.getWorkspaces}
        onClose={() => this.setState({ showChargeModal: false })} />
    }

    return (
      <>
        {showModal && this.renderModal()}
        <ListScroll>
          {(scrollParent) => (
            <>
              <div className="headline">
                <h1 className="headline__title">
                  <FormattedMessage {...messages.workspaces} />
                </h1>
              </div>
              <Breadcrumb breadcrumbClass='breadcrumbs_static'>
                <BreadcrumbItem />
                <BreadcrumbItem route="List of all Workspaces on the Viso Platform." />
              </Breadcrumb>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
              </div>

              <div className="filters-bar">
                <ListSelectedItems
                  selectedNumber={selectedIds.length}
                  search={this.state.searchName}
                  onReset={this.onReset}
                  filterData={this.state.filter}
                  containerClassName={color}
                />
                <Filter
                  customClass={color}
                  resetFilter={resetFilter}
                  onChange={this.onFilter}
                  columns={filterColumns}
                  data={this.props.workspaces.map((item) => ({
                    ...item,
                    partnerData: {
                      id: (item.partner && item.partner.id) || "1",
                      name:
                        (item.partner && item.partner.status) === "approved"
                          ? "Partner"
                          : (item.partner && item.partner.status) || "Inactive",
                    },
                  }))}
                  defaultFilter={[{
                    field: "status",
                    value: "All",
                    isDefault: true,
                    columns: ["active", "pending", "inactive", "deleted"],
                  }]}
                />
                {showOnlyArchived && (
                  <div
                    className={classNames(
                      "filters-bar__item",
                      this.props.color
                    )}
                  >
                    <button type="button" className="filters-bar__button">
                      {searchName} Show archived
                      <div
                        className="filters-bar__remove"
                        onClick={this.onCloseShowArchived}
                      >
                        <XCircleIcon />
                      </div>
                    </button>
                  </div>
                )}
                <SearchField
                  onSearch={this.onSearch}
                  customClass={color}
                  resetSearch={this.state.resetFilter}
                  label="Search"
                />
              </div>
              <div>
                <>
                  <InfiniteScroll
                    initialLoad={false}
                    useWindow={false}
                    getScrollParent={() => scrollParent}
                  >
                    {activeView === TABLE_VIEW && (
                      <>
                        <List
                          data={
                            hasFilter
                              ? this.state.filteredWorkspace.filter(
                                (item, index) => {
                                  if (
                                    currentPage === 1 &&
                                    index < this.state.offset
                                  ) {
                                    return item;
                                  }

                                  if (
                                    currentPage !== 1 &&
                                    index < this.state.offset * currentPage &&
                                    index >=
                                    this.state.offset * (currentPage - 1)
                                  ) {
                                    return item;
                                  }

                                  return false;
                                }
                              )
                              : this.props.workspaces.filter(
                                (item, index) => {
                                  if (
                                    currentPage === 1 &&
                                    index < this.state.offset
                                  ) {
                                    return item;
                                  }

                                  if (
                                    currentPage !== 1 &&
                                    index < this.state.offset * currentPage &&
                                    index >=
                                    this.state.offset * (currentPage - 1)
                                  ) {
                                    return item;
                                  }

                                  return false;
                                }
                              )
                          }
                          onRowClick={this.onRowClick}
                          selectAll={this.selectAll}
                          onSort={this.onSort}
                          color={color}
                          onAddCharge={this.onAddCharge}
                          onAddCredit={this.onAddCredit}
                          isLoading={this.props.isLoading || this.state.fetching}
                          sort={this.state.sort}
                          isSelectedAll={this.getIsSelectedAll()}
                          selectedIds={selectedIds}
                          onSelect={this.onSelect}
                          onActivateAutoTraining={this.onActivateAutoTraining}
                          onDeactivateAutoTraining={this.onDeactivateAutoTraining}
                          onManageAgent={this.onManageAgent}
                          onDelete={this.onDeleteWorkspace}
                          onCancelSchedule={this.onCancelSchedule}
                          onDeactivate={this.onDeactiveWorkspace}
                          onActivate={this.onActivateWorkspace}
                          onScheduleDelete={this.onScheduleDeleteWorkspace}
                          onActivateAnnotation={this.onActivateAnnotation}
                          onDeactivateAnnotation={this.onDeactivateAnnotation}
                          onActivateTraining={this.onActivateTraining}
                          onDeactivateTraining={this.onDeactivateTraining}
                          onActivateDataset={this.onActivateDataset}
                          onDeactivateDataset={this.onDeactivateDataset}
                          onActivateIncident={this.onActivateIncident}
                          onDeactivateIncident={this.onDeactivateIncident}
                          onActivateDashboard={this.onActivateDashboard}
                          onDeactivateDashboard={this.onDeactivateDashboard}
                        // onUpdate={this.onUpdateWorkspace}
                        />
                      </>
                    )}
                    {activeView === CARD_VIEW && (
                      <div className="data-cards">
                        <Card
                          color={color}
                          data={
                            hasFilter
                              ? this.state.filteredWorkspace.filter(
                                (item, index) => {
                                  if (
                                    currentPage === 1 &&
                                    index < this.state.offset
                                  ) {
                                    return item;
                                  }

                                  if (
                                    currentPage !== 1 &&
                                    index < this.state.offset * currentPage &&
                                    index >=
                                    this.state.offset * (currentPage - 1)
                                  ) {
                                    return item;
                                  }

                                  return false;
                                }
                              )
                              : this.props.workspaces.filter(
                                (item, index) => {
                                  if (
                                    currentPage === 1 &&
                                    index < this.state.offset
                                  ) {
                                    return item;
                                  }

                                  if (
                                    currentPage !== 1 &&
                                    index < this.state.offset * currentPage &&
                                    index >=
                                    this.state.offset * (currentPage - 1)
                                  ) {
                                    return item;
                                  }

                                  return false;
                                }
                              )
                          }
                          onRowClick={this.onRowClick}
                          selectedItems={selectedIds}
                          onSelect={this.onSelect}
                          onDeactivateAutoTraining={this.onDeactivateAutoTraining}
                          onAddCharge={this.onAddCharge}
                          onAddCredit={this.onAddCredit}
                          isLoading={this.props.isLoading || this.state.fetching}
                          onManageAgent={this.onManageAgent}
                          onDeactivate={this.onDeactiveWorkspace}
                          onActivate={this.onActivateWorkspace}
                          onCancelSchedule={this.onCancelSchedule}
                          onScheduleDelete={this.onScheduleDeleteWorkspace}
                          onUpdate={this.onUpdateWorkspace}
                          onDelete={this.onDeleteWorkspace}
                          onActivateAnnotation={this.onActivateAnnotation}
                          onDeactivateAnnotation={this.onDeactivateAnnotation}
                          onActivateTraining={this.onActivateTraining}
                          onDeactivateTraining={this.onDeactivateTraining}
                          onActivateDataset={this.onActivateDataset}
                          onDeactivateDataset={this.onDeactivateDataset}
                          onActivateIncident={this.onActivateIncident}
                          onDeactivateIncident={this.onDeactivateIncident}
                          onActivateDashboard={this.onActivateDashboard}
                          onDeactivateDashboard={this.onDeactivateDashboard}
                        />
                      </div>
                    )}
                    {((hasFilter && !!this.state.filteredWorkspace.length) ||
                      (!hasFilter && !!this.props.workspaces.length)) &&
                      <Pagination
                        onNext={this.onNext}
                        currentPage={this.state.currentPage}
                        setCurrentPage={this.setCurrentPage}
                        onChangeLimit={this.onChangeLimit}
                        limit={this.state.offset}
                        totalPage={hasFilter ? this.state.filteredWorkspace.length : this.props.workspaces.length}
                      />
                    }
                  </InfiniteScroll>
                  {activeView === CARD_VIEW &&
                    workspaces &&
                    !this.props.isLoading &&
                    !workspaces.length && <NoWorkspaces />}
                </>
              </div>
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

WorkspaceList.propTypes = {
  color: PropTypes.string,
  workspaces: PropTypes.array,
  activeView: PropTypes.string,
};

WorkspaceList.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    workspaces: state.workspaces.list,
    isLoading: state.workspaces.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  listPlans,
  listWorkspaces,
  deleteWorkspaceAction,
  scheduleDeleteWorkspaceAction,
  archiveWorkspaceAction,
  deactivateWorkspaceAction,
  cancelScheduleDeleteWorkspaceAction,
  activateWorkspaceAction,
});

export default compose(withRouter, withConnect)(WorkspaceList);
