import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from 'config/config';
import { BASE_URL } from 'app/constants/common';

const DEFAULT_BILLING_VALUE= {
  id: true,
  name: true,
  country: true,
  address1: true,
  address2: true,
  city: true,
  postCode: true,
  vatNumber: true,
  billingContactId: {
    id: true,
    email: true,
    isEmailConfirmed: true,
    lastLogin: true
  }
}

const DEFAULT_BILLING_CATALOG_VALUE= {
  effectiveDate: true,
  updatedAt: true,
  xml: true
}


const DEFAULT_INVOICES = {
  hasMore: true,
  data: {
    id: true,
    number: true,
    amount_due: true,
    amount_paid: true,
    amount_remaining: true,
    invoice_pdf: true,
    status: true,
    currency: true,
    created: true
  }
}

export function getBilling() {
  const query = {
    query: {
      billingInformation: {
        ...DEFAULT_BILLING_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateBillingCatalog(args) {
  const query = {
    mutation: {
      updateBillingCatalog: {
        __args: args,
        ...DEFAULT_BILLING_CATALOG_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function billingCatalog() {
  const query = {
    query: {
      billingCatalog: {
        ...DEFAULT_BILLING_CATALOG_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getWorkspaceSubscriptions(payload) {
  const query = {
    query: {
      workspaceInvoices: {
        __args: payload,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function invoices(payload) {
  const query = {
    query: {
      invoices: {
        __args: payload,
        ...DEFAULT_INVOICES
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}
