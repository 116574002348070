import React, { Component } from "react";
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import ProcessPage from "app/components/common/ProcessPage";
import classNames from 'classnames';
import { defaultNotifier } from "functions/notificationHandler";

import {updateBillingCatalog} from 'app/api/billing';

let builder = '';
class Editor extends Component {
  state = {
    updatedXml: this.props.billingCatalog.xml
  }

  onChange = (value, a) => {
    this.setState({updatedXml: value})
  }

  onUpdate = async () => {
    const {data} = await updateBillingCatalog({
      xml: this.state.updatedXml.toString()
    });

    if(data.errors) {
      return defaultNotifier(data.errors);
    }
    defaultNotifier('Billing catalog content updated successfully.')
    this.props.onClose()
  }

  componentDidMount() {
    const {
      billingCatalog,
    } = this.props;

    setTimeout(() => {
        builder = monaco.editor.create(document.getElementById('container-code'), {
          value: billingCatalog.xml,
          language: 'xml',
          selectOnLineNumbers: true,
          roundedSelection: true,
          cursorStyle: 'line',
          theme: 'vs',
          automaticLayout: false,
        });
  
        builder.onDidChangeModelContent((event) => {
          const valueCurrent = builder.getValue();
          this.onChange(valueCurrent, event);
        });
    }, 100);
  }

  componentWillUnmount() {
    builder.dispose()
  }

  render() {
      return (
        <ProcessPage
          onCancel={() => this.props.onClose()}
        >
          {() => (
            <>
              <div className="headline">
                <h1 className="headline__title">Update Billing Catalog</h1>
                <h3 className="headline__subtitle">
                  Update the current Billing Catalog XML in Killbill
                </h3>
              </div>
              <div id='container-code'></div>
              <button
                onClick={this.onUpdate}
                className={classNames('button',
                  `button_${this.props.color} button_upload button_sm`,
                )}
              >
                Upload
              </button>
            </>
          )}
        </ProcessPage>
      );
}
}

export default Editor;
