import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import AppContainer from './AppContainer';
import store, {persistor} from './configureStore'

import 'styles/styles.scss'

import 'react-toastify/dist/ReactToastify.css'
import 'rc-slider/assets/index.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const MOUNT_NODE = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <AppContainer />
    </PersistGate>
  </Provider>,
  MOUNT_NODE
)
