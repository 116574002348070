import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { orderBy } from "lodash";

import SearchField from "app/components/common/SingleSearch";

import List from "./Table";
import { routeMapping } from "app/constants/routes";
import { getOutput } from "app/components/common/Filter/clientFilter";
import LoadingIndicator from "app/components/common/LoadingIndicator";

import {
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ACCENT,
} from "app/constants/common";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import ProcessPage from "app/components/common/ProcessPage";
import ListSelectedItems from "../../common/ListSelectedItems";
import { listWorkspaceSubscriptions } from "app/actions/plans";

class Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      selectedIds: [],
      showDropdown: false,
      sort: {
        key: "name",
        direction: "asc",
      },
      resetFilter: false,
      filter: {
        single: [],
        range: [],
      },
      searchName: "",
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onFetchWorkspaceSubscriptions = (filter) => {
    const { id } = this.props.plan;
    const { params } = this.props.match;

    const paramsId = params.id.includes('prod_') ? id : params.id;
 
    this.props.listWorkspaceSubscriptions({ planId: paramsId });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: 0,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.subscribedWorkspaces,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: 0,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: 0,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);

    const payload = this.props.subscribedWorkspaces.map((item) => ({
      ...item,
      name: item.workspaceId,
      status: item.status,
      quantity: item.quantity,
      created: item.created,
      createdAt: new Date(item.created * 1000),
    }));

    const finalResult = getOutput(filterData, payload);
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.workspaceName
          .toLowerCase()
          .includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: 0,
      },
      () => this.setState({ filterResult: this.props.subscribedWorkspaces })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.onFetchWorkspaceSubscriptions();
  }

  loadMore = () => {
    const { modules } = this.props;

    if (modules.loading) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE }, () =>
      this.onFetchWorkspaceSubscriptions()
    );
  };

  isMore = () => {
    return false;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.WORKSPACE.path}/${id}`);
  };

  addModule = () => {
    this.props.history.push(routeMapping.IMPORT_MODULE.path);
  };

  onRedirectToPlan = (id) => {
    return this.props.history.push(`${routeMapping.PLANS.path}`);
  };

  onClose = () => {
    return this.props.history.push(routeMapping.WORKSPACES.path);
  };

  render() {
    const { color, plan } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const name = query.get("name");

    const { selectedIds, resetFilter } = this.state;

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

      if(this.props.isLoading) {
        return <LoadingIndicator
          color={this.props.color}
        />
      }
      
    return (
      <ProcessPage onCancel={this.props.onClose || this.onRedirectToPlan}>
        {(scrollRef) => (
          <>
            <div className="headline">
              <h1 className="headline__title">Subscribed Workspaces</h1>
              <h3 className="headline__subtitle">{name || plan.name}</h3>
            </div>
            <div className="popup__filters-bar filters-bar">
              <ListSelectedItems
                selectedNumber={selectedIds.length}
                search={this.state.searchName}
                onReset={this.onReset}
                filterData={this.state.filter}
                containerClassName={color}
              />
              <Filter
                customClass={color}
                resetFilter={resetFilter}
                onChange={this.onFilter}
                columns={filterColumns}
                data={this.props.subscribedWorkspaces}
                defaultFilter={[{
                  field: "status",
                  value: "All",
                  isDefault: true,
                  columns: ["active", "trialing"],
                }]}
              />
              <SearchField
                onSearch={this.onSearch}
                customClass={color}
                resetSearch={this.state.resetFilter}
                label="Search"
              />
            </div>
              <InfiniteScroll
                className="data-table"
                loadMore={this.debouncedLoadMore}
                hasMore={false}
                initialLoad={false}
                loader={
                  <div className="ml-3" key={0}>
                    Loading...
                  </div>
                }
                useWindow={false}
                getScrollParent={() => this.scrollParent}
              >
                <List
                  color={color}
                  data={
                    hasFilter
                      ? this.state.filterResult
                      : this.props.subscribedWorkspaces
                  }
                  selectedIds={selectedIds}
                  onSortChange={this.onSorting}
                  sort={this.state.sort}
                  onRowClick={this.onRowClick}
                  onSelect={this.onSelect}
                  isSelectedAll={this.selectAll}
                />
              </InfiniteScroll>
          </>
        )}
      </ProcessPage>
    );
  }
}

Subscription.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

Subscription.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    plan: state.plans.detail,
    isLoading: state.plans.isLoading,
    subscribedWorkspaces: state.plans.workspaces,
  };
}

const withConnect = connect(mapStateToProps, {
  listWorkspaceSubscriptions,
});

export default compose(withRouter, withConnect)(Subscription);
