export const ALL = 'All'
export const DATE_FORMAT = 'DD-MMM-YYYY'
export const FILTER_DATE = {
  END_DATE: 'start_date_filter',
  START_DATE: 'start_date_filter'
}
export const FILTER_TYPE = {
  DATE: 'date',
  RADIO: 'radio',
  SEARCH: 'search',
  SINGLE_RANGE: 'singleRange',
  MULTI_RANGE: 'multiRange',
  CHECKBOX: 'checkbox',
  MULTI_SELECT: 'multiSelect',
  SINGLE_SELECT: 'singleSelect'
}
