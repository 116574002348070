import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import SortableRow from "app/components/common/Table/SortableRow";
import NumberFormat from "react-number-format";
import { FormattedMessage } from "react-intl";

import { dateFormatter } from "utils/common";

class UserRow extends PureComponent {
  getStatus = (status) => {
    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === "paid",
        "data-table__status_neg": status !== "paid",
      }
    );

    return <div className={className}>{status}</div>;
  };

  getRowRender = () => {
    const {
      startDate,
      endDate,
      invoiceId,
      colorClassName,
      amount,
      refundAdj,
      balance,
      creditAdj,
      status,
      invoice_pdf,
      prettyPlanName,
      description,
      currency,
    } = this.props;

    return {
      name: () => (
        <div className="data-table__td-dark">
          {prettyPlanName || description}
        </div>
      ),
      startDate: () => (
        <div className="">{dateFormatter(startDate)}</div>
      ),
      endDate: () => (
        <div className="">{endDate && dateFormatter(endDate)}</div>
      ),
      invoiceId: () => <div className="">{invoiceId}</div>,
      status: () => <span className="">{this.getStatus(status)}</span>,
      amount: () => (
        <NumberFormat
          value={(amount && parseFloat(amount).toFixed(2)) || "0.00"}
          displayType={"text"}
          thousandSeparator
          renderText={(value) => (
            <span>
              {currency && currency.toUpperCase()} {value}
            </span>
          )}
        />
      ),
      refund: () => (
        <NumberFormat
          value={(refundAdj && parseFloat(refundAdj).toFixed(2)) || "0.00"}
          displayType={"text"}
          thousandSeparator
          renderText={(value) => (
            <span>
              {currency && currency.toUpperCase()} {value}
            </span>
          )}
        />
      ),
      balance: () => (
        <NumberFormat
          value={(balance && parseFloat(balance).toFixed(2)) || "0.00"}
          displayType={"text"}
          thousandSeparator
          renderText={(value) => (
            <span>
              {currency && currency.toUpperCase()} {value}
            </span>
          )}
        />
      ),
      credit: () => (
        <NumberFormat
          value={(creditAdj && parseFloat(creditAdj).toFixed(2)) || "0.00"}
          displayType={"text"}
          thousandSeparator
          renderText={(value) => (
            <span>
              {currency && currency.toUpperCase()} {value}
            </span>
          )}
        />
      ),
      actions: () => (
        <a
          download
          href={invoice_pdf}
          target="_blank"
          rel="noopener noreferrer"
          className={`${colorClassName}`}
        >
          Download
        </a>
      ),
    };
  };

  render() {
    const { colorClassName } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        />
        <td></td>
      </>
    );
  }
}

UserRow.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

export default withRouter(UserRow);
