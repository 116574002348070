import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";

import { getInitials, isArrayEqual } from "utils/common";

import TableHead from "./TableHead";
import { TickIcon } from "images/common";
import NotFound from "app/components/NotFound";
import LoadingIndicator from "app/components/common/LoadingIndicator";

class Table extends Component {
  constructor(props) {
    super(props);

    const initialColumns = props.columns.map((column) => {
      if (column.isOptional) {
        return { ...column, isVisible: false };
      }

      return { ...column, isVisible: true };
    });

    this.state = {
      columns: initialColumns,
      settingSelected: null,
      // isFirstTime: true,
    };
  }

  handleWindowSizeChange = () => {
    if (window.innerWidth <= 1200) {
      const initialColumns = this.props.columns.map((column, index) => {
        if (column.isOptional) {
          return { ...column, isVisible: false };
        }

        if (index < 4) {
          return { ...column, isVisible: true };
        }
        return { ...column, isVisible: false };
      });

      this.onColumnsChange(initialColumns);
    }

    if (window.innerWidth <= 1050) {
      const initialColumns = this.props.columns.map((column, index) => {
        if (column.isOptional) {
          return { ...column, isVisible: false };
        }

        if (index < 2) {
          return { ...column, isVisible: true };
        }
        return { ...column, isVisible: false };
      });

      this.onColumnsChange(initialColumns);
    }

    if (window.innerWidth > 1200) {
      const initialColumns = this.props.columns.map((column) => {
        if (column.isOptional) {
          return { ...column, isVisible: false };
        }

        return { ...column, isVisible: true };
      });

      this.onColumnsChange(initialColumns);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!isArrayEqual(this.props.columns, nextProps.columns)) {
      const initialColumns = nextProps.columns.map((column) => {
        if (column.isOptional) {
          return { ...column, isVisible: false };
        }

        return { ...column, isVisible: true };
      });

      this.state = {
        columns: initialColumns,
        settingSelected: null,
      };
    }
  }

  onSelectSetting = (id) => {
    this.setState({ settingSelected: id });
  };

  onClearSelectedSetting = () => {
    this.setState({ settingSelected: null });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  renderRows() {
    const {
      rows,
      rowKey,
      inputType,
      rowClassName,
      hideSingleMenu,
      rowClick,
      hasImg,
      isSelectable,
      RowComponent,
      onPushProfile,
      onShowDeleteModal,
      onAssignProfile,
      isUserSetting,
      isIndex,
      isPriceSetting,
      monthlyPriceId,
      yearlyPriceId,
      isPaymentSetting,
      selectedItems = [],
    } = this.props;
    const { isFirstTime } = this.state;
    return rows
      .sort(
        (row1, row2) =>
          isFirstTime &&
          selectedItems.includes(row2.id) - selectedItems.includes(row1.id)
      )
      .map((row, index) => (
        <tr
          key={rowKey ? row[rowKey] : index}
          onClick={(e) => {
            if(rowClick) {
              return rowClick(row.id, e);
            }
            this.toggleSelect(row, e);
          }}
          className={classNames("data-table__row", rowClassName, {
            isActive: this.state.settingSelected === row.id,
            "data-table__row_selected": isSelectable && this.isSelected(row),
            [`data-table__row_${this.props.colorClassName}`]: this.props
              .colorClassName,
          })}
        >
          {isUserSetting && (
            <td className="data-table__td">
              <div className={`checkbox checkbox_${this.props.colorClassName}`}>
                <input
                  type="checkbox"
                  className="checkbox__input"
                  checked={this.isSelected(row)}
                  readOnly
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleSelect(row, e);
                  }}
                />
                <div className="checkbox__image">
                  {getInitials(row.firstName, row.lastName)}
                </div>
              </div>
            </td>
          )}
          {isPaymentSetting && (
            <td className="data-table__td">
              <div className={`checkbox checkbox_${this.props.colorClassName}`}>
                <input
                  type="checkbox"
                  className="checkbox__input"
                  checked={this.isSelected(row)}
                  readOnly
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleSelect(row, e);
                  }}
                />
                <div className="checkbox__image">
                  P
                </div>
              </div>
            </td>
          )}
                    {isIndex && (
            <td className="data-table__td">
              <div className={`checkbox checkbox_${this.props.colorClassName}`}>
                <input
                  type="checkbox"
                  className="checkbox__input"
                  checked={this.isSelected(row)}
                  readOnly
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleSelect(row, e);
                  }}
                />
                <div className="checkbox__image">
                  {index + 1}
                </div>
              </div>
            </td>
          )}
          {isPriceSetting && (
            <>
              <td className="data-table__td">
                <div
                  className={`checkbox checkbox_${this.props.colorClassName}`}
                >
                  {monthlyPriceId === row.id && (
                    <span
                      className={`data-cards__selected ${this.props.colorClassName}`}
                    >
                      <TickIcon />
                    </span>
                  )}
                  {yearlyPriceId === row.id && (
                    <span
                      className={`data-cards__selected ${this.props.colorClassName}`}
                    >
                      <TickIcon />
                    </span>
                  )}
                  <input
                    type="checkbox"
                    className="checkbox__input"
                    checked={this.isSelected(row)}
                    readOnly
                    onClick={(e) => {
                      e.stopPropagation();
                      this.toggleSelect(row, e);
                    }}
                  />
                  <div className="checkbox__image">
                    {this.props.getCurrencyIcon[row.currency] ||
                      row.currency.toUpperCase()}
                  </div>
                </div>
              </td>
            </>
          )}

          {!isUserSetting && !isPriceSetting && isSelectable && !isIndex && !isPaymentSetting && (
            <td className="data-table__td">
              <div
                className={classNames(
                  "checkbox",
                  this.props.checkboxClassName,
                  {
                    [`checkbox_${this.props.colorClassName}`]: this.props
                      .colorClassName,
                  }
                )}
                onClick={(ev) => {
                  this.setState({ isFirstTime: false });
                  ev.stopPropagation();
                  this.toggleSelect(row, ev);
                }}
              >
                {inputType === "radio" ? (
                  <div className="radio">
                    <input
                      type="radio"
                      name="row"
                      className="radio__input"
                      checked={this.isSelected(row)}
                    />
                    <div className="radio__check" />
                  </div>
                ) : (
                  <>
                    <input
                      checked={this.isSelected(row)}
                      type="checkbox"
                      className="checkbox__input"
                      readOnly
                    />
                    <div className="checkbox__image">
                      {hasImg ? (
                        this.props.CheckboxImage
                      ) : (
                        <this.props.CheckboxImage row={row} />
                      )}
                    </div>
                  </>
                )}
              </div>
            </td>
          )}
          <RowComponent
            {...row}
            {...this.props}
            onSelectSetting={this.onSelectSetting}
            settingSelected={this.state.settingSelected}
            onClearSelectedSetting={this.onClearSelectedSetting}
            hideSingleMenu={hideSingleMenu}
            onShowDeleteModal={onShowDeleteModal}
            {...this.props.rowProps}
            onPushProfile={onPushProfile}
            onAssignProfile={onAssignProfile}
            columns={this.state.columns}
            colorClassName={this.props.colorClassName}
            index={index}
          />
        </tr>
      ));
  }

  renderLoading() {
    const {
      rowClassName,
    } = this.props;

    return [1, 2, 3, 4, 5, 6, 7, 8]
      .map((row, index) => (
        <tr
          key={index}
          className={classNames('data-table__row', rowClassName, {
          })}
        >
          <td className="data-table__td">
            <div className={`checkbox checkbox_${this.props.colorClassName}`}>
              <input
                type="checkbox"
                className="checkbox__input"
                checked={this.isSelected(row)}
                readOnly
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ isFirstTime: false });
                }}
              />
              <div className="checkbox__image">
              </div>
            </div>
          </td>
          <td className={classNames('data-table__td')}/>
          <td className={classNames('data-table__td')}/>
          <td className={classNames('data-table__td')}/>
          <td className={classNames('data-table__td')}/>
          <td className={classNames('data-table__td')}/>
          <td className={classNames('data-table__td')}/>
        </tr>
      ));
  }
  
  onColumnsChange = (columns) => {
    this.setState({ columns });
  };

  renderEmptyComponent = () => {
    if (this.props.EmptyRowComponent) {
      return this.props.EmptyRowComponent();
    }

    return (
      <NotFound
        parentClass="no-result__container"
        color={this.props.colorClassName}
        strokeWidth="1"
        logo={this.props.notFoundIcon}
        title="No results found"
        description="Try adjusting your search or filter to find what you are looking for."
      />
    );
  };

  renderTableBody = () => {
    const { allowOnlySingleSelect, inputType } = this.props;

    // if (this.props.isLoading) {
    //   return (
    //     <LoadingIndicator
    //       customClass="loading-table"
    //       color={this.props.colorClassName}
    //     />
    //   );
    // }

    return (
      <>
        <table
          className={classNames("data-table__table", this.props.tableClassName)}
        >
          <TableHead
            inputType={this.props.inputType}
            onUpdateSort={this.props.onUpdateSort}
            currentSortDir={this.props.currentSortDir}
            currentSortKey={this.props.currentSortKey}
            columns={this.props.isLoading
              ?
              [
                {isVisible: true},
                {isVisible: true},
                {isVisible: true},
                {isVisible: true},
                {isVisible: true},
              ]
              :
              this
                .state.columns}
            isSelectable={this.props.isSelectable}
            isSelectedAll={
              this.props.isSelectable ? this.isSelectedAll() : false
            }
            hasData={!!this.props.rows.length}
            isSelectable={this.props.isSelectable}
            isSelectedAll={
              this.props.isSelectable ? this.isSelectedAll() : false
            }
            selectAll={this.selectAll}
            hideCheckbox={allowOnlySingleSelect || inputType === "radio"}
            colorClassName={this.props.colorClassName}
            withColumnSelect={this.props.withColumnSelect}
            onColumnsChange={this.onColumnsChange}
          />
          <tbody className="data-table__body">
          {!!this.props.rows.length && !this.props.isLoading && this.renderRows()}
            {this.props.isLoading && this.renderLoading()}
            {!this.props.rows.length && !this.props.isLoading && (
              <tr className="data-table__row">
                <td className="data-table__td_empty"></td>
              </tr>
            )}
          </tbody>
        </table>
        {!this.props.rows.length
          && !this.props.isLoading
          && this.renderEmptyComponent()}
      </>
    );
  };

  isSelectedAll = () => {
    if (this.props.rows && !this.props.rows.length) {
      return false;
    }
    return (
      (this.props.selectedItems && this.props.selectedItems.length) ===
      (this.props.rows && this.props.rows.length)
    );
  };

  isSelected = (row) => {
    const { selectedItems = [] } = this.props;

    return selectedItems.indexOf(row.id) > -1;
  };

  selectAll = (e) => {
    if (this.isSelectedAll()) {
      return this.props.onSelect([], e);
    }

    const allSelected = this.props.rows.map((row) => row.id);

    this.props.onSelect(allSelected, e);
  };

  toggleSelect = (row, e) => {
    const {
      onSelect,
      selectedItems = [],
      inputType,
      allowOnlySingleSelect,
    } = this.props;

    if (allowOnlySingleSelect) {
      return onSelect(row.id, e);
    }
    if (inputType === "radio") {
      return onSelect(row.id, e);
    }
    if (this.isSelected(row)) {
      onSelect(
        selectedItems.filter((item) => item !== row.id),
        e
      );
    } else {
      onSelect(selectedItems.concat(row.id), e);
    }
  };

  render() {
    return (
      <div className={classNames('', {
        'data-table__loading': !this.props.hideLoader && this.props.isLoading,
        'data-table': !this.props.isLoading,
      })}
      >
        {this.renderTableBody()}
      </div>
    );
  }
}

Table.propTypes = {
  isUserSetting: PropTypes.bool,
  inputType: PropTypes.string,
  columns: PropTypes.array.isRequired,
  currentSortKey: PropTypes.string,
  currentSortDir: PropTypes.string,
  EmptyRowComponent: PropTypes.func,
  RowComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  CheckboxImage: PropTypes.func,
  rowClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  rowClick: PropTypes.func,
  rowKey: PropTypes.string,
  rows: PropTypes.array.isRequired,
  rowProps: PropTypes.object,
  tableClassName: PropTypes.string,
  onUpdateSort: PropTypes.func,
  isSelectable: PropTypes.bool,
  selectedItems: PropTypes.array,
  onSelect: PropTypes.func,
  onPushProfile: PropTypes.func,
  onAssignProfile: PropTypes.func,
  selectAllClass: PropTypes.string,
  colorClassName: PropTypes.string,
  withColumnSelect: PropTypes.bool,
};

Table.defaultProps = {
  onUpdateSort: (f) => f,
  rowClick: (f) => f,
};

export default Table;
