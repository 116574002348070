import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import CardTags from "app/components/common/Detail/CardTags";

class Information extends Component {
  renderContent = () => {
    const { workspace } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.reference} />
          </div>
          <div className="overview__value">{workspace.name}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.internalNote} />
          </div>
          <div className="overview__value">{workspace.organizationSize}</div>
          <div className="overview__title" />
          <div className="overview__label">Tags</div>
          <div className="overview__value">
            <CardTags
              color={this.props.color}
              tags={workspace.userExperiences1}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title="Information"
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Information;
