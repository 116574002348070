import React, { Component } from 'react';
import { compose } from 'redux';
import className from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl'
import { EnumType } from 'json-to-graphql-query'

import messages from 'utils/message'
import CustomInput from 'app/components/common/Input/CustomInput';
import { routeMapping } from 'app/constants/routes'
import CustomSelect from 'app/components/common/Input/CustomSelect'
import CustomMultiSelect from 'app/components/common/Input/CustomMultiSelect'

import { createModel, getModelFramework } from 'app/api/model';
import Tags from 'app/components/common/Tags'
import CustomTextarea from 'app/components/common/Input/CustomTextarea'

import ProcessPage from 'app/components/common/ProcessPage';
import {LIBRARY_ACCENT} from 'utils/constants'

import ModelUploader from 'app/components/FileUploader';
import TextUploader from 'app/components/TextUploader';
import {
  successNotifier,
  defaultNotifier,
} from 'functions/notificationHandler'
import {modelUpload, onClearFile} from 'app/actions/models'

import {MODEL_TYPE, LICENSE, DEVICE_TYPE, MODEL_DATASET, MODEL_ARCHITECTURE} from './constants';

const NONE = [
  {
    label: 'None',
    value: 'none'}
]

class ImportModule extends Component {
  state = {
    name: '',
    description: '',
    gitRepositoryUrl: '',
    categories: [],
    deviceTypes: [],
    username: '',
    password: '',
    passphrase: '',
    flow: '',
    tags: [],
    isLoading: false,
    sshKey: '',
    type: null,
    version: '',
    modelArchitecture: ''
  };

  onChangeField = (name, value) => {
    this.setState({[name]: value})
  }


  async componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({ id });

    const {data} = await getModelFramework();

    if(data.errors) {
      return this.setState({frameworkList: []})
    }

    this.setState({frameworkList: data.data.modelFrameworks.map(item => ({value: item.key, label: item.name})) || []})

    // const isLimitExceeded = workspace.plan && application.count && allowCreationBasedOnQuota(
    //   application.count,
    //   workspace.plan.features.applicationLimit
    // );

    // if (isLimitExceeded) {
    //   return this.setState({ showQuotaModal: true})
    // }

  }

  componentWillUnmount() {
    this.props.onClearFile()
  }

  onKeyPress = e => {
    if (e.key === 'Enter') {
      this.props.onNext()
    }
  }

  onRedirectToModel = () => {
    this.props.history.push(routeMapping.MODELS.path)
  };

  onSubmit = async () => {
    const {
      name,
      description,
      tags,
      modelType,
      framework,
      deviceTypes,
      dataset,
      license,
      version,
      labels,
      modelArchitecture,
      imageSize } = this.state;
    const {file} = this.props;
    this.setState({isLoading: true});
    if (name
       && description
         && modelType
          && framework
           && !!deviceTypes.length
            && dataset
             && labels
              && imageSize
               && file.url) {
      await createModel({
        name,
        description,
        tags,
        version,
        modelArchitecture: modelArchitecture,
        modelType: modelType ? new EnumType(modelType) : null,
        framework: framework || null,
        deviceTypes: deviceTypes.length ? deviceTypes.map(device => new EnumType(device)) : null,
        dataset: dataset,
        license,
        imageSize,
        labels,
        s3Key: file.url,
      })
        .then(res => {
          const {errors, data} = res.data
          this.setState({isLoading: false})

          if (errors) {
            this.setState({isLoading: false})

            return defaultNotifier(errors)
          }

          if (data && data.createModel) {
            successNotifier('Model imported successfully');

            return this.onRedirectToModel()
          }
        })
        .catch(err => {
          this.setState({isLoading: false})

          defaultNotifier(err)
        })
    }
  }


  onAddTags = (tags) => {
    this.setState({tags,
      isChanged: true,
    })
  }

  onChange = (name, value) => {
    this.setState({[name]: value})
  }

  addTextFile = (file) => {

    const reader = new FileReader();

    reader.onload = async (e) => {
      const text = (e.target.result)

      this.setState({textFile: file.file})
      if (text.includes('\r\n')) {
        return this.setState({labels: text.split('\r\n')})
      }
      return this.setState({labels: text.split('\n')})
    };

    reader.readAsText(file.file)
  }

  updateFile = (file, config) => {

    this.props.modelUpload(file, config);
  }

  removeTextFile = () => {
    this.setState({textFile: '', labels: ''})
  }


  render() {
    const {
      name,
      isLoading,
      categories,
      modelType,
      framework,
      dataset,
      license,
      deviceTypes,
      description,
      imageSize,
      labels,
      version,
      modelArchitecture,
      s3Key} = this.state
    const { color, file } = this.props;

    const submitClass = className('button', {
      [`button_${color}`]: color,
    });

    return (
      <ProcessPage
        onCancel={this.onRedirectToModel}
      >
        {() => (
          <>
          <div>
            <div className="headline">
              <h1 className="headline__title center headline_title__register">
              Add a custom AI model
              </h1>
              <h3 className="headline__subtitle">
              Upload your custom AI model to your Viso Workspace. The model will appear in your model library.
              </h3>
            </div>
            <div className="login">
              <div className="login__form">
                <div className={`text-field text-field_${color}`}>
                  <FormattedMessage {...messages.name}>
                    {(msg) =>
                      <CustomInput
                        onChange={(e) => this.onChange('name', e.target.value)}
                        name='name'
                        value={name}
                        label={msg}
                      />
                    }
                  </FormattedMessage>
                </div>
                <div className={`text-field text-field_${color}`}>
                  <FormattedMessage {...messages.description}>
                    {(msg) =>
                      <CustomTextarea
                        parentClass={color}
                        value={this.state.description}
                        onChange={e => this.onChange('description', e.target.value)}
                        label={msg}
                        type="textarea"
                      />
                    }
                  </FormattedMessage>
                </div>
                <div className={`text-field text-field_${color}`}>
                  <Tags
                    tags={this.state.tags}
                    tagClass={color}
                    onChange={this.onAddTags}
                  />
                </div>
                <div className={`text-field text-field_${color}`}>
                    <FormattedMessage {...messages.version}>
                      {msg => (
                        <CustomInput
                          onChange={e => this.onChange('version', e.target.value)}
                          name="version"
                          dataCy="model-name"
                          value={version}
                          label={msg}
                          isInvalid={!this.state.version}
                          hintText={
                            this.state.version ? (
                              ''
                            ) : (
                              <FormattedMessage {...messages.required} />
                            )
                          }
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <CustomSelect
                    color="accent3"
                    value={modelArchitecture}
                    dataCy="model-dataset"
                    onChange={value => this.onChange('modelArchitecture', value)}
                    label="Model Architecture"
                    options={MODEL_ARCHITECTURE}
                  />
                <CustomSelect
                  color='accent3'
                  value={modelType}
                  onChange={value => this.onChange('modelType', value)}
                  label={'Model type'}
                  options={MODEL_TYPE}
                />
                <CustomSelect
                  color='accent3'
                  value={framework}
                  onChange={value => this.onChange('framework', value)}
                  label={'Framework'}
                  options={this.state.frameworkList}
                />
                <div className={`text-field text-field_${color}`}>
                  <FormattedMessage {...messages.imageSizeEg}>
                    {(msg) =>
                      <CustomInput
                        onChange={(e) => this.onChange('imageSize', e.target.value)}
                        name='name'
                        value={this.state.imageSize}
                        label={msg}
                      />
                    }
                  </FormattedMessage>
                </div>
                <CustomSelect
                  color='accent3'
                  value={dataset}
                  onChange={value => this.onChange('dataset', value)}
                  label={'Dataset'}
                  options={MODEL_DATASET}
                />
                  <CustomSelect
                    color="accent3"
                    value={license}
                    editable
                    // onCreate={value => this.onChange('license', value.name)}
                    dataCy="model-dataset"
                    onChange={value => this.onChange('license', value)}
                    label="License"
                    options={LICENSE}
                  />
                <div className={`select-field select-field_${color}`}>
                  <CustomMultiSelect
                    value={this.state.deviceTypes}
                    color={this.props.color}
                    disabled
                    parentClass={`select-field select-field_${color}`}
                    onChange={value => this.onChange('deviceTypes', value)}
                    options={DEVICE_TYPE}
                    label={'Device types'}
                  />
                </div>
                <div className={`text-field text-field_${color}`}>
                  <ModelUploader
                    color={color}
                    title={'Model file (tar.xz/tar.gz)'}
                    uploading={file.uploading}
                    onRemove={this.props.onClearFile}
                    onUpdate={this.updateFile}
                    moduleFile={this.props.file}/>
                </div>
                <div className={`text-field text-field_${color}`}>
                  <TextUploader
                    color={color}
                    accept={'text/*'}
                    title={'Model classes (.txt)'}
                    uploading={file.uploading}
                    onRemove={this.removeTextFile}
                    onUpdate={this.addTextFile}
                    file={this.state.textFile}/>
                </div>
                <div className="login__actions">
                  <button
                    onClick={this.onSubmit}
                    disabled={file.uploading || !file.url || isLoading
                      || !(name
                      && description
                        && modelType
                         && framework
                          && deviceTypes
                           && dataset
                            && labels
                             && imageSize)
                    }
                    className={submitClass}>
                    {isLoading ?
                      'Creating...'
                      : 'Add model'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

ImportModule.propTypes = {
  color: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    file: state.model.file,
    isLoading: state.model.isLoading,
  };
}

const withConnect = connect(
  mapStateToProps, {modelUpload, onClearFile}
);

ImportModule.defaultProps = {
  color: LIBRARY_ACCENT
};

export default compose(
  withRouter,
  withConnect,
)(ImportModule);
