import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'

const MAX_TAGS_COUNT = 2

class CategoryTags extends Component {
  constructor(props) {
    super(props)

    this.tagsRef = React.createRef()
  }

  getExtraTagsLength = () => {
    return this.getExtraTags().length
  }

  getExtraTags = () => {
    return this.props.categories.slice(MAX_TAGS_COUNT)
  }

  renderExtraTags = () => {
    const extraTagsLength = this.getExtraTagsLength()
    const extraTags = this.getExtraTags()

    return (
      <>
        <div className="tags__count" ref={this.tagsRef}>+{extraTagsLength}</div>
        <UncontrolledPopover trigger="hover" target={this.tagsRef} className="tags-popover">
          <PopoverBody>
            { extraTags.map((extraTag) => (
              <div className="tag-popover" key={extraTag}>{ extraTag.name }</div>
            ))}
          </PopoverBody>
        </UncontrolledPopover>
      </>
    )
  }

  render() {
    const {icon, onAction} = this.props

    const extraTagsLength = this.getExtraTagsLength()

    return (
      <div className={classNames('tags', { [`tags_${this.props.colorClassName}`]: this.props.colorClassName })}>
        { this.props.categories.slice(0, MAX_TAGS_COUNT).map(category => {
          return <div key={category.id} className="tags__item" onClick={(e) => onAction(category.id, e)}>{ category.name }</div>
        }
        )}
        { extraTagsLength > 0 && this.renderExtraTags() }
      </div>
    )
  }
}

CategoryTags.propTypes = {
  tags: PropTypes.array,
  colorClassName: PropTypes.string
}

CategoryTags.deaultProps = {
  tags: []
}

export default CategoryTags
