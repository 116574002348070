const filterColumns = [
  {
    id: 1,
    label: 'Status',
    field: 'status',
    type: 'radio',
  },
  {
    id: 2,
    label: 'Worskpace',
    field: 'name',
    type: 'search',
  },
  {
    id: 3,
    label: 'Worskpace ID',
    field: 'id',
    type: 'checkbox',
  },
  {
    id: 4,
    label: 'Owner',
    field: 'owner',
    filterBy: 'id',
    type: 'multiSelect',
  },
  {
    id: 4,
    label: 'Partner',
    field: 'partnerData',
    type: 'checkbox',
  },
  {
    id: 5,
    label: 'Created',
    field: 'createdAt',
    type: 'date',
  }]
  
  export default filterColumns