import moment from 'moment'

export const getOutput = (filterData, allResult) => {
  let result = []
  const rangeResult = []
  let currentResult = []
  let finalResult = allResult
  let isFirstLoop = true
  let isFirstLoopRange = true

  const isIdType = ['id']
  const isObjectType = ['createdBy', 'partnerData', 'workspace']
  const isEnumType = ['billingPeriod', 'category']
  const isNameType = ['type', 'productId', 'priceId', 'name', 'firstName', 'email', 'workspaceName']
  const isDateType = ['updated_at', 'createdAt', 'updatedAt', 'created', 'lastLogin', 'invoiceDate']
  const isRadioType = ['status', 'interval', 'quantity', 'modelType', 'dataset', 'framework', 'paymentMethod']

  filterData.single.forEach(single => {
    currentResult = [];
    finalResult = [];
    
    const filteredValue = isFirstLoop ? allResult : result
    const items = filteredValue.filter(item => {

      if (isIdType.includes(single.name)) {
        return single.value.includes(item[single.name])
      }

      if (isEnumType.includes(single.name)) {
        return single.value.includes(item[single.name])
      }

      if (isObjectType.includes(single.name)) {
        return single.value.includes(item[single.name] && item[single.name].name)
      }

      if (['owner'].includes(single.name)) {
        return single.value.includes(item[single.name].id)
      }

      if (single.name === 'username') {
        const fullName = `${item.firstName} ${item.lastName}`;

        return (fullName && fullName.toLowerCase()).includes(single.value.toLowerCase())
      }

      if (isNameType.includes(single.name)) {
        return (item[single.name] && item[single.name].toLowerCase()).includes(single.value.toLowerCase())
      }

      if (single.name === 'version') {
        return single.value.includes(item[single.name])
      }

      if (isRadioType.includes(single.name)) {
        if (single.name === 'status' || single.name === 'dataset' || single.name === 'framework') {
          return single.value === item[single.name]
        }
        
        return single.value === item[single.name] || single.value === 'All'
      }

      if (single.name === 'keywords') {
        return single.value.find(value => item[single.name].toLowerCase().includes(value.toLowerCase()))
      }

      return false
    })
    isFirstLoop = false
    result = items
    currentResult.push(...items)
    finalResult.push(...items)
  })

  filterData.range.forEach(range => {
    finalResult = [];
    const data = filterData.single.length ? currentResult : allResult

    const filteredValue = isFirstLoopRange
      ?
      data
      :
      rangeResult

    let dateRange = []

    if (isDateType.includes(range.name)) {
      dateRange = filteredValue.filter(item => {
        const isDateGreaterThan = moment(range.value.upper).isSameOrAfter(item[range.name])
        const isDateSmallerThan = moment(item[range.name]).isSameOrAfter(range.value.lower)

        return isDateGreaterThan && isDateSmallerThan
      })
    }

    if (range.type === 'singleRange') {
      dateRange = filteredValue.filter(item => item[range.field] <= range.filterValue[0])
    }

    if (range.name === "workspaceCount") {
      dateRange = filteredValue.filter(item => item[range.name] <= range.value.upper)
    }

    isFirstLoopRange = false
    rangeResult.push(...dateRange)
    finalResult.push(...dateRange)
  })

  return finalResult
}