import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

import CustomInput from "app/components/common/Input/CustomInput";
import CustomTextarea from "app/components/common/Input/CustomTextarea";
import ProcessPage from "app/components/common/ProcessPage";
import Tags from "app/components/common/Tags";
import EditForm from "app/components/common/Edit/EditForm";
import CustomSelect from "app/components/common/Input/CustomSelect";
import workspace from "images/common/workspace";
import { NET_OPTIONS } from "app/constants/common";


class EditInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChanged: false,
      notes: '',
      bank: false,
      creditCard: false,
      paymentTerms: '30'
    };
  }

  onUpdatePayment = (term) => {
    this.setState({paymentTerms: term})
  }

  componentDidMount() {
    const { workspace: {kilBillInfo, availablePaymentMethods, paymentTerms} } = this.props;
    
    this.setState({
      notes: kilBillInfo.notes,
      paymentTerms: paymentTerms.toString(),
      bank: availablePaymentMethods.bank,
      creditCard: availablePaymentMethods.creditCard
    })
  }

  onFieldChange = (e, name) => {
    this.setState({
      isChanged: true,
      [name]: e.target.value,
    });
  };

  onUpdateModal = (name) => {
    this.setState({[name]: !this.state[name]})
  }

  onSubmit = () => {
    const {bank, creditCard} = this.state;

    if(this.state.bank || this.state.creditCard){
      this.props.onUpdateSettings({
        paymentTerms: parseInt(this.state.paymentTerms),
        availablePaymentMethods: {
          bank,
          creditCard
        }
      })
    }
  };

  renderContent = () => {
    const { color } = this.props;

    return (
      <>
        <div className={`text-field text-field_${color}`}>
        <CustomSelect
          value={this.state.paymentTerms}
          label="Payment Terms"
          onChange={this.onUpdatePayment}
          options={NET_OPTIONS}
        />
        </div>
        <div className={`text-field text-field_${color}`}>
        <div className="modal__checkbox__title">Available Payment Methods</div>
        <div className="modal__checkbox">
          <div className={`checkbox checkbox_blocked checkbox_${color} pointerNone`}>
            <input
              type="checkbox"
              name="new"
              className={`checkbox__input modal__checkbox__input checkbox_${color}`}
              // onClick={() => this.onUpdateModal("creditCard")}
              checked={this.state.creditCard}
            />
            <div
              className="checkbox__check"
              // onClick={() => this.onUpdateModal("creditCard")}
            />
            <label
              className="checkbox__label modal__checkbox__label label-color-imp"
              // onClick={() => this.onUpdateModal("creditCard")}
            >
              Credit Card
            </label>
          </div>
          <div className="spacer-line" />
          <div className={`checkbox checkbox_blocked checkbox_${color}`}>
            <input
              type="checkbox"
              name="new"
              className={`checkbox__input modal__checkbox__input checkbox_${color}`}
              onClick={() => this.onUpdateModal("bank")}
              checked={this.state.bank}
            />
            <div
              className="checkbox__check"
              onClick={() => this.onUpdateModal("bank")}
            />
            <label
              className="checkbox__label modal__checkbox__label label-color-imp"
              onClick={() => this.onUpdateModal("bank")}
            >
              Bank
            </label>
          </div>
        </div>
        </div>
        {/* <div className={`text-field text-field_${color}`}>
          <CustomTextarea
            parentClass={color}
            value={this.state.notes}
            onChange={(e) => this.onFieldChange(e, "notes")}
            label="Notes"
            type="textarea"
          />
        </div> */}
      </>
    );
  };

  render() {
    const { name, description, tags } = this.state;
    const { workspace } = this.props;

    return (
      <ProcessPage onCancel={() => this.props.closePopup()}>
        {() => (
          <EditForm
            color={this.props.color}
            submitLabel="Continue"
            submitClassName={
              (!this.state.bank && !this.state.creditCard)
                ? "round-button_disabled"
                : null
            }
            onSubmit={this.onSubmit}
            title="Billing Settings"
            subTitle={
              "The billing settings of a workspace impact payment and invoicing options."
            }
            content={this.renderContent()}
            disabled={!this.state.bank && !this.state.creditCard}
          />
        )}
      </ProcessPage>
    );
  }
}

EditInformation.propTypes = {
  profile: PropTypes.object,
  closePopup: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EditInformation;
