import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SingleMenu from "./SingleMenu";
import { dateFormatter } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";
import TableTags from "app/components/common/TableTags";
import {convertDefaultStatusToStatus} from 'utils/common'

class UserRow extends PureComponent {
  onRedirectToListing = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.history.push(`/listing/${id}`);
  };

  getStatus = () => {
    const { status, currentSortKey } = this.props;

    const statusClass = classNames(
      "data-table__status text-capitalize text-captilize",
      {
        "data-table__status_pos": status === "active",
        "data-table__status_neg": status === "inactive",
        "data-table__td_dark": currentSortKey === "status",
      }
    );

    return <div className={statusClass}>{convertDefaultStatusToStatus(status)}</div>;
  };

  getRowRender = () => {
    const {
      updatedAt,
      id,
      firstName,
      lastName,
      workspace,
      workspaceId,
      tags,
      createdAt,
      email,
      lastLogin,
      devPlanActivated,
      colorClassName,
      currentSortKey,
      workspaces,
      isBillingContact,
      ownedWorkspaces
    } = this.props;

    const updatedAtClass = classNames({
      "data-table__td_dark": currentSortKey === "updatedAt",
    });

    const lastSignInClass = classNames({
      "data-table__td_dark": currentSortKey === "lastLogin",
    });

    const createdClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "createdAt",
    });

    const nameClass = classNames({
      "data-table__td_dark": currentSortKey === "firstName",
    });

    const emailClass = classNames({
      "data-table__td_dark": currentSortKey === "email",
    });

    const billingClass = classNames({
      "data-table__td_dark": currentSortKey === "isBillingContact",
    })
  
    const fullName = `${firstName || ''} ${lastName || ''}`

    return {
      firstName: () => <div className={nameClass}>{fullName}</div>,
      email: () => <div className={emailClass}>{email}</div>,
      status: () => this.getStatus(),
      tags: () =>
        tags && (
          <TableTags tags={tags} colorClassName={colorClassName} />
        ),
      devPlanActivated: () => (
        <div className={updatedAtClass}>{devPlanActivated ? <span className="accent0">Active</span> : 'Inactive'}</div>
      ),
      createdAt: () => (
        <div className={createdClass}>{dateFormatter(createdAt, true)}</div>
      ),
      lastLogin: () => (
        <div className={lastSignInClass}>{dateFormatter(lastLogin, true)}</div>
      ),
      workspaces: () => (workspaces && <TableTags tags={workspaces.map(workspace => workspace.name)} colorClassName={colorClassName} />) || 'N/A',
      ownedWorkspaces: () => (ownedWorkspaces.length && <TableTags tags={ownedWorkspaces.map(workspace => workspace.name)} colorClassName={colorClassName} type='link' data={ownedWorkspaces} />) || 'N/A',
      isBillingContact: () => <div className={billingClass}>{isBillingContact ?  'Yes' : 'No'}</div>
    };
  };

  render() {
    const {
      colorClassName,
      id,
      email,
      status,
      devPlanActivated
    } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <SingleMenu
            id={id}
            status={status}
            email={email}
            devPlanActivated={devPlanActivated}
            onDeactivateDev={this.props.onDeactivateDev}
            onActivateDev={this.props.onActivateDev}
            onActivate={this.props.onActivate}
            onDeactivate={this.props.onDeactivate}
          />
        </SortableRow>
      </>
    );
  }
}

UserRow.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(UserRow));
