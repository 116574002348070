import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Field,
  reduxForm,
  FieldArray,
  Form,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";

import ProcessPage from "app/components/common/ProcessPage";
import Input from "app/components/common/Form/Input";
import SingleSelect from "app/components/common/Form/SingleSelect";

import EditForm from "app/components/common/Edit/EditForm";
import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import { getDuration } from "utils/common";
import { updatePlanAction } from "app/actions/plans";
import validate from "../Create/validate";
import { INTERVAL, CURRENCY } from "app/constants/common";

const FORM_NAME = "PRICING";

const required = (value) => (value ? undefined : "Required");
const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

class PlanPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      id: "",
      tags: [],
      description: "",
    };
  }

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  onChangeField = (name, value) => {
    this.setState({ [name]: value });
  };

  onEdit = async (data) => {
    const { id, name } = this.props.plan;

    const tiers = data.tiers.map((item) => ({
      up_to: item["up_to"] ? parseInt(item["up_to"]) : null,
      unit_amount: item["unit_amount"]
        ? parseInt(item["unit_amount"]) * 100
        : item["unit_amount"],
    }));

    const payload = {
      id,
      tiers,
      name: name,
      type: data.type,
      currency: data.currency,
      minEndpoints: parseInt(data.minEndpoints),
      trial: parseInt(data.trial),
    };

    try {
      await this.props.updatePlanAction(payload);
      this.props.onClose();
    } catch (err) {}
    this.props.onClose();
  };

  componentDidMount() {
    const {
      tiers,
      array: { push },
    } = this.props;

    this.props.tiers &&
      this.props.tiers.forEach((tier, index) => {
        push(
          "tiers",
          tiers.length - 1 === index
            ? { up_to: "", unit_amount: tier.unit_amount / 100 }
            : { up_to: tier["up_to"], unit_amount: tier.unit_amount / 100 }
        );
      });
  }

  componentWillUnmount() {
    this.props.change('tiers', [])
  }
  
  renderContent = () => {
    const { color, editMode, formValue} = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit(this.onEdit)}>
        <div className="plan_title__container">
          <h3 className="plan_title">Pricing Settings</h3>
          <div className="grid-row grid-row_nog grid-row_jcb">
            <div className="grid-col mw-200">
              <Field
                parentClass={`select-field select-field_${color}`}
                name="interval"
                disabled
                options={INTERVAL}
                validate={[required]}
                component={SingleSelect}
                label="Interval"
              />
                            <Field
                name="minEndpoints"
                component={Input}
                parentClass={color}
                label="Minimum"
                postFix="Units"
                maxLength="4"
                disabled
                type="text"
                readOnly
                normalize={(val) => val.match(/^\d+(\.\d+)?$/)}
                pattern="[0-9]*"
                inputMode="numeric"
                validate={[required, number]}
              />
            </div>
            <div className="grid-col mw-200">
              <Field
                parentClass={`select-field select-field_${color}`}
                name="currency"
                options={CURRENCY}
                disabled
                component={SingleSelect}
                label="Currency"
                validate={[required]}
              />
            </div>
          </div>
       
          <h3 className="plan_title">Trial Settings</h3>
          <div className="grid-row grid-row_nog grid-row_jcb">
            <div className="grid-col mw-200">
            <Field
                parentClass={color}
                name="trial"
                disabled
                readOnly
                component={Input}
                label="Trial period"
                postFix="Days"
                validate={[required]}
                maxLength="3"
              />
            </div>
            <div className="grid-col mw-200">
            <Field
                parentClass={color}
                name="trialFee"
                disabled
                readOnly
                component={Input}
                label="Trial price"
                postFix="USD"
                validate={[required]}
              />
            </div>
          </div>
        </div>
        <FieldArray
          name="tiers"
          component={(tiers) => {
            return (
              <div className="plan_title__container">
                <h3 className="plan_title">Pricing Tiers</h3>
                {tiers.fields.map((plan, index) => (
                  <div className="grid-row plan_form grid-row_nog grid-row_jcb" key={index}>
                    <div className={`plan_number checkbox checkbox_${color}`}>
                      <div className="checkbox__image">{index + 1}</div>
                    </div>
                    <div className="grid-col mw-138">
                      <Field
                        readOnly
                        type='number'
                        parentClass={color}
                        name={`${plan}.up_to`}
                        component={Input}
                        label={
                          tiers.fields.length - 1 === index ? "∞" : "Last unit"
                        }
                        maxLength="6"
                        validate={[number]}
                        disabled
                      />
                    </div>
                    <div className="grid-col mw-200">
                      <Field
                        readOnly
                        type='number'
                        parentClass={color}
                        name={`${plan}.unit_amount`}
                        component={Input}
                        label={formValue && formValue.interval === 'year' ? "price/yearly" :"price/month"}
                        postFix={this.props.currency && this.props.currency.toUpperCase()}
                        maxLength="6"
                        validate={[number]}
                        disabled
                      />
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        />
      </Form>
    );
  };

  render() {
    const { name } = this.state;
    const { plan } = this.props;

    return (
      <ProcessPage onCancel={this.props.onClose} onBack={this.props.onBack}>
        {() => (
          <>
            <EditForm
              wrapperClass="pricing__form"
              color={this.props.color}
              content={this.renderContent()}
              disabled={!name}
              title="Plan Pricing"
              subTitle={
                <>
                  {plan.name} • Modified {getDuration(plan.updatedAt)}
                </>
              }
            />
          </>
        )}
      </ProcessPage>
    );
  }
}

PlanPricing.propTypes = {
  color: PropTypes.string,
};

PlanPricing.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  return {
    currency: selector(state, "currency"),
    formValue: state.form.PRICING.values
  };
}

const withConnect = connect(mapStateToProps, { updatePlanAction });

export default reduxForm({
  form: FORM_NAME,
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(withConnect(PlanPricing));
