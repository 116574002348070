import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import CardTags from "app/components/common/Detail/CardTags";
import EditInformation from '../Edit/Information';

class Information extends Component {
  state = {
    openEditInformationModal: false
  }

  onEdit = () => {
    this.setState({ openEditInformationModal: true });
  };

  onCloseModal = () => {
    this.setState({ openEditInformationModal: false });
  };

  renderContent = () => {
    const { payment } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.reference} />
          </div>
          <div className="overview__value">{payment.reference}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.internalNote} />
          </div>
          <div className="overview__value">{payment.note}</div>
          <div className="overview__title" />
          <div className="overview__label">Tags</div>
          <div className="overview__value">
            <CardTags
              color={this.props.color}
              tags={payment.tags}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    const { openEditInformationModal } = this.state;

    if (openEditInformationModal) {
      return (
        <EditInformation
          payment={this.props.payment}
          color={this.props.color}
          getPayment={this.props.getPayment}
          onClose={this.onCloseModal}
        />
      );
    }


    return (
      <Card
        color={this.props.color}
        fullWidth
        title="Information"
        className="overview"
        onEdit={this.onEdit}
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  payment: PropTypes.object,
};

export default Information;
