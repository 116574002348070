import React from 'react';

import LeftPanel from 'app/components/LeftPanel'
import CentralPanel from 'app/components/CentralPanel'

export class App extends React.PureComponent {
  render() {
    return (
      <>
        <LeftPanel/>
        <CentralPanel />
      </>
    )
  }
}

export default App;
