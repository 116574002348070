import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";
import { createStructuredSelector } from "reselect";
import Slider, { Handle } from "rc-slider";
import NumberFormat from "react-number-format";

import { CheckIcon, DragIcon } from "images/common";
import { truncate } from "utils/common";

class PlanItem extends Component {
  state = {
    endpoint: 0,
    price: 0,
    appPrice: 0,
    minEndpoints: 0,
  };

  componentDidMount() {
    const { plan } = this.props;

    const endpoint = plan.metadata.minEndpoints;

    this.setState({ endpoint, minEndpoints: plan.metadata.minEndpoints });

    endpoint && this.onChange(endpoint.toString());
  }

  onBlur = (value) => {
    const { plan } = this.props;
    const minEndpoint = plan.metadata.minEndpoints;
    const inputNumber = value && value.replace(/,/g, "");

    if (+inputNumber > 5000) {
      this.onChange("5000");
      return this.setState({ showMinPointWarning: false, endpoint: 5000 });
    }

    if (+value < minEndpoint) {
      this.onChange(minEndpoint);
      this.setState({ showMinPointWarning: true, endpoint: +minEndpoint });
    }

    if (+value >= minEndpoint) {
      this.setState({ showMinPointWarning: false });
    }
  };

  onChange = (value) => {
    const inputNumber = isNaN(value) ? value && value.replace(/,/g, "") : value;
    this.setState({ endpoint: +inputNumber > 5000 ? 5000 : +inputNumber });

    const { plan } = this.props;
    const minEndpoint = plan.metadata.minEndpoints;

    const planTiers = plan.tiers.map((tier, index) => {
      if (index === plan.tiers.length - 1) {
        return { unit_amount: tier.unit_amount, up_to: 5000 };
      }
      return { ...tier, unit_amount: tier.unit_amount };
    });

    let price = 0;
    let remainingUnit =
      +inputNumber < minEndpoint ? +minEndpoint : +inputNumber;

    // Logic to calculate price
    if (
      (+inputNumber < minEndpoint ? +minEndpoint : +inputNumber) <=
      +planTiers[0].up_to
    ) {
      price = planTiers[0].unit_amount * inputNumber;
    } else if (+inputNumber > 5000) {
      price = planTiers[plan.tiers.length - 1].unit_amount * 5000;

      return this.setState({ price: price / 5000 });
    } else {
      planTiers.forEach((tier) => {
        let planPrice = price;
        if (remainingUnit > 0) {
          if (remainingUnit >= tier.up_to) {
            planPrice = tier.up_to * tier.unit_amount;
          } else {
            planPrice = remainingUnit * tier.unit_amount;
          }
          remainingUnit -= tier.up_to;
          price += planPrice;
        }
      });
    }

    this.setState({
      price: price
    });

    if (this.state.appPrice === 0) {
      this.setState({ appPrice: price});
    }

    // this.setState({ price: (plan.tiers[plan.tiers.length - 1] && plan.tiers[plan.tiers.length - 1].unit_amount) || 0 });
  };

  render() {
    const { name, type } = this.props;
    const { price, endpoint, appPrice, minEndpoints } = this.state;

    const { accent, plan } = this.props;
    const minUnit =
      +plan.metadata.minEndpoints ||
      (plan && plan.tiers && plan.tiers[0] && plan.tiers[0].up_to);

    const selectedPrice =
      price &&
      parseFloat(price / 100)
        .toFixed(2)
        .toString()
        .split(".");

    const btnClass = classNames("plan_card__button", {
      [`plan_card__button_${accent}`]: accent,
    });

    return (
      <div className="plan_card">
        <div className="plan_card__title">{name}</div>
        <div className="plan_card__price">
          <div className="plan_rate">
            <div>
              <span className="plan_amount">
                {selectedPrice ? (
                  <NumberFormat
                    value={selectedPrice[0]}
                    displayType={"text"}
                    thousandSeparator
                    renderText={(value) => value}
                  />
                ) : (
                  "00"
                )}
                .
                <span className="plan_amount_decimal">
                  {selectedPrice ? selectedPrice[1] : "00"}
                </span>
              </span>
            </div>
            <div className="plan_dollar">USD</div>
            &nbsp;&nbsp;
          </div>
        </div>
        {type === "application" && (
          <div className="plan_duration">One-time payment</div>
        )}
        {type === "module" && (
          <>
            <h4 className="plan_duration">
              monthly for
              {endpoint < minEndpoints && (
                <p className="custom-tooltip">{`${minEndpoints} is the minimum amount`}</p>
              )}
              <div className="plan_card__endpoints">
                <NumberFormat
                  value={this.state.endpoint}
                  displayType={"text"}
                  thousandSeparator
                  renderText={(value) => (
                    <input
                      type="text"
                      onBlur={(e) => this.onBlur(e.target.value)}
                      onChange={(e) => this.onChange(e.target.value)}
                      className="plan_input_endpoint"
                      value={value}
                    />
                  )}
                />
              </div>
              Endpoint
            </h4>
            <div className={`${accent} slider-card`}>
              <Slider
                min={minUnit}
                max={5000}
                value={endpoint}
                onChange={this.onChange}
                handle={(handleProps) => {
                  return (
                    <Handle {...handleProps}>
                      <div className="plan_card__icon">
                        <DragIcon />
                      </div>
                    </Handle>
                  );
                }}
              />
            </div>
          </>
        )}
        <div
          className={btnClass}
          onClick={() => this.props.onSelectPlan(plan, minEndpoints, appPrice)}
        >
          Get Started
        </div>
        {plan.tags &&
          plan.tags
            .filter((tag, index) => index <= 2)
            .map((tag, index) => (
              <div className={`plan_card__features ${accent}`} key={index}>
                <CheckIcon />
                <div className="plan_feature_title">{truncate(tag, 35)}</div>
              </div>
            ))}
      </div>
    );
  }
}

PlanItem.propTypes = {
  loginRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PlanItem);
