import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl'
import classNames from 'classnames'

import messages from "utils/message";

import Card from "app/components/common/Detail/Card";
import { getDuration, convertDefaultStatusToStatus } from 'utils/common';

class Metadata extends Component {
  state = {
    showUserInformationModal: false
  };

  getStatus = (status) => {
    const className = classNames('data-table__status overview__value data-custom__status text-capitalize',
      {
        'data-table__status_pos': status === 'listed',
        'data-table__status_warning': status === 'in progress' || status === 'submitted',
        'data-table__status_neg': status === 'rejected' || status === 'draft',
      })

    return (
      <div className={className}>{convertDefaultStatusToStatus(status)}</div>
    )
  }


  onAddTags = tags => {
    this.setState({ tags });
  };

  openEditModal = () => {
    this.setState({ showUserInformationModal: true });
  };

  closeEditModal = () => {
    this.setState({ showUserInformationModal: false });
  };

  renderContent = () => {
    const { listing } = this.props;

    return (
      <>
        <div className="grid-col grid-col_6">
          <div className="overview__label">Listing ID</div>
          <div className="overview__value">{listing.id}</div>
        </div>

        <div className="grid-col grid-col_6">
          <div className="overview__label">
            <FormattedMessage {...messages.status}/>
          </div>
          {this.getStatus(listing.status)}
        </div>

        <div className="grid-col grid-col_6">
          <div className="overview__label">
            <FormattedMessage {...messages.type}/>
          </div>
          <div className="overview__value text-capitalize">{listing.type}</div>
        </div>

        <div className="grid-col grid-col_6">
          <div className="overview__label">
            <FormattedMessage {...messages.lastUpdated}/>
          </div>
          <div className="overview__value">
            {getDuration(listing.updatedAt)}
          </div>
        </div>

        <div className="grid-col grid-col_6">
          <div className="overview__label">Pre Installable</div>
          <div className="overview__value">{listing.preInstallable ? 'True' : 'False'}</div>
        </div>
      </>
    );
  };

  render() {
    const {listing} = this.props;

    return (
      <Card
        color={this.props.color}
        title="Metadata"
        className="overview"
        customTag={<div className="text-color">Status: <span className="text-capitalize label-color">{listing && convertDefaultStatusToStatus(listing.status)}</span></div>}
        content={this.renderContent()}
      />
    );
  }
}

Metadata.propTypes = {
  user: PropTypes.object
};

export default Metadata;
