import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames'

export const SingleMenu = ({ onToogleMenu, closeMenu, id, hideParentClass, updateToLatestVersion, buildStatus, buildingVisoAgentVersion, visoAgentVersion }) => {  
  const onUpgrade = (e) => {
    e.stopPropagation()
    onToogleMenu(e, id)
  
    if(buildStatus === 'building') {
      return false
    }
    closeMenu()
    updateToLatestVersion(id, e)
  }

  const manageClass = classNames("actions__option", {
    actions__option_disabled: buildStatus === 'building',
    cursor: buildStatus === 'succeeded'
  });

  const parentClass = classNames({
    'actions__dropdown actions__dropdown_visible': !hideParentClass
  });

  return (
    <>
      <div className={parentClass}>
      {/* <span className="actions__option" onClick={(e) => onDuplicate(id, e)}>
        Copy to edit
      </span> */}
      <span
        onClick={(e) => onUpgrade(e)}
        className={manageClass}
      >
        Upgrade to latest version
      </span>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
