import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class EditForm extends Component {
  
  render() {
    const {wrapperClass} = this.props;

    return (
      <div className="popup__cont">
        <div className="login">
          <div className="headline center">
            <h1 className="headline__title">
              { this.props.title }
            </h1>
            { this.props.subTitle &&
              <h3 className="headline__subtitle">
                { this.props.subTitle }
              </h3>
            }
          </div>
          <div className={classNames('login__form', {[wrapperClass]: wrapperClass})}>
            <div className="text-field text-field_accent0">
              { this.props.content }
            </div>
            <div className="login__actions">
              {this.props.onSubmit &&
              <button
                onClick={this.props.onSubmit}
                className={classNames('button',
                  `button_${this.props.color}`,
                  this.props.submitClassName,
                )}
                disabled={this.props.disabled}
              >
                { this.props.submitLabel }
              </button>
              }
              {this.props.discardLabel  &&
              <div>
                <br/>
                <br/>
                <span
                  className={'button-discard'}
                  onClick={this.props.onDiscard}
                >
                  { this.props.discardLabel }
                </span>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditForm.propTypes = {
  color: PropTypes.string,
  submitLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  submitClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  content: PropTypes.node.isRequired,
};

EditForm.defaultProps = {
  submitLabel: 'Save',
  color: 'accent0',
};

export default EditForm;
