import React, { Component } from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";

import Card from "app/components/common/Detail/Card";
// import UpdatePayment from './edit/ListPayment'
import { TickIcon } from "images/common";
import { DollarSign } from "react-feather";

import { getInterval } from "utils/common";

export const getCurrencyIcon = {
  usd: <DollarSign />,
};

class ProfileInformation extends Component {
  state = {
    showEditModal: false,
  };

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  openEditModal = () => {
    this.setState({ showEditModal: true });
  };

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  };

  onEditRole = (data) => {
    const { tags } = this.state;
    const { user } = this.props;

    const payload = {
      ...data,
      tags,
      id: user.id,
    };

    this.props.onEdit(payload, this.closeEditModal);
  };

  renderContent = () => {
    const { showEditModal } = this.state;
    const { color, listing } = this.props;

    if (showEditModal) {
      return <div>test</div>;
    }

    const prices = this.props.listing.prices
      ? this.props.listing.prices.map((item) => {
          if (
            item.id === listing.yearlyPriceId ||
            item.id === listing.monthlyPriceId
          ) {
            return { ...item, isDefault: true };
          }
          return item;
        })
      : [];

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {sortBy(prices, ["isDefault"])
              .filter((item, index) => index < 4)
              .map((price) => (
                <tr className="data-table__row" key={price.id}>
                  <td className="data-table__td data-table__td_1">
                    <div
                      className={`checkbox ${
                        price.isDefault ? `checkbox_${color}` : "checkbox_label"
                      }`}
                    >
                      {listing.monthlyPriceId === price.id && (
                        <span
                          className={`data-cards__selected data-cards__selected_item ${color}`}
                        >
                          <TickIcon />
                        </span>
                      )}
                      {listing.yearlyPriceId === price.id && (
                        <span
                          className={`data-cards__selected data-cards__selected_item ${color}`}
                        >
                          <TickIcon />
                        </span>
                      )}
                      <div className="checkbox__image">
                        {getCurrencyIcon[price.currency] ||
                          price.currency.toUpperCase()}
                      </div>
                    </div>
                  </td>
                  <td className="data-table__td">
                    <div className="data-table__price_item">
                      {listing.type === "application" ? (
                        <>
                          <span
                            className={
                              "data-table__td_dark data-table__priceId"
                            }
                          >
                            {price &&
                              price.unit_amount &&
                              parseFloat(price.unit_amount / 100).toFixed(
                                2
                              )}{" "}
                            {price.currency && price.currency.toUpperCase()}{" "}
                          </span>
                          <span>&nbsp;• One-time</span>
                        </>
                      ) : (
                        <>
                          <span
                            className={
                              "data-table__td_dark data-table__priceId"
                            }
                          >
                            {price &&
                              price.tiers &&
                              `${parseFloat(
                                price.tiers[0].unit_amount / 100
                              ).toFixed(2)} 
                        ${price.currency && price.currency.toUpperCase()}`}
                          </span>
                          &nbsp;
                          <span>
                            •{" "}
                            {price.recurring &&
                              price.recurring.interval &&
                              getInterval[price.recurring.interval]}
                          </span>
                        </>
                      )}
                    </div>
                  </td>
                  <td className="data-table__td">
                    {price.metadata &&
                      price.metadata.minEndpoints &&
                      `${price.metadata.minEndpoints} Endpoints Minimum`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { showEditModal } = this.state;
    const { color, listing } = this.props;

    if (showEditModal) {
      return <div>test</div>;
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        title="Pricing"
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

ProfileInformation.propTypes = {
  user: PropTypes.object,
};

export default ProfileInformation;
