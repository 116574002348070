import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { omitBy, isNil, debounce } from 'lodash';

import filterColumns from './filter';
import UserTable from './Table';
import { SOLUTION_ACCENT, DEFAULT_PAGE_SIZE } from 'utils/constants';
import ListSelectedItems from 'app/components/common/ListSelectedItems';
import ProcessPage from "app/components/common/ProcessPage";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { EnumType } from 'json-to-graphql-query';

class BillingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: {
        key: 'date',
        direction: 'asc'
      },
      filter: {
        single: [],
        range: []
      },
      selectedIds: [],
      offset: 20,
      resetFilter: false,
      resetSearch: false
    };
    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  loadMore = () => {
    if (this.props.allInvoices.loading) {
      return false
    }

    this.setState(
      {
        offset: this.state.offset + DEFAULT_PAGE_SIZE
      },
      this.getInvoices
    );
  };

  onSorting = () => {
    return false;
  };

  /* Filter End*/
  componentDidMount() {
  }

  getInvoices = () => {
    const payload = {
      range: this.state.filter.range,
      single: this.state.searchName
        ? [...this.state.filter.single, this.state.search]
        : [...this.state.filter.single]
    };

    const params = {
      limit: this.state.offset,
      query: payload,
      customerId: this.props.workspace.customerId
    };

    this.props.getInvoicesRequest(omitBy(params, isNil));
  };

  onFilter = data => {
    const { filterData, filteredColumns } = getFilterData(data);

    this.setState(
      {
        resetFilter: false,
        filter: filterData,
        filteredColumns: filteredColumns,
        offset: DEFAULT_PAGE_SIZE
      },
      this.getInvoices
    );
  };

  onReset = () => {
    this.setState({ isAllSelected: false });
    if (this.state.filter || this.state.searchName) {
      this.setState(
        {
          filter: {
            single: [],
            range: []
          },
          search: '',
          searchName: '',
          resetFilter: true,
          offset: DEFAULT_PAGE_SIZE
        },
        this.getInvoices
      );
    }
  };

  state = {
    selectedIds: [],
    selectItemFromTable: true
  };

  onSelect = (id, e) => {
    e.stopPropagation();
    this.setState({ selectedIds: id });
  };

  onSetSelectedIds = devices => {
    this.setState({ selectedIds: devices, selectItemFromTable: false });
  };

  render() {
    const { color, allInvoices } = this.props;
    const { resetFilter, filter, selectedIds } = this.state;

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {(scrollRef) => (
          <>
            <div className="headline">
              <h1 className="headline__title">
                Invoice List
              </h1>
              <h3 className="headline__subtitle">{this.props.workspaceName}</h3>
            </div>

            <div className="filters-bar">
            <ListSelectedItems
                          selectedNumber={selectedIds.length}
                          search={this.state.searchName}
                          onReset={this.onReset}
                          filterData={this.state.filter}
                          containerClassName={color}
                        />
              <Filter
                defaultFilter={
                    [{
                      field: 'status',
                      isDefault: true,
                      value: 'All',
                      columns: ['paid', 'open', 'draft', 'uncollectible', 'void']
                    }]
                }
                customClass={color}
                resetFilter={resetFilter}
                onChange={this.onFilter}
                columns={filterColumns}
                data={this.props.invoices}
              >
              </Filter>

            </div>
            <InfiniteScroll
              loadMore={this.debouncedLoadMore}
              hasMore={this.props.allInvoices.hasMore}
              initialLoad={false}
              loader={
                <div className="ml-3" key={0}>
                  Loading...
                </div>
              }
              useWindow={false}
              getScrollParent={() => scrollRef}
            >
              {this.props.allInvoices.data && (
                <UserTable
                  color={color}
                  users={this.props.allInvoices.data}
                  selectedItems={selectedIds}
                  // isLoading={this.props.allInvoices.loading}
                  onSortChange={this.onSorting}
                  sort={this.state.sort}
                  onSelect={() => null}
                  isSelectedAll={this.selectAll}
                />
              )}
            </InfiniteScroll>
          </>
        )}
      </ProcessPage>
    );
  }
}

BillingList.propTypes = {
  color: PropTypes.string
};

BillingList.defaultProps = {
  color: SOLUTION_ACCENT
};

export default BillingList;
