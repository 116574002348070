import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import {Link} from 'react-router-dom'

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import { dateFormatter } from "utils/common";
import {convertDefaultStatusToStatus} from 'utils/common'
import {routeMapping} from 'app/constants/routes';

class Overview extends Component {
  getStatus = (status) => {
    const statusClass = classNames(
      "data-table__status text-capitalize text-captilize",
      {
        "data-table__status_pos": status === "approved",
        "data-table__status_warning":
          status === "pending",
        "data-table__status_neg": status === "rejected",
      }
    );

    return <div className={statusClass}>{status && convertDefaultStatusToStatus(status)}</div>;
  };
  renderContent = () => {
    const { partner, color } = this.props;

    return (
      <>
              <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Partner ID</div>
          <div className="overview__value">
            {partner.id}
          </div>
          <div className="overview__label">Sign up date</div>
          <div className="overview__value">
            {partner.createdAt && dateFormatter(partner.createdAt, true)}
          </div>
        </div>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.status} />
          </div>
          <div className="overview__value">
            {this.getStatus(partner.status)}
          </div>
          <div className="overview__label">Related Workspace</div>
          {partner.workspace && <div className="overview__value">
            <Link to={`${routeMapping.WORKSPACE.path}/${partner.workspace.id}`}>{partner.workspace && partner.workspace.name}</Link>
          </div>
  }
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.overview} />}
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Overview.propTypes = {
  onEdit: PropTypes.func,
  color: PropTypes.string,
  workspace: PropTypes.object,
};

export default Overview;
