import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from 'config/config';
import { BASE_URL } from 'app/constants/common';

const DEFAULT_LISTING_VALUE= {
  id: true,
  name: true,
  type: true,
  logo: true,
  tagLine: true,
  status: true,
  workspaceId: true,
  workspace: {
    id: true,
    name: true,
    subDomain: true,
  },
  updatedAt: true,
  createdAt: true,
  preInstallable: true,
  marketplaceCategories: {
    id: true,
    name: true,
    description: true,
    slug: true
  },
}

const DASHBOARD_VALUES = {
  uid: true,
  title: true,
  tags: true
}

const SOLUTION_RETURN_VALUES = {
  id: true,
  name: true,
  type: true,
  logo: true,
  tagLine: true,
  status: true,
  createdAt: true,
  updatedAt: true,
  prices: true,
  images: true,
  description: true,
  keywords: true,
  userGuide: true,
  privacyPolicy: true,
  license: true,
  email: true,
  phone: true,
  website: true,
  monthlyPriceId: true,
  yearlyPriceId: true,
  preInstallable: true,
  marketplaceCategories: {
    id: true,
    name: true,
    description: true,
    slug: true
  },
  approvedBy: {
    id: true,
    email: true,
    firstName: true,
    lastName: true
  },
  approvedAt: true,
  workspaceId: true,
  activeVersion: true,
  package: {
    id: true,
    name: true
  } ,
  versions: {
    version: true,
    createdAt: true
  },
  workspace: {
    name: true,
    logo: true,
    subDomain: true
  },
  requiredModules: {
    name: true,
    moduleID: true,
    version: true,
    type: true,
    logo: true,
    link: true
  },
  dashboardUIds: true,
  videos: {
    id: true,
    name: true,
    size: true,
    url: true,
    type: true
  },
}

export function getListings() {
  const query = {
    query: {
      solutions: {
        ...DEFAULT_LISTING_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getListingDetails(payload) {
  const query = {
    query: {
      solution: {
        __args: payload,
        ...SOLUTION_RETURN_VALUES
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getDashboards(payload) {
  const query = {
    query: {
      dashboards: {
        __args: payload,
        ...DASHBOARD_VALUES
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function reviewSolution(param) {
  const query = {
    mutation: {
      reviewSolution: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function rejectSolution(param) {
  const query = {
    mutation: {
      rejectSolution: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function approveSolution(param) {
  const query = {
    mutation: {
      approveSolution: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function setPreInstallSolution(param) {
  const query = {
    mutation: {
      setPreInstallSolution: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}