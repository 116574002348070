import React from 'react';
import {Link} from 'react-router-dom'
import classNames from 'classnames'

import { toast } from 'react-toastify'
import {CloseIcon} from 'images/common'

const SuccessNotification = ({message, link }) => {
  if (link) {
    return   (
      <div className='message'>
        <Link to={link} className='message__action'>
          <div className='grid-row grid-row_nog'>
            <div className='grid-col'>
              <div className='text-color'>{message}</div>
            </div>
            <div className='grid-col grid-col_auto'>
              View
            </div>
          </div>
        </Link>
      </div>
    )
  }
  return (
    <div className='message'>
      <div className='grid-row grid-row_aic'>
        <div className='grid-col'>
          <div className='message__text'>{message}</div>
        </div>
      </div>
    </div>
  )
}

const MarketplaceNotification = ({message, link, closeToast }) => {
  if (link) {
    return   (
      <>
      <div className='message'>
        <div className='grid-row grid-row_aic'>
          <div className='grid-col'>
            <Link to={link} className='message__action'>
              <div className='message__text'>{message && message.toString()} </div>
            </Link>
          </div>
        </div>
      </div>
      <div className='grid-col grid-col_auto message-close' onClick={closeToast}>
        <CloseIcon/>
      </div>
    </>
    )
  }
  return (
    <div className='message'>
      <div className='grid-row grid-row_nog'>
        <div className='grid-col'>
          <div className='message__text'>{message}</div>
        </div>
      </div>
    </div>
  )
}

const DefaultNotification = ({ message, closeToast }) => {
  const bodyClass = classNames('grid-col grid-col_auto message-close', {
    'notifier-close': message.length > 36
  })
  return (
    <div className='message'>
      <div className='grid-row grid-row_aic'>
        <div className='grid-col'>
          <div className='message__text'>{message && message.toString()} </div>
        </div>
        <div className={bodyClass} onClick={closeToast}>
          <CloseIcon/>
        </div>
      </div>
    </div>
  )
}

const UpdateNotification = ({ closeToast, message }) => (
  <div className='message'>
    <div className='grid-row grid-row_aic'>
      <div className='grid-col'>
        <div className='message__text'>{message}</div>
      </div>
      <div className='grid-col grid-col_auto' onClick={closeToast}>
        <a href='javascript:void(0)' className='message__action' disabled>
      Undo
        </a>
      </div>
    </div>
  </div>
)

export function successNotifier(message, link, displayAlways = false) {
  return toast(<SuccessNotification message={message} link={link}/>, {
    position: toast.POSITION.BOTTOM_LEFT,
    closeButton: false,
    autoClose: !displayAlways,
    draggable: false,
  })
}


export function marketplaceNotifier(message, link, displayAlways = true) {
  return toast(<MarketplaceNotification message={message} link={link}/>, {
    position: toast.POSITION.BOTTOM_LEFT,
    closeButton: false,
    autoClose: false,
    draggable: false,
  })
}

export function defaultNotifier(message, displayAlways = false) {
  if (!message) return
  if (typeof message === 'string') {
    return toast(<DefaultNotification message={message}/>, {
      position: toast.POSITION.BOTTOM_LEFT,
      closeButton: false,
      autoClose: !displayAlways,
      draggable: false,
    })
  }

  if (typeof message !== 'string' && message.length) {
    return message.forEach(error => {
      return toast(<DefaultNotification message={error.message}/>, {
        position: toast.POSITION.BOTTOM_LEFT,
        closeButton: false,
        draggable: false,
      })
    })
  }
}

export function updateNotifier(message) {
  if (!message) return

  return toast(<UpdateNotification message={message}/>, {
    position: toast.POSITION.BOTTOM_LEFT,
    closeButton: false,
    draggable: false,
  })
}
