import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

class VerticalButtonMenu extends Component {
  state = {
    showDropdown: false,
  };

  toggleAction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.onClickMenu) {
      this.props.onClickMenu()
    }
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = (e) => {
    this.setState({ showDropdown: false });
  };

  closeMenu = () => {
    this.setState({ showDropdown: false});
  };

  render() {
    const { customClass } = this.props;

    const themeClass = classNames("actions__button", {
      actions__show: this.state.showDropdown,
      [customClass]: customClass,
    });
    return (
      <div className="actions">
        <OutsideClickHandler onOutsideClick={this.onActionOutsideClick}>
          <button
            type="button"
            className={themeClass}
            onClick={this.toggleAction}
          />
        {this.props.children && (
          <div
          className={classNames("actions__dropdown", {
            actions__dropdown_visible: this.state.showDropdown,
          })}
          >
            {this.state.showDropdown &&
              React.Children.map(this.props.children, (child) => {
                return React.cloneElement(child, {
                  closeMenu: this.closeMenu,
                  onToogleMenu: this.toggleAction
                });
              })}
          </div>
        )}
        </OutsideClickHandler>
      </div>
    );
  }
}

VerticalButtonMenu.propTypes = {
  children: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default VerticalButtonMenu;
