import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const SingleMenu = ({ detailRole, hideParentClass, onDelete, id, closeMenu, category, onChangePlan }) => {
  const parentClass = classNames({
    'actions__dropdown actions__dropdown_visible': !hideParentClass
  });

  const onDeleteMenu = (e) => {
    e.stopPropagation();
    onDelete(id);
    closeMenu()
  }

  const onChangeMenu = (e) => {
    e.stopPropagation();
    onChangePlan(id);
    closeMenu()
  }

  return (
    <>
      <div className={parentClass}>
      <span
        onClick={onChangeMenu}
        className={classNames('actions__option', {
          // 'actions__option_disabled': category === 'BASE'
        })}
      >
        Change Subscription
      </span>
      <span
        onClick={onDeleteMenu}
        className={classNames('actions__option', {
          'actions__option_disabled': category === 'BASE'
        })}
      >
        Cancel Subscription
      </span>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
