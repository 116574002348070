import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";
import TableTags from "app/components/common/TableTags";
import {snakeCaseToTitleCase, getPriceFormat} from 'utils/common'

import SingleMenu from "./SingleMenu";

import { dateFormatter } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { status } = this.props;

    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === "active",
        "data-table__status_neg": status === "inactive",
      }
    );

    return <div className={className}>{status}</div>;
  };

  onRedirectToSubscribedWorkspace = (id, e) => {
    e.stopPropagation();
    e.preventDefault();
    const { workspaceCount, name } = this.props;

    if (!workspaceCount) {
      return false;
    }

    this.props.history.push(`/workspace/plan/${id}?name=${name}`);
  };

  getTrial = (phases) => {
    const { name} = this.props;

    if(!phases) {
      return 'N/A'
    }
    const phase = phases.find(phase => phase.type === 'TRIAL');


    if(phase) {
      return `${phase.duration.number >= 0 ? phase.duration.number : ''} ${phase.duration.unit}`
    }
    
    return 'N/A'
  }

  getPriceList = () => {
    const {phases} = this.props;

    if(!phases) {
      return ''
    }

    const phase = phases.map(phase => phase.type);

    if(phase) {
      return phase
    }
    
    return ''
  }

  getPrice = () => {
    const {phases} = this.props;

    if(!phases) {
      return ''
    }

    const phase = phases.map(phase => phase.type);

    if(phase) {
      const price = phases[0].prices
      .map(item => item.value)
      .reduce((sum, item) => sum + item.value);

      return price && getPriceFormat(price);
    }
    
    return ''
  }
  

  getRowRender = () => {
    const {
      createdAt,
      priceId,
      trial,
      productId,
      name,
      id,
      phases,
      category,
      product,
      isDevPlan,
      originalId,
      billingPeriod,
      currentSortKey,
      colorClassName,
      quantity,
      prettyName,
      workspaceCount
    } = this.props;

    const nameClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "prettyName",
    });

    const billingPeriodClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "billingPeriod",
    });

    const workspaceCountClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "workspaceCount",
    });

    const statusClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "status",
    });

    const productClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "product",
    });

    const categoryClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "category",
    });

    const trailClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "trial",
    });

    const priceListClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "prices",
    });

    const priceClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "priceId",
    });

    const quantityClass = classNames('type__value cursor', {
      "data-table__td_dark": currentSortKey === "quantity",
    });

    const createdClass = classNames('type__value', {
      "data-table__td_dark": currentSortKey === "createdAt",
    });

    const priceList = this.getPriceList();
    const price = this.getPrice();

    return {
      prettyName: () => (
        <div className={nameClass} title={prettyName}>
          {prettyName}
          {isDevPlan && <span className="workspace_owner_tag">Developer Plan</span>}
        </div>
      ),
      priceId: () => <span className={priceClass}>{price}</span>,
      billingPeriod: () => <span className={billingPeriodClass}>{snakeCaseToTitleCase(billingPeriod)}</span>,
      category: () => <span className={categoryClass}>{snakeCaseToTitleCase(category)}</span>,
      quantity: () => <span className={quantityClass}>{quantity}</span>,
      workspaceCount: () => <span className={workspaceCountClass}>{workspaceCount}</span>,
      trial: () => <span className={trailClass}>{this.getTrial(phases)}</span>,
      status: () => <span className={statusClass}>{this.getStatus()}</span>,
      product: () => <span className={productClass}>{snakeCaseToTitleCase(product)}</span>,
      createdAt: () => <span className={createdClass}>{dateFormatter(createdAt, true)}</span>,
      phases: () => priceList ? <TableTags tags={priceList} colorClassName={colorClassName} /> : 'N/A',
    };
  };

  render() {
    const {
      colorClassName,
      id,
      onDelete,
      status,
      isDevPlan,
      onUpdateStatus,
      onDuplicate,
      originalId,
      onSetAsDevPlan,
      category
    } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <SingleMenu
            id={id}
            originalId={originalId}
            onDelete={onDelete}
            status={status}
            isDevPlan={isDevPlan}
            category={category}
            onSetAsDevPlan={onSetAsDevPlan}
            showQuota={this.props.showQuota}
            showAddonPlan={this.props.showAddonPlan}
            onDeactivatePlan={this.props.onDeactivatePlan}
            onActivatePlan={this.props.onActivatePlan}
            workspaceCount={this.props.workspaceCount}
            onDuplicate={onDuplicate}
            onUpdateStatus={onUpdateStatus}
          />
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
