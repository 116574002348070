import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from 'config/config';
import { BASE_URL } from 'app/constants/common';

const DEFAULT_PARTNERS_VALUE= {
  id: true,
  status: true,
  workspace: {
    id: true,
    name: true,
    status: true,
    createdAt: true,
    createdBy: {
      id: true,
      name: true,
      email: true
    }
  },
  updatedAt: true,
  createdAt: true,
}

const DEFAULT_PARTNER_VALUE= {
  id: true,
  status: true,
  businessName: true,
  address1: true,
  address2: true,
  city: true,
  country: true,
  postCode: true,
  vat: true,
  contact: {
    id: true,
    firstName: true,
    lastName: true,
    email: true
  },
  workspace: {
    id: true,
    name: true,
    status: true,
    createdAt: true,
    createdBy: {
      id: true,
      name: true,
      email: true
    }
  },
  submittedAt: true,
  stripeAccountId: true,
  updatedAt: true,
  createdAt: true,
  reviewedAt: true,
  reviewedBy: {
    id: true,
    email: true,
    firstName: true,
    lastName: true
  }
}

export function getPartners() {
  const query = {
    query: {
      partners: {
        ...DEFAULT_PARTNERS_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getPartner(payload) {
  const query = {
    query: {
      partner: {
        __args: payload,
        ...DEFAULT_PARTNER_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}


export function rejectPartner(param) {
  const query = {
    mutation: {
      rejectPartner: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function approvePartner(param) {
  const query = {
    mutation: {
      approvePartner: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deactivatePartner(param) {
  const query = {
    mutation: {
      deactivatePartner: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function activatePartner(param) {
  const query = {
    mutation: {
      activatePartner: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}
