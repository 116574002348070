import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import CardTags from "app/components/common/Detail/CardTags";
import { getPaymentMethod, getPaymentName } from "utils/common";
import {getPriceFormat} from 'utils/common';

class Organization extends Component {
  renderContent = () => {
    const { workspace: {kilBillInfo}, workspace } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.accountID} />
          </div>
          <div className="overview__value"><a href={kilBillInfo.accountURL} target="_blank">{kilBillInfo.accountId}</a></div>
          <div className="overview__label">
            Payment Terms
          </div>
          <div className="overview__value">Net {workspace.paymentTerms} days</div>
          <div className="overview__label">
            Set Payment Method
          </div>
          <div className="overview__value">{getPaymentMethod(workspace.paymentMethod)}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.accountCredit} />
          </div>
          <div className="overview__value">{getPriceFormat(kilBillInfo.accountCBA)}</div>
        </div>
        
        <div className="grid-col">
        <div className="overview__label">
            <FormattedMessage {...messages.billCycleDay} />
          </div>
          <div className="overview__value">{kilBillInfo.billCycleDayLocal}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.currency} />
          </div>
          <div className="overview__value">
            {kilBillInfo.currency}
          </div>
          <div className="overview__label">
            Available Payment Methods
          </div>
          <div className="overview__value">{getPaymentName(workspace.availablePaymentMethods)}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.accountBalance} />
          </div>
          <div className="overview__value">{getPriceFormat(kilBillInfo.accountBalance)}</div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title="Billing Settings"
        className="overview"
        onEdit={this.props.onEdit}
        content={this.renderContent()}
      />
    );
  }
}

Organization.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Organization;
