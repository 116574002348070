import {users} from '../constants/users';

const INITIAL_STATE = {
  isLoading: false,
  detail: null,
  list: [],
  visoUsers: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case users.GET_ADMIN_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case users.GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload
      }
    case users.GET_ADMIN_USERS_REJECTED:
      return {
        ...state,
        isLoading: false,
        list: []
      }
    case users.GET_VISO_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        visoUsers: [],
      }
    case users.GET_VISO_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        visoUsers: action.payload,
      }
    default:
      return state;
  }
}