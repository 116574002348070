import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List } from "react-feather";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import NotFound from "app/components/NotFound";
import { FormattedMessage } from "react-intl";
import Modal from "app/components/common/Modal";
import Pagination from "app/components/Pagination";

import SearchField from "app/components/common/SingleSearch";
import NoPlans from "../../common/NoItem";
import messages from "utils/message";

import Card from "./Card";
import ListTable from "./Table";

import { routeMapping } from "app/constants/routes";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";

import { CARD_VIEW, TABLE_VIEW, DEFAULT_PAGE_SIZE } from "app/constants/common";

import {
  fetchPartners,
  activatePartneAction,
  deactivatePartnerAction,
  approvePartnerAction,
  rejectPartnerAction,
} from "app/actions/partner";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";
import {isSmallerDevice} from 'utils/common';

import ListSelectedItems from "../../common/ListSelectedItems";
import PreLoaderList from "app/components/common/PreLoader/PreLoaderList";

class ListingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      selectedIds: [],
      currentPage: 1,
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "workspace.name",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: DEFAULT_PAGE_SIZE,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.partners,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: DEFAULT_PAGE_SIZE,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.partners);
    let result = finalResult;
    this.setCurrentPage(1);

    if (this.state.searchName) {
      result = finalResult.filter(
        (item) =>
          item.workspace.name &&
          item.workspace.name
            .toLowerCase()
            .includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.setState({ filterResult: this.props.partners })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.getPartner();

    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({activeView: CARD_VIEW})
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
    isNextSmallerDeviceType &&
    nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({activeView: CARD_VIEW})
    }

    if (
      isSmallerDevice &&
    nextProps.deviceSizeType !== this.props.deviceSizeType &&
    nextProps.deviceSizeType === 'desktop'
    ) {
      return this.setState({activeView: TABLE_VIEW})
    }
  }
  
  getPartner = () => {
    this.props.fetchPartners();
  };

  onApprove = async (id) => {
    await this.props.approvePartnerAction(id, this.getPartner);
  };

  onApprovePartner = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The partner will be approved.`;
    const title = `Confirm approve partner`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onApprove(id),
    });
  };

  onDeactivate = async (id) => {
    await this.props.deactivatePartnerAction(id, this.getPartner);
  };

  onDeactivatePartner = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The partner will be deactivated.`;
    const title = `Confirm deactivate partner`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onDeactivate(id),
    });
  };

  onActivate = async (id) => {
    await this.props.activatePartneAction(id, this.getPartner);
  };

  onActivatePartner = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The partner will be activated.`;
    const title = `Confirm activate partner`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onActivate(id),
    });
  };

  onRejectPartner = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The partner will be rejected.`;
    const title = `Confirm reject partner`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onReject(id),
    });
  };

  onReject = async (id) => {
    await this.props.rejectPartnerAction(id, this.getPartner);
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  loadMore = () => {
    if (this.state.isAllSelected) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE });
  };

  isMore = () => {
    const { partners } = this.props;

    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    const data = hasFilter ? this.state.filterResult : partners;

    if (this.state.activeView === CARD_VIEW) {
      return false
    }

    if (!data) {
      return false;
    }

    return data.length > this.state.offset;
  };


  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`/partner/${id}`);
  };

  redirectToCreatePage = () => {
    return this.props.history.push(routeMapping.CREATE_CATEGORY.path);
  };

  onRedirectToWorkspace = () => {
    this.props.history.push("/workspaces?isArchived=true");
  }

  renderModal = () => {
    const { body, onConfirm, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={title}
        body={body}
        onClose={this.onCloseModal}
      />
    );
  };

    // pagination 
    onChangeLimit = (offset) => {
      this.setState({ offset: offset });
      this.setState({ currentPage: 1 });
    };
  
    setCurrentPage = (currentPage) => {
      this.setState({ currentPage: currentPage });
    };

  render() {
    const { color } = this.props;

    const { showModal, activeView, selectedIds, resetFilter, currentPage } = this.state;

    const viewHeadlineClass = classNames(
      `actions-nav__icon actions-nav__icon_rows ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === TABLE_VIEW }
    );

    const viewListClass = classNames(
      `actions-nav__icon actions-nav__icon_cards ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === CARD_VIEW }
    );

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    if(this.props.isLoading) {
      return <PreLoaderList/>
    }

    return (
      <>
        {showModal && this.renderModal()}
        <ListScroll>
          {(scrollParent) => (
            <>
              <div className="headline">
                <h1 className="headline__title">Partners</h1>
              </div>
              <Breadcrumb breadcrumbClass='breadcrumbs_static'>
                <BreadcrumbItem />
                <BreadcrumbItem route="Workspaces with activated Partner Portal." />
              </Breadcrumb>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}>
                    <span
                      className="actions__option cursor"
                      onClick={() => this.onRedirectToWorkspace()}
                    >
                      Show archived Workspaces
                    </span>
                  </VerticalButtonMenu>
                </div>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
              </div>
              {!hasFilter &&
              !this.props.partners.length &&
              !this.props.isLoading ? (
                <NotFound
                  color={color}
                  strokeWidth="1"
                  logo={<List size={42} />}
                  title="No partner available"
                />
              ) : (
                <>
                  <div className="filters-bar">
                    <ListSelectedItems
                      selectedNumber={selectedIds.length}
                      search={this.state.searchName}
                      onReset={this.onReset}
                      filterData={this.state.filter}
                      containerClassName={color}
                    />
                    <Filter
                      customClass={color}
                      resetFilter={resetFilter}
                      onChange={this.onFilter}
                      columns={filterColumns}
                      data={this.props.partners}
                      defaultFilter={[{
                        field: "status",
                        isDefault: true,
                        value: "All",
                        columns: [
                          "none",
                          "pending",
                          "approved",
                          "rejected",
                          "inactive",
                          "needs update",
                        ],
                      }]}
                    />
                    <SearchField
                      onSearch={this.onSearch}
                      customClass={color}
                      resetSearch={this.state.resetFilter}
                      label="Search"
                    />
                  </div>
                  {!this.props.isLoading &&
                  <div>
                    {this.props.partners && !!this.props.partners.length &&
                    <InfiniteScroll
                      initialLoad={false}
                      useWindow={false}
                      getScrollParent={() => scrollParent}
                    >
                      {activeView === TABLE_VIEW && (
                        <>
                        <ListTable
                          data={
                            hasFilter
                              ? this.state.filterResult.filter(
                                (item, index) => {
                                  if (
                                    currentPage === 1 &&
                                    index < this.state.offset
                                  ) {
                                    return item;
                                  }
    
                                  if (
                                    currentPage !== 1 &&
                                    index < this.state.offset * currentPage &&
                                    index >=
                                      this.state.offset * (currentPage - 1)
                                  ) {
                                    return item;
                                  }
    
                                  return false;
                                }
                              )
                              : this.props.partners.filter(
                                (item, index) =>  {
                                  if (
                                    currentPage === 1 &&
                                    index < this.state.offset
                                  ) {
                                    return item;
                                  }

                                  if (
                                    currentPage !== 1 &&
                                    index < this.state.offset * currentPage &&
                                    index >=
                                      this.state.offset * (currentPage - 1)
                                  ) {
                                    return item;
                                  }

                                  return false;
                                }
                              )
                          }
                          onRowClick={this.onRowClick}
                          selectAll={this.selectAll}
                          onSort={this.onSort}
                          color={color}
                          isLoading={this.props.isLoading}
                          sort={this.state.sort}
                          isSelectedAll={this.getIsSelectedAll()}
                          selectedIds={selectedIds}
                          onSelect={this.onSelect}
                          onReject={this.onRejectPartner}
                          onApprove={this.onApprovePartner}
                          onActivate={this.onActivatePartner}
                          onDeactivate={this.onDeactivatePartner}
                        />
                          </>
                      )}
                      {activeView === CARD_VIEW && (
                        <div className="data-cards">
                          <Card
                            color={color}
                            data={
                              hasFilter
                                ? this.state.filterResult.filter(
                                  (item, index) => {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
      
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
      
                                    return false;
                                  }
                                )
                                : this.props.partners.filter(
                                  (item, index) =>  {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }
  
                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }
  
                                    return false;
                                  }
                                )
                            }
                            onDuplicate={this.onDuplicate}
                            onRowClick={this.onRowClick}
                            selectedItems={selectedIds}
                            onReject={this.onRejectPartner}
                            onApprove={this.onApprovePartner}
                            onActivate={this.onActivatePartner}
                            onDeactivate={this.onDeactivatePartner}
                            onSelect={this.onSelect}
                          />
                        </div>
                      )}
                        {((hasFilter && !!this.state.filterResult.length) ||
                          (!hasFilter && !!this.props.partners.length)) &&
                          <Pagination
                            onNext={this.onNext}
                            currentPage={this.state.currentPage}
                            setCurrentPage={this.setCurrentPage}
                            onChangeLimit={this.onChangeLimit}
                            limit={this.state.offset}
                            totalPage={hasFilter ? this.state.filterResult.length : this.props.partners.length}
                          />
                          }
                    </InfiniteScroll>
  }
                    {activeView === CARD_VIEW &&
                      this.props.partners &&
                      !this.props.partners.length && <NoPlans />}
                  </div>
                  }
                </>
              )}
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

ListingList.propTypes = {
  color: PropTypes.string,
  partners: PropTypes.array,
  activeView: PropTypes.string,
};

ListingList.defaultProps = {
  color: "accent1",
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    partners: state.partner.list,
    isLoading: state.partner.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  fetchPartners,
  rejectPartnerAction,
  activatePartneAction,
  approvePartnerAction,
  deactivatePartnerAction,
});

export default compose(withRouter, withConnect)(ListingList);
