import {plans} from '../constants/plans';

const INITIAL_STATE = {
  list: [],
  isLoading: false,
  detail: {},
  workspaces: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case plans.GET_PLANS_PENDING:
      return {
        ...state,
        isLoading: true,
        list: []
      };
    case plans.GET_PLANS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        list: action.payload
      };
    case plans.GET_PLANS_REJECTED:
      return {
        ...state,
        isLoading: true,
        list: []
      };
      case plans.GET_PLAN_DETAILS_PENDING:
        return {
          ...state,
          isLoading: true,
          detail: {}
        };
      case plans.GET_PLAN_DETAILS_FULFILLED:
        return {
          ...state,
          isLoading: false,
          detail: action.payload
        };
      case plans.GET_PLAN_DETAILS_REJECTED:
        return {
          ...state,
          isLoading: false,
          detail: {}
        };
        case plans.GET_SUBSCRIPTIONS_WORKSPACE_REQUEST:
          return {
            ...state,
            isLoading: true,
            workspaces: []
          };
        case plans.GET_SUBSCRIPTIONS_WORKSPACE_SUCCESS:
          return {
            ...state,
            isLoading: false,
            workspaces: action.payload
          };
        case plans.GET_SUBSCRIPTIONS_WORKSPACE_REJECTED:
          return {
            ...state,
            isLoading: false,
            workspaces: []
          };
    default:
      return state;
  }
}