import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { List } from "react-feather";


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'

const columnList = [{
  label: 'Listing Name',
  isSortable: true,
  isLocked: true,
  sortKey: 'name'
},
{
  label: 'Type',
  sortable: false,
  isSortable: true,
  sortKey: 'type'
},{
  label: 'Listing ID',
  sortable: false,
  isSortable: true,
  sortKey: 'id'
},
{
  label: 'Status',
  sortable: false,
  isSortable: true,
  sortKey: 'status'
},

{
  label: 'Partner',
  sortable: false,
  isSortable: true,
  sortKey: 'partner'
},
{
  label: 'Categories',
  sortable: false,
  isSortable: true,
  sortKey: 'categories'
},
{
  label: <FormattedMessage {...appMessages.modified} />,
  sortable: false,
  isSortable: true,
  sortKey: 'updatedAt',
  isOptional: true
},
{
  label: <FormattedMessage {...appMessages.createdAt} />,
  sortable: false,
  isSortable: true,
  sortKey: 'createdAt'
},
{
  label: 'PreInstallable',
  sortable: false,
  isSortable: true,
  isOptional: true,
  sortKey: 'preInstallable'
},
]

class ListingTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        notFoundIcon={<List size={42}/>}
        rows={data || []}
        colorClassName={color}
        CheckboxImage={List}
        isSelectable
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        onPreInstall={this.props.onPreInstall}
        isLoading={this.props.isLoading}
        onReject={this.props.onReject}
        onApprove={this.props.onApprove}
        onReview={this.props.onReview}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
        onUpdateStatus={this.props.onUpdateStatus}
      />
    )
  }
}

ListingTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ListingTable
