import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';

import {updatePayment} from 'app/api/payment';
import { getDuration, convertDefaultStatusToStatus } from 'utils/common';
import ProcessPage from 'app/components/common/ProcessPage';
import CustomInput from 'app/components/common/Input/CustomInput';
import CustomTextarea from 'app/components/common/Input/CustomTextarea';
import Tags from 'app/components/common/Tags';
import appMessages from 'utils/message';
import EditForm from 'app/components/common/Edit/EditForm';

import { defaultNotifier } from 'functions/notificationHandler';


class EditInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: props.payment.note || '',
      id: props.payment.id || '',
      tags: props.payment.tags || [],
      reference: props.payment.reference || ''
    };
  }

  onAddTags = tags => {
    this.setState({ tags });
  };

  onChangeField = (note, value) => {
    this.setState({ [note]: value });
  };

  onSubmitPayment = async () => {
    const {payment} = this.props

    const { note, reference, tags } = this.state;

    const payload = {
      id: payment.id,
      note,
      reference,
      tags
    };

    const {data} = await updatePayment(payload);

    if (data.errors) {
      return defaultNotifier(data.errors)
    }

    this.props.getPayment()
    this.props.onClose();
  };

  renderContent = () => {
    const { color } = this.props;
    const { note, reference } = this.state;

    return (
      <>
        <div classnote={`text-field text-field_${color}`}>
          <CustomInput
            value={reference}
            onChange={e => this.onChangeField('reference', e.target.value)}
            label='Reference'
          />
        </div>
        <div classnote={`text-field text-field_${color}`}>
          <CustomInput
            value={note}
            onChange={e => this.onChangeField('note', e.target.value)}
            label='Note'
          />
        </div>
        <Tags
          tags={this.state.tags}
          tagClass={color}
          onChange={this.onAddTags}
        />
      </>
    );
  };

  render() {
    const { color, payment } = this.props;

    const { note, reference, tags } = this.state;
    const hasChange =
      payment.note !== note ||
      payment.reference !== reference ||
      !isEqual((payment.tags) || [], tags);

    const submitClass = classnames(`button button_${color} button-save`, {
      button_disabled: !hasChange
    });
    return (
      <ProcessPage onCancel={this.props.onClose} isChanged={hasChange} showUnsavedWarning>
        {() => (
          <EditForm
            color={this.props.color}
            onCancel={this.props.onClose}
            submitLabel={<FormattedMessage {...appMessages.save} />}
            submitClassnote={submitClass}
            onSubmit={this.onSubmitPayment}
            content={this.renderContent()}
            title={'Payment Information'}
            note={payment.note}
            subTitle={
              <>
                {payment.invoiceNumber} • <span classnote="text-capitalize">{payment.paymentDate}</span>
              </>
            }
          />
        )}
      </ProcessPage>
    );
  }
}

EditInformation.propTypes = {
  color: PropTypes.string,
  model: PropTypes.object,
  onClose: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(EditInformation);
