import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";
import { List } from "react-feather";

import SmallCard from "../../common/SmallCard";
import { getInitials } from 'utils/common';

import SingleMenu from "./SingleMenu";

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getStatus = (status) => {
    const { currentSortKey } = this.props;

    const statusClass = classNames(
      "data-cards__status c-text text-capitalize",
      {
        success: status === "active",
        warning: status === "pending",
        failure: status === "inactive",
        "data-table__td_dark": currentSortKey === "status",
      }
    );

    return (
      <>
        <i className={statusClass}>•</i> {status}
      </>
    );
  };

  getSubheading = (status) => {
    return (
      <>
        {this.getStatus(status)}
      </>
    );
  };

  render() {
    const {
      color,
      data,
      email,
      onSelect,
      selectedItems,
    } = this.props;    
      return (
        <div className="grid-row">
        {data.map((item) => {
          return (
          <SmallCard
            key={item.id}
            heading={item.email}
            id={item.id}
            initials={getInitials(item.firstName, item.lastName)}
            onCardClick={this.props.onRowClick}
            colorClassName={color}
            subheading={this.getSubheading(item.status)}
            selectedItems={selectedItems}
            onSelect={onSelect}
            CheckboxImage={<List/>}
          >
            <SingleMenu            
            devPlanActivated={item.devPlanActivated}
            email={item.email}
            onDeactivateDev={this.props.onDeactivateDev}
            onActivateDev={this.props.onActivateDev}
            onActivate={this.props.onActivate}
            onDeactivate={this.props.onDeactivate}
              id={item.id}
              status={item.status}
            />
          </SmallCard>
        )}
        )}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
