import React from 'react';
import PropTypes from 'prop-types';

import { SOLUTION_ACCENT } from 'app/constants/common';

class EstimateItem extends React.Component {
  render() {
    const { provider, plan, period, amount, name } = this.props;

    return (
      <tr className="data-table__row data-table__row_noclick">
        <th className="data-table__td data-table__td_1" />
        <td className="data-table__td data-table__td_dark fw500">
          {name}
        </td>
        <td className="data-table__td">{plan}</td>
        <td className="data-table__td">{period}</td>
        <td className="data-table__td">{provider}</td>
        <td className="data-table__td data-table__td_dark fw500 ta-right">
          {amount}
        </td>
        <th className="data-table__td data-table__td_1" />
      </tr>
    );
  }
}

EstimateItem.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  newSubscriptions: PropTypes.string,
  totalSubscriptions: PropTypes.string,
  earningPeriod: PropTypes.string,
  amount: PropTypes.string
};

EstimateItem.defaultProps = {
  color: SOLUTION_ACCENT
};

export default EstimateItem;
