import {sortBy} from 'lodash'

import {
  getInvoices,
  getInvoice,
} from "../api/invoices";
import { invoices } from "../constants/invoices";
import { getInvoice as getInvoiceFormat } from 'utils/common';
import { defaultNotifier } from "functions/notificationHandler";

export function listInvoice(payload) {
  return async (dispatch) => {
    dispatch({ type: invoices.GET_INVOICES_PENDING });

    try {
      const { data } = await getInvoices(payload);

      if (data.errors) {
        defaultNotifier(data.errors);
        dispatch({
          type: invoices.GET_INVOICES_REJECTED,
          error: {
            message: "Error while fetching invoices.",
          },
        });
      }

      const list = sortBy(data.data.invoices.data, [
        'invoiceNumber',
      ]).reverse();

      dispatch({
        type: invoices.GET_INVOICES_FULFILLED,
        payload: {
          data: list,
          count: data.data.invoices.count
        }
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the invoices");

      dispatch({
        type: invoices.GET_INVOICES_REJECTED,
        error: {
          message: "Error while fetching invoices.",
        },
      });
    }
  };
}

export function getInvoiceDetails(payload) {
  return async (dispatch) => {
    dispatch({ type: invoices.GET_INVOICE_DETAILS_PENDING });

    try {
      const { data } = await getInvoice(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      dispatch({
        type: invoices.GET_INVOICE_DETAILS_FULFILLED,
        payload: data.data.invoice,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the invoices");
    }
  };
}
