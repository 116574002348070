import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import { dateFormatter } from "utils/common";

class Overview extends Component {
  getStatus = (isActive) => {
    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": isActive === "active",
        "data-table__status_neg": isActive === "inactive",
      }
    );

    return <div className={className}>{isActive}</div>;
  };

  renderContent = () => {
    const { category, color } = this.props;
    const {category: {createdBy={}}} = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Category-ID</div>
          <div className="overview__value">{category.id}</div>
          <div className="overview__label">Created</div>
          <div className="overview__value">
            {dateFormatter(category.createdAt, true)}
          </div>
        </div>
        <div className="grid-col">
          <div className="overview__label">
            <FormattedMessage {...messages.status} />
          </div>
          <div className="overview__value">
            {this.getStatus(category.status)}
          </div>
          <div className="overview__label">Created by</div>
          <div className="overview__value">
            {createdBy && <span className={`cursor ${color}`}>{`${createdBy.firstName} ${createdBy.lastName}`}</span>}
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.overview} />}
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Overview.propTypes = {
  onEdit: PropTypes.func,
  color: PropTypes.string,
  workspace: PropTypes.object,
};

export default Overview;
