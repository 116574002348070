import moment from 'moment'
import { uniq } from 'lodash'
import * as timeago from 'timeago.js'
import { isEmpty, xorWith, isEqual, isArray } from 'lodash';

const DOMAIN_NAME = '.viso'
const DEFAULT_SHORTENED_TEXT_COUNT = 35

export const isLocalhost = window.location.href.startsWith("http://localhost")

export const dateFormatter = (value, isTime) => {
  const dateTime = isNaN(value)
    ? value
    : Number(value)

  if (isTime) {
    return moment(dateTime).format('MMM D, YYYY HH:mm');
  }

  return moment(dateTime).format('MMM D, YYYY');
}

export const dateFormatter2 = (value, isTime) => {
  const dateTime = isNaN(value)
    ? value
    : Number(value)

  return moment(dateTime).format('D MMM, YYYY')
}

export const getLastUpdateTime = (date) => {
  return moment(date, 'YYYYMMDD').fromNow()
}

export const dateFormatter4 = (value) => {
  const dateTime = isNaN(value) ? value : Number(value);

  return moment(dateTime).format('MMM DD, YYYY');
};

export const detailDateFormatter = (value, isTime) => {
  const dateTime = isNaN(value)
    ? value
    : Number(value)

  if (isTime) {
    return moment(dateTime).format('DD.MM.YYYY HH:mm')
  }

  return moment(dateTime).format('DD.MM.YYYY')
}

export const getDuration = (value) => {
  return timeago.format(value)
}

export const snakeCaseToTitleCase = (str) => {
  return str
    .replace(/_/g, ' ')
    .replace(/-/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
}

export const truncate = (name, size) => {
  const count = size || DEFAULT_SHORTENED_TEXT_COUNT

  if (!name) {
    return name
  }

  if ((name && name.length <= count) || (typeof (name) === 'number')) {
    return name
  }

  const subString = name.substring(0, count - 1)

  return `${subString}...`
}

export const getColumns = (data, columnName) => {
  return uniq(data.map(item => item[columnName]))
}

export const getLangauge = (lang) => {
  const languages = {
    de: 'German',
    en: 'English'
  }

  return languages[lang] || 'English'
}

export const getInitials = (firstName, lastName) => {
  const firstNameInitial = firstName ? firstName[0].toUpperCase() : ''
  const lastNameInitial = lastName ? lastName[0].toUpperCase() : ''

  return `${firstNameInitial}${lastNameInitial}` || 'N/A'
}

export const getSubDomain = () => {
  const subDomainList = window.location.host.split(DOMAIN_NAME)

  if (subDomainList.length > 1) {
    return subDomainList[0]
  }

  return ''
}

export const getShortLang = {
  'english': 'en',
  'german': 'de'
}

export const getLanguage = {
  'english': 'English',
  'german': 'Deutsch (German)'
}

export const getRoleType = (name) => {
  const isDefaultRole = ['Admin', 'Member'].includes(name)

  if (isDefaultRole) {
    return 'System'
  }

  return 'Custom'
}

export const getMRR = (value, plan) => {

  if(!(plan && plan.tiers)) {
    return 0
  }

  const planTiers = plan.tiers.map((tier, index) => {
    if (index === plan.tiers.length - 1) {
      return { unit_amount: tier.unit_amount, up_to: 5000 };
    }
    return { ...tier, unit_amount: tier.unit_amount };
  });

  let price = 0;
  let remainingUnit = +value;

  // Logic to calculate price
  if (+value <= +planTiers[0].up_to) {
    price = planTiers[0].unit_amount * value;
  } else {
    planTiers.forEach((tier, index) => {
      let planPrice = price;
      if (remainingUnit > 0) {
        if (remainingUnit >= tier.up_to) {
          planPrice =
            (tier.up_to -
              (planTiers[index - 1] ? planTiers[index - 1].up_to : 0)) *
            tier.unit_amount;
        } else {
          planPrice = remainingUnit * tier.unit_amount;
        }
        remainingUnit -=
          tier.up_to -
          (planTiers[index - 1] ? planTiers[index - 1].up_to : 0);
        price += planPrice;
      }
    });
  }

  return `${parseFloat(price / 100).toFixed(2)}`
};

export const getPrice = (plan) => {

  if(!(plan && plan.phases)) {
    return 0
  }

  const price = plan.phases[0].prices.map(item => item.value).reduce((sum, price) => sum + price.value);

  return `$ ${price}`
};

export const getInterval = {
  'month': 'Monthly',
  'year': 'Yearly',
}

export const isArrayEqual = (array1, array2) => {
  if (isArray(array1) && isArray(array2)) {
    return isEmpty(xorWith(array1, array2, isEqual));
  }

  return false;
};

export const convertDefaultStatusToStatus = (status) => {

  if (!status) {
    return '-'
  }
  const statusList = status.split('_');

  return statusList.reduce((allStatus, item) => `${allStatus} ${item}`);
}

export const isSmallerDevice = device => {
  if (device === 'tablet' || device === 'mobile') {
    return true;
  }

  return false;
};


export const toolbarOptions = [
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic'],
  ['link'],
];

export const clipboard =
{
  allowed: {
    tags: ['a', 'b', 'strong'],
    attributes: ['href', 'rel', 'target', 'class']
  },
  keepSelection: true,
  magicPasteLinks: true,
}

export const getPaymentMethod = (id) => {
  if (id === 1) {
    return "Bank Transaction";
  }

  if (id === 2) {
    return "Cash";
  }

  if (id === 3) {
    return "Credit Card";
  }

  if (id ===4) {
    return "Online Payment";
  }
};

export const getPaymentName = (payment) => {
  if(payment.bank && payment.creditCard) {
    return 'Credit Card, Bank Transaction'
  }

  if(payment.bank && !payment.creditCard) {
    return 'Bank Transaction'
  }

  if(!payment.bank && payment.creditCard) {
    return 'Credit Card'
  }

  return ''
};


export const getInvoice = (version) => {
  if (!version) {
    return null;
  }

  if (version.toString().length === 1) {
    return `INV-0000${version}`;
  }

  if (version.toString().length === 2) {
    return `INV-000${version}`;
  }

  if (version.toString().length === 3) {
    return `INV-00${version}`;
  }

  return 'INV-00001';
};

export const dateFormatter5 = (value) => {
  const dateTime = isNaN(value) ? value : Number(value);

  return moment(dateTime).format('MMM-DD-YYYY');
};

export const getPriceFormat = (val) => {
  // remove sign if negative
  let sign = 1;
  if (val < 0) {
    sign = -1;
    val = -val;
  }

  // trim the number decimal point if it exists
  let num = val.toFixed(2).toString().includes('.') ? val.toFixed(2).toString().split('.')[0] : val.toFixed(2).toString();
  let len = num.toString().length;
  let result = '';
  let count = 1;

  for (let i = len - 1; i >= 0; i--) {
    result = num.toString()[i] + result;
    if (count % 3 === 0 && count !== 0 && i !== 0) {
      result = ',' + result;
    }
    count++;
  }

  // add number after decimal point
  if (val.toFixed(2).toString().includes('.')) {
    result = result + '.' + val.toFixed(2).toString().split('.')[1];
  }
  // return result with - sign if negative
  result = sign < 0 ? '-' + result : result;

  return `USD ${result}`;
}

export const getPaymentFormat = (version) => {
  if (!version) {
    return null;
  }

  if (version.toString().length === 1) {
    return `PMT-0000${version}`;
  }

  if (version.toString().length === 2) {
    return `PMT-000${version}`;
  }

  if (version.toString().length === 3) {
    return `PMT-00${version}`;
  }

  return 'PMT-00001';
};

export const getAddressInfo = (user) => {
  let address = '';

  if (!user) {
    return '';
  }

  if (user.address2) {
    address = `${user.address2}`;
  }

  if (user.city) {
    address = address ? `${address}, ${user.city}` : ` ${user.city}`;
  }

  if (user.postCode) {
    address = address ? `${address}, ${user.postCode}` : ` ${user.postCode}`;
  }

  return address || '';
}
