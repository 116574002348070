import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { PaymentIcon } from "images/common";
import DetailScroll from "app/components/common/Scroll";
import Workspace from "./components/Workspace";
import Information from "./components/Information";
import RelatedInvoice from "./components/RelatedInvoice";
import Organization from "./components/Organization";
import BillingSettings from "./components/BillingSettings";
import BillingInfo from "./components/BillingInfo";
import RelatedWorkspace from "../relatedWorkspace";
import Invoice from "./components/Invoice";
import Metrics from "./components/Metrics";
import DeleteWorkspace from "../DeleteWorkspace";
import {
  updateWorkspaceAnnotationSetting,
  updateWorkspaceTrainingSetting,
  cvEnvs,
} from "app/api/workspaces";
import { defaultNotifier } from "functions/notificationHandler";

import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";

import { DEFAULT_ACCENT } from "app/constants/common";
import {
  getPaymentAction,
} from "app/actions/payments";
import SingleMenu from "../list/SingleMenu";
import messages from "utils/message";
import { getPaymentFormat } from "utils/common";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import ActionMenu from "app/components/common/ActionMenu";

import Modal from "app/components/common/Modal";
import { routeMapping } from "app/constants/routes";
import AssociatedContact from "./components/AssociatedContact";
import Overview from "./components/Overview";
import AddRecord from "../recordPayment/AddRecord";
import PreLoader from "app/components/common/PreLoader/PreLoaderDetail";

class WorkspaceDetail extends React.Component {
  state = {
    payment: {},
    showDeleteModal: false,
    showDropdown: false,
    loggedInUser: this.props.loggedInUser,
    editPopupForm: null,
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  getAgents = () => {
    const { id } = this.props.match.params;

    this.props.getPayment({ workspaceId: id });
  };

  redirectToWorkspaces = () => {
    this.setState({ showDeleteModal: false });

    this.props.history.push(routeMapping.WORKSPACES.path);
  };

  getPayment = () => {
    const { id } = this.props.match.params;

    this.props.getPaymentAction({ id });
  }

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.props.getPaymentAction({ id });
  }

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onEdit = (formName) => {
    this.setState({ editPopupForm: formName });
  };

  onEditClose = () => {
    this.setState({ editPopupForm: null });
  };

  renderModal = () => {
    const { body, onConfirm, onCancel, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={onCancel}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={onCancel} />
    );
  };

  onManageAgent = () => {
    const { id } = this.props.match.params;

    this.props.history.push(`/payment/${id}/agents`);
  };

  onShowRelatedWorkspaceModal = () => {
    this.setState({showRelatedWorkspaceModal: true})
  }

  render() {
    const { color, payment, invoices } = this.props;
    const { showModal } = this.state;
    const { id } = this.props.match.params;

    if (this.props.isLoading) {
      return <PreLoader color={this.props.colorClassName} />;
    }

    if (!payment) {
      return <DetailScroll />;
    }

    if (this.state.showRelatedWorkspaceModal) {
      return <RelatedWorkspace 
      payment={payment}
      subscription={[payment.workspace]}
      onClose={() => this.setState({showRelatedWorkspaceModal: false})}
      color={this.props.colorClassName} />;
    }

    return (
      <DetailScroll>
        {showModal && this.renderModal()}
        <div className={"messages-bar snackbar-wrapper"}></div>
        <div className="headline">
          <div className="headline__icon admin_workspace__image accent0 headline__icon_lg">
            <PaymentIcon />
          </div>
          <h1 className="headline__title">Payment {getPaymentFormat(payment.paymentNumber)}</h1>
        </div>

        <Breadcrumb breadcrumbClass={`breadcrumbs breadcrumbs_${color}`}>
          <BreadcrumbItem route="Payments" />
          <BreadcrumbItem route={getPaymentFormat(payment.paymentNumber)} />
        </Breadcrumb>

        <div className="actions-nav">
          <div className="actions">
            <VerticalButtonMenu customClass={color}>
              {/* <ActionMenu
                onClick={(e) => null}
                className={"actions__option"}
                text="Delete Payment"
              ></ActionMenu> */}
            </VerticalButtonMenu>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col grid-col_sm-12">
            {payment && <Information color={color} payment={payment} getPayment={this.getPayment} />}
            {payment && payment.workspace && <Workspace payment={payment} color={color} />}
            {payment && payment.workspace && (
              <AssociatedContact color={color} payment={payment} />
            )}
          </div>
          <div className="grid-col grid-col_sm-12">
            {payment && <Overview payment={payment} color={color} />}
            {payment && <RelatedInvoice payment={payment} color={color} />}
          </div>
        </div>
      </DetailScroll>
    );
  }
}

WorkspaceDetail.propTypes = {
  color: PropTypes.string,
  payment: PropTypes.object,
  listRequest: PropTypes.func,
  workspaces: PropTypes.array,
  loggedInUser: PropTypes.object,
  updateWorkspaceRequest: PropTypes.func,
};

WorkspaceDetail.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    payment: state.payments.detail,
    isLoading: state.payments.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  getPaymentAction,
});

export default compose(withRouter, withConnect)(WorkspaceDetail);
