import React from 'react'

export default () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M17.8573 15.7141C17.5391 15.7155 17.2254 15.7888 16.9394 15.9284L13.868 12.8569H11.4287V14.2855H13.2759L15.9294 16.9391C15.7897 17.225 15.7162 17.5387 15.7144 17.8569C15.7144 18.2807 15.8401 18.695 16.0756 19.0474C16.311 19.3998 16.6457 19.6745 17.0372 19.8367C17.4288 19.9989 17.8597 20.0413 18.2753 19.9586C18.691 19.8759 19.0728 19.6718 19.3725 19.3722C19.6722 19.0725 19.8763 18.6907 19.959 18.275C20.0416 17.8593 19.9992 17.4285 19.837 17.0369C19.6748 16.6453 19.4002 16.3107 19.0478 16.0752C18.6954 15.8398 18.2811 15.7141 17.8573 15.7141ZM17.8573 18.5712C17.716 18.5712 17.5779 18.5293 17.4604 18.4508C17.343 18.3724 17.2514 18.2608 17.1974 18.1303C17.1433 17.9998 17.1292 17.8561 17.1567 17.7176C17.1843 17.579 17.2523 17.4518 17.3522 17.3519C17.4521 17.252 17.5794 17.1839 17.7179 17.1564C17.8565 17.1288 18.0001 17.143 18.1306 17.197C18.2611 17.2511 18.3727 17.3426 18.4512 17.4601C18.5297 17.5776 18.5716 17.7157 18.5716 17.8569C18.5716 18.0464 18.4963 18.2281 18.3624 18.362C18.2284 18.496 18.0467 18.5712 17.8573 18.5712Z" fill="currentColor"/>
      <path d="M17.8573 7.85694C17.4155 7.85825 16.985 7.99628 16.6249 8.25206C16.2647 8.50785 15.9926 8.86886 15.8459 9.28551H11.4287V10.7141H15.8459C15.9774 11.0835 16.208 11.4096 16.5124 11.6568C16.8168 11.9041 17.1833 12.0629 17.5719 12.1159C17.9604 12.1689 18.3561 12.1141 18.7156 11.9574C19.0751 11.8008 19.3846 11.5484 19.6104 11.2277C19.8361 10.9071 19.9694 10.5305 19.9956 10.1392C20.0218 9.74798 19.94 9.35703 19.759 9.00912C19.5781 8.66122 19.305 8.36974 18.9696 8.16654C18.6342 7.96334 18.2494 7.85624 17.8573 7.85694ZM17.8573 10.7141C17.716 10.7141 17.5779 10.6722 17.4604 10.5937C17.343 10.5152 17.2514 10.4037 17.1974 10.2731C17.1433 10.1426 17.1292 9.999 17.1567 9.86044C17.1843 9.72189 17.2523 9.59461 17.3522 9.49472C17.4521 9.39482 17.5794 9.32679 17.7179 9.29923C17.8565 9.27167 18.0001 9.28582 18.1306 9.33988C18.2611 9.39394 18.3727 9.48549 18.4512 9.60296C18.5297 9.72042 18.5716 9.85852 18.5716 9.99979C18.5716 10.1892 18.4963 10.3709 18.3624 10.5049C18.2284 10.6388 18.0467 10.7141 17.8573 10.7141Z" fill="currentColor"/>
      <path d="M17.8573 8.98418e-07C17.2891 0.000568195 16.7444 0.226515 16.3427 0.628256C15.9409 1.03 15.715 1.57471 15.7144 2.14286C15.7169 2.48504 15.8022 2.82154 15.963 3.12357L13.283 5.71429H11.4287V7.14286H13.8601L17.003 4.10571C17.2949 4.23302 17.6116 4.29363 17.9299 4.28312C18.2483 4.27261 18.5602 4.19124 18.8432 4.04495C19.1261 3.89866 19.3728 3.69112 19.5654 3.43745C19.758 3.18377 19.8916 2.89034 19.9565 2.57852C20.0213 2.2667 20.0159 1.94433 19.9405 1.63489C19.865 1.32544 19.7216 1.03671 19.5205 0.789716C19.3194 0.542718 19.0658 0.343667 18.7781 0.207056C18.4903 0.0704439 18.1758 -0.000290782 17.8573 8.98418e-07ZM17.8573 2.85714C17.716 2.85714 17.5779 2.81525 17.4604 2.73676C17.343 2.65828 17.2514 2.54672 17.1974 2.4162C17.1433 2.28568 17.1292 2.14207 17.1567 2.00351C17.1843 1.86495 17.2523 1.73768 17.3522 1.63778C17.4521 1.53789 17.5794 1.46986 17.7179 1.4423C17.8565 1.41474 18.0001 1.42888 18.1306 1.48294C18.2611 1.53701 18.3727 1.62856 18.4512 1.74602C18.5297 1.86349 18.5716 2.00159 18.5716 2.14286C18.5716 2.3323 18.4963 2.51398 18.3624 2.64793C18.2284 2.78189 18.0467 2.85714 17.8573 2.85714Z" fill="currentColor"/>
      <path d="M11.4286 2.85728H12.8571V1.42871H11.4286C11.0215 1.43007 10.6195 1.51892 10.2498 1.68924C9.88007 1.85955 9.55128 2.10736 9.28571 2.41585C9.02014 2.10736 8.69136 1.85955 8.32164 1.68924C7.95192 1.51892 7.54991 1.43007 7.14286 1.42871H6.42857C4.72419 1.4306 3.09015 2.1085 1.88497 3.31368C0.679792 4.51886 0.00189061 6.1529 0 7.85728V12.143C0.00189061 13.8474 0.679792 15.4814 1.88497 16.6866C3.09015 17.8918 4.72419 18.5697 6.42857 18.5716H7.14286C7.54991 18.5702 7.95192 18.4814 8.32164 18.311C8.69136 18.1407 9.02014 17.8929 9.28571 17.5844C9.55128 17.8929 9.88007 18.1407 10.2498 18.311C10.6195 18.4814 11.0215 18.5702 11.4286 18.5716H12.8571V17.143H11.4286C11.0498 17.1426 10.6867 16.992 10.4188 16.7242C10.151 16.4563 10.0004 16.0932 10 15.7144V4.28585C10.0004 3.90709 10.151 3.54395 10.4188 3.27612C10.6867 3.00829 11.0498 2.85766 11.4286 2.85728ZM7.14286 17.143H6.42857C5.22746 17.1408 4.0672 16.7067 3.1597 15.9199C2.2522 15.133 1.65803 14.046 1.48571 12.8573H2.85714V11.4287H1.42857V8.57157H3.57143C4.13958 8.571 4.68429 8.34505 5.08603 7.94331C5.48777 7.54157 5.71372 6.99686 5.71428 6.42871V5.00014H4.28571V6.42871C4.28571 6.61815 4.21046 6.79983 4.0765 6.93379C3.94255 7.06774 3.76087 7.143 3.57143 7.143H1.48571C1.65803 5.95431 2.2522 4.86727 3.1597 4.08042C4.0672 3.29358 5.22746 2.85943 6.42857 2.85728H7.14286C7.52162 2.85766 7.88476 3.00829 8.15259 3.27612C8.42042 3.54395 8.57105 3.90709 8.57143 4.28585V7.143H7.14286V8.57157H8.57143V11.4287H7.14286C6.57471 11.4293 6.02999 11.6552 5.62825 12.057C5.22651 12.4587 5.00057 13.0034 5 13.5716V15.0001H6.42857V13.5716C6.42857 13.3821 6.50383 13.2004 6.63778 13.0665C6.77173 12.9325 6.95342 12.8573 7.14286 12.8573H8.57143V15.7144C8.57105 16.0932 8.42042 16.4563 8.15259 16.7242C7.88476 16.992 7.52162 17.1426 7.14286 17.143Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20.0012" height="20" fill="currentColor"/>
      </clipPath>
    </defs>
  </svg>
)