import { EnumType } from 'json-to-graphql-query';

export const getFilterData = data => {
  let multiClientFilter = data &&
    Object.values(data)
      .filter(item => item.clientFilter)
      .filter(item => item.type === 'singleRange' || item.type === 'date')

  let singleClientFilter = data &&
      Object.values(data)
        .filter(item => item.clientFilter)
        .filter(item => item.type !== 'singleRange' && item.type !== 'date')


  let singleFilter =
    data &&
    Object.values(data)
      .filter(item => item.type === 'search')
      .filter(item => !item.clientFilter)
      .map(item => {
        return {
          operator: new EnumType('contains'),
          name: item.field,
          value: item.filterBy === 'id' ? item.ids : item.filterValue
        };
      });


  const filterItem =
    data &&
    Object.values(data)
      .filter(item => item.filterValue !== 'All')
      .filter(item => !item.clientFilter)
      .filter(
        item =>
          item.type === 'radio' ||
          item.type === 'checkbox' ||
          item.type === 'multiSelect'
      )
      .map(item => {
        return {
          operator: new EnumType('equalTo'),
          name: item.field,
          value: item.filterBy === 'id' ? item.ids : item.filterValue
        };
      });

  const filterTag =
    data &&
    Object.values(data)
      .filter(item => item.filterValue !== 'All')
      .filter(item => item.type === 'tags')
      .filter(item => !item.clientFilter)
      .map(item => {
        return {
          operator: new EnumType('in'),
          name: item.field,
          value: item.filterBy === 'id' ? item.ids : item.filterValue
        };
      });

  singleFilter = [...singleFilter, ...filterItem, ...filterTag];

  const range = data &&
  Object.values(data)
    .filter(item => item.type === 'singleRange')
    .filter(item => !item.clientFilter)
    .map(item => {
      return {
        name: item.field,
        value: {
          lower: 0,
          upper: item.filterBy === 'id' ? item.ids : item.filterValue[0]
        }
      };
    })

  const rangeFilter =
    data &&
    Object.values(data)
      .filter(item => item.type === 'date')
      .filter(item => !item.clientFilter)
      .map(item => {
        let startDate = new Date(item.filterValue.startDate).setHours(
          0,
          0,
          0,
          0
        );
        startDate = item.unixTime ?
        (new Date(startDate).getTime() / 1000)
        : new Date(startDate).toISOString();

        let endDate = new Date(item.filterValue.endDate).setHours(
          23,
          59,
          59,
          0
        );
        endDate = item.unixTime ?
          (new Date(endDate).getTime() / 1000)
          : new Date(endDate).toISOString();

        return {
          name: item.field,
          value: {
            lower: startDate,
            upper: endDate
          }
        };
      });

  const filterData = {
    single: singleFilter,
    clientFilter: {
      single: singleClientFilter,
      range: multiClientFilter
    },
    range: [...rangeFilter, ...range]
  };


  return { filterData };
};
