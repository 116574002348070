import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import NumberFormat from "react-number-format";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";

import { getMRR } from "utils/common";

class Metrics extends Component {
  renderContent = () => {
    const { color, plan } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.activeSubscriptions} />
          </div>
          <div className="overview__value">
            <span className={`${color} cursor`} onClick={(!!plan && !!plan.workspaceCount) ? this.props.showWorkspaceSubscribePage : undefined}>
              {(plan && plan.workspaceCount) || 0} Workspaces
            </span>
          </div>
          <div className="overview__label">
            <FormattedMessage {...messages.mrr} />
          </div>
          <div className="overview__value">
            <NumberFormat
              value={getMRR(plan.actualEndpoints, plan.prices && plan.prices[0])}
              displayType={"text"}
              thousandSeparator
              renderText={(value) => (
                <span>
                  {
                    plan.prices &&
                    plan.prices[0] &&
                    plan.prices[0].currency &&
                    plan.prices[0].currency.toUpperCase()}{" "}
                  {value}
                </span>
              )}
            />
          </div>
        </div>

        <div className="grid-col">
          <div className="overview__label">
            <FormattedMessage {...messages.billableEndpoints} />
          </div>
          <div className="overview__value">{plan.billableEndpoints}</div>
          <div className="overview__label">
            Actual Endpoints
          </div>
          <div className="overview__value">{plan.actualEndpoints}</div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.metrics} />}
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Metrics.propTypes = {
  onEdit: PropTypes.func,
  color: PropTypes.string,
  workspace: PropTypes.object,
};

export default Metrics;
