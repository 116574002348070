import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

import CustomInput from "app/components/common/Input/CustomInput";
import CustomTextarea from "app/components/common/Input/CustomTextarea";
import ProcessPage from "app/components/common/ProcessPage";
import EditForm from "app/components/common/Edit/EditForm";
import CustomSelect from "app/components/common/Input/CustomSelect";

import {addCharge} from 'app/api/workspaces';
import { defaultNotifier } from "functions/notificationHandler";

class AddCharge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChanged: false,
      comment: "",
      description: '',
      amount: '',
      currency: "USD",
    };
  }

  componentDidMount() {}

  onFieldChange = (e, name) => {
    this.setState({
      isChanged: true,
      [name]: e.target.value,
    });
  };

  onSubmit = async () => {
    const {id} = this.props;
    const {amount, comment, isLoading, description} = this.state;

    if(isLoading) {
      return false
    }

    if(amount && id && description) {
      this.setState({isLoading: true})
      const {data} = await addCharge({
        workspaceId: id,
        amount: parseFloat(amount),
        description,
        comment
      });

      if(data.errors) {
        defaultNotifier(data.errors)
        return this.setState({isLoading: false})
      }

      this.setState({isLoading: false})
  
      defaultNotifier('Charge added successfully.')
      this.props.getWorkspaces();
      this.props.onClose();
    }
  };

  renderContent = () => {
    const { color } = this.props;

    return (
      <>
        <div className="grid-row">
          <div className="grid-col">
            <div className={`text-field text-field_${color}`}>
              <CustomInput
                value={this.state.amount}
                type="number"
                parentClass={color}
                onChange={(e) => this.onFieldChange(e, "amount")}
                label="Amount"
                isInvalid={!this.state.amount}
                hintText={this.state.amount ? "" : "Amount is required"}
              />
            </div>
          </div>
          <div className="grid-col">
            <div className={`text-field text-field_${color}`}>
            <CustomSelect
              readOnly
              disabled
              value={this.state.currency}
              label="Currency"
              onChange={this.onUpdatePayment}
              options={[{value: 'USD', label: 'USD'}]}
            />
            </div>
          </div>
        </div>
          <CustomTextarea
            parentClass={color}
            value={this.state.description}
            onChange={(e) => this.onFieldChange(e, "description")}
            label="Description"
            type="textarea"
            isInvalid={!this.state.description}
            hintText={this.state.description ? "" : "Description is required"}
          />
          <CustomTextarea
            parentClass={color}
            value={this.state.comment}
            onChange={(e) => this.onFieldChange(e, "comment")}
            label="Comment"
            type="textarea"
          />
      </>
    );
  };

  render() {
    const {isLoading} = this.state;

    return (
      <ProcessPage onCancel={() => this.props.onClose()}>
        {() => (
          <EditForm
            color={this.props.color}
            submitLabel={isLoading ? "Loading..." : "Add Charge"}
            submitClassName={
              !this.state.amount || this.state.isLoading ? "round-button_disabled" : null
            }
            onSubmit={this.onSubmit}
            title="Add New Charge"
            subTitle={
              "Create a new charge for this workspace. A new invoice is created."
            }
            content={this.renderContent()}
            disabled={!this.state.amount || !this.state.description}
          />
        )}
      </ProcessPage>
    );
  }
}

AddCharge.propTypes = {
  profile: PropTypes.object,
  closePopup: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddCharge;
