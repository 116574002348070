import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'

import FilterType from './FilterType'
import { XCircleIcon } from 'images/common'
import { FILTER_TYPE } from './constants'
import {snakeCaseToTitleCase} from 'utils/common'

class FilterItem extends React.Component {
  constructor(props) {
    super(props)
    this.isUpdated = false
  }

  state = {
    isOpen: false,
    isDateFocus: false
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEscClick, false);
    this.setState({ isOpen: this.props.visibleFilter })
  }

  onEscClick = (event) => {
    if (!this.props.ignoreCloseOnEsc && event.keyCode === 27) {
      // on Esc button click it should close popup
      this.onCloseDropdown()
    }
  }

  onOpenDropdown = () => {
    this.setState({ isOpen: true })
    this.setState({ isDateFocus: false })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscClick, false);
  }

  onFocusDate =() => {
    this.setState({ isDateFocus: true })
  }

  onCloseDropdown = () => {

    this.setState({ isOpen: false, isDateFocus: false }, () => {
      const { data, onRemove } = this.props

      if (
        !data.filterValue &&
        data &&
        data.field &&
        onRemove &&
        !this.isUpdated
      ) {
        onRemove(data.field, this.isUpdated)
      }
    })
  }

  onFilterApply = (name, value, type, ids) => {
    const {
      data: {
        customValue
      }
    } = this.props

    if (type === FILTER_TYPE.CHECKBOX && !value.length) {
      return
    }

    if (typeof (value) !== 'number' && !value) {
      return
    }

    if (value) {
      const selectedFilters = {
        ...this.props.selectedFilters,
        [name]: {
          ...this.props.data,
          filterValue: customValue ? customValue[value] : value,
          ids: ids
        }
      }

      this.props.setFilterValue(selectedFilters)
      this.onCloseDropdown()
      this.isUpdated = true
    }
  }

  getTitle = () => {
    const {
      data: { filterValue, type, customKey }
    } = this.props

    let filterTitle = filterValue && `: "${customKey ? customKey[filterValue] : filterValue}"`

    if (type === FILTER_TYPE.SINGLE_RANGE) {
      filterTitle = (customKey || filterValue) && `: Upto ${customKey ? customKey[filterValue] : filterValue}`
    }

    if (type === FILTER_TYPE.MULTI_RANGE) {
      filterTitle = (customKey || filterValue) && `: ${filterValue[0]} - ${filterValue[1]}`
    }

    if (type === FILTER_TYPE.RADIO) {
      filterTitle =
      (customKey || filterValue) && `: ${snakeCaseToTitleCase(customKey ? customKey[filterValue] : filterValue)}`
    }

    if (type === FILTER_TYPE.CHECKBOX) {
      let filterData =
        filterValue &&
        filterValue.length &&
        filterValue.map(item => ` ${item} `)

      if (filterData) {
        filterData = filterData.toString()
        filterTitle = `: ${filterData}`
      } else {
        filterTitle = ''
      }
    }

    if (type === FILTER_TYPE.DATE) {
      if (filterValue) {

        if (filterValue.startDate && !filterValue.endDate) {
          filterTitle = `: ${moment(filterValue.startDate).format(
            'DD MMM YYYY'
          )}`
        }

        if (filterValue.startDate && filterValue.endDate) {
          filterTitle = `: ${moment(filterValue.startDate).format(
            'DD MMM YYYY'
          )} - ${moment(filterValue.endDate).format('DD MMM YYYY')}`
        }
      }
    }

    return filterTitle && filterTitle.replace(/,/g, ', ')
  }

  render() {
    const {isDateFocus} = this.state
    const {
      data: {
        label,
        type,
        field,
        columns = [],
        readyOnly,
        isDefault,
        filterValue },
      onRemove,
      customClass
    } = this.props

    const dropdownClass = classNames('filters-bar__dropdown filters-bar__dropdown_sm', {
      'filters-bar__dropdown_visible': this.state.isOpen && !readyOnly,
      'filters-bar__datepicker': type === 'date',
      'filters-bar__tags': type === 'tags',
      'filters-bar__radio': type === 'radio' || type === 'checkbox',
      'filters-bar__rangepicker': type === 'singleRange' || type === 'multiRange',
      'filters-bar__active': isDateFocus
    })

    return (
      <OutsideClickHandler
        display={'inline-block'}
        onOutsideClick={this.onCloseDropdown}
      >
        <div className={`filters-bar__item ${customClass}`}>
          <button
            type='button'
            className='filters-bar__button'
            onClick={this.onOpenDropdown}
          >
            {label}{this.getTitle()}
            {!isDefault &&
            <div
              className='filters-bar__remove'
              onClick={(e) => onRemove(field, true, e)}
            >
              <XCircleIcon />
            </div>
            }
          </button>
          <div className={dropdownClass}>
            <FilterType
              customClass={customClass}
              value={filterValue}
              type={type}
              name={field}
              title={label}
              columns={columns}
              onFocusDate={this.onFocusDate}
              onFilterApply={this.onFilterApply}
            />
          </div>
        </div>
      </OutsideClickHandler>
    )
  }
}

FilterItem.propTypes = {
  data: PropTypes.object,
  onFilter: PropTypes.func,
  onRemove: PropTypes.func,
  isDefault: PropTypes.bool,
  customClass: PropTypes.string,
  setFilterValue: PropTypes.func,
  onSelectFilter: PropTypes.func,
  visibleFilter: PropTypes.string,
  selectedFilters: PropTypes.object
}

export default FilterItem
