import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'

import messages from 'utils/message'
import ProcessPage from "app/components/common/ProcessPage";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PrivacyPolicy = ({
  color,
  solution,
  onClose,
  onUpdate,
  data,
  onConfirm,
  getSolution,
  removeImage
}) => {

  const [value, setValue] = useState(solution.privacyPolicy);

  const onCallback = () => {
    getSolution();
    onClose()
  }

  const onSubmit = () => {
    let payload = {
      id: solution.id,
      privacyPolicy: value,
    }

    onConfirm(payload, onCallback)
  }

  const onChangeEditor = (payload) => {
    setValue(payload)
  }
  return (
    <ProcessPage onCancel={onClose}>
      {() => (
        <div className="popup__inner">
          <div className="headline">
            <h1 className="headline__title">Privacy Policy</h1>
            <h3 className="headline__subtitle">{solution.name} • {solution.id}</h3>
          </div>


          <form action="#" className="popup__form popup__form_fw">
            <div className="text-editor">
              <ReactQuill theme="snow" value={value} onChange={() => null} readOnly/>
            </div>
          </form>

          {/* <div className="foot-nav">
            <div onClick={onSubmit}
              className="round-button round-button_br round-button_primary round-button_forw cursor">
              <span className="round-button__label">
                <FormattedMessage {...messages.confirm}/>
              </span>
            </div>
          </div> */}
        </div>
      )}
    </ProcessPage>
  )
}

PrivacyPolicy.propTypes = {
  user: PropTypes.object
}

export default PrivacyPolicy;
