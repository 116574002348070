import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import classNames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'


const DetailScroll = ({ children, isStatusBarOpen, isProcessPage }) => {
  const mainClass = classNames('content', {
    expanded: !isStatusBarOpen,
    'no-background': isProcessPage,
  })

  return (
    <Scrollbars
      style={{ width: '100%', height: '100vh' }}
      renderView={(props) => {
        return <main {...props} className={mainClass} />
      }}
      universal
      autoHide
      renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
    >
      <div className='content-wrapper'>
        <div className='content-body'>
          {children}
        </div>
      </div>
    </Scrollbars>
  )
}


DetailScroll.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
    PropTypes.func
  ])
}

function mapStateToProps(state) {
  return {
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(DetailScroll)
