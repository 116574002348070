import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from 'config/config';
import { BASE_URL } from 'app/constants/common';

const DEFAULT_PLAN_VALUE= {
  id: true,
  name: true,
  description: true,
  prettyName: true,
  tags: true,
  status: true,
  product: true,
  category: true,
  phases: true,
  unit: true,
  quantity: true,
  billingPeriod: true,
  workspaceCount: true,
  updatedAt: true,
  createdAt: true,
  features: {
    applicationLimit: true,
    applicationVersionLimit: true,
    dashboardLimit: true,
    deploymentLimit: true,
    deviceLimit: true,
    moduleLimit: true,
    profileLimit: true,
    videoLimit: true,
    userLimit: true,
    influxStorageLimit: true,
    logStorageLimit: true,
    auditLogStatus: true,
    terminalAccessManagementStatus: true,
    videoViewerAccessManagementStatus: true,
    multiFactorAuthenticationStatus: true,
    appDataRetentionDays: true,
    deviceMetricsRetentionDays: true,
    userRoleManagementStatus: true,
    sessionDuration: true,
    forcedPasswordReset: true,
  }
}

const DEFAULT_PLAN_DETAIL_VALUE = {
  id: true,
  description: true,
  tags: true,
  name: true,
  status: true,
  product: true,
  category: true,
  phases: true,
  unit: true,
  quantity: true,
  billingPeriod: true,
  workspaceCount: true,
  billableEndpoints: true,
  actualEndpoints: true,
  createdAt: true,
  updatedAt: true,
  prettyName: true,
  features: {
    applicationLimit: true,
    applicationVersionLimit: true,
    dashboardLimit: true,
    deploymentLimit: true,
    deviceLimit: true,
    moduleLimit: true,
    profileLimit: true,
    videoLimit: true,
    userLimit: true,
    influxStorageLimit: true,
    logStorageLimit: true,
    auditLogStatus: true,
    terminalAccessManagementStatus: true,
    videoViewerAccessManagementStatus: true,
    multiFactorAuthenticationStatus: true,
    appDataRetentionDays: true,
    deviceMetricsRetentionDays: true,
    userRoleManagementStatus: true,
    sessionDuration: true,
    forcedPasswordReset: true,
  }
}

const DEFAULT_PLAN_FEATURES = {
  applicationLimit: true,
  applicationVersionLimit: true,
  dashboardLimit: true,
  deploymentLimit: true,
  deviceLimit: true,
  moduleLimit: true,
  profileLimit: true,
  videoLimit: true,
  userLimit: true,
  influxStorageLimit: true,
  logStorageLimit: true,
  auditLogStatus: true,
  multiFactorAuthenticationStatus: true
}

const DEFAULT_SUBSCRIPTION_WORKSPACE = {
  workspaceId: true,
  workspaceName: true,
  subscription: true
}

export function subscriptionWorkspaces(payload) {
  const query = {
    query: {
      subscriptionWorkspaces: {
        __args: payload,
        ...DEFAULT_SUBSCRIPTION_WORKSPACE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getPlans() {
  const query = {
    query: {
      plans: {
        ...DEFAULT_PLAN_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getPlan(payload) {
  const query = {
    query: {
      plan: {
        __args: payload,
        ...DEFAULT_PLAN_DETAIL_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function createPlan(param) {
  const query = {
    mutation: {
      createPlan: {
        __args: param,
        ...DEFAULT_PLAN_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updatePlanFeatures(param) {
  const query = {
    mutation: {
      updatePlanFeatures: {
        __args: param,
        ...DEFAULT_PLAN_DETAIL_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function createPrice(param) {
  const query = {
    mutation: {
      createPrice: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updatePlan(param) {
  const query = {
    mutation: {
      updatePlan: {
        __args: param,
        ...DEFAULT_PLAN_DETAIL_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function activatePlan(param) {
  const query = {
    mutation: {
      activatePlan: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function setAsDevPlan(param) {
  const query = {
    mutation: {
      setAsDevPlan: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deactivatePlan(param) {
  const query = {
    mutation: {
      deactivatePlan: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deletePlan(param) {
  const query = {
    mutation: {
      deletePlan: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function setDefaultPrice(param) {
  const query = {
    mutation: {
      setDefaultPrice: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deletePrice(param) {
  const query = {
    mutation: {
      deletePrice: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}