import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from 'classnames'
import NumberFormat from 'react-number-format';

import SortableRow from "app/components/common/Table/SortableRow";
import { dateFormatter, getMRR, truncate } from "utils/common";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { subscription } = this.props
    
    const className = classNames('data-table__status type__value text-captilize subscription_status',
      {
        'data-table__status_pos': subscription && subscription.status === 'active',
        'data-table__status_neg': subscription && subscription.status !== 'active'
      })

    return (
      <div className={className}>{subscription && subscription.status || 'N/A'}</div>
    )
  }

  getRowRender = () => {
    const {
      workspaceName,
      colorClassName,
      subscription,
    } = this.props;

    return {
      customer: () => <div className="data-table__td_dark">{workspaceName}</div>,
      status: () => <span className="type__value">{this.getStatus()}</span>,
      quantity: () => (
        <span className={`text-link ${colorClassName}`}>
          {subscription && subscription.quantity}
        </span>
      ),
      startDate: () => subscription ? dateFormatter(subscription.current_period_start * 1000, true) : '',
      endDate: () => subscription ? dateFormatter(subscription.current_period_end * 1000, true) : '',
      mrr: () => <NumberFormat
      value={0}
      displayType={'text'}
      thousandSeparator renderText={value =>
          <span>USD{' '}{value}</span>
      }/>
      ,
      subscribedAt: () => 'null'
    };
  };

  render() {
    const { colorClassName } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          hideSingleMenu
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
