import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'
import {WorkspaceIcon} from 'images/common'

const columnList = [{
  label: <FormattedMessage {...appMessages.customer} />,
  isSortable: true,
  isLocked: true,
  sortKey: 'customer'
}, {
  label: <FormattedMessage {...appMessages.subscriptionStatus} />,
  sortKey: 'status'
},
{
  label: <FormattedMessage {...appMessages.billableEndpoints} />,
  sortable: false,
  isSortable: true,
  sortKey: 'quantity'
},

{
  label: 'Start Date',
  sortable: false,
  isSortable: true,
  sortKey: 'startDate'
},
{
  label: 'End Date',
  sortable: false,
  isSortable: true,
  sortKey: 'endDate'
},
]

class ModuleTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    const result = data && data.length ? data.map(item => ({...item, id: item.workspaceId})) : [];

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        rows={result || []}
        notFoundIcon={<WorkspaceIcon/>}
        colorClassName={color}
        CheckboxImage={WorkspaceIcon}
        isSelectable
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
      />
    )
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ModuleTable
