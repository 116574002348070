import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List } from "react-feather";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import NotFound from "app/components/NotFound";
import { FormattedMessage } from "react-intl";
import Modal from "app/components/common/Modal";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import Pagination from "app/components/Pagination";
import SearchField from "app/components/common/SingleSearch";
import NoPlans from "../../common/NoItem";
import messages from "utils/message";

import Card from "./Card";
import ListTable from "./Table";

import { routeMapping } from "app/constants/routes";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";

import { CARD_VIEW, TABLE_VIEW, DEFAULT_PAGE_SIZE } from "app/constants/common";

import { getVisoUserAction } from "app/actions/users";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";
import { isSmallerDevice } from "utils/common";

import ListSelectedItems from "../../common/ListSelectedItems";
import {
  deactivateDevPlan,
  activateDevPlan,
  deactivateUser,
  activateUser,
} from "app/api/users";
import { defaultNotifier } from "functions/notificationHandler";
import InviteUser from "../InviteUsers";
import PreLoaderList from "app/components/common/PreLoader/PreLoaderList";

class ListingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      selectedIds: [],
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "email",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.users
        ,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: DEFAULT_PAGE_SIZE,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    this.setCurrentPage(1);

    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.users);
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter(
        (item) =>
          (item.firstName &&
            item.firstName
              .toLowerCase()
              .includes(this.state.searchName.toLowerCase())) ||
          (item.lastName &&
            item.lastName
              .toLowerCase()
              .includes(this.state.searchName.toLowerCase())) ||
          item.email
            .toLowerCase()
            .includes(this.state.searchName.toLowerCase()) ||
          `${item.firstName && item.firstName.toLowerCase()} ${
            item.lastName && item.lastName.toLowerCase()
          }`.includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        currentPage: 1,
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.setState({ filterResult: this.props.users })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.getVisoUsers();

    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  getVisoUsers = () => {
    this.props.getVisoUserAction();
  };

  onAcceptDevApi = async (id) => {
    const { data } = await activateDevPlan(id, this.getVisoUsers);

    if (data.errors) {
      defaultNotifier(data.errors);
    }

    if (data.data) {
      defaultNotifier("Developer plan activated");

      this.getVisoUsers();
    }
  };

  onActivateDev = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `Activate developer plan`;
    const title = `Confirm activation`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onAcceptDevApi(id),
    });
  };

  onDeactivateApi = async (id) => {
    const { data } = await deactivateUser(id, this.getVisoUsers);

    if (data.errors) {
      defaultNotifier(data.errors);
    }

    if (data.data) {
      defaultNotifier("User deactivated successfully");

      this.getVisoUsers();
    }
  };

  onDeactivate = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The user will be deactivated.`;
    const title = `Confirm deactivate user`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onDeactivateApi(id),
    });
  };

  onActivateApi = async (id) => {
    const { data } = await activateUser(id, this.getVisoUsers);

    if (data.errors) {
      defaultNotifier(data.errors);
    }

    if (data.data) {
      defaultNotifier("User activated successfully");

      this.getVisoUsers();
    }
  };

  onActivate = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `The user will be activated.`;
    const title = `Confirm activate user`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onActivateApi(id),
    });
  };

  onDeactivateDev = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `Deactivate the developer plan`;
    const title = `Confirm deactivation`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onDeactivateDevApi(id),
    });
  };

  onDeactivateDevApi = async (id) => {
    const { data } = await deactivateDevPlan(id, this.getVisoUsers);

    if (data.errors) {
      defaultNotifier(data.errors);
    }

    if (data.data) {
      defaultNotifier("Developer plan deactivated");
      this.getVisoUsers();
    }
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  loadMore = () => {
    if (this.state.isAllSelected) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE });
  };

  isMore = () => {
    const { users } = this.props;

    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    const data = hasFilter ? this.state.filterResult : users;

    if (this.state.activeView === CARD_VIEW) {
      return false;
    }

    if (!data) {
      return false;
    }

    return data.length > this.state.offset;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`/partner/${id}`);
  };

  redirectToCreatePage = () => {
    return this.props.history.push(routeMapping.CREATE_CATEGORY.path);
  };

  onRedirectToWorkspace = () => {
    this.props.history.push("/workspaces?isArchived=true");
  };

  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  onNext = (currentPage) => {};

  renderModal = () => {
    const { body, onConfirm, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={title}
        body={body}
        onClose={this.onCloseModal}
      />
    );
  };

  redirectToInvitePage = () => {
    this.setState({ showInvitePage: true });
  };

  render() {
    const { color } = this.props;
    const { currentPage, limit } = this.state;

    const query = new URLSearchParams(this.props.location.search);
    const email = query.get("email");
    const name = query.get("name");

    const { showModal, activeView, selectedIds, resetFilter } = this.state;

    const viewHeadlineClass = classNames(
      `actions-nav__icon actions-nav__icon_rows ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === TABLE_VIEW }
    );

    const viewListClass = classNames(
      `actions-nav__icon actions-nav__icon_cards ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === CARD_VIEW }
    );

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    if(this.props.isLoading) {
      return <PreLoaderList/>
    }

    return (
      <>
        {showModal && this.renderModal()}
        {this.state.showInvitePage && (
          <InviteUser
            onCancel={() => this.setState({ showInvitePage: false })}
            getVisoUsers={this.getVisoUsers}
            onClose={() => {
              this.setState({ showInvitePage: false });
              this.getVisoUsers();
            }}
          />
        )}
        <ListScroll>
          {(scrollParent) => (
            <>
              <div className="headline">
                <h1 className="headline__title">Viso Users</h1>
              </div>
              <Breadcrumb breadcrumbClass="breadcrumbs_static">
                <BreadcrumbItem />
                <BreadcrumbItem route="List of all personal Viso Accounts on the Viso Platform." />
              </Breadcrumb>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
                <span
                  className={`actions-nav__link text-link ${color} cursor`}
                  onClick={this.redirectToInvitePage}
                >
                  Invite for Workspace Setup
                </span>
              </div>
              {!hasFilter &&
              !this.props.users.length &&
              !this.props.isLoading ? (
                <NotFound
                  color={color}
                  strokeWidth="1"
                  logo={<List size={42} />}
                  title="No users available"
                />
              ) : (
                <>
                  <div className="filters-bar">
                    <ListSelectedItems
                      selectedNumber={selectedIds.length}
                      search={this.state.searchName}
                      onReset={this.onReset}
                      filterData={this.state.filter}
                      containerClassName={color}
                    />
                      <Filter
                        customClass={color}
                        resetFilter={resetFilter}
                        onChange={this.onFilter}
                        columns={filterColumns}
                        data={this.props.users}
                        defaultFilter={
                          name
                            ? [
                                {
                                  field: "status",
                                  isDefault: true,
                                  value: "All",
                                  columns: ["active", "inactive", "invited"],
                                },
                                {
                                  field: "username",
                                  value: name,
                                  type: "serach",
                                },
                              ]
                            : [
                                {
                                  field: "status",
                                  isDefault: true,
                                  value: "All",
                                  columns: ["active", "inactive", "invited"],
                                },
                              ]
                        }
                      />
                    <SearchField
                      onSearch={this.onSearch}
                      customClass={color}
                      resetSearch={this.state.resetFilter}
                      label="Search"
                    />
                  </div>
                  {!this.props.isLoading && (
                  <div>
                    <InfiniteScroll
                      initialLoad={false}
                      useWindow={false}
                      getScrollParent={() => scrollParent}
                    >
                    {activeView === TABLE_VIEW && (
                      <>
                        <ListTable
                          data={
                            hasFilter
                              ? this.state.filterResult
                              .filter(
                                  (item, index) => {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }

                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }

                                    return false;
                                  }
                                )

                              : this.props.users
                              .filter((item, index) => {
                                  if (
                                    currentPage === 1 &&
                                    index < this.state.offset
                                  ) {
                                    return item;
                                  }

                                  if (
                                    currentPage !== 1 &&
                                    index < this.state.offset * currentPage &&
                                    index >=
                                      this.state.offset * (currentPage - 1)
                                  ) {
                                    return item;
                                  }

                                  return false;
                                })
                          }
                          onRowClick={() => null}
                          selectAll={this.selectAll}
                          onSort={this.onSort}
                          color={color}
                          isLoading={this.props.isLoading}
                          sort={this.state.sort}
                          isSelectedAll={this.getIsSelectedAll()}
                          selectedIds={selectedIds}
                          onSelect={this.onSelect}
                          onDeactivateDev={this.onDeactivateDev}
                          onActivateDev={this.onActivateDev}
                          onActivate={this.onActivate}
                          onDeactivate={this.onDeactivate}
                        />
                      </>
                    )}
                    {activeView === CARD_VIEW && (
                      <div className="data-cards">
                        <Card
                          color={color}
                          data={
                            hasFilter
                              ? this.state.filterResult
                              .filter(
                                  (item, index) => {
                                    if (
                                      currentPage === 1 &&
                                      index < this.state.offset
                                    ) {
                                      return item;
                                    }

                                    if (
                                      currentPage !== 1 &&
                                      index < this.state.offset * currentPage &&
                                      index >=
                                        this.state.offset * (currentPage - 1)
                                    ) {
                                      return item;
                                    }

                                    return false;
                                  }
                                )
                              : this.props.users
                              .filter((item, index) => {
                                  if (
                                    currentPage === 1 &&
                                    index < this.state.offset
                                  ) {
                                    return item;
                                  }

                                  if (
                                    currentPage !== 1 &&
                                    index < this.state.offset * currentPage &&
                                    index >=
                                      this.state.offset * (currentPage - 1)
                                  ) {
                                    return item;
                                  }

                                  return false;
                                })
                          }
                          onDuplicate={this.onDuplicate}
                          onRowClick={() => null}
                          selectedItems={selectedIds}
                          onDeactivateDev={this.onDeactivateDev}
                          onActivateDev={this.onActivateDev}
                          onActivate={this.onActivate}
                          onDeactivate={this.onDeactivate}
                          onSelect={this.onSelect}
                        />
                      </div>
                    )}
                      {((hasFilter && !!this.state.filterResult.length) ||
                        (!hasFilter && !!this.props.users.length)) &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          setCurrentPage={this.setCurrentPage}
                          onChangeLimit={this.onChangeLimit}
                          limit={this.state.offset}
                          totalPage={hasFilter ? this.state.filterResult.length : this.props.users.length}
                        />
                        }
                    </InfiniteScroll>
                    {activeView === CARD_VIEW &&
                      this.props.users &&
                      !this.props.users.length && <NoPlans />}
                  </div>
                  )}
                </>
              )}
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

ListingList.propTypes = {
  color: PropTypes.string,
  users: PropTypes.array,
  activeView: PropTypes.string,
};

ListingList.defaultProps = {
  color: "accent0",
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    users: state.users.visoUsers,
    isLoading: state.users.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  getVisoUserAction,
});

export default compose(withRouter, withConnect)(ListingList);
