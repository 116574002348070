import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import SmallCard from "../../common/SmallCard";
import SingleMenu from "./SingleMenu";
import { getInterval } from "utils/common";

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getSubheading = (item) => {
    return <>{item.type}</>;
  };

  render() {
    const { color, data, onSelect, selectedItems, plan } = this.props;

    return (
      <div className="grid-row">
        {data &&
          data.map((item, index) => (
            <>
              <SmallCard
                key={item.id}
                heading={`Phase ${index+1}`}
                id={item.id}
                // monthlyPriceId={plan && plan.monthlyPriceId}
                // yearlyPriceId={plan && plan.yearlyPriceId}
                onCardClick={this.props.onRowClick}
                colorClassName={color}
                subheading={this.getSubheading(item)}
                selectedItems={selectedItems}
                onSelect={onSelect}
                CheckboxImage={index+1}
              >
                {/* <SingleMenu
                  id={item.id}
                  interval={item.recurring && item.recurring.interval}
                  deletePrice={this.props.deletePrice}
                  setDefaultPrice={this.props.setDefaultPrice}
                /> */}
              </SmallCard>
            </>
          ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
