import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from 'config/config';
import { BASE_URL } from 'app/constants/common';

const DEFAULT_MARKETPLACE_VALUE= {
  id: true,
  name: true,
  description: true,
  parents: {
    id: true,
    name: true,
    description: true,
    slug: true
  },
  slug: true,
  status: true,
  updatedAt: true,
  createdAt: true,
  createdBy: {
    id: true,
    firstName: true,
    lastName: true
  }
}

export function getMarketplaceCategories() {
  const query = {
    query: {
      marketplaceCategories: {
        ...DEFAULT_MARKETPLACE_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getCategory(payload) {
  const query = {
    query: {
      marketplaceCategory: {
        __args: payload,
        ...DEFAULT_MARKETPLACE_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function createMarketplaceCategory(param) {
  const query = {
    mutation: {
      createMarketplaceCategory: {
        __args: param,
        ...DEFAULT_MARKETPLACE_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function activateMarketplaceCategory(param) {
  const query = {
    mutation: {
      activateMarketplaceCategory: {
        __args: param,
        ...DEFAULT_MARKETPLACE_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deactivateMarketplaceCategory(param) {
  const query = {
    mutation: {
      deactivateMarketplaceCategory: {
        __args: param,
        ...DEFAULT_MARKETPLACE_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deleteMarketplaceCategory(param) {
  const query = {
    mutation: {
      deleteMarketplaceCategory: {
        __args: param,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateMarketplaceCategory(param) {
  const query = {
    mutation: {
      updateMarketplaceCategory: {
        __args: param,
        ...DEFAULT_MARKETPLACE_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}