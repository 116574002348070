import React, { Component } from "react";
import PropTypes from "prop-types";

import Card from "app/components/common/Detail/Card";
import WorkspaceBilling from "app/components/workspaces/billing";
import {SOLUTION_ACCENT} from "app/constants/common";
class Billing extends Component {
  state = {
    openModal: false
  }

  openModal = () => {
    this.setState({openModal: true})
  }

  closeModal = () => {
    this.setState({openModal: false})
  }

  renderContent = () => {
    const {workspace: {billInformation={}}} = this.props

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            Business name
          </div>
          <div className="overview__value">{billInformation.name}</div>
          <div className="overview__label">
            Address 1
          </div>
          <div className="overview__value">{billInformation.address1}</div>
          <div className="overview__label">
            City
          </div>
          <div className="overview__value">{billInformation.city}</div>
          <div className="overview__label">
            VAT Number
          </div>
          <div className="overview__value">{billInformation.vatNumber}</div>

        </div>
        <div className="grid-col">
          <div className="overview__label">
            Country
          </div>
          <div className="overview__value">{billInformation.country}</div>
          <div className="overview__label">
            Address 2
          </div>
          <div className="overview__value">{billInformation.address2}</div>
          <div className="overview__label">
            Postcode
          </div>
          <div className="overview__value">{billInformation.postCode}</div>
        </div>
      </>
    );
  };

  render() {
    const {workspace} = this.props

    if(this.state.openModal) {
      return <WorkspaceBilling onClose={this.closeModal} workspace={workspace} color={this.props.color}/>
    }

    return (
      <Card
        color={this.props.color}
        title='Billing Information'
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Billing.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

Billing.defaultProps = {
  color: SOLUTION_ACCENT,
};

export default Billing;
