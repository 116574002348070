import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl'

import { DashboardIcon } from 'images/common';
import Card from 'app/components/common/Detail/Card';

class Dashboard extends Component {
  state = {
    showEditModal: false
  };

  openEditModal = () => {
    this.setState({ showEditModal: true });
  };

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  };

  renderContent = () => {
    const { color } = this.props;
    const { solution = {} } = this.props;

    if (!solution.dashboardUIds.length) {
      return null;
    }

    return (
      <>
        <div className="flow-modules__data-table data-table">
          <table className="data-table__table">
            <tbody className="data-table__body">
              {this.props.dashboards &&
              this.props.dashboards
                .filter(item => solution.dashboardUIds && solution.dashboardUIds.includes(item.uid))
                .map(item =>
                  <tr className="data-table__row" key={item.uid}>
                    <td className="data-table__td data-table__td_1">
                      <div className={`checkbox checkbox_${color}`}>
                        <div className="checkbox__image">
                          <DashboardIcon />
                        </div>
                      </div>
                    </td>
                    <td className={`data-table__td ${color}`}>
                      {item.title}
                    </td>
                    <td className="data-table__td">
                  -
                    </td>
                    <td className="data-table__td"/>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
          </>
    );
  };

  render() {
    const { showEditModal } = this.state;
    const { color, solution } = this.props;

    return (
      <Card
        noGrid
        color={this.props.color}
        title='Dashboard'
        subTitle='Attached dashboards that will be imported to the workspace.'
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.object
};

export default Dashboard;
