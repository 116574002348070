import {auth} from '../constants/login';

const INITIAL_STATE = {
    isLoggedIn: false,
    userData: {},
    error: false,
    isLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case auth.USER_LOGIN_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case auth.USER_LOGIN_FULFILLED:
      return {
        ...state,
        isLoggedIn: true,
        error: false,
        userData: action.payload,
        isLoading: false
      };
    case auth.USER_LOGIN_REJECTED:
      return {
        ...state,
        error: action.error.message,
        isLoading: false
      };
      case auth.USER_LOGOUT:
      return {
        isLoggedIn: false,
        userData: {},
        isLoading: false
      };
    default:
      return state;
  }
}