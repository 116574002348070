import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class CustomInput extends React.PureComponent {
  state = {
    showError: false
  }

  onBlur = () => {
    this.setState({showError: true})
  }

  onChange = (e) => {
    const {aplhaNumericOnly} = this.props

    const text = e.currentTarget.value
    const regex = /^[a-z0-9-_]+$/i
    const isValid = text.match(regex)

    if (!text) {
      this.props.onChange(e)
    }

    if (isValid) {
      return this.props.onChange(e)
    }

    if (!aplhaNumericOnly) {
      return this.props.onChange(e)
    }
  }

  render() {
    const {showError} = this.state

    return (
      <input
      type="number"
      value={this.props.value}
      className={classNames('text-field__input2', {
        'text-field__input2_value': this.props.value
      })}
      onChange={this.onChange}
    />
    )
  }
}

CustomInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
}
