import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';

import {updateModel} from 'app/api/model';
import { getDuration, convertDefaultStatusToStatus } from 'utils/common';
import ProcessPage from 'app/components/common/ProcessPage';
import CustomInput from 'app/components/common/Input/CustomInput';
import CustomTextarea from 'app/components/common/Input/CustomTextarea';
import Tags from 'app/components/common/Tags';
import appMessages from 'utils/message';
import EditForm from 'app/components/common/Edit/EditForm';

import { defaultNotifier } from 'functions/notificationHandler';


class EditInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.model.name || '',
      id: props.model.id || '',
      tags: props.model.tags || [],
      description: props.model.description || ''
    };
  }

  onAddTags = tags => {
    this.setState({ tags });
  };

  onChangeField = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmitModel = async () => {
    const {model} = this.props

    const { name, description, tags } = this.state;

    const payload = {
      id: model.id,
      name,
      description,
      tags
    };

    const {data} = await updateModel(payload);

    if (data.errors) {
      return defaultNotifier(data.errors)
    }

    this.props.getModel()
    this.props.onClose();
  };

  renderContent = () => {
    const { color } = this.props;
    const { name, description } = this.state;

    return (
      <>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={name}
            onChange={e => this.onChangeField('name', e.target.value)}
            label={<FormattedMessage {...appMessages.name} />}
            isInvalid={!name}
            postIconClass="success"
            hintText={name ? '' : <FormattedMessage {...appMessages.nameIsRequired}/>}
          />
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomTextarea
            value={description}
            onChange={e => this.onChangeField('description', e.target.value)}
            label={<FormattedMessage {...appMessages.description} />}
            type="textarea"
            isInvalid={!description}
            hintText={description ? '' : <FormattedMessage {...appMessages.descriptionIsRequired}/>}
          />
        </div>
        <Tags
          tags={this.state.tags}
          tagClass={color}
          onChange={this.onAddTags}
        />
      </>
    );
  };

  render() {
    const { model, color } = this.props;

    const { name, description, tags } = this.state;
    const hasChange =
      model.name !== name ||
      model.description !== description ||
      !isEqual((model && model.tags) || [], tags);

    const submitClass = classNames(`button button_${color} button-save`, {
      button_disabled: !hasChange
    });
    return (
      <ProcessPage onCancel={this.props.onClose} isChanged={hasChange} showUnsavedWarning>
        {() => (
          <EditForm
            color={this.props.color}
            onCancel={this.props.onClose}
            submitLabel={<FormattedMessage {...appMessages.save} />}
            submitClassName={submitClass}
            onSubmit={this.onSubmitModel}
            content={this.renderContent()}
            disabled={!name}
            title={'Model Information'}
            name={model.name}
            subTitle={
              <>
                {model.name} • <span className="text-capitalize">{model.modelType && convertDefaultStatusToStatus(model.modelType)}</span> •
                {' '}<FormattedMessage {...appMessages.modified}/> {getDuration(model.updatedAt)}
              </>
            }
          />
        )}
      </ProcessPage>
    );
  }
}

EditInformation.propTypes = {
  color: PropTypes.string,
  model: PropTypes.object,
  onClose: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(EditInformation);
