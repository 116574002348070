import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import EditInformation from "../Edit/Information";
import CardTags from "app/components/common/Detail/CardTags";

class Information extends Component {
  state = {
    openEditInformationModal: false,
  };

  onEdit = () => {
    this.setState({ openEditInformationModal: true });
  };

  onCloseModal = () => {
    this.setState({ openEditInformationModal: false });
  };

  renderContent = () => {
    const { partner } = this.props;
    const {businessName, address1, address2, city, country, postCode, vat} = partner;
    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Business Name</div>
          <div className="overview__value">{businessName}</div>
          <div className="overview__label">Address 1</div>
          <div className="overview__value">
            {address1}
          </div>
          <div className="overview__label">City</div>
          <div className="overview__value">{city}</div>
          <div className="overview__label">VAT Number</div>
          <div className="overview__value">{vat}</div>
        </div>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Country</div>
          <div className="overview__value">{country}</div>
          <div className="overview__label">Address 2</div>
          <div className="overview__value">{address2}</div>
          <div className="overview__label">Postcode</div>
          <div className="overview__value">{postCode}</div>
        </div>
      </>
    );
  };

  render() {
    const { openEditInformationModal } = this.state;

    if (openEditInformationModal) {
      return (
        <EditInformation
          partner={this.props.partner}
          color={this.props.color}
          onClose={this.onCloseModal}
        />
      );
    }

    return (
      <Card
        color={this.props.color}
        title='Vendor Information'
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Information;
