import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import NotFoundPage from 'app/components/NotFound'
import { routes, routeMapping } from "routes";

class CentralPanel extends React.Component {
  render() {
    return (
      <>
        <Switch>
          {routes.map((route) =>
            route.component ? (
              <Route
                key={route.name}
                path={route.path}
                exact={route.exact}
                sidebarStatus={this.props.sidebarStatus}
                name={route.name}
                render={(props) => <route.component {...props} />}
              />
            ) : null
          )}
          <Redirect from="/" to={routeMapping.WORKSPACES.path}/>
          <Route component={NotFoundPage}/>
        </Switch>
      </>
    );
  }
}

CentralPanel.propTypes = {};

export default CentralPanel;
