const filterColumns = [
  {
    id: 1,
    label: 'Status',
    field: 'status',
    type: 'radio',
  },
  {
    id: 2,
    label: 'Name',
    field: 'name',
    type: 'search',
  },
  {
    id: 3,
    label: 'Type',
    field: 'type',
    type: 'radio',
  },
  {
    id: 7,
    label: 'Created',
    field: 'createdAt',
    type: 'date',
  }]
  
  export default filterColumns