import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CloseIcon from 'images/common/x-circle'

class ListSelectedItems extends Component {
  render() {
    const {
      containerClassName,
      selectedNumber,
      onReset,
      filterData,
      search
    } = this.props

    return (
      <>
      {!!selectedNumber &&
        <div className={classNames('tags', {[`tags_${containerClassName}`]: containerClassName})}>
          <div className="tags__item">{ selectedNumber} selected
            <button type="button" className="tags__remove" onClick={onReset}><CloseIcon /></button>
          </div>
        </div>
      }
        { (!!filterData.single.length || !!filterData.range.length || search || !!selectedNumber) &&
          <button
            type="button"
            className="filters-bar__reset"
            onClick={onReset}
          >Reset</button>
        }
      </>
    )
  }
}

ListSelectedItems.propTypes = {
  filterData: PropTypes.any,
  searchValue: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
  selectedNumber: PropTypes.number,
  containerClassName: PropTypes.string
}

export default ListSelectedItems
