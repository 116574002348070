import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Check } from 'react-feather'

const AddRow = ({ label, isVisible, triggerVisibility }) => (
  <div
    className={classNames(
      'manage-columns__option manage-columns__option_check',
      { 'manage-columns__option_checked': isVisible }
    )}
    onClick={triggerVisibility}
  >
    {label} { isVisible && <Check className="manage-columns__check" /> }
  </div>
)

AddRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isVisible: PropTypes.bool,
  triggerVisibility: PropTypes.func.isRequired
}

class AddColumns extends Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: []
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showMenu !== nextProps.showMenu) {
      this.setState({ columns: this.getColumns(nextProps.columns) })
    }
  }

  getColumns = (cols) => {
    return cols.filter(col => !col.isVisible)
  }

  triggerVisibility = (sortKey) => {
    const columns = this.state.columns
      .map(col => {
        if (col.sortKey === sortKey) {
          return { ...col, isVisible: !col.isVisible }
        }

        return col
      })

    this.setState({ columns })
  }

  render() {
    return (
      <div className={classNames('manage-columns__slide manage-columns__slide_add', this.props.containerClassName)}>
        <div className="manage-columns__heading">Add new Columns</div>
        { this.state.columns
          .map(col => (
            <AddRow
              key={col.sortKey}
              { ...col }
              triggerVisibility={() => this.triggerVisibility(col.sortKey)}
            />
          ))
        }
        <div className="manage-columns__foot">
          <button
            onClick={() => this.props.onConfirm(this.state.columns)}
            className="manage-columns__apply"
          >Apply</button>
        </div>

      </div>
    )
  }
}

AddColumns.propTypes = {
  columns: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  containerClassName: PropTypes.string
}

export default AddColumns
