import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from 'moment';

import ProcessPage from "app/components/common/ProcessPage";
import EditForm from "app/components/common/Edit/EditForm";
import { SOLUTION_ACCENT } from "app/constants/common";
import Switch from "app/components/common/Input/Switch";
import { handleStagingEnvironment } from 'app/api/workspaces'
import { defaultNotifier } from "functions/notificationHandler";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  onSubmit = async () => {
    const {
      active
    } = this.state;

    const { data } = await handleStagingEnvironment({status: !!active});

    if (data.errors) {
      return defaultNotifier('Failed to update the settings.')
    }

    defaultNotifier('Settings updated.')
  }

  async componentDidMount() {
  }

  onChangeSwitch = (name, value) => {
    this.setState({ [name]: value });
  };

  renderAnnouncement = () => {

    return (
      <>
        <h5 className="label-color mx-b-5"></h5>
        <div className="setting-switch">
          <h5 className="label-color mx-b-10">Setting</h5>
          <div className="switch-wrapper">
            <Switch
              color={"accent4"}
              onChange={this.onChangeSwitch}
              checked={this.state.active}
              name="active"
              text="Enable staging server"
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <ProcessPage
      onCancel={this.props.onClose}
    >
      {() => (
        <EditForm
          color={this.props.color}
          submitLabel="Save"
          title="Staging Settings"
          subTitle={<div>Update staging settings</div>}
          onSubmit={this.onSubmit}
          content={this.renderAnnouncement()}
        />
      )}
    </ProcessPage>
    );
  }
}

Settings.propTypes = {
  color: PropTypes.string,
};

Settings.defaultProps = {
  color: SOLUTION_ACCENT,
};

const withConnect = connect(null, {
});

export default compose(withConnect)(Settings);
