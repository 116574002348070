import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames'

export const SingleMenu = ({ onDelete, id, onToogleMenu, status, closeMenu, hideParentClass, onActivate, onDeactivate }) => {
  const deleteClass = classNames("actions__option", {
    actions__option_disabled: status === "active",
    cursor: status === "inactive",
  });

  const activateClass = classNames("actions__option", {
    actions__option_disabled: status === 'active',
    cursor: status === "inactive",
  });

  const deactivateClass = classNames("actions__option", {
    actions__option_disabled: status === 'inactive',
    cursor: status === "active",
  });

  const onDeleteMenu = (id, e) => {
    e.stopPropagation()
    onToogleMenu(e, id)

    if(status === 'active') {
      return false
    }

    closeMenu()
    onDelete(id, e)
  }

  const onActivateMenu = (id, e) => {
    e.stopPropagation()
    onToogleMenu(e, id)

    if( status === 'active') {
      return false
    }
  
    closeMenu()
    onActivate(id, e)
  }

  
  const onDeactivateMenu = (id, e) => {
    e.stopPropagation()
    onToogleMenu(e, id)

    if(status === 'inactive') {
      return false
    }
  
    closeMenu()
    onDeactivate(id, e)
  }

  const parentClass = classNames({
    'actions__dropdown actions__dropdown_visible': !hideParentClass
  });

  return (
    <>
      <div className={parentClass}>
      <span
        onClick={(e) => onActivateMenu(id, e)}
        className={activateClass}
      >
        Activate Category
      </span>
      <span
        onClick={(e) => onDeactivateMenu(id, e)}
        className={deactivateClass}
      >
        Deactivate Category
      </span>
      <span onClick={(e) => onDeleteMenu(id, e)} className={deleteClass}>
        Remove Category
      </span>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
