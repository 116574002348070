import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";
import { List } from "react-feather";

import SmallCard from "../../common/SmallCard";

import SingleMenu from "./SingleMenu";

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getStatus = (status) => {
    const { currentSortKey } = this.props;

    const statusClass = classNames(
      "data-cards__status c-text text-capitalize",
      {
        success: status === "approved",
        warning: status === "pending",
        failure: status === "rejected",
        "data-table__td_dark": currentSortKey === "status",
      }
    );

    return (
      <>
        <i className={statusClass}>•</i> {status}
      </>
    );
  };

  getSubheading = (status) => {
    return (
      <>
        {this.getStatus(status)}
      </>
    );
  };

  render() {
    const {
      color,
      data,
      onSelect,
      selectedItems,
    } = this.props;

    const result =
      data && data.map((item) => ({ ...item, id: item.productId }));

    return (
      <div className="grid-row">
        {result && result.map((item) => (
          <SmallCard
            key={item.id}
            heading={item.workspace.name}
            id={item.id}
            onCardClick={this.props.onRowClick}
            colorClassName={color}
            subheading={this.getSubheading(item.status, item.productId)}
            selectedItems={selectedItems}
            onSelect={onSelect}
            CheckboxImage={<List/>}
          >
            <SingleMenu
              onApprove={this.props.onApprove}
              onReject={this.props.onReject}
              onActivate={this.props.onActivate}
              onDeactivate={this.props.onDeactivate}
              id={item.id}
              status={item.status}
            />
          </SmallCard>
        ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
