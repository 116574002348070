import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CustomInput from 'app/components/common/Input/CustomInput'

class Input extends Component {
  getHintText = () => {
    if (this.checkIsInvalid()) {
      return this.props.meta.error
    }
  }

  checkIsInvalid = () => {
    const { error, touched } = this.props.meta

    return error && touched
  }

  render() {
    const {  meta: { error }} = this.props

    return (
      <CustomInput
        {...this.props}
        error={error}
        defaultValue={this.props.defaultValue}
        onChange={this.props.input.onChange}
        value={this.props.input.value}
        isInvalid={error}
        hintText={error}
      />
    )
  }
}

Input.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  onFocus: PropTypes.func
}

export default Input
