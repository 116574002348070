import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { EnumType } from 'json-to-graphql-query'
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import {isSmallerDevice} from 'utils/common';

import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import { FilePlus } from "images/common";
import NotFound from "app/components/NotFound";

import SearchField from "app/components/common/SingleSearch";

import Card from "./Card";
import List from "./Table";
import { routeMapping } from "app/constants/routes";

import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  SOLUTION_ACCENT,
} from "app/constants/common";
import {getAdminUserAction} from "app/actions/users";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";

import ListSelectedItems from "../../common/ListSelectedItems";

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      selectedIds: [],
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "firstName",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
      limit: 10,
      totalPage: 30,
      currentPage: 1,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: 0,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.users,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: 0,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.users.map(item => ({...item, status: 'active'})));
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.firstName.toLowerCase().includes(this.state.searchName.toLowerCase()) || 
        item.lastName.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: 0,
      },
      () => this.setState({ filterResult: this.props.users })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    const params = {
      limit: DEFAULT_PAGE_SIZE,
      offset: this.state.offset,
      sort: {
        field: this.state.sort.key,
        type:
          this.state.sort.direction === 'asc'
            ? new EnumType('ASC')
            : new EnumType('DESC')
      }
    }

    this.props.getAdminUserAction(params);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({activeView: CARD_VIEW})
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
    isNextSmallerDeviceType &&
    nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({activeView: CARD_VIEW})
    }

    if (
      isSmallerDevice &&
    nextProps.deviceSizeType !== this.props.deviceSizeType &&
    nextProps.deviceSizeType === 'desktop'
    ) {
      return this.setState({activeView: TABLE_VIEW})
    }
  }

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  loadMore = () => {
    const { modules } = this.props;

    if (modules.loading) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE }, () =>
      this.getUsers()
    );
  };

  isMore = () => {
    return false;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    // return this.props.history.push(`${routeMapping.PLAN.path}/${id}`);
  };

  redirectToCreatePage = () => {
    return this.props.history.push(routeMapping.CREATE_PLAN.path);
  };

  render() {
    const { color } = this.props;

    const { showModal, activeView, selectedIds, resetFilter } = this.state;

    const viewHeadlineClass = classNames(
      `actions-nav__icon actions-nav__icon_rows ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === TABLE_VIEW }
    );

    const viewListClass = classNames(
      `actions-nav__icon actions-nav__icon_cards ${this.props.color} cursor`,
      { "actions-nav__icon_active": activeView === CARD_VIEW }
    );

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

      return (
      <>
        {showModal && this.renderModal()}
        <ListScroll>
          {(scrollParent) => (
            <>
              <div className="headline">
                <h1 className="headline__title">
                  Viso Staff
                </h1>
              </div>
              <Breadcrumb breadcrumbClass='breadcrumbs_static'>
                <BreadcrumbItem />
                <BreadcrumbItem route="List of users that can access the Admin Panel." />
              </Breadcrumb>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
              </div>
              {!hasFilter && !this.props.users.length && !this.props.isLoading ? (
                <NotFound
                  color={color}
                  strokeWidth="1"
                  logo={<FilePlus size={42} />}
                  title="Create your own users"
                  description="Add dynamic users for your workspaces"
                />
              ) : (
                <>
                  <div className="filters-bar">
                    <ListSelectedItems
                      selectedNumber={selectedIds.length}
                      search={this.state.searchName}
                      onReset={this.onReset}
                      filterData={this.state.filter}
                      containerClassName={color}
                    />
                    <Filter
                      customClass={color}
                      resetFilter={resetFilter}
                      onChange={this.onFilter}
                      columns={filterColumns}
                      data={this.props.users}
                      defaultFilter={[{
                        field: "status",
                        value: "All",
                        isDefault: true,
                        columns: ["active"],
                      }]}
                    />
                    <SearchField
                      onSearch={this.onSearch}
                      customClass={color}
                      resetSearch={this.state.resetFilter}
                      label="Search"
                    />
                  </div>
                  <div>
                    {/* <InfiniteScroll
                      loadMore={this.debouncedLoadMore}
                      hasMore={this.isMore()}
                      initialLoad={false}
                      useWindow={false}
                      getScrollParent={() => this.scrollParent}
                    > */}
                      {activeView === TABLE_VIEW && (
                        <>
                        <List
                          data={
                            hasFilter
                            ? this.state.filterResult
                            : this.props.users
                          }
                          onRowClick={this.onRowClick}
                          selectAll={this.selectAll}
                          onSort={this.onSort}
                          color={color}
                          isLoading={this.props.isLoading}
                          onDeactivatePlan={this.onDeactivatePlan}
                          onActivatePlan={this.onActivatePlan}
                          onDuplicate={this.onDuplicate}
                          sort={this.state.sort}
                          isSelectedAll={this.getIsSelectedAll()}
                          selectedIds={selectedIds}
                          onSelect={this.onSelect}
                          onUpdateStatus={this.onUpdateStatus}
                          onDelete={this.onDelete}
                        />
                        </>
                      )}
                      {activeView === CARD_VIEW && (
                        <div className="data-cards">
                          <Card
                            color={color}
                            data={
                              hasFilter
                                ? this.state.filterResult
                                : this.props.users
                            }
                            onDuplicate={this.onDuplicate}
                            onRowClick={this.onRowClick}
                            selectedItems={selectedIds}
                            onDeactivatePlan={this.onDeactivatePlan}
                            onActivatePlan={this.onActivatePlan}
                            onUpdateStatus={this.onUpdateStatus}
                            onDelete={this.onDelete}
                            onSelect={this.onSelect}
                          />
                        </div>
                      )}
                    {/* </InfiniteScroll> */}
                  </div>
                </>
              )}
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

UserList.propTypes = {
  color: PropTypes.string,
  users: PropTypes.array,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

UserList.defaultProps = {
  color: SOLUTION_ACCENT,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    users: state.users.list,
    isLoading: state.users.isLoading
  };
}

const withConnect = connect(mapStateToProps, {
  getAdminUserAction
});

export default compose(withRouter, withConnect)(UserList);
