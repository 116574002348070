import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler'

import Cog from 'images/common/cog'
import Dropdown from 'app/components/common/Table/ColumnSelector/Dropdown'

class ColumnSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDropdown: false
    }
  }

  toggleDropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown })
  }

  onOutsideClick = () => {
    this.setState({ showDropdown: false })
  }

  render() {
    return (
      <div className="manage-columns">
        <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
          <button
            onClick={this.toggleDropdown}
            className="manage-columns__toggle"
          >
            <Cog />
          </button>
          { this.state.showDropdown &&
            <Dropdown
              toggleDropdown={this.toggleDropdown}
              columns={this.props.columns}
              onChangeConfirm={this.props.onChange}
            />
          }
        </OutsideClickHandler>
      </div>
    )
  }
}

ColumnSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired
}

export default ColumnSelector
