import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {Codesandbox} from 'react-feather'
import SmallCard from "../../common/SmallCard";
import SingleMenu from "./SingleMenu";

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getSubheading = (status, agentVersion) => {
    return (
      <>
        {status} • {agentVersion}
      </>
    );
  };

  render() {
    const { color, data, onSelect, onDelete, selectedItems } = this.props;

    return (
      <div className="grid-row">
        {data.map((item) => (
          <SmallCard
            key={item.id}
            heading={item.deviceType}
            id={item.id}
            onCardClick={this.props.onRowClick}
            colorClassName={color}
            subheading={this.getSubheading(
              item.buildStatus,
              item.visoAgentVersion
            )}
            selectedItems={selectedItems}
            onSelect={onSelect}
            CheckboxImage={<Codesandbox/>}
          >
            <SingleMenu
              id={item.id}
              buildStatus={item.buildStatus}
              updateToLatestVersion={this.props.updateToLatestVersion}
              visoAgentVersion={item.visoAgentVersion}
            />
          </SmallCard>
        ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
