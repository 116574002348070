import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';

const middlewares = [thunkMiddleware];

const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];

if (window['__REDUX_DEVTOOLS_EXTENSION__']) {
  enhancers.push(window['__REDUX_DEVTOOLS_EXTENSION__']());
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form'],
  whitelist: ['user', 'workspaces'] 
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = compose(...enhancers);

const store = createStore(persistedReducer, undefined, composedEnhancers);

export const persistor = persistStore(store);

export default store;
