import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { orderBy } from "lodash";
import { FormattedMessage } from "react-intl";
import messages from "utils/message";

import SearchField from "app/components/common/SingleSearch";
import { getOutput } from "app/components/common/Filter/clientFilter";

import List from "./Table";
import { cancelSubscriptionPlan } from "app/api/workspaces";
import { routeMapping } from "app/constants/routes";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import Plans from "../plans";

import {
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ACCENT,
} from "app/constants/common";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import ProcessPage from "app/components/common/ProcessPage";
import ListSelectedItems from "../../common/ListSelectedItems";
import Modal from "app/components/common/Modal";
import { defaultNotifier } from "functions/notificationHandler";

import { connect } from "react-redux";

class Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      selectedIds: [],
      showDropdown: false,
      sort: {
        key: "planName",
        direction: "asc",
      },
      resetFilter: false,
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getPlans = () => {
    this.props.listPlans();
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: 0,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.subscription,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: 0,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);

    const payload = this.props.subscription.map((item) => ({
      ...item,
      id: item.subscriptionId,
      name: item.planName,
      status: item.state,
      quantity: item.quantity,
      provider: "viso",
      createdAt: new Date(item.created * 1000),
    }));

    const finalResult = getOutput(filterData, payload);
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.plan.product.name
          .toLowerCase()
          .includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: 0,
      },
      () => this.setState({ filterResult: this.props.subscription })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    if(typeof id === 'string') {
      return this.setState({ selectedIds: [id] });
    }
    return this.setState({ selectedIds: id });

  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  getWorkspaces = () => {
    return;
  };

  loadMore = () => {
    const { modules } = this.props;

    if (modules.loading) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE }, () =>
      this.getWorkspaces()
    );
  };

  isMore = () => {
    return false;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    // return this.props.history.push(`${routeMapping.PLAN.path}/${id}`);
  };

  addModule = () => {
    this.props.history.push(routeMapping.IMPORT_MODULE.path);
  };

  onClose = () => {
    return this.props.history.push(routeMapping.WORKSPACES.path);
  };

  onDelete = (id) => {
    this.setState({ showDeleteModal: true, selectedId: id });
  };

  onDeleteSubscription = async () => {
    this.props.onClose();
    const { data } = await cancelSubscriptionPlan({
      subscriptionId: this.state.selectedId,
    });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    this.props.getWorkspaces();
    defaultNotifier("Subscription removed successfully");
  };

  onCloseModal = () => {
    this.setState({ showDeleteModal: false });
  };

  renderModal = () => {
    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          this.onDeleteSubscription();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={"Cancel Subscription"}
        body={"You are cancelling the subscription."}
        onClose={this.onCloseModal}
      />
    );
  };

  onHidePlanPage = () => {
    this.setState({showPlanPage: false});
    this.props.getWorkspaces();
  }

  onShowPlanPage = () => {
    this.setState({showPlanPage: true})
  }

  onHideChangePlan = () => {
    this.setState({showChangePlan: false});
    this.props.getWorkspaces();
  }

  onShowChangePlan = (id) => {
    const {subscription} = this.props;

    const sub = subscription.find(plan => plan.subscriptionId === id);

    this.setState({showChangePlan: true, selectedSubscription: sub})
  }


  render() {
    const { color } = this.props;

    const { selectedIds, resetFilter } = this.state;

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

      if(this.state.showPlanPage) {
        return <Plans 
        category='ADD_ON'
        isActiveOnly
        title='Add new Subscription'
        subTitle='Select a plan to subscribe the workspace.'
        workspace={this.props.workspace}
         onCancel={this.onHidePlanPage}/>
      }

      if(this.state.showChangePlan) {
        const planName = this.props.plans.find(plan => plan.name === this.state.selectedSubscription.planName);

        return <Plans 
        type='changePlan'
        title='Change Subscription'
        isActiveOnly
        subTitle={`Replace the subscription from the plan ${planName && planName.prettyName}.`}
        category={this.state.selectedSubscription.productCategory}
        product={this.state.selectedSubscription.productName}
        id={this.state.selectedSubscription.subscriptionId}
        subscriptionName={this.state.selectedSubscription.planName}
        plan={this.state.selectedSubscription}
        workspace={this.props.workspace}
        onCancel={this.onHidePlanPage}/>
      }

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {(scrollRef) => (
          <>
            {this.state.showDeleteModal && this.renderModal()}
            <div className="headline">
              <h1 className="headline__title">Assigned Plans</h1>
              <h3 className="headline__subtitle">
                {this.props.workspace.name}
              </h3>
            </div>
            <div className="popup__actions-nav actions-nav">
                <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                <span
                  className={`actions-nav__link actions-nav__target cursor`}
                  onClick={() => this.onShowPlanPage()}
                >
                  Subscribe to new plan
                </span>
            </div>
            <div className="popup__filters-bar filters-bar">
              <ListSelectedItems
                selectedNumber={selectedIds.length}
                search={this.state.searchName}
                onReset={this.onReset}
                filterData={this.state.filter}
                containerClassName={color}
              />
              <Filter
                customClass={color}
                resetFilter={resetFilter}
                onChange={this.onFilter}
                columns={filterColumns}
                data={
                  hasFilter
                    ? this.state.filterResult.map((item) => ({
                        ...item,
                        id: item.subscriptionId,
                      }))
                    : this.props.subscription.map((item) => ({
                        ...item,
                        id: item.subscriptionId,
                      }))
                }
                defaultFilter={[
                  {
                    field: "status",
                    isDefault: true,
                    value: "All",
                    columns: ["ACTIVE", "INACTIVE", "DRAFT"],
                  },
                ]}
              />
              <SearchField
                onSearch={this.onSearch}
                customClass={color}
                resetSearch={this.state.resetFilter}
                label="Search"
              />
            </div>

            <InfiniteScroll
              className="data-table"
              loadMore={this.debouncedLoadMore}
              hasMore={false}
              initialLoad={false}
              loader={
                <div className="ml-3" key={0}>
                  Loading...
                </div>
              }
              useWindow={false}
              getScrollParent={() => this.scrollParent}
            >
              <List
                color={color}
                data={
                  hasFilter
                    ? this.state.filterResult.map((item) => ({
                        ...item,
                        id: item.subscriptionId,
                      }))
                    : this.props.subscription.map((item) => ({
                        ...item,
                        id: item.subscriptionId,
                      }))
                }
                plans={this.props.plans}
                selectedIds={selectedIds}
                onChangePlan={this.onShowChangePlan}
                onSortChange={this.onSort}
                sort={this.state.sort}
                onRowClick={() => null}
                onDelete={this.onDelete}
                workspace={this.props.workspace}
                onSelect={() => null}
                isSelectedAll={() => null}
              />
            </InfiniteScroll>
          </>
        )}
      </ProcessPage>
    );
  }
}

Subscription.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

Subscription.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    plans: state.plans.list,
  };
}

const withConnect = connect(mapStateToProps, {
});

export default compose(withRouter, withConnect)(Subscription);
