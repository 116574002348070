import {sortBy} from 'lodash'
import { partner } from "../constants/partner";

import {
  getPartner,
  getPartners,
  approvePartner,
  deactivatePartner,
  activatePartner,
  rejectPartner
} from "../api/partner";
import { defaultNotifier } from "functions/notificationHandler";

export function fetchPartners(payload) {
  return async (dispatch) => {
    dispatch({ type: partner.GET_PARTNER_PENDING });

    try {
      const { data } = await getPartners(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      const result = sortBy(data.data.partners, ['workspace.name']);

      dispatch({
        type: partner.GET_PARTNER_FULFILLED,
        payload: result,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the partners");

      dispatch({
        type: partner.GET_PARTNER_REJECTED,
        error: {
          message: "Error while fetching partners.",
        },
      });
    }
  };
}

export function fetchPartner(payload) {
  return async (dispatch) => {
    dispatch({ type: partner.GET_PARTNER_DETAILS_PENDING });

    try {
      const { data } = await getPartner(payload);
      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      dispatch({
        type: partner.GET_PARTNER_DETAILS_FULFILLED,
        payload: data.data.partner,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the partner");

      dispatch({
        type: partner.GET_PARTNER_DETAILS_REJECTED,
        error: {
          message: "Error while fetching the partner.",
        },
      });
    }
  };
}

export function deactivatePartnerAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await deactivatePartner(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Partner deactivated.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to deactivate the partner");
    }
  };
}

export function activatePartneAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await activatePartner(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Partner activated successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to activate the partner");
    }
  };
}

export function approvePartnerAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await approvePartner(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Partner approved successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to approve the partner");
    }
  };
}

export function rejectPartnerAction(payload, onCallback) {
  return async (dispatch) => {
    try {
      const { data } = await rejectPartner(payload);

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier("Partner rejected successfully.");

      if (onCallback) {
        onCallback();
      }
    } catch (err) {
      defaultNotifier("Fail to reject the partner");
    }
  };
}
