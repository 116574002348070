import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import EditInformation from "../Edit/Information";
import CardTags from "app/components/common/Detail/CardTags";

class Information extends Component {
  state = {
    openEditInformationModal: false,
  };

  onEdit = () => {
    this.setState({ openEditInformationModal: true });
  };

  onCloseModal = () => {
    this.setState({ openEditInformationModal: false });
  };

  renderContent = () => {
    const { partner } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">First Name</div>
          <div className="overview__value">{partner && partner.contact && partner.contact.firstName}</div>
          <div className="overview__label">User Email</div>
          <div className="overview__value">
            {partner.contact && partner.contact.email}
          </div>
        </div>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Last Name</div>
          <div className="overview__value">{partner && partner.contact && partner.contact.lastName}</div>
        </div>
      </>
    );
  };

  render() {
    const { openEditInformationModal } = this.state;

    if (openEditInformationModal) {
      return (
        <EditInformation
          partner={this.props.partner}
          color={this.props.color}
          onClose={this.onCloseModal}
        />
      );
    }

    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.partnerContact} />}
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Information.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Information;
