import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import CardTags from "app/components/common/Detail/CardTags";

class Organization extends Component {
  renderContent = () => {
    const { workspace } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.workspaceName} />
          </div>
          <div className="overview__value">{workspace.name}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.companySize} />
          </div>
          <div className="overview__value">{workspace.organizationSize}</div>
        </div>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.companyName} />
          </div>
          <div className="overview__value">{workspace.organizationName}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.companyIndustry} />
          </div>
          <div className="overview__value">
            {workspace.organizationIndustry}
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title="Workspace Information"
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Organization.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default Organization;
