import { defineMessages } from 'react-intl'

export const scope = 'topKamera.component.filter'

export default defineMessages({
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All'
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply'
  },
  addFilter: {
    id: `${scope}.addFilter`,
    defaultMessage: 'Add a filter'
  }
})
