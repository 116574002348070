const filterColumns = [
  {
    id: 1,
    label: 'Interval',
    field: 'interval',
    type: 'radio',
  },
  {
    id: 2,
    label: 'Price Id',
    field: 'name',
    type: 'search',
  },
  {
    id: 5,
    label: 'Created',
    field: 'createdAt',
    type: 'date',
  }]
  
  export default filterColumns