import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { X } from 'react-feather'

class Modal extends Component {
  render() {
    return (
      <>
        <div className="overlay overlay_visible"></div>
        <div className="modal modal_visible">
          <div className="modal__frame">
            <div className="modal__title">{ this.props.title }</div>
            <div className="modal__cont">
              { this.props.body }
            </div>
            <div className="modal__foot">
              { this.props.buttons }
            </div>
            <button
              type="button"
              className="modal__close"
              onClick={this.props.onClose}
            >
              <X />
            </button>
          </div>
        </div>
      </>
    )
  }
}

Modal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  body: PropTypes.node,
  buttons: PropTypes.any.isRequired,
  onClose: PropTypes.func
}

export default Modal
