import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { orderBy } from "lodash";

import SearchField from "app/components/common/SingleSearch";
import { getOutput } from "app/components/common/Filter/clientFilter";

import List from "./Table";

import { routeMapping } from "app/constants/routes";

import {
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DASHBOARD_ACCENT_COLOR,
} from "app/constants/common";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import ProcessPage from "app/components/common/ProcessPage";
import ListSelectedItems from "../../common/ListSelectedItems";

class Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      selectedIds: [],
      showDropdown: false,
      sort: {
        key: "plan.product.name",
        direction: "asc",
      },
      resetFilter: false,
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: 0,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.subscription,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: 0,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);

    const payload = this.props.subscription.map((item) => ({
      ...item,
      id: item.invoiceId,
      name: item.plan.product.name,
      status: item.status,
      quantity: item.quantity,
      provider: "viso",
      createdAt: new Date(item.created * 1000),
    }));

    const finalResult = getOutput(filterData, payload);
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.plan.product.name
          .toLowerCase()
          .includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: 0,
      },
      () => this.setState({ filterResult: this.props.subscription })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  getWorkspaces = () => {
    return;
  };

  loadMore = () => {
    const { modules } = this.props;

    if (modules.loading) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE }, () =>
      this.getWorkspaces()
    );
  };

  isMore = () => {
    return false;
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.PLAN.path}/${id}`);
  };

  addModule = () => {
    this.props.history.push(routeMapping.IMPORT_MODULE.path);
  };

  onClose = () => {
    return this.props.history.push(routeMapping.WORKSPACES.path);
  };

  render() {
    const { color } = this.props;

    const { selectedIds, resetFilter } = this.state;

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {(scrollRef) => (
          <>
            <div className="headline">
              <h1 className="headline__title">Workspace Subscriptions</h1>
              <h3 className="headline__subtitle">
                {this.props.workspace.name}
              </h3>
            </div>
            <div className="filters-bar">
              <ListSelectedItems
                selectedNumber={selectedIds.length}
                search={this.state.searchName}
                onReset={this.onReset}
                filterData={this.state.filter}
                containerClassName={color}
              />
              <Filter
                customClass={color}
                resetFilter={resetFilter}
                onChange={this.onFilter}
                columns={filterColumns}
                data={
                  hasFilter
                    ? this.state.filterResult.map((item) => ({
                        ...item,
                        id: item.invoiceId,
                      }))
                    : this.props.subscription.map((item) => ({
                        ...item,
                        id: item.invoiceId,
                      }))
                }
                defaultFilter={[
                  {
                    field: "status",
                    isDefault: true,
                    value: "All",
                    columns: ["active", "inactive", "draft"],
                  },
                ]}
              />
              <SearchField
                onSearch={this.onSearch}
                customClass={color}
                resetSearch={this.state.resetFilter}
                label="Search"
              />
            </div>
            <InfiniteScroll
              className="data-table"
              loadMore={this.debouncedLoadMore}
              hasMore={false}
              initialLoad={false}
              loader={
                <div className="ml-3" key={0}>
                  Loading...
                </div>
              }
              useWindow={false}
              getScrollParent={() => this.scrollParent}
            >
              <List
                color={color}
                data={
                  hasFilter ? this.state.filterResult : this.props.subscription
                }
                selectedIds={selectedIds}
                onSortChange={this.onSort}
                sort={this.state.sort}
                onRowClick={this.onRowClick}
                workspace={this.props.workspace}
                onSelect={this.onSelect}
                isSelectedAll={this.selectAll}
              />
            </InfiniteScroll>
          </>
        )}
      </ProcessPage>
    );
  }
}

Subscription.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object,
  activeView: PropTypes.string,
  libraryModulesListRequest: PropTypes.func,
};

Subscription.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

export default compose(withRouter)(Subscription);
