import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import UserRow from './Row'
import Table from 'app/components/common/Table'
import {HistoryIcon} from 'images/common'


const columnList = [{
  label: 'Date',
  sortKey: 'date',
  isSortable: false,
  isLocked: true
}, {
  label: 'Invoice no',
  sortKey: 'invoiceNo',
  isSortable: false,
}, {
  label: 'Status',
  sortKey: 'status',
  isSortable: false,
}, {
  label: 'Amount',
  sortKey: 'amount',
  isSortable: false,
}, {
  label: 'Actions',
  sortKey: 'actions',
  isSortable: false,
}]

class UsersTable extends Component {

  onRowClick = () => {
  }

  render() {
    const {color} = this.props

    return (
      <Table
        inputType={this.props.inputType}
        columns={columnList}
        RowComponent={UserRow}
        notFoundIcon={<HistoryIcon/>}
        rows={this.props.users}
        isNotFixed
        tableClassName='data-table-no-fixed'
        CheckboxImage={HistoryIcon}
        isSelectable
        isLoading={this.props.isLoading}
        hideSingleMenu
        colorClassName={color}
        withColumnSelect
        selectedItems={this.props.selectedItems}
        onSelect={this.props.onSelect}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSortChange}
        rowClick={this.onRowClick}
      />
    )
  }
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(UsersTable)
