const filterColumns = [
  {
    id: 1,
    label: 'Status',
    field: 'status',
    type: 'radio',
  },
  {
    id: 2,
    label: 'Worskpace',
    field: 'workspaceName',
    type: 'search',
  }
]
  
  export default filterColumns