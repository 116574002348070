import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const SingleMenu = ({
  closeMenu,
  id,
  status,
  type,
  onToogleMenu,
  hideParentClass,
  onApprove,
  onReject,
  onReview,
  preInstallable,
  onPreInstall
}) => {
  const rejectClass = classNames("actions__option", {
    actions__option_disabled: status === "rejected" || status === "draft" || status === 'listed',
    cursor: status !== "rejected",
  });

  const reviewClass = classNames("actions__option", {
    actions__option_disabled: status === "listed" || status === "rejected" || status === "draft" || status ==="in_review",
    cursor: status === "submitted",
  });

  const approveClass = classNames("actions__option", {
    actions__option_disabled: status === "submitted" || status === "draft" || status === "rejected" || status === "listed",
    cursor: status === "active",
  });

  const installClass = classNames("actions__option", {
    actions__option_disabled: status !== "listed" || type !== 'application',
    cursor: status === "active",
  });

  const onRejectListing = (id, e) => {
    e.stopPropagation();
    if (status === "rejected" || status === "draft" || status === 'listed') {
      return false;
    }

    onToogleMenu(e, id);

    closeMenu();
    onReject(id, e);
  };

  const onReviewListing = (payload, e, status) => {
    e.stopPropagation();
    if (status === "listed" || status === "rejected" || status === "draft" || status ==="in_review") {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onReview(payload, e);
  };

  const onApproveListing = (payload, e, status) => {
    e.stopPropagation();
    if (status === "listed" || status === "rejected" || status === "draft" || status === "submitted") {
      return false;
    }

    onToogleMenu(e, id);

    closeMenu();
    onApprove(payload, e);
  };

  const onPreInstallListing = (e) => {
    e.stopPropagation();
    if (status !== "listed" || type !== "application") {
      return false;
    }

    onToogleMenu(e, id);
    closeMenu();
    onPreInstall(id, preInstallable, e)
  };

  const parentClass = classNames({
    "actions__dropdown actions__dropdown_visible": !hideParentClass,
  });

  return (
    <>
      <div className={parentClass}>
        <span
          onClick={(e) => onReviewListing({ id }, e, status)}
          className={reviewClass}
        >
          Start Review
        </span>
        <span
          onClick={(e) => onApproveListing({ id }, e, status)}
          className={approveClass}
        >
          Approve Listing
        </span>
        <span
          onClick={(e) => onRejectListing({ id }, e, status)}
          className={rejectClass}
        >
          Reject Listing
        </span>
        <span onClick={(e) => onPreInstallListing(e)} className={installClass}>
        {preInstallable ? 'Disable Pre-installation' : 'Enable Pre-installation'}
      </span>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  listing: PropTypes.any,
};

export default SingleMenu;
