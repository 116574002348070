import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import { connect } from 'react-redux';

import App from "app";
import Login from "app/components/login";
import PrivateRoute from "app/components/PrivateRoute";

import {updateDeviceType} from 'app/actions/ui';

class AppContainer extends Component {
  state = {
    width: this.props.updateDeviceType({width: window.innerWidth})
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.props.updateDeviceType({width: window.innerWidth})
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  render() {

    return (
      <IntlProvider locale="en">
        <div className="color-theme-viso contrast-theme-light">
        <div className="central-wrapper"/>
          <Router>
            <Switch>
              <Route exact path="/login" render={() => <Login />} />
              <PrivateRoute path="/" component={App} />
            </Switch>
          </Router>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </div>
      </IntlProvider>
    );
  }
}

AppContainer.propTypes = {};

AppContainer.defaultProps = {
  lang: "en",
};

export default connect(null, {updateDeviceType})(AppContainer);