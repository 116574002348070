import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from 'config/config';
import { BASE_URL } from 'app/constants/common';

const DEFAULT_USERS_FIELDS = {
  id: true,
  email: true,
  firstName: true,
  lastName: true,
  createdAt: true,
  updatedAt: true,
  lastLogin: true
}

const DEFAULT_VISO_USERS_FIELDS = {
  id: true,
  email: true,
  status: true,
  firstName: true,
  lastName: true,
  tags: true,
  isEmailConfirmed: true,
  lastLogin: true,
  createdAt: true,
  updatedAt: true,
  workspaces: {
    id: true,
    name: true
  },
  ownedWorkspaces: {
    id: true,
    name: true
  },
  isBillingContact: true
}

export function getAdminUsers(args) {
  const query = {
    query: {
      adminUsers: {
        ...DEFAULT_USERS_FIELDS
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getVisoUsers(args) {
  const query = {
    query: {
      users : {
        ...DEFAULT_VISO_USERS_FIELDS
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}


export function getVersionInfo(args) {
  const query = {
    query: {
      visoSettings: [
        {
          name: true,
          value: true
        }
      ]
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateVersionSettings(args) {
  const query = {
    mutation: {
      updateVisoSetting: {
        __args: args,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function activateUser(args) {
  const query = {
    mutation: {
      activateUser: {
        __args: args,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deactivateUser(args) {
  const query = {
    mutation: {
      deactivateUser: {
        __args: args,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function activateDevPlan(args) {
  const query = {
    mutation: {
      activateDevPlan: {
        __args: args,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function inviteUsersForDevPlan(args) {
  const query = {
    mutation: {
      inviteUsersForDevPlan: {
        __args: args,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function inviteWorkspaceSetup(args) {
  const query = {
    mutation: {
      inviteWorkspaceSetup: {
        __args: args,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deactivateDevPlan(args) {
  const query = {
    mutation: {
      deactivateDevPlan: {
        __args: args,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function createVisoSetting(args) {
  const query = {
    mutation: {
      createVisoSetting: {
        __args: args,
            name: true,
            value: true
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

