import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {truncate} from 'utils/common'

import { dateFormatter } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";
import classNames from "classnames";

class Workspaces extends PureComponent {
  onRedirectToSubscribedWorkspace = (id, e) => {
    e.stopPropagation()
    e.preventDefault()
    const {workspacesCount} = this.props

    if(!workspacesCount) {
      return false
    }
    this.props.history.push(`/workspace/plan/${id}`);
  };

  getRowRender = () => {
    const {firstName, lastName, updatedAt, email, colorClassName, lastLogin, currentSortKey} = this.props

    const fullName = `${firstName || ''} ${lastName || ''}`

    const nameClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "firstName",
    });

    const emailClass = classNames('text-capitalize no-capitalize', {
      "data-table__td_dark": currentSortKey === "email",
    });

    const roleClass = classNames('text-capitalize', {
      colorClassName: colorClassName,
      "data-table__td_dark": currentSortKey === "role",
    });

    const statusClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "status",
    });

    const loginClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "lastLoginAt",
    });

    const updatedAtClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "updatedAt",
    });

    return {
      email: () => <div className={emailClass}>{ email }</div>,
      firstName: () => <div className={nameClass}>{truncate(fullName, 17)}</div>,
      role: () => <span className={roleClass}>Administrator</span>,
      status: () => <span className={statusClass}>Active</span>,
      lastLoginAt: () => lastLogin ? <span className={loginClass}>{dateFormatter(lastLogin, true)}</span> : '-',
      updatedAt: () => <span className={updatedAtClass}>{dateFormatter(updatedAt, true)}</span>
    }
  }

  render() {
    const { colorClassName } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
