import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SmallCard from "../../common/SmallCard";
import {ModelIcon} from 'images/common'
import SingleMenu from './SingleMenu'
import { convertDefaultStatusToStatus } from 'utils/common'

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getStatus = (status) => {
    const className = classNames("data-cards__status c-text text-capitalize", {
      success: status === 'active',
      failure: status === 'inactive'
    });

    return <i className={className}>•</i>;
  };

  getSubheading = (framework, type) => <>{framework && convertDefaultStatusToStatus(framework)} {framework && '•'} {type && convertDefaultStatusToStatus(type)}</>

  render() {
    const {
      color,
      data,
      onSelect,
      onDelete,
      selectedItems,
    } = this.props;

    return (
      <div className="grid-row">
        {data && data.map((item) => (
          <SmallCard
            key={item.id}
            heading={item.name}
            id={item.id}
            onCardClick={this.props.onRowClick}
            colorClassName={color}
            subheading={this.getSubheading(item.framework, item.modelType)}
            selectedItems={selectedItems}
            onSelect={onSelect}
            CheckboxImage={ModelIcon}
          >
            <SingleMenu 
            onDelete={onDelete}
            onDeactivate={this.props.onDeactivate}
            onActivate={this.props.onActivate}
            id={item.id}
            status={item.status}/>
          </SmallCard>
        ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
