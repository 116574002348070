import React from "react";
import classNames from "classnames";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ExternalLink } from "react-feather";
import Plan from "./PlanItem";
import { FormattedMessage } from "react-intl";
import ReactQuill from "react-quill";
import { withRouter } from "react-router-dom";
import { dateFormatter } from "utils/common";
import { DEFAULT_ACCENT } from "utils/constants";
import ModuleCard from "app/components/ModuleCard";
import ListScroll from "app/components/common/ListScroll";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import UserGuide from "./UserGuide";
import License from "./License";
import PrivacyPolicy from "./PrivacyPolicy";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { WorkspaceIcon } from "images/common";
import { connect } from "react-redux";
import { getListDetails } from "app/actions/listing";
import messages from "utils/message";
import { listCategory } from "app/actions/category";

const interval = {
  1: "monthly",
  2: "yearly",
};

const URL_REGEX = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

const Next = ({ onNext }) => {
  return (
    <button
      onClick={onNext}
      className="solution-gallery__arrow solution-gallery__arrow_next"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
        />
      </svg>
    </button>
  );
};

const Prev = ({ onPrev }) => {
  return (
    <button
      onClick={onPrev}
      className="solution-gallery__arrow solution-gallery__arrow_prev"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
        />
      </svg>
    </button>
  );
};

class MarketplaceDetail extends React.Component {
  constructor(props) {
    super(props);

    this.slider = React.createRef();
  }

  isPreviewPage = () => {
    return false;
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    this.props.getListDetails({ id });
    // this.props.listCategory();
  }

  state = {
    activeSlide: 0,
  };

  next = () => {
    this.slider.current.slickNext();
  };

  previous = () => {
    this.slider.current.slickPrev();
  };

  navigateToImage = (index) => {
    this.slider.current.slickGoTo(index);
  };

  onRedirectToLogin = () => {};

  render() {
    const { solution = {}, color, workspace = {} } = this.props;
    const isPreview = this.isPreviewPage();

    let data = isPreview ? this.props.data : solution;

    if (isPreview && data) {
      data.workspace = this.props.workspace;
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      customPaging: () => <span />,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      prevArrow: <Prev onPrev={this.previous} />,
      nextArrow: <Next onNext={this.next} />,
    };

    if (this.props.isLoading || this.props.loading) {
      return (
        <LoadingIndicator color={this.props.colorClassName} />
      );
    }

    const categories = "";
    const otherCategories = "";

    if (this.state.showUserGuide) {
      return (
        <div>
          <UserGuide
            solution={data}
            readonly
            onClose={() => this.setState({ showUserGuide: false })}
          />
        </div>
      );
    }

    if (this.state.showLicense) {
      return (
        <div>
          <License
            solution={data}
            readonly
            onClose={() => this.setState({ showLicense: false })}
          />
        </div>
      );
    }

    if (this.state.showPrivacy) {
      return (
        <div>
          <PrivacyPolicy
            solution={data}
            readonly
            onClose={() => this.setState({ showPrivacy: false })}
          />
        </div>
      );
    }

    return (
      <div className="marketplace_wrapper">
        <ListScroll customClass="is-cover-page">
          {() => (
            <>
              <div className="cover subscription">
                <div className="content-wrapper">
                  <nav className="head-nav">
                    <button className="round-button round-button_tl round-button_burger">
                      <span className="round-button__label">
                        <FormattedMessage {...messages.menu} />
                      </span>
                    </button>
                  </nav>

                  <Breadcrumb customClass={color}>
                    <BreadcrumbItem route="Listing" />
                    <BreadcrumbItem route={data.name} action={this.onClose} />
                  </Breadcrumb>

                  <div className="subscription_info grid-row grid-row_aic">
                    <div className="grid-col grid-col_auto">
                      <div className="checkbox">
                        <div className="checkbox__image checkbox__image_xl img_container">
                          <img src={data.logo} width="85" height="85" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="solution_wrapper_info">
                      <h1 className="cover__title">{data && data.name}</h1>
                      <p className="mt_100 h4 c-label cover__subtitle">
                        {data.tagLine}
                      </p>
                    </div>
                    {!data.purchased && (
                      <div className="solution_wrapper_signin">
                        <div className="vertical-align">
                          <div className="">
                            {URL_REGEX.test(data.userGuide) ? (
                              <a
                                href={data.userGuide}
                                className="text-link"
                                target="_blank"
                                className="h4 cover__link vertical-align"
                              >
                                <FormattedMessage {...messages.viewUserGuide} />{" "}
                                &nbsp; &nbsp;
                                <ExternalLink size="18" />
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  this.setState({ onShowUserGuide: true })
                                }
                                className="h4 cover__link vertical-align"
                              >
                                <FormattedMessage {...messages.viewUserGuide} />{" "}
                                &nbsp; &nbsp;
                                <ExternalLink size="18" />
                              </a>
                            )}
                          </div>
                          <div className="">
                            <button
                              className={`button_${color} button button_sm`}
                              onClick={this.onRedirectToLogin}
                            >
                              <FormattedMessage {...messages.signInInstall} />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="secondary-nav">
                    <a
                      href="#"
                      className="secondary-nav__link secondary-nav__link_active"
                    >
                      <FormattedMessage {...messages.overview} />
                    </a>
                    {/* <a href="#" className="secondary-nav__link">
                      <FormattedMessage {...messages.pricing} />
                    </a>
                    <a href="#" className="secondary-nav__link">
                      <FormattedMessage {...messages.reviews} />
                    </a> */}
                  </div>
                </div>
              </div>

              <div className="cover-market-spacing">
                <h2 className="mb_150 text-color">
                  {data.type === "application" ? (
                    <FormattedMessage {...messages.applicationOverview} />
                  ) : (
                    <FormattedMessage {...messages.moduleOverview} />
                  )}
                </h2>

                <div className="solution-separator">
                  <div className="solution-separator-main">
                    <div id="solution-gallery" className="solution-gallery">
                      <Slider {...settings} ref={this.slider}>
                        {data.images &&
                          data.images.map((image) => (
                            <div
                              className="solution-gallery__slide"
                              key={image}
                            >
                              <div className="solution-gallery__image">
                                <img
                                  src={image}
                                  alt=""
                                  width={139}
                                  height={80}
                                />
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>

                    <div
                      id="solution-thumbnails"
                      className="solution-thumbnails"
                    >
                      <div>
                        {data.images &&
                          data.images.map((key, index) => {
                            const solutionClass = classNames(
                              "solution-thumbnails__image",
                              {
                                "solution-thumbnails__image_active":
                                  index === this.state.activeSlide,
                              }
                            );

                            return (
                              <span
                                key={index}
                                onClick={() => this.navigateToImage(index)}
                              >
                                <span className={solutionClass}>
                                  <img
                                    src={key}
                                    width="146"
                                    height="85"
                                    alt=""
                                  />
                                </span>
                              </span>
                            );
                          })}
                      </div>
                    </div>

                    <div className="solution-desc">
                      <div className="text-editor hide-editor-layout">
                        <ReactQuill
                          theme="snow"
                          onChange={() => null}
                          value={(data && data.description) || ""}
                          readOnly
                        />
                      </div>
                      {data.keywords && !!data.keywords.length && (
                        <>
                          <h3>
                            <FormattedMessage {...messages.keywords} />
                          </h3>
                          <div className="tags tags_accent0 solution-tag">
                            {data.keywords &&
                              data.keywords.map((keyword) => (
                                <div className="tags__item" key={keyword}>
                                  {keyword}
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="solution-separator-navbar">
                    <div className="solution-info">
                      <div className="solution-info__heading mx-b-12">
                        <FormattedMessage {...messages.developer} />
                      </div>
                      <div className="solution-info__item">
                        <div className="checkbox">
                          <div className="checkbox__image">
                            {data && data.workspace && data.workspace.logo ? (
                              <img
                                src={data.workspace.logo}
                                width="44"
                                height="44"
                                alt=""
                              />
                            ) : (
                              <WorkspaceIcon />
                            )}
                          </div>
                        </div>
                        {"    "}
                        <Link
                          to={`/workspace/${data.workspaceId}`}
                          className="text-link"
                        >
                          {data && data.workspace && data.workspace.name}
                        </Link>
                      </div>

                      <div className="solution-info__heading mx-b-10">
                        <FormattedMessage {...messages.categories} />
                      </div>
                      <div className="tags tags_label">
                        {data.marketplaceCategories &&
                          !!data.marketplaceCategories.length &&
                          data.marketplaceCategories.map((category) => (
                            <div className="tags__item">{category.name}</div>
                          ))}
                      </div>

                      <div className="solution-info__heading">
                        <FormattedMessage {...messages.lastUpdated} />
                      </div>
                      <div className="solution-info__item">
                        {data.updatedAt && dateFormatter(data.updatedAt)}
                      </div>

                      <div className="solution-info__heading">
                        <FormattedMessage {...messages.version} />
                      </div>
                      <div className="solution-info__item">
                        {data.activeVersion || "v1.0.0"}
                      </div>

                      {data.type === "application" && (
                        <div className="solution-info__heading">
                          <FormattedMessage {...messages.requiredModules} />
                        </div>
                      )}
                      {data.requiredModules &&
                        data.requiredModules.map((item) => {
                          if (item.type === "public") {
                            return (
                              <div className="solution-info__item">
                                <a
                                  href={item.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-link"
                                >
                                  {item.name}
                                </a>
                              </div>
                            );
                          }
                          return (
                            <div className="solution-info__item">
                              <Link
                                to={`/listing/${item.link}`}
                                className="text-link"
                              >
                                {item.name}
                              </Link>
                            </div>
                          );
                        })}
                      <div className="solution-info__heading">
                        <FormattedMessage {...messages.resources} />
                      </div>
                      <div className="solution-info__item">
                        {URL_REGEX.test(data.userGuide) ? (
                          <a
                            href={data.userGuide}
                            className="text-link"
                            target="_blank"
                          >
                            <FormattedMessage {...messages.userGuide} />
                          </a>
                        ) : (
                          <a
                            href="javascript:void(0)"
                            className="text-link"
                            onClick={() =>
                              this.setState({ showUserGuide: true })
                            }
                          >
                            <FormattedMessage {...messages.userGuide} />
                          </a>
                        )}
                      </div>
                      <div className="solution-info__item">
                        <a
                          href="javascript:void(0)"
                          className="text-link"
                          onClick={() => this.setState({ showLicense: true })}
                        >
                          <FormattedMessage {...messages.termsOfUse} />
                        </a>
                      </div>
                      <div className="solution-info__item">
                        <a
                          href="javascript:void(0)"
                          className="text-link"
                          onClick={() => this.setState({ showPrivacy: true })}
                        >
                          <FormattedMessage {...messages.privacyPolicy} />
                        </a>
                      </div>

                      <div className="solution-info__heading">
                        <FormattedMessage {...messages.contact} />
                      </div>
                      <div className="solution-info__item">
                        <a
                          className="text-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`mailto:${data.email}`}
                        >
                          <FormattedMessage {...messages.emailSupport} />
                        </a>
                      </div>
                      <div className="solution-info__item">
                        <a
                          className="text-link"
                          href={data.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage {...messages.supportWebsite} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="solution-pricing__wrapper">
                <div className="content-wrapper">
                  <div className="grid-row">
                    <div className="grid-col grid-col_6 solution-pricing__container">
                      <h2 className="text-color">
                        {data.name} <FormattedMessage {...messages.pricing} />
                      </h2>
                      <div className="label-color solution-pricing__wrapper_desc">
                        <FormattedMessage {...messages.integrationRequires} />
                      </div>{" "}
                      <a
                        href="javascript:void(0)"
                        className="text-link"
                        onClick={() => this.setState({ showLicense: true })}
                      >
                        terms of service
                      </a>
                      ,{" "}
                      <a
                        href="javascript:void(0)"
                        className="text-link"
                        onClick={() => this.setState({ showPrivacy: true })}
                      >
                        privacy policy
                      </a>
                      ,{" "}
                      {URL_REGEX.test(data.userGuide) ? (
                        <a
                          href={data.userGuide}
                          className="text-link"
                          target="_blank"
                        >
                          support documentation
                        </a>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          className="text-link"
                          onClick={() => this.setState({ showUserGuide: true })}
                        >
                          support documentation
                        </a>
                      )}
                    </div>
                    {data.prices &&
                      (data.monthlyPriceId || data.yearlyPriceId) && (
                        <div className="plan_container__card">
                          <Plan
                            key={1}
                            plan={
                              data.prices &&
                              data.prices.find(
                                (price) =>
                                  price.id ===
                                  (workspace.subscriptionInterval !== 2
                                    ? data.monthlyPriceId
                                    : data.yearlyPriceId)
                              )
                            }
                            type={data.type}
                            name={<FormattedMessage {...messages.pricing} />}
                            interval={interval[workspace.subscriptionInterval]}
                            onSelectPlan={this.onRedirectToLogin}
                            accent={"accent0"}
                            color="accent0"
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>

              {otherCategories && (
                <div className="solution-pricing__container solution-category__container">
                  <h2 className="mb_150 text-color solution-preview-category__title">
                    {data.type === "application" ? (
                      <>
                        <FormattedMessage {...messages.moreApplicationsIn} />:{" "}
                        <span>
                          {categories &&
                            categories.map((item, index) => (
                              <span className="text-capitalize">
                                {item}
                                {index !== categories.length - 1 && ", "}
                              </span>
                            ))}
                        </span>
                      </>
                    ) : (
                      <>
                        <FormattedMessage {...messages.moreModulesIn} />:{" "}
                        <span>
                          {categories &&
                            categories.map((item, index) => (
                              <span className="text-capitalize">
                                {item}
                                {index !== categories.length - 1 && ", "}
                              </span>
                            ))}
                        </span>
                      </>
                    )}
                  </h2>
                  <div className="grid-row data-cards__wrapper_solution">
                    {categories &&
                      categories.map((item) => {
                        return item.solutions
                          .filter((sol) => sol.id !== data.id)
                          .filter((sol) => sol.type === data.type)
                          .map((sol) => (
                            <ModuleCard
                              name={sol.name}
                              id={sol.id}
                              key={sol.id}
                              logo={sol.logo}
                              type={sol.type}
                              description={sol.tagLine}
                            />
                          ));
                      })}
                  </div>
                </div>
              )}
            </>
          )}
        </ListScroll>
      </div>
    );
  }
}

MarketplaceDetail.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    solution: state.listing.detail,
    solutionByCategories: state.category.list,
    isLoading: state.listing.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  getListDetails,
  listCategory,
});

export default withRouter(withConnect(MarketplaceDetail));
