import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import classNames from 'classnames';

import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import { WorkspaceIcon } from "images/common";
import DetailScroll from "app/components/common/Scroll";
import Owner from "./components/Owner";
import Agents from "./components/Agents";
import WorkspaceCard from "./components/WorkspaceCard";
import Organization from "./components/Organization";
import BillingSettings from "./components/BillingSettings";
import BillingInfo from "./components/BillingInfo";
import BillingContact from "./components/BillingContact";
import Billing from "./components/Billing";
import Subscriptions from "./components/Subscriptions";
import Invoice from "./components/Invoice";
import Metrics from "./components/Metrics";
import DeleteWorkspace from "../DeleteWorkspace";
import {updateWorkspaceAnnotationSetting, updateWorkspaceTrainingSetting, cvEnvs, updateWorkspace, updateWorkspaceDatasetSetting, activateAutoTraining} from 'app/api/workspaces'
import { defaultNotifier } from 'functions/notificationHandler'
import {
  listPlans
} from "app/actions/plans";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";

import { DEFAULT_ACCENT } from "app/constants/common";
import {
  getWorkspaceDetails,
  listWorkspaceSubscriptions,
  listVisoAgents
} from "app/actions/workspaces";
import SingleMenu from "../list/SingleMenu";
import messages from "utils/message";
import { dateFormatter } from "utils/common";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import EditBillingSetting from './components/EditBillingSetting'
import Modal from "app/components/common/Modal";
import AddCredit from './components/AddCredit';

import {
  listWorkspaces,
  deleteWorkspaceAction,
  scheduleDeleteWorkspaceAction,
  archiveWorkspaceAction,
  cancelScheduleDeleteWorkspaceAction,
  activateWorkspaceAction,
  deactivateWorkspaceAction,
  getWorkspaceInvoice
} from "app/actions/workspaces";
import { routeMapping } from "app/constants/routes";
import PreLoader from "app/components/common/PreLoader/PreLoaderDetail";
import AddCharge from "./components/AddCharge";

class WorkspaceDetail extends React.Component {
  state = {
    workspace: {},
    showDeleteModal: false,
    showDropdown: false,
    loggedInUser: this.props.loggedInUser,
    editPopupForm: null,
  };

  onDeactiveWorkspace = (payload, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Confirm deactivate workspace";
    const body =
      "The users of the workspace will not be able to sign in and access their data. All subscriptions are paused.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: () =>
        this.props.deactivateWorkspaceAction(payload, this.getWorkspaces),
      onCancel: this.onCloseModal,
    });
  };

  onActivateWorkspace = (payload, e) => {
    e.preventDefault();
    e.stopPropagation();
    const title = "Confirm activate workspace";
    const body =
      "Activating the workspace will allow the workspace users to sign in and re-activate the paused subscriptions.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: () =>
        this.props.activateWorkspaceAction(payload, this.getWorkspaces),
      onCancel: this.onCloseModal,
    });
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  onActivateAnnotation = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const title = "Activate Annotation";
    const body =
      "Activating the annotation will allow the workspace users to create environments and re-use paused environments.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        await updateWorkspaceAnnotationSetting({
          id, 
          enabled: true
        });
        defaultNotifier('Annotation enabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateAnnotation = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const {color} = this.props;
    const {data} = await cvEnvs({workspaceId: id});
    const cvList = data.data.cvEnvs;

    const activeEnv = cvList && cvList
    .filter(cv => cv.template === 'CVAT')
    .filter(cv => cv.status !== 'paused')

    const title = "Deactivate Annotation";
    const body = <span>Workspace users will no longer be able to create environments. There are <span className={color}>{activeEnv ? activeEnv.length : 0} environment(s)</span> that will be set to paused.</span>

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        await updateWorkspaceAnnotationSetting({
          id, 
          enabled: false
        });
        defaultNotifier('Annotation disabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onActivateTraining = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const title = "Activate Training";
    const body =
      "Activating the training will allow the workspace users to create experiment and re-use paused experiment.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
      await activateAutoTraining({
          id, 
          enabled: true
        });
      defaultNotifier('Training enabled.');
      this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onActivateAutoTraining = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const title = "Activate Experiment";
    const body =
      "Activating will allow the workspace users to create experiment and re-use paused experiment.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        await activateAutoTraining({
          id, 
          enabled: true
        });
      defaultNotifier('Training enabled.');
      this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateTraining = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const {color} = this.props;
    const {data} = await cvEnvs({workspaceId: id});
    const cvList = data.data.cvEnvs;
  
    const activeEnv = cvList && cvList
    .filter(cv => cv.template === 'JupyterLab')
    .filter(cv => cv.status !== 'paused')
    const title = "Deactivate Annotation";
    const body = <span>Workspace users will no longer be able to create environments. There are <span className={color}>{activeEnv ? activeEnv.length : 0} environment(s)</span> that will be set to paused.</span>
    
    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
        await updateWorkspaceTrainingSetting({
            id, 
            enabled: false
        });
        defaultNotifier('Training disabled.')
        this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onActivateDataset = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const title = "Activate Dataset";
    const body =
      "Activating the dataset will allow the workspace users to create dataset.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
      await updateWorkspaceDatasetSetting({
          id, 
          enabled: true
        });
      defaultNotifier('Dataset enabled.');
      this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onDeactivateDataset = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
  
    const title = "Deactivate Dataset";
    const body =
      "Deactivating the dataset will revoke all the access related to dataset.";

    this.setState({
      title,
      showModal: true,
      body,
      onConfirm: async () => {
      await updateWorkspaceDatasetSetting({
          id, 
          enabled: false
        });
      defaultNotifier('Dataset disabled.');
      this.getWorkspaces()
      },
      onCancel: this.onCloseModal,
    });
  };

  onScheduleDeleteWorkspace = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const date = moment().add(14, "days").format("D MMM YYYY HH:mm");

    const body = `The workspace will be deleted. All personal data will be permanently deleted. To cancel the deletion, the workspace can be recovered until ${date}.`;
    const title = `Confirm remove workspace`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () =>
        this.props.scheduleDeleteWorkspaceAction(
          { workspaceId: id },
          this.getWorkspaces
        ),
      onCancel: this.onCloseModal,
    });
  };

  onCancelSchedule = (id, e, deleteDate) => {
    e.preventDefault();
    e.stopPropagation();

    const body = `Your workspace has been deleted. We will permanently delete your personal data.
    To cancel the deletion, you can recover the workspace until ${dateFormatter(
      deleteDate,
      true
    )}`;
    const title = `Confirm recover workspace`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () =>
        this.props.cancelScheduleDeleteWorkspaceAction(
          { workspaceId: id },
          this.getWorkspaces
        ),
      onCancel: this.onCloseModal,
    });
  };

  onDeleteWorkspace = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      showDeleteModal: true,
      currentWorkspaceId: id,
    });
  };

  getAgents = () => {
    const { id } = this.props.match.params;

    this.props.listVisoAgents({ workspaceId: id });
  };

  getWorkspaces = () => {
    const { id } = this.props.match.params;

    this.props.getWorkspaceDetails({ id });
  };

  redirectToWorkspaces = () => {
    this.setState({ showDeleteModal: false });

    this.props.history.push(routeMapping.WORKSPACES.path);
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.props.getWorkspaceDetails({ id });
    this.props.listPlans();
    this.getAgents()
    // this.props.listWorkspaceSubscriptions({workspaceId: id})
  }

  componentWillReceiveProps(nextProps) {
    const {workspace} = this.props;

    if((nextProps.workspace && nextProps.workspace.availablePaymentMethods) && nextProps.workspace !== workspace) {
      this.setState({
        creditCard: nextProps.workspace.availablePaymentMethods.creditCard,
        bank: nextProps.workspace.availablePaymentMethods.bank
      })
    }
  }

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onEdit = (formName) => {
    this.setState({ editPopupForm: formName });
  };

  onEditClose = () => {
    this.setState({ editPopupForm: null });
  };

  renderModal = () => {
    const { body, onConfirm, onCancel, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={onCancel}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={onCancel} />
    );
  };

  onManageAgent = () => {
    const { id } = this.props.match.params;

    this.props.history.push(`/workspace/${id}/agents`);
  }

  onUpdateModal = (name) => {
    this.setState({[name]: !this.state[name]})
  }

  onUpdateSettings = async (payload) => {
    const {workspace: {id}} = this.props;

    const {data} = await updateWorkspace({id, ...payload})

    if(data.errors) {
      return defaultNotifier(data.errors)
    }

    this.setState({showAvailableModal: false})
    defaultNotifier('Billing settings successfully.')
    this.getWorkspaces()
  }

  onUpdateWorkspace = async () => {
    const {workspace: {id}} = this.props;

    const {data} = await updateWorkspace({id, availablePaymentMethods : {
      bank: this.state.bank,
      creditCard: this.state.creditCard
    }})

    if(data.errors) {
      defaultNotifier(data.errors)
    }

    defaultNotifier('Payment method updated successfully.')
    this.getWorkspaces()
  }

  updatePaymentModal = (closeMenu) => {
    const { color } = this.props;

    const buttons = [
      <button
        className={classNames("modal__confirm", {
          [`modal__confirm_${color}`]: color,
        })}
        key="confirm"
        onClick={() => {
            this.onUpdateWorkspace();
            this.setState({ showAvailableModal: false });
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button
        className="modal__cancel"
        key="cancel"
        onClick={() => this.setState({ showAvailableModal: false })}
      >
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal
        classNames="modal__frame"
        buttons={buttons}
        title={"Available Payment Methods"}
        body={
          <>
            <div>
            Manage the payment methods available for this workspace. Ensure that at least one methods must be selected.
            </div>
            <div className="modal__checkbox">
              <div className={`checkbox checkbox_blocked checkbox_${color}`}>
                <input
                  type="checkbox"
                  name="new"
                  className={`checkbox__input modal__checkbox__input checkbox_${color}`}
                  onClick={() => this.onUpdateModal('creditCard')}
                  checked={this.state.creditCard}
                />
                <div
                  className="checkbox__check"
                  onClick={() => this.onUpdateModal('creditCard')}

                />
                <label
                  className="checkbox__label modal__checkbox__label label-color-imp"
                  onClick={() => this.onUpdateModal('creditCard')}

                >
                  Credit Card
                </label>
              </div>
              <div className="spacer-line"/>
              <div className={`checkbox checkbox_blocked checkbox_${color}`}>
                <input
                  type="checkbox"
                  name="new"
                  className={`checkbox__input modal__checkbox__input checkbox_${color}`}
                  onClick={() => this.onUpdateModal('bank')}
                  checked={this.state.bank}
                />
                <div
                  className="checkbox__check"
                  onClick={() => this.onUpdateModal('bank')}
                />
                <label
                  className="checkbox__label modal__checkbox__label label-color-imp"
                  onClick={() => this.onUpdateModal('bank')}
                >
                  Bank
                </label>
              </div>
            </div>
          </>
        }
        onClose={() => this.setState({ showAvailableModal: false })}
      />
    );
  };

  onShowAvailableModal = () => {
    this.setState({showAvailableModal: true})
  }

  render() {
    const { color, workspace, invoices } = this.props;
    const { showAvailableModal } = this.state;
    const { id } = this.props.match.params;

    if (!workspace) {
      return <DetailScroll/>
    }

    if (this.state.showDeleteModal) {
      return (
        <DeleteWorkspace
          isLoading={this.props.isLoading}
          workspace={workspace}
          onDelete={() =>
            this.props.deleteWorkspaceAction(
              { id: this.state.currentWorkspaceId },
              this.redirectToWorkspaces
            )
          }
          onClose={() => this.setState({ showDeleteModal: false })}
        />
      );
    }

    if(this.state.showCreditModal) {
      return <AddCredit 
      id={id}
      getWorkspaces={this.getWorkspaces}
      onClose={() => this.setState({showCreditModal: false})}/>
    }

    if(this.state.showChargeModal) {
      return <AddCharge 
      id={id}
      getWorkspaces={this.getWorkspaces}
      onClose={() => this.setState({showChargeModal: false})}/>
    }
  
    if(this.props.isLoading){
      return <PreLoader/>
    }

    if(showAvailableModal) {
      return <EditBillingSetting 
      workspace={workspace}
      onUpdateSettings={this.onUpdateSettings}
      closePopup={() => this.setState({showAvailableModal: false})}/>
    }
    

    return (
      <DetailScroll>
        {this.state.showModal && this.renderModal()}
        <div className={"messages-bar snackbar-wrapper"}></div>
        <div className="headline">
          <div className="headline__icon admin_workspace__image accent0">
            <WorkspaceIcon />
          </div>
          <h1 className="headline__title">
            {workspace.name}
            <>
                  {'   '}
                  <span className="workspace_owner_tag">
                    {workspace.status}
                  </span>
                </>
            </h1>
        </div>

        <Breadcrumb breadcrumbClass={`breadcrumbs breadcrumbs_${color}`}>
          <BreadcrumbItem route="Workspaces" />
          <BreadcrumbItem route={workspace.name} />
        </Breadcrumb>

        <div className="actions-nav">
          <div className="actions">
            <VerticalButtonMenu customClass={color}>
              <SingleMenu
                id={id}
                hideParentClass
                isSingleMenu
                onAddCharge={() => this.setState({showChargeModal: true})}
                onAddCredit={() => this.setState({showCreditModal: true})}
                onOpenSubscription={() => this.setState({isSubscriptionOpen: true})}
                onActivateAnnotation={this.onActivateAnnotation}
                onDeactivateAnnotation={this.onDeactivateAnnotation}
                onActivateTraining={this.onActivateTraining}
                onDeactivateTraining={this.onDeactivateTraining}
                onActivateDataset={this.onActivateDataset}
                onDeactivateDataset={this.onDeactivateDataset}
                onCancelSchedule={this.onCancelSchedule}
                deleteDate={workspace.deleteDate}
                status={workspace.status}
                onShowPlanPage={this.onShowPlanPage}
                onManageAgent={this.onManageAgent}
                onUpdate={this.onUpdateWorkspace}
                onDeactivate={this.onDeactiveWorkspace}
                onActivate={this.onActivateWorkspace}
                trainingEnabled={workspace.trainingEnabled}
                datasetEnabled={workspace.datasetEnabled}
                annotationEnabled={workspace.annotationEnabled}
                onDelete={this.onDeleteWorkspace}
                onScheduleDelete={this.onScheduleDeleteWorkspace}
                onActivateAutoTraining={this.onActivateAutoTraining}
              />
            </VerticalButtonMenu>
          </div>
            <span
            className={`actions-nav__link actions-nav__target cursor`}
            onClick={() => this.onShowAvailableModal()}
          >
            Billing Settings
          </span>

        </div>
          <div className="grid-row">
            <div className="grid-col grid-col_sm-12">
            {workspace && <Owner color={color} workspace={workspace} />}
              {workspace && (
                <Organization workspace={workspace} color={color} />
              )}
              {workspace && <BillingContact color={color} workspace={workspace} />}
              {workspace && (
                <BillingInfo workspace={workspace} color={color} />
              )}
              {workspace.billInformation && <Billing workspace={workspace} />}
              {workspace && <Agents color={color} workspace={workspace} agents={this.props.agents}/>}

            </div>
            <div className="grid-col grid-col_sm-12">
              {workspace && (
                <WorkspaceCard color={color} workspace={workspace} />
              )}
              {workspace && workspace.kilBillInfo && (
                <BillingSettings
                onEdit={this.onShowAvailableModal}
                workspace={workspace} 
                color={color} />
              )}
              {workspace.subscriptions && (
                <Subscriptions 
                isOpen={this.state.isSubscriptionOpen}
                onOpen={() => this.setState({isSubscriptionOpen: true})}
                onClose={() => this.setState({isSubscriptionOpen: false})}
                plans={this.props.plans}
                getWorkspaces={this.getWorkspaces}
                workspace={workspace} />
              )}
                {/* <Invoice workspace={workspace} 
                allInvoices={invoices}
                getInvoicesRequest={this.props.getWorkspaceInvoice}
                invoices={invoices && invoices.data}/> */}
              {workspace.subscriptionData && <Metrics workspace={workspace} />}
            </div>
          </div>
      </DetailScroll>
    );
  }
}

WorkspaceDetail.propTypes = {
  color: PropTypes.string,
  workspace: PropTypes.object,
  listRequest: PropTypes.func,
  workspaces: PropTypes.array,
  loggedInUser: PropTypes.object,
  updateWorkspaceRequest: PropTypes.func,
};

WorkspaceDetail.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    workspace: state.workspaces.detail,
    plans: state.plans.list,
    workspaces: state.workspaces.list,
    invoices: state.workspaces.invoices,
    agents: state.workspaces.agents,
    isLoading: state.workspaces.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  listPlans,
  getWorkspaceDetails,
  getWorkspaceInvoice,
  listWorkspaceSubscriptions,
  listWorkspaces,
  listVisoAgents,
  deactivateWorkspaceAction,
  deleteWorkspaceAction,
  cancelScheduleDeleteWorkspaceAction,
  scheduleDeleteWorkspaceAction,
  archiveWorkspaceAction,
  activateWorkspaceAction,
});

export default compose(withRouter, withConnect)(WorkspaceDetail);
