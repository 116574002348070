import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import { dateFormatter } from "utils/common";
import { convertDefaultStatusToStatus } from "utils/common";

class Overview extends Component {
  getStatus = (status) => {
    const statusClass = classNames(
      "data-table__status text-capitalize text-captilize",
      {
        "data-table__status_pos": status === "approved",
        "data-table__status_warning": status === "pending",
        "data-table__status_neg": status === "rejected",
      }
    );

    return (
      <div className={statusClass}>
        {status && convertDefaultStatusToStatus(status)}
      </div>
    );
  };
  renderContent = () => {
    const { partner, color } = this.props;

    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">Created Listings</div>
          <div className="overview__value">-</div>
          <div className="overview__label">Active Listings</div>
          <div className="overview__value">
            -
          </div>
        </div>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            Active Subscriptions
          </div>
          <div className="overview__value">
            -
          </div>
          <div className="overview__label">Subscription Sales</div>
          <div className="overview__value">
            -
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.offeredListings} />}
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Overview.propTypes = {
  onEdit: PropTypes.func,
  color: PropTypes.string,
  workspace: PropTypes.object,
};

export default Overview;
