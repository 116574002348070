const filterColumns = [
  {
    id: 2,
    label: 'Payment method',
    field: 'paymentMethod',
    type: 'radio',
    customValue: { All: 'All', 'Bank': 1, 'Credit Card': 2},
    customKey: { All: 'All', 1: 'Bank', 2: 'Credit Card'},
  },
  {
    id: 3,
    label: 'Worskpace',
    field: 'workspace',
    type: 'multiSelect',
  },
  {
    id: 5,
    label: 'Created',
    field: 'createdAt',
    type: 'date',
  }]
  
  export default filterColumns