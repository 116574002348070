import React from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'

import { FILTER_TYPE } from '../constants'

class SingleSelect extends React.Component {
  state = {
    filterValue: ''
  }

  onApply = (column) => {
    const { filterValue } = this.state

    this.props.onFilterApply(column, filterValue, FILTER_TYPE.SINGLE_SELECT)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEnter, false);
  }

  onEnter = (event) => {
    if (event.key === 'Enter') {
      // on Esc button click it should close popup
      this.onApply()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onChange = (e) => {
    const { value } = e.target

    this.setState({ filterValue: value })
  }

  render() {
    const { columns } = this.props

    return (
      <>
        <Scrollbars autoHeight autoHeightMax={columns.length > 0 ? 250 : 0}
          renderView={props => <div {...props} className="view"/>}>
          {columns
            .filter(column => column.name)
            .map(column => (
              <div className="filters-bar__option" onClick={() => this.onApply(column, column.name, FILTER_TYPE.SINGLE_SELECT)} key={column.name}>{column.label}</div>
            ))}
        </Scrollbars>
      </>
    )
  }
}

SingleSelect.propTypes = {
  isDefault: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  columns: PropTypes.array,
  onFilterApply: PropTypes.func
}

export default SingleSelect
