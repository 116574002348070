import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from 'config/config';
import { BASE_URL } from 'app/constants/common';

const MODEL_DETAILS_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  modelType: true,
  categories: true,
  framework: true,
  deviceTypes: true,
  dataset: true,
  license: true,
  modelSize: true,
  imageSize: true,
  labels: true,
  associatedNodes: {
    node: true,
    module: true
  },
  source: true,
  url: true,
  createdAt: true,
  updatedAt: true
}

const MODEL_LIST_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  source: true,
  modelType: true,
  categories: true,
  framework: true,
  deviceTypes: true,
  dataset: true,
  url: true,
  createdAt: true,
  updatedAt: true
}

const DOCKER_CONTAINER_LIST_RETURN_VALUES = {
  id: true,
  module: true,
  nodeType: true,
  repositoryUri: true,
  createdAt: true,
  updatedAt: true
}

const MODEL_FRAMEWORK = {
  key: true,
  name: true,
}

export function getModelFramework() {
  const query = {
    query: {
      modelFrameworks: {
        ...MODEL_FRAMEWORK
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getModels() {
  const query = {
    query: {
      models: {
        ...MODEL_LIST_RETURN_VALUES
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getModelDetails(payload) {
  const query = {
    query: {
      model: {
        __args: payload,
        ...MODEL_DETAILS_RETURN_VALUES
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function createModel(args) {
  const query = {
    mutation: {
      createModel: {
        __args: args,
        ...MODEL_LIST_RETURN_VALUES
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })
  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deleteModel(args) {
  const query = {
    mutation: {
      deleteModel: {
        __args: args,
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })
  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function uploadModelFile(param, data) {

  const query = {
    mutation: {
      uploadFile: {
        __args: {filename: param.file.path, limitSize: false},
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {
      },
      query: gqlQuery
    },
  })
}

export function updateModel(args) {
  const query = {
    mutation: {
      updateModel: {
        __args: args,
        ...MODEL_DETAILS_RETURN_VALUES
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })
  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function assignNodesToModel(args) {
  const query = {
    mutation: {
      assignNodesToModel: {
        __args: args,
        ...MODEL_DETAILS_RETURN_VALUES
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })
  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function unassignNodesFromModel(args) {
  const query = {
    mutation: {
      unassignNodesFromModel: {
        __args: args,
        ...MODEL_DETAILS_RETURN_VALUES
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })
  return axiosObj({
    method: 'POST',
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}