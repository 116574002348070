import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import Card from "app/components/common/Detail/Card";
import { FormattedMessage } from "react-intl";
import { WorkspaceIcon } from "images/common";

import messages from "utils/message";

class Workspace extends Component {
  state = {
    showListPage: false,
  };

  onRedirectToUser = (id) => {
    // this.props.history.push(`${routeMapping.SETTINGS_USER.path}/${id}`)
  };

  getStatus = () => {
    const {payment} = this.props;

    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": payment.workspace.status === 'active',
        "data-table__status_neg": payment.workspace.status === 'inactive',
        "data-table__status_neutral": payment.workspace.status === 'deleted',
      }
    );

    return <div className={className}>{payment.workspace.status || 'Active'}</div>
  };

  renderContent = () => {
    const { color, payment } = this.props;

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
              <tr className="data-table__row">
                <td className="data-table__td data-table__td_1">
                  <div className={`checkbox checkbox_${color}`}>
                    <div className="checkbox__image">
                      <WorkspaceIcon />
                    </div>
                  </div>
                </td>
                <td className="data-table__td">
                  {payment.workspace.name}
                </td>
                <td className="data-table__td">
                  {this.getStatus()}
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { workspace } = this.props;

    return (
      <Card
        noGrid
        color={this.props.color}
        title="Workspace"
        subTitle="Related subscription workspace account."
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

Workspace.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default withRouter(Workspace);
