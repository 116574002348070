import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Button = ({children, isDisabled, onClick, color, customClass, className, disabled}) => {
  return (
    <button
      onClick={onClick}
      className={classNames({
        [`button_${color}`]: color,
        [className]: className,
        'button_disabled': disabled
      })}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default Button
