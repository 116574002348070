import classnames from 'classnames';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const ActionIcon = ({ to, icon, onClick, text, color, type, disabled, className }) => {
  if (type === 'link') {
    return (
      <Link
        to={to}
        onClick={onClick}
        className={classnames('actions__option', {
          [color]: color,
          [className]: className,
          actions__option_disabled: disabled
        })}
      >
        <span className="action-icon">
          {icon && <div className="action_icon_item">{icon}</div>}
          <div className="action-icon-text">{text}</div>
        </span>
      </Link>
    );
  }

  return (
    <a href={'javascript:void(0)'}
      onClick={onClick}
      className={classnames('actions__option', {
        [color]: color,
        [className]: className,
        actions__option_disabled: disabled
      })}
    >
      <span className="action-icon">
      {icon && <div className="action_icon_item">{icon}</div>}
        <div className="action-icon-text">{text}</div>
      </span>
    </a>
  );
};

ActionIcon.propTypes = {
  color: PropTypes.string
};

ActionIcon.defaultProps = {
  color: 'accent0'
};

export default ActionIcon;
