import { jsonToGraphQLQuery } from "json-to-graphql-query";

import axiosObj from "config/config";
import { BASE_URL } from "app/constants/common";

const DEFAULT_PAYMENTS_FIELDS = {
  count: true,
  data: {
    id: true,
    invoiceId: true,
    invoiceNumber: true,
    amount: true,
    currency: true,
    paymentMethod: true,
    paymentNumber: true,
    reference: true,
    note: true,
    tags: true,
    workspace: {
      id: true,
      name: true,
    },
    paymentDate: true,
    createdAt: true,
    createdBy: {
      id: true,
      firstName: true,
      lastName: true
    }
  }
};

const DEFAULT_PAYMENT_FIELDS = {
  id: true,
  invoiceId: true,
  invoiceNumber: true,
  amount: true,
  currency: true,
  paymentMethod: true,
  paymentNumber: true,
  reference: true,
  note: true,
  tags: true,
  workspace: {
    id: true,
    name: true,
    createdBy: {
      id: true,
      name: true,
      email: true
    },
    owner: {
      id: true,
      name: true,
      email: true
    },
    status: true,
    billingContact: {
      id: true,
      name: true,
      email: true
    }
  },
  paymentDate: true,
  createdAt: true,
  createdBy: {
    id: true,
    firstName: true,
    lastName: true
  }
};

export function getPayments(args) {
  const query = {
    query: {
      payments: {
        __args: args,
        ...DEFAULT_PAYMENTS_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getPayment(args) {
  const query = {
    query: {
      payment: {
        __args: args,
        ...DEFAULT_PAYMENT_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updatePayment(args) {
  const query = {
    mutation: {
      updatePayment: {
        __args: args,
        ...DEFAULT_PAYMENT_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function recordPayment(args) {
  const query = {
    mutation: {
      recordPayment: {
        __args: args,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
