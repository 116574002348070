import React, { Component } from "react";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'

import {getInitials} from 'utils/common'
import Card from "app/components/common/Detail/Card";
import { routeMapping } from "app/constants/routes";

class Owner extends Component {
  onRedirectToUser = (id, name) => {
    this.props.history.push(`${routeMapping.VISO_USER.path}?id=${id}&name=${name}`)
  }

  renderContent = () => {
    const { color } = this.props;
    const {workspace} = this.props
    const name = workspace.owner && workspace.owner.name && workspace.owner.name.replace(/\s+/g, ' ')
    const firstName = (name && name.split(' ')[0]) || '-'
    const lastName = (name && name.split(' ')[1]) || '-'

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            <tr className="data-table__row" onClick={() => 
              this.onRedirectToUser(
                workspace.owner && workspace.owner.id,
                workspace.owner && `${workspace.owner.name}`,
                )}>
              <td className="data-table__td data-table__td_1">
                <div className={`checkbox checkbox_${color}`}>
                  <div className="checkbox__image">
                    {getInitials(firstName, lastName)}
                  </div>
                </div>
              </td>
              <td className={`data-table__td ${color}`}>{workspace.owner && workspace.owner.name}</td>
              <td className="data-table__td">{workspace.owner && workspace.owner.email}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <Card
        noGrid
        color={this.props.color}
        title='Workspace Owner'
        subTitle="The user that is the owner of the workspace."
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

Owner.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default withRouter(Owner);
