const filterColumns = [
{
  id: 1,
  label: 'Status',
  field: 'status',
  type: 'radio',
},
{
  id: 2,
  label: 'Plan Name',
  field: 'name',
  type: 'search',
},
{
  id: 4,
  label: 'Mode',
  field: 'billingPeriod',
  type: 'checkbox',
},
{
  id: 5,
  label: 'Category',
  field: 'category',
  type: 'checkbox',
},
// {
//   id: 6,
//   label: 'Subscriptions',
//   field: 'workspaceCount',
//   type: 'singleRange',
// },
{
  id: 7,
  label: 'Created',
  field: 'createdAt',
  type: 'date',
}]

export default filterColumns