import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from 'classnames'

import messages from "utils/message";
import Card from "app/components/common/Detail/Card";
import { dateFormatter } from "utils/common";
import {countries} from 'utils/countries'

class Workspace extends Component {
  getStatus = (status) => {
    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === 'active',
        "data-table__status_neg": status === 'inactive',
        "data-table__status_neutral": status === 'deleted',
      }
    );

    return <div className={className}>{status}</div>
  };

  getAnnotationStatus = () => {
    const { annotationEnabled } = this.props.workspace;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": annotationEnabled,
        "data-table__status_neg": !annotationEnabled,
      }
    );
    return <div className={className}><span className="text-capitalize">{annotationEnabled ? 'Active' : 'Inactive'}</span></div>
  };

  getTrainingStatus = () => {
    const { trainingEnabled } = this.props.workspace;

    const className = classNames(
      "data-table__status type__value",
      {
        "data-table__status_pos": trainingEnabled,
        "data-table__status_neg": !trainingEnabled,
      }
    );
    return <div className={className}><span className="text-capitalize">{trainingEnabled ? 'Active' : 'Inactive'}</span></div>
  };


  renderContent = () => {
    const {workspace} = this.props
    return (
      <>
        <div className="grid-col">
          <div className="overview__title" />
          <div className="overview__label">
            <FormattedMessage {...messages.company} />
          </div>
          <div className="overview__value">{workspace.organizationName}</div>
          {/* <div className="overview__label">
            <FormattedMessage {...messages.address1} />
          </div>
          <div className="overview__value">{workspace.billingInformation && workspace.billingInformation.address1}</div>
          <div className="overview__label">
            <FormattedMessage {...messages.city} />
          </div>
          <div className="overview__value">
          <div className="overview__value">{workspace.billingInformation && workspace.billingInformation.city}</div>
          </div> */}
        </div>
        <div className="grid-col">
        <div className="overview__label">
            <FormattedMessage {...messages.country} />
          </div>
          <div className="overview__value">
          <div className="overview__value">{workspace.billingInformation && 
          countries.find(country => country.value === workspace.billingInformation.country) &&
          countries.find(country => country.value === workspace.billingInformation.country).label}</div>
          </div>
          {/* <div className="overview__label">
          <FormattedMessage {...messages.address2} />
          </div>
          <div className="overview__value">{workspace.deleteDate && dateFormatter(workspace.deleteDate, true)}</div>
          <div className="overview__label">
          <FormattedMessage {...messages.postCode} />
          </div>
          <div className="overview__value">
          <div className="overview__value">{workspace.billingInformation && workspace.billingInformation.postCode}</div>
          </div> */}
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.billingInformation} />}
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

Workspace.propTypes = {
  onEdit: PropTypes.func,
  color: PropTypes.string,
  workspace: PropTypes.object,
};

export default Workspace;
