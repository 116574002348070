import React from "react";
import { Link, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Scrollbars } from "react-custom-scrollbars";
import {
  MachineLearningModel,
  Layers,
  Settings,
  AssemblyCluster,
  List,
  AddAlt,
  Power,
} from "@carbon/react/icons";
import { ChevronsDown, ChevronsUp } from "react-feather";
import { getInitials } from "utils/common";
import { FilePlus, Users, PlusCircle, Briefcase } from "react-feather";
import { connect } from "react-redux";
import { updateLeftPanelStatus } from "../actions/ui";
import classNames from "classnames";
import {
  RightCevronIcon,
  SettingIcon,
  ModelIcon,
  SubscriptionIcon,
  InvoicesIcon,
  PaymentIcon,
} from "images/common";
import Accordion from "app/components/Accordion";

// Routes displayed in the left panel.
const navLinks = [
  {
    icon: <Users strokeWidth={1.5} />,
    id: "viso-user",
    link: "/users",
    class: `primary-nav__link_accent0`,
    name: "Viso Users",
  },
  {
    icon: <Layers />,
    id: "workspace",
    link: "/workspaces",
    class: `primary-nav__link_accent0`,
    name: "Workspaces",
  },
  {
    icon: <Briefcase strokeWidth={1.5}/>,
    id: "partners",
    link: "/partners",
    class: `primary-nav__link_accent1`,
    name: "Partners",
  },
];

const navLinks1 = [
  {
    icon: <FilePlus strokeWidth={1.5}/>,
    id: "plans",
    link: "/plans",
    class: `primary-nav__link_accent2`,
    name: "Plans",
  },
  {
    icon: <InvoicesIcon />,
    id: "invoices",
    link: "/invoices",
    class: `primary-nav__link_accent0`,
    name: "Invoices",
  },
  {
    icon: <PaymentIcon />,
    id: "payments",
    link: "/payments",
    class: `primary-nav__link_accent0`,
    name: "Payments",
  },
];

const navLinks2 = [
  {
    icon: <MachineLearningModel />,
    id: "models",
    link: "/models",
    class: `primary-nav__link_accent2`,
    name: "Models",
  },
  {
    icon: <AddAlt />,
    id: "categories",
    link: "/categories",
    class: `primary-nav__link_accent3`,
    name: "Categories",
  },
  {
    icon: <List />,
    id: "listings",
    link: "/listing",
    class: `primary-nav__link_accent4`,
    name: "Listings",
  },
];

const navLinks3 = [
  {
    icon: <Users strokeWidth={1.5} />,
    id: "viso-staff",
    link: "/staff",
    class: `primary-nav__link_accent4`,
    name: "Viso Staff",
  },
  {
    icon: <Settings />,
    id: "settings",
    link: "/settings",
    class: `primary-nav__link_accent4`,
    name: "Settings",
  },
  {
    icon: <AssemblyCluster />,
    id: "settings_admin",
    link: "/admin",
    class: `primary-nav__link_accent4`,
    name: "Kubernetes Admin",
  },
];

class LeftPanel extends React.Component {
  state = {
    isActive: false,
    openAll: true,
    isChanged: false,
    showButtonBox: true
  };

  getActiveClass = (link, activeClass) => {
    const hash = this.props.location.pathname.slice(1);

    if (hash === "/" && link === "/") {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    if (link !== "/" && hash.includes(link.slice(1, link.length))) {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    return `primary-nav__link ${activeClass}`;
  };

  onExpandAll = () => {
    if (!this.state.isChanged) {
      this.setState({ isChanged: true });
    } else {
      this.setState({ isChanged: false });
    }
    this.setState({ openAll: true });
  };

  onCollapseAll = () => {
    if (!this.state.isChanged) {
      this.setState({ isChanged: true });
    } else {
      this.setState({ isChanged: false });
    }
    this.setState({ openAll: false });
  };

  getChildActiveClass = (link, activeClass) => {
    const hash = window.location.pathname;
    const { workspace } = this.props;

    // Activate sub header when header is selected.

    if (link !== "/" && hash.includes(link)) {
      return `primary-nav__sublink primary-nav__sublink_active ${activeClass}`;
    }

    return `primary-nav__sublink ${activeClass}`;
  };

  onLogout = () => {
    this.props.history.push("/logout");
  };

  handleScroll = (payload) => {
    this.setState({scrollHeight: payload.top})
    if (payload.top < 0.99) {
      this.setState({showButtonBox: true})
    } else {
      this.setState({showButtonBox: false})
    }

    if (payload.scrollTop !== 0) {
      this.setState({isScrolling: true})
      return this.setState({ scrollTop: payload.scrollTop });
    }

    this.setState({isScrolling: false});
    this.setState({ scrollTop: payload.scrollTop });
  };

  render() {
    const { isStatusBarOpen, userData } = this.props;
    const sidebarClass = classNames("sidebar", {
      collapsed: !isStatusBarOpen,
    });

    return (
      <>
        <div id="overlay" className="overlay" onClick={this.hideNav}></div>
        <aside id="sidebar" className={sidebarClass}>
          <div className={classNames("sidebar__header", {})} ref={this.navHead}>
            <div className="sidebar__head">
              {!isStatusBarOpen ? (
                <div className="sidebar__title h1 c-label">
                  Viso
                  <br />
                  <span className="sidebar__title_sec">Admin</span>
                </div>
              ) : (
                <div className="sidebar__title h1 c-label">
                  Viso<span className="sidebar__title_sec"> Admin</span>
                </div>
              )}
            </div>
            <nav
              className={classNames("workspace-nav", {})}
              data-cy="navbar-settings"
              onClick={this.toggleWorkspaceSelection}
            >
              <button type="button" className="workspace-nav__toggle h4">
                {isStatusBarOpen && (
                  <div
                    className="header mx-b-3 w-84"
                    // onClick={this.redirectToWorkspace}
                  >
                    {userData.user && userData.user.firstName} {userData.user && userData.user.lastName}
                  </div>
                )}
                {isStatusBarOpen && (
                  <div
                    className="h5 c-label sub-header w-84"
                    // onClick={this.redirectToUserPage}
                  >
                    Viso Staff
                  </div>
                )}
                <div className="workspace-nav__icon">{userData.user && getInitials(userData.user.firstName, userData.user.lastName)}</div>
              </button>
            </nav>
          </div>
          <div ref={this.navStaticHeight} className={classNames('primary-nav-wrapper', {
            'primary-nav-wrapper_scroll': this.state.isScrolling
          })}>
            <Scrollbars
              universal
              style={{ width: "100%" }}
              autoHide
              autoHideDuration={1}
              onScrollFrame={this.handleScroll}
              renderThumbVertical={props => <div {...props} className="" />}
            >
              <div id="nav-fixed-height">
                <nav className="primary-nav primary-nav-container">
                  <Accordion
                    noLine
                    // alwaysOpen={isStatusBarOpen}
                    isOpen={this.state.openAll}
                    isChanged={this.state.isChanged}
                    title="ACCOUNTS"
                    description={
                      <>
                    <div className="nav-item">
                        {navLinks.map((row) => (
                          <div key={row.name}>
                            <Link
                              to={row.link}
                              key={row.name}
                              className={this.getActiveClass(
                                row.link,
                                row.class
                              )}
                            >
                              {row.icon}
                              {row.name}
                            </Link>
                            {row.children && (
                              <div className="primary-nav__dropdown">
                                {row.children.map((child) => (
                                  <Link
                                    key={child.name}
                                    className={this.getChildActiveClass(
                                      child.link,
                                      child.class
                                    )}
                                    to={child.link}
                                  >
                                    {child.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                        </div>
                      </>
                    }
                  />
                  <Accordion
                    noLine
                    // alwaysOpen={isStatusBarOpen}
                    isOpen={this.state.openAll}
                    isChanged={this.state.isChanged}
                    title="BILLING"
                    description={
                      <>
                      <div className="nav-item">
                        {navLinks1.map((row) => (
                          <div key={row.name}>
                            <Link
                              to={row.link}
                              key={row.name}
                              className={this.getActiveClass(
                                row.link,
                                row.class
                              )}
                            >
                              {row.icon}
                              {row.name}
                            </Link>
                            {row.children && (
                              <div className="primary-nav__dropdown">
                                {row.children.map((child) => (
                                  <Link
                                    key={child.name}
                                    className={this.getChildActiveClass(
                                      child.link,
                                      child.class
                                    )}
                                    to={child.link}
                                  >
                                    {child.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      </>
                    }
                  />
                  <Accordion
                    noLine
                    // alwaysOpen={isStatusBarOpen}
                    isOpen={this.state.openAll}
                    isChanged={this.state.isChanged}
                    title="VISO HUB"
                    description={
                      <>
                    <div className="nav-item">
                        {navLinks2.map((row) => (
                          <div key={row.name}>
                            <Link
                              to={row.link}
                              key={row.name}
                              className={this.getActiveClass(
                                row.link,
                                row.class
                              )}
                            >
                              {row.icon}
                              {row.name}
                            </Link>
                            {row.children && (
                              <div className="primary-nav__dropdown">
                                {row.children.map((child) => (
                                  <Link
                                    key={child.name}
                                    className={this.getChildActiveClass(
                                      child.link,
                                      child.class
                                    )}
                                    to={child.link}
                                  >
                                    {child.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                        </div>
                      </>
                    }
                  />
                  <Accordion
                    noLine
                    // alwaysOpen={isStatusBarOpen}
                    isOpen={this.state.openAll}
                    isChanged={this.state.isChanged}
                    title="OPERATIONS"
                    description={
                      <>
                    <div className="nav-item">
                        {navLinks3.map((row) => (
                          <div key={row.name}>
                            <Link
                              to={row.link}
                              key={row.name}
                              className={this.getActiveClass(
                                row.link,
                                row.class
                              )}
                            >
                              {row.icon}
                              {row.name}
                            </Link>
                            {row.children && (
                              <div className="primary-nav__dropdown">
                                {row.children.map((child) => (
                                  <Link
                                    key={child.name}
                                    className={this.getChildActiveClass(
                                      child.link,
                                      child.class
                                    )}
                                    to={child.link}
                                  >
                                    {child.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                        </div>
                      </>
                    }
                  />
                </nav>
                <span
                    className="primary-nav__link cursor primary-nav__button"
                    onClick={this.onLogout}
                  >
                    <Power />
                    Logout
                  </span>
                <div className="primary-nav primary-nav_bottom">
                  <div className="nav-footer nav-footer-lg">
                    <button
                      type="button"
                      className="sidebar__toggle"
                      onClick={() =>
                        this.props.updateLeftPanelStatus(!isStatusBarOpen)
                      }
                    >
                      <RightCevronIcon />
                      Collapse
                    </button>
                    {isStatusBarOpen && (
                      <div className="nav-footer-menu">
                        <div type="button" onClick={this.onExpandAll}>
                          <ChevronsDown />
                        </div>
                        <div onClick={this.onCollapseAll} className="">
                          <ChevronsUp />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Scrollbars>
            <div className={classNames('', {
              'primary-nav-scroll': this.state.showButtonBox
            })}/>
          </div>
        </aside>
      </>
    );
  }
}

LeftPanel.propTypes = {};

function mapStateToProps(state) {
  return {
    isStatusBarOpen: state.ui.isStatusBarOpen,
    userData: state.user.userData
  };
}

const withConnect = connect(mapStateToProps, { updateLeftPanelStatus });

export default withRouter(withConnect(LeftPanel));
