import React, { Component } from "react";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import classNames from "classnames";
import moment from "moment";
import getSymbolFromCurrency from 'currency-symbol-map'
import NumberFormat from 'react-number-format';

import { FilePlus, HistoryIcon, DownloadIcon } from "images/common";
import Card from "app/components/common/Detail/Card";
import ViewHistory from '../../list/invoices'
import { dateFormatter } from "utils/common";

class Invoice extends Component {
  state = {
    openModal: false,
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  
  openEditModal = () => {
    this.setState({ showEditModal: true })
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false })
  }
  
  closeModal = () => {
    this.setState({ openModal: false });
  };

  renderContent = () => {
    const { showEditModal } = this.state
    const { color, invoices = [], allInvoices } = this.props

    if (showEditModal) {
      return (
        <ViewHistory
          onClose={this.closeEditModal}
          color={color}
          workspace={this.props.workspace}
          invoices={invoices}
          allInvoices={allInvoices}
          getInvoicesRequest={this.props.getInvoicesRequest}
          workspaceName={
            this.props.workspace && this.props.workspace.name
          }/>
      )
    }

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {invoices &&
            invoices
              .filter((item, index) => index < 3)
              .map(invoice => (
                <tr className={`data-table__row card_${color}`} key={invoice.number} onClick={this.openEditModal}>
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <HistoryIcon/>
                      </div>
                    </div>
                  </td>
                  <td className={`data-table__td ${color}`}>{dateFormatter(invoice.created * 1000)}</td>
                  <td className="data-table__td ">{invoice.number}</td>
                  <NumberFormat
                    value={(invoice.amount_due && parseFloat(invoice.amount_due / 100).toFixed(2)) || '0.00'}
                    displayType={'text'}
                    thousandSeparator renderText={value =>
                      <td className="data-table__td">
                        {invoice.currency && getSymbolFromCurrency(invoice.currency)}{value}</td>
                    }/>
                  <td className="data-table__td data-table__td_1">
                    <a download href={invoice.invoice_pdf} className="invoice_download" target='_blank' rel="noopener noreferrer">
                      <div className="data-table__icon">
                        <DownloadIcon/>
                      </div>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    return (
      <Card
        noGrid
        isAccentAction
        action={this.openEditModal}
        color={this.props.color}
        title={'Invoices'}
        className="flow-modules"
        customText='View'
        content={this.renderContent()}
      />
    )
  }
}

export default withRouter(Invoice);
