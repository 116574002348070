import React, { Component } from 'react'
import PropTypes from 'prop-types'
import xorBy from 'lodash/xorBy'

import ManageColumns from './ManageColumns'
import AddColumns from './AddColumns'

const SHOW_MANAGE = 'SHOW_MANAGE_COLUMNS'
const SHOW_ADD = 'SHOW_ADD_COLUMNS'

class Dropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: props.columns,
      showMenu: SHOW_MANAGE
    }
  }

  onColumnRemove = (key) => {
    const columns = this.state.columns.map(col => {
      if (col.sortKey === key) {
        return { ...col, isVisible: false }
      }

      return col
    })

    this.setState({ columns })
  }

  onConfirm = () => {
    this.props.onChangeConfirm(this.state.columns)
    this.props.toggleDropdown()
  }

  getManageClassName = () => {
    const { showMenu } = this.state

    if (showMenu === SHOW_ADD) {
      return 'manage-columns__slide_left'
    }
  }

  getAddClassName = () => {
    const { showMenu } = this.state

    if (showMenu === SHOW_MANAGE) {
      return 'manage-columns__slide_right'
    }
  }

  onAddColumns = (columns) => {
    const newColumns = this.state.columns
      .map(column => {
        const newCol = columns.find(col => col.sortKey === column.sortKey)
        if (newCol) {
          return newCol
        }

        return column
      })

    this.setState(
      { columns: newColumns },
      () => this.props.onChangeConfirm(this.state.columns)
    );
    this.changeMenu(SHOW_MANAGE);
  }

  changeMenu = (showMenu) => {
    this.setState({ showMenu });
  }

  onOrderChange = (columns) => {
    const nonVisibleColumns = xorBy(columns, this.state.columns, 'sortKey')

    this.setState({ columns: columns.concat(nonVisibleColumns) })
  }

  getManageColumnsHeight = () => {
    const { showMenu } = this.state
    if (showMenu === SHOW_MANAGE) {
      const visibleColumns = this.state.columns
        .filter(col => (col.isVisible || col.isLocked))

      if (visibleColumns.length === this.state.columns.length) {
      // header + footer + sum of columns height
        return 70 + 64 + visibleColumns.length * 48 + 'px'
      }

      // header + footer + add new columns + sum of columns height
      return 70 + 64 + 64 + visibleColumns.length * 48 + 'px'
    }

    const removedColumnns = this.state.columns
      .filter(col => (!col.isVisible && !col.isLocked))

    // header + footer + removed columns sum
    return 70 + 64 + removedColumnns.length * 48 + 'px'
  }

  render() {
    return (
      <div className="manage-columns__dropdown manage-columns__dropdown_visible">
        <ManageColumns
          onConfirm={this.onConfirm}
          columns={this.state.columns}
          toggleDropdown={this.props.toggleDropdown}
          onRemove={this.onColumnRemove}
          containerClassName={this.getManageClassName()}
          showAddColumns={() => this.changeMenu(SHOW_ADD)}
          onOrderChange={this.onOrderChange}
          containerHeight={this.getManageColumnsHeight()}
        />
        <AddColumns
          columns={this.state.columns}
          onConfirm={this.onAddColumns}
          containerClassName={this.getAddClassName()}
          showMenu={this.state.showMenu}
        />
      </div>
    )
  }
}

Dropdown.propTypes = {
  columns: PropTypes.array,
  onChangeConfirm: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired
}

export default Dropdown
