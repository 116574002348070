import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import ListScroll from "app/components/common/ListScroll";
import LoadingIndicator from "app/components/common/LoadingIndicator";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import SingleMenu from "./SingleMenu";
import CustomInput from "app/components/common/Input/CustomInput";
import ProcessPage from "app/components/common/ProcessPage";
import EditForm from "app/components/common/Edit/EditForm";
import { SOLUTION_ACCENT } from "app/constants/common";
import { createPlanAction, listPlans } from "app/actions/plans";
import {
  getVersionInfo,
  updateVersionSettings,
  createVisoSetting,
} from "app/api/users";
import { defaultNotifier } from "functions/notificationHandler";
import { SettingIcon } from "images/common";
import Card from "app/components/common/Detail/Card";

import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: [],
    };
  }

  render() {
    const { color } = this.props;

    if (this.state.isLoading) {
      return <LoadingIndicator color={this.props.color} />;
    }

    return (
      <ListScroll>
        {(scrollParent) => (
          <>
            <div className="headline">
              <div className="headline__icon admin_workspace__image accent4">
                <SettingIcon />
              </div>
              <h1 className="headline__title">Kubernetes Admin</h1>
            </div>
            <div className={`secondary-nav secondary-nav_${color}`}>
              
              <Link to="/admin" className="secondary-nav__link secondary-nav__link_active">
                Kubernetes Admin
              </Link>
            </div>
            <div>
              IFrame here
            </div>
          </>
        )}
      </ListScroll>
    );
  }
}

Settings.propTypes = {
  color: PropTypes.string,
};

Settings.defaultProps = {
  color: SOLUTION_ACCENT,
};

const withConnect = connect(null, {
  createPlanAction,
  listPlans,
});

export default compose(withRouter, withConnect)(Settings);
