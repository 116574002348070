import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { orderBy } from "lodash";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";
import Pagination from "app/components/Pagination";
import NotFound from "app/components/NotFound";

import { defaultNotifier } from 'functions/notificationHandler'
import SearchField from "app/components/common/SingleSearch";
import Card from "./Card";
import List from "./Table";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import Modal from "app/components/common/Modal";
import DeleteWorkspace from "../DeleteWorkspace";
import messages from "utils/message";
import NoWorkspaces from "../../common/NoItem";
import { routeMapping } from "app/constants/routes";
import { isSmallerDevice } from "utils/common";
import LoadingIndicator from "app/components/common/LoadingIndicator";

import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ACCENT,
} from "app/constants/common";
import {
  listPayment
} from "app/actions/payments";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";

import ListSelectedItems from "../../common/ListSelectedItems";
import { XCircleIcon } from "images/common";
import { dateFormatter } from "utils/common";
import AddRecord from "../recordPayment/AddRecord";
import { EnumType } from 'json-to-graphql-query';

class Payments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      currentPage: 1,
      selectedIds: [],
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "createdAt",
        direction: "desc",
      },
      limit: DEFAULT_PAGE_SIZE,
      filter: {
        single: [],
        range: [],
      },
      filteredWorkspace: [],
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onSort = (sort) => {
    this.setState({sort}, () => {
      this.getPayments();
    })
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: DEFAULT_PAGE_SIZE,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    this.setCurrentPage(1);

    const finalResult = getOutput(
      filterData,
      this.props.payments.data
    );
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.id.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filteredWorkspace: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: DEFAULT_PAGE_SIZE,
      },
      () => this.setState({ filteredWorkspace: this.props.payments.data })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  componentDidMount() {
    this.getPayments();
  
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  getPayments = () => {
    const {sort, limit} = this.state;

    this.setState({ showDeleteModal: false });
    this.props.listPayment({limit: limit, offset: this.state.offset, sort: [{field: sort.key, type: new EnumType(sort.direction.toUpperCase())}]});
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  renderModal = () => {
    const { body, onConfirm, onCancel, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        disabled={this.state.fetching}
        onClick={() => {
          if(!this.state.fetching) {
            onConfirm();
            this.onCloseModal();
          }
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={onCancel}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={onCancel} />
    );
  };

  toggleCreatePopup = () => {
    this.setState({ showCreatePopup: !this.state.showCreatePopup });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  getScrollRef = (ref) => {
    this.scrollParent = ref;
  };

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onRowClick = (id) => {
    return this.props.history.push(`${routeMapping.PAYMENTS.path}/${id}`);
  };

  addModule = () => {
    this.props.history.push(routeMapping.IMPORT_MODULE.path);
  };

  onRedirectToArchivePage = () => {
    this.props.history.push("/payments?isArchived=true");
  };

  onCloseShowArchived = () => {
    this.setState({ showOnlyArchived: false });
    this.props.history.push(routeMapping.WORKSPACES.path);
  };

  onManageAgent = (ws, e) => {
    this.props.history.push(`/workspace/${ws.id}/agents/${ws.name}`);
  };

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }


  // pagination 
  onChangeLimit = (offset) => {
    this.setState({ currentPage: 1, limit: offset, offset: 0 }, () => this.getPayments());
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
    if(currentPage !== this.state.currentPage) {
      this.setState({offset: (currentPage - 1) * this.state.limit}, () => this.getPayments())
    }
  };

  onShowRecordPage = () => {
    this.setState({showRecordPage: true})
  }

  onHideRecordPage = () => {
    this.setState({showRecordPage: false})
  }

  render() {
    const { color, payments = [] } = this.props;

    const {
      searchName,
      showModal,
      showOnlyArchived,
      activeView,
      selectedIds,
      resetFilter,
      currentPage
    } = this.state;

    if (this.state.fetching) {
      return (
        <LoadingIndicator
          color={this.props.colorClassName}
        />
      );
    }

    const viewHeadlineClass = classNames(
      "actions-nav__icon actions-nav__icon_cards cursor",
      {
        "actions-nav__icon_active": activeView === CARD_VIEW,
        [color]: color,
      }
    );

    const viewListClass = classNames(
      "actions-nav__icon actions-nav__icon_rows cursor",
      {
        "actions-nav__icon_active": activeView === TABLE_VIEW,
        [color]: color,
      }
    );

    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    // if (
    //   !this.state.searchName &&
    //   !this.state.filter.single.length &&
    //   !this.state.filter.range.length &&
    //   !(this.props.invoices && this.props.invoices.count)
    // ) {
    //   return (
    //     <NotFound
    //       color={color}
    //       strokeWidth="1"
    //       logo={'P'}
    //       title="Start deploying your Apps"
    //       description="Using profiles, you’ll be able to deploy your applications to
    //    devices. Create profiles to manage deployments."
    //       buttonText="Create first profile"
    //       onAction={this.props.onCreate}
    //     />
    //   );
    // }

    if(this.state.showRecordPage) {
      return <AddRecord onCancel={this.onHideRecordPage}/>
    }

    return (
      <>
        {showModal && this.renderModal()}
        <ListScroll>
          {(scrollParent) => (
            <>
              <div className="headline">
                <h1 className="headline__title">
                  <FormattedMessage {...messages.payments} />
                </h1>
              </div>
              <div className="actions-nav">
                <div className="actions">
                  <VerticalButtonMenu customClass={color}></VerticalButtonMenu>
                </div>
                <span
                  className={viewListClass}
                  onClick={() => this.updateView(TABLE_VIEW)}
                ></span>
                <span
                  className={viewHeadlineClass}
                  onClick={() => this.updateView(CARD_VIEW)}
                ></span>
                <span
                  className={`actions-nav__link cursor label-color`}
                  onClick={this.onShowRecordPage}
                >
                  Record New
                </span>
              </div>

              <div className="filters-bar">
                <ListSelectedItems
                  selectedNumber={selectedIds.length}
                  search={this.state.searchName}
                  onReset={this.onReset}
                  filterData={this.state.filter}
                  containerClassName={color}
                />
                <Filter
                  customClass={color}
                  resetFilter={resetFilter}
                  onChange={this.onFilter}
                  columns={filterColumns}
                  data={this.props.payments && this.props.payments.data}
                  defaultFilter={[{
                    field: "paymentMethod",
                    value: "All",
                    isDefault: true,
                    columns: ['Bank', 'Credit Card'],
                  }]}
                />
                {showOnlyArchived && (
                  <div
                    className={classNames(
                      "filters-bar__item",
                      this.props.color
                    )}
                  >
                    <button type="button" className="filters-bar__button">
                      {searchName} Show archived
                      <div
                        className="filters-bar__remove"
                        onClick={this.onCloseShowArchived}
                      >
                        <XCircleIcon />
                      </div>
                    </button>
                  </div>
                )}
                <SearchField
                  onSearch={this.onSearch}
                  customClass={color}
                  resetSearch={this.state.resetFilter}
                  label="Search"
                />
              </div>
              <div>
                <InfiniteScroll
                  initialLoad={false}
                  useWindow={false}
                  getScrollParent={() => scrollParent}
                >
                  {activeView === TABLE_VIEW && (
                    <>
                    <List
                        data={
                          hasFilter
                            ? this.state.filteredWorkspace
                            : this.props.payments.data
                        }
                      onRowClick={this.onRowClick}
                      selectAll={this.selectAll}
                      onSort={this.onSort}
                      color={color}
                      isLoading={this.props.isLoading}
                      sort={this.state.sort}
                      isSelectedAll={this.getIsSelectedAll()}
                      selectedIds={selectedIds}
                      onSelect={this.onSelect}
                      onManageAgent={this.onManageAgent}
                      onDelete={this.onDeleteWorkspace}
                      onCancelSchedule={this.onCancelSchedule}
                      onDeactivate={this.onDeactiveWorkspace}
                      onActivate={this.onActivateWorkspace}
                      onScheduleDelete={this.onScheduleDeleteWorkspace}
                      onActivateAnnotation={this.onActivateAnnotation}
                      onDeactivateAnnotation={this.onDeactivateAnnotation}
                      onActivateTraining={this.onActivateTraining}
                      onDeactivateTraining={this.onDeactivateTraining}
                      // onUpdate={this.onUpdateWorkspace}
                    />
                      </>
                  )}
                  {activeView === CARD_VIEW && (
                    <div className="data-cards">
                      <Card
                        color={color}
                        data={
                          hasFilter
                            ? this.state.filteredWorkspace
                            : this.props.payments.data
                        }
                        onRowClick={this.onRowClick}
                        selectedItems={selectedIds}
                        onSelect={this.onSelect}
                        isLoading={this.props.isLoading}
                        onManageAgent={this.onManageAgent}
                        onDeactivate={this.onDeactiveWorkspace}
                        onActivate={this.onActivateWorkspace}
                        onCancelSchedule={this.onCancelSchedule}
                        onScheduleDelete={this.onScheduleDeleteWorkspace}
                        onUpdate={this.onUpdateWorkspace}
                        onDelete={this.onDeleteWorkspace}
                        onActivateAnnotation={this.onActivateAnnotation}
                        onDeactivateAnnotation={this.onDeactivateAnnotation}
                        onActivateTraining={this.onActivateTraining}
                        onDeactivateTraining={this.onDeactivateTraining}
                      />
                    </div>
                  )}
                    {((hasFilter && !!this.state.filteredWorkspace.length) ||
                      (!hasFilter && !!this.props.payments.count)) &&
                      <Pagination
                        paymentPage
                        currentPage={this.state.currentPage}
                        setCurrentPage={this.setCurrentPage}
                        onChangeLimit={this.onChangeLimit}
                        limit={this.state.offset}
                        totalPage={(hasFilter && !this.state.isSorted) ? this.state.filteredWorkspace.length : this.props.payments.count}
                      />
                    }
                </InfiniteScroll>
                {activeView === CARD_VIEW &&
                  payments &&
                  !this.props.isLoading &&
                  !payments.count && <NoWorkspaces />}
              </div>
              <div className="foot-nav">
                    <button
                      onClick={this.onShowRecordPage}
                      className={classNames(
                        'round-button round-button_br round-button_primary round-button_plus',
                        {
                          [`round-button_${color}`]: color,
                        },
                      )}
                    >
                      <span className="round-button__label">
                        Record new
                      </span>
                    </button>
                  </div>
            </>
          )}
        </ListScroll>
      </>
    );
  }
}

Payments.propTypes = {
  color: PropTypes.string,
  payments: PropTypes.array,
  activeView: PropTypes.string,
};

Payments.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    deviceSizeType: state.ui.device,
    payments: state.payments.list,
    isLoading: state.payments.isLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {
  listPayment
});

export default compose(withRouter, withConnect)(Payments);
