import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SortableTh from './SortableTh'
import ColumnSelector from './ColumnSelector'

const TYPE_CHECKBOX = 'checkbox'
const TYPE_COLUMN_SELECT = 'column-select'

export default class TableHead extends Component {
  renderTh = (column, index) => {
    const key = column.sortKey || index

    if (column.type === TYPE_CHECKBOX) {
      return (
        <th className='data-table__th data-table__th_1' key={key}>
          {!this.props.hideCheckbox && (
            <div
              className={classNames('checkbox', this.props.selectAllClass, {
                [`checkbox_${this.props.colorClassName}`]: this.props
                  .colorClassName
              })}
            >
              <input
                onChange={this.props.selectAll}
                type='checkbox'
                className='checkbox__input'
                checked={this.props.isSelectedAll}
              />
              <div className='checkbox__check' />
            </div>
          )}
        </th>
      )
    }

    if (column.type === TYPE_COLUMN_SELECT) {
      return (
        <th className='data-table__th data-table__th_1' key={key}>
          <ColumnSelector
            columns={this.props.columns}
            onChange={this.props.onColumnsChange}
          />
        </th>
      )
    }

    if (!column.isVisible) {
      return null
    }

    if (column.isSortable) {
      return (
        <SortableTh
          onUpdateSort={this.props.onUpdateSort}
          currentSortDir={this.props.currentSortDir}
          currentSortKey={this.props.currentSortKey}
          key={key}
          {...column}
        />
      )
    }

    return (
      <th className={classNames('data-table__th', column.className)} key={key}>
        {column.label}
      </th>
    )
  }

  render() {
    const columns = [...this.props.columns]

    if (this.props.isSelectable) {
      columns.unshift({ type: TYPE_CHECKBOX })
    }

    if (this.props.withColumnSelect) {
      columns.push({ type: TYPE_COLUMN_SELECT })
    }

    return (
      <thead className='data-table__head'>
        <tr>{columns.map((column, index) => this.renderTh(column, index))}</tr>
      </thead>
    )
  }
}

TableHead.propTypes = {
  columns: PropTypes.array,
  inputType: PropTypes.string,
  onUpdateSort: PropTypes.func.isRequired,
  currentSortKey: PropTypes.string,
  currentSortDir: PropTypes.string,
  isSelectable: PropTypes.bool,
  selectAllClass: PropTypes.string,
  selectAll: PropTypes.func,
  isSelectedAll: PropTypes.bool,
  colorClassName: PropTypes.string,
  withColumnSelect: PropTypes.bool,
  onColumnsChange: PropTypes.func
}
