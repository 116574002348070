import {sortBy} from 'lodash'

import {
  getPayments,
  getPayment
} from "../api/payment";
import { payments } from "../constants/payments";

import { defaultNotifier } from "functions/notificationHandler";

export function listPayment(payload) {
  return async (dispatch) => {
    dispatch({ type: payments.GET_PAYMENTS_PENDING });

    try {
      const { data } = await getPayments(payload);

      if (data.errors) {
        defaultNotifier(data.errors);
        dispatch({
          type: payments.GET_PAYMENTS_REJECTED,
          error: {
            message: "Error while fetching payments.",
          },
        });
      }

      const list = sortBy(data.data.payments.data, [
        'createdAt',
      ]).reverse();

      dispatch({
        type: payments.GET_PAYMENTS_FULFILLED,
        payload: {
          data: list,
          count: data.data.payments.count
        },
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the payments");

      dispatch({
        type: payments.GET_PAYMENTS_REJECTED,
        error: {
          message: "Error while fetching payments.",
        },
      });
    }
  };
}

export function getPaymentAction(payload) {
  return async (dispatch) => {
    dispatch({ type: payments.GET_PAYMENT_DETAILS_PENDING });

    try {
      const { data } = await getPayment(payload);

      if (data.errors) {
        defaultNotifier(data.errors);
      }

      dispatch({
        type: payments.GET_PAYMENT_DETAILS_FULFILLED,
        payload: data.data.payment,
      });
    } catch (err) {
      defaultNotifier("Failed to fetch the payments");
    }
  };
}
