import {ui} from '../constants/ui';

const INITIAL_STATE = {
  isStatusBarOpen: true,
  device: 'desktop',
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ui.UPDATE_DEVICE_TYPE:
      return {
        ...state,
        device: action.payload
      }
    case ui.TOGGLE_LEFT_PANEL:
      return {
        ...state,
        isStatusBarOpen: action.payload
      };
    default:
      return state;
  }
}