import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from 'moment';

import { withRouter, Link } from "react-router-dom";
import ProcessPage from "app/components/common/ProcessPage";
import EditForm from "app/components/common/Edit/EditForm";
import { SOLUTION_ACCENT } from "app/constants/common";
import { createPlanAction, listPlans } from "app/actions/plans";
import { clipboard, toolbarOptions } from "utils/common";
import Switch from "app/components/common/Input/Switch";
import CustomSelect from "app/components/common/Input/CustomSelect";
import Card from "app/components/common/Detail/Card";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-datetime/css/react-datetime.css";
import { announcement, updateAnnouncement } from 'app/api/workspaces'
import { defaultNotifier } from "functions/notificationHandler";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const getAccent = [
  {
    label: "Main accent",
    value: "accent0",
  },
  {
    label: "Alert color",
    value: "warning",
  },
  {
    label: "Success Color",
    value: "success",
  },
];

class Settings extends Component {
  constructor(props) {
    super(props);
    const nowDate = moment(new Date()).format("dddd, Do MMMM HH:mm");

    this.state = {
      settings: [],
      color: "accent0",
      active: false,
      canClose: true,
      forAdmin: true,
      start: new Date(),
      end: new Date(),
      dateInfo: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'dateInfo'
      },
      message: `Due to planned maintenance the workspace will be unavailable from ${nowDate} to ${nowDate} CET.`
    };
  }

  onFieldChange = (e, name) => {
    this.setState({ [name]: e.target.value })
  }


  convertUTCToLocalDate = (date) => {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
    return date
  }
  
  convertLocalToUTCDate= (date) => {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
    return date
  }

  onChangeEnd = () => {

  }
  onSubmit = async () => {
    const {
      active,
      start,
      end,
      color,
      message,
      forAdmin, canClose,
    } = this.state;

    const result = {
      active: active,
      start: moment.utc(start).format(),
      end: moment.utc(end).format(),
      message: message,
      color: color || 'accent0',
      forAdmin: forAdmin,
      canClose: canClose
    }

    const { data } = await updateAnnouncement(result);

    if (data.errors) {
      return defaultNotifier('Failed to update the announcement.')
    }

    defaultNotifier('Announcement updated.')
  }

  async componentDidMount() {
    const { data } = await announcement()

    if (data.errors) {
      return defaultNotifier("Failed to fetch announcement details")
    }

    this.setState({
      currentAnnouncement: data.data.announcement.active,
      active: data.data.announcement.active,
      message: data.data.announcement.message,
      forAdmin: data.data.announcement.forAdmin || false,
      canClose: data.data.announcement.canClose || false,
      color: data.data.announcement.color
    })

    if(data.data.announcement.start) {
      this.setState({start: new Date(data.data.announcement.start) })
    }

    if(data.data.announcement.end) {
      this.setState({start: new Date(data.data.announcement.end) })
    }
  }

  onChangeField = (name, value) => {
    const data = this.state.settings.map((setting) => {
      if (setting.name === name) {
        return { name: setting.name, value: value };
      }

      return setting;
    });

    this.setState({ [name]: value, settings: data });
  };

  onUpdateField = (name, value) => {
    this.setState({ [name]: value });
  };

  onUpdateFilter = (item) => {
    this.setState({ dateInfo: item.dateInfo })
  }

  getStatusName = () => {
    const { start, end, active } = this.state;

    const currentDate = moment(new Date());
    const startDate = moment(start);
    const endDate = moment(end);

    if (currentDate.isAfter(endDate) || !active) {
      return 'Inactive'
    }

    if (currentDate.isBefore(startDate)) {
      return 'Scheduled'
    }

    if(currentDate.isBetween(startDate, endDate)) {
      return 'Active'
    }

    return 'Inactive'
  }

  getStatus = () => {
    const status = this.getStatusName()

    const className = classNames(
      "data-table__status type__value text-captilize",
      {
        "data-table__status_pos": status === "Active",
        "data-table__status_pos": status === "Scheduled",
        "data-table__status_neg": status === "Inactive",
      }
    )

    return <div className={className}>{status}</div>

  };

  onChangeStart = (startDate) => {
    
    const {end} = this.state;

    this.setState({ 'start': startDate})
  }

  onChangeEnd = (endDate) => {
    this.setState({ 'end': endDate })
  }

  renderAnnouncement = () => {
    const { color } = this.props;
    const { previousEnabled, end, start } = this.state;
    // const starDate = moment(this.state.dateInfo.startDate).format("Do MMMM HH:mm");
    // const endDate = moment(this.state.dateInfo.endDate).format("Do MMMM HH:mm");
    const startDate = moment(new Date()).format("dddd, Do MMMM HH:mm");
    const endDate = moment(new Date()).format("dddd, Do MMMM HH:mm");

    return (
      <>
        <h5 className="label-color mx-b-5">Current status</h5>
        <h4>{this.getStatus(previousEnabled ? 'active' : 'inactive')}</h4>
        <div className="setting-switch">
          <h5 className="label-color mx-b-10">Settings</h5>
          <div className="switch-wrapper">
            <Switch
              color={"accent4"}
              onChange={this.onChangeSwitch}
              checked={this.state.active}
              name="active"
              text="Enable announcement"
            />
          </div>
          <div className="switch-wrapper">
            <Switch
              color={"accent4"}
              checked={this.state.canClose}
              onChange={this.onChangeSwitch}
              name="canClose"
              text="Enable close button"
            />
          </div>
          <div className="switch-wrapper">
            <Switch
              color={"accent4"}
              checked={this.state.forAdmin}
              onChange={this.onChangeSwitch}
              name="forAdmin"
              text="Visible only to admin/owner"
            />
          </div>
        </div>
        <div className={`text-field_${color}`}>
          <CustomSelect
            value={this.state.color}
            label="Color theme"
            onChange={this.onChangeColor}
            options={getAccent}
          />
        </div>
        <div className="grid-row">
          <div className="grid-col">
            <div className="'text-field">
              <div className="custom_label">Show from (UTC)</div>
              <DatePicker
                selected={this.convertUTCToLocalDate(start)}
                showTimeSelect
                utcOffset={0}
                minDate={moment().toDate()}
                dateFormat="do MMMM yyyy H:mm"
                onChange={date => this.onChangeStart(this.convertLocalToUTCDate(date))}
              />
              <div className="text-field__underscore"></div>
            </div>
          </div>
          <div className="grid-col">
            <div className="'text-field">
              <div className="custom_label">Show until (UTC)</div>
              <DatePicker
                selected={this.convertUTCToLocalDate(end)}
                showTimeSelect
                utcOffset={0}
                
                minDate={start}
                dateFormat="do MMMM yyyy H:mm"
                onChange={date => this.onChangeEnd(this.convertLocalToUTCDate(date))}
              />
              <div className="text-field__underscore"></div>
            </div>
          </div>
        </div>

        <h5 className="label-color mx-b-5">Message</h5>
        <div className={classNames("text-editor", {})}>
          <ReactQuill
            modules={{
              toolbar: toolbarOptions,
              clipboard: clipboard,
            }}
            formats={["bold", "italic"]}
            theme="snow"
            // value={`${this.state.message} from ${startDate} to ${endDate}`}
            value={this.state.message}
            onChange={this.onChangeEditor}
          />
        </div>
      </>
    );
  };

  onChangeSwitch = (name, value) => {
    this.setState({ [name]: value });
  };

  onChangeColor = (value) => {
    this.setState({ color: value });
  };

  onChangeEditor = (message) => this.setState({ message });

  render() {
    const { color } = this.props;
    const { showEditModal, updateCatalogModal, showAnnoucementPage } =
      this.state;

    const submitClass = classNames(`button button_${color}`, {
      // button_disabled: !name,
    });

    if (showAnnoucementPage) {
      return (
        <ProcessPage
          onCancel={() => this.setState({ showAnnoucementPage: false })}
        >
          {() => (
            <EditForm
              color={this.props.color}
              submitLabel="Save"
              title="Announcement Banner"
              subTitle={<div>The announcement banner appears at the top of the page and can be used to <br />communicate upgrades, downtime, etc.</div>}
              submitClassName={submitClass}
              onSubmit={this.onSubmit}
              content={this.renderAnnouncement()}
            />
          )}
        </ProcessPage>
      );
    }

    return (
      <Card
        color={this.props.color}
        title="Annoucement"
        fullWidth
        className="overview"
        customText={"Update"}
        action={() => this.setState({ showAnnoucementPage: true })}
      />
    );
  }
}

Settings.propTypes = {
  color: PropTypes.string,
};

Settings.defaultProps = {
  color: SOLUTION_ACCENT,
};

const withConnect = connect(null, {
  createPlanAction,
  listPlans,
});

export default compose(withRouter, withConnect)(Settings);
