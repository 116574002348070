import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SingleMenu from "./SingleMenu";
import { dateFormatter } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";
import TableTags from "app/components/common/TableTags";
import {convertDefaultStatusToStatus} from 'utils/common'

class CategoryRow extends PureComponent {
  onRedirectToListing = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.history.push(`/listing/${id}`);
  };

  getStatus = () => {
    const { status, currentSortKey } = this.props;

    const statusClass = classNames(
      "data-table__status text-capitalize text-captilize",
      {
        "data-table__status_pos": status === "approved",
        "data-table__status_warning":
          status === "pending",
        "data-table__status_neg": status === "rejected" || status === "draft",
        "data-table__td_dark": currentSortKey === "status",
      }
    );

    return <div className={statusClass}>{convertDefaultStatusToStatus(status)}</div>;
  };

  getRowRender = () => {
    const {
      updatedAt,
      id,
      workspace,
      workspaceId,
      keywords,
      createdAt,
      colorClassName,
      currentSortKey,
    } = this.props;

    const updatedAtClass = classNames({
      "data-table__td_dark": currentSortKey === "updatedAt",
    });

    const createdAtClass = classNames({
      "data-table__td_dark": currentSortKey === "createdAt",
    });

    const idClass = classNames('text-capitalize', {
      "data-table__td_dark": currentSortKey === "id",
    });

    const nameClass = classNames({
      "data-table__td_dark": currentSortKey === "workspace.name",
    });

    const partnerClass = classNames('text-link cursor', {
      [colorClassName]: colorClassName,
    });

    return {
      'workspace.name': () => <div className={nameClass}>{workspace && workspace.name}</div>,
      id: () => <span className={idClass}>{id}</span>,
      status: () => this.getStatus(),
      partner: () => (
        <span
          className={partnerClass}
          onClick={(e) => this.onRedirectToListing(id, e)}
        >
          {workspaceId}
        </span>
      ),
      tags: () =>
        keywords && (
          <TableTags tags={keywords} colorClassName={colorClassName} />
        ),
      updatedAt: () => (
        <div className={updatedAtClass}>{dateFormatter(updatedAt, true)}</div>
      ),
      createdAt: () => (
        <div className={createdAtClass}>{dateFormatter(createdAt, true)}</div>
      ),
    };
  };

  render() {
    const {
      colorClassName,
      id,
      status,
    } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <SingleMenu
            id={id}
            status={status}
            onReject={this.props.onReject}
            onApprove={this.props.onApprove}
            onActivate={this.props.onActivate}
            onDeactivate={this.props.onDeactivate}
          />
        </SortableRow>
      </>
    );
  }
}

CategoryRow.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(CategoryRow));
