import { routeMapping } from "app/constants/routes";

import Logout from "app/components/Logout";
import Workspaces from "app/components/workspaces/list";
import WorkspaceBilling from "app/components/workspaces/billing";
import WorkspaceSubscription from "app/components/workspaces/subscription";
import SubscribedWorkspace from "app/components/plans/subscription";
import WorkspaceBillEstiamte from "app/components/workspaces/billEstimate";
import WorkspaceDetail from "app/components/workspaces/details/index";
import CreatePlan from "app/components/plans/details/Create/Information";
import CreateModel from "app/components/model/Create/AddModel";
import CreateCategory from "app/components/category/details/Create/Information";

import Plans from 'app/components/plans/list'
import Settings from 'app/components/settings/Settings'
import SettingsKubernetes from 'app/components/settings/Kubernetes'
import Agents from 'app/components/workspaces/manageAgents'
import Category from 'app/components/category/list'
import Models from 'app/components/model/list'
import Model from 'app/components/model/details'
import Listing from 'app/components/listing/list'
import Partner from 'app/components/partner/list'
import Users from 'app/components/users/list'
import VisoUsers from 'app/components/visoUsers/list'
import PlanDetails from 'app/components/plans/details'
import ListingDetails from 'app/components/listing/details'
import SolutionPreview from 'app/components/listing/details/SolutionPreview'
import PartnerDetails from 'app/components/partner/details'
import CategoryDetails from 'app/components/category/details'
import Subscriptions from "app/components/subscriptions/list";
import SubscriptionDetails from "app/components/subscriptions/details";
import Invoices from "app/components/invoices/list";
import InvoiceDetails from "app/components/invoices/details";
import Payments from "app/components/payment/list";
import PaymentDetails from "app/components/payment/details";

const routes = [
  {
    path: routeMapping.SUBSCRIPTIONS.path,
    exact: true,
    name: "Subscriptions list",
    component: Subscriptions,
  },
  {
    path: routeMapping.INVOICES.path,
    exact: true,
    name: "Subscriptions",
    component: Invoices,
  },
  {
    path: routeMapping.INVOICE_DETAILS.path,
    exact: true,
    name: "Invoice Detail",
    component: InvoiceDetails,
  },
  {
    path: routeMapping.PAYMENTS.path,
    exact: true,
    name: "Payments",
    component: Payments,
  },
  {
    path: routeMapping.PAYMENT_DETAILS.path,
    exact: true,
    name: "Payment Details",
    component: PaymentDetails,
  },
  {
    path: routeMapping.PLANS.path,
    exact: true,
    name: "Base Plans",
    component: Plans,
  },
  {
    path: routeMapping.CREATE_MODEL.path,
    exact: true,
    name: "Create Model",
    component: CreateModel,
  },
  {
    path: routeMapping.MODELS.path,
    exact: true,
    name: "Models",
    component: Models,
  },
  {
    path: routeMapping.MODEL.path,
    exact: true,
    name: "Model",
    component: Model,
  },
  {
    path: routeMapping.CATEGORY.path,
    exact: true,
    name: "Categories",
    component: Category,
  },
  {
    path: routeMapping.PARTNER.path,
    exact: true,
    name: "Partners",
    component: Partner,
  },
  {
    path: routeMapping.LISTING.path,
    exact: true,
    name: "Listings",
    component: Listing,
  },
  {
    path: routeMapping.SETTINGS.path,
    exact: true,
    name: "Setting",
    component: Settings,
  },
  {
    path: routeMapping.WORKSPACE_AGENTS.path,
    exact: true,
    name: "Agents",
    component: Agents,
  },
  {
    path: routeMapping.USERS.path,
    exact: true,
    name: "Users",
    component: Users,
  },
  {
    path: routeMapping.VISO_USER.path,
    exact: true,
    name: "VisoUsers",
    component: VisoUsers,
  },
  {
    path: routeMapping.CREATE_PLAN.path,
    exact: true,
    name: "Create Plan",
    component: CreatePlan,
  },
  {
    path: routeMapping.CREATE_CATEGORY.path,
    exact: true,
    name: "Create Category",
    component: CreateCategory,
  },

  {
    exact: true,
    path: routeMapping.PLAN_DETAILS.path,
    name: "Plan Detail",
    component: PlanDetails,
  },
  {
    exact: true,
    path: routeMapping.CATEGORY_DETAILS.path,
    name: "Category Detail",
    component: CategoryDetails,
  },
  {
    exact: true,
    path: routeMapping.SUBSCRIPTION_DETAILS.path,
    name: "Subscription Details",
    component: SubscriptionDetails,
  },
  {
    exact: true,
    path: routeMapping.LISTING_DETAILS.path,
    name: "Listing Detail",
    component: ListingDetails,
  },
  {
    path: `${routeMapping.LISTING_DETAILS.path}/preview`,
    name: "Listing Detail",
    component: SolutionPreview,
  },
  {
    exact: true,
    path: routeMapping.PARTNER_DETAILS.path,
    name: "Partner Detail",
    component: PartnerDetails,
  },
  {
    path: routeMapping.WORKSPACE_BILLING.path,
    exact: true,
    name: "Workspace Billing",
    component: WorkspaceBilling,
  },
  {
    path: routeMapping.WORKSPACE_ESTIMATE.path,
    exact: true,
    name: "Workspace Estimate",
    component: WorkspaceBillEstiamte,
  },
  {
    path: routeMapping.WORKSPACE_SUBSCRIPTION.path,
    exact: true,
    name: "Workspace Subscription",
    component: WorkspaceSubscription,
  },
  {
    exact: true,
    path: routeMapping.WORKSPACE_DETAILS.path,
    name: "Workspace",
    component: WorkspaceDetail,
  },
    {
    exact: true,
    path: routeMapping.SUBSCRIBED_WORKSPACE_DETAILS.path,
    name: "Subscribed workspace",
    component: SubscribedWorkspace,
  },
  {
    path: routeMapping.WORKSPACES.path,
    exact: true,
    name: "Workspaces",
    component: Workspaces,
  },
  {
    path: routeMapping.LOGOUT.path,
    name: "Logout",
    component: Logout,
  },
  {
    path: `/admin`,
    name: "Kubernetes Admin",
    component: SettingsKubernetes,
  },
  {
    path: routeMapping.SETTINGS.path,
    name: "Agent",
    component: Settings,
  }
];

export { routeMapping, routes };
