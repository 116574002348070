import React from 'react';
import PropTypes from 'prop-types';

export const SingleMenu = ({
  id,
  onDelete
}) => {

  return (
    <>
        <div className="actions__dropdown actions__dropdown_visible">
          <span 
            onClick={e => onDelete(id, e)}
          
          className='actions__option'>
        Delete
      </span>
        </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any
};

export default SingleMenu;
