import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class CustomInput extends React.PureComponent {
  state = {
    showError: false
  }

  onBlur = () => {
    this.setState({showError: true})

    if(this.props.onBlur) {
      this.props.onBlur()
    }
  }

  onFocus = () => {
    if(this.props.onFocus) {
      this.props.onFocus()
    }
  }

  componentDidMount() {
    this.setState({value: this.props.value === 0 ? '0.0' : this.props.value})
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      return this.setState({value: nextProps.value || ''})
    }
  }
  
  onChange = (e) => {
    const {aplhaNumericOnly} = this.props

    const text = e.currentTarget.value
    const regex = /^[a-z0-9-_]+$/i
    const isValid = text.match(regex)

    if (!text) {
      this.props.onChange(e)
    }

    if (isValid) {
      this.setState({value: e.currentTarget.value})
      return this.props.onChange(e)
    }

    if (!aplhaNumericOnly) {
      this.setState({value: e.currentTarget.value})

      return this.props.onChange(e)
    }
  }

  render() {
    const {showError, value} = this.state
    const {parentClass, postFix, maxLength, postIcon, postIconClass, min} = this.props

    const inputClass = classNames({
      'text-field__input': true,
      'text-field__input_filled': !!value || !!this.props.defaultValue,
      'text-field_readonly': this.props.readOnly,
      'text-field__input_disabled': this.props.disabled
    })

    const parentCustomClass = classNames('text-field ', {
      'text-field_invalid': this.props.isInvalid && showError,
      [`text-field_${parentClass}`]: this.props.parentClass
    })

    return (
      <div className={parentCustomClass}>
        <input
          {...this.props.input}
          type={this.props.type || 'text'}
          className={inputClass}
          maxLength={maxLength}
          min={min}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          defaultValue={this.props.defaultValue}
          name={this.props.name}
          value={value}
        />
        <label className="text-field__label">{this.props.label}</label>
        <div className="text-field__underscore"></div>
        {(showError && this.props.hintText) && <div className="text-field__hint text-field__hint_r">{ this.props.hintText }</div> }
        {postFix && <div className="text-field__postfix">{postFix}</div>}
        {postIcon && <div className={`text-field__postIcon ${postIconClass}`}>{postIcon}</div>}
      </div>
    )
  }
}

CustomInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  parentClass: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hintText: PropTypes.string,
  isInvalid: PropTypes.any
}
