export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount'
export const DAEMON = '@@saga-injector/daemon'
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount'

export const COLOR_BASE = '#2184F9'
export const COLOR_ACC1 = '#845EC2'
export const COLOR_ACC2 = '#D65DB1'
export const COLOR_ACC3 = '#FF6F91'
export const COLOR_ACC4 = '#FF9671'
export const COLOR_C2_TEXT = '#8B959E'
export const COLOR_C1_TEXT = '#444444'

export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_SHORTENED_DESCRIPTION_COUNT = 100
export const AUTOSAVE_WAIT_INTERVAL = 500
export const DEFAULT_SHORTENED_TEXT_COUNT = 35
export const SNACKBAR_TIMEOUT = 6000
export const REFETCH_IMAGE_LIST = 300000
export const APP_AUTO_RELOAD = 30000

export const LANGUAGES = [{value: 'english', label: 'English'}, {value: 'german', label: 'Deutsch (German)'}]
export const STATUS = [{value: 'active', label: 'Active'}, {value: 'invited', label: 'Invited'}, {value: 'inactive', label: 'Inactive'}]

export const CARD_VIEW = 'card'
export const TABLE_VIEW = 'list'

export const UUID_LENGTH = 37;

export const DEFAULT_ACCENT = 'accent0'
export const PRIMARY_ACCENT = 'primary'
export const LIBRARY_ACCENT = 'accent3'
export const SOLUTION_ACCENT = 'accent4'
export const DASHBOARD_ACCENT_COLOR = 'accent2'

export const roleLevel = {
  OWNER_ROLE: 10,
  ADMIN_ROLE: 20,
  MEMBER_ROLE: 30
}
