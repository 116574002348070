import React from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { routes } from "routes";

const Breadcrumb = ({ children, customColor, workspace, breadcrumbClass, className }) => (
  <div className={classNames(`breadcrumbs breadcrumbs_${customColor}`, {
    [breadcrumbClass]: breadcrumbClass,
    [className]: className
  })}>
    {React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        workspace,
        isLast: index === children.length - 1,
      })
    })}
  </div>
)

export const BreadcrumbItem = ({ route, isLast }) => {
  const selectedRoute = routes.find(routeDetail => routeDetail.name === route) || route


  if (!selectedRoute && !isLast) {
    return <span />
  }

  return isLast ? (
    <span className="breadcrumbs__link cursor">{route}</span>
  ) : (
      <>
        <Link className="breadcrumbs__link" to={selectedRoute.path}>{route}</Link>
        <div className="breadcrumbs__arrow"></div>
      </>
  )
}


BreadcrumbItem.propTypes = {
  route: PropTypes.string,
  isLast: PropTypes.bool
}

Breadcrumb.propTypes = {
  children: PropTypes.node,
  breadcrumbClass: PropTypes.string
}

export default Breadcrumb