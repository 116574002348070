import { jsonToGraphQLQuery } from "json-to-graphql-query";

import axiosObj from "config/config";
import { BASE_URL } from "app/constants/common";

const ANNOUNCEMENT = {
  active: true,
  canClose: true,
  forAdmin: true,
  color: true,
  start: true,
  end: true,
  message: true
}

const DEFAULT_WORKSPACE_FIELDS = {
  id: true,
  name: true,
  organizationName: true,
  organizationAddress: true,
  organizationSize: true,
  organizationIndustry: true,
  subDomain: true,
  logo: true,
  deleteDate: true,
  defaultLanguage: true,
  createdAt: true,
  owner: {
    id: true,
    name: true,
    email: true,
  },
  status: true,
  customerId: true,
  subscriptions: true,
  billingInformation: {
    name: true,
    country: true,
    address1: true,
    address2: true,
    city: true,
    postCode: true,
  },
  subscriptionQuantity: true,
  userExperiences1: true,
  userExperiences2: true,
  annotationEnabled: true,
  datasetEnabled: true,
  trainingEnabled: true,
  paymentMethod: true,
  kilBillInfo: {
    accountId: true,
    accountURL: true,
    billCycleDayLocal: true,
    currency: true,
    notes: true,
    accountCBA: true,
    accountBalance: true
  },
  availablePaymentMethods: {
    bank: true,
    creditCard: true,
  },
  paymentTerms: true,
  autoTrainingEnabled: true,
  incidentsEnabled: true,
  dashboardsEnabled: true
};

const DEFAULT_WORKSPACES_FIELDS = {
  id: true,
  name: true,
  status: true,
  createdAt: true,
  owner: {
    id: true,
    name: true,
    email: true,
  },
  partner: {
    id: true,
    status: true,
    createdAt: true,
  },
  annotationEnabled: true,
  trainingEnabled: true,
  datasetEnabled: true,
  visoAgentVersion: true,
  autoTrainingEnabled: true,
  incidentsEnabled: true,
  dashboardsEnabled: true
};

const DEFAULT_VISO_AGENTS = {
  id: true,
  deviceType: true,
  architecture: true,
  visoAgentVersion: true,
  buildStatus: true,
  buildingVisoAgentVersion: true,
  osVersion: true,
  createdAt: true,
  updatedAt: true,
  deviceCount: true
};

const DEFAULT_CVS = {
  template: true,
  status: true,
};

export function getWorkspaces(args) {
  const query = {
    query: {
      workspaces: {
        __args: args,
        ...DEFAULT_WORKSPACES_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getWorkspace(payload) {
  const query = {
    query: {
      workspace: {
        __args: payload,
        ...DEFAULT_WORKSPACE_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function scheduleDeleteWorkspace(param) {
  const query = {
    mutation: {
      scheduleWorkspaceDelete: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function reactivateWorkspace(param) {
  const query = {
    mutation: {
      reactivateWorkspace: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteWorkspace(param) {
  const query = {
    mutation: {
      deleteWorkspace: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function archiveWorkspace(param) {
  const query = {
    mutation: {
      archiveWorkspace: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function activateWorkspace(param) {
  const query = {
    mutation: {
      activateWorkspace: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deactivateWorkspace(param) {
  const query = {
    mutation: {
      deactivateWorkspace: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function getVisoAgents(payload) {
  const query = {
    query: {
      visoAgents: {
        __args: payload,
        ...DEFAULT_VISO_AGENTS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateVisoAgentVersion(param) {
  const query = {
    mutation: {
      updateVisoAgentVersion: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateWorkspaceAnnotationSetting(param) {
  const query = {
    mutation: {
      updateWorkspaceAnnotationSetting: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateWorkspaceTrainingSetting(param) {
  const query = {
    mutation: {
      updateWorkspaceTrainingSetting: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateWorkspaceDatasetSetting(param) {
  const query = {
    mutation: {
      updateWorkspaceDatasetSetting: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function cvEnvs(payload) {
  const query = {
    query: {
      cvEnvs: {
        __args: payload,
        ...DEFAULT_CVS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function cancelSubscriptionPlan(param) {
  const query = {
    mutation: {
      cancelSubscriptionPlan: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function addSubscriptionPlan(param) {
  const query = {
    mutation: {
      addSubscriptionPlan: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function changeSubscriptionPlan(param) {
  const query = {
    mutation: {
      changeSubscriptionPlan: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
export function updateWorkspace(param) {
  const query = {
    mutation: {
      updateWorkspace: {
        __args: param,
        id: true
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function addCredit(param) {
  const query = {
    mutation: {
      addCredit: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function addCharge(param) {
  const query = {
    mutation: {
      createCharge: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateAnnouncement(param) {
  const query = {
    mutation: {
      updateAnnouncement: {
        __args: param,
        ...ANNOUNCEMENT
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function announcement() {
  const query = {
    query: {
      announcement: {
        ...ANNOUNCEMENT
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function activateAutoTraining(param) {
  const query = {
    mutation: {
      activateAutoTraining: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function activateIncidents(param) {
  const query = {
    mutation: {
      activateIncidents: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function activateDashboards(param) {
  const query = {
    mutation: {
      activateDashboards: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function handleStagingEnvironment(param) {
  const query = {
    mutation: {
      handleStagingEnvironment: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

