import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { isEqual } from "lodash";

import { getDuration } from "utils/common";
import ProcessPage from "app/components/common/ProcessPage";
import CustomInput from "app/components/common/Input/CustomInput";
import CustomTextarea from "app/components/common/Input/CustomTextarea";

import Tags from "app/components/common/Tags";
import appMessages from "utils/message";

import EditForm from "app/components/common/Edit/EditForm";
import { updatePlanAction } from "app/actions/plans";

class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.plan.prettyName || "",
      id: props.plan.id || "",
      page: "first",
      tags: props.plan.tags || [],
      description: props.plan.description || "",
    };
  }

  onBack = () => {
    this.setState({ page: "first" });
  };

  onNext = () => {
    this.setState({ page: "second" });
  };

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  onChangeField = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmitApplication = () => {
    this.props.onClose();
  };

  renderContent = () => {
    const { color, plan: {prettyName} } = this.props;
    const { description } = this.state;

    return (
      <>
        <div className={`text-field text-field_${color}`}>
          <CustomInput
            value={prettyName}
            disabled
            readOnly
            onChange={(e) => this.onChangeField("name", e.target.value)}
            label={<FormattedMessage {...appMessages.planName} />}
            isInvalid={!prettyName}
            postIconClass="success"
            hintText={prettyName ? "" : "Name is required"}
          />
        </div>
        <div className={`text-field text-field_${color}`}>
          <CustomTextarea
            value={description}
            onChange={(e) => this.onChangeField("description", e.target.value)}
            label={<FormattedMessage {...appMessages.planDescription} />}
            type="textarea"
          />
        </div>
        <Tags
          tags={this.state.tags}
          tagClass={color}
          onChange={this.onAddTags}
        />
      </>
    );
  };

  onEdit = async (data) => {
    const { tags, description } = this.state;
    const { id } = this.props.plan;

    const payload = {
      id,
      tags,
      description,
    };

    try {
      await this.props.updatePlanAction(payload);
      this.props.onClose();
    } catch (err) {}
    this.props.onClose();
  };

  render() {
    const { plan, color } = this.props;

    const { prettyName, description, tags, name } = this.state;
    const hasChange =
      plan.description !== description ||
      !isEqual((plan && plan.tags) || [], tags);

    const submitClass = classNames(`button button_${color}`, {
      button_disabled: !hasChange,
    });

    return (
      <ProcessPage
        onCancel={this.props.onClose}
        isChanged={hasChange}
        showUnsavedWarning
      >
        {() => (
          <EditForm
            color={this.props.color}
            submitLabel={<FormattedMessage {...appMessages.continue} />}
            submitClassName={submitClass}
            onSubmit={this.onEdit}
            content={this.renderContent()}
            title="Plan Information"
            subTitle={
              <>
                {plan.prettyName} • Modified {getDuration(plan.updatedAt)}
              </>
            }
          />
        )}
      </ProcessPage>
    );
  }
}

const withConnect = connect(null, {
  updatePlanAction,
});

Information.propTypes = {
  color: PropTypes.string,
  plan: PropTypes.object,
  onClose: PropTypes.func,
  history: PropTypes.object,
  updateapplicationError: PropTypes.func,
  updateApplicationRequest: PropTypes.func,
};

export default withConnect(withRouter(Information));
