import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const SingleMenu = ({
  id, closeMenu, model = {}, onUnassign }) => {
  const unassign = classnames('actions__option', {
  });

  const onUnassignNode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    closeMenu();
    onUnassign(model.id, id)
  }

  return (
      <>
        <a
          href="javascript:void(0)"
          onClick={onUnassignNode}
          className={unassign}
        >
          Unassign
        </a>
      </>
  )
};

SingleMenu.propTypes = {
  profile: PropTypes.any
};

export default SingleMenu;
