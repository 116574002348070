const validate = (values) => {
  const errors = {};

  if (!values.tiers || !values.tiers.length) {
  } else {
    const planArrayErrors = [];
    let firstUnit = "";
    let secondUnit = "";

    values.tiers.forEach((plan, index) => {
      if (values.tiers.length - 1 !== index) {
        firstUnit = values.tiers[index]['up_to'];
        secondUnit = values.tiers[index + 1]['up_to'];
      }

      const memberErrors = {};
      if (parseFloat(secondUnit, 10) <= parseFloat(firstUnit, 10)) {
        memberErrors['up_to'] = "Value too high";
        planArrayErrors[index] = memberErrors;
      }

      if (!plan || !plan['unit_amount']) {
        memberErrors['unit_amount'] = "Required";
        planArrayErrors[index] = memberErrors;
      }

      if ((!plan || !plan['up_to']) && (values.tiers.length -1 !== index)) {
        memberErrors['up_to'] = "Required";
        planArrayErrors[index] = memberErrors;
      }

      return memberErrors;
    });
    if (planArrayErrors.length) {
      errors.tiers = planArrayErrors
    }
  }

  return errors;
};

export default validate;
