import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SingleMenu from "./SingleMenu";
import Status from "app/components/Status";
import { dateFormatter } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";
import CategoryTags from "app/components/common/CategoryTags";

class CategoryRow extends PureComponent {
  onRedirectToCategory = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.history.push(`/category/${id}`);
  };

  getRowRender = () => {
    const {
      createdAt,
      description,
      name,
      id,
      slug,
      status,
      parents,
      colorClassName,
      currentSortKey,
    } = this.props;

    const craetedAtClass = classNames({
      "data-table__td_dark": currentSortKey === "createdAt",
    });

    const nameClass = classNames({
      "data-table__td_dark": currentSortKey === "name",
    });

    const idClass = classNames("text-capitalize", {
      "data-table__td_dark": currentSortKey === "id",
    });

    const slugClass = classNames("text-capitalize", {
      "data-table__td_dark": currentSortKey === "slug",
    });

    const descriptionClass = classNames("text-capitalize", {
      "data-table__td_dark": currentSortKey === "description",
    });

    const statusClass = classNames("text-capitalize", {
      "data-table__td_dark": currentSortKey === "status",
    });

    const parentClass = classNames('category-tags', {
      [colorClassName]: colorClassName,
    });

    return {
      name: () => <div className={nameClass}>{name}</div>,
      id: () => <div className={idClass}>{id}</div>,
      slug: () => <div className={slugClass}>{slug}</div>,
      status: () => <Status type={status} status={status} customClass={statusClass} />,
      description: () => (
        <div className={descriptionClass}>{description}</div>
      ),
      provider: () => <div className="data-table__td_dark">viso.ai</div>,
      "parent.name": () =>
        <CategoryTags categories={parents} colorClassName={colorClassName} onAction={this.onRedirectToCategory}/>,
      createdAt: () => (
        <div className={craetedAtClass}>{dateFormatter(createdAt, true)}</div>
      ),
    };
  };

  render() {
    const {
      colorClassName,
      id,
      onDelete,
      status
    } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <SingleMenu
            id={id}
            onDelete={onDelete}
            status={status}
            onDeactivate={this.props.onDeactivate}
            onActivate={this.props.onActivate}
          />
        </SortableRow>
      </>
    );
  }
}

CategoryRow.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(CategoryRow));
