import {sortBy} from 'lodash'
import { users } from '../constants/users';

import { getAdminUsers, getVisoUsers} from '../api/users';
import { defaultNotifier } from 'functions/notificationHandler'

export function getAdminUserAction(payload) {
  return async (dispatch) => {
    dispatch({ type: users.GET_ADMIN_USERS_REQUEST });

    try {
      const {data} = await getAdminUsers(payload);
      const result = sortBy(data.data.adminUsers, ['email']);

      if (data.errors) {
        return defaultNotifier(data.errors)
      }

      dispatch({
        type: users.GET_ADMIN_USERS_SUCCESS,
        payload: result,
      });
      
    } catch (err) {
      defaultNotifier('Failed to fetch the users')

      dispatch({
        type: users.GET_ADMIN_USERS_REJECTED,
        error: {
          message: 'Error while fetching the users.',
        },
      });
    }
  };
}

export function getVisoUserAction(payload) {
  return async (dispatch) => {
    dispatch({ type: users.GET_VISO_USERS_REQUEST });

    try {
      const {data} = await getVisoUsers(payload);

      if (data.errors) {
        return defaultNotifier(data.errors)
      }

      const result = sortBy(data.data.users, ['email']);

      dispatch({
        type: users.GET_VISO_USERS_SUCCESS,
        payload: result
      });
      
    } catch (err) {
      defaultNotifier('Failed to fetch the users')

      dispatch({
        type: users.GET_ADMIN_USERS_REJECTED,
        error: {
          message: 'Error while fetching the users.',
        },
      });
    }
  };
}
