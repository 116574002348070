import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ActionMenu from 'app/components/common/ActionMenu';

export const SingleMenu = ({
  onDelete,
  closeMenu,
  id,
  name,
  status,
  onToogleMenu,
  trainingEnabled,
  annotationEnabled,
  hideParentClass,
  onDeactivate,
  onActivate,
  onActivateAnnotation,
  onDeactivateAnnotation,
  onActivateTraining,
  onDeactivateTraining,
  onManageAgent
}) => {
  const activeClass = classNames("actions__option", {
    actions__option_disabled: status === "active" || status === 'deleted',
    cursor: status !== 'active' && status !== 'deleted',
  });

  const deactiveClass = classNames("actions__option", {
    actions__option_disabled: status === 'inactive' || status === 'deleted',
    cursor: status !== 'inactive' && status !== 'deleted',
  });

  const removeClass = classNames("actions__option", {
    actions__option_disabled: status === 'active',
    cursor: status !== 'active',
  });

  const manageClass = classNames("actions__option", {
    actions__option_disabled: status === 'inactive' || status === 'deleted',
    cursor: status !== 'inactive' && status !== 'deleted',
  });

  const activeAnnotationClass = classNames("actions__option", {
    actions__option_disabled: annotationEnabled,
    cursor: !annotationEnabled
  });

  const deactiveAnnotationClass = classNames("actions__option", {
    actions__option_disabled: !annotationEnabled,
    cursor: annotationEnabled
  });

  const activeTrainingClass = classNames("actions__option", {
    actions__option_disabled: trainingEnabled,
    cursor: !trainingEnabled
  });

  const deactiveTrainingClass = classNames("actions__option", {
    actions__option_disabled: !trainingEnabled,
    cursor: trainingEnabled
  });

  const onDeleteMenu = (id, e, status) => {
    e.stopPropagation();
    if (status !== 'deleted' && status!== "inactive") {
      return false;
    }
    onToogleMenu(e, id)

    closeMenu();
    onDelete(id, e);
  };

  // const onCancelScheduleMenu = (id, e, status) => {
  //   e.stopPropagation();
  //   onToogleMenu(e, id)

  //   if (status !== 'archived') {
  //     return false;
  //   }
  //   closeMenu();
  //   onCancelSchedule(id, e, deleteDate);
  // };

  const onDeactivateMenu = (payload, e, status) => {
    e.stopPropagation();
    if (status === 'inactive' || status === 'deleted') {
      return false;
    }
    onToogleMenu(e, id)

    closeMenu();
    onDeactivate(payload, e);
  };

  const onManage = (payload, e, status) => {
    e.stopPropagation();
    if (status === 'inactive' || status === 'deleted') {
      return false;
    }
    onToogleMenu(e, id)

    closeMenu();
    onManageAgent(payload, e);
  };

  const onActivateMenu = (payload, e) => {
    e.stopPropagation();
    if (status === "active" || status === 'deleted') {
      return false;
    }
    onToogleMenu(e, id)
  
    closeMenu();
    onActivate(payload, e);
  };

  const onActivateAnnotationMenu = (e) => {
    e.stopPropagation();
    if (annotationEnabled) {
      return false;
    }
    onToogleMenu(e, id)
  
    closeMenu();
    onActivateAnnotation(id, e);
  };

  const onDeactivateAnnotationMenu = (e) => {
    e.stopPropagation();
    if (!annotationEnabled) {
      return false;
    }
    onToogleMenu(e, id)
  
    closeMenu();
    onDeactivateAnnotation(id, e);
  };

  const onActivateTrainingMenu = (e) => {
    e.stopPropagation();
    if (trainingEnabled) {
      return false;
    }
    onToogleMenu(e, id)
  
    closeMenu();
    onActivateTraining(id, e);
  };

  const onDeactivateTrainingMenu = (e) => {
    e.stopPropagation();
    if (!trainingEnabled) {
      return false;
    }
    onToogleMenu(e, id)
  
    closeMenu();
    onDeactivateTraining(id, e);
  };

  // const onScheduleDeleteMenu = (id, e, status) => {
  //   e.stopPropagation();

    
  //   if (status !== 'archived') {
  //     return false;
  //   }

  //   closeMenu();
  //   onScheduleDelete(id, e);
  // };

  const parentClass = classNames({
    'actions__dropdown actions__dropdown_visible': !hideParentClass
  });

  return (
    <>
      <div className={parentClass}>
      <ActionMenu
        className={manageClass}
        text={'Owned Workspaces'}
        onClick={(e) => onManage({ id, name }, e, status)}
      >
      </ActionMenu>
      <ActionMenu
        onClick={(e) => onDeactivateMenu({ id }, e, status)}
        className={deactiveClass}
        text="Deactivate Developer Plan"
      >
      </ActionMenu>
      <ActionMenu
        onClick={(e) => onActivateMenu({ id }, e, status)}
        className={activeClass}
        text="Activate Developer Plan"
      >
        </ActionMenu>
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
