import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = ({
  title,
  description,
  buttonText,
  logo,
  parentClass,
  onAction,
  color,
  secondaryText
}) => {
  return (
    <main className={`not-found__wrapper ${parentClass}`}>
      <div className="not-found__circle">{logo}</div>
      <div>
        <div className="not-found__text">{title}</div>
        <p className="not-found__description">{description}</p>
      </div>
      {buttonText &&
      <div className="not-found__button">
        <button className={`button_${color} button`} onClick={onAction}>
          {buttonText}
        </button>
      </div>
      }
      {secondaryText && (
        <div className="not-found__link">
          <Link to="/">{secondaryText}</Link>
        </div>
      )}
    </main>
  );
};

export default NotFound;
