// Revalidate (i.e. React) Validators

import { createValidator } from 'revalidate'

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i

export const isValidEmail = createValidator(
  message => value => {
    if (value && !EMAIL_REGEX.test(value)) {
      return message
    }
  },
  'Invalid email address'
)

export const isValidPassword = createValidator(
  message => value => {
    if (value && !PASSWORD_REGEX.test(value)) {
      return message
    }
  },
  'Password must be 8 characters, at least one letter and one number'
)
