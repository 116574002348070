import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ChevronRight } from 'react-feather'
import classNames from 'classnames'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import ColumnRow from './ColumnRow'

class ManageColumns extends Component {
  onDragEnd = (res) => {
    const {
      source: { index: sourceIndex },
      destination
    } = res

    const visibleColumns = this.props.columns
      .filter(col => (col.isVisible || col.isLocked))

    const destIndex = destination ?
      destination.index :
      visibleColumns.length - 1

    const removed = visibleColumns
      .find((col, index) => index === sourceIndex)
    const columns = visibleColumns
      .filter(col => col.sortKey !== removed.sortKey)
    columns.splice(destIndex, 0, removed)

    this.props.onOrderChange(columns)
  }

  render() {
    return (
      <div
        className={classNames('manage-columns__slide', this.props.containerClassName)}
        style={{ height: this.props.containerHeight }}
      >
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="manage-columns__heading">Manage Columns</div>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  this.props.columns
                    .filter(col => (col.isLocked || col.isVisible))
                    .map((col, index) =>
                      <ColumnRow
                        {...col}
                        key={col.sortKey}
                        onRemove={this.props.onRemove}
                        index={index}
                      />)
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {this.props.columns.length !== (this.props.columns.filter(columns => columns.isVisible)).length &&
          <div className="manage-columns__option manage-columns__option_add" onClick={this.props.showAddColumns}>
            Add new columns <ChevronRight className="manage-columns__right" />
          </div>
          }
          <div className="manage-columns__foot">
            <button
              className="manage-columns__confirm"
              onClick={this.props.onConfirm}
            >Confirm</button>
            <button
              className="manage-columns__cancel"
              onClick={this.props.toggleDropdown}
            >Cancel</button>
          </div>
        </DragDropContext>
      </div>
    )
  }
}

ManageColumns.propTypes = {
  columns: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  showAddColumns: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  containerHeight: PropTypes.string
}

export default ManageColumns
