import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import CustomInput from 'app/components/common/Input/CustomInput';
import Tags from "app/components/common/Tags";
import Button from "app/components/Button";
import { defaultNotifier } from "functions/notificationHandler";
import { FormattedMessage } from "react-intl";
import ProcessPage from "app/components/common/ProcessPage";
import { DEFAULT_ACCENT } from "utils/constants";
import { SingleDatePicker } from 'react-dates';
import classNames from 'classnames';
import appMessages from "utils/message";
import Card from "app/components/common/Detail/Card";
import CardEmpty from "app/components/common/Detail/CardEmpty";
import Plans from "./invoices";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FilePlus } from "images/common";
import CustomSelect from "app/components/common/Input/CustomSelect";
import {recordPayment} from 'app/api/payment';
import 'react-dates/lib/css/_datepicker.css';
import { routeMapping } from "routes";
import {dateFormatter, getInvoice} from 'utils/common';

const PAYMENT_MODE = [
  {
    label: "Bank Transfer",
    value: "1",
  },
  {
    label: "Credit Card",
    value: "2",
  },
];

class InviteUser extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    inviteUserRequest: PropTypes.func.isRequired,
    history: PropTypes.object,
    inviteUsers: PropTypes.object,
    errors: PropTypes.array,
  };

    
  onAddTags = tags => {
    this.setState({ tags});
  };

  constructor(props) {
    super(props);
    this.state = {
      showInvoiceList: false,
      selectedInvoice: [],
      email: null,
      name: "",
      tags: [],
      offset: 0,
      sort: {
        key: "name",
        direction: "asc",
      },
      date: moment(),
      expiration: "1",
      modal: this.props.onShow,
      errorMessage: "",
      loggedInUser: this.props.loggedInUser,
      usersList: [],
      creditCard: false,
      bank: false,
      paymentMethod: '1',
    };
  }

  onSelect = (id) => {
    return this.setState({ selectedRoleIds: id });
  };

  componentDidMount() {
    this.setState({amount: this.props.invoice.balance})
  }

  onChangeField = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  onCancel = () => {
    this.props.closeModal();
  };

  onUpdatePayment = (value) => {
    this.setState({paymentMethod: value})
  }

  onChange = (e, name) => {
    this.setState({[name]: e.target.value})
  }

  onRecord = async (id) => {
    const { invoice } = this.props;


      const paymentDate = (this.state.date && moment(this.state.date).format('YYYY-MM-DD')) || '';

      const {data} = await recordPayment({
        invoiceId: invoice.invoiceId,
        amount: this.state.amount,
        paymentMethod: parseInt(this.state.paymentMethod, 10),
        reference: this.state.reference || '',
        note: this.state.note || '',
        tags: this.state.tags,
        paymentDate: paymentDate || ''
      });

      if(data.errors) {
        return defaultNotifier('Error while adding the record')
      }
  
      if(data.data.recordPayment) {
        defaultNotifier(data.data.recordPayment)
        this.props.getInvoices();
        this.props.history.push(routeMapping.INVOICES.path)
        return this.props.onCancel()
      }
  }

  onChangeFloat = (e, name) => {
    this.setState({[name]: parseFloat(e.target.value, 10)})
  }
  
  renderContent = () => {
    const { color, invoice } = this.props;


    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
              <tr className="data-table__row">
                <td className="data-table__td data-table__td_1">
                  <div className={`checkbox checkbox_${color}`}>
                    <div className="checkbox__image">
                      IN
                    </div>
                  </div>
                </td>
                <td className={`data-table__td ${color}`}>{getInvoice(invoice.invoiceNumber)}</td>
                <td className={`data-table__td`}>Due {dateFormatter(invoice.targetDate)}</td>
              </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { color } = this.props;
    const submitClass = classNames(`button button_${color}`, {
    });

    return (
      <ProcessPage onCancel={this.props.onCancel}>
        {() => (
          <>
            <div className="popup__cont">
              <div className="login">
                <div className="headline">
                  <h1 className="headline__title">Record payment</h1>
                  <h3 className="headline__subtitle">
                    Add a new payment record
                  </h3>
                </div>

                <div className="login__form">
                <Card
                  noGrid
                  isLoading={this.props.isLoading}
                  color={this.props.color}
                  title={"Assign the payment to an Invoice"}
                  className="empty-box custom-box"
                  content={this.renderContent()}
                />
                  <div className="grid-row">
                <div className={`text-field_${color} grid-col`}>
                  <CustomInput
                    value={this.state.amount}
                    type='number'
                    step='1'
                    onChange={(e) => this.onChangeFloat(e, "amount")}
                    label="Amount"
                  />
                  </div>
                <div className={`text-field_${color} grid-col`}>
                  <CustomInput
                    disabled
                    value={'USD'}
                    onChange={() => null}
                    label="Currency"
                  />
                </div>
                  </div>
                  <CustomSelect
                    value={this.state.paymentMethod}
                    label="Payment Mode"
                    onChange={this.onUpdatePayment}
                    options={PAYMENT_MODE}
                  />
                <div className={`text-field_${color}`}>
                  <CustomInput
                    value={this.state.reference}
                    onChange={(e) => this.onChange(e, "reference")}
                    label="Reference"
                  />
                </div>
                <div className={`text-field text-field_${color}`}>
                  <CustomInput
                    value={this.state.note}
                    onChange={(e) => this.onChange(e, "note")}
                    label="Internal Note"
                  />
                </div>
                <div className={`text-field text-field_${color}`}>
                  <SingleDatePicker
                  isOutsideRange={() => false}
                  displayFormat={() => "DD MMMM YYYY"}
                  numberOfMonths={1} autoFocus
                  date={this.state.date} // momentPropTypes.momentObj or null
                  onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  id="your_unique_id" // PropTypes.string.isRequired,
                />
                </div>
                  <div className={`text-field text-field_${color}`}>
                  <Tags
                      tags={this.state.tags}
                      tagClass={'accent0'}
                      onChange={this.onAddTags}
                    />
                    </div>
                  <div className="login__actions">
                    <Button
                      type="button"
                      className={submitClass}
                      onClick={this.onRecord}
                    >
                      <FormattedMessage {...appMessages.continue} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

InviteUser.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    invoices: state.invoices.list,
  };
}

const withConnect = connect(mapStateToProps, null);

export default compose(withRouter, withConnect)(InviteUser);
