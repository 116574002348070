import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

import user from 'app/reducer/auth'
import ui from 'app/reducer/ui'
import workspaces from 'app/reducer/workspaces'
import invoices from 'app/reducer/invoices'
import payments from 'app/reducer/payments'
import plans from 'app/reducer/plans'
import users from 'app/reducer/users'
import billing from 'app/reducer/billing'
import category from 'app/reducer/category'
import listing from 'app/reducer/listing'
import partner from 'app/reducer/partner'
import model from 'app/reducer/model'

const rootReducer = combineReducers({
  user,
  ui,
  plans,
  users,
  listing,
  partner,
  model,
  category,
  billing,
  invoices,
  payments,
  workspaces,
  form: formReducer,
});

export default rootReducer;
