import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import appMessages from 'utils/message'
import Switch from "app/components/common/Input/Switch";
import CustomInput2 from "app/components/common/Input/CustomInput2";
import { updatePlanFeatures } from "app/api/plans";
import ProcessPage from "app/components/common/ProcessPage";
import { defaultNotifier } from "functions/notificationHandler";

class PlanQuota extends Component {
  state = {
    applicationLimit: null,
    applicationVersionLimit: null,
    dashboardLimit: null,
    deploymentLimit: null,
    deviceLimit: null,
    moduleLimit: null,
    profileLimit: null,
    videoLimit: null,
    userLimit: null,
    influxStorageLimit: null,
    logStorageLimit: null,
    auditLogStatus: false,
    multiFactorAuthenticationStatus: false,
    userRoleManagementStatus: false,
    videoViewerAccessManagementStatus: false,
    terminalAccessManagementStatus: false,
    forcedPasswordReset: false,
    sessionDuration: false,
  };

  componentDidMount() {
    const { plan } = this.props;

    this.setState({
      applicationLimit: (plan.features && plan.features.applicationLimit),
      applicationVersionLimit:
        (plan.features && plan.features.applicationVersionLimit),
      dashboardLimit: (plan.features && plan.features.dashboardLimit),
      deploymentLimit: (plan.features && plan.features.deploymentLimit),
      deviceLimit: (plan.features && plan.features.deviceLimit),
      moduleLimit: (plan.features && plan.features.moduleLimit),
      profileLimit: (plan.features && plan.features.profileLimit),
      videoLimit: (plan.features && plan.features.videoLimit),
      userLimit: (plan.features && plan.features.userLimit),
      influxStorageLimit:
        (plan.features && plan.features.influxStorageLimit),
      appDataRetentionDays:
        (plan.features && plan.features.appDataRetentionDays),
      deviceMetricsRetentionDays:
        (plan.features && plan.features.deviceMetricsRetentionDays),
      logStorageLimit: (plan.features && plan.features.logStorageLimit),
      auditLogStatus: (plan.features && plan.features.auditLogStatus),
      multiFactorAuthenticationStatus:
        (plan.features && plan.features.multiFactorAuthenticationStatus),
      userRoleManagementStatus: 
      (plan.features && plan.features.userRoleManagementStatus),
      videoViewerAccessManagementStatus: 
      (plan.features && plan.features.videoViewerAccessManagementStatus),
      terminalAccessManagementStatus: 
      (plan.features && plan.features.terminalAccessManagementStatus),
      sessionDuration: plan.features && plan.features.sessionDuration,
      forcedPasswordReset: plan.features && plan.features.forcedPasswordReset,
    });
  }

  onChange = (name, value) => {
    this.setState({ [name]: parseFloat(value) });
  };

  onChangeSwitch = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    const payload = {
      id: this.props.plan.id,
      applicationLimit: this.state.applicationLimit,
      applicationVersionLimit: this.state.applicationVersionLimit,
      dashboardLimit: this.state.dashboardLimit,
      deploymentLimit: this.state.deploymentLimit,
      deviceLimit: this.state.deviceLimit,
      moduleLimit: this.state.moduleLimit,
      deviceMetricsRetentionDays: this.state.deviceMetricsRetentionDays,
      appDataRetentionDays: this.state.appDataRetentionDays,
      profileLimit: this.state.profileLimit,
      videoLimit: this.state.videoLimit,
      userLimit: this.state.userLimit,
      influxStorageLimit: this.state.influxStorageLimit,
      logStorageLimit: this.state.logStorageLimit,
      auditLogStatus: this.state.auditLogStatus,
      sessionDuration: this.state.sessionDuration,
      forcedPasswordReset: this.state.forcedPasswordReset,
      multiFactorAuthenticationStatus: this.state.multiFactorAuthenticationStatus,
      userRoleManagementStatus: this.state.userRoleManagementStatus,
      terminalAccessManagementStatus: this.state.terminalAccessManagementStatus,
      videoViewerAccessManagementStatus: this.state.videoViewerAccessManagementStatus
    };

    const { data } = await updatePlanFeatures(payload);

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    defaultNotifier("Updated successfully");
    this.props.onClose();
  };

  render() {
    const {
    applicationLimit,
    applicationVersionLimit,
    dashboardLimit,
    deploymentLimit,
    deviceLimit,
    moduleLimit,
    profileLimit,
    videoLimit,
    userLimit,
    influxStorageLimit,
    logStorageLimit,
    auditLogStatus,
    appDataRetentionDays,
    deviceMetricsRetentionDays,
    userRoleManagementStatus,
    videoViewerAccessManagementStatus,
    terminalAccessManagementStatus,
    sessionDuration,
    forcedPasswordReset,
    multiFactorAuthenticationStatus} = this.state;

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {() => (
          <>
            <div className="headline">
              <h1 className="headline__title"><FormattedMessage {...appMessages.workspacePlanConfiguration}/></h1>
              <h3 className="headline__subtitle">
                <FormattedMessage {...appMessages.featuresIncludedInPlan}/>
              </h3>
            </div>
            <div className="login">
              <div className="plan_quota_wrapper">
                <div className="plan_quota_container">
                  <div className="plan_quoto_block">
                  <div className="plan_quota_title"><FormattedMessage {...appMessages.itemLimits}/></div>
                  <div className="plan_quota_item">
                    <div>
                      <CustomInput2
                        value={moduleLimit}
                        onChange={(e) =>
                          this.onChange("moduleLimit", e.target.value)
                        }
                      />{" "}
                      <span className="plan_quota_info">
                        <FormattedMessage {...appMessages.modules}/>
                      </span>
                    </div>
                    <div>
                      <CustomInput2
                        value={videoLimit}
                        onChange={(e) =>
                          this.onChange("videoLimit", e.target.value)
                        }
                      />{" "}
                      <span className="plan_quota_info">Videos</span>
                    </div>
                  </div>
                  <div className="plan_quota_item">
                    <div>
                      <CustomInput2
                        value={applicationLimit}
                        onChange={(e) =>
                          this.onChange("applicationLimit", e.target.value)
                        }
                      />{" "}
                      <span className="plan_quota_info">
                      <FormattedMessage {...appMessages.applications}/>
                      </span>
                    </div>
                    <div>
                      <CustomInput2
                        value={applicationVersionLimit}
                        onChange={(e) =>
                          this.onChange(
                            "applicationVersionLimit",
                            e.target.value
                          )
                        }
                      />{" "}
                      <span className="plan_quota_info">
                      <FormattedMessage {...appMessages.applicationVersions}/>
                      </span>
                    </div>
                  </div>
                  <div className="plan_quota_item">
                    <div>
                      <CustomInput2
                        value={profileLimit}
                        onChange={(e) =>
                          this.onChange("profileLimit", e.target.value)
                        }
                      />{" "}
                      <span className="plan_quota_info">Profiles</span>
                    </div>
                    <div>
                      <CustomInput2
                        value={dashboardLimit}
                        onChange={(e) =>
                          this.onChange("dashboardLimit", e.target.value)
                        }
                      />{" "}
                      <span className="plan_quota_info">Dashboards</span>
                    </div>
                  </div>
                  <div className="plan_quota_item">
                    <div>
                      <CustomInput2
                        value={userLimit}
                        onChange={(e) =>
                          this.onChange("userLimit", e.target.value)
                        }
                      />{" "}
                      <span className="plan_quota_info">Users</span>
                    </div>
                    <div>
                      <CustomInput2
                        value={deploymentLimit}
                        onChange={(e) =>
                          this.onChange("deploymentLimit", e.target.value)
                        }
                      />{" "}
                      <span className="plan_quota_info">Deployments</span>
                    </div>
                  </div>
                  </div>
                  {/* <div className="plan_quoto_block">
                  <div className="plan_quota_container">
                    <div className="plan_quota_title">
                      <FormattedMessage {...appMessages.resourceQuotas}/>
                    </div>
                    <div className="plan_quota_item">
                      <div>
                        <CustomInput2
                          value={influxStorageLimit}
                          onChange={(e) =>
                            this.onChange("influxStorageLimit", e.target.value)
                          }
                        />{" "}
                        <span className="plan_quota_info">
                          GB Influx Storage
                        </span>
                      </div>
                      <div>
                        <CustomInput2
                          value={logStorageLimit}
                          onChange={(e) =>
                            this.onChange("logStorageLimit", e.target.value)
                          }
                        />{" "}
                        <span className="plan_quota_info">GB Log</span>
                      </div>
                    </div>
                  </div>
                  </div> */}
                  <div className="plan_quoto_block">
                  <div className="plan_quota_container">
                    <div className="plan_quota_title">
                    Data retention
                    </div>
                    <div className="plan_quota_item">
                      <div>
                        <CustomInput2
                          value={appDataRetentionDays}
                          onChange={(e) =>
                            this.onChange("appDataRetentionDays", e.target.value)
                          }
                        />{" "}
                        <span className="plan_quota_info">
                        Application data (days)
                        </span>
                      </div>
                      <div>
                        <CustomInput2
                          value={deviceMetricsRetentionDays}
                          onChange={(e) =>
                            this.onChange("deviceMetricsRetentionDays", e.target.value)
                          }
                        />{" "}
                        <span className="plan_quota_info">Device metrics (days)</span>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="plan_quoto_block">
                  <div className="plan_quota_container">
                    <div className="plan_quota_title">
                      <FormattedMessage {...appMessages.premiumFeatures}/>
                    </div>
                    <div className="switch-wrapper">
                      <Switch
                        color={"accent2"}
                        onChange={this.onChangeSwitch}
                        checked={auditLogStatus}
                        name="auditLogStatus"
                        text="Enable Audit Log"
                      />
                    </div>
                    <div className="switch-wrapper">
                      <Switch
                        color={"accent2"}
                        checked={multiFactorAuthenticationStatus}
                        onChange={this.onChangeSwitch}
                        name="multiFactorAuthenticationStatus"
                        text="Enable Multi Factor Authentication"
                      />
                    </div>
                    <div className="switch-wrapper">
                      <Switch
                        color={"accent2"}
                        checked={userRoleManagementStatus}
                        onChange={this.onChangeSwitch}
                        name="userRoleManagementStatus"
                        text="Enable User Role Management"
                      />
                    </div>
                    <div className="switch-wrapper">
                      <Switch
                        color={"accent2"}
                        checked={terminalAccessManagementStatus}
                        onChange={this.onChangeSwitch}
                        name="terminalAccessManagementStatus"
                        text="Enable Terminal Access Management"
                      />
                    </div>
                    <div className="switch-wrapper">
                      <Switch
                        color={"accent2"}
                        checked={videoViewerAccessManagementStatus}
                        onChange={this.onChangeSwitch}
                        name="videoViewerAccessManagementStatus"
                        text="Enable Video Viewer Access Management"
                      />
                    </div>
                    <div className="switch-wrapper">
                      <Switch
                        color={"accent2"}
                        checked={sessionDuration}
                        onChange={this.onChangeSwitch}
                        name="sessionDuration"
                        text="Enable Session Management"
                      />
                    </div>
                    <div className="switch-wrapper">
                      <Switch
                        color={"accent2"}
                        checked={forcedPasswordReset}
                        onChange={this.onChangeSwitch}
                        name="forcedPasswordReset"
                        text="Enable Force Password Reset"
                      />
                    </div>
                  </div>
                  </div>
                </div>
                <div className="login__actions">
                  <button
                    onClick={this.onSubmit}
                    className={classNames(
                      "button",
                      `button_${this.props.color}`
                    )}
                  >
                    <FormattedMessage {...appMessages.confirm}/>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

export default PlanQuota;
