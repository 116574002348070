import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ActionMenu from "app/components/common/ActionMenu";
import {
  SettingsIcon,
  ActivateIcon,
  DeactivateIcon,
  RemoveIcon,
  AddCredits,
  AddCharge,
} from "images/common";

export const SingleMenu = ({
  onDelete,
  closeMenu,
  id,
  isSingleMenu,
  name,
  status,
  onToogleMenu,
  trainingEnabled,
  annotationEnabled,
  hideParentClass,
  onDeactivate,
  onActivate,
  onActivateAnnotation,
  onDeactivateAnnotation,
  onActivateTraining,
  onDeactivateTraining,
  onActivateDataset,
  onDeactivateDataset,
  onManageAgent,
  onOpenSubscription,
  onAddCredit,
  onAddCharge,
  datasetEnabled,
  autoTrainingEnabled,
  incidentsEnabled,
  dashboardsEnabled,
  onActivateDashboard,
  onDeactivateDashboard,
  onActivateIncident,
  onDeactivateIncident,
  onActivateAutoTraining,
  onDeactivateAutoTraining
}) => {
  const activeClass = classNames("actions__option", {
    actions__option_disabled: status === "active" || status === "deleted",
    cursor: status !== "active" && status !== "deleted",
  });

  const deactiveClass = classNames("actions__option", {
    actions__option_disabled: status === "inactive" || status === "deleted",
    cursor: status !== "inactive" && status !== "deleted",
  });

  const removeClass = classNames("actions__option", {
    actions__option_disabled: status === "active",
    cursor: status !== "active",
  });

  const manageClass = classNames("actions__option", {
    actions__option_disabled: status === "inactive" || status === "deleted",
    cursor: status !== "inactive" && status !== "deleted",
  });

  const activeAnnotationClass = classNames("actions__option", {
    actions__option_disabled: annotationEnabled,
    cursor: !annotationEnabled,
  });

  const deactiveAnnotationClass = classNames("actions__option", {
    actions__option_disabled: !annotationEnabled,
    cursor: annotationEnabled,
  });

  const activeTrainingClass = classNames("actions__option", {
    actions__option_disabled: trainingEnabled,
    cursor: !trainingEnabled,
  });

  const deactiveTrainingClass = classNames("actions__option", {
    actions__option_disabled: !trainingEnabled,
    cursor: trainingEnabled,
  });

  const activeDatasetClass = classNames("actions__option", {
    actions__option_disabled: datasetEnabled,
    cursor: !datasetEnabled,
  });

  const activateAutoTrainingActionClass = classNames("actions__option", {
    // actions__option_disabled: trainingEnabled,
    // cursor: !trainingEnabled,
  });

  const activateIncidentActionClass = classNames("actions__option", {
    actions__option_disabled: incidentsEnabled,
    cursor: !incidentsEnabled,
  });

  const deactivateIncidentActionClass = classNames("actions__option", {
    actions__option_disabled: !incidentsEnabled,
    cursor: incidentsEnabled,
  });

  const activateDashboardActionClass = classNames("actions__option", {
    actions__option_disabled: dashboardsEnabled,
    cursor: !dashboardsEnabled,
  });

  const deactivateDashboardActionClass = classNames("actions__option", {
    actions__option_disabled: !dashboardsEnabled,
    cursor: dashboardsEnabled,
  });

  const deactiveDatasetClass = classNames("actions__option", {
    actions__option_disabled: !datasetEnabled,
    cursor: datasetEnabled,
  });

  const onDeleteMenu = (id, e, status) => {
    e.stopPropagation();
    if (status !== "deleted" && status !== "inactive" && status !== "pending") {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onDelete(id, e);
  };

  // const onCancelScheduleMenu = (id, e, status) => {
  //   e.stopPropagation();
  //   onToogleMenu(e, id)

  //   if (status !== 'archived') {
  //     return false;
  //   }
  //   closeMenu();
  //   onCancelSchedule(id, e, deleteDate);
  // };

  const onDeactivateMenu = (payload, e, status) => {
    e.stopPropagation();
    if (status === "inactive" || status === "deleted") {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onDeactivate(payload, e);
  };

  const onManage = (payload, e, status) => {
    e.stopPropagation();
    if (status === "inactive" || status === "deleted") {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onManageAgent(payload, e);
  };

  const onActivateMenu = (payload, e) => {
    e.stopPropagation();
    if (status === "active" || status === "deleted") {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onActivate(payload, e);
  };

  const onActivateAutoTrainingMenu = (e) => {
    e.stopPropagation();

    onToogleMenu(e, id);

    closeMenu();
    onActivateAutoTraining(id, e);
  };

  const onActivateIncidentMenu = (e) => {
    e.stopPropagation();

    onToogleMenu(e, id);

    closeMenu();
    onActivateIncident(id, e);
  };

  const onActivateDashboardMenu = (e) => {
    e.stopPropagation();

    onToogleMenu(e, id);

    closeMenu();
    onActivateDashboard(id, e);
  };

  const onDeactivateAutoTrainingMenu = (e) => {
    e.stopPropagation();

    onToogleMenu(e, id);

    closeMenu();
    onDeactivateAutoTraining(id, e);
  };

  const onDeactivateIncidentMenu = (e) => {
    e.stopPropagation();

    onToogleMenu(e, id);

    closeMenu();
    onDeactivateIncident(id, e);
  };

  const onDeactivateDashboardMenu = (e) => {
    e.stopPropagation();

    onToogleMenu(e, id);

    closeMenu();
    onDeactivateDashboard(id, e);
  };

  const onActivateAnnotationMenu = (e) => {
    e.stopPropagation();
    if (annotationEnabled) {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onActivateAnnotation(id, e);
  };

  const onDeactivateAnnotationMenu = (e) => {
    e.stopPropagation();
    if (!annotationEnabled) {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onDeactivateAnnotation(id, e);
  };

  const onActivateTrainingMenu = (e) => {
    e.stopPropagation();
    if (trainingEnabled) {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onActivateTraining(id, e);
  };

  const onDeactivateTrainingMenu = (e) => {
    e.stopPropagation();
    if (!trainingEnabled) {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onDeactivateTraining(id, e);
  };

  const onActivateDatasetMenu = (e) => {
    e.stopPropagation();
    if (datasetEnabled) {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onActivateDataset(id, e);
  };

  const onDeactivateDatasetMenu = (e) => {
    e.stopPropagation();
    if (!datasetEnabled) {
      return false;
    }
    onToogleMenu(e, id);

    closeMenu();
    onDeactivateDataset(id, e);
  };

  // const onScheduleDeleteMenu = (id, e, status) => {
  //   e.stopPropagation();

  //   if (status !== 'archived') {
  //     return false;
  //   }

  //   closeMenu();
  //   onScheduleDelete(id, e);
  // };

  const parentClass = classNames({
    "actions__dropdown actions__dropdown_visible": !hideParentClass,
  });

  return (
    <>
      <div className={parentClass}>
        {isSingleMenu && (
          <ActionMenu
            className="actions__option"
            icon={<SettingsIcon />}
            text={"Manage Subscriptions"}
            onClick={(e) => onOpenSubscription()}
          ></ActionMenu>
        )}
        <ActionMenu
          className={manageClass}
          icon={<SettingsIcon />}
          text={"Manage Viso Agents"}
          onClick={(e) => onManage({ id, name }, e, status)}
        ></ActionMenu>
        <>
          <hr />
          <ActionMenu
            className={manageClass}
            icon={<AddCredits />}
            text={"Add Credit"}
            onClick={(e) => onAddCredit(id, e)}
          ></ActionMenu>
          <ActionMenu
            className={manageClass}
            icon={<AddCharge />}
            text={"Add Charge"}
            onClick={(e) => onAddCharge(id, e)}
          ></ActionMenu>
        </>
        <hr />
        <ActionMenu
          onClick={(e) => onDeactivateMenu({ id }, e, status)}
          className={deactiveClass}
          text="Deactivate Workspace"
          icon={<DeactivateIcon />}
        ></ActionMenu>
        <ActionMenu
          onClick={(e) => onActivateMenu({ id }, e, status)}
          className={activeClass}
          text="Activate Workspace"
          icon={<ActivateIcon />}
        ></ActionMenu>
        {/* <span
        onClick={(e) => onScheduleDeleteMenu(id, e, status)}
        className="actions__option cursor"
      >
        Schedule for Deletion
      </span>
      <span
        onClick={(e) => onCancelScheduleMenu(id, e, deleteDate, status)}
        className={cancelRemoveClass}
      >
        Cancel Scheduled Deletion
      </span> */}
        <ActionMenu
          onClick={(e) => onDeleteMenu(id, e, status)}
          className={removeClass}
          text={"Remove immediately"}
          icon={<RemoveIcon />}
        />
        <hr />
        <ActionMenu
          onClick={(e) => onActivateAnnotationMenu(e)}
          className={activeAnnotationClass}
          icon={<ActivateIcon />}
          text="Activate Annotation"
        />
        <ActionMenu
          onClick={(e) => onDeactivateAnnotationMenu(e)}
          className={deactiveAnnotationClass}
          text="Deactivate Annotation"
          icon={<DeactivateIcon />}
        ></ActionMenu>
        <hr />
        <ActionMenu
          onClick={(e) => onActivateTrainingMenu(e)}
          className={activeTrainingClass}
          icon={<ActivateIcon />}
          text={"Activate Training"}
        ></ActionMenu>
        <ActionMenu
          onClick={(e) => onDeactivateTrainingMenu(e)}
          className={deactiveTrainingClass}
          text="Deactivate Training"
          icon={<DeactivateIcon />}
        />
        <hr />
        <ActionMenu
          onClick={(e) => onActivateDashboardMenu(e)}
          className={activateDashboardActionClass}
          icon={<ActivateIcon />}
          text={"Activate Dashboard"}
        ></ActionMenu>
        <ActionMenu
          onClick={(e) => onDeactivateDashboardMenu(e)}
          className={deactivateDashboardActionClass}
          icon={<DeactivateIcon />}
          text={"Deactivate Dashboard"}
        ></ActionMenu>
        <ActionMenu
          onClick={(e) => onActivateIncidentMenu(e)}
          className={activateIncidentActionClass}
          icon={<ActivateIcon />}
          text={"Activate Incident"}
        ></ActionMenu>
        <ActionMenu
          onClick={(e) => onDeactivateIncidentMenu(e)}
          className={deactivateIncidentActionClass}
          icon={<DeactivateIcon />}
          text={"Deactivate Incident"}
        ></ActionMenu>
        <hr/>
        <ActionMenu
          onClick={(e) => onActivateDatasetMenu(e)}
          className={activeDatasetClass}
          icon={<ActivateIcon />}
          text={"Activate Dataset"}
        ></ActionMenu>
        {/* <ActionMenu
          onClick={(e) => onActivateAutoTrainingMenu(e)}
          className={activateAutoTrainingActionClass}
          icon={<ActivateIcon />}
          text={"Activate Auto Training"}
        ></ActionMenu>
        <hr/> */}
        <ActionMenu
          onClick={(e) => onDeactivateDatasetMenu(e)}
          className={deactiveDatasetClass}
          text="Deactivate Dataset"
          icon={<DeactivateIcon />}
        />
      </div>
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any,
};

export default SingleMenu;
