import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PlusCircle } from "react-feather";
import { FormattedMessage } from "react-intl";

import Modal from "app/components/common/Modal";
import messages from "utils/message";
import Breadcrumb, { BreadcrumbItem } from "app/components/Breadcrumb";

import DetailScroll from "app/components/common/Scroll";
import Information from "./components/Information";
import Overview from "./components/Overview";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import LoadingIndicator from "app/components/common/LoadingIndicator";

import { LIBRARY_ACCENT } from "app/constants/common";
import {
   listCategory,
   getCategoryDetails,
   deleteCategoryAction,
   deactivateCategoryAction,
   activateCategoryAction
  } from "app/actions/category";
import { routeMapping } from "app/constants/routes";
import PreLoader from "app/components/common/PreLoader/PreLoaderDetail";

const Menu = ({
  onActivateCategory,
  onDeactivateCategory,
  onDelete,
  closeMenu,
  deleteClass,
  deactivateClass,
  activateClass,
}) => {
  return (
    <>
      <span className={activateClass} onClick={() => onActivateCategory(closeMenu)}>
        Activate Category
      </span>
      <span
        className={deactivateClass}
        onClick={() => onDeactivateCategory(closeMenu)}
      >
        Deactivate Category
      </span>
      <span onClick={() => onDelete(closeMenu)} className={deleteClass}>
        Remove Category
      </span>
    </>
  );
};

class CategoryDetails extends React.Component {
  state = {
    showDropdown: false,
    loggedInUser: this.props.loggedInUser,
    editPopupForm: null,
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.props.getCategoryDetails({ id });
    if(!this.props.categories.length) {
      this.props.listCategory();
    }
  }

  componentDidUpdate(nextProps) {
    if(nextProps.match.url !== this.props.match.url) {
      this.props.getCategoryDetails({ id: this.props.match.params.id });
    }
  }

  toggleAction = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  onEdit = (formName) => {
    this.setState({ editPopupForm: formName });
  };

  redirectToCategories = () => {
    this.props.history.push(routeMapping.CATEGORY.path);
  };

  onDelete = async () => {
    const { id } = this.props.match.params;
    await this.props.deleteCategoryAction({ id }, this.redirectToCategories);
  };

  onActivate = async () => {
    const { id } = this.props.match.params;

    await this.props.activateCategoryAction(
      {
        id,
      },
      () => this.props.getCategoryDetails({ id })
    );
  };

  onDeactivate = async () => {
    const { id } = this.props.match.params;

    await this.props.deactivateCategoryAction(
      {
        id,
      },
      () => this.props.getCategoryDetails({ id })
    );
  };

  onEditClose = () => {
    this.setState({ editPopupForm: null });
  };

  showWorkspaceSubscribePage = () => {
    this.setState({ showSubscribeModal: true });
  };

  hideWorkspaceSubscribePage = () => {
    this.setState({ showSubscribeModal: false });
  };

  onActivateCategory = (closeMenu) => {
    const body = `The category will be activated.`;
    const title = `Confirm activate category`;

    const { category } = this.props;

    if (category.status === "active") {
      return false;
    }

    closeMenu();

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onActivate()
    });
  };

  onDeactivateCategory = (closeMenu) => {
    const { category } = this.props;

    if (category.status === "inactive") {
      return false;
    }

    closeMenu();
    const body = `The category will be deactivated.`;
    const title = `Confirm deactivate category`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onDeactivate()
    });
  };

  onDeleteCategory = (closeMenu) => {
    closeMenu()

    const { category } = this.props;

    if (category.status === "active") {
      return false;
    }

    const body = `The category will be deleted.`;
    const title = `Confirm remove category`;

    this.setState({
      showModal: true,
      body,
      title,
      onConfirm: () => this.onDelete()
    });
  };

  renderModal = () => {
    const { body, onConfirm, title } = this.state;

    const buttons = [
      <button
        className="modal__confirm"
        key="confirm"
        onClick={() => {
          onConfirm();
          this.onCloseModal();
        }}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={this.onCloseModal}>
        <FormattedMessage {...messages.cancel} />
      </button>,
    ];

    return (
      <Modal buttons={buttons} title={title} body={body} onClose={this.onCloseModal} />
    );
  };

  render() {
    const { color, category } = this.props;
    const {showModal} = this.state;

    if (!category) {
      return <DetailScroll />;
    }

    const deleteClass = classNames("actions__option", {
      actions__option_disabled: category.status === "active",
      cursor: category.status === "inactive",
    });

    const activateClass = classNames("actions__option", {
      actions__option_disabled: category.status === "active",
      cursor: category.status === "inactive",
    });

    const deactivateClass = classNames("actions__option", {
      actions__option_disabled: category.status === "inactive",
      cursor: category.status === "active",
    });

    if(this.props.isLoading) {
      return <PreLoader
        color={this.props.color}
      />
    }
  
    return (
      <DetailScroll>
        {showModal && this.renderModal()}
        <div className={"messages-bar snackbar-wrapper"}></div>
        <div className="headline">
          <div className={`headline__icon ${color} admin_workspace__image`}>
            <PlusCircle />
          </div>
          <h1 className="headline__title">{category.name}</h1>
        </div>

        <Breadcrumb breadcrumbClass='breadcrumbs_static'>
          <BreadcrumbItem route="Categories" />
          <BreadcrumbItem route={category.name} />
        </Breadcrumb>

        <div className="actions-nav">
          <div className="actions">
            <VerticalButtonMenu customClass={color}>
              <Menu
                workspaceCount={category.workspaceCount}
                onActivateCategory={this.onActivateCategory}
                onDeactivateCategory={this.onDeactivateCategory}
                onDelete={this.onDeleteCategory}
                deleteClass={deleteClass}
                activateClass={activateClass}
                deactivateClass={deactivateClass}
              />
            </VerticalButtonMenu>
          </div>
        </div>
          <div className="grid-row">
            <div className="grid-col grid-col_sm-12">
              <Information color={color} category={category} />
            </div>
            <div className="grid-col grid-col_sm-12">
              <Overview color={color} category={category} />
            </div>
          </div>
      </DetailScroll>
    );
  }
}

CategoryDetails.propTypes = {
  color: PropTypes.string,
  category: PropTypes.object,
  loggedInUser: PropTypes.object,
};

CategoryDetails.defaultProps = {
  color: LIBRARY_ACCENT,
};

function mapStateToProps(state) {
  return {
    category: state.category.detail,
    categories: state.category.list,
    isLoading: state.category.isLoading,
  };
}

const withConnect = connect(mapStateToProps, {
  listCategory,
  getCategoryDetails,
  activateCategoryAction,
  deactivateCategoryAction,
  deleteCategoryAction,
});

export default compose(withRouter, withConnect)(CategoryDetails);
