import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Switch extends React.Component {
  state = {
    currentValue: false
  };

  componentDidMount() {
    this.setState({ currentValue: this.props.checked });
  }

  // static getDerivedStateFromProps(nextProps, val) {
  //   return {
  //     currentValue: nextProps.value
  //   }
  // }

  onChange = e => {
    const {name} = this.props;

    this.setState({currentValue: e.target.checked});

    if (this.props.onChange) {
      this.props.onChange(name, e.target.checked)
    }
  };

  render() {
    const { text, color, value, noLabel, checked } = this.props;
    const {currentValue} = this.state;

    return (
      <div className={classNames('switch-container', {
        'no-label': noLabel
      })}>
        <label
          className={classNames('switch', {
          })}
        >
          <input type="checkbox" onChange={this.onChange} checked={currentValue} />
          <span
            className={classNames('slider round', {
              [color]: color
            })}
          />
        </label>
        {text && <h4 className={classNames('switch-label', {
          'switch-label-text': this.state.currentValue
        })}>{text}</h4>
        }
      </div>
    );
  }
}

Switch.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  parentClass: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hintText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isInvalid: PropTypes.any
};
