import React from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Codesandbox} from 'react-feather'
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import VerticalButtonMenu from "app/components/common/VerticalButtonMenu";
import { orderBy } from "lodash";
import NotFound from "app/components/NotFound";
import ProcessPage from "app/components/common/ProcessPage";
import ActionsView from "app/components/ActionsView";

import SearchField from "app/components/common/SingleSearch";
import NoPlans from "../../common/NoItem";

import Card from "./Card";
import List from "./Table";

import {
  CARD_VIEW,
  TABLE_VIEW,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ACCENT,
} from "app/constants/common";
import { updateVisoAgentVersion } from "app/api/workspaces";
import ListScroll from "app/components/common/ListScroll";
import filterColumns from "./filter";
import {
  listVisoAgents
} from "app/actions/workspaces";
import Filter from "app/components/common/Filter";
import { getFilterData } from "app/components/common/Filter/filter";
import { getOutput } from "app/components/common/Filter/clientFilter";
import { defaultNotifier } from "functions/notificationHandler";

import ListSelectedItems from "../../common/ListSelectedItems";

class ManageAgents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      selectedIds: [],
      isSorted: false,
      showOnlyArchived: false,
      showDropdown: false,
      sort: {
        key: "name",
        direction: "asc",
      },
      filter: {
        single: [],
        range: [],
      },
      searchName: null,
      resetFilter: false,
      activeView: TABLE_VIEW,
      showCreatePopup: false,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  componentDidMount() {
    const { name } = this.props.match.params;

    this.setState({workspaceName: name})
    this.getAgents()
  }

  onSort = (sort) => {
    const hasFilter =
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      offset: 0,
      filterResult: orderBy(
        hasFilter ? this.state.filterResult : this.props.agents,
        [sort.key],
        [sort.direction]
      ),
    });
  };

  onSearch = (data) => {
    if (!data) {
      return this.setState(
        {
          resetFilter: false,
          searchName: data || null,
          offset: 0,
        },
        () => this.onFilter({ ...this.state.activeFilterParams }, true)
      );
    }

    return this.setState(
      {
        resetFilter: false,
        searchName: data || null,
        offset: 0,
      },
      () => this.onFilter({ ...this.state.activeFilterParams }, true)
    );
  };

  onFilter = async (args) => {
    this.setState({ activeFilterParams: args });
    const { filterData } = getFilterData(args);
    const finalResult = getOutput(filterData, this.props.agents);
    let result = finalResult;

    if (this.state.searchName) {
      result = finalResult.filter((item) =>
        item.deviceType
          .toLowerCase()
          .includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      filterResult: result,
    });
  };

  onReset = () => {
    this.setState(
      {
        filter: {
          single: [],
          range: [],
        },
        activeFilterParams: null,
        selectedIds: [],
        searchName: "",
        resetFilter: true,
        offset: 0,
      },
      () => this.setState({ filterResult: this.props.agents })
    );
  };

  onSelect = (id, e) => {
    e.stopPropagation();

    return this.setState({ selectedIds: id });
  };

  selectAll = (e) => {
    const { checked } = e.target;
    const { data } = this.props;

    if (data && checked) {
      this.setState({ selectedIds: data.map((profile) => profile.id) });
    } else if (!checked) {
      this.setState({ selectedIds: [] });
    }
  };

  getIsSelectedAll = () => {
    return false;
  };

  updateView = (activeView) => {
    this.setState({ activeView });
  };

  getAgents = () => {
    const { id } = this.props.match.params;

    this.props.listVisoAgents({ workspaceId: id });
  };

  onDelete = async (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    await this.props.deletePlanAction({ id });
    this.getAgents();
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  loadMore = () => {
    const { modules } = this.props;

    if (modules.loading) {
      return false;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE }, () =>
      this.getAgents()
    );
  };

  isMore = () => {
    return false;
  };

  updateToLatestVersion = async (id) => {
    const { data } = await updateVisoAgentVersion({ id });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    defaultNotifier("Updated to the latest version");
    this.getAgents();
  };

  redirectToWorkspace = () => {

    this.props.history.goBack()
  }

  render() {
    const { color } = this.props;

    const { activeView, selectedIds, resetFilter } = this.state;

    const hasFilter =
      this.state.isSorted ||
      !!this.state.filter.range.length ||
      !!this.state.filter.single.length ||
      this.state.searchName;

    return (
      <>
        <ProcessPage onCancel={this.props.onClose || this.redirectToWorkspace}>
          {() => (
            <div>
              <div className="headline">
                <h1 className="headline__title">Viso Agents</h1>
                <h3 className="headline__subtitle">
                  {this.state.workspaceName || this.props.workspaceName}
                </h3>
              </div>
                <>
                <ActionsView
                  activeView={this.state.activeView}
                  updateView={this.updateView}
                  colorClassName={color}
                  containerClassName="popup__actions-nav"
                ></ActionsView>
                  {!hasFilter &&
                  !this.props.agents.length &&
                  !this.props.isLoading ? (
                    <NotFound
                      color={color}
                      strokeWidth="1"
                      logo={<Codesandbox size={42} />}
                      title="Create a viso agent"
                      description="No viso agent"
                    />
                  ) : (
                    <>
                      <div className="popup__filters-bar filters-bar popup__filters-bar_custom_spacing2">
                        <ListSelectedItems
                          selectedNumber={selectedIds.length}
                          search={this.state.searchName}
                          onReset={this.onReset}
                          filterData={this.state.filter}
                          containerClassName={color}
                        />
                        <Filter
                          customClass={color}
                          resetFilter={resetFilter}
                          onChange={this.onFilter}
                          columns={filterColumns}
                          data={this.props.agents}
                          // defaultFilter={{
                          //   field: "status",
                          //   value: "All",
                          //   columns: ["active", "inactive", "draft"],
                          // }}
                        />
                        <SearchField
                          onSearch={this.onSearch}
                          customClass={color}
                          resetSearch={this.state.resetFilter}
                          label="Search"
                        />
                      </div>
                      <div>
                        <InfiniteScroll
                          loadMore={this.debouncedLoadMore}
                          hasMore={this.isMore()}
                          initialLoad={false}
                          useWindow={false}
                          getScrollParent={() => this.scrollParent}
                        >
                          {activeView === TABLE_VIEW && (
                            <List
                              data={
                                hasFilter
                                  ? this.state.filterResult
                                  : this.props.agents
                              }
                              onRowClick={this.onRowClick}
                              updateToLatestVersion={this.updateToLatestVersion}
                              selectAll={this.selectAll}
                              onSort={this.onSort}
                              color={color}
                              isLoading={this.props.isLoading}
                              sort={this.state.sort}
                              isSelectedAll={this.getIsSelectedAll()}
                              selectedIds={selectedIds}
                              onSelect={this.onSelect}
                            />
                          )}
                          {activeView === CARD_VIEW && (
                            <div className="data-cards">
                              <Card
                                color={color}
                                data={
                                  hasFilter
                                    ? this.state.filterResult
                                    : this.props.agents
                                }
                                onRowClick={this.onRowClick}
                                selectedItems={selectedIds}
                                updateToLatestVersion={
                                  this.updateToLatestVersion
                                }
                                onDelete={this.onDelete}
                                onSelect={this.onSelect}
                              />
                            </div>
                          )}
                        </InfiniteScroll>
                        {activeView === CARD_VIEW &&
                          this.props.agents &&
                          !this.props.agents.length && <NoPlans />}
                      </div>
                    </>
                  )}
                </>
            </div>
          )}
        </ProcessPage>
      </>
    );
  }
}

ManageAgents.propTypes = {
  color: PropTypes.string,
  agents: PropTypes.array,
  activeView: PropTypes.string,
};

ManageAgents.defaultProps = {
  color: DEFAULT_ACCENT,
};

function mapStateToProps(state) {
  return {
    agents: state.workspaces.agents,
    isLoading: state.workspaces.agentsLoading,
    isStatusBarOpen: state.ui.isStatusBarOpen,
  };
}

const withConnect = connect(mapStateToProps, {listVisoAgents});

export default compose(withRouter, withConnect)(ManageAgents);
