import React, { Component } from "react";
import PropTypes from "prop-types";

import Card from "app/components/common/Detail/Card";
import PlanPriceList from "../../planPrice";
import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import { TickIcon } from "images/common";
import { DollarSign } from "react-feather";

import { getInterval } from "utils/common";

export const getCurrencyIcon = {
  usd: <DollarSign />,
};

class Subscriptions extends Component {
  state = {
    openModal: false,
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  renderContent = () => {
    const { color, plan } = this.props;

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {plan &&
              plan.prices &&
              plan.prices.map((price) => (
                <tr className="data-table__row" key={price.id}>
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      {plan.monthlyPriceId === price.id && (
                        <span
                          className={`data-cards__selected data-cards__selected_item ${color}`}
                        >
                          <TickIcon />
                        </span>
                      )}
                      {plan.yearlyPriceId === price.id && (
                        <span
                          className={`data-cards__selected data-cards__selected_item ${color}`}
                        >
                          <TickIcon />
                        </span>
                      )}
                      <div className="checkbox__image">
                        {getCurrencyIcon[price.currency] ||
                          price.currency.toUpperCase()}
                      </div>
                    </div>
                  </td>
                  <td className="data-table__td">
                    <div className="data-table__price_item">
                      <span
                        className={`data-table__td_dark data-table__priceId`}
                      >
                        {price.id}
                      </span>
                      {"   "}
                      <span>
                        •{" "}
                        {price.recurring &&
                          price.recurring.interval &&
                          getInterval[price.recurring.interval]}
                      </span>
                    </div>
                  </td>
                  <td className="data-table__td">
                    {price.metadata &&
                      price.metadata.minEndpoints &&
                      `${price.metadata.minEndpoints} Endpoints Minimum`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    if (this.state.openModal) {
      return (
        <PlanPriceList
          getCurrencyIcon={getCurrencyIcon}
          data={this.props.plan.prices}
          onClose={this.closeModal}
        />
      );
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        title="Plan Prices"
        subTitle="Prices to which workspaces can subscribe to."
        className="flow-modules"
        onEdit={this.openModal}
        content={this.renderContent()}
      />
    );
  }
}

Subscriptions.propTypes = {
  color: PropTypes.string,
};

Subscriptions.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

export default Subscriptions;
