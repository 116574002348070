import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import Menu from "./SingleMenu";
import SmallCard from "../../common/SmallCard";
import { WorkspaceIcon } from "images/common";
import {getInvoice, getPaymentFormat} from 'utils/common'

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getStatus = (status) => {
    const className = classNames("data-cards__status text-capitalize", {
      success: status === "active",
      failure: status === "inactive",
      neutral: status === "deleted",
    });

    return <i className={className}>•</i>;
  };

  onRedirectToPartner = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    if(id) {
      return this.props.history.push(`/partner/${id}`)
    }
  }

  getSubheading = (payment) => {
    return (
      <>
        {getInvoice(payment.invoiceNumber)} •{' '}
        {payment.amount} {payment.currency}
      </>
    );
  };

  render() {
    const {
      color,
      data,
      onSelect,
      detailRole = {},
      selectedItems,
    } = this.props;

    return (
      <div className="grid-row">
        {data.map((payment) => (
          <SmallCard
            key={payment.id}
            heading={payment.invoiceDate}
            id={payment.id}
            isLoading={this.props.isLoading}
            onCardClick={this.props.onRowClick}
            colorClassName={color}
            subheading={this.getSubheading(payment)}
            selectedItems={selectedItems}
            onSelect={onSelect}
            CheckboxImage={WorkspaceIcon}
          >
            <Menu
              id={payment.id}
              detailRole={detailRole.role}
              onRecord={this.props.onRecord}
              onScheduleDelete={this.props.onScheduleDelete}
            />
          </SmallCard>
        ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
