import React from 'react';
import {FormattedMessage} from 'react-intl';

import appMessages from 'utils/message'

const filterColumns = [{
  id: 1,
  label: <FormattedMessage {...appMessages.type}/>,
  field: 'modelType',
  type: 'radio',
},
{
  id: 2,
  label: 'Dataset',
  field: 'dataset',
  type: 'checkbox',
},
{
  id: 3,
  label: 'Framework',
  field: 'framework',
  type: 'checkbox',
},
{
  id: 5,
  label: <FormattedMessage {...appMessages.created}/>,
  type: 'date',
  field: 'createdAt',
},
{
  id: 5,
  label: <FormattedMessage {...appMessages.updated}/>,
  type: 'date',
  field: 'updatedAt',
},
{
  id: 6,
  label: <FormattedMessage {...appMessages.tags}/>,
  type: 'tags',
  field: 'tags',
}
]

export default filterColumns