import { payments } from "../constants/payments";
import { billing } from "../constants/billing";

const INITIAL_STATE = {
  list: {
    count: 0,
    data: [],
  },
  isLoading: false,
  detail: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case payments.GET_PAYMENTS_PENDING:
      return {
        ...state,
        isLoading: true,
        list: {
          count: state.list.count || 0,
          data: state.list.data || []
        },
      };
      case payments.GET_PAYMENTS_FULFILLED:
        return {
          ...state,
          isLoading: false,
          list: action.payload,
        };
        case payments.GET_PAYMENTS_REJECTED:
          return {
            ...state,
            isLoading: false,
            list: {
              count: state.list.count || 0,
              data: state.list.data || []
            },
          };
    case payments.GET_PAYMENT_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        detail: null,
      };
    case payments.GET_PAYMENT_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
      };
    case payments.GET_PAYMENT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        detail: null,
      };
      
    default:
      return state;
  }
}
