import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Menu from "./SingleMenu";
import { dateFormatter, getInvoice } from "utils/common";
import SortableRow from "app/components/common/Table/SortableRow";
import NumberFormat from "react-number-format";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { status } = this.props;

    const className = classNames("data-table__status type__value", {
      "data-table__status_pos": status === "paid",
      "data-table__status_neg": status === "overdue",
      "data-table__status_neutral": status === "paid",
    });
    return (
      <div className={className}>
        <span className="text-capitalize">{status}</span>
      </div>
    );
  };

  onRedirectToPartner = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    if (id) {
      return this.props.history.push(`/partner/${id}`);
    }
  };

  getPartner = () => {
    const { partner = {} } = this.props;

    if (partner && partner.id && partner.status === "approved") {
      return (
        <span
          className="cursor accent0"
          onClick={(e) => this.onRedirectToPartner(e, partner.id)}
        >
          Partner
        </span>
      );
    }
    return (
      <span onClick={(e) => this.onRedirectToPartner(e)}>
        {(partner && partner.status) || "Inactive"}
      </span>
    );
  };

  onRedirectToWs = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.history.push(`/workspace/${id}`);
  };

  getRowRender = () => {
    const {
      workspace,
      invoiceNumber,
      targetDate,
      amount,
      invoiceDate,
      currency,
      creditAdj,
      balance,
      refundAdj,
      currentSortKey,
      colorClassName,
      id,
    } = this.props;

    const nameClass = classNames({
      "data-table__td_dark": currentSortKey === "invoiceNumber",
    });

    const statusClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "status",
    });

    const amountClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "amount",
    });

    const balanceClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "balance",
    });

    const creditClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "creditAdj",
    });

    const refundClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "refundAdj",
    });

    const workspaceClass = classNames("type__value", {
      [colorClassName]: colorClassName,
      "data-table__td_dark": currentSortKey === "workspace.name",
    });

    const createdAtClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "invoiceDate",
    });

    const targetAtClass = classNames("type__value", {
      "data-table__td_dark": currentSortKey === "targetDate",
    });

    return {
      invoiceNumber: () => <div className={nameClass}>{getInvoice(invoiceNumber)}</div>,
      status: () => <span className={statusClass}>{this.getStatus()}</span>,
      amount: () => (
        <span className={amountClass}>
          <NumberFormat
            value={amount && amount.toFixed(2)}
            displayType={"text"}
            thousandSeparator
            renderText={(value) => <span>
              {currency} {value}
              </span>
            }
          />
        </span>
      ),
      creditAdj: () => (
        <span className={creditClass}>
          <NumberFormat
            value={creditAdj && creditAdj.toFixed(2)}
            displayType={"text"}
            thousandSeparator
            renderText={(value) => <span>
              {currency} {value}
              </span>
            }
          />
        </span>
      ),
      balance: () => (
        <span className={balanceClass}>
          <NumberFormat
            value={balance && balance.toFixed(2)}
            displayType={"text"}
            thousandSeparator
            renderText={(value) => <span>
              {currency} {value}
              </span>
            }
          />
        </span>
      ),
      refundAdj: () => (
        <span className={refundClass}>
          <NumberFormat
            value={refundAdj && refundAdj.toFixed(2)}
            displayType={"text"}
            thousandSeparator
            renderText={(value) => <span>
              {currency} {value}
              </span>
            }
          />
        </span>
      ),
      workspace: () => (
        <span
          className={workspaceClass}
          onClick={(e) => this.onRedirectToWs(workspace.id, e)}
        >
          {workspace && workspace.name}
        </span>
      ),
      invoiceDate: () => (
        <span className={createdAtClass}>{dateFormatter(invoiceDate)}</span>
      ),
      targetDate: () => (
        <span className={targetAtClass}>{dateFormatter(targetDate)}</span>
      ),
    };
  };

  render() {
    const {
      colorClassName,
      detailRole = {},
      id,
      isActive,
      archived,
      deleteDate,
      status,
      name,
      balance,
    } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
          <Menu
            id={id}
            name={name}
            isActive={isActive}
            deleteDate={deleteDate}
            archived={archived}
            status={status}
            balance={balance}
            detailRole={detailRole.role}
            onRecord={this.props.onRecord}
            onScheduleDelete={this.props.onScheduleDelete}
          />
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
