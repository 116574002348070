import React from 'react'
import PropTypes from 'prop-types'

import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import {WorkspaceIcon} from 'images/common'
import {FilePlus} from 'images/common'

const columnList = [{
  label: 'Phase',
  isSortable: true,
  isLocked: true,
  sortKey: 'phase'
}, 
{
  label: 'Type',
  sortKey: 'type',
  isSortable: true,
  isLocked: true,
},
{
  label: 'Duration',
  sortable: false,
  sortKey: 'duration'
},
{
  label: 'Billing Period',
  sortable: false,
  sortKey: 'billingPeriod'
},
{
  label: 'Price',
  sortable: false,
  sortKey: 'prices'
}
]

class ModuleTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        plan={this.props.plan}
        rows={data || []}
        notFoundIcon={<FilePlus/>}
        colorClassName={color}
        CheckboxImage={WorkspaceIcon}
        isSelectable
        isIndex
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        monthlyPriceId={this.props.plan && this.props.plan.monthlyPriceId}
        yearlyPriceId={this.props.plan && this.props.plan.yearlyPriceId}
        deletePrice={this.props.deletePrice}
        setDefaultPrice={this.props.setDefaultPrice}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSort}
        rowClick={this.props.onRowClick}
      />
    )
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ModuleTable
