import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import appMessages from 'utils/message'
import Card from 'app/components/common/Detail/Card';
import {convertDefaultStatusToStatus } from 'utils/common'

class ModelDetail extends Component {
  renderContent = () => {
    const { model} = this.props

    return (
      <>
          <div className="grid-col">
            <dl>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.dataset} />
              </dt>
              <dd className="overview__value">{model && model.dataset && convertDefaultStatusToStatus(model.dataset)}</dd>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.modelSize}/>
              </dt>
              <dd className="overview__value">
                <dd className="overview__value">{model && model.modelSize}</dd>
              </dd>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.source} />
              </dt>
              <dd className="overview__value text-capitalize">
                {model && model.source}
              </dd>
            </dl>
          </div>
          <div className="grid-col">
            <dl>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.license}/>
              </dt>
              <dd className="overview__value text-capitalize">{model && model.license}</dd>
              <dt className="overview__label">
                <FormattedMessage {...appMessages.imageSize} />
              </dt>
              <dd className="overview__value">
                {model && model.imageSize}
              </dd>
            </dl>
          </div>
          </>
    )
  }

  render() {
    return (
      <Card
        isLoading={this.props.isLoading}
        color={this.props.color}
        title={
          <FormattedMessage {...appMessages.modelDetails} />
        }
        className="overview"
        content={this.renderContent()}
      />
    );
  }
}

ModelDetail.propTypes = {
  flow: PropTypes.object,
  color: PropTypes.string
}

export default ModelDetail
