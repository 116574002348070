import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from 'classnames'

import Menu from "./SingleMenu";
import SortableRow from "app/components/common/Table/SortableRow";
import NumberFormat from "react-number-format";
import { dateFormatter, getMRR, getInterval } from "utils/common";

class Workspaces extends PureComponent {
  getStatus = () => {
    const { status, pause_collection } = this.props

    const className = classNames('data-table__status type__value text-captilize',
      {
        'data-table__status_pos': status === 'active',
        'data-table__status_neg': status === 'Deactivated'
      })

    return (
      <div className={className}>{ status }
      <span>{pause_collection && '(Paused)'}</span></div>
    )
  }

  getDueDate = (subscription) => {
    if(subscription.status === 'active') {
      return `${dateFormatter(subscription.current_period_end * 1000)}`
    }

    return `${dateFormatter(subscription.billing_cycle_anchor * 1000)}`
  }
  
  getProvider = (plan, metadata) => {

    if(metadata.type === 'marketplace') {
      return plan.product && plan.product.metadata.provider
    }

    return 'viso.ai'
  }

  getRowRender = () => {
    const {
      type,
      plan,
      name,
      createdAt,
      id,
      quantity,
      metadata,
      createdBy
    } = this.props;

    return {
      id: () => <div className="data-table__td">{id}</div>,
      name: () => <div className="data-table__td_dark">{name}</div>,
      'owner': <div className="data-table__td_dark">{createdBy.name}</div>,
      status: () => <span className="type__value">{this.getStatus()}</span>,
      period: () => <span className="text-capitalize">{type}</span>,
      createdAt: () => dateFormatter(createdAt, true),
      interval: () => getInterval[plan.interval],
      endpoints: () => quantity,
      nextInvoice: () => <NumberFormat
      value={getMRR(quantity ,plan )}
      displayType={'text'}
      thousandSeparator renderText={value =>
        <span>{plan.currency && plan.currency.toUpperCase()}{' '}{value}</span>
      }/>,
      nextDue: () => this.getDueDate(this.props),
      provider: () => this.getProvider(plan, metadata)
    };
  };

  render() {
    const { colorClassName, detailRole = {} } = this.props;

    return (
      <>
        <SortableRow
          {...this.props}
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        >
            <Menu detailRole={detailRole.role} />
        </SortableRow>
      </>
    );
  }
}

Workspaces.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Workspaces));
