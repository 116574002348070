import React, { Component } from "react";
import PropTypes from "prop-types";

import Card from "app/components/common/Detail/Card";
import PlanPriceList from "../../planPrice";
import { DASHBOARD_ACCENT_COLOR } from "app/constants/common";
import { TickIcon } from "images/common";
import { DollarSign } from "react-feather";

import { getInterval, snakeCaseToTitleCase, getPriceFormat } from "utils/common";

export const getCurrencyIcon = {
  usd: <DollarSign />,
};

class Subscriptions extends Component {
  state = {
    openModal: false,
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  getPrice = (phase) => {
    if(phase.type === 'TRIAL') {
      return `${phase && phase.fixedPrices && phase.fixedPrices[0] && getPriceFormat(phase.fixedPrices[0].value)}`
    }

    const price = phase.prices.map(price => price.value).reduce((sum ,item) => item + sum);

    return `${getPriceFormat(price)}`

  }

  renderContent = () => {
    const { color, plan } = this.props;

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {plan &&
              plan.phases &&
              plan.phases.map((phase, index) => (
                <tr className="data-table__row" key={phase.type}>
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        {index + 1}
                      </div>
                    </div>
                  </td>
                  <td className="data-table__td">
                    <div className="data-table__price_item">
                      <span
                        className={`data-table__td_dark data-table__priceId text-capitalize`}
                      >
                        {phase.type && snakeCaseToTitleCase(phase.type)}<span className='label-color'><span className='dot-class'>•</span>{phase.duration.number > 0 && phase.duration.number} {snakeCaseToTitleCase(phase.duration.unit)}</span>
                      </span>
                    </div>
                  </td>
                  <td className="data-table__td">{this.getPrice(phase)} {phase.type === 'TRIAL' ? '' : snakeCaseToTitleCase(plan.billingPeriod)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    if (this.state.openModal) {
      return (
        <PlanPriceList
          getCurrencyIcon={getCurrencyIcon}
          plan={this.props.plan}
          data={this.props.plan.phases.map((phase, index) => ({...phase, id: index, phase: `Phase ${index+1}`}))}
          onClose={this.closeModal}
        />
      );
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        title="Plan Phases"
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

Subscriptions.propTypes = {
  color: PropTypes.string,
};

Subscriptions.defaultProps = {
  color: DASHBOARD_ACCENT_COLOR,
};

export default Subscriptions;
