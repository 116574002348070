import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'


import ModuleRow from './Row'
import Table from 'app/components/common/Table'
import appMessages from 'utils/message'
import { FilePlus } from "images/common";


const columnList = [{
  label: 'Plan Name',
  isSortable: true,
  isLocked: true,
  sortKey: 'id'
}, {
  label: <FormattedMessage {...appMessages.status} />,
  sortKey: 'status'
}, {
  label: <FormattedMessage {...appMessages.type} />,
  sortKey: 'type'
},
{
  label: <FormattedMessage {...appMessages.provider} />,
  sortable: false,
  isSortable: true,
  sortKey: 'provider'
},
{
  label: <FormattedMessage {...appMessages.interval} />,
  sortable: false,
  isSortable: true,
  isOptional: true,
  sortKey: 'interval'
},
{
  label: <FormattedMessage {...appMessages.endpoints} />,
  sortable: false,
  isSortable: true,
  sortKey: 'endpoints'
},
{
  label: <FormattedMessage {...appMessages.nextInvoice} />,
  sortable: false,
  isSortable: true,
  sortKey: 'nextInvoice'
},
{
  label: <FormattedMessage {...appMessages.nextDueDate} />,
  sortable: false,
  isSortable: true,
  sortKey: 'nextDue'
},
{
  label: <FormattedMessage {...appMessages.subscribedAt} />,
  sortable: false,
  isSortable: true,
  sortKey: 'createdAt'
},
]

class ModuleTable extends React.Component {
  render() {
    const {
      color,
      data,
      onSelect,
      selectedIds
    } = this.props

    const result = data && data.map(item => ({...item}));

    return (
      <Table
        columns={columnList}
        RowComponent={ModuleRow}
        rows={result || []}
        notFoundIcon={<FilePlus/>}
        colorClassName={color}
        CheckboxImage={FilePlus}
        isSelectable
        workspace={this.props.workspace}
        withColumnSelect
        selectedItems={selectedIds}
        onSelect={onSelect}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSortChange}
        rowClick={this.props.onRowClick}
      />
    )
  }
}

ModuleTable.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired
}

export default ModuleTable
