import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";

import SmallCard from "app/components/common/SmallCard";
import {FilePlus} from 'images/common'
import SingleMenu from './SingleMenu'

class Card extends React.Component {
  state = {
    showSingleMenu: false,
  };

  isActive = (id) => {
    const { selectedIds } = this.props;

    return selectedIds.includes(id);
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showSingleMenu: true });
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false });
  };

  getStatus = (status) => {
    const className = classNames("data-cards__status c-text text-capitalize", {
      success: status === 'active',
      failure: status === 'inactive'
    });

    return <i className={className}>•</i>;
  };

  onRedirectToSubscribedWorkspace = (id, e, workspaceCount) => {
    e.stopPropagation()
    e.preventDefault()

    if(!workspaceCount) {
      return false
    }

    this.props.history.push(`/workspace/plan/${id}`);
  };

  getSubheading = (status, originalId, workspaceCount) => {
    const {
      color,
    } = this.props;

    return <>
      {this.getStatus(status)} {status} • <span className={`text-link cursor ${color}`} onClick={(e) => this.onRedirectToSubscribedWorkspace(originalId, e, workspaceCount)}>
      {workspaceCount || 0} workspaces
      </span>
    </>
  }
  

  render() {
    const {
      color,
      data,
      onSelect,
      onUpdateStatus,
      onDelete,
      selectedItems,
    } = this.props;

    const result = data && data.map(item => ({...item, id: item.id, originalId: item.id}))

    return (
      <div className="grid-row">
        {result.map((item) => (
          <SmallCard
            key={item.id}
            heading={item.name}
            id={item.id}
            onCardClick={this.props.onRowClick}
            colorClassName={color}
            subheading={this.getSubheading(
              item.status,
              item.originalId,
              item.workspaceCount
            )}
            selectedItems={selectedItems}
            onSelect={onSelect}
            CheckboxImage={FilePlus}
            hideSingleMenu
          >
            <SingleMenu 
             onDuplicate={this.props.onDuplicate}
             onDeactivatePlan={this.props.onDeactivatePlan}
             onSetAsDevPlan={this.props.onSetAsDevPlan}
             onActivatePlan={this.props.onActivatePlan}
            id={item.id}
            isDevPlan={item.isDevPlan}
           originalId={item.originalId}
             onDelete={onDelete}
              status={item.status}
               onUpdateStatus={onUpdateStatus}/>
          </SmallCard>
        ))}
      </div>
    );
  }
}

Card.propTypes = {
  onSelect: PropTypes.func,
  modules: PropTypes.object,
  selectedIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  disableOnClick: PropTypes.bool,
};

const withConnect = connect(null, null);

export default withConnect(withRouter(Card));
