import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Codesandbox } from "react-feather";
import { getInitials, dateFormatter } from "utils/common";
import Card from "app/components/common/Detail/Card";
import { FormattedMessage } from "react-intl";

import messages from "utils/message";

class VisoAgents extends Component {
  state = {
    showListPage: false,
  };

  onRedirectToUser = (id) => {
    // this.props.history.push(`${routeMapping.SETTINGS_USER.path}/${id}`)
  };

  renderContent = () => {
    const { color, workspace } = this.props;

    const name = workspace.owner && workspace.owner.name && workspace.owner.name.replace(/\s+/g, ' ')
    const firstName = (name && name.split(' ')[0]) || '-'
    const lastName = (name && name.split(' ')[1]) || '-'

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            <tr className="data-table__row">
              <td className="data-table__td data-table__td_1">
                <div className={`checkbox checkbox_${color}`}>
                  <div className="checkbox__image">
                    {firstName[0].toUpperCase()}{lastName[0].toUpperCase()}
                  </div>
                </div>
              </td>
              <td className="data-table__td">{workspace.owner && workspace.owner.name}</td>
              <td className="data-table__td">{workspace.owner && workspace.owner.email}</td>
            </tr>
          </tbody>
        </table>
        {/* <div className="grid-col">
          <div className="grid-row grid-row_aic">
            <div className="grid-col">
              <div className="overview__title" />
              <div className="overview__label">
                <FormattedMessage {...messages.timezone} />
              </div>
              <div className="overview__value">UTC +2</div>
            </div>
            <div className="grid-col">
              <div className="overview__title" />
              <div className="overview__label">
                <FormattedMessage {...messages.locale} />
              </div>
              <div className="overview__value">EN</div>
            </div>
          </div>
        </div> */}
      </div>
    );
  };

  render() {
    const { workspace } = this.props;

    return (
      <Card
        noGrid
        color={this.props.color}
        title="Billing Contact"
        className="flow-modules"
        content={this.renderContent()}
      />
    );
  }
}

VisoAgents.propTypes = {
  onEdit: PropTypes.func,
  workspace: PropTypes.object,
};

export default withRouter(VisoAgents);
