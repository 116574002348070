const filterColumns = [
{
  id: 1,
  label: 'Status',
  field: 'status',
  type: 'radio',
},
{
  id: 2,
  label: 'Category Name',
  field: 'name',
  type: 'search',
},
{
  id: 3,
  label: 'Category ID',
  field: 'id',
  type: 'checkbox',
},
{
  id: 7,
  label: 'Created',
  field: 'createdAt',
  type: 'date',
}]

export default filterColumns